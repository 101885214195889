import React from 'react';

import TextField from '../../../TextField/TextField';
import { correctText } from '../../../../utils/convertingDataHelper';
import { ReactComponent as ClearIcon } from '../../../../assets/images/icons/stroke_white.svg';
import DefaultButton from '../../../DefaultButton/DefaultButton';
import styles from '../../sass/FilterScreen.module.scss';
import { TValueItem } from '../../Types';

interface ITextItems {
  arrayData: TValueItem[];
  classType?: string;
  handleCheck?: (value: string) => void;
  handlerCloseDropdown?: () => void;
}

const TextItems = ({
  arrayData, classType, handleCheck, handlerCloseDropdown,
}: ITextItems): React.ReactElement => (
  <>
    {arrayData && arrayData.map((item, index) => (
      <div
        key={[item.value, index].join('_')}
        className={classType ? styles[classType] : 'filterItemEmpty'}
      >
        <TextField
          styleType={classType || 'filterItem'}
          text={correctText({ item })}
        />
        {classType && (
          <DefaultButton
            buttonClass="btnClearItemSelect"
            handleClick={() => {
              if (typeof handlerCloseDropdown === 'function') handlerCloseDropdown();
              if (typeof handleCheck === 'function') handleCheck(item.value as string);
            }}
          >
            <ClearIcon />
          </DefaultButton>
        )}
      </div>
    ))}
  </>
);

export default TextItems;
