import {
  useEffect, useState, useCallback
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ALERT_TABLE_SORTING } from '../../../../constants/storage';
import {
  setTableSorted,
  setMarketsFilterWatchlistAction
} from '../../../../store/screener/actions';
import { setActiveAlert } from '../../../../store/watchlist/actions';
import { setSortAlertArray } from '../../../../components/ChartContainer/utils/alertHelper';
import useAlerts from '../../../../components/ChartContainer/hooks/useAlerts';
import RootStateTypes from '../../../../store/RootStateTypes';
import {
  FiltersArray,
  AlertData,
  SymbolAlert,
  StatusAlert,
  Alert, StoredSymbol
} from '../types/AlertTypes';
import {
  ISortData, AlertHook
} from '../types/Interfaces';
import { setAlertsSortData } from '../../../../store/alerts/slice';
import { TABLE_SCREEN_PATH } from '../../../../constants/screener';
import { NOTIFICATION_DELAY_TIMES } from '../../../../constants/alerts';
import useLastStateUpdateStore from '../../../../utils/hooks/useLastStateUpdateStore';

const useAlertsTableWorker = (): AlertHook => {
  const {
    removeAlerts,
    getAlertsNotificationList,
    getAlertsList,
    triggeredAlertsListener,
    getAlertSettings
  } = useAlerts();
  const dispatch = useDispatch();
  const [selectedAlertItems, setSelectedItems] = useState<number[]>([]);
  const [alertFilters, setAlertFilters] = useState<number[][][][]>([]);

  const symbolsList = useSelector((state:RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const selectedTab = useSelector((state:RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const activeAlert = useSelector((state:RootStateTypes) => state.watchlistState.activeAlertID, shallowEqual);
  const alertTab = useSelector((state:RootStateTypes) => state.watchlistState.alertsTab, shallowEqual);
  const alertColumns = useSelector((state:RootStateTypes) => state.accountState.userSettings.alertColumns);
  const storedSorting = useSelector((state:RootStateTypes) => state.accountState.userSettings.alertSorting);

  const filterMarket = useSelector((state:RootStateTypes) => state.screenerState.filterMarket, shallowEqual);
  const sortData = useSelector((state:RootStateTypes) => state?.alertsState?.sortData, shallowEqual);
  const alertListFromStore = useSelector((state:RootStateTypes) => state?.alertsState?.alertsList, shallowEqual);
  const mixedAlertColumns = useSelector((state:RootStateTypes) => state?.alertsState?.mixedAlertColumns, shallowEqual);
  const currentAlertData = useSelector((state:RootStateTypes) => state?.alertsState?.currentAlertData, shallowEqual);
  const pagePath = useSelector((state:RootStateTypes) => state.screenerState.pagePath, shallowEqual);

  const { updateStoreHandler } = useLastStateUpdateStore();

  const getDataForFilters = () => {
    if (!currentAlertData?.length) {
      return;
    }
    const filters:FiltersArray = [[[0, 0, []]]];

    currentAlertData.forEach((item:AlertData) => {
      if (!(filters[0][0][2] as Array<number>)?.includes((item[1] as SymbolAlert)?.sortIndex)) {
        (filters[0][0][2] as Array<number>)?.push((item[1] as SymbolAlert)?.sortIndex);
      }
    });
    if (JSON.stringify(filterMarket) !== JSON.stringify(filters)) {
      setAlertFilters(filters as number[][][][]);
      dispatch(setMarketsFilterWatchlistAction({ data: { filters } }));
    }
  };

  const handleSetActiveAlert = useCallback(
    (id:number) => {
      dispatch(setActiveAlert(id));
    },
    [],
  );

  const getDataForSymbol = (symbolShortName:string) => {
    const filteredSymbol = symbolsList?.filter((symbol: StoredSymbol) => symbol?.sym === symbolShortName);
    if (filteredSymbol?.length > 0) {
      return { icon: filteredSymbol[0]?.icon, sym: filteredSymbol[0]?.sym, sortIndex: filteredSymbol[0]?.index };
    }
    return { icon: '', sym: '', sortIndex: 0 };
  };

  const sortAlertHandler = (index:number) => {
    const array:ISortData[] = JSON.parse(JSON.stringify(sortData));
    const defaultItem = { sortValue: 1, indexElement: index };
    const currentIndex = array.findIndex((item:ISortData) => item.indexElement === index);
    const newSort = setSortAlertArray(array, defaultItem, currentIndex);

    dispatch(setAlertsSortData(newSort || []));
    updateStoreHandler(ALERT_TABLE_SORTING, newSort);
  };

  const sortAlertStatus = (index:number) => sortData?.find((item:ISortData) => item.indexElement === index);

  const clearAlertSortHandler = useCallback(() => {
    dispatch(setAlertsSortData([]));
    updateStoreHandler(ALERT_TABLE_SORTING, []);
  }, []);

  const setAlertItems = (checkedItem:Alert) => {
    setSelectedItems((prevState) => {
      const position = prevState.indexOf(checkedItem?.id);

      if (position !== -1) {
        const buffer = prevState;
        buffer.splice(position, 1);
        return [...buffer];
      }

      return [...prevState, checkedItem?.id];
    });
  };

  const setAllAlertItems = () => {
    if (selectedAlertItems?.length > 0) {
      setSelectedItems([]);
    } else {
      const buffer:number[] = [];
      currentAlertData.forEach((item:AlertData) => {
        buffer.push((item[0] as StatusAlert).id);
      });
      setSelectedItems([...buffer]);
    }
  };

  const getSymbolFormatedByAlertIds = useCallback(
    (alertIds:number[]) => {
      const alertsSymbols = alertListFromStore?.filter((alert:Alert) => alertIds?.includes(alert?.id));
      const formatedSymbolsData = alertsSymbols?.map((alert:Alert) => getDataForSymbol(alert?.symbol));
      return formatedSymbolsData;
    },
    [alertListFromStore],
  );

  const checkSelected = () => {
    let newSelectedItems = [...selectedAlertItems];
    const alertIds = alertListFromStore.map((alert:Alert) => alert?.id);
    newSelectedItems = newSelectedItems?.filter((selected) => alertIds.includes(selected));
    setSelectedItems(newSelectedItems);
  };

  const bulkRemoveAlerts = useCallback(
    () => {
      removeAlerts(selectedAlertItems);
      setSelectedItems([]);
    },
    [selectedAlertItems],
  );

  useEffect(() => {
    getAlertsNotificationList();
    getAlertsList();
    getAlertSettings();

    const interval = setInterval(() => {
      triggeredAlertsListener();
    }, NOTIFICATION_DELAY_TIMES.TICKS);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (selectedTab?.id === alertTab?.id && pagePath !== TABLE_SCREEN_PATH.SCREENER) {
      dispatch(setAlertsSortData(storedSorting || []));
    }
  }, []);

  useEffect(() => {
    if (
      selectedTab?.id === alertTab?.id
      && currentAlertData?.length
      && pagePath !== TABLE_SCREEN_PATH.SCREENER
    ) {
      getDataForFilters();
    }

    if (
      selectedTab?.id !== alertTab?.id
      && selectedAlertItems.length > 0
      && pagePath !== TABLE_SCREEN_PATH.SCREENER
    ) {
      setSelectedItems([]);
    }
  }, [selectedTab, pagePath]);

  useEffect(() => {
    if (selectedTab?.id === alertTab?.id && pagePath !== TABLE_SCREEN_PATH.SCREENER) {
      getDataForFilters();
      checkSelected();
      if (sortData.length) {
        dispatch(setTableSorted(true));
      } else {
        dispatch(setTableSorted(false));
      }
    }
  }, [alertListFromStore, alertTab, selectedTab, sortData, alertColumns, pagePath, currentAlertData]);

  return {
    currentAlertData,
    alertFilters,
    sortAlertHandler,
    sortAlertStatus,
    clearAlertSortHandler,
    setAlertItems,
    selectedAlertItems,
    setAllAlertItems,
    sortData,
    getSymbolFormatedByAlertIds,
    activeAlert,
    handleSetActiveAlert,
    mixedAlertColumns,
    bulkRemoveAlerts,
  };
};

export default useAlertsTableWorker;
