import * as React from 'react';
import styles from './sass/TargeList.module.scss';
import { ReactComponent as ChevronRight } from '../../../../assets/images/icons/chevronRight.svg';
import Button from '../../../Button/Button';

const colors = {
  blue: styles.blue,
  yellow: styles.yellow,
  red: styles.red,
  black: styles.black,
};

export interface ITargetBottomMenuItem {
  caption: string
  icon: React.FunctionComponent
  mouseEnterHandler?: () => void,
  mouseLeaveHandler?: () => void,
  onClick?: (e?: MouseEvent) => void
  hasSubMenu?: boolean
  iconColor?: 'red' | 'yellow' | 'blue' | 'black'
  classNames?: string
}

const TargetBottomMenuItem = ({
  caption,
  icon: Icon,
  iconColor = 'blue',
  onClick = () => { },
  mouseEnterHandler = () => { },
  mouseLeaveHandler = () => { },
  hasSubMenu = false,
  classNames = '',
}: ITargetBottomMenuItem): JSX.Element => (
  <Button
    handleClick={onClick}
    mouseEnterHandler={mouseEnterHandler}
    mouseLeaveHandler={mouseLeaveHandler}
    buttonClass={styles.menuItem}
  >
    <div
      className={`${styles.iconWrapper} ${colors[iconColor]} ${classNames}`}
    >
      <Icon />
    </div>
    {caption}
    {hasSubMenu
      ? (
        <>
          <div className={styles.filler} />
          <div className={`${styles.chevronWrapper} 
          ${iconColor === 'black' && colors[iconColor]}`}
          >
            <ChevronRight />
          </div>
        </>
      )
      : null}
  </Button>
);

export default TargetBottomMenuItem;
