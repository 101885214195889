import { useEffect } from 'react';

const useViewListener = (setUpdateSocket) => {
  const socketUpdate = () => {
    if (!document.hidden) {
      // eslint-disable-next-line no-console
      console.log('visibilitychange ==>');
      setUpdateSocket();
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', socketUpdate);
    return () => {
      document.removeEventListener('visibilitychange', socketUpdate);
    };
  }, []);
};

export default useViewListener;
