import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/GetPremiumButton.module.scss';
import Button from '../Button/Button';
import { ReactComponent as DiamondIcon } from '../../assets/images/icons/screener/diamond.svg';

const GetPremiumButton = ({ handleRedirect }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.btnWrap}>
      <Button
        type="button"
        buttonClass={styles.getPremiumButton}
        handleClick={handleRedirect}
      >
        <DiamondIcon
          className={styles.icon}
        />
        <p> 
          {t('getPremium')}
        </p>
      </Button>
    </div>
  );
};

GetPremiumButton.propTypes = {
  handleRedirect: PropTypes.func.isRequired,
};

export default GetPremiumButton;
