import React from 'react';
import { Tooltip } from '@mui/material';
import styles from '../sass/WidgetCharts.module.scss';
import { IScreenerDropDown } from '../types/WidgetChartsInterfaces';
import { ReactComponent as ClearButton } from '../../../assets/images/icons/removeScreen.svg';
import { ReactComponent as IconStroke } from '../../../assets/images/icons/screener/color_stroke.svg';
import ScreenerSelector from './ScreenerSelector';
import { DROPDOWN_STYLES, SelectedScreenerTypes } from '../types/WidgetChartsEnums';
import { useTheme } from '../../../utils/hooks/useTheme';
import getInfoTooltip from '../../../utils/getInfoTooltip';
import ThemeVariants from '../../../constants/theme';
import { ScreenerTooltipDarkStyles, ScreenerTooltipStyles } from '../../Screener/ScreenerTooltipStyles';
import useWidgetHeaderHelper from '../hook/useWidgetHeaderHelper';

const ScreenerDropDown = ({
  screenerStates,
  setScreenerStates,
  selectedType,
  setDefaultFilter,
  screenerSearch,
  dropDownRef,
  isEditable
}: IScreenerDropDown): React.ReactElement => {
  const { theme } = useTheme();
  const { getWidgetStyles, getFolderWidgetStyles } = useWidgetHeaderHelper(
    screenerStates.screenerIsOpen,
    DROPDOWN_STYLES.WIDGET_WIDTH,
    dropDownRef,
  );
  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setScreenerStates((prev) => ({
      ...prev,
      screenerIsOpen: false,
      dataPointsXIsOpen: false,
      dataPointsYIsOpen: false,
      dataPointsZIsOpen: false,
      folderMenuIsOpen: -1,
      selectedScreener: SelectedScreenerTypes.SCREENERPRESET,
      screenerId: null,
    }));
    setDefaultFilter();
  };
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setScreenerStates((prev) => ({
      ...prev,
      screenerIsOpen: !prev.screenerIsOpen,
      dataPointsXIsOpen: false,
      dataPointsYIsOpen: false,
      dataPointsZIsOpen: false,
      folderMenuIsOpen: -1,
    }));
  };

  return (
    <div className={styles.performanceChartDropdown}>
      <button onMouseDown={(e) => isEditable && handleOpen(e)} type="button" className={styles.screenerButton}>
        <Tooltip
          arrow
          componentsProps={theme === ThemeVariants.LIGHT_MODE ? ScreenerTooltipStyles : ScreenerTooltipDarkStyles}
          title={(
            <div>
              {getInfoTooltip(
                screenerStates.selectedScreenerTitle || 'Screener',
                screenerStates.selectedScreenerTitle || 'Screener'
              )}
            </div>
          )}
        >
          <span className={styles.titleText}>{screenerStates.selectedScreenerTitle || 'Screener'}</span>
        </Tooltip>
        {screenerStates.selectedScreenerTitle && isEditable
          && (
            <div
              className={styles.crossIconChevron}
              role="button"
              tabIndex={0}
              onMouseDown={(e) => handleClear(e)}
              aria-label="clear"
            >
              <ClearButton />
            </div>
          )}
        {isEditable
          && <IconStroke className={`${styles.dropdownIconChevron} ${screenerStates.screenerIsOpen && styles.open}`} />}
      </button>
      <ScreenerSelector
        selectedType={selectedType}
        screenerStates={screenerStates}
        setScreenerStates={setScreenerStates}
        screenerSearch={screenerSearch}
        widgetStyle={getWidgetStyles()}
        folderWidgetStyle={getFolderWidgetStyles()}
        theme={theme}
      />
    </div>
  );
};

export default ScreenerDropDown;
