import React from 'react';

import styles from './sass/ConfirmModal.module.scss';
import TextField from '../TextField/TextField';

type ModalProps = {
  title: string;
  body: string;
  description: string;
  confirmCaption: string;
  declineCaption: string;
  confirmHandler: () => void;
  declineHandler: () => void;
  isCentered?: boolean,
};
const GeneralConfirmModal = ({
  title,
  body,
  description,
  confirmCaption,
  declineCaption,
  confirmHandler,
  declineHandler,
  isCentered
}: ModalProps): React.ReactElement => (
  <div className={styles.wrapper}>
    <div className={styles.contentWrapper}>
      <div className={`${styles.titleContentWrapper} ${isCentered && styles.centeredText}`}>
        <TextField
          styleType="confirmModalTitle"
          text={title}
        />
      </div>
      <div className={`${styles.textContentWrapper} ${isCentered && styles.centeredText}`}>
        <TextField
          styleType="confirmModal"
          text={body}
        />
        <TextField
          styleType="confirmModal"
          text={description}
        />
      </div>
      <div className={`${styles.buttonContentWrapper} ${isCentered && styles.centeredText}`}>
        <div
          className={styles.buttonNo}
          aria-hidden="true"
          onClick={declineHandler}
          onTouchStart={declineHandler}
        >
          <TextField text={declineCaption} styleType="noOverwriteButton" />
        </div>
        <div
          className={styles.buttonYes}
          aria-hidden="true"
          onClick={confirmHandler}
          onTouchStart={confirmHandler}
        >
          <TextField text={confirmCaption} styleType="applyButton" />
        </div>
      </div>
    </div>
  </div>
);

export default GeneralConfirmModal;
