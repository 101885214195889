import React, { useState, useEffect, useMemo } from 'react';

import FilterLayout from '../../../layout/FilterLayout/FilterLayout';
import Checkbox from '../../Checkbox/Checkbox';
import NoOptions from '../../NoOptions/NoOptions';
import { findIndexItem } from '../../../utils/helpers';
import filters from '../../../constants/filters';
import { TCommonItem, TValueItem } from '../Types';

interface IFilterCheckbox {
  title: string;
  setCheckHandler: (value: (number | number[])[]) => void;
  favoriteActive: boolean;
  addFavorite: () => void;
  columnId: number;
  disabled: boolean;
  noOptionsAvailable: string;
  disabledHeart: boolean;
  description: string;
  category: string;
  stateFiltersCheckbox: TCommonItem;
}

const FilterCheckbox = ({
  title,
  setCheckHandler,
  favoriteActive,
  addFavorite,
  columnId,
  disabled,
  noOptionsAvailable,
  disabledHeart,
  description,
  category,
  stateFiltersCheckbox
}: IFilterCheckbox): React.ReactElement => {
  const [listOption, setListOption] = useState<TValueItem[]>([]);

  useEffect(() => {
    setListOption(stateFiltersCheckbox?.fVal);
  }, [stateFiltersCheckbox]);

  const addFilter = (newItems: TValueItem[]): void => {
    setListOption(newItems);
    const sortArray = newItems.filter((el) => el.checked === true);
    const itemsArray = sortArray.map((item) => item.value);
    setCheckHandler([columnId, 0, itemsArray as number[]]);
  };

  const checkHandler = (id: number): void => {
    const newItems = listOption.map((item) => ({ ...item }));
    if (newItems[0].title === filters.yes || newItems[0].title === filters.no) {
      newItems[0].checked = id === 1 ? !newItems[0].checked : false;
      newItems[1].checked = id === 0 ? !newItems[1].checked : false;
    } else {
      const indexItem = findIndexItem(newItems, 'value', id);
      if (indexItem !== -1) {
        newItems[indexItem].checked = !newItems[indexItem].checked;
      }
    }
    setListOption(newItems);
    addFilter(newItems);
  };

  return useMemo(() => (
    <FilterLayout
      id={columnId}
      title={title}
      columnId={columnId}
      addButtonHandler={addFavorite}
      favoriteActive={favoriteActive}
      disabled={disabled}
      description={description}
      category={category}
      disabledHeart={disabledHeart}
    >
      <>
        {listOption.length > 0 ? listOption.map((item, index) => (
          <Checkbox
            key={[item.title, index].join('_')}
            id={[item.title, columnId].join('_')}
            additionalClass="checkboxLabel"
            name={item.title}
            checked={(listOption.length
              && listOption[
                listOption.findIndex((el) => el.title === item.title)]?.checked)
              || false}
            styleInput="checkboxFilters"
            changeHandler={() => checkHandler(item.value as number)}
            textStyle="checkboxText"
            title={item.title}
          />
        )) : (
          <NoOptions text={noOptionsAvailable} />
        )}
      </>
    </FilterLayout>
  ), [
    listOption,
    stateFiltersCheckbox,
    setCheckHandler
  ]);
};

export default FilterCheckbox;
