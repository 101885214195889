import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/DataPanel.module.scss';
import DataPanelContentItem from './DataPanelContentItem';
import TextWithTooltip from './TextWithTooltip';

const DataPanelContentCartItem = ({
  data,
  dataPoints,
  screenerId,
  getPointData,
  symbolIndex,
}) => {
  return (
    <div
      className={styles.dataPanelContentCard}
    >
      <div className={styles.dataPanelContentCardHeader}>
        <div className={`${styles.dataPanelContentCardHeaderTitle} ${styles.FullWidth}`}>
          <TextWithTooltip title={data.title} />
        </div>
      </div>
      <div
        className={styles.dataPanelContentCardItems}
      >
        {data.columns.map((item) => (
          <DataPanelContentItem
            key={[item, 'showItem'].join('_')}
            screenerId={screenerId}
            itemId={item}
            staticData={dataPoints[item]}
            dataPoint={getPointData(item)}
            symbolIndex={symbolIndex}
            getPointData={getPointData}
          />
        ))}
      </div>
    </div>
  );
};

DataPanelContentCartItem.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  dataPoints: PropTypes.shape({}).isRequired,
  getPointData: PropTypes.func.isRequired,
  symbolIndex: PropTypes.number.isRequired,
};

DataPanelContentCartItem.defaultProps = {
  screenerId: null,
};

export default DataPanelContentCartItem;
