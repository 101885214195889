import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/EmptyResultsContainer.module.scss';
import { ReactComponent as IconSearch } from '../../assets/images/icons/screener/search.svg';

const EmptyResultsContainer = ({ noResultLabel, noResultTitle }) => (
  <div className={styles.noResult}>
    <span className={styles.icon}><IconSearch /></span>
    <span className={styles.label}>{noResultLabel}</span>
    <span className={styles.title}>{noResultTitle}</span>
  </div>
);

EmptyResultsContainer.propTypes = {
  noResultLabel: PropTypes.string,
  noResultTitle: PropTypes.string,
};

EmptyResultsContainer.defaultProps = {
  noResultLabel: '',
  noResultTitle: '',
};

export default EmptyResultsContainer;
