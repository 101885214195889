type TSectorIndustryData = {
  sector: string;
  industry: string;
};

interface ISectorIndustryCache {
  setDataHandler: (data: TSectorIndustryData, symbolIndex: number) => void;
  removeDataFromCache: (symbolIndex: number) => void;
  getDataFromCache: (symbolIndex: number) => TSectorIndustryData;
}

class SectorIndustryDataProcessor implements ISectorIndustryCache {
  data: {
    [key: number]: TSectorIndustryData;
  };

  constructor() {
    this.data = {};
  }

  setDataHandler(data: TSectorIndustryData, symbolIndex: number): void {
    if (!this.data[symbolIndex]) {
      this.data[symbolIndex] = data;
    }
  }

  removeDataFromCache(symbolIndex: number): void {
    if (this.data[symbolIndex]) {
      delete this.data[symbolIndex];
    }
  }

  getDataFromCache(symbolIndex: number): TSectorIndustryData {
    return this.data[symbolIndex];
  }
}

// eslint-disable-next-line import/prefer-default-export
export const SectorIndustryCache = new SectorIndustryDataProcessor();
