import React, {
  useState, useEffect, useRef, useMemo
} from 'react';

import { useTranslation } from 'react-i18next';
import FilterLayout from '../../layout/FilterLayout/FilterLayout';
import SelectDefault from './SelectDefault';
import NoOptions from '../NoOptions/NoOptions';
import { ReactComponent as SelectArrow } from '../../assets/images/icons/selectArrow.svg';
import styles from './sass/FilterMultiInclude.module.scss';
import TabsButton from '../TabsButton/TabsButton';
import constants from '../../constants/filters';
import ListOptionMultiCheck from './ListOptionMultiCheck';
import { findIndexItem } from '../../utils/helpers';
import {
  currentTabHandler,
  filterSearchItem,
  includeExcludeHelper,
  setIncludeExcludeHelper,
  setSelectedStatus
} from '../../utils/helperFilter';
import { handleOutsideClick, TMouseEvent } from '../../utils/handleOutsideClick';
import { TFilterItem, TValueItem } from '../FilterScreen/Types';

interface IFilterMultiInclude {
  filterItems: TValueItem[];
  columnId: number;
  title: string;
  addFavorite: () => void;
  favoriteActive: boolean;
  disabled: boolean;
  disabledHeart: boolean;
  noOptionsAvailable: string;
  tabLabel: string;
  type: string;
  tabLabelSecond: string;
  stateFiltersHandler: (array: (number | number[])[]) => void;
  stateFiltersChecked: TFilterItem;
  bottomFlag: boolean;
  searchFilter: string;
  description: string;
  category: string;
}

const FilterMultiInclude = ({
  filterItems,
  columnId,
  title,
  addFavorite,
  favoriteActive,
  disabled,
  disabledHeart,
  noOptionsAvailable,
  tabLabel,
  tabLabelSecond,
  stateFiltersHandler,
  stateFiltersChecked,
  bottomFlag,
  searchFilter,
  description,
  category
}:IFilterMultiInclude): React.ReactElement => {
  const { t } = useTranslation();
  const [active, setActive] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [includeListOptions, setIncludeListOption] = useState<TValueItem[]>([]);
  const [excludeListOptions, setExcludeListOption] = useState<TValueItem[]>([]);
  const [selectedIncludeItems, setSelectedIncludeItems] = useState<TValueItem[]>([]);
  const [selectedExcludeItems, setSelectedExcludeItems] = useState<TValueItem[]>([]);
  const [activeTab, setActiveTab] = useState<string>(constants.leftTab);
  const [activeMultiSelected, setActiveMultiSelected] = useState<boolean>(false);
  const inputItem = useRef<HTMLInputElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);

  const stateOptions = includeExcludeHelper(activeTab, constants, includeListOptions, excludeListOptions);
  const stateSelectedOptions = includeExcludeHelper(activeTab, constants, selectedIncludeItems, selectedExcludeItems);

  useEffect(() => {
    if (filterItems.length > 0) {
      setIncludeListOption(filterItems);
      setExcludeListOption(filterItems);
    }
  }, []);

  const refreshDataFilter = () => {
    const arrayIncludeSelected = stateFiltersChecked?.items?.filter((el) => el.checked === true);
    const arrayExcludeSelected = stateFiltersChecked?.itemsExclude?.filter((el) => el.checked === true);
    setSelectedIncludeItems(setIncludeExcludeHelper(arrayIncludeSelected as TValueItem[]));
    setSelectedExcludeItems(setIncludeExcludeHelper(arrayExcludeSelected as TValueItem[]));
  };

  useEffect(() => {
    const typeActiveFilter = searchFilter?.split(':');
    if (typeActiveFilter.length > 0 && +typeActiveFilter[2] === columnId) {
      const tab = currentTabHandler(typeActiveFilter, constants);
      setActiveTab(tab);
    }
  }, [searchFilter]);

  useEffect(() => {
    refreshDataFilter();
  }, [stateFiltersChecked]);

  const filterItemsFilter = (selectedItems: TValueItem[], initialState: TValueItem[]): TValueItem[] => {
    const newArray = JSON.parse(JSON.stringify(initialState)) as TValueItem[];
    selectedItems.forEach((item) => {
      const indexItem = findIndexItem(newArray, constants.value, item.value);
      if (indexItem !== -1) {
        newArray.splice(indexItem, 1);
      }
    });
    return newArray;
  };

  const filterState = (): void => {
    const newIncludeArray = filterItemsFilter(selectedExcludeItems, filterItems);
    const newExcludeArray = filterItemsFilter(selectedIncludeItems, filterItems);
    setIncludeListOption(newIncludeArray);
    setExcludeListOption(newExcludeArray);
  };

  const filterSearchState = (): void => {
    const newIncludeArray = filterItemsFilter(selectedExcludeItems, includeListOptions);
    const newExcludeArray = filterItemsFilter(selectedIncludeItems, excludeListOptions);
    setIncludeListOption(newIncludeArray);
    setExcludeListOption(newExcludeArray);
  };

  useEffect(() => {
    if (searchValue) {
      filterSearchState();
    } else {
      filterState();
    }
  }, [selectedIncludeItems, selectedExcludeItems]);

  const handlerOpenSelect = () => {
    setActive(!active);
    setSearchValue('');
  };

  const handlerCloseDropdown = () => {
    setActive(false);
    setSearchValue('');
  };

  const selectItem = (id: number, item: TValueItem) => {
    const newArray = JSON.parse(JSON.stringify(stateSelectedOptions)) as TValueItem[];
    const indexSelectedItem = findIndexItem(newArray, constants.value, id);
    if (indexSelectedItem === -1) {
      newArray.push(item);
    } else {
      newArray.splice(indexSelectedItem, 1);
    }
    const itemsArray = newArray.map((el) => el.value) as number[];
    if (activeTab === constants.leftTab) {
      setSelectedIncludeItems(newArray);
      stateFiltersHandler([columnId, 0, itemsArray]);
    } else {
      setSelectedExcludeItems(newArray);
      stateFiltersHandler([columnId, 4, itemsArray]);
    }
  };

  const checkHandler = (itemValue: number): void => {
    const indexItem = findIndexItem(stateOptions, constants.value, itemValue);
    if (indexItem !== -1) {
      selectItem(itemValue, stateOptions[indexItem]);
    }
  };

  const searchValueHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    setSelectedStatus(stateSelectedOptions, setActiveMultiSelected);
  }, [stateSelectedOptions]);

  useEffect(() => {
    if (searchValue === '') {
      filterState();
    } else {
      const resultItems = filterSearchItem(stateOptions, searchValue);
      if (activeTab === constants.leftTab) {
        setIncludeListOption(resultItems);
      } else {
        setExcludeListOption(resultItems);
      }
    }
  }, [searchValue, activeTab]);

  const outsideClickHandler = (event: TMouseEvent): void => {
    handleOutsideClick(event, handlerCloseDropdown, dropdown);
  };

  useEffect(() => {
    document.addEventListener('click', (event) => outsideClickHandler(event as TMouseEvent));
    return () => {
      document.removeEventListener('click', outsideClickHandler as EventListener);
      setActive(false);
      setSearchValue('');
    };
  }, []);

  return useMemo(() => (
    <FilterLayout
      id={columnId}
      title={title}
      columnId={columnId}
      addButtonHandler={addFavorite}
      favoriteActive={favoriteActive}
      disabled={disabled}
      description={description}
      category={category}
      disabledHeart={disabledHeart}
    >
      <>
        <TabsButton
          tabLabel={tabLabel}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabLabelSecond={tabLabelSecond}
        />
        { filterItems.length > 0 ? (
          <div className={styles.container} ref={dropdown}>
            <div ref={inputItem}>
              <SelectDefault
                clickSelect={handlerOpenSelect}
                Image={SelectArrow}
                active={active}
                label={`${t('select')} ${title}`}
                handleCheck={checkHandler}
                items={stateSelectedOptions}
                activeMultiSelected={activeMultiSelected}
                handlerCloseDropdown={handlerCloseDropdown}
              />
            </div>
            {active && (
              <ListOptionMultiCheck
                inputItem={inputItem?.current?.getBoundingClientRect()?.height as number}
                placeholder={`${t('searchBy')} ${title}`}
                searchValue={searchValue}
                searchValueHandler={searchValueHandler}
                listOptions={stateOptions}
                handleCheck={checkHandler}
                selectedItems={stateSelectedOptions}
                bottomFlag={bottomFlag}
                noOptionsAvailable={noOptionsAvailable}
              />
            )}
          </div>
        ) : (
          <NoOptions text={noOptionsAvailable} />
        )}
      </>
    </FilterLayout>
  ), [
    active,
    searchValue,
    includeListOptions,
    excludeListOptions,
    selectedIncludeItems,
    selectedExcludeItems,
    activeTab,
    activeMultiSelected,
    stateFiltersChecked,
    searchFilter,
    stateFiltersHandler
  ]);
};

export default FilterMultiInclude;
