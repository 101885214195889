import { TEMPLATE_TYPE } from '../../../constants/screener';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';
import { TWidgetQueryState } from '../../../utils/Types';
import { IGetWidgetValues, IInitHelper, ISetInit } from '../types/WidgetChartsInterfaces';
import {
  PerformanceDataSet, SocketDataResponse, TDataPoint, WidgetLastState
} from '../types/WidgetChartsypes';

const noDataPoint = { type: '', name: '' };

const findDataPoints = (
  widgetValues: WidgetLastState | undefined,
  columnsList: TDataPoint[],
  findY: boolean,
  findZ: boolean,
): IInitHelper => {
  const findDataPoint = columnsList.find((item: TDataPoint) => item.index === widgetValues?.datapointIndex)
    ?? noDataPoint;
  const findDataPointY = findY ? (columnsList.find(
    (item: TDataPoint) => item.index === widgetValues?.datapointYIndex
  ) ?? noDataPoint
  ) : noDataPoint;
  const findDataPointZ = findZ ? (columnsList.find(
    (item: TDataPoint) => item.index === widgetValues?.datapointZIndex
  ) ?? noDataPoint
  ) : noDataPoint;
  return {
    widgetValues, findDataPoint, findDataPointY, findDataPointZ
  };
};

export const initHelper = (
  widgetValues: WidgetLastState | undefined,
  columnsList: TDataPoint[],
  findY: boolean,
  findZ: boolean,
): IInitHelper => {
  if (widgetValues) {
    return findDataPoints(widgetValues, columnsList, findY, findZ);
  }
  return {
    widgetValues: undefined,
    findDataPoint: noDataPoint,
    findDataPointY: noDataPoint,
    findDataPointZ: noDataPoint
  };
};

export const initPresetHelper = (
  widgets: Widget[],
  itemid: string,
  columnsList: TDataPoint[],
  findY: boolean,
  findZ: boolean,
  defaultScreenId: number,
): IInitHelper => {
  const findData = widgets.find((child) => child.id === itemid);
  if (findData) {
    return findDataPoints({
      id: itemid,
      sortBy: findData?.sortBy ?? 1,
      screenerId: findData?.screenerId ?? defaultScreenId,
      datapointIndex: findData?.datapointIndex ?? null,
      datapointYIndex: findData?.datapointYIndex ?? null,
      datapointZIndex: findData?.datapointZIndex ?? null,
      filter: [],
    }, columnsList, findY, findZ);
  }
  return {
    widgetValues: undefined,
    findDataPoint: noDataPoint,
    findDataPointY: noDataPoint,
    findDataPointZ: noDataPoint
  };
};

export const sortOrder = (data: PerformanceDataSet[], sortType: number): PerformanceDataSet[] => {
  if (sortType === 1) {
    return data.sort((a, b) => b.value - a.value);
  }
  return data.sort((a, b) => a.value - b.value);
};

export const dataCallback = (
  resp: SocketDataResponse,
  widget: Widget,
  cb: (resp: SocketDataResponse, widget: Widget) => void,
  prepareDataHandler: (resp: SocketDataResponse) => void,
): void => {
  if (resp?.messageId === Number(widget?.id) && resp?.screenerId) {
    cb(resp, widget);
    prepareDataHandler(resp);
  }
};

export const dataCallbackPatch = (
  resp: SocketDataResponse,
  widget: Widget,
  cb: (resp: SocketDataResponse, widget: Widget) => void,
  prepareDataHandler: (resp: SocketDataResponse) => void,
): void => {
  if (resp?.messageId === Number(widget?.id)) {
    if (resp?.screenerId) {
      cb(resp, widget);
      prepareDataHandler(resp);
    }
  }
};

export const dataCallbackAll = (
  resp: SocketDataResponse,
  widget: Widget,
  widgetQueriesRef: React.MutableRefObject<{ [key: string]: TWidgetQueryState } | null>,
  prepareDataHandler: (resp: SocketDataResponse) => void,
): void => {
  if (widgetQueriesRef?.current) {
    const currentWidgetQueryId = widgetQueriesRef?.current[Number(widget.id)];
    if (resp?.screenerId === currentWidgetQueryId?.screenerId) {
      prepareDataHandler(resp);
    }
  }
};

const setInit: ISetInit = (
  values,
  selectedDashboard,
  columnsList,
  setFilter,
  initialWidget,
  settings,
): void => {
  if (selectedDashboard?.type === TEMPLATE_TYPE.DASHBOARDS_PRESET) {
    const {
      widgetValues, findDataPoint, findDataPointY, findDataPointZ
    } = initPresetHelper(selectedDashboard.data.widgets,
      settings.itemId.toString(), columnsList, settings.findY, settings.findZ, settings.defaultScreenId);
    if (widgetValues && widgetValues?.screenerId && widgetValues?.datapointIndex) {
      setFilter(
        findDataPoint,
        findDataPointY,
        findDataPointZ,
        {
          id: widgetValues.id,
          sortBy: widgetValues.sortBy,
          datapointIndex: widgetValues?.datapointIndex ?? null,
          screenerId: widgetValues.screenerId,
          datapointYIndex: widgetValues?.datapointYIndex ?? null,
          datapointZIndex: widgetValues?.datapointZIndex ?? null,
          filter: [],
        }
      );
    } else {
      initialWidget();
    }
  } else {
    const {
      widgetValues, findDataPoint, findDataPointY, findDataPointZ
    } = initHelper(values, columnsList, true, true);
    if (widgetValues) {
      setFilter(
        findDataPoint,
        findDataPointY,
        findDataPointZ,
        widgetValues
      );
    } else {
      initialWidget();
    }
  }
};

export const getWidgetValues: IGetWidgetValues = (
  widget, lastState,
  selectedDashboard, columnsList, setFilter,
  initialWidget, settings,
) => {
  let widgetValues: WidgetLastState | undefined;
  if (widget?.datapointIndex) {
    widgetValues = {
      id: widget.id,
      sortBy: widget?.sortBy ?? 1,
      screenerId: widget?.screenerId ?? settings.defaultScreenId,
      datapointIndex: widget?.datapointIndex,
      datapointYIndex: widget?.datapointYIndex ?? null,
      datapointZIndex: widget?.datapointZIndex ?? null,
    };
  } else {
    const dashboard = lastState.find((item) => item.dashboardId === settings.itemId);
    widgetValues = dashboard?.widgets.find((child) => child.id === widget.id);
  }
  setInit(
    widgetValues,
    selectedDashboard,
    columnsList,
    setFilter,
    initialWidget,
    {
      itemId: widget.id,
      defaultScreenId: settings.defaultScreenId,
      findY: settings.findY,
      findZ: settings.findZ,
    }
  );
};
