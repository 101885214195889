import { CustomIndicator, IContext, IPineSeries, IPineStudyResult, LibraryPineStudy, PineJS, PineJSStd, RawStudyMetaInfoId } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';
import { inputs, defaultInputs } from './metainfo/inputs';
import { defaultStyles, styles } from './metainfo/styles';
import { plots } from './metainfo/plots';

function calculateMA(context: IContext, mPineJSStd: PineJSStd, type: string, length: number, series: IPineSeries): number {
  if (type === 'SMA') {
    return mPineJSStd.sma(series, length, context);
  }

  return mPineJSStd.ema(series, length, context);
}

export default function keyMovingAverages(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Key Moving Averages',
    metainfo: {
      defaults: {
        inputs: defaultInputs,
        precision: 2,
        styles: defaultStyles
      },
      description: 'DV - Key Moving Averages',
      id: 'DVKMA@tv-basicstudies-1' as RawStudyMetaInfoId,
      inputs: inputs,
      isCustomIndicator: true,
      is_price_study: true,
      plots: plots,
      shortDescription: 'DV - Key Moving Averages',
      format: {
        type: 'price'
      },
      styles: styles
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs) => {

        this._context = context;

        const [
          // Intraday Moving Averages
          IntradayMA1Enabled,
          IntradayMA1Length,
          IntradayMA1Type,
          IntradayMA2Enabled,
          IntradayMA2Length,
          IntradayMA2Type,
          IntradayMA3Enabled,
          IntradayMA3Length,
          IntradayMA3Type,
          IntradayMA4Enabled,
          IntradayMA4Length,
          IntradayMA4Type,
          IntradayMA5Enabled,
          IntradayMA5Length,
          IntradayMA5Type,

          // Daily Moving Averages
          DailyMA1Enabled,
          DailyMA1Length,
          DailyMA1Type,
          DailyMA2Enabled,
          DailyMA2Length,
          DailyMA2Type,
          DailyMA3Enabled,
          DailyMA3Length,
          DailyMA3Type,
          DailyMA4Enabled,
          DailyMA4Length,
          DailyMA4Type,
          DailyMA5Enabled,
          DailyMA5Length,
          DailyMA5Type,
          DailyMA6Enabled,
          DailyMA6Length,
          DailyMA6Type,
          DailyMA7Enabled,
          DailyMA7Length,
          DailyMA7Type,

          // Weekly Moving Averages
          WeeklyMA1Enabled,
          WeeklyMA1Length,
          WeeklyMA1Type,
          WeeklyMA2Enabled,
          WeeklyMA2Length,
          WeeklyMA2Type,
          WeeklyMA3Enabled,
          WeeklyMA3Length,
          WeeklyMA3Type,
          WeeklyMA4Enabled,
          WeeklyMA4Length,
          WeeklyMA4Type,
          WeeklyMA5Enabled,
          WeeklyMA5Length,
          WeeklyMA5Type,

          // Monthly Moving Averages
          MonthlyMA1Enabled,
          MonthlyMA1Length,
          MonthlyMA1Type,
          MonthlyMA2Enabled,
          MonthlyMA2Length,
          MonthlyMA2Type,
          MonthlyMA3Enabled,
          MonthlyMA3Length,
          MonthlyMA3Type,
          MonthlyMA4Enabled,
          MonthlyMA4Length,
          MonthlyMA4Type,
          MonthlyMA5Enabled,
          MonthlyMA5Length,
          MonthlyMA5Type,

        ] = getInputArray({ inputs, length: 66 });

        const isDaily = mPineJS.Std.period(this._context) === '1D';
        const isWeekly = mPineJS.Std.period(this._context) === '1W';
        const isMonthly = mPineJS.Std.period(this._context) === '1M';
        const isIntraday = mPineJS.Std.isintraday(this._context);
        const closeSeries = this._context.new_var(mPineJS.Std.close(this._context));

        const intradayMA1 = isIntraday && IntradayMA1Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          IntradayMA1Type,
          IntradayMA1Length,
          closeSeries
        ) : null;

        const intradayMA2 = isIntraday && IntradayMA2Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          IntradayMA2Type,
          IntradayMA2Length,
          closeSeries
        ) : null;

        const intradayMA3 = isIntraday && IntradayMA3Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          IntradayMA3Type,
          IntradayMA3Length,
          closeSeries
        ) : null;

        const intradayMA4 = isIntraday && IntradayMA4Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          IntradayMA4Type,
          IntradayMA4Length,
          closeSeries
        ) : null;

        const intradayMA5 = isIntraday && IntradayMA5Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          IntradayMA5Type,
          IntradayMA5Length,
          closeSeries
        ) : null;

        const dailyMA1 = isDaily && DailyMA1Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA1Type,
          DailyMA1Length,
          closeSeries
        ) : null;

        const dailyMA2 = isDaily && DailyMA2Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA2Type,
          DailyMA2Length,
          closeSeries
        ) : null;


        const dailyMA3 = isDaily && DailyMA3Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA3Type,
          DailyMA3Length,
          closeSeries
        ) : null;

        const dailyMA4 = isDaily && DailyMA4Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA4Type,
          DailyMA4Length,
          closeSeries
        ) : null;

        const dailyMA5 = isDaily && DailyMA5Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA5Type,
          DailyMA5Length,
          closeSeries
        ) : null;

        const dailyMA6 = isDaily && DailyMA6Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA6Type,
          DailyMA6Length,
          closeSeries
        ) : null;

        const dailyMA7 = isDaily && DailyMA7Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          DailyMA7Type,
          DailyMA7Length,
          closeSeries
        ) : null;

        const weeklyMA1 = isWeekly && WeeklyMA1Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          WeeklyMA1Type,
          WeeklyMA1Length,
          closeSeries
        ) : null;

        const weeklyMA2 = isWeekly && WeeklyMA2Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          WeeklyMA2Type,
          WeeklyMA2Length,
          closeSeries
        ) : null;

        const weeklyMA3 = isWeekly && WeeklyMA3Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          WeeklyMA3Type,
          WeeklyMA3Length,
          closeSeries
        ) : null;

        const weeklyMA4 = isWeekly && WeeklyMA4Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          WeeklyMA4Type,
          WeeklyMA4Length,
          closeSeries
        ) : null;

        const weeklyMA5 = isWeekly && WeeklyMA5Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          WeeklyMA5Type,
          WeeklyMA5Length,
          closeSeries
        ) : null;

        const monthlyMA1 = isMonthly && MonthlyMA1Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          MonthlyMA1Type,
          MonthlyMA1Length,
          closeSeries
        ) : null;

        const monthlyMA2 = isMonthly && MonthlyMA2Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          MonthlyMA2Type,
          MonthlyMA2Length,
          closeSeries
        ) : null;

        const monthlyMA3 = isMonthly && MonthlyMA3Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          MonthlyMA3Type,
          MonthlyMA3Length,
          closeSeries
        ) : null;

        const monthlyMA4 = isMonthly && MonthlyMA4Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          MonthlyMA4Type,
          MonthlyMA4Length,
          closeSeries
        ) : null;

        const monthlyMA5 = isMonthly && MonthlyMA5Enabled ? calculateMA(
          this._context,
          mPineJS.Std,
          MonthlyMA5Type,
          MonthlyMA5Length,
          closeSeries
        ) : null;

        return [
          intradayMA1 ?? NaN,
          intradayMA2 ?? NaN,
          intradayMA3 ?? NaN,
          intradayMA4 ?? NaN,
          intradayMA5 ?? NaN,
          dailyMA1 ?? NaN,
          dailyMA2 ?? NaN,
          dailyMA3 ?? NaN,
          dailyMA4 ?? NaN,
          dailyMA5 ?? NaN,
          dailyMA6 ?? NaN,
          dailyMA7 ?? NaN,
          weeklyMA1 ?? NaN,
          weeklyMA2 ?? NaN,
          weeklyMA3 ?? NaN,
          weeklyMA4 ?? NaN,
          weeklyMA5 ?? NaN,
          monthlyMA1 ?? NaN,
          monthlyMA2 ?? NaN,
          monthlyMA3 ?? NaN,
          monthlyMA4 ?? NaN,
          monthlyMA5 ?? NaN,
        ];
      };
    }
  };
};
