import { useState } from 'react';
import { DragWidgetTypes } from '../types/DashboardEnums';
import { IUseEnabledDragMenuHook } from '../types/DashboardInterfaces';

const useEnabledDragMenu: IUseEnabledDragMenuHook = () => {
  const [enableScreenDrag, setEnableScreenDrag] = useState(true);
  const [enableTVDrag, setEnableTVDrag] = useState(true);
  const [enableStatsDrag, setEnableStatsDrag] = useState(true);
  const [enablePSCDrag, setEnablePSCDrag] = useState(true);
  const [enableWidgetChartDrag, setEnableWidgetChartDrag] = useState(true);

  const setEnableDrag = (value: boolean, type: DragWidgetTypes): void => {
    switch (type) {
      case DragWidgetTypes.SCREEN:
        setEnableScreenDrag(value);
        break;
      case DragWidgetTypes.TV:
        setEnableTVDrag(value);
        break;
      case DragWidgetTypes.STATS:
        setEnableStatsDrag(value);
        break;
      case DragWidgetTypes.POSITIONSIZE:
        setEnablePSCDrag(value);
        break;
      case DragWidgetTypes.BUBBLE:
      case DragWidgetTypes.HEATMAP:
      case DragWidgetTypes.PERFORMANCE:
        setEnableWidgetChartDrag(value);
        break;
      default:
        break;
    }
  };

  return {
    enableScreenDrag,
    enableTVDrag,
    enableStatsDrag,
    enablePSCDrag,
    enableWidgetChartDrag,
    setEnableDrag,
  };
};

export default useEnabledDragMenu;
