export enum FrameType {
  '1 min' = '1',
  '3 min' = '3',
  '5 min' = '5',
  '10 min' = '10',
  '15 min' = '15',
  '30 min' = '30',
  '39 min' = '39',
  '65 min' = '65',
  '78 min' = '78',
  '130 min' = '130',
  '195 min' = '195',
  '1h' = '1h',
  '2h' = '2h',
  '4h' = '4h',
  'Daily' = '1D',
  'Weekly' = '1W',
  '1 Month' = '1M',
  '3 Month' = '3M',
  '6 Month' = '6M',
  '1 Year' = '12M',
}

export enum ChartType {
  'Bars' = '0',
  'Candlestick' = '1',
  'Line' = '2',
  'Area' = '3',
  'Heiken Ashi' = '8',
  'Hollow Candles' = '9',
  'Baseline' = '10',
  'High-Low' = '12',
  'Column' = '13',
  'Line With Markers' = '14',
  'Stepline' = '15',
  'HLC Area' = '16',
  'Renko' = '4',
  'Kagi' = '5',
  'LineBreak' = '7'
}

export enum SortType {
  'Alphabetically' = 'Alphabetically',
  'RV20' = 'RV20',
  'DCR' = 'DCR',
  'Price % Change' = 'Price % Change'
}
