import React from 'react';

import Button from '../Button/Button';
import styles from './sass/ManageAlerts.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import { IConfirmAlert } from './interfaces/IConfirmAlert';

const ConfirmDialog: React.FC<IConfirmAlert> = ({
  headerTitle,
  labelCancel,
  labelConfirm,
  actionNext,
  actionCancel,
  children
}) => (
  <div className={styles.confirmDialogWraper}>
    <div className={styles.confirmDialog}>
      <div className={styles.confirmDialogHeader}>
        {headerTitle}
        <CloseIcon onClick={actionCancel} />
      </div>
      <div className={styles.confirmDialogContent}>
        {children}
      </div>
      <div className={styles.confirmDialogAction}>
        <Button handleClick={actionCancel} buttonClass={styles.confirmDialogButton}>
          {labelCancel}
        </Button>
        <Button handleClick={actionNext} buttonClass={styles.confirmDialogButton}>
          {labelConfirm}
        </Button>
      </div>
    </div>
  </div>
);

export default ConfirmDialog;
