import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import stylesModal from './sass/NewScreenModals.module.scss';
import { ReactComponent as FolderIcon } from '../../assets/images/icons/screener/folder.svg';
import TextField from '../TextField/TextField';

const MoveToFolderItem = ({
  handleItemClick,
  item,
  itemStatus,
}) => {
  const { t } = useTranslation();
  return (
    <div
      key={item.id}
      aria-hidden="true"
      onMouseDown={() => handleItemClick(item)}
      className={stylesModal[itemStatus ? 'itemFolderWrapperActive' : 'itemFolderWrapper']}
    >
      <FolderIcon />
      <div className={stylesModal.moveToTitleWrapper}>
        <TextField
          text={item.title}
          styleType={itemStatus ? 'watchListTitleActive' : 'watchListTitle'}
        />
        <TextField
          text={`${item.items.length} ${t('columnset')}`}
          styleType={itemStatus ? 'watchListDescriptionActive' : 'watchListDescription'}
        />
      </div>
    </div>
  );
};
MoveToFolderItem.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  itemStatus: PropTypes.bool.isRequired
};

export default MoveToFolderItem;
