import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function netNewHighsLows(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Net New Highs/Lows',
    metainfo: {
      id: 'TL-netnewhighslows@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Net New Highs/Lows',
      description: 'DV - Net New Highs/Lows',
      is_price_study: false,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      defaults: {
        inputs: {
          in_0: 'NYSE',
          in_1: false,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#3A75E8',
                style: 0,
                width: 1
              },
              1: {
                color: '#E83AAD',
                style: 0,
                width: 1
              }
            }
          },
          palette_bg: {
            colors: {
              0: {
                color: '#3A75E8',
                style: 0,
                width: 1
              },
              1: {
                color: '#E83AAD',
                style: 0,
                width: 1
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 5,
            trackPrice: false,
            transparency: 0
          }
        }
      },
      inputs: [
        {
          defval: 'NYSE',
          id: 'in_0',
          name: 'Index',
          options: ['NYSE', 'NASDAQ', 'AMEX', 'USA'],
          type: 'text' as StudyInputType.Text
        },
        {
          defval: false,
          id: 'in_1',
          name: 'Background',
          type: 'bool' as StudyInputType.Bool
        }
      ],
      palettes: {
        palette_0: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'Color 0'
            },
            1: {
              name: 'Color 1'
            }
          },
        },
        palette_bg: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'Color 0'
            },
            1: {
              name: 'Color 1'
            }
          },
          valToIndex: {
            0: 0,
            1: 1
          }
        }
      },
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_2',
          palette: 'palette_bg',
          type: 'bg_colorer' as StudyPlotType.BgColorer,
        },
      ],
      styles: {
        plot_2: {
          title: 'Background'
        },
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Plot'
        }
      },
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context, inputs) => {
        this._context = context;
        const [in_0] = getInputArray({
          inputs,
          length: 1
        });
        this._context.new_sym(`NH-${in_0}`, mPineJS.Std.period(this._context));
        this._context.new_sym(`NL-${in_0}`, mPineJS.Std.period(this._context));
      };
      this.main = (context, inputs) => {
        this._context = context;
        this._context.select_sym(0);
        const mainSymbolTimeSeries = this._context.new_unlimited_var(this._context.symbol.time);
        // switch to the first requested symbol
        this._context.select_sym(1);
        // save the first series' time and close value
        var firstSymbolTimeSeries = this._context.new_var(this._context.symbol.time);
        var firstSeriesCloseSeries = this._context.new_var(mPineJS.Std.close(this._context));
        // switch to the second requested symbol
        this._context.select_sym(2);
        // save the second series' time and close value
        var secondSymbolTimeSeries = this._context.new_var(this._context.symbol.time);
        var secondSymbolCloseSeries = this._context.new_var(mPineJS.Std.close(this._context));
        // save series' time
        const nyse_new_highs = firstSeriesCloseSeries.adopt(firstSymbolTimeSeries, mainSymbolTimeSeries);
        const nyse_new_lows = secondSymbolCloseSeries.adopt(secondSymbolTimeSeries, mainSymbolTimeSeries);

        const nyse_net_new_high_new_lows = nyse_new_highs - nyse_new_lows;
        this._context.select_sym(0);

        var bgColor = NaN;
        const nyse_net_new_high_new_lows_series = context.new_unlimited_var(nyse_net_new_high_new_lows);
        if (inputs(1)) {
          if (
            nyse_net_new_high_new_lows_series.get(0) > 0 &&
            nyse_net_new_high_new_lows_series.get(1) > 0 &&
            nyse_net_new_high_new_lows_series.get(2) > 0
          ) {
            bgColor = 0;
          }
          if (
            nyse_net_new_high_new_lows_series.get(0) < 0 &&
            nyse_net_new_high_new_lows_series.get(1) < 0 &&
            nyse_net_new_high_new_lows_series.get(2) < 0) {
            bgColor = 1;
          }
        }

        return [
          nyse_net_new_high_new_lows,
          nyse_net_new_high_new_lows > 0 ? 0 : 1,
          bgColor,
        ];
      };
    }
  };
}
