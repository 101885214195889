import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { APIParams, ActiveTab } from '../types/EducationComponentsTypes';
import {
  resetArticlesList,
  resetTutorialsList,
  setArticlesList, setIsArticlesLoading, setIsTutorialsLoading, setTutorialsList,
  updateArticlesList,
  updateTutorialsList,
  setEventsList,
  updateWebinarsList,
  setWebinarsList,
  setAllCategories,
  resetCategories
} from '../../../store/education/slice';
import {
  ARTICLES, EVENTS, INITIAL_PARAMS,
  TUTORIAL_SLUG,
  TUTORIALS,
  WEBINAR_SLUG
} from '../types/EducationComponentsEnum';
import $api from '../../../http';
import { IUseEducation } from '../types/EducationComponentsInterfaces';
import { SAVE_USER_STATE_DELAY } from '../../../constants/screener';
import RootStateTypes from '../../../store/RootStateTypes';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import useAccount from '../../../utils/hooks/useAccount';
import sortEventsByDate from '../utils/utils';

const useEducation: IUseEducation = () => {
  const dispatch = useDispatch();
  const { updateUserState } = useAccount();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const debouncedUpdateLastState = useDebouncedCallback();
  const [articleParams, setArticleParams] = useState<APIParams>(INITIAL_PARAMS);
  const [tutorialParams, setTutorialParams] = useState<APIParams>(INITIAL_PARAMS);
  const [eventsParams, setEventsParams] = useState<APIParams>(INITIAL_PARAMS);
  const [activeTab, setActiveTab] = useState<ActiveTab>(ARTICLES);

  const openConfirmModal = useSelector((state: RootStateTypes) => state.accountState.openConfirmModal, shallowEqual);
  const updatedLastState = useSelector((state: RootStateTypes) => state.accountState.updatedLastState, shallowEqual);
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const lastStateId = useSelector((state: RootStateTypes) => state.accountState.lastStateId);
  const modalTypeWatchList = useSelector((state: RootStateTypes) => state.watchlistState.modalType, shallowEqual);
  const educationActiveTab = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.educationActiveTab, shallowEqual
  );
  const onTabChange = (value: ActiveTab) => {
    setActiveTab(value);
    updateStoreHandler('educationActiveTab', value);
  };

  const getArticles = (params: APIParams) => {
    try {
      dispatch(setIsArticlesLoading(true));
      $api.request({
        baseURL: process.env.REACT_APP_WP_API_URL,
        url: 'blogs',
        params: { ...params, categories: params.categories.toString(), levels: params.levels.toString() },
        method: 'GET'
      }).then((response) => {
        dispatch(params.isLoadMore ? updateArticlesList(response.data) : setArticlesList(response.data));
        dispatch(setIsArticlesLoading(false));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const getTutorials = (params: APIParams) => {
    try {
      dispatch(setIsTutorialsLoading(true));
      $api.request({
        baseURL: process.env.REACT_APP_WP_API_URL,
        url: 'webinars',
        params: {
          ...params,
          categories: params.categories.toString(),
          levels: params.levels.toString(),
          type: TUTORIAL_SLUG
        },
        method: 'GET'
      }).then((response) => {
        dispatch(params.isLoadMore ? updateTutorialsList(response.data) : setTutorialsList(response.data));
        dispatch(setIsTutorialsLoading(false));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const getWebinars = (params: APIParams) => {
    try {
      $api.request({
        baseURL: process.env.REACT_APP_WP_API_URL,
        url: 'webinars',
        params: {
          ...params,
          categories: params.categories.toString(),
          levels: params.levels.toString(),
          type: WEBINAR_SLUG
        },
        method: 'GET'
      }).then((response) => {
        dispatch(params.isLoadMore ? updateWebinarsList(response.data) : setWebinarsList(response.data));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const getFutureEvents = () => {
    try {
      $api.request({
        baseURL: process.env.REACT_APP_WP_API_URL,
        url: 'events',
        params: { params: INITIAL_PARAMS },
        method: 'GET'
      }).then((response) => {
        const data = sortEventsByDate(response.data);
        dispatch(setEventsList(data));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const getAllCategories = () => {
    try {
      $api.request({
        baseURL: process.env.REACT_APP_WP_API_URL,
        url: 'blogs/all-categories',
        method: 'GET'
      }).then((response) => {
        dispatch(setAllCategories(response.data));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    if ([ARTICLES, EVENTS, TUTORIALS].includes(educationActiveTab)) { setActiveTab(educationActiveTab); }
  }, [educationActiveTab]);

  useEffect(() => {
    if (updatedLastState) {
      debouncedUpdateLastState(updateUserState, SAVE_USER_STATE_DELAY, userLastState, lastStateId);
    }
  }, [userLastState]);

  useEffect(() => {
    getArticles(articleParams);
  }, [articleParams]);

  useEffect(() => {
    if (tutorialParams) {
      getTutorials(tutorialParams);
    }
  }, [tutorialParams]);

  useEffect(() => {
    if (eventsParams) {
      getWebinars(eventsParams);
    }
  }, [eventsParams]);

  useEffect(() => {
    getAllCategories();
    getFutureEvents();
    return () => {
      dispatch(resetArticlesList());
      dispatch(resetTutorialsList());
      dispatch(resetCategories());
    };
  }, []);

  return {
    articleParams,
    setArticleParams,
    tutorialParams,
    setTutorialParams,
    eventsParams,
    setEventsParams,
    activeTab,
    openConfirmModal,
    onTabChange,
    modalTypeWatchList,
  };
};

export default useEducation;
