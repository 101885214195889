import { shallowEqual, useSelector } from 'react-redux';

const usePrepareLastSymbolState = () => {
  const lastScreenerSymbol = useSelector((state) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual);
  const lastDeepListSymbol = useSelector((state) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual);
  const lastAlertsSymbol = useSelector((state) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual);
  const selectedTabId = useSelector((state) => state.accountState.userSettings.selectedTabId, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const searchValue = useSelector((state) => state.screenerState.searchValue, shallowEqual);

  const currentSymbolHandler = (isDeepList = false) => {
    if (selectedTabId === alertTab?.id && isDeepList) {
      return Object.keys(searchValue).length > 0 ? searchValue : lastAlertsSymbol;
    }
    if (isDeepList) {
      return Object.keys(searchValue).length > 0 ? searchValue : lastDeepListSymbol;
    }
    return Object.keys(searchValue).length > 0 ? searchValue : lastScreenerSymbol;
  };
  return { currentSymbolHandler };
};

export default usePrepareLastSymbolState;
