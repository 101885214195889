import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { FolderItemData, TDashboardItemsData } from '../../../utils/Types';
import Input from '../../Input/Input';
import { MAX_LENGTH_COUNT } from '../../../constants/screener';
import constants from '../../../constants/filters';

interface IDialogContentFolder {
  onChange: (item: TDashboardItemsData) => void,
  item: TDashboardItemsData,
  theme: string
}
const DialogContentScreener = ({
  onChange, item, theme,
}: IDialogContentFolder): React.ReactElement => {
  const { t } = useTranslation();
  const currentItem = item as FolderItemData;

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({
      ...currentItem,
      title: e.target.value
    });
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    onChange({
      ...currentItem,
      data: {
        ...currentItem.data,
        description: e.target.value
      }
    } as TDashboardItemsData);
  };

  const getPlaceholder = (itemType: string) => {
    switch (itemType) {
      case constants.deepList: {
        return t('watchlistName');
      }
      case constants.screener: {
        return t('placeholerScreenName');
      }
      case constants.dataPanel: {
        return t('dataPanelName');
      }
      case constants.columns: {
        return t('columnSetName');
      }
      default: {
        return t('placeholerScreenName');
      }
    }
  };

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={styles.dialogContentFieldItem}>
        <label className={styles.dialogContentFieldItemLabel} htmlFor="folderName">{getPlaceholder(item?.type)}</label>
        <Input
          type="text"
          autoFocus
          inputClass={styles.dialogContentFieldItemInput}
          handlerChange={handleChanges}
          placeholder={getPlaceholder(item?.type)}
          value={currentItem?.title || ''}
          maxLength={MAX_LENGTH_COUNT}
        />
      </div>
      <div className={styles.dialogContentFieldItem}>
        <label className={styles.dialogContentFieldItemLabel} htmlFor="folderName">{t('description')}</label>
        <textarea onChange={handleDescriptionChange} className={styles.dialogContentFieldItemTextarea}>
          {currentItem?.data?.description || ''}
        </textarea>
      </div>
    </div>
  );
};

export default DialogContentScreener;
