import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './sass/DefaultLink.module.scss';

const areEqual = (prevProps, nextProps) => {
  return prevProps.href === nextProps.href
    && prevProps.className === nextProps.className
    && prevProps.linkText === nextProps.linkText
    && prevProps.children === nextProps.children;
};

const DefaultLink = ({
  href, className, linkText, children
}) => (
  <Link
    to={href}
    className={`${styles.link} ${className}`}
  >
    { children }
    { linkText }
  </Link>
);

export default React.memo(DefaultLink, areEqual);

DefaultLink.defaultProps = {
  className: '',
  linkText: '',
  children: null,
};

DefaultLink.propTypes = {
  className: PropTypes.string,
  linkText: PropTypes.string,
  href: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
