import axios from 'axios';
import {
  checkTokenExpire, saveTokens
} from '../utils/helpers';
import { getFromLocalStorage } from '../utils/storageWorks';
import { ACCESS_TOKEN, RE_AUTH_QUERY } from '../constants/storage';

export const {
  REACT_APP_PUBLIC_API_URL,
  REACT_APP_PUBLIC_LIGHT_SERVER_URL,
  REACT_APP_PUBLIC_ALERTS
} = process.env;

let isRefreshing = false;
const refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (accessToken, refreshToken) => {
  refreshSubscribers.map((cb) => cb(accessToken));
  saveTokens(accessToken, refreshToken);
};

const $api = axios.create({
  baseURL: REACT_APP_PUBLIC_API_URL
});

$api.interceptors.request.use((config) => {
  const configRequest = config;
  if (!configRequest.headers.Authorization) {
    configRequest.headers.Authorization = `Bearer ${getFromLocalStorage(ACCESS_TOKEN)}`;
  }
  return configRequest;
});

const retryRequestWithInterval = async (error, retriesLeft = 3, attempt = 1) => {
  if (retriesLeft === 0) {
    throw error;
  }

  const isServerError = error.config && error.response && error.response.status >= 500;
  const isCancelledRequest = error.code === 'ECONNABORTED';

  if (isServerError || isCancelledRequest) {
    // eslint-disable-next-line no-console
    console.log(`Retrying last request. Retries left: ${retriesLeft}`);
    const interval = +`${attempt === 3 ? attempt + 1 : attempt}000`;

    await new Promise((resolve) => setTimeout(resolve, interval));
    try {
      return await $api.request(error.config);
    } catch (retryError) {
      return retryRequestWithInterval(retryError, retriesLeft - 1, attempt + 1);
    }
  }
  throw error;
};

$api.interceptors.response.use((response) => response,
  (error) => {
    const { config, response: { status } } = error;
    const originalRequest = config;
    if (status === 401 && !error.config._isRetry) {
      const checkToken = checkTokenExpire(getFromLocalStorage('refreshToken'));
      if (checkToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios.post(
            `${REACT_APP_PUBLIC_API_URL}auth/refresh-tokens`,
            {},
            {
              headers: {
                Authorization: `Bearer ${getFromLocalStorage('refreshToken')}`
              }
            }
          )
            .then((response) => {
              isRefreshing = false;
              onRefreshed(response.data.accessToken, response.data.refreshToken);
            })
            .catch(() => {
              if (process.env.REACT_APP_IS_AMPLIFY === 'false') {
                if (getFromLocalStorage('isWebView')) {
                  window?.ReactNativeWebView?.postMessage('logout');
                } else {
                  window.location.replace(`${process.env.REACT_APP_WP_URL}?${RE_AUTH_QUERY}`);
                }
              }
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((response) => {
            originalRequest.headers.Authorization = `Bearer ${response}`;
            resolve(axios(originalRequest));
          });
        });
      }
    }

    // eslint-disable-next-line no-underscore-dangle
    if (!error.config.__isRetryRequest) {
      // eslint-disable-next-line no-param-reassign,no-underscore-dangle
      error.config.__isRetryRequest = true;
      return retryRequestWithInterval(error);
    }

    return Promise.reject(error);
  });

export default $api;
