import React from 'react';
import PropTypes from 'prop-types';

import styles from './sass/ScreenerTable.module.scss';
import TableHeaderCell from './TableHeaderCell';
import { deepEqual } from '../../utils/objectHelper';
import ListTableHeaderCell from './components/ListTableHeaderCell';
import { LAST_ITEM_SCREENER_WIDTH } from '../../constants/screener';

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.columns, nextProps.columns)
    && prevProps.checked === nextProps.checked
    && prevProps.columns === nextProps.columns
    && prevProps.sortStatus === nextProps.sortStatus;
};

const ScreenerTableHeader = ({
  data,
  columns,
  setAllItems,
  checked,
  toggleIconHandler,
  sortHandler,
  sortStatus,
  columnsSize,
  updateColumnsSize,
  initColumnsSize,
  loaderStatus,
  setScrollLeft,
  firstItem,
  zIndexFirst,
  alertTable,
  setGridWidth,
  headerRef,
  showWeight,
  widgetResizeSelector,
  isWidget,
  isWatchlist,
}) => {
  return (
    <div
      className={`${styles.screenerTableWrapper} ${styles.maxWidth}`}
    >
      {data.length ? (
        <div
          ref={headerRef}
          className={styles.maxWidth}
        >
          {firstItem && (
            <TableHeaderCell
              column={firstItem}
              checked={checked}
              setAllItems={setAllItems}
              toggleIconHandler={toggleIconHandler}
              sortHandler={sortHandler}
              sortStatus={sortStatus}
              name={firstItem?.name}
              columnsSize={columnsSize}
              updateColumnsSize={updateColumnsSize}
              initColumnsSize={initColumnsSize}
              loaderStatus={loaderStatus}
              zIndex={columns?.length + 2 - zIndexFirst}
              setScrollLeft={setScrollLeft}
              alertTable={alertTable}
              setGridWidth={setGridWidth}
              widgetResizeSelector={widgetResizeSelector}
              isWidget={isWidget}
              isWatchlist={isWatchlist}
            />
          )}
          <ListTableHeaderCell
            columns={columns}
            setAllItems={setAllItems}
            toggleIconHandler={toggleIconHandler}
            sortHandler={sortHandler}
            sortStatus={sortStatus}
            columnsSize={columnsSize}
            updateColumnsSize={updateColumnsSize}
            initColumnsSize={initColumnsSize}
            loaderStatus={loaderStatus}
            setScrollLeft={setScrollLeft}
            alertTable={alertTable}
            checked={checked}
            setGridWidth={setGridWidth}
            showWeight={showWeight}
            widgetResizeSelector={widgetResizeSelector}
            isWidget={isWidget}
            isWatchlist={isWatchlist}
          />
          <div
            className={styles.screenerTableHeaderCell}
            style={{ width: `${LAST_ITEM_SCREENER_WIDTH}px` }}
          />
        </div>
      ) : <div className={styles.screenerTableHeader} />}
    </div>
  );
};

ScreenerTableHeader.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired,
  setAllItems: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
  )).isRequired,
  toggleIconHandler: PropTypes.func.isRequired,
  columnsSize: PropTypes.shape({}).isRequired,
  initColumnsSize: PropTypes.func.isRequired,
  updateColumnsSize: PropTypes.func.isRequired,
  loaderStatus: PropTypes.bool.isRequired,
  setScrollLeft: PropTypes.func.isRequired,
  firstItem: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  alertTable: PropTypes.bool,
  zIndexFirst: PropTypes.number.isRequired,
  setGridWidth: PropTypes.func.isRequired,
  headerRef: PropTypes.shape({}),
  showWeight: PropTypes.bool,
  widgetResizeSelector: PropTypes.number,
  isWidget: PropTypes.bool,
  isWatchlist: PropTypes.bool,
};

ScreenerTableHeader.defaultProps = {
  firstItem: null,
  alertTable: false,
  headerRef: null,
  showWeight: false,
  widgetResizeSelector: 0,
  isWidget: false,
  isWatchlist: false,
};

export default React.memo(ScreenerTableHeader, areEqual);
