import React, { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import MultichartWrapper from './components/MultichartWrapper';
import SymbolCounter from '../SymbolCounter/SymbolCounter';
import RootStateTypes from '../../store/RootStateTypes';
import useMultiChartWorker from './hooks/useMultiChartWorker';
import { setMultiChartIsMounted } from '../../store/multiChart/actions';
import { firstMultiChartRow } from '../../constants/multiChart';
import style from './sass/MultiChart.module.scss';
import { setSelectedSymbolsScreener } from '../../store/screener/actions';
import { setSingleItem } from '../../store/selectSymbol/slice';
import TableContext from '../../pages/Table/context/TableContext';

const ScreenerMultiChartView = (): React.ReactElement => {
  const {
    isWatchlist,
    selectedItems,
  } = useContext(TableContext);

  const dispatch = useDispatch();
  const { setCurrentRange, setSortBy } = useMultiChartWorker(true, isWatchlist);
  const multiChartSorting = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.multiChartSorting, shallowEqual
  );
  const { direction, columnId } = multiChartSorting ?? { direction: 1, columnId: '' };
  const screenerId = useSelector((state: RootStateTypes) => state.multiChartState.screenerId, shallowEqual);
  const symbolsList = useSelector(
    (state: RootStateTypes) => state.multiChartState.symbolsList, shallowEqual
  );
  const scrollToRow = useSelector(
    (state: RootStateTypes) => state.multiChartState.scrollToRow, shallowEqual
  );
  const selectedSymbol = useSelector(
    (state: RootStateTypes) => state.multiChartState.selectedSymbol, shallowEqual
  );

  const currentSelectedChartIndex = symbolsList?.indexOf(selectedSymbol) + 1 || firstMultiChartRow;

  const setSelectedCharts = (index: number) => {
    dispatch(setSelectedSymbolsScreener(index));
    dispatch(setSingleItem(index));
  };

  useEffect(() => () => {
    if (screenerId) {
      dispatch(setMultiChartIsMounted(true));
    }
  }, [screenerId]);

  useEffect(() => {
    setSortBy(columnId, direction);
  }, [columnId, direction]);

  return (
    <>
      <div id="screenShotTarget" className={style.multiChartWrapper}>
        <MultichartWrapper
          setCurrentRange={setCurrentRange}
          selectedCharts={selectedItems}
          scrollToRow={scrollToRow}
          isDeepList={isWatchlist}
          setSelectedCharts={setSelectedCharts}
          symbolsList={symbolsList}
        />
      </div>
      <SymbolCounter
        selectedItems={currentSelectedChartIndex}
        counterSymbols={symbolsList.length}
        checkedItem={selectedItems.length}
      />
    </>
  );
};

export default ScreenerMultiChartView;
