import { createSlice } from '@reduxjs/toolkit';
import { snapScreen } from '../../components/TwitterShare/helpers/imgHelper';

type Action = { type: string, payload: string };

const INITIAL_STATE = {
  showShareModal: false,
  screenShot: null,
};

const twitterSlice = createSlice({
  name: 'twitterSlice',
  initialState: INITIAL_STATE,
  reducers: {
    showShareModal(state, action) {
      state.showShareModal = action.payload;
    },
    setScreenShot(state, action) {
      state.screenShot = action.payload;
    }
  },
});

export const {
  showShareModal,
  setScreenShot,
} = twitterSlice.actions;

export function takeScreenShot() {
  return async function fetchTodoByIdThunk(dispatch: (action: Action) => void):Promise<void> {
    const canvas = await snapScreen();
    if (canvas) {
      dispatch(setScreenShot(canvas));
    }
    dispatch(showShareModal(true));
  };
}

export default twitterSlice;
