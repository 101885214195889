import React, { useEffect, useState } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardComponents.module.scss';
import { WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import ChartWidget from './ChartWidget';
import ScreenWidget from './ScreenWidget';
import DeepListWidget from './DeepListWidget';
import { IDashboardItemContent } from '../types/DashboardComponentsInterfaces';
import StatsChartWidget from './StatsChartWidget';
import NewScreenModals from '../../NewScreenModals/NewScreenModals';
import RootStateTypes from '../../../store/RootStateTypes';
import PositionSizeWidget from './PositionSizeWidget';
import PerformanceChartWidget from './PerformanceChartWidget';
import HeatMapWidget from './HeatMapWidget';
import { setIsOpenModal } from '../../../store/watchlist/actions';
import BubbleChartWidget from './BubbleChartWidget';
import { setWidgetsColumnVisible } from '../../../store/dashboards/slice';
import { TDashboardItemsData } from '../../../utils/Types';
import useWidgetHeader from '../hook/useWidgetHeader';

const DashboardItemContent = ({
  widget, removeWidget,
  updateWidget, setColorGroup,
  changeSymbolForGroup,
  activeSymbolIndexValue,
  lastActiveGroup, setSearch,
  globalSearchKeyHandler, dashboardId,
  isEditable,
}: IDashboardItemContent): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState('');
  const [singleItemStatus, setSingleItemStatus] = useState(false);
  const [currentScreensItem, setCurrentScreensItem] = useState(-1);
  const [currentScreensSubItem, setCurrentScreensSubItem] = useState(-1);
  const [hoveredPresetItem, setHoveredPresetItem] = useState(-1);

  const modalType = useSelector((state: RootStateTypes) => state.watchlistState.modalType, shallowEqual);

  const openModalHandler = (text: string, singleItem: boolean) => {
    setSingleItemStatus(singleItem);
    setOpenModal(text);
    dispatch(setWidgetsColumnVisible(false));
  };

  const {
    setTitle,
    setSelectedItem,
  } = useWidgetHeader(widget, updateWidget);
  const openItemsModalHandler = (
    text: string,
    singleItem: boolean,
    screenItem: number,
    screenSubItem: number,
    hoveredItem: number
  ) => {
    setCurrentScreensItem(screenItem);
    setCurrentScreensSubItem(screenSubItem);
    setHoveredPresetItem(hoveredItem);
    setSingleItemStatus(singleItem);
    setOpenModal(text);
  };

  const currentItemSelected = (tab: TDashboardItemsData) => {
    setSelectedItem(tab);
    setTitle(tab?.title);
    updateWidget({ ...widget, deepListId: tab.id });
  };

  useEffect(() => {
    dispatch(setIsOpenModal(!!openModal));
  }, [openModal]);

  const getComponent = (): React.ReactElement => {
    switch (widget.type) {
      case WidgetTypes.CHART:
        return (
          <ChartWidget
            openModalHandler={openModalHandler}
            openItemsModalHandler={openItemsModalHandler}
            changeSymbolForGroup={changeSymbolForGroup}
            activeSymbolIndexValue={activeSymbolIndexValue}
            lastActiveGroup={lastActiveGroup}
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            setSearch={setSearch}
            globalSearchKeyHandler={globalSearchKeyHandler}
            widget={widget}
            isEditable={isEditable}
          />
        );
      case WidgetTypes.SCREENER:
        return (
          <ScreenWidget
            openModalHandler={openModalHandler}
            openItemsModalHandler={openItemsModalHandler}
            changeSymbolForGroup={changeSymbolForGroup}
            lastActiveGroup={lastActiveGroup}
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            setSearch={setSearch}
            widget={widget}
            globalSearchKeyHandler={globalSearchKeyHandler}
            isEditable={isEditable}
          />
        );
      case WidgetTypes.STATS_CHART:
        return (
          <StatsChartWidget
            openModalHandler={openModalHandler}
            openItemsModalHandler={openItemsModalHandler}
            changeSymbolForGroup={changeSymbolForGroup}
            activeSymbolIndexValue={activeSymbolIndexValue}
            lastActiveGroup={lastActiveGroup}
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            setSearch={setSearch}
            widget={widget}
            globalSearchKeyHandler={globalSearchKeyHandler}
            isEditable={isEditable}
          />
        );
      case WidgetTypes.POSITION_SIZE_CALCULATOR:
        return (
          <PositionSizeWidget
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            widget={widget}
            dashboardId={dashboardId}
          />
        );
      case WidgetTypes.PERFORMANCE_CHART:
        return (
          <PerformanceChartWidget
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            widget={widget}
            dashboardId={dashboardId}
            changeSymbolForGroup={changeSymbolForGroup}
            isEditable={isEditable}
          />
        );
      case WidgetTypes.HEATMAP:
        return (
          <HeatMapWidget
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            widget={widget}
            dashboardId={dashboardId}
            changeSymbolForGroup={changeSymbolForGroup}
            isEditable={isEditable}
          />
        );
      case WidgetTypes.BUBBLE_CHART:
        return (
          <BubbleChartWidget
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            widget={widget}
            dashboardId={dashboardId}
            changeSymbolForGroup={changeSymbolForGroup}
            isEditable={isEditable}
          />
        );
      default:
        return (
          <DeepListWidget
            openModalHandler={openModalHandler}
            openItemsModalHandler={openItemsModalHandler}
            changeSymbolForGroup={changeSymbolForGroup}
            lastActiveGroup={lastActiveGroup}
            setColorGroupe={setColorGroup}
            updateWidget={updateWidget}
            removeWidget={removeWidget}
            setSearch={setSearch}
            widget={widget}
            globalSearchKeyHandler={globalSearchKeyHandler}
            isEditable={isEditable}
          />
        );
    }
  };

  return (
    <div className={styles.dashboardItemContent}>
      {(openModal || modalType) && ![t('deleteSymbols'), t('addSymbol')].includes(modalType) && (
        <NewScreenModals
          openModal={openModal}
          openModalHandler={openModalHandler}
          modalType={modalType}
          singleItemStatus={singleItemStatus}
          hoveredPresetItem={hoveredPresetItem}
          currentScreensItem={currentScreensItem}
          currentScreensSubItem={currentScreensSubItem}
          currentItemSelected={currentItemSelected}
        />
      )}
      {getComponent()}
      <div className={`gridEventDestroy
          ${styles.gridStackContentPointBreaker}
          ${widget.type === WidgetTypes.CHART && styles.topPosition}
        `}
      />
    </div>
  );
};

export default DashboardItemContent;
