function createDefaultPalette(primaryColor: string, secondaryColor: string) {
  return {
    colors: {
      0: {
        color: primaryColor,
        style: 0,
        width: 1
      },
      1: {
        color: secondaryColor,
        style: 0,
        width: 1
      },
    }
  };
}

export const defaultPalettes = {
  lineShort1: createDefaultPalette('#808000', '#880E4F'),
  lineShort2: createDefaultPalette('#808000', '#880E4F'),
  lineShort3: createDefaultPalette('#808000', '#880E4F'),
  lineShort4: createDefaultPalette('#808000', '#880E4F'),
  lineShort5: createDefaultPalette('#808000', '#880E4F'),
  lineLong1: createDefaultPalette('#4CAF50', '#FF5252'),
  lineLong2: createDefaultPalette('#4CAF50', '#FF5252'),
  lineLong3: createDefaultPalette('#4CAF50', '#FF5252'),
  lineLong4: createDefaultPalette('#4CAF50', '#FF5252'),
  lineLong5: createDefaultPalette('#4CAF50', '#FF5252'),
  lsColor0: createDefaultPalette('#036103', '#880E4F'),
  lsColor1: createDefaultPalette('#4CAF50', '#f44336'),
  lsColor2: createDefaultPalette('#2196f3', '#ffb74d'),
  lsColor3: createDefaultPalette('#009688', '#f06292'),
  lsColor4: createDefaultPalette('#05bed5', '#e65100'),
};

interface Palette {
  valToIndex: Record<number, number>;
  colors: Record<number, { name: string }>;
}

function createPalette(): Palette {
  return {
    valToIndex: {
      0: 0,
      1: 1,
    },
    colors: {
      0: { name: 'Color 0' },
      1: { name: 'Color 1' },
    },
  };
}

export const palettes: Record<string, Palette> = {
  lineShort1: createPalette(),
  lineShort2: createPalette(),
  lineShort3: createPalette(),
  lineShort4: createPalette(),
  lineShort5: createPalette(),
  lineLong1: createPalette(),
  lineLong2: createPalette(),
  lineLong3: createPalette(),
  lineLong4: createPalette(),
  lineLong5: createPalette(),
  lsColor0: createPalette(),
  lsColor1: createPalette(),
  lsColor2: createPalette(),
  lsColor3: createPalette(),
  lsColor4: createPalette(),
};
