import { LongDescriptionHook } from './HooksInterphaces';

export const descriptionCache: { [key: string]: string } = {};

const useLongDescription: LongDescriptionHook = () => {
  const getDescription = async (symbol: string) => {
    const URL = process.env.REACT_APP_GET_LONG_DESCRIPTION;
    if (!descriptionCache[symbol]) {
      try {
        const response = await fetch(`${URL}${symbol}`);
        if (response.status === 200) {
          descriptionCache[symbol] = await response.text();
        } else {
          descriptionCache[symbol] = '';
        }
      } catch (e) {
        throw new Error(e as string);
      }
    }
  };

  return { getDescription };
};

export default useLongDescription;
