import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './sass/SeekBar.module.scss';
import InputRangePanel from '../InputRangePanel/InputRangePanel';
import useSeekBar from './hooks/useSeekBar';

interface ISeekBar {
  maxValue: number,
  minValue: number,
  setArray: (array: (number | number[])[]) => void,
  viewRange: boolean,
  initialMax: number,
  initialMin: number,
  columnId: number,
  typeData: string,
  activeTab: Record<string, boolean>,
  handleTab:boolean,
}

const SeekBar = ({
  maxValue, minValue, setArray, viewRange, initialMax,
  initialMin, columnId, typeData, activeTab, handleTab,
}: ISeekBar): React.ReactElement => {
  const { t } = useTranslation();

  const containerRef = createRef<HTMLDivElement>();

  const {
    minInputValue,
    maxInputValue,
    touched,
    addHandler,
    setMaxPosition,
    setMaxValueHandler,
    setMinPosition,
    setMinValueHandler
  } = useSeekBar(
    maxValue, minValue, setArray, viewRange, initialMax,
    initialMin, columnId, typeData, activeTab, handleTab,
  );

  return (
    <div ref={containerRef} className={styles.range}>
      <InputRangePanel
        touched={touched}
        typeData={typeData}
        activeTab={activeTab}
        centralText={t('to')}
        labelButton={t('add')}
        addHandler={addHandler}
        setMinPosition={setMinPosition}
        setMaxPosition={setMaxPosition}
        initialMax={initialMax}
        initialMin={initialMin}
        minInputValue={minInputValue}
        maxInputValue={maxInputValue}
        setMinValueHandler={setMinValueHandler}
        setMaxValueHandler={setMaxValueHandler}
      />
    </div>
  );
};

export default SeekBar;
