import React, { Suspense, useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FilterDropdown from '../../../components/FilterScreen/FilterDropdown';
import ClientOnlyPortal from '../../../components/ClientOnlyPortal/ClientOnlyPortal';
import WatchListModals from '../../../components/WatchListModals/WatchListModals';
import ShareTwitterModal from '../../../components/TwitterShare/ShareTwitterModal';
import ModalLayout from '../../../layout/ModalLayout/ModalLayout';
import BannerMobile from '../../../components/BannerMobile/BannerMobile';
import ModalAlert from './ModalAlert';
import useScreenshot from '../../../components/TwitterShare/hooks/useScreenshot';
import NotionPopUp from '../../../components/NotionPopUp/NotionPopUp';
import { messageAction } from '../../../store/auth/actions';
import NewVersionPopUp from '../../../components/NewVersionPopUp/NewVersionPopUp';
import AlertsNotionList from '../../../components/AlertsNotionList/AlertsNotionList';
import DashboardItemsDialog from '../../../components/DashboardItemsDialog/DashboardItemsDialog';
import UseDashboardsDialogs from '../../../components/DashboardItemsDialog/hooks/UseDashboardsDialogs';
import {
  MODAL_TITLES,
  SUBSCRIPTION_TYPE
} from '../../../constants/screener';
import { checkRoleUser } from '../../../utils/userHelper';
import { ReactComponent as TimeIcon } from '../../../assets/images/icons/timeCircleIcon.svg';
import TableContext from '../context/TableContext';

const ScreenerPortalItemsWrapper = () => {
  const {
    headerFilter,
    isVisibleFilters,
    openModalHandler,
    setIsVisibleFilters,
    currentModalRef,
    viewBanner,
    titlePopUpMobile,
    textBtnPopUpMobile,
    handlerViewBanner,
    openModal
  } = useContext(TableContext);
  const dispatch = useDispatch();

  const modalTypeScreener = useSelector((state) => state.watchlistState.modalType, shallowEqual);
  const openAlert = useSelector((state) => state?.alertsState?.openAlertModal, shallowEqual);
  const popUp = useSelector((state) => state.authState.popUp, shallowEqual);
  const message = useSelector((state) => state.authState.message, shallowEqual);
  const showModal = useSelector((state) => state.twitterState.showShareModal, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const isUpdatedPopUpVisible = useSelector(
    (state) => state.screenerState.isHasBeenUpdatePopupVisible, shallowEqual
  );

  const {
    open,
    actionOpenDialog,
    actionCancelDialog,
    dialogData,
    actionCallback
  } = UseDashboardsDialogs();

  const {
    twittText,
    setTwittText,
    cleanUpScreenshotData,
  } = useScreenshot();

  const handleItemScreenerModal = (type, flag) => {
    if (type === MODAL_TITLES.EDIT) {
      const itemType = selectedScreen?.type === 'screener' ? 0 : 1;
      actionOpenDialog(1, 0, 1, itemType, selectedScreen);
    } else {
      openModalHandler(type, flag);
    }
  };

  useEffect(() => {
    if (!popUp) {
      dispatch(messageAction({ message: '' }));
    }
  }, [message, popUp]);

  return (
    <>
      {openAlert && <ModalAlert />}
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          actionCallback={actionCallback}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      <FilterDropdown
        headerFilter={headerFilter}
        isVisibleFilters={isVisibleFilters}
        openModalHandler={handleItemScreenerModal}
        setIsVisibleFilters={setIsVisibleFilters}
        currentModalRef={currentModalRef}
        openModal={openModal}
      />
      <ClientOnlyPortal selector="#watchlist_modals">
        {modalTypeScreener && modalTypeScreener !== MODAL_TITLES.CREATE
          && modalTypeScreener !== MODAL_TITLES.CREATE_DATA_PANEL
          && modalTypeScreener !== MODAL_TITLES.CREATE_DATA_PANEL_PRESET
          && <WatchListModals />}
      </ClientOnlyPortal>
      <ClientOnlyPortal selector="#twitter_share_modal">
        {showModal && (
          <ShareTwitterModal
            textTweet={twittText}
            handlerTextTweet={setTwittText}
            cleanUpScreenshotData={cleanUpScreenshotData}
          />
        )}
      </ClientOnlyPortal>
      {viewBanner && (
        <ModalLayout typeModal="modalMobile" classModal="modalPopUpMobile">
          <BannerMobile
            Icon={TimeIcon}
            title={titlePopUpMobile}
            buttonText={textBtnPopUpMobile}
            handlerClickButton={handlerViewBanner}
          />
        </ModalLayout>
      )}
      {(popUp) && (<NotionPopUp />)}
      {(isUpdatedPopUpVisible) && (
        <Suspense fallback={<></>}>
          <NewVersionPopUp />
        </Suspense>
      )}
      {(isUpdatedPopUpVisible) && (<NewVersionPopUp />)}
      <AlertsNotionList />
    </>
  );
};

export default ScreenerPortalItemsWrapper;
