import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/WidgetCharts.module.scss';
import { IDataPointDropDown } from '../types/WidgetChartsInterfaces';
import { TDataPoint } from '../types/WidgetChartsypes';
import SearchBlock from './SearchBlock';
import EmptyResultsContainer from '../../EmptyResultsContainer/EmptyResultsContainer';

const DataPointSelector = ({
  screenerStates,
  dataPointList,
  setScreenerStates,
  objectKey,
  widgetStyle
}: IDataPointDropDown): React.ReactElement => {
  const { t } = useTranslation();

  const handleSelect = (item: TDataPoint) => {
    setScreenerStates((prev) => ({
      ...prev,
      screenerIsOpen: false,
      [`dataPoints${objectKey}IsOpen`]: false,
      folderMenuIsOpen: -1,
      [`dataPoints${objectKey}Title`]: item.name,
      [`dataPoints${objectKey}Index`]: item.index,
      [`dataPoints${objectKey}Type`]: item.type
    }));
  };

  const list = useMemo(() => {
    let filteredArray = [...dataPointList];
    if (screenerStates[`dataPoints${objectKey}Search`].length) {
      filteredArray = filteredArray.filter((item: TDataPoint) => item.name.toLowerCase()
        .includes(screenerStates[`dataPoints${objectKey}Search`].toLowerCase()));
    }
    return filteredArray;
  }, [screenerStates[`dataPoints${objectKey}Search`]]);

  return (
    <div
      className={styles.dataPointsTabDropdownMenu}
      style={widgetStyle}
      onMouseLeave={() => setScreenerStates((prev) => ({
        ...prev,
        screenerIsOpen: false,
      }))}
    >
      <div className={styles.screenerSearch}>
        <SearchBlock
          screenerStates={screenerStates}
          setScreenerStates={setScreenerStates}
          stateKey={`dataPoints${objectKey}Search`}
        />

        <div className={styles.screenerItems}>
          <div className={styles.screenerItemsLeft}>
            <div className={styles.itemsWrapper}>
              {list?.map((item: TDataPoint) => (
                <span
                  role="button"
                  tabIndex={0}
                  onMouseDown={() => handleSelect(item)}
                  key={item.index}
                >
                  <span>
                    {item.name}
                  </span>
                </span>
              ))}
              {list.length === 0 && screenerStates[`dataPoints${objectKey}Search`] && (
                <EmptyResultsContainer
                  noResultLabel={t('noResultsFound')}
                  noResultTitle={
                    t('noResultScreens',
                      {
                        search: screenerStates[`dataPoints${objectKey}Search`],
                        type: t('emptySearch')
                      })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataPointSelector;
