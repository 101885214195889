import { SUPPORTED_RESOLUTIONS, SUBSESSIONS } from '../constants/tvWidgetOptions';
import { emitter } from './socketEmitter';
import { rtProcessor } from './ChartPrefetchServices/RtDataProcessor';
import { rtAccumProcessor } from './ChartPrefetchServices/RtAccumProcessor';
import { WS_CHANNELS, WS_PING_TYPES } from '../constants/socketConnection';
import { messageIdGenerator } from '../components/ChartContainer/utils/chartingHelper';

export const channelToSubscription = new Map();

export const saveChartIdToSubscription = (chartId, chanelString) => {
  const subscriptionItem = channelToSubscription.get(chanelString);

  if (subscriptionItem) {
    subscriptionItem.chartId = chartId;
  }
};

export const rtDataListener = (symbolIndex, chanelString) => {
  const subItem = channelToSubscription.get(chanelString);
  if (subItem === undefined || !rtProcessor.rtData[symbolIndex]) return;

  const resolutionIndex = SUPPORTED_RESOLUTIONS.indexOf(subItem.resolution);
  const allowedChanel = +symbolIndex === subItem.symbolIndex;

  const rtDataForCurrentSymbol = rtProcessor.rtData[symbolIndex];

  rtDataForCurrentSymbol[subItem.resolution].forEach((item) => {
    rtAccumProcessor.accumItemHandler(
      item,
      allowedChanel,
      resolutionIndex,
      subItem,
    );
  });
};

export const findChannelStringByChartId = (chartId, map) => {
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const [_, value] of map) {
    if (value.chartId === chartId) {
      return value.channelString;
    }
  }
  return null;
};

export const rtDataListenerFromSocket = (symbolIndex, chartId, data) => {
  const chanelString = findChannelStringByChartId(chartId, channelToSubscription);
  const subItem = channelToSubscription.get(chanelString);
  if (subItem === undefined) return;

  const resolutionIndex = SUPPORTED_RESOLUTIONS.indexOf(subItem.resolution);
  const allowedChanel = +symbolIndex === subItem.symbolIndex;

  data.data.forEach((item) => {
    rtAccumProcessor.accumItemHandler(
      item,
      allowedChanel,
      resolutionIndex,
      subItem,
    );
  });
};

export const subscribeOnStream = (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscribeUID,
  lastHistoricalBar,
  symbolIndex,
  socket,
  extendedFromUser,
  dashboardWidgetId
) => {
  const channelString = dashboardWidgetId
    ? `0~${symbolInfo.name}~${symbolInfo.description}~${subscribeUID}~${dashboardWidgetId}`
    : `0~${symbolInfo.name}~${symbolInfo.description}~${subscribeUID}`;

  rtAccumProcessor.initNewAccum(symbolIndex, channelString);

  const handler = {
    id: subscribeUID,
    callback: onRealtimeCallback,
  };

  let subscriptionItem = channelToSubscription.get(channelString);

  if (subscriptionItem) {
    subscriptionItem.handlers.push(handler);
    return;
  }

  subscriptionItem = {
    resolution,
    lastHistoricalBar,
    channelString,
    handlers: [handler],
    symbolIndex,
    marketSettings: symbolInfo?.subsession_id || SUBSESSIONS.REGULAR
  };

  channelToSubscription.set(channelString, subscriptionItem);
  rtDataListener(symbolIndex, channelString);

  emitter(socket, symbolIndex || 0, channelString, extendedFromUser);
};

export const unsubscribeFromStream = (subscriberUID, socket) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const channelString of channelToSubscription.keys()) {
    const subscriptionItem = channelToSubscription.get(channelString);
    const handlerIndex = subscriptionItem.handlers.findIndex((handler) => handler.id === subscriberUID);

    if (handlerIndex !== -1) {
      subscriptionItem.handlers.splice(handlerIndex, 1);
      if (subscriptionItem.handlers.length === 0) {
        // eslint-disable-next-line no-console
        console.log('[unsubscribeBars]: Unsubscribe from streaming. Channel:', channelString);
        rtAccumProcessor.removeAccumSubItem(subscriptionItem.symbolIndex, subscriptionItem.channelString);

        const unsubscribeMessage = { messageId: messageIdGenerator(), chartId: subscriptionItem.chartId };
        socket?.sendMessage(WS_CHANNELS.CHART_UNSUBSCRIBE, unsubscribeMessage);

        channelToSubscription.delete(channelString);
        break;
      }
    }
  }
};

export const pingCallback = (resp, ChartConnection) => {
  if (resp?.chartId) {
    const subscriptionItems = findChannelStringByChartId(resp.chartId, channelToSubscription);

    if (subscriptionItems) {
      ChartConnection?.sendMessage(
        WS_CHANNELS.PONG_PERIODICAL_DATA,
        {
          periodicalId: resp?.chartId,
          type: WS_PING_TYPES.CHART,
          socketId: ChartConnection?.socketId
        }
      );
    }
  }
};
