import React from 'react';

import styles from './sass/BannerMobile.module.scss';

import TextField from '../TextField/TextField';
import Button from '../Button/Button';

interface IBannerMobileProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
  title: string;
  buttonText: string;
  handlerClickButton: () => void;
  additionalClass?: string;
  theme?: string;
  headText?: string;
}

const BannerMobile = ({
  Icon, title, buttonText, handlerClickButton, additionalClass, theme, headText
}: IBannerMobileProps): React.ReactElement => (
  <div className={`${additionalClass ? styles[additionalClass] : ''} ${theme || ''}`}>
    <div className={styles.container}>
      <Icon />
      {headText && <TextField styleType="titleBanner" text={headText} />}
      <TextField styleType={headText ? 'mainTextBanner' : 'titleBanner'} text={title} />
      <Button handleClick={handlerClickButton} buttonClass={styles.buttonContinue}>
        {buttonText}
      </Button>
    </div>
  </div>
);

export default BannerMobile;
