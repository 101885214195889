import {
  FIND_NAME, DEFAULT_ITEM, RECOMMENDED_COLUMNS, RECOMMENDED_TYPE, COLUMNS_INDEX
} from '../constants/screener';
import { findIndexItem } from './helpers';

const convertIndexToData = (array, columnsList) => {
  const data = [];
  if (typeof (array) !== 'string') {
    array?.forEach((item) => {
      const indexItem = findIndexItem(columnsList, FIND_NAME.INDEX, item);
      if (indexItem !== -1) {
        data.push({
          name: columnsList[indexItem]?.name,
          type: columnsList[indexItem]?.type,
          id: columnsList[indexItem]?.index,
          group: columnsList[indexItem]?.group
        });
      }
    });
  }

  return data;
};

const getRecommendedColumns = (type, array, indexItem) => {
  let columns;
  if (type !== RECOMMENDED_TYPE) {
    columns = array[indexItem]?.data?.selectedColumns?.default || array[indexItem]?.data?.selectedColumns;
  } else {
    columns = RECOMMENDED_COLUMNS;
  }
  return columns;
};

export const getColumnsSetItem = (array, id, columnsList, type) => {
  const indexItem = findIndexItem(array, FIND_NAME.ID, id);
  let columns = [];
  let name = '';
  let dataItem = {};
  if (array[indexItem]?.title === FIND_NAME.RECOMMENDED || type === RECOMMENDED_TYPE) {
    columns = getRecommendedColumns(type, array, indexItem);
  } else if (indexItem === -1) {
    const allItemsInFolders = [];
    array?.forEach((elem) => {
      allItemsInFolders.push(...elem.items);
    });
    const indexElem = findIndexItem(allItemsInFolders, FIND_NAME.ID, id);
    if (allItemsInFolders[indexElem]?.data?.selectedColumns) {
      columns = allItemsInFolders[indexElem]?.data?.selectedColumns;
    }
    name = allItemsInFolders[indexElem]?.title;
    dataItem = allItemsInFolders[indexElem]?.data;
  } else {
    if (array[indexItem]?.data?.selectedColumns
       && array[indexItem].title !== FIND_NAME.RECOMMENDED) {
      columns = array[indexItem]?.data?.selectedColumns.map((item) => (item.id ? item.id : item));
    }
    name = array[indexItem]?.title;
    dataItem = array[indexItem]?.data;
  }

  const dataColumns = convertIndexToData(columns, columnsList);
  return { columns: dataColumns, name, dataItem };
};

export const checkArray = (checkArrayData, oldArray) => {
  const newArray = [];
  const newItems = [];

  checkArrayData.forEach((item) => {
    const indexItem = findIndexItem(oldArray, FIND_NAME.ID, item.id);
    if (indexItem !== -1) {
      newArray.push(item);
    } else {
      newItems.push(item);
    }
  });
  return newArray.length === oldArray.length && newItems.length === 0;
};

export const setNewColumnsCondition = (itemColumn, state) => {
  const stateColumns = [...state];
  const indexItem = findIndexItem(stateColumns, FIND_NAME.ID, itemColumn?.id);
  if (indexItem !== -1) {
    stateColumns.splice(indexItem, 1);
  } else if (stateColumns.length < 1) {
    stateColumns.push(itemColumn);
  } else {
    stateColumns.splice(0, 0, itemColumn);
  }
  return stateColumns.map((item) => item.id);
};

export const getAllColumns = (array) => {
  const allItems = [];
  array?.forEach((elem) => {
    if (elem.type === FIND_NAME.FOLDER) {
      allItems.push(...elem.items);
    } else {
      allItems.push(elem);
    }
  });
  return allItems;
};

export const getItem = (array, id) => {
  let item = {};
  array.forEach((el) => {
    if (el?.data?.screenIdList?.length > 0) {
      const indexItem = el?.data?.screenIdList?.findIndex((screenId) => +screenId === +id);
      if (indexItem !== -1) {
        item = { ...el };
      }
    }
  });
  return item;
};

export const getActiveColumnsHolding = (isHoldingView, selectedColumns) => {
  return isHoldingView ? selectedColumns : selectedColumns.filter(({ id }) => id !== COLUMNS_INDEX.ETF);
};

export const isNumericalColumn = (type) => /M|candle|round|int|percent/.test(type);

export const getActiveColumns = (columnsList, arrayId, columns) => {
  const currentItem = [...arrayId];
  const objItem = [];
  if (columnsList?.length > 0) {
    currentItem.forEach((el) => {
      const indexItem = findIndexItem(columnsList, FIND_NAME.INDEX, el);
      if (indexItem !== -1) {
        const item = {
          group: columnsList[indexItem]?.group,
          id: columnsList[indexItem]?.index,
          name: columnsList[indexItem]?.name,
          type: columnsList[indexItem]?.type,
        };
        objItem.push(item);
      }
    });
  }
  return [...objItem, ...columns];
};

export const checkListOnRecommended = (array) => array.findIndex((el) => el?.title === FIND_NAME.RECOMMENDED) !== -1;

export const getIdColumn = (itemsColumnSets) => {
  let columnSetIdItem = -1;
  const allColumnSet = getAllColumns(itemsColumnSets);
  const indexItem = findIndexItem(allColumnSet, FIND_NAME.TITLE, FIND_NAME.RECOMMENDED);
  if (indexItem !== DEFAULT_ITEM) {
    columnSetIdItem = allColumnSet[indexItem]?.id;
  }
  return { indexItem, columnSetIdItem };
};
