import { CustomIndicator, IContext, IPineSeries, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputValue } from '../../charting_library/charting_library';
import getInputArrayTs from '../utilities/getInputArrayTs';
import { defaultInputs, inputs } from './metainfo/inputs';
import { defaultPalettes, palettes } from './metainfo/palettes';
import { defaultStyles, styles } from './metainfo/styles';
import plots from './metainfo/plots';

const dollarVolume = (mPineJS: PineJS): CustomIndicator => {
  return {
    name: 'DV - Enhanced Dollar Volume',
    metainfo: {
      defaults: {
        inputs: defaultInputs,
        palettes: defaultPalettes,
        precision: 0,
        styles: defaultStyles,
      },
      description: 'DV - Enhanced Dollar Volume',
      id: 'TL-enhancedDollarvolume@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Enhanced Dollar Volume',
      format: {
        type: 'volume',
      },
      inputs: inputs,
      palettes: palettes,
      plots: plots,
      styles: styles,
      isCustomIndicator: true,
      is_price_study: false
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context: IContext, inputs: <T extends StudyInputValue>(index: number) => T) {
        this._context = context;
        this._input = inputs;
        this.tenBarsRollingSeries = [];
      };
      this.main = (context, inputs) => {
        this._context = context;
        this._input = inputs;

        const [dailyMALookback, weeklyMALookback, monthlyMALookback, truncateVolume, previousClose] = getInputArrayTs({
          inputs,
          length: 5
        });

        let maLookback;
        if (mPineJS.Std.isweekly(this._context)) {
          maLookback = weeklyMALookback;
        } else if (mPineJS.Std.ismonthly(this._context)) {
          maLookback = monthlyMALookback;
        } else {
          maLookback = dailyMALookback;
        }

        const volume = mPineJS.Std.volume(this._context);
        const close = mPineJS.Std.close(this._context);
        const closeSeries = this._context.new_unlimited_var(close);
        const dollarVolumeSeries = this._context.new_unlimited_var(volume * close);
        let highestDownVolume = 0;
        var day_color = 0;

        for (let i = 0; i < 10; i++) {
          if (closeSeries.get(i) < closeSeries.get(i + 1)) {
            if (dollarVolumeSeries.get(i) > highestDownVolume) {
              highestDownVolume = dollarVolumeSeries.get(i);
            }
          }
        }
        const lowestVolume = mPineJS.Std.lowest(dollarVolumeSeries, 10, this._context);
        if (previousClose) {
          day_color = close > closeSeries.get(1) ? 0 : 1;
        } else {
          day_color = close > mPineJS.Std.open(this._context) ? 0 : 1;
        }
        if (close > closeSeries.get(1) && dollarVolumeSeries.get(0) > highestDownVolume) {
          day_color = 2;
        }
        if (dollarVolumeSeries.get(0) <= lowestVolume) {
          day_color = 3;
        }
        const exponent = 0.5;
        const formattedVolume = truncateVolume ? dollarVolumeSeries.get(0) ** exponent : dollarVolumeSeries.get(0);
        const formattedVolumeSeries = this._context.new_var(formattedVolume);
        const movingAverage = mPineJS.Std.sma(formattedVolumeSeries, maLookback, this._context);

        return [
          formattedVolume,
          day_color,
          movingAverage,
        ];
      };
    }
  };
};
export default dollarVolume;
