import React from 'react';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toggleAlertNotificationListDropdownVisible } from '../../store/alerts/slice';
import styles from './sass/AlertsList.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import { setSelectedTab } from '../../store/watchlist/actions';
import { setRoute } from '../../store/screener/actions';
import { setVisibleItemsTableStore } from '../../store/tableData/slice';
import { defaultPage, TABLE_SCREEN_PATH } from '../../constants/screener';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { SELECTED_TAB_ID } from '../../constants/storage';

const AlertsListBottom = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);

  const openAlertPage = () => {
    updateStoreHandler(SELECTED_TAB_ID, alertTab?.id);
    dispatch(setSelectedTab(alertTab));
    dispatch(toggleAlertNotificationListDropdownVisible());
    dispatch(setVisibleItemsTableStore(defaultPage));
    dispatch(setRoute(TABLE_SCREEN_PATH.DEEPLIST));
  };

  return (
    <div className={styles.bottomButtonWrapper}>
      <DefaultButton handleClick={openAlertPage}>
        {t('manageAlerts')}
      </DefaultButton>
    </div>
  );
};

export default AlertsListBottom;
