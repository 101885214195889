import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from '../Button/Button';
import { popUpAction, changeNotificationTypeAction, notionAnimationAction } from '../../store/auth/actions';
import useCreateTimer from '../../utils/hooks/useCreateTimer';
import { NOTIFICATION_TIMER } from '../../constants/onboarding';
import STATUS from '../../constants/status';
import styles from './sass/NotionPopUp.module.scss';
import { ReactComponent as IconAlert } from '../../assets/images/icons/info.svg';
import { ReactComponent as IconSuccess } from '../../assets/images/icons/success.svg';
import { ReactComponent as IconClose } from '../../assets/images/icons/close.svg';
import { ReactComponent as IconWarning } from '../../assets/images/icons/warning.svg';
import { ReactComponent as TooltipAlert } from '../../assets/images/icons/screener/tooltipAlert.svg';

const NotionPopUp = () => {
  const popUp = useSelector((state) => state.authState.popUp, shallowEqual);
  const { message } = useSelector((state) => state.authState);
  const errorMessageTitle = useSelector((state) => state.authState.errorMessageTitle, shallowEqual);
  const notificationType = useSelector((state) => state.authState.notificationType, shallowEqual);
  const showNotionAnimation = useSelector((state) => state.authState.showNotionAnimation, shallowEqual);
  const dispatch = useDispatch();
  const timer = useCreateTimer(NOTIFICATION_TIMER);

  useEffect(() => {
    if (!popUp) {
      dispatch(popUpAction(false));
    }
    if (timer.counter === 0) {
      dispatch(popUpAction(false));
      dispatch(notionAnimationAction(false));
      dispatch(changeNotificationTypeAction({ type: 'error' }));
    }
  }, [popUp, timer]);

  return (
    <div
      className={`
        ${styles.NotionPopUp} ${styles[notificationType]} ${showNotionAnimation && styles.notionPopUpAnimation}
      `}
    >
      {notificationType === STATUS.SUCCESS && <IconSuccess />}
      {notificationType === STATUS.ALERT && <TooltipAlert />}
      {notificationType === STATUS.WARNING && <IconWarning />}
      {notificationType === STATUS.ERROR && <IconAlert />}
      <div className={styles.NotionPopUp_content}>
        <span className={`${styles.title} ${styles[`${notificationType}Text`]}`}>{errorMessageTitle}</span>
        <div className={`${styles.text} ${styles[`${notificationType}Text`]}`}>{message}</div>
      </div>
      <Button
        buttonClass={`${styles.close} ${styles[`${notificationType}Text`]}`}
        handleClick={() => dispatch(popUpAction(false))}
      >
        <IconClose />
      </Button>
    </div>
  );
};

export default NotionPopUp;
