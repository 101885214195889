import React, { SyntheticEvent } from 'react';

import CalendarSingle from '../Calendar/Calendar';
import TabsPeriodDate from '../TabsPeriodDate/TabsPeriodDate';
import CalendarPeriod from '../CalendarPeriod/CalendarPeriod';

interface IContainerPicker {
  startDate: Date;
  endDate: Date;
  columnId: number;
  applyText: string;
  activeTab: {
    after: boolean;
    before: boolean;
    range: boolean;
  };
  styleType: string;
  tabsHandler: (tab: string) => void;
  cancelText: string;
  cancelSelect: () => void;
  openCalendar: boolean;
  placeholder: string;
  categoryName: string;
  onFieldChange: (index: number, value: Date) => void;
  activeTabRange: boolean;
  applySelectDate: () => void;
  startSingleDate: Date | string;
  applySelectRange: (event: SyntheticEvent, date: string[], correct: number[]) => void;
  rangeButtonType: string;
  placeholderPeriod: string;
  setStartSingleDate: (date: Date) => void;
  handleJustOpenDatePicker: () => void;
  correctPeriod?: boolean;

}

const ContainerPicker = ({
  startDate,
  endDate,
  columnId,
  applyText,
  activeTab,
  styleType,
  tabsHandler,
  cancelText,
  cancelSelect,
  openCalendar,
  placeholder,
  categoryName,
  onFieldChange,
  activeTabRange,
  applySelectDate,
  startSingleDate,
  applySelectRange,
  rangeButtonType,
  placeholderPeriod,
  setStartSingleDate,
  handleJustOpenDatePicker,
  correctPeriod,
}: IContainerPicker): React.ReactElement => (
  <div>
    <TabsPeriodDate
      activeTab={activeTab}
      tabsHandler={tabsHandler}
      styleType={styleType}
    />
    {!activeTabRange ? (
      <CalendarSingle
        applySelectDate={applySelectDate}
        applyText={applyText}
        cancelSelect={cancelSelect}
        cancelText={cancelText}
        handleJustOpenDatePicker={handleJustOpenDatePicker}
        openCalendar={openCalendar}
        placeholder={placeholder}
        setStartDate={setStartSingleDate}
        startDate={startSingleDate}
      />
    ) : (
      <CalendarPeriod
        columnId={columnId}
        placeholder={placeholderPeriod}
        applySelectDate={applySelectRange}
        applyText={applyText}
        cancelSelect={cancelSelect}
        cancelText={cancelText}
        handleJustOpenDatePicker={handleJustOpenDatePicker}
        openCalendar={openCalendar}
        onFieldChange={onFieldChange}
        startDate={startDate}
        endDate={endDate}
        shortFilterName={rangeButtonType}
        idCategory={categoryName}
        correctPeriod={correctPeriod}
      />
    )}
  </div>
);

export default ContainerPicker;
