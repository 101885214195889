import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/TextWithImage.module.scss';
import TextField from '../TextField/TextField';
import Image from '../Image/Image';

const areEqual = (prevProps, nextProps) => {
  return prevProps.label === nextProps.label
    && prevProps.active === nextProps.active;
};

const TextWithImage = ({
  label, ImageSrc, active, styleText
}) => (
  <div className={`${styles.container} ${active ? styles.activeSelect : ''}`}>
    <TextField styleType={styleText} text={label} />
    {Object.keys(ImageSrc).length > 0 ? <ImageSrc /> : <Image src={ImageSrc} alt="arrow" />}
  </div>
);

TextWithImage.propTypes = {
  label: PropTypes.string.isRequired,
  ImageSrc: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  active: PropTypes.bool.isRequired,
  styleText: PropTypes.string,
};

TextWithImage.defaultProps = {
  styleText: '',
  ImageSrc: ''
};

export default React.memo(TextWithImage, areEqual);
