import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { WS_CHANNELS } from '../../constants/socketConnection';
import SocketContext from '../../context/SocketContext';
import styles from './sass/SymbolCounter.module.scss';

const SymbolCounter = ({
  counterSymbols,
  selectedItems,
  checkedItem,
  isHorizontalScroll
}) => {
  const { t } = useTranslation();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const { SocketScreener, AuthorizedScreener } = useContext(SocketContext);

  const positionCallback = (data) => {
    if (typeof data.symbolIndex !== 'undefined' && data.type === 'all') {
      if (data.columns[0] === 0) {
        setCurrentItemIndex(data.symbolIndexPosition);
      }
      if (data.symbolIndexPosition > data.total) {
        setCurrentItemIndex(0);
      }
    }
  };

  useEffect(() => () => {
    if (SocketScreener?.connected && AuthorizedScreener) {
      SocketScreener.removeListener(WS_CHANNELS.SCREENER_SUBSCRIBE, positionCallback);
    }
  }, []);

  useEffect(() => {
    setCurrentItemIndex(selectedItems);
  }, [selectedItems]);

  return (
    <div className={`${styles.container} ${isHorizontalScroll && styles.containerExtraClass}`}>
      {`${t('resultsSymbolCount', { select: counterSymbols === 0 ? 0 : currentItemIndex, all: counterSymbols })}
      (${t('selectedResult', { totalSelected: checkedItem })})`}
    </div>
  );
};

SymbolCounter.propTypes = {
  counterSymbols: PropTypes.number.isRequired,
  selectedItems: PropTypes.number.isRequired,
  checkedItem: PropTypes.number.isRequired,
  isHorizontalScroll: PropTypes.bool,
};

SymbolCounter.defaultProps = {
  isHorizontalScroll: false,
};

export default SymbolCounter;
