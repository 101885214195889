import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyChart from '../charts/EmptyChart';
import { EmptyChartNames } from '../types/WidgetChartsEnums';
import { IEmptyContainer } from '../types/WidgetChartsInterfaces';

const EmptyContainer = ({
  chartName,
  dataPointsXIndex,
  dataPointsYIndex,
  length,
  queryId,
  screener,
  type
}: IEmptyContainer): React.ReactElement | null => {
  const { t } = useTranslation();

  const handleShowEmpty = (): boolean => {
    if (chartName === EmptyChartNames.performanceChart) {
      return !!(queryId && (length === 0));
    }
    if (chartName === EmptyChartNames.heatMap) {
      return !!(queryId && (!length || !dataPointsXIndex));
    }
    return !!((!dataPointsXIndex || !dataPointsYIndex || !length) && queryId);
  };

  const getChartDescription = () => t('noResultsDetail', { type, screener });

  return handleShowEmpty() ? (
    <EmptyChart
      title={t('noResultsPerformance', { chartName })}
      description={getChartDescription()}
    />
  ) : null;
};

export default EmptyContainer;
