import { CustomIndicator, IContext, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyAvailableConstSources, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';

export default function percentSmaEma(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - % from SMA/EMA',
    metainfo: {
      id: 'DV-percentSMAEMA@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - % from SMA/EMA',
      description: 'DV - % from SMA/EMA',
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: 'inherit',
      },
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Below Extension 1'
            },
            1: {
              name: 'Between Extensions 1 & 2'
            },
            2: {
              name: 'Between Extensions 2 & 3'
            },
            3: {
              name: 'Above Extension 3'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
          }
        }
      },
      inputs: [
        {
          id: 'type',
          defval: 'SMA',
          name: 'Type',
          options: ['SMA', 'EMA'],
          type: 'text' as StudyInputType.Text
        },
        {
          id: 'ma_length',
          name: 'Length',
          type: 'integer' as StudyInputType.Integer,
          defval: 8,
        },
        {
          id: 'source',
          defval: 'close',
          name: 'Source',
          options: ['open', 'high', 'low', 'close'],
          type: 'source' as StudyInputType.Source,
        },
        {
          id: 'ext1',
          name: 'Extension 1',
          type: 'float' as StudyInputType.Float,
          defval: 5,
          step: 0.1
        },
        {
          id: 'ext2',
          name: 'Extension 2',
          type: 'float' as StudyInputType.Float,
          defval: 10,
          step: 0.1
        },
        {
          id: 'ext3',
          name: 'Extension 3',
          type: 'float' as StudyInputType.Float,
          defval: 20,
          step: 0.1
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        }
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: '% Change SMA/EMA'
        }
      },
      defaults: {
        inputs: {
          type: 'SMA',
          ma_length: 8,
          source: 'close',
          ext1: 5,
          ext2: 10,
          ext3: 20,
        },
        palettes: {
          palette_0: {
            colors: [
              { color: '#089981', style: 0, width: 3 },
              { color: '#FFEB3B', style: 0, width: 3 },
              { color: '#FF9800', style: 0, width: 3 },
              { color: '#F23645', style: 0, width: 3 },
            ]
          },
        },
        styles: {
          plot_0: {
            color: '#3A75E8',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 1,
            trackPrice: false,
            transparency: 0
          },
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [type, ma_length, source, ext1, ext2, ext3] = getInputArray({
          inputs,
          length: 6
        });

        const ma = calculateMA(mPineJS, this._context, source, type, ma_length);
        const price = mPineJS.Std[source as StudyAvailableSources](this._context);
        const percentDiff = (price - ma) / ma * 100;

        return [
          percentDiff,
          lineColorChecker(Math.abs(percentDiff), ext1, ext2, ext3)
        ];
      };
    }
  };
}

const calculateMA = (
  mPineJS: PineJS,
  mcontext: IContext,
  source: StudyAvailableConstSources,
  type: string,
  length: number,
) => {
  let result = 0;
  if (type == "EMA") {
    result = mPineJS.Std.ema(mcontext.new_var(mPineJS.Std[source as StudyAvailableSources](mcontext)), length, mcontext);
  } else if (type == "SMA") {
    result = mPineJS.Std.sma(mcontext.new_var(mPineJS.Std[source as StudyAvailableSources](mcontext)), length, mcontext);
  }
  return result;
}

export type StudyAvailableSources = "open" | "high" | "low" | "close" | "hl2" | "hlc3" | "ohlc4";

const lineColorChecker = (val: number, ext1: number, ext2: number, ext3: number): number => {
  if (val <= ext1) {
    return 0;
  } else if (val > ext1 && val <= ext2) {
    return 1
  } else if ((val > ext2 && val <= ext3)) {
    return 2;
  } else {
    return 3;
  }
}
