import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ButtonExportScreener from '../ButtonExportScreener/ButtonExportScreener';
import TwitterShare from '../TwitterShare/ButtonTwitterShare';
import ScreenerButtonGroup from '../ScreenerButtonGroup/ScreenerButtonGroup';
import NewScreenButtonGroup from '../NewScreenButtonGroup/NewScreenButtonGroup';
import SymbolSearch from './components/SymbolSearch';

import styles from './sass/ScreenerMenu.module.scss';
import GetPremiumButton from '../GetPremiumButton/GetPremiumButton';
import ScreenerMenuDivider from './components/ScreenerMenuDivider';
import { SCREENER_VIEWS } from '../../constants/screener';
import ScreenerMenuView from './components/ScreenerMenuView';
import ButtonChartRefresh from '../MultichartView/components/ButtonChartRefresh';
import MultiChartSettingsButton from '../MultichartView/components/MultiChartSettingsButton';
import ScreenerMenuOptions from './components/ScreenerMenuOptions';

const ScreenerMenu = ({
  disableExport,
  isFree,
  selectedItems,
  copySelectedSymbols,
  setIsVisibleFilters,
  currentScreensItem,
  currentScreensSubItem,
  currentPresetItem,
  setCurrentPresetItem,
  moreScreenItemVisible,
  moreScreenSubItemVisible,
  moreItemHandler,
  currentItemHandler,
  isLoading,
  openModalHandler,
  setHeaderFilter,
  currentItemSelected,
  setTitleScreenDrop,
  setHoveredPresetItem,
  isVisibleFilters,
  watchList,
  hideItem,
  setMoreScreenItemVisible,
  setFocus,
  currentModalRef,
  screenerView,
  searchHeaderValue,
  resetSearchHandler,
  subItemHoverHandler,
  activeSymbolIndex,
  handleRedirect,
}) => useMemo(() => {
  return (
    <div className={`${styles.container} ${watchList && styles.watchlistContainer}`}>
      {!watchList && selectedItems.length === 0 && (
        <div className={`${styles.wrapperLeft} ${isFree ? styles.blurWrapper : ''}`}>
          <SymbolSearch
            setFocus={setFocus}
            search={searchHeaderValue}
            resetSearchHandler={resetSearchHandler}
            isFree={isFree}
          />
        </div>
      )}
      {selectedItems.length > 0 && (
        <div className={`${styles.buttonGroup} ${styles.buttonGroupWrapperWatchlist}`}>
          <ScreenerButtonGroup
            handleCopySymbols={copySelectedSymbols}
            watchList={watchList}
          />
        </div>
      )}
      <NewScreenButtonGroup
        searchHeaderValue={searchHeaderValue}
        isVisibleFilters={isVisibleFilters}
        activeSymbolIndex={activeSymbolIndex}
        setHoveredPresetItem={setHoveredPresetItem}
        openModalHandler={openModalHandler}
        moreItemHandler={moreItemHandler}
        currentItemHandler={currentItemHandler}
        moreScreenItemVisible={moreScreenItemVisible}
        isFree={isFree}
        moreScreenSubItemVisible={moreScreenSubItemVisible}
        currentPresetItem={currentPresetItem}
        setCurrentPresetItem={setCurrentPresetItem}
        currentScreensItem={currentScreensItem}
        currentScreensSubItem={currentScreensSubItem}
        setIsVisibleFilters={setIsVisibleFilters}
        setHeaderFilter={setHeaderFilter}
        currentItemSelected={currentItemSelected}
        setTitleScreenDrop={setTitleScreenDrop}
        hideItem={hideItem}
        setMoreScreenItemVisible={setMoreScreenItemVisible}
        currentModalRef={currentModalRef}
        subItemHoverHandler={subItemHoverHandler}
        resetSearchHandler={resetSearchHandler}
      />
      <div className={styles.wrapperRight}>
        {screenerView === SCREENER_VIEWS.CHART && (
          <>
            <MultiChartSettingsButton />
            <ButtonChartRefresh />
          </>
        )}
        <ButtonExportScreener
          isFree={isFree}
          watchList={watchList}
          screenerView={screenerView}
          disableExport={disableExport}
          selectedItems={selectedItems}
        />
        <TwitterShare />
        {isFree && <GetPremiumButton handleRedirect={handleRedirect} />}
        <ScreenerMenuDivider />
        <ScreenerMenuOptions />
        <ScreenerMenuDivider />
        <ScreenerMenuView />
      </div>
    </div>
  );
}, [
  setFocus,
  disableExport,
  isFree,
  isLoading,
  moreScreenItemVisible,
  moreScreenSubItemVisible,
  currentPresetItem,
  currentScreensItem,
  currentItemSelected,
  isVisibleFilters,
  selectedItems,
  currentScreensSubItem,
  searchHeaderValue,
  screenerView
]);

ScreenerMenu.propTypes = {
  watchList: PropTypes.bool,
  setHoveredPresetItem: PropTypes.func,
  currentItemHandler: PropTypes.func,
  moreScreenItemVisible: PropTypes.bool,
  moreScreenSubItemVisible: PropTypes.bool,
  currentPresetItem: PropTypes.number,
  isLoading: PropTypes.bool,
  setCurrentPresetItem: PropTypes.func,
  currentScreensItem: PropTypes.number,
  currentScreensSubItem: PropTypes.number,
  connectTwitter: PropTypes.func.isRequired,
  copySelectedSymbols: PropTypes.func.isRequired,
  disableExport: PropTypes.bool,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired,
  setIsVisibleFilters: PropTypes.func,
  setHeaderFilter: PropTypes.func,
  currentItemSelected: PropTypes.func,
  setTitleScreenDrop: PropTypes.func,
  isFree: PropTypes.bool,
  openModalHandler: PropTypes.func,
  moreItemHandler: PropTypes.func,
  isVisibleFilters: PropTypes.bool,
  hideItem: PropTypes.func,
  setMoreScreenItemVisible: PropTypes.func,
  searchHeaderValue: PropTypes.string,
  setSearch: PropTypes.func,
  currentModalRef: PropTypes.shape({}),
  resetSearchHandler: PropTypes.func,
  subItemHoverHandler: PropTypes.func,
};

ScreenerMenu.defaultProps = {
  setMoreScreenItemVisible: () => undefined,
  hideItem: () => undefined,
  setIsVisibleFilters: () => undefined,
  setTitleScreenDrop: () => undefined,
  currentItemSelected: () => undefined,
  setHeaderFilter: () => undefined,
  currentScreensSubItem: -1,
  currentScreensItem: -1,
  setCurrentPresetItem: () => undefined,
  currentPresetItem: -1,
  isFree: false,
  moreScreenSubItemVisible: false,
  moreScreenItemVisible: false,
  currentItemHandler: () => undefined,
  hoveredPresetItem: -1,
  setHoveredPresetItem: () => undefined,
  isVisibleFilters: false,
  watchList: false,
  disableExport: true,
  isLoading: false,
  openModalHandler: () => undefined,
  moreItemHandler: () => undefined,
  search: '',
  setSearch: () => undefined,
  currentModalRef: null,
  resetSearchHandler: () => undefined,
  subItemHoverHandler: () => undefined,
};

export default ScreenerMenu;
