import { useDrag, useDrop } from 'react-dnd';

const ItemTypes = {
  TAB: 'tab',
};

const useDnDtabs = (ref, index, id, changeOrderHandler, endDragHandler, selectedId) => {
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.TAB,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(dragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      changeOrderHandler(dragIndex, hoverIndex);

      dragItem.index = hoverIndex; // eslint-disable-line
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TAB,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: (dragFunction) => {
      const isTouchScreen = (navigator.msMaxTouchPoints > 0) || (navigator.maxTouchPoints > 0);
      const isCurrentTab = selectedId === id;

      if (!isTouchScreen) {
        return dragFunction;
      }

      return isCurrentTab ? dragFunction : undefined;
    },
    end: (dragItem) => {
      endDragHandler(dragItem);
    }
  });

  return {
    drop,
    drag,
    isDragging,
    handlerId,
  };
};

export default useDnDtabs;
