import React from 'react';
import styles from '../sass/PositionSize.module.scss';
import { IFormLayout } from '../types/PostionSizeInterfaces';

const FormLayout = ({ children, title }: IFormLayout): React.ReactElement => (
  <div className={styles.row}>
    <p className={styles.row_title}>{title}</p>
    <div className={styles.inputRows}>
      {children}
    </div>
  </div>
);

export default FormLayout;
