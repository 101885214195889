import React, { useState, useEffect, useMemo } from 'react';

import FilterLayout from '../../layout/FilterLayout/FilterLayout';
import RangePanel from '../FilterGroupEmaSma/RangePanel';
import filtersSwitchButton from '../../lists/filtersSwitchButton';
import filters from '../../constants/filters';
import { initTab } from '../../utils/commonGroupFilterHandler';
import { TValueItem } from '../FilterScreen/Types';

interface IFilterRange {
  columnId: number;
  title: string;
  disabled: boolean;
  disabledHeart: boolean;
  setArray: (array: (number | number[])[]) => void;
  viewRange: boolean;
  initialMin: number;
  initialMax: number;
  typeData: string;
  minValue: number;
  maxValue: number;
  filterItems: TValueItem[];
  searchFilter: string;
  description: string;
  category: string;
  noOptionsAvailable: string;
}

const FilterRange = ({
  columnId, title, disabled, disabledHeart, setArray, viewRange,
  initialMin, initialMax, typeData, minValue, maxValue, filterItems,
  searchFilter, description, category, noOptionsAvailable
}: IFilterRange): React.ReactElement => {
  const [handleTab, setHandleTab] = useState(false);
  const [activeTab, setActiveTabs] = useState<Record<string, boolean>>({
    greater: true,
    less: false,
    range: false
  });

  const tabsHandler = (item: string): void => {
    const body: {
      [key: string]: boolean
    } = { ...filters.initialBody };
    setHandleTab(true);
    body[item] = true;
    setActiveTabs(body);
  };

  useEffect(() => {
    initTab(setHandleTab);
  }, [searchFilter]);

  return useMemo(() => (
    <FilterLayout
      id={columnId}
      title={title}
      category={category}
      columnId={columnId}
      disabled={disabled}
      description={description}
      disabledHeart={disabledHeart}
      addButtonHandler={() => undefined}
    >
      <RangePanel
        activeTab={activeTab}
        tabsHandler={tabsHandler}
        viewRange={viewRange}
        initialMin={initialMin}
        initialMax={initialMax}
        setArray={setArray}
        columnId={columnId}
        typeData={typeData}
        minValue={minValue}
        maxValue={maxValue}
        listButton={filtersSwitchButton}
        handleTab={handleTab}
        noOptionsAvailable={noOptionsAvailable}
      />
    </FilterLayout>
  ), [
    viewRange,
    filterItems,
    searchFilter,
    activeTab,
    setArray
  ]);
};

export default FilterRange;
