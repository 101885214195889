import React, { useState, useEffect } from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { checkRoleUser } from '../../utils/userHelper';
import { AddMenuActions, DasboardsItemsDropdownTypes, DialogItemEntityType } from './types/constants';
import useSearchSortDashboardItems from './hooks/useSearchSortDashboardItems';
import ItemDropdownTabs from './components/ItemDropdownTabs';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { IDashboardsItemDropdown } from './types/interfaces';
import { useTheme } from '../../utils/hooks/useTheme';
import { DashboardItemsDialogActionTypes, DialogItemDataTypes } from '../DashboardItemsDialog/types/constants';
import { TDashboardItemsData } from '../../utils/Types';
import useFavoriteHooks from './hooks/FaworiteWorker';
import {
  DASHBOARD_DROPDOWN_WIDTH,
  EDIT_MODAL_TYPES,
  SUBSCRIPTION_TYPE,
  TABLE_SCREEN_PATH
} from '../../constants/screener';
import RootStateTypes from '../../store/RootStateTypes';
import useNewScreen from '../../utils/hooks/useNewScreen';

const DashboardsItemDropdown = ({
  savedItems,
  presetItems,
  isOpen,
  setIsOpen,
  parrentRef,
  topMargin,
  labelSaved,
  labelPreset,
  labelAdd,
  labelDescription,
  iconAddMenu,
  actionCreateNewCallback,
  actionEditCallback,
  selectedItem,
  itemType,
  currentItemHandler,
  updateAfterDragActions
}: IDashboardsItemDropdown): React.ReactElement => {
  const { theme } = useTheme();

  const lastPath = useSelector((state:RootStateTypes) => state.accountState.userSettings.lastTablePath, shallowEqual);

  const [positionStyles, setPositionStyles] = useState({
    top: 0,
    left: 0,
  });

  const [updateState, setUpdateState] = useState(false);

  const { linkToWatchlist, unLinkFromWatchlist } = useFavoriteHooks();
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const {
    open,
    actionOpenDialog,
    actionCancelDialog,
    dialogData,
  } = UseDashboardsDialogs();

  const {
    sortedSavedItems,
    sortedPresetItems,
    selectedTab,
    setSelectedTab,
    sort,
    setSort,
    search,
    setSearch,
  } = useSearchSortDashboardItems(savedItems, presetItems, selectedItem, isOpen);

  const { shareDashboardItem } = useNewScreen();

  const handleSetTab = (tab: DasboardsItemsDropdownTypes): void => {
    setSelectedTab(tab);
  };

  const internalOrExternalAdd = (): void => {
    if ((itemType === DialogItemDataTypes.SCREENER || itemType === DialogItemDataTypes.DEEP_LIST
        || itemType === DialogItemDataTypes.DATA_PANEL || itemType === DialogItemDataTypes.DASHBOARD
        || itemType === DialogItemDataTypes.COLUMN_SET)
      && typeof actionCreateNewCallback === 'function') {
      actionCreateNewCallback(selectedTab);
    } else {
      actionOpenDialog(DashboardItemsDialogActionTypes.ADD, itemType, DialogItemEntityType.ITEM, selectedTab);
    }
  };

  const internalOrExternalEdit = (item: TDashboardItemsData): void => {
    if (itemType === DialogItemDataTypes.SCREENER && typeof actionCreateNewCallback === 'function') {
      actionEditCallback(item);
    } else {
      actionOpenDialog(DashboardItemsDialogActionTypes.EDIT, itemType, DialogItemEntityType.ITEM, selectedTab, item);
    }
  };

  const handleAddItem = (type: AddMenuActions): void => {
    if (type === AddMenuActions.ADD_FOLDER) {
      actionOpenDialog(DashboardItemsDialogActionTypes.ADD, itemType, DialogItemEntityType.FOLDER, selectedTab);
    } else {
      internalOrExternalAdd();
    }
    setIsOpen(false);
  };

  const handleEditItem = (item: TDashboardItemsData): void => {
    if (item?.type === EDIT_MODAL_TYPES.FOLDER) {
      actionOpenDialog(DashboardItemsDialogActionTypes.EDIT, itemType, DialogItemEntityType.FOLDER, selectedTab, item);
    } else {
      internalOrExternalEdit(item);
    }
  };

  const handleDeleteItem = (item: TDashboardItemsData): void => {
    const type = item?.type === EDIT_MODAL_TYPES.FOLDER ? DialogItemEntityType.FOLDER : DialogItemEntityType.ITEM;
    actionOpenDialog(DashboardItemsDialogActionTypes.DELETE, itemType, type, selectedTab, item);
  };

  const handleDuplicateItem = (item: TDashboardItemsData): void => {
    actionOpenDialog(DashboardItemsDialogActionTypes.DUPLICATE, itemType, DialogItemEntityType.ITEM, selectedTab, item);
  };

  const handleShareItem = (item: TDashboardItemsData): void => {
    shareDashboardItem(item?.id, item?.type, item?.title);
  };

  const handleMoveItem = (item: TDashboardItemsData): void => {
    actionOpenDialog(DashboardItemsDialogActionTypes.MOVE_TO, itemType, DialogItemEntityType.ITEM, selectedTab, item);
  };

  const handleRemoveItem = (item: TDashboardItemsData): void => {
    actionOpenDialog(DashboardItemsDialogActionTypes.REMOVE, itemType, DialogItemEntityType.ITEM, selectedTab, item);
  };

  const handleSetFavorite = (item: TDashboardItemsData): void => {
    if (itemType === DialogItemDataTypes.SCREENER) {
      if (item?.isFavorite) {
        unLinkFromWatchlist(item?.id, item?.title, selectedTab);
      } else {
        linkToWatchlist(item?.id, item?.title, selectedTab);
      }
    }
  };

  useEffect(() => {
    if (parrentRef.current) {
      const { top, left, height } = parrentRef.current.getBoundingClientRect();
      const documentHeight = document.body.getBoundingClientRect().height;
      const documentWidth = document.body.getBoundingClientRect().width;
      const topOffset = documentHeight - (documentHeight - top);
      let deltaTop = top + height + topMargin;
      let deltaLeft = left;
      if (itemType === DialogItemDataTypes.DEEP_LIST) {
        deltaLeft = left + 156;
        if (lastPath === TABLE_SCREEN_PATH.DASHBOARDS) {
          deltaLeft = left;
        }
      }
      if (itemType === DialogItemDataTypes.DATA_PANEL) deltaLeft = left - 170;
      if ((documentHeight - top < 430) && topOffset > 430) {
        deltaTop = top - 440;
      } else if ((documentHeight - top < 430) && topOffset < 430) {
        deltaTop = documentHeight - 440;
        deltaLeft = left + 156;
      }
      if (deltaLeft + DASHBOARD_DROPDOWN_WIDTH >= documentWidth) {
        deltaLeft = documentWidth - DASHBOARD_DROPDOWN_WIDTH;
      }
      setPositionStyles({ top: deltaTop, left: deltaLeft });
    }
  }, [isOpen, parrentRef.current]);

  useEffect(() => {
    if (updateState) {
      updateAfterDragActions();
      setUpdateState(false);
    }
  }, [selectedTab]);

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          currentItemHandler={currentItemHandler}
          adminRole={adminRole}
        />
      )}
      <ItemDropdownTabs
        handleAddItem={handleAddItem}
        selectedTab={selectedTab}
        handleSetTab={handleSetTab}
        sortedSavedItems={sortedSavedItems}
        sortedPresetItems={sortedPresetItems}
        positionStyles={positionStyles}
        isOpen={isOpen}
        labelPreset={labelPreset}
        labelSaved={labelSaved}
        labelAdd={labelAdd}
        labelDescription={labelDescription}
        iconAddMenu={iconAddMenu}
        sortStatus={sort}
        search={search}
        setSearch={setSearch}
        setSort={setSort}
        setIsOpen={setIsOpen}
        theme={theme}
        selectedItem={selectedItem}
        setSelectedItem={currentItemHandler}
        actionDuplicate={handleDuplicateItem}
        actionShare={handleShareItem}
        actionDelete={handleDeleteItem}
        actionEdit={handleEditItem}
        actionMove={handleMoveItem}
        actionFavorite={handleSetFavorite}
        itemType={itemType}
        actionRemove={handleRemoveItem}
        adminRole={adminRole}
        setUpdateState={setUpdateState}
      />
    </>
  );
};

export default DashboardsItemDropdown;
