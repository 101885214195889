export const DATE_CONSTANTS = {
  SHIFT_DATE: 1000,
  HOUR: 3600,
  DAY: 86400,
  WEEK: 604800,
  TWO_WEEK: 1209600,
  MONTH: 2629743,
  YEAR: 31556926
};

export const EARNING_TIME = {
  835: 'AMC',
  223: '',
  13561: 'BTO',
};

export default DATE_CONSTANTS;
