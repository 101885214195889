import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Gleap from 'gleap';
import styles from './sass/ButtonSupport.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import { PRODUCTION } from '../../constants/environment';

const ButtonSupport = ({
  IconElement,
  text,
  className,
  isVisible,
  educationBtn,
  setShowMarket,
  setShowProfile,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === PRODUCTION) {
      Gleap.initialize(`${process.env.REACT_APP_GLEAP_KEY}`);
    }
  }, []);

  const openSupport = () => {
    if (Gleap.isOpened()) {
      Gleap.close();
    } else if (educationBtn) {
      Gleap.openHelpCenter();
    } else {
      Gleap.open();
      setShowMarket(false);
      setShowProfile(false);
    }
  };

  return (
    <div
      className={styles.sideBarItemWrapper}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <DefaultButton handleClick={openSupport} className={className}>
        <IconElement />
        <span>{text}</span>
      </DefaultButton>
      {visible && !isVisible ? <div className={styles.sideBarInfoTool}>{text}</div> : null}
    </div>
  );
};

ButtonSupport.propTypes = {
  IconElement: PropTypes.shape({}),
  text: PropTypes.string,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  educationBtn: PropTypes.bool,
  setShowMarket: PropTypes.func.isRequired,
  setShowProfile: PropTypes.func.isRequired,
};

ButtonSupport.defaultProps = {
  IconElement: null,
  text: '',
  className: '',
  isVisible: false,
  educationBtn: false
};

export default React.memo(ButtonSupport);
