import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './sass/ComingSoon.module.scss';
import { ReactComponent as IconTime } from '../../assets/images/icons/accountSettings/timeCircle.svg';

const ComingSoonTooltip = ({ tooltipClass, hasIcon, title }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.tooltip} ${tooltipClass}`}
    >
      <div className={styles.tooltip_wrapper}>
        {hasIcon && (
          <IconTime />
        )}
        {!title ? t('commingSoon') : title}
      </div>
    </div>
  );
};

ComingSoonTooltip.defaultProps = {
  tooltipClass: '',
  title: '',
  hasIcon: true,
};

ComingSoonTooltip.propTypes = {
  tooltipClass: PropTypes.string,
  hasIcon: PropTypes.bool,
  title: PropTypes.string
};

export default ComingSoonTooltip;
