import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DEFAULT_COLUMNS } from '../../../constants/screener';
import { setColumnListAction } from '../../../store/screener/actions';

const usePrepareColumnsData = () => {
  const dispatch = useDispatch();
  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const groupsList = useSelector((state) => state.screenerState.groupsList, shallowEqual);

  const preparedColumnsHandler = () => {
    const data = [];
    groupsList?.map((item) => data.push({
      groupName: item.name ? item.name : 'UnGrouping', groupId: item.index, items: []
    }));

    data.forEach((itemGroup, index) => {
      columnsList?.map((itemColumn) => itemColumn.enabled && itemColumn.show
        && itemGroup.groupId === itemColumn.group && !DEFAULT_COLUMNS.includes(itemColumn.name)
        && data[index].items.push({
          name: itemColumn.name === 'Timestamp' ? 'Date' : itemColumn.name,
          type: itemColumn.type,
          id: itemColumn.index,
          group: itemColumn.group
        }));
    });
    dispatch(setColumnListAction({ data }));
  };

  return {
    preparedColumnsHandler
  };
};

export default usePrepareColumnsData;
