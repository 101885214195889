import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

import styles from '../sass/DataPanel.module.scss';
import TextWithTooltip from './TextWithTooltip';
import DataPanelDynamicContentItem from './DataPanelDynamicContentItem';
import IndustryDropDown from './IndustryDropDown';
import { ReactComponent as IconStroke } from '../../../assets/images/icons/screener/color_stroke.svg';
import {
  groupIndex,
  industryGroupIndexes,
  industryIndex,
  sectorIndex,
  subIndustryIndex
} from '../../../constants/dataPanel';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import {
  DATA_PANEL_SETTINGS,
  IS_GROUP_OPEN,
  IS_INDUSTRY_OPEN,
  IS_SECTOR_OPEN,
  IS_SUB_INDUSTRY_OPEN
} from '../../../constants/storage';

const DataPanelContentItem = ({
  dataPoint, screenerId, staticData, symbolIndex, getPointData
}) => {
  const { updateStoreHandler } = useLastStateUpdateStore();
  const industryDataRows = useSelector((state) => state.tableDataState.industryDataRows, shallowEqual);
  const dataPanelSettings = useSelector(
    (state) => state.accountState.userSettings.dataPanelSettings, shallowEqual
  );
  const industryDataColumns = useSelector((state) => state.tableDataState.industryDataColumns, shallowEqual);
  const sectorDataRows = useSelector((state) => state.tableDataState.sectorDataRows, shallowEqual);
  const sectorDataColumns = useSelector((state) => state.tableDataState.sectorDataColumns, shallowEqual);
  const groupDataRows = useSelector((state) => state.tableDataState.groupDataRows, shallowEqual);
  const groupDataColumns = useSelector((state) => state.tableDataState.groupDataColumns, shallowEqual);
  const subIndustryDataRows = useSelector((state) => state.tableDataState.subIndustryDataRows, shallowEqual);
  const subIndustryDataColumns = useSelector((state) => state.tableDataState.subIndustryDataColumns, shallowEqual);
  const industryScreenerId = useSelector(
    (state) => state.tableDataState.industryScreenerId, shallowEqual
  );
  const sectorScreenerId = useSelector(
    (state) => state.tableDataState.sectorScreenerId, shallowEqual
  );
  const groupScreenerId = useSelector(
    (state) => state.tableDataState.groupScreenerId, shallowEqual
  );
  const subIndustryScreenerId = useSelector(
    (state) => state.tableDataState.subIndustryScreenerId, shallowEqual
  );
  const [currentData, setCurrentData] = useState(null);

  const isIndustry = industryGroupIndexes.includes(dataPoint?.value);

  const getData = (dataPointValue) => {
    let data;
    switch (dataPointValue) {
      case groupIndex: {
        data = [
          groupDataRows, groupDataColumns, groupScreenerId, IS_GROUP_OPEN,
          dataPanelSettings.isGroupOpen
        ];
        break;
      }
      case industryIndex: {
        data = [
          industryDataRows, industryDataColumns, industryScreenerId,
          IS_INDUSTRY_OPEN, dataPanelSettings.isIndustryOpen
        ];
        break;
      }
      case sectorIndex: {
        data = [
          sectorDataRows, sectorDataColumns, sectorScreenerId, IS_SECTOR_OPEN,
          dataPanelSettings.isSectorOpen
        ];
        break;
      }
      case subIndustryIndex: {
        data = [
          subIndustryDataRows, subIndustryDataColumns, subIndustryScreenerId, IS_SUB_INDUSTRY_OPEN,
          dataPanelSettings.isSubIndustryOpen
        ];
        break;
      }
      default: {
        data = [
          null, null, null, null, null
        ];
        break;
      }
    }
    return data;
  };

  const [dataRows, dataColumns, dataScreenerId, stringValueIsOpen, isOpen] = getData(dataPoint?.value);

  const setOpenDropdown = () => {
    if (isIndustry) {
      updateStoreHandler(DATA_PANEL_SETTINGS, {
        ...dataPanelSettings,
        [stringValueIsOpen]: !isOpen
      });
    }
  };

  useEffect(() => {
    setCurrentData(staticData);
  }, [symbolIndex, staticData]);

  return (
    <div
      className={`${styles.dataPanelItemCard} ${isIndustry && styles.cursorPointer}`}
    >
      <div
        className={styles.dataPanelItemEdit}
        aria-hidden
        onClick={setOpenDropdown}
      >
        <div className={`${styles.dataPanelItemEditTitle} ${styles.short}`}>
          <TextWithTooltip title={dataPoint?.label} />
        </div>
        {isIndustry ? (
          <div className={styles.dataPanelItemEditContentItem}>
            <DataPanelDynamicContentItem
              dataPoint={dataPoint}
              screenerId={screenerId}
              symbolIndex={symbolIndex}
              currentData={currentData}
            />
            <IconStroke className={styles[isOpen ? 'activeStroke' : 'defaultStroke']} />
          </div>
        ) : (
          <DataPanelDynamicContentItem
            dataPoint={dataPoint}
            screenerId={screenerId}
            symbolIndex={symbolIndex}
            currentData={currentData}
          />
        )}
      </div>
      {
        isOpen && (
          <>
            <div className={styles.divider} />
            <IndustryDropDown
              dataColumns={dataColumns}
              dataRows={dataRows}
              screenerId={dataScreenerId}
              getPointData={getPointData}
            />
          </>
        )
      }
    </div>
  );
};

DataPanelContentItem.propTypes = {
  dataPoint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
  }),
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  staticData: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  symbolIndex: PropTypes.number.isRequired,
  getPointData: PropTypes.func,
};

DataPanelContentItem.defaultProps = {
  screenerId: null,
  staticData: null,
  dataPoint: null,
  getPointData: () => null
};

export default DataPanelContentItem;
