import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';

import moment from 'moment/moment';
import { growth } from '../../constants/statsChart';
import StatsChartView from './StatsChartView';
import {
  CHECKED_FILTERS, GROWS_DATA
} from '../../constants/storage';
import { defaultSelectedQuarterHandler, selectedQuarterHandler } from '../../utils/helpers';
import { updateStoreAction } from '../../store/account/actions';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';

const StatsChart = ({ selectedChart, showStats, setHeight }) => {
  const { t } = useTranslation();

  const mergeTimestamp = useSelector((state) => state.statsChartState.mergeTimestamp, shallowEqual);
  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);
  const estimateVisibleData = useSelector((state) => state.screenerState.estimateVisibleData, shallowEqual);
  const statsChartData = useSelector((state) => state.statsChartState.lineChart, shallowEqual);
  const storedNumberOfQuarters = useSelector((state) => state.accountState.userSettings.numberOfQuarters, shallowEqual);
  const storedCheckedFilters = useSelector((state) => state.accountState.userSettings.checkedFilters, shallowEqual);
  const storedGrowsFlag = useSelector((state) => state.accountState.userSettings.growsData, shallowEqual);
  const { updateStoreHandler } = useLastStateUpdateStore();

  const [currentData, setCurrentData] = useState(statsChartData[0]);
  const [selectedQuarter, setSelectedQuarter] = useState(
    [currentData.length - storedNumberOfQuarters, currentData.length]
  );

  const { ref, width, height } = useResizeDetector();

  const selectedGrowsFlag = (item) => {
    updateStoreHandler(GROWS_DATA, item);
  };

  const checkedFiltersHandler = (item) => {
    if (storedCheckedFilters.filter((el) => el === item).length > 0) {
      updateStoreHandler(CHECKED_FILTERS, storedCheckedFilters.filter((el) => el !== item));
    } else {
      updateStoreHandler(CHECKED_FILTERS, [...storedCheckedFilters, item]);
    }
  };

  const selectedQuarterPlus = useCallback(() => {
    if (selectedQuarter[1] !== currentData.length) {
      setSelectedQuarter([selectedQuarter[0] + 1, selectedQuarter[1] + 1]);
    }
  }, [selectedQuarter, estimateVisibleData]);

  const selectedQuarterMinus = useCallback(() => {
    if (selectedQuarter[0] !== 0) {
      setSelectedQuarter([selectedQuarter[0] - 1, selectedQuarter[1] - 1]);
    }
  }, [selectedQuarter, estimateVisibleData]);

  useEffect(() => {
    const prevQuarter = moment(mergeTimestamp).subtract(1, 'quarter');
    const currentSubData = statsChartData[storedGrowsFlag === growth ? 1 : 0];

    const lastDataIndex = statsChartData[storedGrowsFlag === growth ? 1 : 0].findIndex((item) => {
      return item.quarter === `${prevQuarter.year()}: Q${prevQuarter.quarter()}`;
    });

    const { eightQuartersResult, fourQuartersResult } = selectedQuarterHandler(currentSubData, lastDataIndex);
    const { eightQuartersDefaultResult, fourQuartersDefaultResult } = defaultSelectedQuarterHandler(currentSubData);

    setCurrentData(currentSubData);

    if (lastDataIndex >= 0) {
      setSelectedQuarter(storedNumberOfQuarters === 4 ? fourQuartersResult : eightQuartersResult);
    } else {
      setSelectedQuarter(storedNumberOfQuarters === 4 ? fourQuartersDefaultResult : eightQuartersDefaultResult);
    }
  }, [storedGrowsFlag, statsChartData, storedNumberOfQuarters, estimateVisibleData]);

  useEffect(() => {
    if (height) {
      setHeight(height);
    }
  }, [height]);

  return (
    <StatsChartView
      setStoredStatsSources={updateStoreAction}
      setCheckedFilters={checkedFiltersHandler}
      selectedChart={selectedChart}
      isGrowth={storedGrowsFlag}
      selectedQuarterPlus={selectedQuarterPlus}
      selectedQuarterMinus={selectedQuarterMinus}
      width={width}
      height={height}
      buttonTitle={t('upgradeToSeeFullStats')}
      wrapperRef={ref}
      chartSymbolData={currentData.slice(selectedQuarter[0], selectedQuarter[1])}
      checkedFilters={storedCheckedFilters}
      setIsGrowth={selectedGrowsFlag}
      profile={profile}
      subcritionPlan={profile?.role}
      numberOfQuarters={storedNumberOfQuarters}
      showStats={showStats}
    />
  );
};

StatsChart.propTypes = {
  selectedChart: PropTypes.string.isRequired,
  showStats: PropTypes.bool.isRequired,
  setHeight: PropTypes.func.isRequired,
};

export default React.memo(StatsChart);
