import React, {
  useCallback, useContext, useEffect, useState
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ScreenerMenu from '../../../components/ScreenerMenu/ScreenerMenu';
import OUTER_URLS from '../../../constants/outerLinks';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { SUBSCRIPTION_TYPE } from '../../../constants/screener';
import { getItemsId } from '../../../store/newScreen/actions';
import { setSelectedItems } from '../../../store/selectSymbol/slice';
import { setMarketsFilterScreenerAction } from '../../../store/screener/actions';
import { changeTabAction } from '../../../store/tableData/slice';
import TableContext from '../context/TableContext';

const ScreenerHeader = () => {
  const {
    setFocus,
    activeSymbolIndexValue,
    selectedItems,
    disableExport,
    screenerView,
    setHeaderFilter,
    moreItemHandler,
    openModalHandler,
    isVisibleFilters,
    currentPresetItem,
    currentScreensItem,
    setTitleScreenDrop,
    currentItemHandler,
    copySelectedSymbols,
    setIsVisibleFilters,
    currentItemSelected,
    setHoveredPresetItem,
    setCurrentPresetItem,
    currentScreensSubItem,
    moreScreenItemVisible,
    setMoreScreenItemVisible,
    moreScreenSubItemVisible,
    currentModalRef,
    searchHeaderValue,
    resetSearchHandler,
    subItemHoverHandler,
  } = useContext(TableContext);
  const [refreshScreener, setRefreshScreener] = useState(false);

  const dispatch = useDispatch();
  const handleRedirect = useCallback(() => window.location.replace(OUTER_URLS.PRICING), []);
  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);
  const selectedScreenId = useSelector((state) => state.accountState.userSettings.selectedScreenId, shallowEqual);
  const requestStateFilters = useSelector((state) => state.newScreenState.requestStateFilters, shallowEqual);

  const {
    getItemsPreset, getItems, getItemId, hideItem
  } = useNewScreen();

  const showResult = useCallback(() => {
    dispatch(changeTabAction());
    const dataFilters = requestStateFilters && requestStateFilters.length > 0 && requestStateFilters[0]?.length > 0
      ? requestStateFilters : [];
    dispatch(setMarketsFilterScreenerAction({ data: dataFilters }));
    setIsVisibleFilters(false);
  }, [requestStateFilters]);

  useEffect(() => {
    getItemsPreset();
  }, []);

  useEffect(() => {
    if (profile && profile?.role !== SUBSCRIPTION_TYPE.FREE) {
      getItems();
    }
  }, [profile]);

  useEffect(() => {
    if (selectedScreenId !== 0) {
      getItemId(selectedScreenId);
      setRefreshScreener(true);
    } else {
      dispatch(getItemsId({}));
    }
    if (selectedItems?.length > 0) {
      dispatch(setSelectedItems([]));
    }
  }, [selectedScreenId]);

  useEffect(() => {
    if (!isVisibleFilters && refreshScreener) {
      showResult();
      setRefreshScreener(false);
    }
  }, [requestStateFilters]);

  return (
    <ScreenerMenu
      setFocus={setFocus}
      handleRedirect={handleRedirect}
      activeSymbolIndex={activeSymbolIndexValue}
      hideItem={hideItem}
      isFree={false}
      selectedItems={selectedItems}
      disableExport={disableExport}
      connectTwitter={() => undefined}
      screenerView={screenerView}
      setHeaderFilter={setHeaderFilter}
      moreItemHandler={moreItemHandler}
      openModalHandler={openModalHandler}
      isVisibleFilters={isVisibleFilters}
      currentPresetItem={currentPresetItem}
      currentScreensItem={currentScreensItem}
      setTitleScreenDrop={setTitleScreenDrop}
      currentItemHandler={currentItemHandler}
      copySelectedSymbols={copySelectedSymbols}
      setIsVisibleFilters={setIsVisibleFilters}
      currentItemSelected={currentItemSelected}
      setHoveredPresetItem={setHoveredPresetItem}
      setCurrentPresetItem={setCurrentPresetItem}
      currentScreensSubItem={currentScreensSubItem}
      moreScreenItemVisible={moreScreenItemVisible}
      setMoreScreenItemVisible={setMoreScreenItemVisible}
      moreScreenSubItemVisible={moreScreenSubItemVisible}
      currentModalRef={currentModalRef}
      searchHeaderValue={searchHeaderValue}
      resetSearchHandler={resetSearchHandler}
      subItemHoverHandler={subItemHoverHandler}
    />
  );
};

export default ScreenerHeader;
