import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { MODAL_TITLES, TEMPLATE_TYPE } from '../../../constants/screener';
import Button from '../../../components/Button/Button';
import styles from '../sass/TableScreener.module.scss';
import { setSharedDashboardItem } from '../../../store/screener/actions';

const SharedSymbolRow = ({
  openModalHandler
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sharedDashboardItem = useSelector((state) => state.screenerState.sharedDashboardItem, shallowEqual);

  const itemType = sharedDashboardItem?.type;
  const itemTitle = sharedDashboardItem?.title;

  const handleCancel = () => {
    dispatch(setSharedDashboardItem({}));
  };

  const getTitle = () => {
    switch (itemType) {
      case TEMPLATE_TYPE.WATCHLIST: return 'Watchlist';
      case TEMPLATE_TYPE.SCREENER: return 'Screen';
      case TEMPLATE_TYPE.DASHBOARDS: return 'Dashboard';
      case TEMPLATE_TYPE.DATA_PANEL: return 'Data Panel';
      case TEMPLATE_TYPE.COLUMN_SET: return 'Column Set';
      default: return 'Screen';
    }
  };

  const createHandler = () => {
    openModalHandler(MODAL_TITLES.CREATE_SHARED_ITEM);
  };

  return (
    <>
      <div className={styles.sharedItemDiv}>
        <div className={styles.sharedItemTitle}>{`Do you want to add “${itemTitle}” ${getTitle()}?`}</div>
        <div className={styles.sharedItemButtonWrapper}>
          <Button
            buttonClass={styles.cancelButton}
            handleClick={handleCancel}
          >
            {t('close')}
          </Button>
          <Button
            buttonClass={styles.createFolderButton}
            handleClick={createHandler}
          >
            {t('add')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SharedSymbolRow;

SharedSymbolRow.propTypes = {
  openModalHandler: PropTypes.func.isRequired,
};
