import { SxProps } from '@mui/system';
import ThemeVariants from '../../../constants/theme';

export const getPaperOptions = (theme: string): SxProps => ({
  width: '520px',
  height: 'auto',
  minHeight: '254px',
  padding: '40px',
  backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232b3b' : '#FFFFFF',
  overflowY: 'unset',
});

export const getTitleProps = (theme: string): SxProps => ({
  color: theme === ThemeVariants.DARK_MODE ? '#D5D4E9' : '#283555',
  fontFamily: 'Quicksand',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: 'normal',
  padding: '0',
  letterSpacing: '.03em',
  marginBottom: '30px',
});

export const getContentProps = (theme: string): SxProps => ({
  padding: '1px',
  backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232b3b' : '#FFFFFF',
  overflowY: 'unset',
});

export const getActionsProps = (theme: string): SxProps => ({
  padding: '1px',
  marginTop: '9px',
  backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232b3b' : '#FFFFFF',
});
