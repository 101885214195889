const ACTION_ACCOUNT = {
  SAVE_USER_PROFILE: '@@USER_PROFILE',
  GET_USER_PROFILE: '@@GET_USER_PROFILE',
  SAVE_USER_AVATAR: '@@USER_AVATAR',
  TOGGLE_SIDE_MENU: '@@TOGGLE_SIDE_MENU',
  GET_USER_AVATAR: '@@GET_USER_AVATAR',
  GET_USER_PLAN: '@@GET_USER_PLAN',
  SAVE_USER_CARDS: '@@SAVE_USER_CARDS',
  GET_USER_CARDS: '@@GET_USER_CARDS',
  DELETE_USER_CARD: '@@DELETE_USER_CARD',
  SAVE_ERROR_MESSAGE: '@@SAVE_ERROR_MESSAGE',
  SAVE_USER_ACTIVE_SUBSCRIPTION: '@@SAVE_USER_ACTIVE_SUBSCRIPTION',
  GET_USER_ACTIVE_SUBSCRIPTION: '@@GET_USER_ACTIVE_SUBSCRIPTION',
  SAVE_PAYMENT_HISTORY: '@@SAVE_PAYMENT_HISTORY',
  GET_PAYMENT_HISTORY: '@@GET_PAYMENT_HISTORY',
  SAVE_PHONE_VERIFIED: '@@SAVE_PHONE_VERIFIED',
  SAVE_EMAIL_VERIFIED: '@@SAVE_EMAIL_VERIFIED',
  GET_STRIPE_KEY: '@@GET_STRIPE_KEY',
  SAVE_STRIPE_KEY: '@@SAVE_STRIPE_KEY',
  SAVE_TWITTER: '@@SAVE_TWITTER',
  SAVE_USER_STATE: '@@SAVE_USER_STATE',
  SAVE_SELECTED_SCREEN_ID: '@@SAVE_SELECTED_SCREEN_ID',
  SAVE_LAST_SYMBOL_SCREENER_STATE: '@@SAVE_LAST_SYMBOL_SCREENER_STATE',
  SAVE_LAST_SYMBOL_DEEPLIST_STATE: '@@SAVE_LAST_SYMBOL_DEEPLIST_STATE',
  SAVE_LAST_SYMBOL_ALERT_STATE: '@@SAVE_LAST_SYMBOL_ALERT_STATE',
  SAVE_LAST_SELECTED_TAB_STATE: '@@SAVE_LAST_SELECTED_TAB_STATE',
  LOADED_USER_SETTINGS: '@@LOADED_USER_SETTINGS',
  SET_LAST_SORT_DATA: '@@SET_LAST_SORT_DATA',
  TOGGLE_PANEL_DATA_ADD: '@@TOGGLE_PANEL_DATA_ADD',
  UPDATE_STORE_ACTION: '@@UPDATE_STORE_ACTION',
  UPDATE_NESTED_STORE_ACTION: '@@UPDATE_NESTED_STORE_ACTION',
  UPDATE_NESTED_SORTING_ACTION: '@@UPDATE_NESTED_SORTING_ACTION',
  UPDATE_NESTED_COLUMN_SETS_PRESETS_SCREENER_IDS_ACTION: '@@UPDATE_NESTED_COLUMN_SETS_PRESETS_SCREENER_IDS_ACTION',
  UPDATE_NESTED_STORE_ACTION_PRESET: '@@UPDATE_NESTED_STORE_ACTION_PRESET',
  SET_ALERT_COLUMNS: '@@SET_ALERT_COLUMNS',
  SET_GLOBAL_SEARCH_VISIBLE: '@@SET_GLOBAL_SEARCH_VISIBLE',
  SET_OVERWRITE_FLAG: '@@SET_OVERWRITE_FLAG',
  SET_OPEN_CONFIRM_MODAL: '@@SET_OPEN_CONFIRM_MODAL',
  SET_LAST_STATE_ID: '@@SET_LAST_STATE_ID',
  SET_GLOBAL_SEARCH_ON_KEY_DOWN: '@@SET_GLOBAL_SEARCH_ON_KEY_DOWN',
  SET_POSITION_SIZE_CALCULATOR: '@@SET_POSITION_SIZE_CALCULATOR',
  SET_WIDGET_CHARTS: '@@SET_WIDGET_CHARTS',
  LOG_OUT: '@@LOG_OUT',
  UPDATED_LAST_STATE: '@@UPDATED_LAST_STATE'
};

export default ACTION_ACCOUNT;
