import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './sass/ScreenerDropDown.module.scss';
import TextField from '../TextField/TextField';
import { ALLOWED_TITLE_LENGTH_DROP_DOWN } from '../../constants/screener';
import { VIEW_TYPE_TARGET } from '../../constants/watchlist';
import TargetListIcon from '../../assets/images/icons/targetList/TargetListIcon';

const WatchListItem = ({ watchlistItem, copyHandler, lastElement }) => {
  const { t } = useTranslation();
  const [viewTool, setViewTool] = useState(false);

  return (
    <div
      aria-hidden="true"
      className={styles.item}
      key={watchlistItem.id}
      onClick={() => copyHandler(watchlistItem)}
      onMouseEnter={() => setViewTool(true)}
      onMouseLeave={() => setViewTool(false)}
    >
      <div className={styles.itemContentWrapper}>
        <div className={styles.titleWrapper}>
          {watchlistItem.viewType === VIEW_TYPE_TARGET && (
            <div className={styles.targetIcon}>
              <TargetListIcon
                colorStart={watchlistItem.data.color1}
              />
            </div>
          )}
          <TextField
            text={watchlistItem.title?.length <= ALLOWED_TITLE_LENGTH_DROP_DOWN
              ? watchlistItem.title : `${watchlistItem.title.slice(0, ALLOWED_TITLE_LENGTH_DROP_DOWN)}...`}
            styleType="watchListTitle"
          />
          {viewTool && watchlistItem.title?.length > ALLOWED_TITLE_LENGTH_DROP_DOWN && (
            <div
              className={`${styles.toolDropDown} ${lastElement && styles.toolDropDownTop}`}
            >
              {watchlistItem.title}
            </div>
          )}
        </div>
        <TextField text={`${watchlistItem.data?.stocks || 0} ${t('stocks')}`} styleType="watchListDescription" />
      </div>
    </div>
  );
};

WatchListItem.propTypes = {
  lastElement: PropTypes.bool,
  copyHandler: PropTypes.func.isRequired,
  watchlistItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    viewType: PropTypes.string,
    data: PropTypes.shape({
      stocks: PropTypes.number,
      symbolsList: PropTypes.arrayOf(PropTypes.shape({})),
      color1: PropTypes.string,
      color2: PropTypes.string
    })
  }).isRequired,
};

WatchListItem.defaultProps = {
  lastElement: false
};

export default WatchListItem;
