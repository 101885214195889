import React from 'react';

import styles from './sass/ChunkImage.module.scss';

interface IChunkImage {
  Image: React.FC;
  styleType?: boolean;
}

const ChunkImage = ({ Image, styleType }: IChunkImage): React.ReactElement => (
  <div className={styles.chunkImg}>
    <div className={`${styleType ? styles.orGroup : styles.imageChunkContainer}`}>
      <Image />
    </div>
  </div>
);

export default ChunkImage;
