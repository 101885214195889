export const fontFamily = [{
  cssSrc: 'https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap'
}];

export const account = {
  stripeInputStyle: {
    base: {
      fontFamily: 'Quicksand, sans-serif',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: '0.03em',
      color: '#283555'
    },
    invalid: {
      fontFamily: 'Quicksand, sans-serif',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '15px',
      letterSpacing: '0.03em',
      color: '#EC6D6D',
    },
  },
};

export const DEFAULT_TIMEZONE = 'America/New_York';
export const allowColumns = ['General', 'Earnings', 'Sales'];
export const notificationsData = [
  {
    title: 'Announcement 1',
    shortContent: `In English, the word "borscht" is most often associated
                  with the soup's varian...`,
    new: false,
  },
  {
    title: 'Announcement 2',
    shortContent: `The same name, however, is also used for a
                  wide selection of sour-tasting sou...`,
    new: false,
  },
  {
    title: 'Announcement 3',
    shortContent: `In English, the word "borscht" is most often associated
                  with the soup's varian...`,
    new: false,
  },
  {
    title: 'Announcement 4',
    shortContent: `The same name, however, is also used for a
                  wide selection of sour-tasting sou...`,
    new: false,
  },
  {
    title: 'Announcement 5',
    shortContent: `The same name, however, is also used for a
                  wide selection of sour-tasting sou...`,
    new: false,
  },
];

export const lastStateTitle = 'Last state';
export const lastStateType = 'userLastState';
export const mobileLastStateType = 'userMobileLastState';
export const userPanel = 'User Panel';
