import {
  EPS,
  GROWTH,
  SALES,
  VALUE,
} from '../constants/customIndicatorsSettings';
import { fetchEpsSalesEstimates } from '../components/ChartContainer/utils/tradingViewHelper';

const fetchEpsDataHandler = async (symIndex, property, type, onHistoryCallback, socket) => {
  const data = await fetchEpsSalesEstimates(symIndex, property, type, socket);
  onHistoryCallback(data, { noData: false });
};

const epsSalesEstimatesHandler = async (symbolInfo, symbolsList, firstDataRequest, onHistoryCallback, socket) => {
  if (symbolInfo.name.endsWith(EPS.VALUE_DAILY) && firstDataRequest) {
    const currentSymbol = symbolsList.find((item) => item.sym
      === symbolInfo.name.split(EPS.VALUE_DAILY)[0]);
    await fetchEpsDataHandler(currentSymbol.index, EPS.CATEGORY, VALUE, onHistoryCallback, socket);
    return;
  }
  if (symbolInfo.name.endsWith(EPS.GROWTH_DAILY) && firstDataRequest) {
    const currentSymbol = symbolsList.find((item) => item.sym
      === symbolInfo.name.split(EPS.GROWTH_DAILY)[0]);
    await fetchEpsDataHandler(currentSymbol.index, EPS.CATEGORY, GROWTH, onHistoryCallback, socket);
    return;
  }

  // DV-SALES SYMBOLS
  if (symbolInfo.name.endsWith(SALES.VALUE_DAILY) && firstDataRequest) {
    const currentSymbol = symbolsList.find((item) => item.sym
      === symbolInfo.name.split(SALES.VALUE_DAILY)[0]);
    await fetchEpsDataHandler(currentSymbol.index, SALES.CATEGORY, VALUE, onHistoryCallback, socket);
    return;
  }
  if (symbolInfo.name.endsWith(SALES.GROWTH_DAILY) && firstDataRequest) {
    const currentSymbol = symbolsList.find((item) => item.sym
      === symbolInfo.name.split(SALES.GROWTH_DAILY)[0]);
    await fetchEpsDataHandler(currentSymbol.index, SALES.CATEGORY, GROWTH, onHistoryCallback, socket);
  }
};

export default epsSalesEstimatesHandler;
