import React from 'react';
import IButtonProps from './interfaces/IButton';

const Button = ({
  children,
  buttonClass,
  handleClick,
  mouseEnterHandler = () => {},
  mouseLeaveHandler = () => {},
  disabled = false,
  refObject = null,
}: IButtonProps) => (
  <button
    ref={refObject}
    type="button"
    disabled={disabled}
    onClick={handleClick}
    className={buttonClass}
    onMouseEnter={mouseEnterHandler}
    onMouseLeave={mouseLeaveHandler}
  >
    { children }
  </button>
);

export default React.memo(Button);
