import {
  useContext, useEffect, useMemo, useRef, useState
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import {
  WS_CHANNELS,
  WS_PING_TYPES,
} from '../../../constants/socketConnection';
import SocketContext from '../../../context/SocketContext';
import {
  FindDataPoint,
  HeatMapDataset,
  PerformanceDataSet,
  ScreenerStates,
  TDataPoint,
  WidgetDataPoint,
  WidgetLastState,
} from '../types/WidgetChartsypes';
import {
  DEFAULT_SCREENER_STATE,
  FOLDER_ITEM,
  SCREENER_TYPES,
  SelectedScreenerTypes,
} from '../types/WidgetChartsEnums';
import { ITargetListItem } from '../../Screener/interfaces/ITargetList';
import {
  IScreenerSearch,
  IUseWidgetCharts,
} from '../types/WidgetChartsInterfaces';
import { removeWidgetChartsConnectionQuery } from '../../../store/dashboards/slice';
import { ScreenItemData, TWidgetQueryState } from '../../../utils/Types';
import { messageIdGenerator } from '../../ChartContainer/utils/chartingHelper';
import { SocketPingResponse } from '../../../services/Interfaces';
import useStatsChart from '../../../utils/hooks/useStatsChart';
import { findItem } from '../../DasboardComponents/utils/utils';
import { Dashboard } from '../../../pages/Dashboards/types/DashboardTypes';

const useWidgetCharts: IUseWidgetCharts = (
  widget, prodDefaultIndex, screenerDefaultTitle, updateStoreSymbolsState
) => {
  const widgetQueriesRef = useRef<{ [key: number]: TWidgetQueryState }>(null);
  const dispatch = useDispatch();
  const { Socket, Connection, Authorized } = useContext(SocketContext);
  const itemsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.presetsNewScreen, shallowEqual);
  const itemsDeeplist = useSelector((state: RootStateTypes) => state.watchlistState.itemsWatchlist, shallowEqual);
  const presetDeeplist = useSelector((state: RootStateTypes) => state.watchlistState.presetsWatchlist, shallowEqual);
  const columnsList = useSelector((state: RootStateTypes) => state.screenerState.columnsList, shallowEqual);
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const userSettings = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);
  const watchLists = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const widgetConnectionQueries = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetChartsConnectionQueries, shallowEqual
  );
  const currentSource = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.statsSources, shallowEqual
  );
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);

  const dashboardsNew = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const dashboardPresets = useSelector((state: RootStateTypes) => state.dashboardsState.dashboardPresets, shallowEqual);
  const selectedDashboardId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedDashboardId, shallowEqual
  );

  const selectedDashboard = ([...dashboardsNew, ...dashboardPresets] as Dashboard[]).find(
    (dashboard: Dashboard) => dashboard.id === selectedDashboardId
  );

  const [screenerStates, setScreenerStates] = useState<ScreenerStates>(DEFAULT_SCREENER_STATE);

  const [queryId, setQueryId] = useState<number | undefined>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [targetLists, setTargetLists] = useState<ITargetListItem[]>([]);
  const [dataPointList, setDataPointList] = useState<TDataPoint[]>([]);

  const { getStatsData, statsChartDataCallback } = useStatsChart();

  let selectedType;
  if (screenerStates.selectedScreener === SelectedScreenerTypes.SCREENER) {
    selectedType = itemsNewScreen;
  } else if (screenerStates.selectedScreener === SelectedScreenerTypes.SCREENERPRESET) {
    selectedType = presetsNewScreen;
  } else if (screenerStates.selectedScreener === SelectedScreenerTypes.DEEPLIST) {
    selectedType = itemsDeeplist;
  } else {
    selectedType = presetDeeplist;
  }

  const refStoredSymbols = useRef<PerformanceDataSet[] | HeatMapDataset[]>([]);
  const intervalIdRef = useRef<NodeJS.Timeout | null>();

  const cleanRefs = () => {
    if (intervalIdRef.current) {
      refStoredSymbols.current = [];
      clearInterval(intervalIdRef.current);
      intervalIdRef.current = null;
    }
  };

  const startInterval = () => {
    if (intervalIdRef.current) clearInterval(intervalIdRef.current);
    intervalIdRef.current = setInterval(() => {
      if (updateStoreSymbolsState) updateStoreSymbolsState(refStoredSymbols.current);
    }, 500);
  };

  const setDefaultFilter = () => {
    const deepvueUniverseFilter = presetsNewScreen
      .flatMap((item: ScreenItemData) => item.items)
      .find((subItem: ScreenItemData) => subItem.id === prodDefaultIndex);

    if (deepvueUniverseFilter) {
      const filterData: ScreenItemData = deepvueUniverseFilter;
      if (filterData?.data?.filters?.length) {
        setScreenerStates((prev) => ({
          ...prev,
          filtersData: JSON.stringify(filterData?.data?.filters),
          selectedScreenerTitle: screenerDefaultTitle,
          screenerId: filterData?.id,
        }));
      }
    }
  };
  const setFilter = (
    findDataPoint: TDataPoint | FindDataPoint,
    findDataPointY: WidgetDataPoint,
    findDataPointZ: WidgetDataPoint,
    widgetValues: WidgetLastState | undefined
  ) => {
    let screenerData;
    if (widgetValues?.screenerId) {
      screenerData = findItem(
        [...itemsNewScreen, ...presetsNewScreen, ...itemsDeeplist, ...presetDeeplist],
        widgetValues?.screenerId
      ) as ScreenItemData;
    } else {
      [...itemsNewScreen, ...presetsNewScreen, ...itemsDeeplist, ...presetDeeplist].forEach((item: ScreenItemData) => {
        if (item.type === FOLDER_ITEM && item.items.length > 0) {
          const temp = item.items.find(
            (subItem: ScreenItemData) => JSON.stringify(subItem?.data?.filters) === JSON.stringify(widgetValues?.filter)
          );
          if (temp) screenerData = temp;
        }
        if (JSON.stringify(item.data?.filters) === JSON.stringify(widgetValues?.filter)) {
          screenerData = item;
        }
      });
    }
    if (screenerData) {
      const filterData: ScreenItemData = screenerData;
      if (widgetValues) {
        const yPoints = findDataPointY ? {
          dataPointsYType: findDataPointY?.type,
          dataPointsYTitle: findDataPointY?.name,
        } : null;
        const zPoints = findDataPointZ ? {
          dataPointsZType: findDataPointZ?.type,
          dataPointsZTitle: findDataPointZ?.name,
        } : null;
        setScreenerStates((prev) => ({
          ...prev,
          dataPointsXTitle: findDataPoint.name,
          dataPointsXIndex: widgetValues.datapointIndex,
          dataPointsXType: findDataPoint?.type,
          dataPointsYIndex: widgetValues.datapointYIndex,
          dataPointsZIndex: widgetValues.datapointZIndex ?? null,
          filtersData: JSON.stringify(filterData?.data?.filters),
          selectedScreenerTitle: filterData?.title,
          screenerId: filterData?.id,
          sortBy: widgetValues.sortBy ?? 1,
          ...yPoints,
          ...zPoints
        }));
      }
    }
  };

  const pingCallback = (resp: SocketPingResponse) => {
    if (widgetQueriesRef?.current) {
      const currentWidgetQueryId = widgetQueriesRef?.current[Number(widget.id)];
      if (resp?.screenerId === currentWidgetQueryId?.screenerId) {
        Connection?.sendMessage(
          WS_CHANNELS.PONG_PERIODICAL_DATA,
          {
            periodicalId: currentWidgetQueryId?.screenerId,
            type: WS_PING_TYPES.SCREENER,
            socketId: Connection?.socketId
          }
        );
      }
    }
  };

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      Connection?.messageEmitter?.on(WS_CHANNELS.PING_PERIODICAL_DATA, pingCallback);
    }
    return () => {
      Connection?.messageEmitter?.off(WS_CHANNELS.PING_PERIODICAL_DATA, pingCallback);
    };
  }, [Socket, Authorized]);

  const cleanChanel = () => {
    if (widgetQueriesRef?.current) {
      const currentWidgetQueryId = widgetQueriesRef?.current[Number(widget.id)];
      Connection?.sendMessage(WS_CHANNELS.SCREENER_UNSUBSCRIBE,
        { messageId: messageIdGenerator(), screenerId: currentWidgetQueryId?.screenerId });

      dispatch(removeWidgetChartsConnectionQuery(widget.id));
    }
  };

  useEffect(() => () => {
    cleanRefs();
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      cleanChanel();
      Connection?.messageEmitter?.off(WS_CHANNELS.PING_PERIODICAL_DATA, pingCallback);
    }
  }, []);

  useEffect(() => {
    const currentWidgetChartSymbolIndex = symbolsList.find(
      (sym: { sym: string, index: number }) => sym.sym === widget.symbol
    )?.index;

    const messageHandler = (res: { data: Array<{ statsChartQ: number, statsChartY: number }> }) => {
      statsChartDataCallback(res, currentSource, currentWidgetChartSymbolIndex);
    };

    if (Socket?.readyState === WebSocket.OPEN) {
      getStatsData(
        Connection,
        currentWidgetChartSymbolIndex,
        currentSource
      );

      Connection?.messageEmitter.on(WS_CHANNELS.SYMBOL_DATA_BATCH, messageHandler);
    }

    return () => {
      if (Socket?.readyState === WebSocket.OPEN) {
        Connection?.messageEmitter.off(WS_CHANNELS.SYMBOL_DATA_BATCH, messageHandler);
      }
    };
  }, [
    Socket?.readyState,
    widget.symbol,
    currentSource,
    pagePath,
  ]);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    widgetQueriesRef.current = widgetConnectionQueries;
  }, [widgetConnectionQueries]);

  const searchScreenerData = (list: ScreenItemData[], text: string) => list.filter(
    (item: ScreenItemData) => (text ? item.title.toLowerCase()
      .includes(text.toLowerCase()) : true)
  );

  const screenerSearch: IScreenerSearch[] = useMemo(() => {
    if (screenerStates.searchText) {
      return [
        {
          title: SCREENER_TYPES[0].title,
          data: searchScreenerData(itemsNewScreen, screenerStates.searchText)
        },
        {
          title: SCREENER_TYPES[1].title,
          data: searchScreenerData(presetsNewScreen, screenerStates.searchText)
        },
        {
          title: SCREENER_TYPES[2].title,
          data: searchScreenerData(itemsDeeplist, screenerStates.searchText)
        },
        {
          title: SCREENER_TYPES[3].title,
          data: searchScreenerData(presetDeeplist, screenerStates.searchText)
        },
      ];
    }
    return [];
  }, [screenerStates.searchText]);

  const updateFilter = () => {
    let watchListFilter: ScreenItemData | undefined;
    itemsDeeplist.forEach((item: ScreenItemData) => {
      if (item.type === FOLDER_ITEM && item.items.length > 0) {
        watchListFilter = item.items.find((subItem: ScreenItemData) => subItem.id === screenerStates.screenerId);
        return watchListFilter;
      }
      if (item.id === screenerStates.screenerId) {
        watchListFilter = item;
      }
      return watchListFilter;
    });
    if (watchListFilter) {
      const filterData: ScreenItemData = watchListFilter;
      if (filterData?.data?.filters?.length) {
        setScreenerStates({ ...screenerStates, filtersData: JSON.stringify(filterData?.data?.filters) });
      }
    }
  };

  return {
    widgetQueriesRef,
    presetsNewScreen,
    itemsDeeplist,
    columnsList,
    symbolsList,
    userSettings,
    watchLists,
    widgetConnectionQueries,
    screenerStates,
    setScreenerStates,
    queryId,
    setQueryId,
    totalCount,
    setTotalCount,
    targetLists,
    setTargetLists,
    dataPointList,
    setDataPointList,
    selectedType,
    setDefaultFilter,
    screenerSearch,
    updateFilter,
    setFilter,
    selectedDashboard,
    cleanRefs,
    startInterval,
    refStoredSymbols,
    intervalIdRef
  };
};

export default useWidgetCharts;
