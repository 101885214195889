export const defaultStyles = {
  plot_0: {
    display: 15,
    linestyle: 0,
    linewidth: 1,
    plottype: 0,
    trackPrice: false,
    transparency: 0,
  },
  plot_10: {
    display: 15,
    linestyle: 0,
    linewidth: 3,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_12: {
    display: 15,
    linestyle: 0,
    linewidth: 3,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_14: {
    display: 15,
    linestyle: 0,
    linewidth: 3,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_16: {
    display: 15,
    linestyle: 0,
    linewidth: 3,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_18: {
    display: 15,
    linestyle: 0,
    linewidth: 3,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_2: {
    display: 15,
    linestyle: 0,
    linewidth: 1,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_4: {
    display: 15,
    linestyle: 0,
    linewidth: 1,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_6: {
    display: 15,
    linestyle: 0,
    linewidth: 1,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  },
  plot_8: {
    display: 15,
    linestyle: 0,
    linewidth: 1,
    plottype: 0,
    trackPrice: false,
    transparency: 0
  }
};

export const styles = {
  plot_0: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Short Leading EMA1'
  },
  plot_2: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Short Leading EMA2'
  },
  plot_4: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Short Leading EMA3'
  },
  plot_6: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Short Leading EMA4'
  },
  plot_8: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Short Leading EMA5'
  },
  plot_10: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Long Leading EMA1'
  },
  plot_12: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Long Leading EMA2'
  },
  plot_14: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Long Leading EMA3'
  },
  plot_16: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Long Leading EMA4'
  },
  plot_18: {
    histogramBase: 0,
    isHidden: false,
    joinPoints: false,
    title: 'Long Leading EMA5'
  },

};
