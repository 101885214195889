import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/DialogBlock.module.scss';
import Triangle from '../Triangle/Triangle';
import TextField from '../TextField/TextField';

const DialogBlock = ({ text, Icon }) => (
  <div className={styles.container}>
    <div className={styles.arrow}>
      <Triangle />
    </div>   
    <div className={styles.dialog}>
      {Icon && <Icon />}
      <TextField styleType="alertDialog" text={text} />
    </div>
  </div>
);

DialogBlock.propTypes = {
  text: PropTypes.string,
  Icon: PropTypes.shape({})
};

DialogBlock.defaultProps = {
  text: '',
  Icon: null
};

export default React.memo(DialogBlock);
