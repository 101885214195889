import React from 'react';
import styles from '../sass/WidgetCharts.module.scss';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from '../../../assets/images/icons/minus.svg';
import { ReactComponent as ResetIcon } from '../../../assets/images/icons/reset.svg';

const Zoom = React.forwardRef((_, ref) => {
  const handleResetZoom = () => ref && ref.current && ref.current.resetZoom('zoom');

  const handleZoom = (threshold) => {
    if (ref && ref.current) {
      ref.current.zoom(threshold, 'zoom');
    }
  };

  return (
    <div className={styles.zoomWrapper}>
      <div className={styles.zoomContainer}>
        <div
          onMouseDown={() => handleZoom(1.1)}
          className={styles.zoomButton}
          tabIndex={0}
          role="button"
          aria-label="reset-zoom"
        >
          <PlusIcon />
        </div>
        <div
          onMouseDown={() => handleZoom(0.9)}
          className={styles.zoomButton}
          tabIndex={0}
          role="button"
          aria-label="reset-zoom"
        >
          <MinusIcon />
        </div>
        <div className={styles.zoomDivider} />
        <div
          onMouseDown={handleResetZoom}
          className={`${styles.zoomButton} ${styles.resetZoom}`}
          tabIndex={0}
          role="button"
          aria-label="reset-zoom"
        >
          <ResetIcon />
        </div>
      </div>
    </div>
  );
});
export default Zoom;
