import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styles from '../../WidgetCharts/sass/WidgetCharts.module.scss';
import { IPerformanceChartWidget } from '../types/DashboardComponentsInterfaces';
import WidgetChartsHeader from '../../WidgetCharts/WidgetChartsHeader';
import {
  DEFAULT_DATA_POINT_INDEX,
  DEFAULT_DATA_POINT_TITLE,
  DEFAULT_RR50DAY_INDEX,
  DEFAULT_RR50DAY_TITLE,
  EmptyChartNames,
  LAST_STATE_KEYS
} from '../../WidgetCharts/types/WidgetChartsEnums';
import useWidgetBubbleChart from '../../WidgetCharts/hook/useWidgetBubbleChart';
import BubbleChart from '../../WidgetCharts/charts/BubbleChart';
import EmptyContainer from '../../WidgetCharts/components/EmptyContainer';

const BubbleChartWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe, dashboardId,
  changeSymbolForGroup, isEditable
}: IPerformanceChartWidget): React.ReactElement => {
  const {
    dataPointList,
    dataSetLabels,
    dataSetValues,
    selectedType,
    queryId,
    screenerStates,
    storedSymbols,
    setScreenerStates,
    setDefaultFilter,
    screenerSearch,
  } = useWidgetBubbleChart(widget, dashboardId, updateWidget);
  const { ref, width, height } = useResizeDetector();

  return (
    <div className={styles.performanceChartContainer} ref={ref}>
      <WidgetChartsHeader
        screenerStates={screenerStates}
        setScreenerStates={setScreenerStates}
        setColorGroupe={setColorGroupe}
        updateWidget={updateWidget}
        removeWidget={removeWidget}
        widget={widget}
        width={width}
        dashboardId={dashboardId}
        dataPointList={dataPointList}
        selectedType={selectedType}
        setDefaultFilter={setDefaultFilter}
        screenerSearch={screenerSearch}
        lastStateKey={LAST_STATE_KEYS.bubble}
        defaultDataPoints={
          {
            dataPointXTitle: DEFAULT_RR50DAY_TITLE,
            dataPointXIndex: DEFAULT_RR50DAY_INDEX,
            dataPointYTitle: DEFAULT_DATA_POINT_TITLE,
            dataPointYIndex: DEFAULT_DATA_POINT_INDEX,
            dataPointZTitle: '',
            dataPointZIndex: null,
          }
        }
        showY
        showX
        showZ
        showAxis
        xShare
        baseRef={ref}
        isEditable={isEditable}
      />
      {
        screenerStates.dataPointsXIndex
        && screenerStates.dataPointsYIndex
        && screenerStates.dataPointsXIndex > -1
        && screenerStates.dataPointsYIndex > -1
        && dataSetLabels.length > 0
        && dataSetValues.length > 0
        && height && (
          <div className={styles.chartContainer}>
            <BubbleChart
              dataSetLabels={dataSetLabels}
              dataSetValues={dataSetValues}
              height={height}
              dataPointsXTitle={screenerStates.dataPointsXTitle}
              dataPointsYTitle={screenerStates.dataPointsYTitle}
              dataPointsXType={screenerStates.dataPointsXType}
              dataPointsXIndex={screenerStates.dataPointsXIndex}
              dataPointsYType={screenerStates.dataPointsYType}
              dataPointsYIndex={screenerStates.dataPointsYIndex}
              dataPointsZType={screenerStates.dataPointsZType}
              dataPointsZIndex={screenerStates.dataPointsZIndex}
              changeSymbolForGroup={changeSymbolForGroup}
              widget={widget}
              queryId={queryId}
              storedSymbols={storedSymbols}
            />
          </div>
        )
      }
      <EmptyContainer
        chartName={EmptyChartNames.bubbleChart}
        dataPointsXIndex={screenerStates.dataPointsXIndex}
        dataPointsYIndex={screenerStates.dataPointsYIndex}
        length={dataSetLabels.length}
        queryId={queryId}
        screener={screenerStates.selectedScreenerTitle}
        type={(`${screenerStates.dataPointsXTitle} and ${screenerStates.dataPointsYTitle}`)}
      />
    </div>
  );
};

export default BubbleChartWidget;
