import { StudyInputType } from "../../../charting_library/charting_library";

export const defaultInputs = {
  indexsym: 'SPY',
  rs_plot_type: 'Line',
  offset: 30,
  movingaverage: 21,
  rs_ma_type: 'EMA',
  movingaverage2: 50,
  rs_ma_type2: 'SMA',
  hideRSNH: false,
  hideRSNHBP: false,
  hideRSMovingAverage1: false,
  hideRSMovingAverage2: true,
  hideMovingAverageCrossover1: false,
  hideMovingAverageCrossover2: true,
};

export const inputs = [
  {
    id: 'indexsym',
    name: 'Calculate Relative Strength vs Index',
    type: 'symbol' as StudyInputType.Symbol,
    defval: 'SPY'
  },
  {
    id: 'rs_plot_type',
    name: 'Relative Strength Line Plot Type',
    options: ['Line', 'Candlesticks'],
    type: 'text' as StudyInputType.Text,
    defval: 'Line'
  },
  {
    id: 'offset',
    name: 'Vertical Offset',
    type: 'integer' as StudyInputType.Integer,
    defval: 30
  },
  {
    id: 'movingaverage',
    name: 'Relative Strength Moving Average Length 1',
    type: 'integer' as StudyInputType.Integer,
    defval: 21
  },
  {
    id: 'rs_ma_type',
    name: 'RS SMA or EMA 1',
    options: ['SMA', 'EMA'],
    type: 'text' as StudyInputType.Text,
    defval: 'EMA'
  },
  {
    id: 'movingaverage2',
    name: 'Relative Strength Moving Average Length 2',
    type: 'integer' as StudyInputType.Integer,
    defval: 50
  },
  {
    id: 'rs_ma_type2',
    name: 'RS SMA or EMA 2',
    options: ['SMA', 'EMA'],
    type: 'text' as StudyInputType.Text,
    defval: 'SMA'
  },
  {
    id: 'hideRSNH',
    name: 'Hide Relative Strength Line New High (Green Dot)',
    type: 'bool' as StudyInputType.Bool,
    defval: false
  },
  {
    id: 'hideRSNHBP',
    name: 'Hide Relative Strength Line New High Before Price (Pink Dot)',
    type: 'bool' as StudyInputType.Bool,
    defval: false
  },
  {
    id: 'hideRSMovingAverage1',
    name: 'Hide Relative Strength Moving Average 1',
    type: 'bool' as StudyInputType.Bool,
    defval: false
  },
  {
    id: 'hideRSMovingAverage2',
    name: 'Hide Relative Strength Moving Average 2',
    type: 'bool' as StudyInputType.Bool,
    defval: true,
  },
  {
    id: 'hideMovingAverageCrossover1',
    name: 'Hide Moving Average Crossover Indicator 1',
    type: 'bool' as StudyInputType.Bool,
    defval: false
  },
  {
    id: 'hideMovingAverageCrossover2',
    name: 'Hide Moving Average Crossover Indicator 2',
    type: 'bool' as StudyInputType.Bool,
    defval: true,
  },
];
