import {
  useState, useEffect, useRef, useCallback
} from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import {
  HALF_CHART,
  ONE_HUNDRED,
  DATA_PANEL_WIDTH,
  THIRD_CHART,
  CHART_CONSTRAINTS,
  INITIAL_SCREENER_LAYOUT,
  LAYOUT_DELTA_WIDTH,
  CHART_50,
  CHART_46,
  SYMBOL_HALF_WIDTH,
  SYMBOL_FULL_WIDTH,
  SYMBOL_WIDTH,
} from '../../constants/screener';

import { PANEL_CONTENT_GRID_TYPES } from '../../components/DataPanel/dataPanelConstants';
import { SCREENER_LAYOUT } from '../../constants/tvWidgetOptions';
import useLastStateUpdateStore from './useLastStateUpdateStore';
import { SendGAEvent } from '../ga';

let saveTimeout = null;

const useLayoutChange = () => {
  const openDataPanel = useRef(null);
  const isDataPanelHalf = useRef(null);
  const screenerLayout = useRef(null);
  const currentUserSettings = useRef(null);

  const { updateStoreHandler } = useLastStateUpdateStore();
  const [hideChart, setHideChart] = useState(false);

  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);
  const storedScreenerLayout = useSelector((state) => state.accountState?.userSettings?.screenerLayout, shallowEqual);
  const profile = useSelector((state) => state.accountState?.profile, shallowEqual);

  const getMinChartWidth = () => {
    return openDataPanel.current ? CHART_CONSTRAINTS.minTwooColumnData : CHART_CONSTRAINTS.min;
  };

  const getCurrentInnerWidth = useCallback(
    () => {
      let currentDeltaInnerWidth = (window.innerWidth / LAYOUT_DELTA_WIDTH);
      if (currentDeltaInnerWidth < 1) currentDeltaInnerWidth = 1;
      return currentDeltaInnerWidth;
    },
    [window.innerWidth],
  );

  const getScreenerLayoutRect = () => {
    return document.getElementById('screenerLayout')?.getBoundingClientRect();
  };

  const getDataPanelLayoutRect = () => {
    return document.getElementById('dataPanelComponent')?.getBoundingClientRect();
  };

  const getPanelWidth = () => {
    let currentDeltaInnerWidth = (window.innerWidth / LAYOUT_DELTA_WIDTH);
    if (currentDeltaInnerWidth < 1) currentDeltaInnerWidth = 1;
    return isDataPanelHalf?.current
      ? (DATA_PANEL_WIDTH / 2) / currentDeltaInnerWidth
      : DATA_PANEL_WIDTH / currentDeltaInnerWidth;
  };

  const getMaxChartWidth = () => {
    const screenerLayoutWidth = getScreenerLayoutRect()?.width;
    if (openDataPanel.current) {
      const dataPanelLayoutWidth = getDataPanelLayoutRect()?.width;
      const layoutWidth = screenerLayoutWidth - dataPanelLayoutWidth;
      const symbolColumnWidth = isDataPanelHalf?.current ? SYMBOL_HALF_WIDTH : SYMBOL_FULL_WIDTH;
      return (ONE_HUNDRED - ((symbolColumnWidth / layoutWidth) * ONE_HUNDRED)) - getPanelWidth();
    }
    return ONE_HUNDRED - ((SYMBOL_WIDTH / screenerLayoutWidth) * ONE_HUNDRED);
  };

  const updateScreenerLayout = (layout) => {
    const saveLayout = layout;
    if (saveLayout.chartWidth > getMaxChartWidth()) {
      saveLayout.chartWidth = getMaxChartWidth();
    }
    updateStoreHandler(SCREENER_LAYOUT, saveLayout);
  };

  const getChartRect = () => {
    return document.getElementById('screenerLayout');
  };

  const changeLayout = (chartWidth) => {
    const layoutRect = getChartRect();
    layoutRect.style.gridTemplateColumns = openDataPanel.current
      ? `${ONE_HUNDRED - chartWidth - getPanelWidth()}% ${chartWidth}% ${getPanelWidth()}%`
      : `${ONE_HUNDRED - chartWidth}% ${chartWidth}%`;
  };

  const handleOpenChart = useCallback(
    () => {
      const currentHide = storedScreenerLayout?.chartWidth ? storedScreenerLayout?.chartWidth === 0 : true;

      if (currentHide) {
        setHideChart(false);
        const layoutCurrent = openDataPanel.current ? THIRD_CHART : HALF_CHART;
        updateScreenerLayout(layoutCurrent);
        const chartSize = openDataPanel.current ? CHART_46 : CHART_50;
        screenerLayout.current.chartWidth = chartSize;
        const layoutRect = getChartRect();
        layoutRect.style.gridTemplateColumns = openDataPanel.current
          ? `${ONE_HUNDRED - chartSize - getPanelWidth()}% ${chartSize}% ${getPanelWidth()}%`
          : `${ONE_HUNDRED - chartSize}% ${chartSize}%`;
      }
    },
    [storedScreenerLayout?.chartWidth],
  );

  useEffect(() => {
    if (screenerLayout?.current?.chartWidth === 0) {
      setHideChart(true);
    }
  }, [screenerLayout?.current]);

  useEffect(() => {
    openDataPanel.current = userSettings?.openDataPanel;
    isDataPanelHalf.current = userSettings?.dataPanelGridType === PANEL_CONTENT_GRID_TYPES.COMPACT;
    currentUserSettings.current = userSettings;

    return () => {
      openDataPanel.current = null;
      isDataPanelHalf.current = null;
      currentUserSettings.current = null;
    };
  }, [userSettings]);

  useEffect(() => {
    if (storedScreenerLayout) {
      screenerLayout.current = storedScreenerLayout;
    } else {
      screenerLayout.current = INITIAL_SCREENER_LAYOUT;
    }

    return () => {
      screenerLayout.current = null;
    };
  }, [storedScreenerLayout]);

  useEffect(() => {
    return () => {
      openDataPanel.current = null;
      isDataPanelHalf.current = null;
      currentUserSettings.current = null;
      screenerLayout.current = null;
    };
  }, []);

  const handleChangeLayoutSize = useCallback((deltaSize) => {
    const storageData = screenerLayout.current;
    const oldChartWidth = storageData?.chartWidth;
    let newChartWidth = oldChartWidth - deltaSize;
    if (newChartWidth <= getMinChartWidth()) {
      newChartWidth = 0;
      setHideChart(true);
    }
    if (newChartWidth > 0 && newChartWidth > getMinChartWidth()) {
      setHideChart(false);
    }
    if (newChartWidth >= getMaxChartWidth()) {
      newChartWidth = getMaxChartWidth();
    }
    storageData.chartWidth = newChartWidth;
    changeLayout(newChartWidth);
    clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      saveTimeout = null;
      updateScreenerLayout(storageData);
    }, 200);
  }, [screenerLayout.current]);

  const updateStatsChartState = useCallback((statsIsOpen) => {
    const storageData = screenerLayout?.current;
    storageData.statsOpen = statsIsOpen;
    updateScreenerLayout(storageData);
    SendGAEvent('user_stats_open', {
      isOpen: Number(statsIsOpen),
      cust_identify: profile?.id,
      event_label: 'User Stats chart open'
    });
  }, [screenerLayout?.current]);

  return {
    getScreenerLayout: storedScreenerLayout || screenerLayout?.current,
    hideChart,
    handleOpenChart,
    handleChangeLayoutSize,
    updateStatsChartState,
    getCurrentInnerWidth,
  };
};

export default useLayoutChange;
