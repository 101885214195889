import { TFilterGroupe } from '../components/FilterScreen/Types';

export const indexes: Record<string, number[]> = {};

export const filterIndexesCollector = (filterList: TFilterGroupe[]): void => {
  filterList.forEach((item) => {
    indexes[item.name] = [];
    item.items.forEach((subItemOne) => {
      if (subItemOne.id !== -1) {
        indexes[item.name].push(subItemOne.id as number);
      }
      if (subItemOne.id === -1) {
        subItemOne.data.forEach((subItemTwo) => {
          if (subItemTwo.id !== -1) {
            indexes[item.name].push(subItemTwo.id as number);
          }
          if (subItemTwo.id === -1) {
            subItemTwo?.data?.forEach((subItemThree) => {
              indexes[item.name].push(subItemThree.id as number);
            });
          }
        });
      }
    });
  });
};

export const selectedCounterHandler = (selectedGroupFilters: TFilterGroupe[], title: string): number => {
  let counter = 0;
  selectedGroupFilters.forEach((item) => item.items.forEach((filterItem) => {
    if (indexes[title]?.includes(filterItem.columnId as number)) {
      counter += 1;
    }
  }));

  return counter;
};
