import React, { useEffect, useRef } from 'react';
import styles from '../sass/WidgetCharts.module.scss';
import { ReactComponent as ClearButton } from '../../../assets/images/icons/removeScreen.svg';
import { ISearchBlock } from '../types/WidgetChartsInterfaces';

const SearchBlock = ({
  screenerStates,
  setScreenerStates,
  stateKey,
}: ISearchBlock): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    setTimeout(() => { inputRef.current?.focus(); }, 300);
  }, []);
  return (
    <div className={styles.searchWrapper}>
      <input
        ref={inputRef}
        className={styles.searchInput}
        autoComplete="off"
        placeholder="Search"
        onChange={(e) => setScreenerStates((prev) => ({
          ...prev,
          [stateKey]: e.target.value,
          folderMenuIsOpen: -1,
        }))}
        value={screenerStates[stateKey]}
      />
      {screenerStates[stateKey]
        && (
          <div
            className={styles.clearIconChevron}
            role="button"
            tabIndex={0}
            onMouseDown={() => setScreenerStates((prev) => ({ ...prev, [stateKey]: '' }))}
            aria-label="search"
          >
            <ClearButton />
          </div>
        )}
    </div>
  );
};

export default SearchBlock;
