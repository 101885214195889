import {
  useContext,
  useEffect
} from 'react';
import { WS_CHANNELS } from '../../../constants/socketConnection';
import SocketContext from '../../../context/SocketContext';
import { IUseWidgetSocketListener } from '../types/WidgetChartsInterfaces';

const useWidgetSocketListener: IUseWidgetSocketListener = ({
  widget,
  screenerStates,
  dataCallback,
  dataCallbackPatch,
  dataCallbackAll,
  partialCallback,
  queryId,
  storedSymbols,
  isPartial = true
}) => {
  const { Socket, Connection, Authorized } = useContext(SocketContext);

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized) {
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_SUBSCRIBE, dataCallback);
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_PATCH, dataCallbackPatch);
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_RESPONSE_ALL, dataCallbackAll);
    }
    return () => {
      Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_SUBSCRIBE, dataCallback);
      Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_PATCH, dataCallbackPatch);
      Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_RESPONSE_ALL, dataCallbackAll);
    };
  }, [Socket, Authorized, screenerStates]);

  useEffect(() => {
    if (Socket?.readyState === WebSocket.OPEN && Authorized && isPartial) {
      Connection?.messageEmitter?.on(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, partialCallback);
    }
    return () => {
      Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, partialCallback);
    };
  }, [Socket, Authorized, queryId, storedSymbols, screenerStates, isPartial]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('=> widget_init', widget.id, new Date().getTime());
    return () => {
      if (Socket?.readyState === WebSocket.OPEN && Authorized) {
        Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_SUBSCRIBE, dataCallback);
        Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_PATCH, dataCallbackPatch);
        Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_RESPONSE_ALL, dataCallbackAll);
        Connection?.messageEmitter?.off(WS_CHANNELS.SCREENER_RESPONSE_PARTIAL, partialCallback);
      }
    };
  }, []);
};

export default useWidgetSocketListener;
