import React from 'react';

export type TMouseEvent = MouseEvent & {
  path: HTMLElement[];
  composedPath: () => HTMLElement[];
};

export const handleOutsideClick = (
  event: TMouseEvent,
  autoClose: () => void,
  ref: React.RefObject<HTMLDivElement>
): void => {
  const path = event.path || (event.composedPath && event.composedPath());
  if (!path.includes(ref.current as HTMLElement)) {
    if (autoClose) {
      autoClose();
    }
  }
};
