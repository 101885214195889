import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  alertsList: [],
  alertsListCounter: 0,
  alertsNotificationList: [],
  alertsNotificationCounter: 0,
  alertListDropdownVisible: false,
  currentAlert: null,
  openAlertModal: false,
  alertNotificationsPopUp: [],
  checkedNotifications: [],
  alertSettingsItemData: {},
  sortData: [],
  columnsOrderChanged: false,
  mixedAlertColumns: [],
  currentAlertData: []
};

const alertSlice = createSlice({
  name: 'alertSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setAlertsList(state, action) {
      state.alertsList = action.payload;
    },
    setAlertsListCounter(state, action) {
      state.alertsListCounter = action.payload;
    },
    setCurrentAlert(state, action) {
      state.currentAlert = action.payload;
    },
    setOpenAlertModal(state, action) {
      state.openAlertModal = action.payload;
    },
    setAlertsNotificationList(state, action) {
      state.alertsNotificationList = action.payload;
    },
    setAlertsNotificationCounter(state, action) {
      state.alertsNotificationCounter = action.payload;
    },
    toggleAlertNotificationListDropdownVisible(state) {
      state.alertListDropdownVisible = !state.alertListDropdownVisible;
    },
    setAlertNotifications(state, action) {
      state.alertNotificationsPopUp = action.payload;
    },
    setAlertCheckedNotifications(state, action) {
      state.checkedNotifications.push(action.payload);
    },
    removeAlertCheckedNotifications(state, action) {
      const index = state.checkedNotifications.indexOf(action.payload);
      if (index !== -1) {
        state.checkedNotifications.splice(index, 1);
      }
    },
    updateAlertCheckedNotifications(state, action) {
      state.alertSettingsItemData = action.payload;
    },
    setAlertsSortData(state, action) {
      state.sortData = action.payload;
    },
    setColumnsOrderChanged(state) {
      state.columnsOrderChanged = !state.columnsOrderChanged;
    },
    setMixedAlertsColumns(state, action) {
      state.mixedAlertColumns = action.payload;
    },
    setCurrentAlertData(state, action) {
      state.currentAlertData = action.payload;
    }
  },
});

export const {
  setAlertsList,
  setAlertsListCounter,
  setCurrentAlert,
  setOpenAlertModal,
  setAlertsNotificationList,
  setAlertsNotificationCounter,
  toggleAlertNotificationListDropdownVisible,
  setAlertNotifications,
  setAlertCheckedNotifications,
  updateAlertCheckedNotifications,
  removeAlertCheckedNotifications,
  setAlertsSortData,
  setColumnsOrderChanged,
  setMixedAlertsColumns,
  setCurrentAlertData
} = alertSlice.actions;
export default alertSlice;
