import React from 'react';
import styles from '../sass/WidgetCharts.module.scss';
import { IScreenerSelector } from '../types/WidgetChartsInterfaces';
import ScreenerFolderItem from './ScreenerFolderItem';
import ScreenerTypes from './ScreenerTypes';
import ScreenerList from './ScreenerList';
import SearchBlock from './SearchBlock';

const ScreenerSelector = ({
  screenerStates,
  setScreenerStates,
  selectedType,
  screenerSearch,
  widgetStyle,
  folderWidgetStyle,
  theme
}: IScreenerSelector): React.ReactElement => (
  <>
    {screenerStates.screenerIsOpen
      && (
        <div className={styles.screenerTabDropdownMenu} style={widgetStyle}>
          <div className={styles.screenerSearch}>
            <SearchBlock
              screenerStates={screenerStates}
              setScreenerStates={setScreenerStates}
              stateKey="searchText"
            />

            <div className={styles.screenerItems}>
              <ScreenerTypes
                screenerStates={screenerStates}
                setScreenerStates={setScreenerStates}
              />
              <ScreenerList
                selectedType={selectedType}
                screenerStates={screenerStates}
                setScreenerStates={setScreenerStates}
                screenerSearch={screenerSearch}
              />
            </div>
          </div>
        </div>
      )}
    {screenerStates.folderMenuIsOpen > -1 && (
      <ScreenerFolderItem
        selectedType={selectedType}
        setScreenerStates={setScreenerStates}
        screenerStates={screenerStates}
        screenerSearch={screenerSearch}
        folderWidgetStyle={folderWidgetStyle}
        theme={theme}
      />
    )}
  </>
);

export default ScreenerSelector;
