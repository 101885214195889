import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './sass/WatchListModals.module.scss';
import TextField from '../TextField/TextField';
import { setModalType } from '../../store/watchlist/actions';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';

const AddSymbolModalHeader = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <ButtonListenerWrapper handleCancel={() => dispatch(setModalType(''))} handleConfirm={() => {}}>
      <div className={styles.addSymbolModalHeader}>
        <TextField text={t('addSymbolModalHeader')} styleType="addSymbolModalHeader" />
        <div
          aria-hidden="true"
          onMouseDown={() => dispatch(setModalType(''))}
          className={styles.closeIcon}
        >
          <CloseIcon />
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

export default AddSymbolModalHeader;
