import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import styles from './sass/AlertsList.module.scss';
import AlertsListItems from './components/AlertsListItems';
import EmptyAlertsList from './components/EmptyAlertsList';
import {
  toggleAlertNotificationListDropdownVisible,
  setAlertsNotificationCounter
} from '../../store/alerts/slice';
import DefaultButton from '../DefaultButton/DefaultButton';
import { setAlertActionFromApp } from '../ChartContainer/utils/alertHelper';
import { currentAlertData } from '../ChartContainer/constants/constants';

const AlertsListBody = ({
  alertAction, toggleAlertStatus,
  checkActive, updateViewedAlerts,
  getAlertsNotificationList, clearAlerts
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alertsNotificationList = useSelector((state) => state.alertsState.alertsNotificationList, shallowEqual);
  const alertsNotificationCounter = useSelector((state) => state.alertsState.alertsNotificationCounter, shallowEqual);

  const addNewAlert = async () => {
    await setAlertActionFromApp();

    alertAction(currentAlertData.data);
    dispatch(toggleAlertNotificationListDropdownVisible());
  };

  const handleClearAll = async () => {
    await clearAlerts();
    getAlertsNotificationList();
    dispatch(setAlertsNotificationCounter(0));
  };

  return (
    <div className={styles.body}>
      {alertsNotificationList.length ? (
        <div className={styles.countBlock}>
          <p className={styles.newsCounter}>
            {t('new')}
            <span className={styles.blueDot} />
            <span>{alertsNotificationCounter}</span>
          </p>
          <DefaultButton handleClick={handleClearAll} className={styles.clearAll}>
            {t('cleanAll')}
          </DefaultButton>
        </div>
      ) : (<></>)}
      {alertsNotificationList.length
        ? (
          <AlertsListItems
            updateViewedAlerts={updateViewedAlerts}
            checkActive={checkActive}
            toggleAlertStatus={toggleAlertStatus}
            alertNotifications={alertsNotificationList}
          />
        )
        : <EmptyAlertsList addNewAlert={addNewAlert} />}
    </div>
  );
};

AlertsListBody.propTypes = {
  alertAction: PropTypes.func.isRequired,
  toggleAlertStatus: PropTypes.func.isRequired,
  checkActive: PropTypes.func.isRequired,
  updateViewedAlerts: PropTypes.func.isRequired,
  getAlertsNotificationList: PropTypes.func.isRequired,
  clearAlerts: PropTypes.func.isRequired,
};

export default AlertsListBody;
