// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';

import { IWidgetChartsHeader } from '../types/DashboardComponentsInterfaces';
import styles from '../sass/DashboardComponents.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import ChartHeaderFilterView from '../../ChartHeaderFilter/ChartHeaderFilterView';
import ColorGroupDropdownButton from './ColorGroupDropdownButton';

const ChartWidgetHeader = ({
  removeWidget, widget,
  setColorGroupe, statsChart,
  setOpenFilters,
  openFilters,
  filter,
  checkedFilters,
  handleAddFilters,
  disabledAction,
  estimateVisibleData,
  estimateVisibleHandler,
  numberOfQuarters,
  currentSource,
  setNumberOfQuarters,
  setSource,
  showData,
  width,
  isEditable
}: IWidgetChartsHeader): React.ReactElement => {
  const colorDropdownRef = useRef<HTMLDivElement>(null);
  const [isColorDropOpen, setIsColorDropOpen] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    const handleClose = (event: MouseEvent) => {
      const path = event.composedPath();
      if (filter?.current && !path.includes(filter?.current)) {
        if (typeof setOpenFilters === 'function') {
          setOpenFilters(false);
        }
      }
    };
    document.body.addEventListener('click', handleClose);
    return () => document.body.removeEventListener('click', handleClose);
  }, []);

  useEffect(() => {
    if (!width) return;
    if (width < 80) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [width]);

  return (
    <div
      style={{ maxWidth: hidden ? '35px' : '100%' }}
      className={`${styles.dashboardWidgetHeaderChart}
      ${statsChart && styles.statsChart}
      ${showData && styles.noTransparent}`}
    >
      <div ref={colorDropdownRef} className={styles.dashboardWidgetHeaderRightBlock}>
        <ColorGroupDropdownButton
          toggleColorDrop={setIsColorDropOpen}
          isColorDropOpen={isColorDropOpen}
          widget={widget}
          colorDropdownRef={colorDropdownRef}
          handleSetColorGroupe={setColorGroupe}
          componentStyle={hidden ? { display: 'none' } : {}}
        />
        {statsChart && (
          <ChartHeaderFilterView
            setOpenFilters={setOpenFilters}
            openFilters={openFilters}
            ref={filter}
            checkedFilters={checkedFilters}
            handleAddFilters={handleAddFilters}
            disabledAction={disabledAction}
            estimateVisibleData={estimateVisibleData}
            estimateVisibleHandler={estimateVisibleHandler}
            numberOfQuarters={numberOfQuarters}
            currentSource={currentSource}
            setNumberOfQuarters={setNumberOfQuarters}
            setSource={setSource}
            className={styles.filterButton}
            componentStyle={hidden ? { display: 'none' } : {}}
            isWidget
          />
        )}
        {isEditable && <CloseIcon className={styles.butClose} onClick={() => removeWidget(widget.id)} />}
      </div>
    </div>
  );
};

export default ChartWidgetHeader;
