import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { oneOfType } from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import { ReactComponent as FolderIcon } from '../../assets/images/icons/screener/folder.svg';
import { ReactComponent as StarIcon } from '../../assets/images/icons/screener/star.svg';
import {
  FIELD_STORAGE_COLUMN, NEW_SCREEN_DATA, TYPE_FIND_ITEM, VIEW_TYPES
} from '../../constants/screener';
import { getItemsId, setSelectedScreen } from '../../store/newScreen/actions';
import { setMarketsFilterScreenerAction } from '../../store/screener/actions';
import { findIndexItem } from '../../utils/helpers';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import { removeLocalStorage } from '../../utils/storageWorks';
import BlockSwitcher from './components/BlockSwitcher';
import NewScreenButtonBlock from './NewScreenButtonBlock';
import { changeTabAction } from '../../store/tableData/slice';

const DeleteModal = ({
  setOpenModal, currentItem, currentSubItem, removeScreen, removeFolder, singleItemStatus, currentPresetItem,
  columnsSetRemove
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);

  const removeItemStoreChange = (selectedId, itemId) => {
    if (selectedId === itemId) {
      dispatch(changeTabAction());
      dispatch(setSelectedScreen({}));
      dispatch(getItemsId({}));
      dispatch(setMarketsFilterScreenerAction({ data: [] }));

      if (currentItem.type === VIEW_TYPES.COLUMNS) {
        removeLocalStorage(FIELD_STORAGE_COLUMN);
      }
    }
  };

  const removeItem = (id, title, screenId, itemId) => {
    removeScreen(id, title);
    removeItemStoreChange(screenId, itemId);
  };

  const removeFolderHandler = () => {
    const checkItemInFolder = findIndexItem(currentItem?.items, TYPE_FIND_ITEM.ID, selectedScreen.id);
    removeFolder(currentItem.id, currentItem.title);
    if (checkItemInFolder !== -1) {
      removeItemStoreChange(selectedScreen.id, selectedScreen.id);
    }
  };

  const removeItemHandler = () => {
    if (currentItem?.type === NEW_SCREEN_DATA.FOLDER && !singleItemStatus) {
      removeFolderHandler();
    } else if ((currentItem?.type !== NEW_SCREEN_DATA.FOLDER && currentPresetItem?.type !== NEW_SCREEN_DATA.PRESETS)
      || (columnsSetRemove && currentItem?.type !== NEW_SCREEN_DATA.FOLDER)) {
      removeItem(currentItem.id, currentItem.title, selectedScreen.id, currentItem.id);
    } else if (currentSubItem && singleItemStatus) {
      removeItem(currentSubItem.id, currentSubItem.title, currentSubItem.id, currentSubItem.id);
    } else if (currentPresetItem?.type === NEW_SCREEN_DATA.PRESETS && !columnsSetRemove) {
      removeItem(currentPresetItem?.id, currentPresetItem?.title, selectedScreen?.id, currentPresetItem?.id);
    }
    setOpenModal();
  };

  return (
    <ButtonListenerWrapper
      handleCancel={setOpenModal}
      handleConfirm={removeItemHandler}
    >
      <div className={stylesModal.moveToFolderWrapper}>
        <div className={stylesModal.moveToFolderHeader}>
          <TextField text={t('areYouSure')} styleType="createFolderModalHeader" />
          <div aria-hidden="true" onMouseDown={() => setOpenModal()}>
            <CloseIcon />
          </div>
        </div>
        <div className={stylesModal.moveToFolderBody}>
          <TextField text={t('youSureYouWant')} styleType="moveToBodyText" />
          <div className={stylesModal.itemFolderWrapperActive}>
            {!columnsSetRemove && (
              <>
                {currentItem?.type === NEW_SCREEN_DATA.SCREENER || currentSubItem?.type === NEW_SCREEN_DATA.SCREENER
                  ? <StarIcon />
                  : <FolderIcon />}
              </>
            )}
            {currentSubItem?.id ? (
              <TextField text={currentSubItem.title} styleType="watchListTitleActive" />
            ) : (
              <BlockSwitcher
                hideIcon={columnsSetRemove}
                currentItem={currentItem}
                currentPresetItem={currentPresetItem}
              />
            )}
          </div>
          <NewScreenButtonBlock
            cancelHandler={setOpenModal}
            cancelButton={t('cancel')}
            actionHandler={removeItemHandler}
            actionButton={t('delete')}
          />
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

DeleteModal.defaultProps = {
  currentSubItem: {} || PropTypes.string,
  currentItem: {
    id: 0,
    title: '',
    countStock: '',
    type: '',
    favorites: false,
    items: [{}],
  },
  currentPresetItem: {
    id: 0,
    title: '',
    countStock: '',
    type: '',
    favorites: false,
    items: [{}],
  },
  columnsSetRemove: false
};

DeleteModal.propTypes = {
  singleItemStatus: PropTypes.bool.isRequired,
  removeFolder: PropTypes.func.isRequired,
  currentSubItem: oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string
    }),
    PropTypes.string,
  ]),
  removeScreen: PropTypes.func.isRequired,
  currentItem: oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      countStock: PropTypes.string,
      type: PropTypes.string,
      favorites: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.func
  ]),
  setOpenModal: PropTypes.func.isRequired,
  currentPresetItem: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      countStock: PropTypes.string,
      favorites: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.func
  ]),
  columnsSetRemove: PropTypes.bool
};

export default DeleteModal;
