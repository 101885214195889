import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import styles from '../sass/DashboardComponents.module.scss';
import { IPositionWidget } from '../types/DashboardComponentsInterfaces';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import PositionSize from '../../PositionSize/positionSize';
import PositionWidgetHeader from './PositionWidgetHeader';

const PositionSizeWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe, dashboardId
}: IPositionWidget): React.ReactElement => {
  const { ref, width } = useResizeDetector();
  const { t } = useTranslation();

  return (
    <div className={styles.positionSizeContainer} ref={ref}>
      <div className={styles.positionSizeHeader}>
        <div className={`${styles.positionSizeDnDBlock} handle-grid-drag`}>
          <DndControl />
        </div>
        <p className={styles.positionTitle}>{t('positionSizeName')}</p>
        <PositionWidgetHeader
          setColorGroupe={setColorGroupe}
          updateWidget={updateWidget}
          removeWidget={removeWidget}
          widget={widget}
          width={width}
          dashboardId={dashboardId}
        />
      </div>

      <div className={styles.positionWrap}>
        <PositionSize
          width={width ?? 0}
          widget={widget}
          dashboardId={dashboardId}
          updateWidget={updateWidget}
        />
      </div>
    </div>
  );
};

export default PositionSizeWidget;
