import ACTION_ACCOUNT from './types';

export const profileAction = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_USER_PROFILE,
  payload
});
export const avatarAction = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_USER_AVATAR,
  payload
});

export const toggleSideMenuAction = () => ({
  type: ACTION_ACCOUNT.TOGGLE_SIDE_MENU
});

export const errorMessageAction = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_ERROR_MESSAGE,
  payload
});

export const activeSubscriptionAction = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_USER_ACTIVE_SUBSCRIPTION,
  payload
});

export const twitterAction = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_TWITTER,
  payload
});

export const saveUserState = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_USER_STATE,
  payload
});

export const saveSelectedScreenId = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_SELECTED_SCREEN_ID,
  payload
});

export const saveLastSymbolDeeplistState = (payload) => ({
  type: ACTION_ACCOUNT.SAVE_LAST_SYMBOL_DEEPLIST_STATE,
  payload
});

export const setLoadedUserSettings = (payload) => ({
  type: ACTION_ACCOUNT.LOADED_USER_SETTINGS,
  payload
});

export const setLastSortData = (payload) => ({
  type: ACTION_ACCOUNT.SET_LAST_SORT_DATA,
  payload
});

export const setGlobalSearchVisible = (payload) => ({
  type: ACTION_ACCOUNT.SET_GLOBAL_SEARCH_VISIBLE,
  payload
});

export const togglePanelDataAdd = (payload) => ({
  type: ACTION_ACCOUNT.TOGGLE_PANEL_DATA_ADD,
  payload
});

export const updateStoreAction = (payload) => ({
  type: ACTION_ACCOUNT.UPDATE_STORE_ACTION,
  payload
});

export const updateNestedStoreAction = (payload) => ({
  type: ACTION_ACCOUNT.UPDATE_NESTED_STORE_ACTION,
  payload
});

export const updateNestedSortingAction = (payload) => ({
  type: ACTION_ACCOUNT.UPDATE_NESTED_SORTING_ACTION,
  payload
});

export const updateColumnSetsPresetsScreenerIdsAction = (payload) => ({
  type: ACTION_ACCOUNT.UPDATE_NESTED_COLUMN_SETS_PRESETS_SCREENER_IDS_ACTION,
  payload
});

export const setUpdatedLastState = (payload) => ({
  type: ACTION_ACCOUNT.UPDATED_LAST_STATE,
  payload
});

export const updateNestedPscAction = (payload) => ({
  type: ACTION_ACCOUNT.SET_POSITION_SIZE_CALCULATOR,
  payload
});

export const updateWidgetChartsAction = (payload) => ({
  type: ACTION_ACCOUNT.SET_WIDGET_CHARTS,
  payload
});

export const setOverwriteFlag = (payload) => ({
  type: ACTION_ACCOUNT.SET_OVERWRITE_FLAG,
  payload
});

export const setOpenConfirmModal = (payload) => ({
  type: ACTION_ACCOUNT.SET_OPEN_CONFIRM_MODAL,
  payload
});

export const logOut = (payload) => ({
  type: ACTION_ACCOUNT.LOG_OUT,
  payload
});

export const setLastStateId = (payload) => ({
  type: ACTION_ACCOUNT.SET_LAST_STATE_ID,
  payload
});

export const setGlobalSearchOnKeyDown = (payload) => ({
  type: ACTION_ACCOUNT.SET_GLOBAL_SEARCH_ON_KEY_DOWN,
  payload
});
