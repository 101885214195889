import React, { Suspense, useContext } from 'react';
import SharedSymbolRow from './screenerComponents/SharedSymbolRow';
import DLHeader from './DeeplistComponents/DLHeader';
import styles from './sass/TableScreener.module.scss';
import ScreenerHeader from './screenerComponents/ScreenerHeader';
import { EMPTY_STRING, SCREENER_VIEWS } from '../../constants/screener';
import ScreenerChangerView from './screenerComponents/ScreenerChangerView';
import ScreenerMultichartView from '../../components/MultichartView/ScreenerMultichartView';
import Header from '../../components/Header/Header';
import FallbackLoader from '../../components/fallbackLoader/FallbackLoader';
import GlobalSearch from '../../components/GlobalSearch/GlobalSearch';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import ScreenerPortalItemsWrapper from './screenerComponents/ScreenerPortalItemsWrapper';
import WatchlistPortalItemsWrapper from './DeeplistComponents/WatchlistPortalItemsWrapper';
import NewScreenModals from '../../components/NewScreenModals/NewScreenModals';
import TableContext from './context/TableContext';

const TableView = () => {
  const {
    isWatchlist,
    screenerView,
    openModalHandler,
    openModal,
    globalSearchVisible,
    openConfirmModal,
    modalType,
    sharedDashboardItem,
    currentPresetItem,
    currentScreensItem,
    currentScreensSubItem,
    currentItemHandler,
    singleItemStatus,
    currentItemSelected,
    selectedScreenId,
    hoveredPresetItem,
    activeLastScreen,
    setCurrentScreensSubItem,
    setHoveredPresetItem,
    setActiveModalWrapper,
    resetSearchHandler,
    handlerSearch,
    search,
    listResults,
    setSearch,
    setGlobalSearchVisible,
    setIsVisibleFilters,
    setPaging,
    hideChart,
    handleOpenChart,
    setActiveSymbolValue,
    searchDropdownVisible,
    setSearchDropdownVisible,
    focus,
    setFocus,
  } = useContext(TableContext);

  return (
    <div className={styles.wrapper}>
      {(openModal === EMPTY_STRING && globalSearchVisible) && (
        <Suspense fallback={<FallbackLoader />}>
          <GlobalSearch
            setPaging={setPaging}
            setGlobalSearchVisible={setGlobalSearchVisible}
            handlerSearch={handlerSearch}
            search={search}
            listResults={listResults}
            focus={focus}
            setFocus={setFocus}
            hideChart={hideChart}
            handleOpenChart={handleOpenChart}
            setSearch={setSearch}
            setActiveSymbolValue={setActiveSymbolValue}
            setIsVisibleFilters={setIsVisibleFilters}
            resetSearchHandler={resetSearchHandler}
            searchDropdownVisible={searchDropdownVisible}
            setSearchDropdownVisible={setSearchDropdownVisible}
          />
        </Suspense>
      )}
      {isWatchlist ? <DLHeader /> : <ScreenerHeader />}
      {sharedDashboardItem?.id && (<SharedSymbolRow openModalHandler={openModalHandler} />)}
      {openConfirmModal && <ConfirmModal />}
      <div className={styles.content}>
        <Header />
        <div id="screenShotTarget" className={styles.screenShotAreaWrapper}>
          {screenerView === SCREENER_VIEWS.SCREENER && (
            <ScreenerChangerView />
          )}
          {screenerView === SCREENER_VIEWS.CHART && (
            <ScreenerMultichartView />
          )}
        </div>
        {(openModal || modalType) && (
          <NewScreenModals
            isWatchlist={isWatchlist}
            modalType={modalType}
            openModal={openModal}
            openModalHandler={openModalHandler}
            selectedScreenId={selectedScreenId}
            activeLastScreen={activeLastScreen}
            singleItemStatus={singleItemStatus}
            hoveredPresetItem={hoveredPresetItem}
            setHoveredPresetItem={setHoveredPresetItem}
            currentPresetsItem={currentPresetItem}
            currentItemHandler={currentItemHandler}
            currentScreensItem={currentScreensItem}
            currentItemSelected={currentItemSelected}
            currentScreensSubItem={currentScreensSubItem}
            setCurrentScreensSubItem={setCurrentScreensSubItem}
            setActiveModalWrapper={setActiveModalWrapper}
            resetSearchHandler={resetSearchHandler}
          />
        )}
      </div>
      {isWatchlist ? <WatchlistPortalItemsWrapper /> : <ScreenerPortalItemsWrapper />}
    </div>
  );
};

export default TableView;
