import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DataPanel.module.scss';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/trash.svg';
import { ReactComponent as DataPanelIcon } from '../../../assets/images/icons/tvIcons/dataPanel.svg';
import { MODAL_ICONS, MODAL_TYPES, MAX_CATEGORY_NAME } from '../dataPanelConstants';
import ButtonListenerWrapper from '../../ButtonListenerWrapper/ButtonListenerWrapper';
import { useTheme } from '../../../utils/hooks/useTheme';

const DataPanelModal = ({ modalData, checkUniqueCategory }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isInputError, setIsInputError] = useState(false);
  const [errorType, setErrorType] = useState('');
  const { theme } = useTheme();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleConfirm = () => {
    let isValid = true;
    if ([MODAL_TYPES.ADD_CATEGORY, MODAL_TYPES.RENAME_CATEGORY].includes(modalData?.modalType)) {
      if (inputValue.length > MAX_CATEGORY_NAME) {
        setIsInputError(true);
        setErrorType(t('dataPanelModalInputLengthError', { max: MAX_CATEGORY_NAME }));
        isValid = false;
      }
      if (!inputValue?.length) {
        setIsInputError(true);
        setErrorType(t('dataPanelModalInputError'));
        isValid = false;
      }
      if (modalData?.modalType === MODAL_TYPES.ADD_CATEGORY && !checkUniqueCategory(inputValue)) {
        setIsInputError(true);
        setErrorType(t('dataPanelModalInputExist'));
        isValid = false;
      }
    }
    if (isValid) {
      const actionMassage = {
        type: modalData?.modalType,
        category: modalData?.category,
        inputValue,
      };
      modalData?.actionConfirm?.action(actionMassage);
    }
  };

  const checkRestoreModal = () => {
    return [MODAL_TYPES.RESTORE_DEFAULTS, MODAL_TYPES.REMOVE_CATEGORY].includes(modalData?.modalType);
  };

  const getDisabledButton = () => {
    if (checkRestoreModal()) {
      return false;
    }
    return !inputValue?.length || inputValue.replace(/\s/g, '')?.length === 0;
  };

  useEffect(() => {
    if (inputValue?.length && inputValue.length <= MAX_CATEGORY_NAME) {
      setIsInputError(false);
      setErrorType('');
    }
  }, [inputValue]);

  useEffect(() => {
    if (modalData?.modalType === MODAL_TYPES.RENAME_CATEGORY) {
      setInputValue(modalData?.category);
    }
  }, []);

  return (
    <ButtonListenerWrapper handleCancel={modalData?.actionDecline?.action} handleConfirm={handleConfirm}>
      <div className={`${styles.dataPanelModalWrapper} ${theme}`}>
        <div className={`${styles.dataPanelModal} ${checkRestoreModal() && styles.full}`}>
          {modalData?.header && (
          <div className={styles.dataPanelModalHeader}>
            {t(modalData.header)}
          </div>
          )}
          <div className={styles.dataPanelModalContent}>
            {modalData?.icon && (
            <div className={styles.dataPanelModalIcon}>
              <div className={styles.dataPanelModalIconWrap}>
                {modalData?.icon === MODAL_ICONS.removeCategory && <IconRemove />}
                {modalData?.icon === MODAL_ICONS.restoreDefaults && <DataPanelIcon />}
              </div>
            </div>
            )}
            {modalData?.content && (
            <div className={styles.dataPanelModalContentText}>
              { modalData.modalType === MODAL_TYPES.REMOVE_CATEGORY
                ? `${t(modalData?.content)}${modalData?.category} ${t('removeCategory')}`
                : t(modalData?.content)}
            </div>
            )}
            {modalData?.input && (
            <div className={styles.dataPanelModalContentInputWrap}>
              <label htmlFor="dataPanelModalInput">
                {t(modalData?.input?.label)}
              </label>
              <input
                autoFocus
                name="dataPanelModalInput"
                onChange={handleInputChange}
                value={inputValue}
                placeholder={t(modalData?.input?.placeholder)}
                className={`${styles.dataPanelModalContentInput} ${isInputError && styles.NoValid}`}
              />
              <div className={styles.inputError}>{isInputError && errorType}</div>
            </div>
            )}
          </div>
          <div className={`${styles.dataPanelModalAction} ${modalData?.reverceAction && styles.reverce}`}>
            <DefaultButton
              disabled={getDisabledButton()}
              className={`${styles.dataPanelModalActionBtn} ${styles.confirm}`}
              handleClick={handleConfirm}
            >
              {t(modalData?.actionConfirm?.text)}
            </DefaultButton>
            <DefaultButton
              className={`${styles.dataPanelModalActionBtn} ${styles.decline}`}
              handleClick={modalData?.actionDecline?.action}
            >
              {t(modalData?.actionDecline?.text)}
            </DefaultButton>
          </div>
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

DataPanelModal.propTypes = {
  modalData: PropTypes.shape({
    modalType: PropTypes.string,
    category: PropTypes.string,
    header: PropTypes.string,
    icon: PropTypes.string,
    content: PropTypes.string,
    reverceAction: PropTypes.bool,
    input: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
    }),
    actionConfirm: PropTypes.shape({
      text: PropTypes.string,
      action: PropTypes.func,
    }),
    actionDecline: PropTypes.shape({
      text: PropTypes.string,
      action: PropTypes.func,
    }),
  }).isRequired,
  checkUniqueCategory: PropTypes.func.isRequired,
};

export default DataPanelModal;
