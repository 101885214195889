import { useDispatch } from 'react-redux';
import { getFromLocalStorage } from '../storageWorks';
import $api from '../../http';
import { loadingAction, saveResponseAction, saveResponseCheckAction } from '../../store/auth/actions';
import { checkTokenExpire, saveTokens, sessionTimeOut } from '../helpers';
import AUTH_PATH from '../../constants/requestPath';

const useAuth = () => {
  const dispatch = useDispatch();

  const checkAuthWorker = async () => {
    dispatch(loadingAction({ loading: true }));
    const checkToken = checkTokenExpire(getFromLocalStorage('refreshToken'));

    if (checkToken) {
      // eslint-disable-next-line no-console
      console.log('checkAuthWorker');
      const response = await $api.post(
        AUTH_PATH.REFRESH_TOKEN,
        {},
        {
          headers: {
            Authorization: `Bearer ${getFromLocalStorage('refreshToken')}`
          }
        }
      );
      saveTokens(response.data.accessToken, response.data.refreshToken);
      dispatch(saveResponseCheckAction({ isAuth: true, user: response.data.role, loading: false }));
      return response.data.role;
    }
    sessionTimeOut();
    dispatch(saveResponseAction({ isAuth: false, user: {}, loading: false }));
    return false;
  };

  return {
    checkAuthWorker,
  };
};

export default useAuth;
