import React from 'react';
import PropTypes from 'prop-types';

const TargetListIcon = ({
  colorStart
}) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len 
        d="M6.13419 0.0189517C6.07614 0.00653516 6.01591 0 5.95415 0C5.48104 0 5.09752 0.38353 5.09752 0.856637V3.141C5.09752 3.61411 5.48104 3.99764 5.95415 3.99764C6.42726 3.99764 6.81079 3.61411 6.81079 3.141V1.80896C8.63909 2.16364 10.0518 3.68254 10.2464 5.56307H8.95694C8.48383 5.56307 8.1003 5.9466 8.1003 6.4197C8.1003 6.89281 8.48383 7.27634 8.95694 7.27634H10.0784C9.61693 8.76571 8.36377 9.90697 6.81079 10.2082V8.9737C6.81079 8.50059 6.42726 8.11706 5.95415 8.11706C5.48104 8.11706 5.09752 8.50059 5.09752 8.9737V10.1931C3.57812 9.87017 2.35698 8.74064 1.90349 7.27393L3.34459 7.27613C3.81769 7.27685 4.20181 6.8939 4.20253 6.42079C4.20325 5.94769 3.8203 5.56357 3.34719 5.56285L1.73643 5.5604C1.83756 4.58728 2.26452 3.71197 2.90765 3.04335C3.23563 2.70237 3.22509 2.16008 2.88412 1.83211C2.54315 1.50413 2.00086 1.51467 1.67288 1.85564C0.637744 2.9318 0 4.39661 0 6.0086C0 9.31751 2.6824 11.9999 5.99132 11.9999C9.30023 11.9999 11.9826 9.31751 11.9826 6.0086C11.9826 2.74744 9.3771 0.0948431 6.13419 0.0189517Z"
        fill={colorStart}
      />
    </svg>
  );
};

TargetListIcon.propTypes = {
  colorStart: PropTypes.string.isRequired
};

export default TargetListIcon;
