import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTheme } from '../../utils/hooks/useTheme';
import ColumnsView from './ColumnsView';

const Columns = ({
  sortStatus, clearSorting, currentModalRef, openModalHandler, setNewSortData,
  isWatchlist, isWidget, sortData, visibleFromWidget, widgetId, widgetType, folderModalHandler
}) => {
  const [currentVisibleColumns, setCurrentVisibleColumns] = useState(false);
  const visibleColumnsScreen = useSelector(
    (state) => state.screenerState.visibleColumnsScreen, shallowEqual
  );
  const { theme } = useTheme();

  useEffect(() => {
    if (isWidget) {
      setCurrentVisibleColumns(visibleFromWidget);
    } else {
      setCurrentVisibleColumns(visibleColumnsScreen);
    }
  }, [visibleColumnsScreen, visibleFromWidget]);

  return (
    <ColumnsView
      visibleColumnsScreen={currentVisibleColumns}
      sortStatus={sortStatus}
      clearSorting={clearSorting}
      theme={theme}
      setNewSortData={setNewSortData}
      currentModalRef={currentModalRef}
      folderModalHandler={folderModalHandler}
      openModalHandler={openModalHandler}
      isWatchlist={isWatchlist}
      sortData={sortData}
      isWidget={isWidget}
      widgetId={widgetId}
      widgetType={widgetType}
    />
  );
};

Columns.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  openModalHandler: PropTypes.func.isRequired,
  setNewSortData: PropTypes.func,
  clearSorting: PropTypes.func.isRequired,
  folderModalHandler: PropTypes.func.isRequired,
  currentModalRef: PropTypes.shape({}),
  isWatchlist: PropTypes.bool,
  isWidget: PropTypes.bool,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
  visibleFromWidget: PropTypes.bool,
  widgetId: PropTypes.string,
  widgetType: PropTypes.string,
};

Columns.defaultProps = {
  setNewSortData: () => {},
  currentModalRef: null,
  isWatchlist: false,
  isWidget: false,
  sortData: [],
  visibleFromWidget: false,
  widgetId: '',
  widgetType: '',
};

export default Columns;
