import ReactPlayer from 'react-player/youtube';
import styles from './sass/EducationComponents.module.scss';
import ModalLayout from '../../layout/ModalLayout/ModalLayout';

import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { IVideoPlayer } from './types/EducationComponentsInterfaces';

const VideoPlayer = ({
  videoSrc, setShowCard
}: IVideoPlayer): React.ReactElement => (
  <ModalLayout typeModal="modalMobile" classModal="modalFade">
    <div className={styles.modalContainer}>
      <div className={styles.modalHeader}>
        <CloseIcon className={styles.closeIcon} onClick={() => setShowCard(false)} />
      </div>
      <div className={styles.playerContainer}>
        <ReactPlayer
          height="100%"
          width="100%"
          url={videoSrc}
          pip
          controls
          config={{
            embedOptions: {
              playerVars: {
                controls: 1,
                autoplay: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 1,
                playsinline: 1
              },
            }
          }}
        />
      </div>

    </div>
  </ModalLayout>
);

export default VideoPlayer;
