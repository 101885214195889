import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/FilterScreen.module.scss';
import DefaultButton from '../../DefaultButton/DefaultButton';
import TextField from '../../TextField/TextField';
import { ReactComponent as IconOr } from '../../../assets/images/icons/screener/doubleArrow.svg';
import Tooltip from '../../Tooltip/Tooltip';

const FilterOr = ({
  title, btnOr, addNewGroupHandler, textTooltip 
}) => (
  <div className={styles.containerOr}>
    <div className={styles.titleOr}>
      <TextField text={title} styleType="" />
      <Tooltip text={textTooltip} />
    </div>
    <DefaultButton buttonClass="orButton" handleClick={addNewGroupHandler}>
      <IconOr />
      <TextField text={btnOr} styleType="orButton" />
    </DefaultButton>
  </div>
);

FilterOr.propTypes = {
  title: PropTypes.string,
  btnOr: PropTypes.string,
  addNewGroupHandler: PropTypes.func,
  textTooltip: PropTypes.string
};

FilterOr.defaultProps = {
  title: '',
  btnOr: '',
  addNewGroupHandler: () => {},
  textTooltip: ''
};

export default FilterOr;
