import React, { useState, useRef, useEffect } from 'react';

import { IWidgetPositionHeader } from '../types/DashboardComponentsInterfaces';
import styles from '../sass/DashboardComponents.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close.svg';
import ColorGroupDropdownButton from './ColorGroupDropdownButton';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { PSC_REMOVE_WIDGET } from '../../../constants/storage';

const PositionWidgetHeader = ({
  removeWidget, widget,
  setColorGroupe,
  width,
  dashboardId
}: IWidgetPositionHeader): React.ReactElement => {
  const colorDropdownRef = useRef<HTMLDivElement>(null);
  const [isColorDropOpen, setIsColorDropOpen] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(false);
  const { updateNestedPscActionData } = useLastStateUpdateStore();

  useEffect(() => {
    if (!width) return;
    if (width < 80) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  }, [width]);

  const handleRemoveWidget = () => {
    removeWidget(widget.id);
    updateNestedPscActionData(PSC_REMOVE_WIDGET, widget.id, dashboardId);
  };

  return (
    <div
      style={{ maxWidth: hidden ? '35px' : '100%' }}
      className={`${styles.dashboardWidgetHeaderPosition}`}
    >
      <div ref={colorDropdownRef} className={styles.dashboardWidgetHeaderRightBlock}>
        <ColorGroupDropdownButton
          toggleColorDrop={setIsColorDropOpen}
          isColorDropOpen={isColorDropOpen}
          widget={widget}
          colorDropdownRef={colorDropdownRef}
          handleSetColorGroupe={setColorGroupe}
          componentStyle={hidden ? { display: 'none' } : {}}
        />
        <CloseIcon className={styles.butClose} onClick={handleRemoveWidget} />
      </div>
    </div>
  );
};

export default PositionWidgetHeader;
