import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { SOURCES, STATS_CLASS_LIST, FILTER_LIST } from '../../../constants/statsChart';
import TableChartItem from './TableChartItem';
import styles from '../sass/TableChart.module.scss';
import { ITableChartWrapper } from './Types';

const TableChartWrapper = (
  {
    currentData, columnWidth, isFullWidth = false,
    checkedFilters, tableBorderSettings, isWidget
  }: ITableChartWrapper
): React.ReactElement => {
  const { t } = useTranslation();
  const [tooltipId, setTooltipId] = useState(null);

  const handleClearTooltipId = () => {
    setTooltipId(null);
  };

  useEffect(() => {
    const scrollContainer = document.getElementById('scroll');
    scrollContainer?.addEventListener('scroll', handleClearTooltipId);

    return () => {
      scrollContainer?.removeEventListener('scroll', handleClearTooltipId);
    };
  }, []);

  return (
    <>
      <div className={`${styles.container} ${isWidget && styles.widgetTable}`}>
        <div className={styles.table_header}>
          <div className={`${styles.table_header_title} ${isWidget && styles.titleWidget}`}>
            {t('data')}
          </div>
          <div className={styles.table_header_content}>
            {currentData.length && currentData[0] && currentData[0].map((item, index) => {
              const middleFlag = item?.estimate === SOURCES.HISTORY
              && currentData[0][index + 1]?.estimate === SOURCES.ESTIMATE;

              return (
                <div
                  className={`${styles[item.estimate === SOURCES.ESTIMATE
                    ? STATS_CLASS_LIST.TABLE_HEADER_EST : STATS_CLASS_LIST.TABLE_HEADER]}
                  ${middleFlag ? styles.borderedHeader : ''}
                  ${index + 1 === currentData[0].length && !isFullWidth ? styles.lastQuarter : ''}`}
                  style={{ width: columnWidth }}
                  key={[item?.quarter, item?.Row, index].join('_')}
                  id={item.quarter}
                >
                  {item.quarter}
                </div>
              );
            })}
          </div>
        </div>
        <div className={`${styles.table_content} ${isWidget && styles.widgetTableContent}`}>
          {currentData?.length && FILTER_LIST?.map((item) => (
            <React.Fragment key={item}>
              { checkedFilters?.includes(item) ? (
                <TableChartItem
                  tableBorderSettings={tableBorderSettings}
                  width={columnWidth}
                  key={item}
                  setTooltipId={setTooltipId}
                  tooltipId={tooltipId}
                  data={item === 'Earnings' ? currentData[0] : currentData[1]}
                  filterItem={item}
                  isWidget={isWidget}
                />
              ) : null}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default TableChartWrapper;
