import getInputArray from '../utilities/getInputArrayTs';
import { IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId } from '../../charting_library/charting_library';
import { defaultInputs, mainInputs } from './metainfo/inputs';
import plots from './metainfo/plots';
import { defaultStyles, styles } from './metainfo/styles';

export default function webbyRsi(mPineJS: PineJS) {
  return {
    name: 'DV - Webby RSI',
    metainfo: {
      id: 'TL-webbyRsi@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Webby RSI',
      description: 'DV - Webby RSI',
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: 'percent',
        precision: 1
      },
      inputs: mainInputs,
      plots: plots,
      styles: styles,
      defaults: {
        styles: defaultStyles,
        inputs: defaultInputs
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs) => {
        this._context = context;
        const [iMAVisible, iMALength, iMAType, iHideZoneLines] = getInputArray({
          inputs,
          length: 4
        });

        const closeSeries = this._context.new_var(mPineJS.Std.close(this._context));

        const ema21 = mPineJS.Std.ema(closeSeries, 21, this._context);
        const lowVsEma21 = ((mPineJS.Std.low(this._context) - ema21) / mPineJS.Std.close(this._context)) * 100;
        const lowVsEma21Series = this._context.new_var(lowVsEma21);

        const movingAverageValue = iMAType === 'SMA'
          ? mPineJS.Std.sma(lowVsEma21Series, iMALength, this._context)
          : mPineJS.Std.ema(lowVsEma21Series, iMALength, this._context);

        const movingAverage = !iMAVisible || movingAverageValue <= 0 ? null : movingAverageValue;
        const webbyRsi = lowVsEma21 > 0 ? Math.abs(lowVsEma21) : null;

        return [
          {
            id: 'webbyRsi',
            value: webbyRsi ?? NaN,
            offset: 0
          },
          {
            id: 'movingAverage',
            value: movingAverage ?? NaN,
            offset: 0
          },
          {
            id: 'zeroPointFiveLine',
            value: iHideZoneLines ? NaN : 0.5,
            offset: 0
          },
          {
            id: 'twoLine',
            value: iHideZoneLines ? NaN : 2,
            offset: 0
          },
          {
            id: 'fourLine',
            value: iHideZoneLines ? NaN : 4,
            offset: 0
          },
          {
            id: 'sixLine',
            value: iHideZoneLines ? NaN : 6,
            offset: 0
          }
        ];
      };
    }
  };
}
