export const PAGE_SIZE = 20;
export const INITIAL_PARAMS = {
  page: 1,
  per_page: PAGE_SIZE,
  search: '',
  sort: 'latest',
  isLoadMore: false,
  categories: ['all'],
  levels: ['all'],
  viewMode: 0,
};
export const ARTICLES = 'Articles';
export const TUTORIALS = 'Tutorials';
export const EVENTS = 'Events';
export const TUTORIAL_SLUG = 'tutorial';
export const WEBINAR_SLUG = 'webinar';
export const SORT_TYPES = ['Latest', 'Popular', 'Oldest', 'A-Z', 'Z-A'];
