import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IContext, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function webbyRSLine(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Webby RS Line',
    metainfo: {
      id: 'TL-webbyRSLinek@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Webby RS Line',
      description: 'DV - Webby RS Line',
      is_price_study: false,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Distance'
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Plot'
        },
        plot_4: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Plot'
        }
      },
      inputs: [
        {
          defval: 'SPY',
          id: 'in_0',
          name: 'Index to Compare',
          type: 'symbol' as StudyInputType.Symbol
        }
      ],
      plots: [{
        id: 'plot_0',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_1',
        palette: 'palette_0',
        target: 'plot_0',
        type: 'colorer' as StudyPlotType.Colorer
      }, {
        id: 'plot_2',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_3',
        palette: 'palette_1',
        target: 'plot_2',
        type: 'colorer' as StudyPlotType.Colorer
      }, {
        id: 'plot_4',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_5',
        palette: 'palette_2',
        target: 'plot_4',
        type: 'colorer' as StudyPlotType.Colorer
      }],
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Color 0'
            },
            1: {
              name: 'Color 1'
            }
          },
          valToIndex: {
            0: 0,
            1: 1
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Color 0'
            },
            1: {
              name: 'Color 1'
            }
          },
          valToIndex: {
            2: 0,
            3: 1
          }
        },
        palette_2: {
          colors: {
            0: {
              name: 'Color 0'
            },
            1: {
              name: 'Color 1'
            }
          },
          valToIndex: {
            4: 0,
            5: 1
          }
        }
      },
      defaults: {
        inputs: {
          in_0: 'SPY',
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#2962ff',
                style: 0,
                width: 2
              },
              1: {
                color: '#FF5252',
                style: 0,
                width: 2
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#00E676',
                style: 0,
                width: 1
              },
              1: {
                color: '#880E4F',
                style: 0,
                width: 1
              }
            }
          },
          palette_2: {
            colors: {
              0: {
                color: '#4CAF50',
                style: 0,
                width: 1
              },
              1: {
                color: '#FF5252',
                style: 0,
                width: 1
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 1,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_4: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          }
        },
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs) => {
        this._context = context;

        const [in_0] = getInputArray({
          inputs,
          length: 1
        });

        this._context.new_sym(in_0, mPineJS.Std.period(this._context));
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_unlimited_var(this._context.symbol.time);
        // Select the secondary symbol
        this._context.select_sym(1);
        const secondarySymbolTime = this._context.new_unlimited_var(this._context.symbol.time);

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolLow = this._context.new_unlimited_var(mPineJS.Std.low(this._context));
        const newSymLow = secondarySymbolLow.adopt(secondarySymbolTime, mainSymbolTime, 1);

        this._context.select_sym(0);
        const low = mPineJS.Std.low(this._context);

        const rs = (low / newSymLow) * 100;
        const rsSeries = this._context.new_var(rs);
        const ema = mPineJS.Std.ema(rsSeries, 21, this._context);
        const dist = ((rs - ema) / rs) * 100;
        const ema1 = mPineJS.Std.ema(context.new_var(dist), 3, this._context);
        const ema2 = mPineJS.Std.ema(context.new_var(dist), 13, this._context);
        const histCol = dist > 0 ? 0 : 1;

        return [
          dist,
          histCol,
          ema1,
          lineColorChecker(this._context, ema1),
          ema2,
          lineColorChecker(this._context, ema2)
        ];
      };
    }
  };
}

const lineColorChecker = (mcontext: IContext, val: number): number => {
  return mcontext.new_var(val).get(0) > mcontext.new_var(val).get(1) ? 0 : 1;
}
