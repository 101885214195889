import $api from '../../http';
import {
  DASHBOARD_ITEM_TYPE_CHART_TEMPLATE,
  DASHBOARD_ITEM_TYPE_CHART_WIDGET,
  DASHBOARD_ITEM_TYPE_DRAWING_TEMPLATES,
  DASHBOARD_ITEM_TYPE_TV_SETTINGS,
  DASHBOARD_ITEMS
} from '../../constants/paths';
import { VIEW_TYPES } from '../../constants/screener';
import numberConstant from '../../constants/numberConstants';
import { STATUS_200 } from '../../constants/responseStatuses';
import useCompression from '../../utils/hooks/useCompression';

const { compressHandler, decompressHandler, jsonHandler } = useCompression();

export default class SaveLoadAdapterHandlers {
  static symbolArrayHandler(symbol) {
    if (Array.isArray(symbol)) {
      return symbol[0];
    }
    return symbol;
  }

  static async getAllCharts() {
    const requestFirst = await $api.get(
      `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_CHART_WIDGET}&page=${
        numberConstant.zeroCount}&limit=${numberConstant.limitItems}`
    );

    if (requestFirst.status === STATUS_200) {
      const requests = requestFirst.data.items.map(async (item) => {
        const decompressedContent = await decompressHandler(item?.data);

        return {
          id: item.id,
          name: item.title,
          symbol: this.symbolArrayHandler(decompressedContent?.content?.symbol) || null,
          resolution: decompressedContent?.content?.resolution || null,
          timestamp: decompressedContent?.content?.timestamp || null,
          content: decompressedContent?.content || {}
        };
      });

      return Promise.all(requests);
    }

    return [];
  }

  static async saveChart(title, type, data) {
    const compressedData = await compressHandler(data);
    return $api.post(
      DASHBOARD_ITEMS,
      {
        title,
        type,
        viewType: VIEW_TYPES.TABLE,
        data: compressedData.compressed ? { s3Data: compressedData.s3Data } : data,
        isFavorite: false
      }
    ).then(async (response) => {
      const decompressedContent = await decompressHandler(response?.data?.data);
      return { data: decompressedContent, id: response?.data.id };
    });
  }

  static async updateChart(id, title, type, data) {
    const compressedData = await compressHandler(data);

    return $api.patch(
      `${DASHBOARD_ITEMS}/${id}`,
      {
        title,
        type,
        viewType: VIEW_TYPES.TABLE,
        data: compressedData.compressed ? { s3Data: compressedData.s3Data } : data,
        isFavorite: false,
        id
      }
    ).then(async (response) => {
      const decompressedContent = await decompressHandler(response?.data?.data);
      return { data: decompressedContent, id: decompressedContent?.content?.id };
    });
  }

  static async removeChart(id) {
    $api.delete(`${DASHBOARD_ITEMS}/${id}`).then((response) => response);
  }

  static async getAllSettingsTemplate() {
    const response = await $api.get(
      `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_TV_SETTINGS}&page=${
        numberConstant.zeroCount}&limit=${numberConstant.limitItems}`
    );

    if (response.status === STATUS_200) {
      const requests = response?.data?.items.map(async (item) => {
        const decompressedContent = await decompressHandler(item?.data);

        return {
          id: item?.id,
          name: decompressedContent?.name,
          content: decompressedContent?.content
        };
      });

      return Promise.all(requests);
    }

    return [];
  }

  static async getAllDrawingTemplate() {
    const response = await $api.get(
      `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_DRAWING_TEMPLATES}&page=${
        numberConstant.zeroCount}&limit=${numberConstant.limitItems}`
    );

    if (response.status === STATUS_200) {
      const requests = response?.data?.items.map(async (item) => {
        const decompressedContent = await decompressHandler(item?.data);

        return {
          id: item?.id,
          name: item?.title,
          content: decompressedContent?.content
        };
      });

      return Promise.all(requests);
    }

    return [];
  }

  static async getAllStudiesTemplate() {
    const response = await $api.get(
      `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_CHART_TEMPLATE}&page=${
        numberConstant.zeroCount}&limit=${numberConstant.limitItems}`
    );

    if (response.status === STATUS_200) {
      const requests = response?.data?.items.map(async (item) => {
        const decompressedContent = await decompressHandler(item?.data);

        return {
          id: item?.id,
          name: item?.title,
          content: jsonHandler(decompressedContent?.content)
        };
      });

      return Promise.all(requests);
    }

    return [];
  }
}
