import React, { useEffect, useRef } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styles from '../../pages/Table/sass/TableScreener.module.scss';
import RemoveSubItemModal from './RemoveSubItemModal';
import { MODAL_TITLES } from '../../constants/screener';
import useNewScreen from '../../utils/hooks/useNewScreen';
import { getFoldersItems } from '../../utils/helpers';
import RemoveModal from '../RemoveModal.js/RemoveModal';
import { findItemColumnSet } from '../../utils/helperNewScreenModal';
import { setVisibleColumnsScreenAction } from '../../store/screener/actions';
import CreateFolderModalHandler from './CreateFolderModalHandler';
import CreateScreenModalHandler from './CreateScreenModalHandler';
import DuplicateModalHandler from './DuplicateModalHandler';
import MoveToModalHandler from './MoveToModalHandler';
import DeleteModalHandler from './DeleteModalHandler';
import UpdateFolderModalHandler from './UpdateFolderModalHandler';
import useNewColumnSet from '../Columns/hooks/useNewColumnSet';
import CommonModal from '../WatchListModals/CommonModal';
import { setModalType } from '../../store/watchlist/actions';
import { setCreatedFolder } from '../../store/newScreen/actions';

const NewScreenModals = ({
  openModalHandler,
  openModal,
  currentScreensItem,
  currentScreensSubItem,
  currentItemHandler,
  singleItemStatus,
  currentItemSelected,
  selectedScreenId,
  hoveredPresetItem,
  activeLastScreen,
  setCurrentScreensSubItem,
  modalType,
  setHoveredPresetItem,
  setActiveModalWrapper,
  closeModalControlled,
  controledAction,
  isDashboard,
  controlledEditName,
  resetSearchHandler,
}) => {
  const dispatch = useDispatch();
  const currentRef = useRef(null);

  const closeModalHandler = () => {
    setActiveModalWrapper(false);
    openModalHandler('');
    currentItemHandler(-1);
    if (modalType !== 'addSymbol' && modalType !== 'deleteSymbols'
      && modalType !== 'signOut') dispatch(setModalType(''));
    dispatch(setCreatedFolder(null));
    closeModalControlled();
  };

  const {
    createFolder, removeScreen, duplicatePreset, moveToFolder, updateFolder, removeFolder, updateScreen,
    createScreen, createPresets, removeColumnSetFromFolder, removeColumnsSet
  } = useNewScreen(currentItemSelected, activeLastScreen, closeModalHandler, setHoveredPresetItem);
  const { createColumnsSet } = useNewColumnSet(closeModalHandler);

  const { removeSubItemFromFolder } = useNewScreen(currentItemSelected);
  const itemsNewScreen = useSelector((state) => state.newScreenState.itemsNewScreen, shallowEqual);
  const foldersPresets = useSelector((state) => state.newScreenState.presetFolders, shallowEqual);
  const groupFilters = useSelector((state) => state.newScreenState.requestStateFilters, shallowEqual);
  const presetsState = useSelector((state) => state.newScreenState.presets, shallowEqual);
  const currentItem = itemsNewScreen.find((item) => item?.id === currentScreensItem);
  const currentPresetItem = presetsState.find((item) => item?.id === hoveredPresetItem);
  const currentFolderPresetItem = foldersPresets.find((item) => item?.id === hoveredPresetItem);
  const currentSubItem = currentItem && currentItem?.items?.length
    ? currentItem.items[currentScreensSubItem] : '';
  const presetsNewScreen = getFoldersItems(presetsState, foldersPresets);
  const itemId = useSelector((state) => state.newScreenState.itemId, shallowEqual);

  const hoveredFolderColumnSetsId = useSelector(
    (state) => state.newScreenState.hoveredFolderColumnSetsId, shallowEqual
  );
  const hoveredColumnSetsId = useSelector(
    (state) => state.newScreenState.hoveredColumnSetsId, shallowEqual
  );

  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns, shallowEqual);
  const columnSetFolder = useSelector((state) => state.newScreenState.columnSetFolder, shallowEqual);
  const itemsColumnSets = useSelector((state) => state.newScreenState.itemsColumnSets, shallowEqual);
  const currentFolderSetsItem = itemsColumnSets.find((item) => item?.id === hoveredFolderColumnSetsId);
  const currentColumnSetItem = findItemColumnSet(itemsColumnSets, hoveredColumnSetsId);

  useEffect(() => {
    dispatch(setVisibleColumnsScreenAction(false));
  }, []);

  useEffect(() => {
    if (currentRef.current) {
      setActiveModalWrapper(true);
    } else {
      setActiveModalWrapper(false);
    }
  }, [currentRef]);

  return (
    <div
      aria-hidden="true"
      className={styles.modalWrapper}
      ref={currentRef}
    >
      <ClickAwayListener
        onClickAway={closeModalHandler}
        touchEvent="onTouchEnd"
      >
        <div className={styles.createScreenWrapper}>
          <CreateScreenModalHandler
            openModal={`${openModal}`}
            closeModalHandler={closeModalHandler}
            selectedScreenId={selectedScreenId}
            itemsNewScreen={itemsNewScreen}
            foldersPresets={foldersPresets}
            groupFilters={groupFilters}
            selectedColumns={selectedColumns}
            columnSetFolder={columnSetFolder}
            moveToFolder={moveToFolder}
            createScreen={createScreen}
            createPresets={createPresets}
            createColumnsSet={createColumnsSet}
            resetSearchHandler={resetSearchHandler}
            currentItemSelected={currentItemSelected}
          />

          <CreateFolderModalHandler
            openModal={openModal}
            selectedScreenId={selectedScreenId}
            currentItemSelected={currentItemSelected}
            activeLastScreen={activeLastScreen}
            setCurrentScreensSubItem={setCurrentScreensSubItem}
            closeModalHandler={closeModalHandler}
            createFolder={createFolder}
          />

          <DuplicateModalHandler
            openModal={openModal}
            closeModalHandler={closeModalHandler}
            duplicatePreset={duplicatePreset}
            currentPresetItem={currentSubItem || currentItem || currentPresetItem}
            itemsColumnSets={itemsColumnSets}
            currentColumnSetItem={currentColumnSetItem}
          />

          <MoveToModalHandler
            openModal={openModal}
            itemsNewScreen={itemsNewScreen}
            presetsNewScreen={presetsNewScreen}
            itemsColumnSets={itemsColumnSets}
            moveToFolder={moveToFolder}
            currentItem={currentItem}
            currentPresetItem={currentPresetItem}
            currentSubItem={currentSubItem}
            currentColumnSetItem={currentColumnSetItem}
            closeModalHandler={closeModalHandler}
          />

          <DeleteModalHandler
            openModal={openModal}
            removeFolder={removeFolder}
            removeScreen={removeScreen}
            currentSubItem={currentSubItem}
            closeModalHandler={closeModalHandler}
            singleItemStatus={singleItemStatus}
            currentPresetItem={currentPresetItem}
            currentFolderSetsItem={currentFolderSetsItem}
            currentColumnSetItem={currentColumnSetItem}
            currentFolderPresetItem={currentFolderPresetItem}
            currentItem={currentItem || currentPresetItem || currentFolderPresetItem}
            removeColumnsSet={removeColumnsSet}
          />

          <UpdateFolderModalHandler
            openModal={openModal}
            updateScreen={updateScreen}
            updateFolder={updateFolder}
            currentSubItem={currentSubItem}
            closeModalHandler={closeModalHandler}
            singleItemStatus={singleItemStatus}
            currentPresetItem={currentPresetItem}
            currentFolderSetsItem={currentFolderSetsItem}
            currentColumnSetItem={currentColumnSetItem}
            currentFolderPresetItem={currentFolderPresetItem}
            itemId={itemId}
            currentItem={currentItem || currentPresetItem || currentFolderPresetItem || itemId}
          />
          {(modalType === MODAL_TITLES.CREATE || MODAL_TITLES.CREATE_DATA_PANEL
              || MODAL_TITLES.CREATE_DATA_PANEL_PRESET)
            && !isDashboard && (
              <CreateScreenModalHandler
                openModal={`${modalType}`}
                closeModalHandler={closeModalHandler}
                selectedScreenId={selectedScreenId}
                itemsNewScreen={itemsNewScreen}
                foldersPresets={foldersPresets}
                groupFilters={groupFilters}
                selectedColumns={selectedColumns}
                columnSetFolder={columnSetFolder}
                moveToFolder={moveToFolder}
                createScreen={createScreen}
                createPresets={createPresets}
                createColumnsSet={createColumnsSet}
                resetSearchHandler={resetSearchHandler}
                currentItemSelected={currentItemSelected}
              />
          )}
          {(modalType === MODAL_TITLES.CREATE_DASHBOARD_PRESET
              || modalType === MODAL_TITLES.CREATE_DASHBOARD) && (
              <CreateScreenModalHandler
                openModal={`${modalType}`}
                closeModalHandler={closeModalControlled}
                selectedScreenId={selectedScreenId}
                itemsNewScreen={itemsNewScreen}
                foldersPresets={foldersPresets}
                groupFilters={groupFilters}
                selectedColumns={selectedColumns}
                columnSetFolder={columnSetFolder}
                moveToFolder={moveToFolder}
                createScreen={createScreen}
                createPresets={createPresets}
                createColumnsSet={createColumnsSet}
                resetSearchHandler={resetSearchHandler}
                currentItemSelected={currentItemSelected}
              />
          )}

          {(modalType === MODAL_TITLES.RENAME || modalType === MODAL_TITLES.DELETE) && isDashboard && (
          <CommonModal
            controlledEditName={controlledEditName}
            editDasboard={controledAction}
            closeDashboardModal={closeModalControlled}
            type={modalType}
            isDashboard={isDashboard}
          />
          )}

          {openModal === MODAL_TITLES.REMOVE_COLUMN && (
          <RemoveModal
            setOpenModal={closeModalHandler}
            itemsColumnSets={itemsColumnSets}
            currentItem={currentColumnSetItem}
            handlerRemoveModal={removeColumnSetFromFolder}
          />
          )}
          {openModal === MODAL_TITLES.REMOVE && (
          <RemoveSubItemModal
            removeSubItemFromFolder={removeSubItemFromFolder}
            removeScreen={removeScreen}
            currentSubItem={currentSubItem}
            setOpenModal={closeModalHandler}
            singleItemStatus={singleItemStatus}
            currentPresetItem={currentPresetItem}
            currentItem={currentItem || currentPresetItem || currentFolderPresetItem}
          />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

NewScreenModals.propTypes = {
  activeLastScreen: PropTypes.func,
  singleItemStatus: PropTypes.bool,
  currentItemHandler: PropTypes.func,
  selectedScreenId: PropTypes.number,
  hoveredPresetItem: PropTypes.number,
  currentScreensItem: PropTypes.number,
  currentItemSelected: PropTypes.func,
  openModal: PropTypes.string.isRequired,
  modalType: PropTypes.string.isRequired,
  currentScreensSubItem: PropTypes.number,
  setCurrentScreensSubItem: PropTypes.func,
  setHoveredPresetItem: PropTypes.func,
  openModalHandler: PropTypes.func.isRequired,
  setActiveModalWrapper: PropTypes.func,
  isDashboard: PropTypes.bool,
  closeModalControlled: PropTypes.func,
  controledAction: PropTypes.func,
  controlledEditName: PropTypes.string,
  resetSearchHandler: PropTypes.func,
};

NewScreenModals.defaultProps = {
  selectedScreenId: -1,
  hoveredPresetItem: -1,
  currentScreensItem: -1,
  singleItemStatus: false,
  currentScreensSubItem: -1,
  activeLastScreen: () => undefined,
  currentItemHandler: () => undefined,
  currentItemSelected: () => undefined,
  setHoveredPresetItem: () => undefined,
  setCurrentScreensSubItem: () => undefined,
  setActiveModalWrapper: () => undefined,
  isDashboard: false,
  closeModalControlled: () => undefined,
  controledAction: () => undefined,
  controlledEditName: '',
  resetSearchHandler: () => undefined,
};

export default React.memo(NewScreenModals);
