import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../sass/AlertsList.module.scss';
import Toast from './Toast';
import scrollStopEvent from '../../../utils/scrollStopEvent';
import { checkedNotificationHandler } from '../../ChartContainer/utils/alertHelper';
import { toggleAlertNotificationListDropdownVisible } from '../../../store/alerts/slice';
import { setSelectedTab, setActiveAlert } from '../../../store/watchlist/actions';
import { setRoute } from '../../../store/screener/actions';
import { TABLE_SCREEN_PATH } from '../../../constants/screener';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { ALERTS_SYMBOL, SELECTED_TAB_ID } from '../../../constants/storage';
import ROUTES_URLS from '../../../constants/routesUrls';
import DefaultLink from '../../DefaultLink/DefaultLink';

const AlertsListItems = ({
  alertNotifications, toggleAlertStatus,
  checkActive, updateViewedAlerts
}) => {
  const itemsRef = useRef([]);
  const dispatch = useDispatch();

  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const { updateStoreHandler } = useLastStateUpdateStore();

  const openAlertPage = (alertId, symbolName) => {
    const currentSymbol = symbolsList?.filter((sym) => sym?.sym === symbolName)[0];
    dispatch(setActiveAlert(alertId));
    updateStoreHandler(ALERTS_SYMBOL, { name: currentSymbol?.sym, index: currentSymbol?.index, position: 0 });
    updateStoreHandler(SELECTED_TAB_ID, alertTab?.id);
    dispatch(setSelectedTab(alertTab));
    dispatch(toggleAlertNotificationListDropdownVisible());
    dispatch(setRoute(TABLE_SCREEN_PATH.DEEPLIST));
  };

  const setItemsRef = useCallback((element, index) => {
    itemsRef.current[index] = element;
  }, []);

  useEffect(() => {
    const item = document.querySelector('#alertsNotificationList');
    checkedNotificationHandler(itemsRef, updateViewedAlerts);

    if (item) {
      scrollStopEvent(() => checkedNotificationHandler(
        itemsRef, updateViewedAlerts
      ), item, dispatch);
    }
  }, []);

  return (
    <ul className={styles.notifications} id="alertsNotificationList">
      {alertNotifications.map((item, index) => (
        <DefaultLink
          href={ROUTES_URLS.TABLE}
          className={styles.notificationLink}
        >
          <Toast
            checkActive={checkActive}
            toggleAlertStatus={toggleAlertStatus}
            clickHandler={openAlertPage}
            setItemsRef={setItemsRef}
            item={item}
            index={index}
            key={[item.id, index].join('_')}
          />
        </DefaultLink>
      ))}
    </ul>
  );
};

AlertsListItems.propTypes = {
  alertNotifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  })).isRequired,
  toggleAlertStatus: PropTypes.func.isRequired,
  checkActive: PropTypes.func.isRequired,
  updateViewedAlerts: PropTypes.func.isRequired,
};

export default AlertsListItems;
