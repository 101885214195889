import { useRouteMatch } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { PATH_TYPE, TABLE_SCREEN_PATH, TEMPLATE_TYPE } from '../../../constants/screener';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { LAST_SCREENER_COLUMNS_SET } from '../../../constants/tvWidgetOptions';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { getAllColumns } from '../../../utils/helperColumnsWorker';

const useSaveColumns = () => {
  const route = useRouteMatch();

  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const stateColumnsSetName = useSelector((state) => state.newScreenState.stateColumnsSetName, shallowEqual);
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const presetsWatchlist = useSelector((state) => state.watchlistState.presets, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedTabID = useSelector((state) => state.watchlistState.selectedTabID, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);
  const itemsColumnSets = useSelector((state) => state.newScreenState.itemsColumnSets, shallowEqual);
  const itemsColumnSetsPreset = useSelector((state) => state.newScreenState.itemsColumnSetsPreset, shallowEqual);
  const userLastState = useSelector((state) => state.accountState.userSettings, shallowEqual);

  const { saveScreenIdToColumnSet, updateScreen } = useNewScreen();
  const { updateStoreHandler, updateColumnSetsPresetsScreenerIdsData } = useLastStateUpdateStore();

  const cleanScreenIdsForColumnsPresets = (targetObj, targetValue) => {
    const entries = Object.entries(targetObj);

    const index = entries.findIndex(([, values]) => values.includes(targetValue));

    if (index === -1) {
      return null;
    }
    const [key, values] = entries[index];
    const filteredValues = values.filter((value) => value !== targetValue);

    return updateColumnSetsPresetsScreenerIdsData(parseInt(key), filteredValues);
  };

  const fillNewColumnSetScreenList = (
    currentColumnSet, currentDashboardItem, currentPlace, isColumnSetPreset = false
  ) => {
    if (isColumnSetPreset) {
      // clean other column sets from current screen id
      cleanScreenIdsForColumnsPresets(userLastState?.presetsColumnSetsScreenerIds, currentDashboardItem.id);

      const data = userLastState?.presetsColumnSetsScreenerIds
      && userLastState.presetsColumnSetsScreenerIds[currentColumnSet?.id]
        ? [...userLastState.presetsColumnSetsScreenerIds[currentColumnSet?.id]] : [];

      if (!data.includes(currentDashboardItem.id)) data.push(currentDashboardItem.id);
      const currentDashboardItemData = {
        ...currentDashboardItem.data,
        columnsId: currentColumnSet?.id
      };
      // update last state for column presets screener ids
      updateColumnSetsPresetsScreenerIdsData(currentColumnSet?.id, data);
      // update dashboard item column set id
      updateScreen(currentDashboardItem.id, currentDashboardItem?.title,
        currentDashboardItemData, currentDashboardItemData?.description);
    } else {
      const data = { ...currentColumnSet?.data };
      if (!data?.screenIdList) {
        data.screenIdList = [];
      }

      data?.screenIdList.push(currentDashboardItem.id);

      // clean other column sets from current screen id
      cleanScreenIdsForColumnsPresets(userLastState.presetsColumnSetsScreenerIds, currentDashboardItem.id);
      saveScreenIdToColumnSet(data, currentColumnSet?.id, currentPlace, currentDashboardItem.id);
    }
  };

  const saveColumnsToSpecificPlace = (id, data = undefined, widgetType) => {
    const flatArrayColumns = getAllColumns([...itemsColumnSets, ...itemsColumnSetsPreset]);
    const currentColumnSet = data || flatArrayColumns.find((item) => item.id === id);
    if (
      route?.path === PATH_TYPE.DASHBOARD
      && selectedTab
      && Object.keys(selectedTab)?.length
      && widgetType === 'deepList'
    ) {
      const itemsAndPresetsWatchlist = [...tabsList, ...presetsWatchlist];
      const currentTab = itemsAndPresetsWatchlist.find((tab) => tab.id === selectedTabID);

      return fillNewColumnSetScreenList(currentColumnSet, currentTab, currentTab.type,
        currentColumnSet.type === TEMPLATE_TYPE.COLUMN_SET_PRESET);
    }
    if (
      route?.path === PATH_TYPE.DASHBOARD
      && !Object.keys(selectedScreen)?.length
      && widgetType === 'screener'
    ) {
      return updateStoreHandler(LAST_SCREENER_COLUMNS_SET, id);
    }
    if (
      route?.path === PATH_TYPE.DASHBOARD
      && selectedScreen
      && Object.keys(selectedScreen)?.length
      && widgetType === 'screener'
    ) {
      return fillNewColumnSetScreenList(currentColumnSet, selectedScreen, selectedScreen.type,
        currentColumnSet.type === TEMPLATE_TYPE.COLUMN_SET_PRESET);
    }
    if (
      pagePath === TABLE_SCREEN_PATH.SCREENER
      && !Object.keys(selectedScreen)?.length
      && (widgetType === TABLE_SCREEN_PATH.SCREENER || !widgetType)
    ) {
      updateStoreHandler(LAST_SCREENER_COLUMNS_SET, id);
    }
    if (
      pagePath === TABLE_SCREEN_PATH.SCREENER
      && selectedScreen
      && Object.keys(selectedScreen)?.length
    ) {
      fillNewColumnSetScreenList(currentColumnSet, selectedScreen, selectedScreen.type,
        currentColumnSet.type === TEMPLATE_TYPE.COLUMN_SET_PRESET);
    }
    if (
      pagePath !== TABLE_SCREEN_PATH.SCREENER
      && selectedTab
      && Object.keys(selectedTab)?.length
    ) {
      fillNewColumnSetScreenList(currentColumnSet, selectedTab, selectedTab.type,
        currentColumnSet.type === TEMPLATE_TYPE.COLUMN_SET_PRESET);
    }
    return null;
  };

  return {
    stateColumnsSetName, saveColumnsToSpecificPlace
  };
};

export default useSaveColumns;
