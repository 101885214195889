import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../Checkbox/Checkbox';
import Button from '../Button/Button';
import { ReactComponent as IconShow } from '../../assets/images/icons/screener/visionButton.svg';
import styles from './sass/ScreenerTable.module.scss';

const SymbolHeaderColumn = ({
  setAllItems,
  checked,
  index,
  toggleIconHandler,
  hideButton,
  hideCheckbox,
}) => (
  <>
    {!hideCheckbox && (
    <Checkbox
      id={`${index}`}
      checked={checked}
      changeHandler={setAllItems}
      additionalClass="checkboxRemoveState"
    />
    )}
    {!hideButton && (
    <Button
      handleClick={toggleIconHandler}
      buttonClass={styles.hideIcons}
    >
      <IconShow />
    </Button>
    ) }
  </>
);

SymbolHeaderColumn.propTypes = {
  setAllItems: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  toggleIconHandler: PropTypes.func.isRequired,
  hideButton: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
};

SymbolHeaderColumn.defaultProps = {
  hideButton: false,  
  hideCheckbox: false,
};

export default SymbolHeaderColumn;
