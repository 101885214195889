import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/DataPanel.module.scss';
import DotMenu from './DotMenu';
import TextWithTooltip from './TextWithTooltip';

const CategoryItem = ({
  category, onCategorySelect,
  onRename, onDelete, selected,
}) => {
  const handleCategorySelect = () => {
    onCategorySelect(category);
  };

  const handleRename = () => {
    onRename(category);
  };

  const handleDelete = () => {
    onDelete(category);
  };

  return (
    <div
      aria-hidden
      onClick={handleCategorySelect}
      className={`${styles.dataCategoryDropdownListItem} ${selected === category && styles.checked}`}
    >
      <TextWithTooltip title={category} />
      <DotMenu
        isSelected={selected === category}
        onRename={handleRename}
        onDelete={handleDelete}
      />
    </div>
  );
};
CategoryItem.propTypes = {
  category: PropTypes.string,
  onCategorySelect: PropTypes.func,
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
};

CategoryItem.defaultProps = {
  category: '',
  onCategorySelect: () => undefined,
};

export default CategoryItem;
