import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/ScreenerTable.module.scss';
import { ONE_HUNDRED } from '../../../constants/screener';

const VolumeDiagram = ({
  volumesHistory, currentVolume, priceChangesHistory, currentPriceChange
}) => {
  const maxVolume = Math.max(...volumesHistory, currentVolume);
  return (
    useMemo(() => (
      <div className={styles.volumeDiagram}>
        {
          volumesHistory.map((volume, index) => (
            <span
              key={[volume, index].join('_')}
              style={{ height: `${(volume / maxVolume) * ONE_HUNDRED}%` }}
              className={priceChangesHistory[index] >= 0 ? styles.grow : styles.loss}
            />
          ))
        }
        <span
          style={{ height: `${(currentVolume / maxVolume) * ONE_HUNDRED}%` }}
          className={currentPriceChange >= 0 ? styles.grow : styles.loss}
        />
      </div>
    ), [currentVolume, currentPriceChange])
  );
};

VolumeDiagram.propTypes = {
  volumesHistory: PropTypes.arrayOf(PropTypes.number).isRequired,
  priceChangesHistory: PropTypes.arrayOf(PropTypes.number).isRequired,
  currentVolume: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  currentPriceChange: PropTypes.number,
};

VolumeDiagram.defaultProps = {
  currentPriceChange: 0,
  currentVolume: null,
};

export default VolumeDiagram;
