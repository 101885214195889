import React, { useEffect, useRef } from 'react';

import styles from './sass/SelectGroupFilter.module.scss';
import { ReactComponent as SelectArrow } from '../../assets/images/icons/selectArrow.svg';
import TextField from '../TextField/TextField';
import { findIndexItem } from '../../utils/helpers';
import constants from '../../constants/filters';
import DropList from './DropList';
import { TCommonItem } from '../FilterScreen/Types';
import { TMouseEvent } from '../../utils/handleOutsideClick';

interface ISelectGroupFilter {
  data: TCommonItem[];
  handlerSelectFilter: (filterItem: TCommonItem) => void;
  viewDropListSingle: boolean;
  setViewDropListSingle: (value: boolean) => void;
  selectFilter: TCommonItem;
  bottomFlag: boolean;
}

const SelectGroupFilter = ({
  data, handlerSelectFilter, viewDropListSingle, setViewDropListSingle, selectFilter,
  bottomFlag
}: ISelectGroupFilter): React.ReactElement => {
  const dropListRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: TMouseEvent) => {
    if (dropListRef.current && !dropListRef.current?.contains(event.target as HTMLDivElement)) {
      setViewDropListSingle(false);
    }
  };

  const handlerChange = (nameItem: string): void => {
    const indexItem = findIndexItem(data, constants.name, nameItem);
    handlerSelectFilter(data[indexItem]);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside as EventListener);
    return () => {
      document.removeEventListener('click', handleClickOutside as EventListener);
    };
  }, []);

  return (
    <div
      className={`${styles.container} ${viewDropListSingle ? styles.openDrop : ''}`}
      onClick={() => setViewDropListSingle(!viewDropListSingle)}
      onKeyDown={() => setViewDropListSingle(!viewDropListSingle)}
      role="button"
      aria-hidden="true"
      tabIndex={0}
      ref={dropListRef}
    >
      <TextField styleType="selectedFilters" text={selectFilter.name || ''} />
      <SelectArrow />
      {viewDropListSingle && (
        <DropList
          data={data}
          bottomFlag={bottomFlag}
          handlerChange={handlerChange}
          selectedItem={selectFilter}
        />
      )}
    </div>
  );
};

export default SelectGroupFilter;
