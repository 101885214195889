import { useState } from 'react';

import { IUseDashboardsDialogs } from '../types/interfaces';
import { DashboardItemsDialogActionTypes, DialogItemDataTypes } from '../types/constants';
import { TDashboardItemsData } from '../../../utils/Types';
import { TDialogData } from '../types/types';
import { DasboardsItemsDropdownTypes, DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';

const UseDashboardsDialogs: IUseDashboardsDialogs = () => {
  const [open, setOpen] = useState(false);
  const [dialogData, setDialogData] = useState<TDialogData>({
    type: null,
    item: null,
    itemType: null,
    entityType: null,
    selectedTab: DasboardsItemsDropdownTypes.SAVED
  });

  const actionOpenDialog = (
    actionType: DashboardItemsDialogActionTypes,
    itemType: DialogItemDataTypes,
    entityType: DialogItemEntityType,
    dropdownType: DasboardsItemsDropdownTypes,
    item?: TDashboardItemsData
  ) => {
    setDialogData({
      type: actionType,
      itemType,
      entityType,
      selectedTab: dropdownType,
      item: item || null,
    });
    setOpen(true);
  };

  const actionCallback = () => {
    setOpen(false);
  };

  const actionCancelDialog = () => {
    setOpen(false);
    setDialogData({
      type: null,
      item: null,
      itemType: null,
      entityType: null,
      selectedTab: null
    });
  };

  return {
    open,
    actionOpenDialog,
    dialogData,
    actionCancelDialog,
    actionCallback
  };
};

export default UseDashboardsDialogs;
