import React from 'react';

import styles from './sass/NoOptions.module.scss';
import TextField from '../TextField/TextField';

interface INoOptions {
  text: string;
  styleType?: string;
}

const NoOptions = ({ text, styleType }: INoOptions): React.ReactElement => (
  <div className={`${styles.container} ${styleType ? styles[styleType] : ''}`}>
    <TextField styleType="filtersNoOptions" text={text} />
  </div>
);

export default NoOptions;
