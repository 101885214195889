import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { MODAL_TITLES } from '../../constants/screener';
import DeleteModal from './DeleteModal';

const DeleteModalHandler = ({
  openModal,
  removeFolder,
  removeScreen,
  currentSubItem,
  closeModalHandler,
  singleItemStatus,
  currentPresetItem,
  currentFolderSetsItem,
  currentColumnSetItem,
  currentItem,
  removeColumnsSet,
  currentFolderPresetItem
}) => (
  <>
    {openModal === MODAL_TITLES.DELETE && (
      <DeleteModal
        removeFolder={removeFolder}
        removeScreen={removeScreen}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        singleItemStatus={singleItemStatus}
        currentPresetItem={currentPresetItem}
        currentItem={currentItem || currentPresetItem || currentFolderPresetItem}
      />
    )}
    {openModal === MODAL_TITLES.DELETE_COLUMNS_FOLDER && (
      <DeleteModal
        removeFolder={removeFolder}
        removeScreen={removeScreen}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        singleItemStatus={singleItemStatus}
        currentItem={currentFolderSetsItem}
        currentPresetItem={currentPresetItem}
      />
    )}
    {openModal === MODAL_TITLES.DELETE_COLUMN && (
      <DeleteModal
        singleItemStatus={singleItemStatus}
        removeFolder={removeFolder}
        removeScreen={removeColumnsSet}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        currentItem={currentColumnSetItem}
        currentPresetItem={currentPresetItem}
      />
    )}
  </>
);

DeleteModalHandler.propTypes = {
  singleItemStatus: PropTypes.bool,
  removeColumnsSet: PropTypes.func,
  currentColumnSetItem: PropTypes.shape({}),
  currentFolderSetsItem: PropTypes.shape({}),
  currentFolderPresetItem: PropTypes.shape({}),
  openModal: PropTypes.string.isRequired,
  closeModalHandler: PropTypes.func,
  removeFolder: PropTypes.func,
  removeScreen: PropTypes.func,
  currentSubItem: oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  currentItem: PropTypes.shape({}),
  currentPresetItem: PropTypes.shape({})
};

DeleteModalHandler.defaultProps = {
  singleItemStatus: false,
  currentColumnSetItem: {},
  removeColumnsSet: () => undefined,
  currentFolderSetsItem: {},
  currentFolderPresetItem: {},
  closeModalHandler: () => undefined,
  removeFolder: () => undefined,
  removeScreen: () => undefined,
  currentItem: {},
  currentPresetItem: {},
  currentSubItem: {} || PropTypes.string,

};

export default React.memo(DeleteModalHandler);
