import React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import FilterCheckbox from './FilterCheckbox';
import DatePicker from '../../DatePicker/DatePicker';
import { getTypeFilter, getItemFilter } from '../../../utils/helperFilter';
import filtersConstant from '../../../constants/filters';
import FilterGroupEmaSma from '../../FilterGroupEmaSma/FilterGroupEmaSma';
import FilterRange from '../../FilterRange/FilterRange';
import FilterMultiInclude from '../../FilterMultiInclude/FilterMultiInclude';
import RootStateTypes from '../../../store/RootStateTypes';
import { TFilterItem, TDefineColumn } from '../Types';

interface IFilterItemsList {
  addFavorite: () => void;
  favoriteData: number[];
  stateFiltersHandler: (filter: (number | number[])[]) => void;
  typeList: string;
  disabled: boolean;
  disabledHeart: boolean;
  columnsList: TDefineColumn[];
  searchFilter: number | string;
  adminUser: boolean;
  categoryName: string;
  categoryItems: TFilterItem[];
}

const FilterItemsList = ({
  addFavorite, favoriteData, stateFiltersHandler, typeList, disabled,
  disabledHeart, columnsList, searchFilter, adminUser, categoryName, categoryItems
}: IFilterItemsList): React.ReactElement => {
  const { t } = useTranslation();
  const stateFilters = useSelector((state: RootStateTypes) => state.filtersState.stateFilters, shallowEqual);
  return (
    <>
      {(categoryItems?.length > 0) && categoryItems?.map((filter, indexItem) => {
        let itemsRender;
        const check = favoriteData.findIndex((el) => el === filter.id) !== -1;
        const bottomFlag = indexItem === categoryItems?.length - 1;
        const itemFilter = getItemFilter(stateFilters, filter?.id, filter?.name) as TFilterItem;
        const checkShowFilter = (filter?.fShow && Object.keys(itemFilter).length > 0)
          || (adminUser && Object.keys(itemFilter).length > 0);
        switch (filter?.fType?.split(':')[0]) {
          case 'dropdown':
            itemsRender = checkShowFilter ? (
              <FilterGroupEmaSma
                searchFilter={searchFilter as number}
                bottomFlag={bottomFlag}
                key={[filter?.name, indexItem].join('_')}
                addFavorite={addFavorite}
                disabled={disabled}
                disabledHeart={disabledHeart}
                filter={filter}
                favoriteActive={false}
                noOptionsAvailable={t('noOptionsAvailable')}
                title={filter?.name}
                setArray={stateFiltersHandler}
                viewRange
                type={filtersConstant.range}
                getTypeFilter={getTypeFilter}
                columnsList={columnsList}
                filterData={itemFilter?.data}
                category={categoryName}
              />
            ) : null;
            break;
          case 'button':
            itemsRender = checkShowFilter ? (
              <FilterGroupEmaSma
                searchFilter={searchFilter as number}
                bottomFlag={bottomFlag}
                key={[filter.name, indexItem].join('_')}
                addFavorite={addFavorite}
                disabled={disabled}
                disabledHeart={disabledHeart}
                filter={filter}
                favoriteActive={false}
                noOptionsAvailable={t('noOptionsAvailable')}
                title={filter?.name}
                setArray={stateFiltersHandler}
                type={filtersConstant.range}
                getTypeFilter={getTypeFilter}
                columnsList={columnsList}
                filterData={itemFilter?.data}
                category={categoryName}
                activeCheckbox
                viewRange
              />
            ) : null;
            break;
          case 'ddt1':
            itemsRender = checkShowFilter ? (
              <FilterGroupEmaSma
                searchFilter={searchFilter as number}
                bottomFlag={bottomFlag}
                key={[filter.name, indexItem].join('_')}
                addFavorite={addFavorite}
                disabled={disabled}
                disabledHeart={disabledHeart}
                filter={filter}
                favoriteActive={false}
                noOptionsAvailable={t('noOptionsAvailable')}
                title={filter?.name}
                setArray={stateFiltersHandler}
                viewRange
                type={filtersConstant.range}
                getTypeFilter={getTypeFilter}
                columnsList={columnsList}
                filterData={itemFilter?.data}
                category={categoryName}
                deepGroupDrop
              />
            ) : null;
            break;
          case 'checkbox':
            itemsRender = checkShowFilter ? (
              <FilterCheckbox
                title={filter.name}
                stateFiltersCheckbox={itemFilter}
                setCheckHandler={stateFiltersHandler}
                addFavorite={addFavorite}
                columnId={filter.id as number}
                key={[filter.name, indexItem].join('_')}
                favoriteActive={check}
                disabled={disabled}
                disabledHeart={disabledHeart}
                description={filter?.desc}
                noOptionsAvailable={t('noOptionsAvailable')}
                category={categoryName}
              />
            ) : null;
            break;
          case 'multiselectSearch':
            itemsRender = checkShowFilter ? (
              <FilterMultiInclude
                searchFilter={searchFilter as string}
                bottomFlag={bottomFlag}
                filterItems={filter?.fVal}
                columnId={filter?.id}
                title={filter?.name}
                type={filter?.fType as string}
                addFavorite={addFavorite}
                favoriteActive={check}
                disabled={disabled}
                tabLabel={t('include')}
                tabLabelSecond={t('exclude')}
                stateFiltersChecked={itemFilter}
                disabledHeart={disabledHeart}
                stateFiltersHandler={stateFiltersHandler}
                noOptionsAvailable={t('noOptionsAvailable')}
                description={filter?.desc}
                key={[filter.name, indexItem].join('_')}
                category={categoryName}
              />
            ) : null;
            break;
          case 'date':
            itemsRender = checkShowFilter && Object.keys(itemFilter).length > 0 ? (
              <DatePicker
                searchFilter={searchFilter}
                key={[filter?.name, indexItem].join('_')}
                title={filter?.name}
                favoriteActive={check}
                addFavorite={addFavorite}
                stateFiltersHandler={stateFiltersHandler}
                placeholder={t('selectDate')}
                placeholderPeriod={t('dateRange')}
                applyText={t('apply')}
                cancelText={t('cancel')}
                columnId={filter?.id as number}
                disabledHeart={disabledHeart}
                disabled={disabled}
                rangeButtonType={filter?.fType?.split(':')[1]}
                categoryName={categoryName}
                description={filter?.desc}
                filter={itemFilter}
                hideLayout={false}
              />
            ) : null;
            break;
          case 'range':
            itemsRender = checkShowFilter && Object.keys(itemFilter).length > 0 ? (
              <FilterRange
                viewRange
                category={categoryName}
                title={filter?.name}
                disabled={disabled}
                description={filter?.desc}
                searchFilter={searchFilter as string}
                columnId={filter?.id as number}
                disabledHeart={disabledHeart}
                setArray={stateFiltersHandler}
                filterItems={itemFilter?.fVal}
                initialMax={filter?.fVal[1]?.value as number}
                initialMin={filter?.fVal[0]?.value as number}
                key={[filter?.name, indexItem].join('_')}
                typeData={getTypeFilter(columnsList, filter.id as number) as string}
                minValue={(itemFilter?.fVal[0]?.value || filter.fVal[0].value) as number}
                maxValue={(itemFilter?.fVal[1]?.value || filter.fVal[1].value) as number}
                noOptionsAvailable={t('noOptionsAvailable')}
              />
            ) : null;
            break;
          default:
            break;
        }
        const favoriteItem = check ? itemsRender : null;
        return typeList === filtersConstant.favorite ? favoriteItem : itemsRender;
      })}
    </>
  );
};

export default FilterItemsList;
