import React from 'react';

const getPosition = (
  filter: React.MutableRefObject<HTMLDivElement>,
  isWidget: boolean
): {
  left: string;
  top: string;
} => {
  const buttonRect = filter.current.getBoundingClientRect();
  const { bottom, right, top } = buttonRect;
  if (isWidget) {
    const { innerHeight } = window;
    if (innerHeight - buttonRect.bottom <= 250) {
      return {
        left: `${right - 230}px`,
        top: `${bottom - 290}px`,
      };
    }
    return {
      left: `${right - 220}px`,
      top: `${top + 60}px`,
    };
  }
  return {
    left: `${right - 230}px`,
    top: `${bottom - 390}px`,
  };
};

export default getPosition;
