import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, FilledAreaType, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function webby52(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Webby % Off 52 Week',
    metainfo: {
      id: 'TL-webby52Week@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Webby % Off 52 Week',
      description: 'DV - Webby % Off 52 Week',
      is_price_study: false,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      bands: [
        {
          id: 'hline_0',
          isHidden: false,
          name: 'Zone 1 High'
        }, {
          id: 'hline_1',
          isHidden: false,
          name: 'Zone 1 Low'
        }, {
          id: 'hline_2',
          isHidden: false,
          name: 'Zone 2 High'
        }, {
          id: 'hline_3',
          isHidden: false,
          name: 'Zone 2 Low'
        }, {
          id: 'hline_4',
          isHidden: false,
          name: 'Zone 3 High'
        }, {
          id: 'hline_5',
          isHidden: false,
          name: 'Zone 3 Low'
        }
      ],
      filledAreas: [
        {
          fillgaps: false,
          id: 'fill_0',
          isHidden: false,
          objAId: 'hline_0',
          objBId: 'hline_1',
          palette: 'palette_1',
          title: 'Hlines Background',
          type: 'hline_hline' as FilledAreaType.TypeHlines
        },
        {
          fillgaps: false,
          id: 'fill_1',
          isHidden: false,
          objAId: 'hline_2',
          objBId: 'hline_3',
          palette: 'palette_2',
          title: 'Hlines Background',
          type: 'hline_hline' as FilledAreaType.TypeHlines
        },
        {
          fillgaps: false,
          id: 'fill_2',
          isHidden: false,
          objAId: 'hline_4',
          objBId: 'hline_5',
          palette: 'palette_3',
          title: 'Hlines Background',
          type: 'hline_hline' as FilledAreaType.TypeHlines
        }
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: '% Off High'
        }
      },
      inputs: [
        {
          defval: true,
          id: 'off',
          name: 'Cut off at 25% from Highs',
          type: 'bool' as StudyInputType.Bool
        }
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line
        }, {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        }, {
          id: 'plot_2',
          palette: 'palette_1',
          target: 'fill_0',
          type: 'colorer' as StudyPlotType.Colorer
        }, {
          id: 'plot_3',
          palette: 'palette_2',
          target: 'fill_1',
          type: 'colorer' as StudyPlotType.Colorer
        }, {
          id: 'plot_4',
          palette: 'palette_3',
          target: 'fill_2',
          type: 'colorer' as StudyPlotType.Colorer
        }
      ],
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Plot'
            }
          },
          valToIndex: {
            0: 0
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Zone 1'
            }
          },
          valToIndex: {
            1: 0
          }
        },
        palette_2: {
          colors: {
            0: {
              name: 'Zone 2'
            }
          },
          valToIndex: {
            2: 0
          }
        },
        palette_3: {
          colors: {
            0: {
              name: 'Zone 3'
            }
          },
          valToIndex: {
            3: 0
          }
        }
      },
      defaults: {
        bands: [
          {
            color: '#000000',
            linestyle: 2,
            linewidth: 1,
            value: 0,
            visible: true
          }, {
            color: '#000000',
            linestyle: 2,
            linewidth: 1,
            value: -8,
            visible: true
          }, {
            color: '#000000',
            linestyle: 2,
            linewidth: 1,
            value: -8,
            visible: true
          }, {
            color: '#000000',
            linestyle: 2,
            linewidth: 1,
            value: -15,
            visible: true
          }, {
            color: '#000000',
            linestyle: 2,
            linewidth: 1,
            value: -15,
            visible: true
          }, {
            color: '#000000',
            linestyle: 2,
            linewidth: 1,
            value: -25,
            visible: true
          }
        ],
        filledAreasStyle: {
          fill_0: {
            color: '#2962ff',
            transparency: 0,
            visible: true
          },
          fill_1: {
            color: '#2962ff',
            transparency: 0,
            visible: true
          },
          fill_2: {
            color: '#2962ff',
            transparency: 0,
            visible: true
          }
        },
        inputs: {
          off: true,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#2962ff',
                style: 0,
                width: 1
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#4CAF50',
                style: 0,
                width: 1
              }
            }
          },
          palette_2: {
            colors: {
              0: {
                color: '#FFEB3B',
                style: 0,
                width: 1
              }
            }
          },
          palette_3: {
            colors: {
              0: {
                color: '#FF5252',
                style: 0,
                width: 1
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          }
        },
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs) => {
        this._context = context;

        const [off] = getInputArray({
          inputs,
          length: 1
        });

        const yearhigh = mPineJS.Std.highest(this._context.new_unlimited_var(mPineJS.Std.high(this._context)), 251, this._context)
        let distAway = ((mPineJS.Std.close(this._context) - yearhigh) / yearhigh) * 100

        if (distAway < -25 && off) {
          distAway = -25
        }

        return [
          distAway,
          0,
          1,
          2,
          3
        ];
      };
    }
  };
}