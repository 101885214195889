import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from '../../sass/PickColumnWrapper.module.scss';
import { ReactComponent as DndControl } from '../../../../assets/images/icons/screener/dnd_frame.svg';
import TextField from '../../../TextField/TextField';
import { ReactComponent as CloseColorGray } from '../../../../assets/images/icons/screener/close_square_black.svg';
import {
  columnsWereUpdateScreenerAction,
  removeColumnItemScreenerAction,
  resetItemsDashboardScreenerAction, setSortData,
} from '../../../../store/screener/actions';
import SortIconsGroup from '../../../Sorting/SortIconsGroup/SortIconsGroup';
import { setNewColumnsCondition } from '../../../../utils/helperColumnsWorker';
import useNewScreen from '../../../../utils/hooks/useNewScreen';
import { DEFAULT_ALERT_GROUP } from '../../../../constants/alerts';
import useLastStateUpdateStore from '../../../../utils/hooks/useLastStateUpdateStore';
import { MIN_COLUMNS_CLEAR } from '../../../../constants/screener';
import { ALERTS_COLUMNS } from '../../../../constants/storage';
import { setCleanedItemsColumnsAction } from '../../../../store/tableData/slice';

const ColumnsSelectedItem = ({
  text, id, setNewSortData,
  singleItem, sortStatus,
  item, additionalClass, sortData,
  group, isAlertTab, saveSortHandler,
  isColumnSetPreset
}) => {
  const dispatch = useDispatch();
  const isShowSorting = !!sortStatus(id);
  const { updateColumnsSetState } = useNewScreen();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const currentPage = useSelector((state) => state.tableDataState.currentPage, shallowEqual);
  const columnSetId = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns, shallowEqual);
  const alertColumns = useSelector((state) => state.accountState.userSettings.alertColumns, shallowEqual);

  const handlerClickRemove = () => {
    if (isAlertTab && group === DEFAULT_ALERT_GROUP.groupId) {
      let currentAlertColumns = [...alertColumns];
      const currentStatus = currentAlertColumns.indexOf(id);
      if (currentStatus === 0 && currentAlertColumns?.length === 1) {
        currentAlertColumns = [];
      } else {
        currentAlertColumns.splice(currentStatus, 1);
      }
      updateStoreHandler(ALERTS_COLUMNS, currentAlertColumns);
    } else {
      const newSortData = sortData.filter((data) => data.indexElement !== id);
      const newColumnSet = [...selectedColumns];

      newColumnSet.splice(0, 2);
      dispatch(removeColumnItemScreenerAction({ data: id }));
      dispatch(columnsWereUpdateScreenerAction(true));

      if (selectedColumns.length === MIN_COLUMNS_CLEAR) {
        dispatch(setCleanedItemsColumnsAction());
      }

      if (currentPage[0] !== 0) {
        dispatch(resetItemsDashboardScreenerAction());
      }

      if (columnSetId !== -1) {
        const newState = setNewColumnsCondition(item, newColumnSet);
        updateColumnsSetState(columnSetId, newState, () => {
          if (isShowSorting) {
            dispatch(setSortData(newSortData));
            saveSortHandler(newSortData);
            setNewSortData(newSortData);
          }
        });
      }
    }
  };

  const checkIsRemovable = () => {
    if (isAlertTab) {
      return true;
    }
    if (isColumnSetPreset) {
      return false;
    }
    return !singleItem;
  };

  return (
    <div className={`${styles.dndItem} ${additionalClass ? styles.dndExtraClass : ''}`}>
      <div className={styles.dndItemContentLeft}>
        { !isColumnSetPreset && <DndControl className={styles.controlDndIcon} />}
        <TextField text={text} styleType="dndItemTitle">
          {isShowSorting ? (
            <>
              <SortIconsGroup
                index={id}
                sortStatus={sortStatus}
              />
              <div className={styles.sortPositionNumber}>{sortStatus(id)?.position}</div>
            </>
          ) : null}
        </TextField>
      </div>
      <div
        aria-hidden="true"
        onMouseDown={handlerClickRemove}
      >
        {checkIsRemovable() && <CloseColorGray className={styles.controlDndIconRemove} />}
      </div>
    </div>
  );
};
ColumnsSelectedItem.defaultProps = {
  id: 0,
  text: '',
  item: {},
  sortData: [{}],
  singleItem: false,
  saveSortHandler: () => {},
  setNewSortData: () => {},
  sortStatus: () => {},
  additionalClass: false,
  group: 0,
  isAlertTab: false,
  isColumnSetPreset: false,
};
ColumnsSelectedItem.propTypes = {
  id: PropTypes.number,
  text: PropTypes.string,
  singleItem: PropTypes.bool,
  sortStatus: PropTypes.func,
  setNewSortData: PropTypes.func,
  saveSortHandler: PropTypes.func,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
  item: PropTypes.shape({}),
  additionalClass: PropTypes.bool,
  group: PropTypes.number,
  isAlertTab: PropTypes.bool,
  isColumnSetPreset: PropTypes.bool,
};
export default ColumnsSelectedItem;
