import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';
import filtersConstants from '../constants/filters';
import {
  MODAL_TITLES,
  FIXED_COLUMNS,
  FIND_NAME,
  TABLE_COLUMNS_MIN_SIZES,
  symbolColumn,
  FILTER_SETTINGS,
  TWO_HUNDRED,
  COLUMNS_INDEX,
  DEFAULT_COLUMNS_INDEX,
  topIds, REVERSE_SORTING_COLUMNS,
} from '../constants/screener';
import numberConstants from '../constants/numberConstants';
import { DEFAULT_TIMEZONE } from '../constants/account';
import { DATE_CONSTANTS } from '../constants/dateConstants';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../constants/storage';
import { ALERT_COLUMN_INDEXES } from '../constants/alerts';

export const handleDebounce = (func, timeout = 300, ...additionalArgs) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, [...args, ...additionalArgs]); }, timeout);
  };
};

export const findIndexItem = (array, name, id) => array?.findIndex((el) => el?.[name] && typeof el[name] !== 'undefined'
  && el[name] === id);

export const scrollToItem = (id, behavior = 'auto') => {
  const el = id ? document.getElementById(id) : null;

  if (el) {
    el.scrollIntoView({
      block: 'nearest',
      behavior,
    });
  }
};

export const scrollToItemStatsChart = (id) => {
  const el = id ? document.getElementById(id) : null;
  if (el) {
    el.scrollIntoView({
      block: 'nearest',
      inline: 'end',
    });
  }
};

const estimateWithCurrentQuarter = (item) => {
  return item.estimate === 'H';
};

export const filterEstimateHandler = (array) => {
  return array.filter((item) => estimateWithCurrentQuarter(item));
};

export const filtersStoreHelper = (payload, linked = 0) => {
  const data = [];
  const parsedPayload = typeof payload === 'string' ? JSON.parse(`[${payload}]`) : payload;

  try {
    if (Array.isArray(parsedPayload)) {
      parsedPayload.forEach((item, index) => {
        if (!index) {
          item.map((subItem) => data.push([subItem]));
        } else {
          data.push(item);
        }
      });
    }

    if (!linked && linked !== 0 && Array.isArray(parsedPayload)) {
      const lastArray = data[0]?.[0]?.[2];
      const uniqueLastArray = Array.isArray(lastArray) ? [...new Set(lastArray)] : [];

      data[0][0][2] = uniqueLastArray;
    }

    return data;
  } catch {
    return data;
  }
};

export const validationFilterMarket = (filters) => {
  if (filters?.length > 0) {
    if (filters[0][0]?.length > 0 && filters[0][0][2]?.length === 0) {
      return [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, []]]];
    }
  }
  return filters;
};

export const parseETFIndex = (filters) => {
  let etfSymbolIndex = -1;
  let etfFilterIndex = -1;

  if (filters) {
    for (let index = 0; index < filters.length; index += 1) {
      const item = filters[index];

      if (item && item[0] && item[0][0] === 1999) {
        const [res] = item[0][2];

        etfSymbolIndex = res;
        etfFilterIndex = index;
        break;
      }
    }
  }

  if (etfFilterIndex >= 0) {
    filters.splice(etfFilterIndex, 1);
  }

  return etfSymbolIndex;
};

export const frameTypeArray = (enumItem) => Object.entries(enumItem).map(([title, id]) => ({ title, id }));

export const truncateString = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return `${inputString.substring(0, maxLength)}...`;
  }
  return inputString;
};

export const statusIndustryHandler = (columns, data, index) => {
  const statusIndustry = [];

  const statusPosition = columns.indexOf(index);
  data?.forEach((item) => {
    statusIndustry.push(item[statusPosition]);
  });

  return statusIndustry;
};

export const dataForVolumesHandler = (columnsList, data) => {
  const dataForVolumeBars = {};
  const columns = columnsList ?? [];

  const volume1DayAgo = 1753;
  const volume2DayAgo = 1754;
  const volume3DayAgo = 1755;
  const volume4DayAgo = 1756;
  const priceChangeToday = 218;
  const priceChange1DayAgo = 1757;
  const priceChange2DayAgo = 1758;
  const priceChange3DayAgo = 1759;
  const priceChange4DayAgo = 1760;

  const volume1DayAgoPosition = columns.indexOf(volume1DayAgo);
  const volume2DayAgoPosition = columns.indexOf(volume2DayAgo);
  const volume3DayAgoPosition = columns.indexOf(volume3DayAgo);
  const volume4DayAgoPosition = columns.indexOf(volume4DayAgo);
  const priceChangeTodayPosition = columns.indexOf(priceChangeToday);
  const priceChange1DayAgoPosition = columns.indexOf(priceChange1DayAgo);
  const priceChange2DayAgoPosition = columns.indexOf(priceChange2DayAgo);
  const priceChange3DayAgoPosition = columns.indexOf(priceChange3DayAgo);
  const priceChange4DayAgoPosition = columns.indexOf(priceChange4DayAgo);

  data?.forEach((item) => {
    dataForVolumeBars[item[0]] = [];
    dataForVolumeBars[item[0]].push(item[volume1DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[volume2DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[volume3DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[volume4DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[priceChangeTodayPosition]);
    dataForVolumeBars[item[0]].push(item[priceChange1DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[priceChange2DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[priceChange3DayAgoPosition]);
    dataForVolumeBars[item[0]].push(item[priceChange4DayAgoPosition]);
  });

  return dataForVolumeBars;
};

export const getDividentAndEarnings = (dataList, currentColumns) => {
  const additionalData = {
    earningsValue: {},
    dividendValue: {},
    earningTimeValue: {},
    isETF: {}
  };
  const columns = currentColumns ?? [];

  const {
    INDEX,
    EARNINGS,
    EARNINGS_TIME,
    DIVIDEND,
    IS_ETF
  } = COLUMNS_INDEX;

  const symbolIndex = columns.indexOf(INDEX);
  const earningsIndex = columns.indexOf(EARNINGS);
  const dividendIndex = columns.indexOf(DIVIDEND);
  const earningTimeIndex = columns.indexOf(EARNINGS_TIME);
  const isETFIndex = columns.indexOf(IS_ETF);

  if (symbolIndex < 0 || !dataList?.data) return false;
  dataList?.data?.forEach((item) => {
    const symbolId = item[symbolIndex];
    additionalData.earningsValue[symbolId] = earningsIndex > -1 ? item[earningsIndex] : null;
    additionalData.dividendValue[symbolId] = dividendIndex > -1 ? item[dividendIndex] : null;
    additionalData.earningTimeValue[symbolId] = earningTimeIndex > -1 ? item[earningTimeIndex] : null;
    additionalData.isETF[symbolId] = isETFIndex > -1 ? item[isETFIndex] : null;
  });
  return additionalData;
};

export const workerWithScreenerData = (res, columnsList, symbolsList, currentColumns) => {
  if (!currentColumns) return {};

  const separatorAdditionalDataIndex = currentColumns.length - DEFAULT_COLUMNS_INDEX.length;
  const tableData = res.data.map((subItem) => subItem.slice(0, separatorAdditionalDataIndex));
  const tableColumns = currentColumns.slice(0, separatorAdditionalDataIndex);

  const additionalData = res.data.map((subItem) => subItem.slice(separatorAdditionalDataIndex, subItem.length));
  const additionalColumns = currentColumns.slice(separatorAdditionalDataIndex, currentColumns.length);

  const statusIndustry = statusIndustryHandler(additionalColumns, additionalData, topIds.TOP_INDUSTRY_STATUS_INDEX);
  const statusSubIndustry = statusIndustryHandler(
    additionalColumns,
    additionalData,
    topIds.TOP_SUB_INDUSTRY_STATUS_INDEX
  );

  const statusSector = statusIndustryHandler(additionalColumns, additionalData, topIds.TOP_SECTOR_STATUS_INDEX);
  const statusGroup = statusIndustryHandler(additionalColumns, additionalData, topIds.TOP_GROUP_STATUS_INDEX);

  const dataForVolumeBars = dataForVolumesHandler(additionalColumns, additionalData);

  const insertColumn = { name: 'Name', type: 'text_capitalize' };
  const tableColumnsData = tableColumns.map((item) => columnsList.find((column) => column.index === item));
  const tableColumnsResult = [tableColumnsData[0], insertColumn, ...tableColumnsData.slice(1)];

  const tableResult = tableData.map((item, index) => {
    const insertSymbolData = symbolsList[item[0]] || {};
    insertSymbolData.sortIndex = symbolsList[item[0]]?.index;

    const insertSymbol = symbolsList[item[0]]?.sym;
    const insertName = symbolsList[item[0]]?.name;

    return [insertSymbolData, insertSymbol, insertName, ...item.slice(2), statusIndustry[index],
      statusSubIndustry[index], statusGroup[index], statusSector[index]];
  });

  const industryIndex = currentColumns.findIndex((column) => column === 21);
  const sectorIndex = currentColumns.findIndex((column) => column === 20);
  const groupIndex = currentColumns.findIndex((column) => column === 29);
  const subIndustryIndex = currentColumns.findIndex((column) => column === 30);

  const industryOfSymbolsData = res.data.map(
    (additionalDataIndustry) => ({
      index: additionalDataIndustry[0],
      industry: additionalDataIndustry[industryIndex],
      sector: additionalDataIndustry[sectorIndex],
      group: additionalDataIndustry[groupIndex],
      subIndustry: additionalDataIndustry[subIndustryIndex],
    })
  );

  const preparedDataScreener = { columns: tableColumnsResult, result: tableResult };

  return { preparedDataScreener, dataForVolumeBars, industryOfSymbolsData };
};

export const checkTokenExpire = (token) => {
  let checkedToken = true;
  if (token) {
    if (jwtDecode(token).exp < Date.now() / 1000) {
      localStorage.clear();
      checkedToken = false;
    }
  }
  return checkedToken;
};

export const saveTokens = (accessToken, refreshToken) => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const sessionTimeOut = () => {
  window.location.href = '/session-expired';
};

export const today = (dataUpdated) => moment(dataUpdated).format('dddd, MMMM DD h:mm:ss A');

export const checkEmptyText = (text) => {
  const array = text?.split(' ');
  const arrayEmpty = array?.filter((el) => el === '');
  return array?.length === arrayEmpty?.length;
};

export const blurWidthSizeHandler = (width, numberOfQuarters) => {
  const tableBlurSize = (width - 150) - ((width - 150) / 4);
  const minusAttribute = numberOfQuarters === 4 ? 222 : 160;
  const lineAndBarBlurSize = (width - minusAttribute) - ((width - minusAttribute) / numberOfQuarters);

  return { tableBlurSize, lineAndBarBlurSize };
};

export const selectedQuarterHandler = (currentSubData, lastDataIndex) => {
  const allowData = currentSubData.slice(lastDataIndex, currentSubData?.length);

  const eightQuartersResult = allowData?.length < 5
    ? [lastDataIndex - (8 - allowData?.length), lastDataIndex + allowData?.length]
    : [lastDataIndex - 3, lastDataIndex + 5];

  const fourQuartersResult = allowData?.length < 2
    ? [lastDataIndex - (4 - allowData?.length), lastDataIndex + allowData?.length]
    : [lastDataIndex - 2, lastDataIndex + 2];

  return { eightQuartersResult, fourQuartersResult };
};

export const defaultSelectedQuarterHandler = (currentSubData) => {
  const eightQuartersDefaultResult = [currentSubData?.length - 8, currentSubData?.length];
  const fourQuartersDefaultResult = [currentSubData?.length - 4, currentSubData?.length];

  return { eightQuartersDefaultResult, fourQuartersDefaultResult };
};

const getSearchedSymbols = (array, searchName, field) => array?.filter((el) => {
  const name = el[field] ? el[field].toLowerCase() : el[field];
  return name ? name.indexOf(searchName.toLowerCase()) !== -1 : null;
});

const sortSearchArray = (array, field, searchName) => {
  return array?.sort((a, b) => {
    if (a[field]?.toLowerCase()
      .indexOf(searchName?.toLowerCase()) > b[field].toLowerCase().indexOf(searchName.toLowerCase())) {
      return 1;
    } if (a[field].toLowerCase()
      .indexOf(searchName?.toLowerCase()) < b[field].toLowerCase().indexOf(searchName.toLowerCase())) {
      return -1;
    }
    if (a[field] > b[field]) return 1;
    return -1;
  });
};

export const getShortDateRange = (start, end) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  if (!start || !end) {
    return null;
  }
  return `${start.toLocaleDateString('en-US', options)} - ${end.toLocaleDateString('en-US', options)}`;
};

const sortPastedSymbols = (searchList, founded, field) => {
  const matched = [];
  const matchedString = [];
  const cleanList = searchList.map((item) => item.replace('$', '')?.toLowerCase());
  founded.forEach((item) => {
    if (cleanList?.includes(item[field]?.toLowerCase())) {
      matched.push(item);
      matchedString.push(item[field]?.toLowerCase());
    }
  });
  return { matched, notMatched: cleanList?.filter((item) => !matchedString.includes(item)) };
};

export const searchItemSym = (array, searchName, field, isMultipleRequest = false) => {
  let newArray = [];
  const regex = /[$]?[A-Za-z.$-]{1,9}/gm;
  const matches = searchName.matchAll(regex);
  const matchArray = [...matches].map((item) => item[0]);
  if (matchArray?.length) {
    matchArray.forEach((item) => {
      const searchedSymbols = getSearchedSymbols(array, item, field);

      const sortResultArray = searchedSymbols?.length
        ? sortSearchArray(searchedSymbols, field, item)
        : [];

      newArray = [...newArray, ...sortResultArray];
    });
  }
  if (isMultipleRequest) {
    return sortPastedSymbols(matchArray, [...new Set(newArray)], field);
  }
  return { matched: [...new Set(newArray.filter((item) => item.enabled === true))], notMatched: [] };
};

const isAlertColumn = (index) => {
  return index === ALERT_COLUMN_INDEXES.SYMBOL || index === ALERT_COLUMN_INDEXES.STATUS;
};

export const getColumnStyle = (size, index, dataPanel, column) => {
  if (!size?.minWidth || dataPanel) {
    return {
      minWidth: `${size?.startWidth || TABLE_COLUMNS_MIN_SIZES.default}px`,
      maxWidth: `${size?.startWidth || TABLE_COLUMNS_MIN_SIZES.default}px`,
      zIndex: index
    };
  }
  if (isAlertColumn(column?.index)) {
    return {
      minWidth: `${(size?.minWidth === TABLE_COLUMNS_MIN_SIZES.default) || size?.maxWidth > 0
        ? TABLE_COLUMNS_MIN_SIZES[column?.index]
        : size?.minWidth}px`,
      maxWidth: `${size?.maxWidth}px`,
      zIndex: index
    };
  }
  return {
    minWidth: `${size?.minWidth}px`,
    maxWidth: `${size?.minWidth}px`,
    zIndex: index
  };
};

export const getDataForRequest = (numberRequest) => {
  const sizeArray = numberRequest - numberConstants.decreaseCount > numberConstants.zeroCount
    ? numberRequest - numberConstants.decreaseCount : numberConstants.defaultCount;
  const startCount = numberRequest - numberConstants.decreaseCount > numberConstants.zeroCount
    ? numberConstants.startCount : numberConstants.zeroCount;
  return [...Array(sizeArray)].map((item, index) => index + startCount);
};

export const getTitleModal = (headerFilter) => {
  if (headerFilter === filtersConstants.newScreen) {
    return MODAL_TITLES.CREATE_SCREEN;
  }
  if (headerFilter === filtersConstants.newPresets) {
    return MODAL_TITLES.CREATE_PRESET;
  }

  return MODAL_TITLES.EDIT;
};

export const getDeltaForCurrentTime = (time) => {
  const transformValue = moment(time * DATE_CONSTANTS.SHIFT_DATE).tz(DEFAULT_TIMEZONE).unix();
  const nowDate = moment().tz(DEFAULT_TIMEZONE).startOf('day').unix();
  return transformValue - nowDate;
};

export const rebuildSearchData = (data) => {
  const resultRebuild = [];
  let mapCount = 0;
  while (mapCount < data?.length) {
    if (data[mapCount]?.enabled) {
      const newSymbol = {};
      newSymbol.symbol = data[mapCount]?.sym;
      newSymbol.full_name = data[mapCount]?.sym;
      newSymbol.description = data[mapCount]?.name;
      newSymbol.exchange = data[mapCount]?.sym;
      newSymbol.ticker = data[mapCount]?.sym;
      resultRebuild.push(newSymbol);
    }
    mapCount += 1;
  }
  return resultRebuild;
};

export const getFoldersItems = (data, folders) => {
  const newArray = [];
  folders.forEach((element) => {
    const newElement = { ...element };
    newElement.items = data.filter((item) => item?.folder?.id === element.id);
    newArray.push(newElement);
  });
  return newArray;
};

export const removeDuplicate = (data) => {
  const newData = [];
  data.forEach((item) => {
    const indexItem = newData?.findIndex((el) => el[0]?.sortIndex === item[0]?.sortIndex);
    if (indexItem === -1) {
      newData.push(item);
    }
  });
  return newData;
};

export const checkRangeValue = (firstValue, secondValue) => {
  let check = true;
  if ((firstValue === null && secondValue === null)
    || (firstValue === 0 && secondValue === 0)
    || (firstValue === null && secondValue === 0)
    || (firstValue === undefined && secondValue === undefined)) {
    check = false;
  }
  return check;
};

export const setSortArray = (array, defaultItem, currentIndex) => {
  if (!array.length || currentIndex === -1) {
    return [...array, defaultItem];
  }

  const newArray = [...array];
  const item = newArray[currentIndex];
  const isSpecialColumn = REVERSE_SORTING_COLUMNS.includes(defaultItem.indexElement);

  if (item.sortValue === -1) {
    newArray.splice(currentIndex, 1);
    return newArray;
  }

  if (isSpecialColumn) {
    item.sortValue = item.sortValue === 0 ? 1 : -1;
  } else {
    item.sortValue = item.sortValue === 1 ? 0 : -1;
  }

  if (item.sortValue === -1) {
    newArray.splice(currentIndex, 1);
  }

  return newArray;
};

const findPosition = (item, activeSymbol, isAlertTable) => {
  if (!item.length) return undefined;
  if (isAlertTable) {
    return item[0].id === activeSymbol;
  }
  return item[0].sortIndex === activeSymbol;
};

let downFunctionTimeout;

export const upFunctionWidget = (activeSymbol, data, setPosition) => {
  const currentSymbolPosition = data.findIndex((item) => findPosition(item, activeSymbol));
  if (currentSymbolPosition > 0) {
    const orderValue = JSON.parse(JSON.stringify(data[currentSymbolPosition - 1][0]));
    setPosition(orderValue?.sortIndex);
  } else if (currentSymbolPosition === 0) {
    const orderValue = JSON.parse(JSON.stringify(data[data?.length - 1][0]));
    setPosition(orderValue?.sortIndex);
  }
};

export const downFunctionWidget = (activeSymbol, data, setPosition, setScrollTop, scrollTop) => {
  if (scrollTop === 0) {
    setScrollTop(1);
  }
  const currentSymbolPosition = data.findIndex((item) => findPosition(item, activeSymbol));
  if (data?.length > currentSymbolPosition + 1) {
    const orderValue = JSON.parse(JSON.stringify(data[currentSymbolPosition + 1][0]));
    setPosition(orderValue?.sortIndex);
  } else if (data?.length === currentSymbolPosition + 1) {
    const orderValue = JSON.parse(JSON.stringify(data[0][0]));
    setScrollTop(0);
    downFunctionTimeout = setTimeout(() => {
      setScrollTop(1);
      clearTimeout(downFunctionTimeout);
    }, TWO_HUNDRED);
    setPosition(orderValue?.sortIndex);
  }
};

export const upFunction = (
  activeSymbol, isAlertTable, symbolsData, setActiveAlert, updateSymbolState, watchList
) => {
  const currentIndex = symbolsData.findIndex(
    (item) => findPosition(item, activeSymbol, isAlertTable)
  );

  if (currentIndex >= 0) {
    let previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      previousIndex = symbolsData.length - 1;
    }

    const previousSymbolData = symbolsData[previousIndex][isAlertTable ? 1 : 0];

    if (isAlertTable) {
      setActiveAlert(symbolsData[previousIndex][0]?.id);
    }

    updateSymbolState(previousSymbolData, true, watchList, isAlertTable, previousIndex);
  }
};

export const downFunction = (
  activeSymbol, isAlertTable, symbolsData, setActiveAlert,
  updateSymbolState, watchList, setScrollPosition, scrollPosition
) => {
  const currentIndex = symbolsData.findIndex(
    (item) => findPosition(item, activeSymbol, isAlertTable)
  );

  setScrollPosition(scrollPosition === 0 ? 1 : scrollPosition);

  const nextIndex = (currentIndex + 1) % symbolsData.length;
  const nextSymbolData = symbolsData[nextIndex][isAlertTable ? 1 : 0];

  if (nextIndex === 0) {
    setTimeout(() => setScrollPosition(1), 200);
    setScrollPosition(0);
  }

  if (isAlertTable) {
    setActiveAlert(symbolsData[nextIndex][0]?.id);
  }

  updateSymbolState(nextSymbolData, true, watchList, isAlertTable, nextIndex);
};

export const clearArray = (array) => {
  const newArray = [];
  array?.forEach((item) => {
    const indexItem = FIXED_COLUMNS?.findIndex((el) => el === item);
    if (indexItem === -1) {
      newArray.push(item);
    }
  });
  return newArray;
};

export const filterColumns = (array) => {
  const newArray = [];
  array?.forEach((item) => {
    const indexItem = findIndexItem(newArray, FIND_NAME.ID, item?.id);
    if (indexItem === -1) {
      newArray.push(item);
    }
  });
  return newArray;
};

export const compareDataArray = (oldData, payload) => {
  if (payload?.screenerId !== oldData?.queryScreenerId) {
    return oldData?.dataRows;
  }

  const comparedData = [...oldData?.dataRows];
  const newDataArray = [...payload?.data];
  const startIndex = oldData?.visibleItemsTable[0];
  const topIndex = oldData?.screenerRowTopIndex;

  if (!comparedData.length || !newDataArray.length) {
    return newDataArray;
  }

  comparedData.splice(startIndex, comparedData.length - startIndex, ...newDataArray);

  if (startIndex > 30) {
    const outOfRangeData = Array.from({ length: topIndex }, (_, index) => [
      { ...comparedData[index][0], outOfRange: true },
    ]);

    comparedData.splice(0, topIndex, ...outOfRangeData);
  }

  return comparedData;
};

export const compareColumnArray = (oldData, payload) => {
  if (payload?.screenerId !== oldData?.queryScreenerId) {
    return oldData?.dataColumns;
  }
  return payload?.data;
};

export const compareCounter = (oldState, payload) => {
  if (oldState?.queryScreenerId && (payload?.screenerId !== oldState?.queryScreenerId)) {
    return oldState.counterSymbols;
  }

  return payload.data;
};

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const handlerAwayClick = (event, ref, callback) => {
  if (ref.current && !ref.current.contains(event.target)) {
    callback();
  }
};

export const initPositionHandler = (index, tableIndexesData, setActivePosition) => {
  const specificItem = tableIndexesData.findIndex((item) => item === index);
  setActivePosition(specificItem);
};

export const dynamicPositionHandler = (
  dataRows, currentSymbolHandler, watchList, isAlertTable, setActivePosition, isWidgetIndex = null
) => {
  const data = dataRows?.results ? dataRows?.results : dataRows;
  if (data.length > 0) {
    let newItemPlaceIndex;
    if (isWidgetIndex) {
      newItemPlaceIndex = data?.findIndex(
        (item) => item[0]?.sortIndex === isWidgetIndex
      );
    } else {
      newItemPlaceIndex = data?.findIndex(
        (item) => item[0]?.sortIndex === currentSymbolHandler(watchList, isAlertTable).index
      );
    }
    setActivePosition(newItemPlaceIndex);
  } else {
    setActivePosition(-1);
  }
};

export const defaultSizeValue = (index) => {
  if (index === symbolColumn) {
    return {
      maxWidth: 200,
      minWidth: 160,
      startWidth: 160
    };
  }
  return {
    maxWidth: 0,
    minWidth: 60,
    startWidth: 60
  };
};

export const compareDeepObjects = (oldObj, newObj, path = '') => {
  const changedFields = {};
  if (newObj) {
    Object.keys(newObj).forEach((key) => {
      const newPath = path ? `${path}.${key}` : key;
      const oldProp = oldObj[key];
      const newProp = newObj[key];

      if (oldProp !== undefined && typeof oldProp === 'object' && typeof newProp === 'object') {
        const nestedChanges = compareDeepObjects(oldProp, newProp, newPath);
        Object.assign(changedFields, nestedChanges);
      } else if (oldProp !== newProp) {
        changedFields[newPath] = newProp;
      }
    });
  }

  return changedFields;
};

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
};

export const cleanOldSearchHistoryAndLayoutChart = (data) => {
  const cleanedData = { ...data };
  let historyList = cleanedData?.searchHistory;
  if (cleanedData?.activeChartLayout) {
    delete cleanedData.activeChartLayout;
  }

  try {
    const parsedData = JSON.parse(historyList);
    historyList = JSON.stringify(parsedData.splice(0, Math.min(30, parsedData.length)));
  } catch (e) {
    historyList = '';
  }

  cleanedData.searchHistory = historyList;
  return cleanedData;
};
