import React, {
  useState, useEffect, ReactElement, Ref
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ScreenerButtonGroup from '../ScreenerButtonGroup/ScreenerButtonGroup';
import SymbolSearch from '../ScreenerMenu/components/SymbolSearch';
import WatchlistAddSymbolButton from '../WatchlistAddSymbolButton/WatchlistAddSymbolButton';
import ITabsBlockMenu from './interfaces/ITabsBlockMenu';
import { getFromLocalStorage } from '../../utils/storageWorks';
import RootStateTypes from '../../store/RootStateTypes';
import styles from './sass/WatchlistTabsBlock.module.scss';
import { UNIVERSE_TYPE } from '../../constants/watchlist';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import { ReactComponent as IconStroke } from '../../assets/images/icons/screener/color_stroke.svg';
import ScreenerMenuDivider from '../ScreenerMenu/components/ScreenerMenuDivider';
import { MODAL_TITLES, SUBSCRIPTION_TYPE, TEMPLATE_TYPE } from '../../constants/screener';
import { checkRoleUser } from '../../utils/userHelper';

const TabsBlockMenu: React.FC<ITabsBlockMenu & { forwardedRef: Ref<HTMLDivElement> }> = ({
  selectedItems,
  handleCopySymbols,
  setFocus,
  search,
  resetSearchHandler,
  removeAlerts,
  openPopUp,
  setOpenPopUp,
  openModalHandler,
  forwardedRef,
}: ITabsBlockMenu & { forwardedRef: Ref<HTMLDivElement> }): ReactElement => {
  const { t } = useTranslation();
  const existSelectedItems = selectedItems.length > 0;
  const [hiddenAddSymbol, setHiddenAddSymbol] = useState(false);
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const alertsTab = useSelector((state: RootStateTypes) => state.watchlistState.alertsTab, shallowEqual);
  const isUniverse = (selectedTab?.data?.subType === UNIVERSE_TYPE);
  const isSearchResult = !!search;
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  const isAdminPreset = !adminRole && selectedTab?.type === TEMPLATE_TYPE.WATCHLIST_PRESETS;

  useEffect(() => {
    if (selectedTab?.id === alertsTab?.id || isAdminPreset) {
      setHiddenAddSymbol(true);
    } else {
      setHiddenAddSymbol(false);
    }
  }, [selectedTab]);

  const handleShowCreateModal = () => {
    openModalHandler(MODAL_TITLES.CREATE_DEEPLIST);
  };

  return (
    <div
      className={styles.tabsButtonBlock}
      ref={forwardedRef}
    >
      {existSelectedItems && (
        <div
          className={`${styles.tabsActionBlock} ${hiddenAddSymbol || isUniverse ? styles.tabsActionBlockEditable : ''}
          ${selectedTab?.parentId && styles.tabsActionBlockLinked}`}
        >
          <ScreenerButtonGroup
            removeAlerts={removeAlerts}
            handleCopySymbols={handleCopySymbols}
            isOnlyRemove={hiddenAddSymbol}
            isSearchResult={isSearchResult}
            watchList
          />
        </div>
      )}
      {!existSelectedItems && (
        <SymbolSearch
          setFocus={setFocus}
          search={search}
          resetSearchHandler={resetSearchHandler}
        />
      )}
      <Button
        handleClick={() => setOpenPopUp(!openPopUp)}
        buttonClass={styles.buttonCurrentScreenElement}
      >
        <TextField text={selectedTab.title || t('myDeeplists')} styleType="CurrentScreenButtonGroup" />
        <IconStroke className={styles[openPopUp ? 'activeStroke' : 'defaultStroke']} />
      </Button>
      <Button
        buttonClass={styles.buttonNewScreen}
        handleClick={handleShowCreateModal}
      >
        <TextField text={t('newWatchlistFirst')} styleType="NewScreenButtonGroup" />
      </Button>
      {!hiddenAddSymbol && (
        <WatchlistAddSymbolButton
          hideAddSymbol={getFromLocalStorage('selectedTabParentId') !== 'null' || isUniverse}
          menuFlag={false}
          onlyIconButton
          isPlusIcon
          isWidget={false}
          selectedItemFromWidget={0}
        />
      )}
      <div className={styles.dividerWrapper}>
        <ScreenerMenuDivider />
      </div>
    </div>
  );
};

export default TabsBlockMenu;
