import moment from 'moment-timezone';
import {
  ICreateMarkId,
  IGetDividendMark,
  IGetEarningsMark,
  IGetMarkLabelData,
  IGetSplitMark,
  IGetTypeOfMark
} from '../../../utils/HelpersInterfaces';
import { MARK_MARKER_KEYS, MARK_TYPES } from '../../../constants/tvWidgetOptions';
import { DEFAULT_TIMEZONE } from '../../../constants/account';
import { formatter } from '../../../utils/convertingDataHelper';
import { AdditionalTimelineData, DividendType, SplitType } from '../../../utils/Types';

const isSplits = (data: AdditionalTimelineData | SplitType | DividendType) => (
  Object.keys(data).includes(MARK_MARKER_KEYS.SPLIT_1) && Object.keys(data).includes(MARK_MARKER_KEYS.SPLIT_2)
);
const isDividends = (data: AdditionalTimelineData | SplitType | DividendType) => (
  Object.keys(data).includes(MARK_MARKER_KEYS.DIV_1) && Object.keys(data).includes(MARK_MARKER_KEYS.DIV_2)
);
const isEarnings = (data: AdditionalTimelineData | SplitType | DividendType) => (
  Object.keys(data).includes(MARK_MARKER_KEYS.ERN_1) && Object.keys(data).includes(MARK_MARKER_KEYS.ERN_2)
);

const formatDate = (timestamp: number | undefined): string => (
  timestamp ? moment.tz(timestamp * 1000, DEFAULT_TIMEZONE).format('ddd, MMM D, YYYY') : '-'
);

const formatDateWithTime = (dateString: string | undefined): string => (
  dateString ? moment.tz(dateString, 'YYYY-MM-DD', DEFAULT_TIMEZONE).format('ddd, MMM D, YYYY') : '-'
);

const valueFormatHandler = (value: string | number | undefined, isUsd = true): string => {
  if (!value) {
    return '-';
  }
  const formattedVal = formatter(value, 2);
  return isUsd ? `$${formattedVal}` : formattedVal;
};

const percentFormat = (value: number | undefined, isRound = true): string => {
  if (!value) {
    return '-';
  }
  return isRound ? `${Math.round(value * 100)}%` : `${Number(value * 100).toFixed(2)}%`;
};

const addLineSpaces = (count: number): string => {
  let spaces = '';
  for (let i = 0; i < count; i += 1) {
    spaces += '\u00A0';
  }
  return spaces;
};

const getEarningsShape = (value: number | undefined): string => (value && value > 0 ? 'earningUp' : 'earningDown');

const getEarningsColor = (value: number | undefined, isTransparent: boolean): string => {
  const opacity = isTransparent ? 0 : 1;
  return value && value > 0 ? `rgb(10,153,129, ${opacity})` : `rgb(239,90,91, ${opacity})`;
};

const getDividendMarkData: IGetDividendMark = (data) => ({
  color: 'transparent',
  labelFontColor: 'rgb(42,98,255)',
  label: 'D',
  tooltip: [
    `Ex-date: ${addLineSpaces(12)}${formatDateWithTime(data?.exDate)}`,
    `Amount: ${addLineSpaces(12)}${valueFormatHandler(data?.grossDividendAmount, false)}${data?.currency}`,
    `Payment date: ${addLineSpaces(2)}${formatDateWithTime(data?.payDate)}`
  ]
});

const getSplitMarkData: IGetSplitMark = (data, date) => ({
  color: 'transparent',
  labelFontColor: 'orange',
  label: 'S',
  tooltip: [
    `Split: ${data?.splitFactor}`,
    `Date: ${date}`
  ]
});

const getEarningsMarkData: IGetEarningsMark = (data, date) => {
  const { eps, sales } = data;
  return {
    color: getEarningsColor(eps.surprisePercent, true),
    label: 'E',
    labelFontColor: getEarningsColor(eps.surprisePercent, false),
    tooltip: [
      `Date: ${addLineSpaces(19)} ${date}`,
      `${addLineSpaces(1)}`,
      'Earnings',
      `Reported: ${addLineSpaces(12)}${valueFormatHandler(eps.value)}`,
      `Estimate: ${addLineSpaces(13)}${valueFormatHandler(eps.estimatedValue)}`,
      `Surprise: ${addLineSpaces(14)}${valueFormatHandler(eps.surpriseValue)}
        (${percentFormat(eps.surprisePercent)})`,
      `Growth: ${addLineSpaces(16)}${percentFormat(eps.growth, false)}`,
      `Up Revisions: ${addLineSpaces(7)}${valueFormatHandler(eps.upRevisions, false)}`,
      `Down Revisions: ${addLineSpaces(2)}${valueFormatHandler(eps.downRevision, false)}`,
      `${addLineSpaces(1)}`,
      'Sales',
      `Reported: ${addLineSpaces(12)}${valueFormatHandler(sales.value)}`,
      `Estimate: ${addLineSpaces(13)}${valueFormatHandler(sales.estimatedValue)}`,
      `Surprise: ${addLineSpaces(14)}${valueFormatHandler(sales.surpriseValue)}
        (${percentFormat(sales.surprisePercent)})`,
      `Growth: ${addLineSpaces(16)}${percentFormat(sales.growth, false)}`,
      `Up Revisions: ${addLineSpaces(7)}${valueFormatHandler(sales.upRevisions, false)}`,
      `Down Revisions: ${addLineSpaces(2)}${valueFormatHandler(sales.downRevisions, false)}`,
    ],
    shape: getEarningsShape(eps.growth)
  };
};

export const getType: IGetTypeOfMark = (data) => {
  if (isSplits(data)) {
    return MARK_TYPES.SPLIT;
  }
  if (isDividends(data)) {
    return MARK_TYPES.DIVIDEND;
  }
  if (isEarnings(data)) {
    return MARK_TYPES.EARNINGS;
  }
  return null;
};

export const getMarkLabelData: IGetMarkLabelData = (data, timestamp, isAnnual) => {
  if (isSplits(data)) {
    return getSplitMarkData(data as SplitType, formatDate(timestamp));
  }
  if (isDividends(data)) {
    return getDividendMarkData(data as DividendType);
  }
  if (isEarnings(data)) {
    return getEarningsMarkData(data as AdditionalTimelineData, formatDate(timestamp), isAnnual);
  }
  return null;
};

export const createMarkId: ICreateMarkId = (data, index) => `${getType(data)}-${index}`;
