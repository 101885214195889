import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import styles from './sass/SwitchPanel.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';

interface ISwitchPanel {
  tabsHandler: (tab: string) => void;
  activeTab: Record<string, boolean>;
  listButton: string[];
}
const SwitchPanel = ({ tabsHandler, activeTab, listButton }: ISwitchPanel): React.ReactElement => {
  const { t } = useTranslation();
  return useMemo(() => (
    <div className={styles.container}>
      {listButton.map((item, index) => {
        let itemRender;
        const itemTitle = item.charAt(0).toUpperCase() + item.slice(1);
        if (activeTab.less) {
          itemRender = (
            <DefaultButton
              key={[item, index].join('_')}
              id={item}
              handleClick={() => tabsHandler(item)}
              buttonClass="tabDateCentral"
              activeClass={`${activeTab[item] ? 'activeTabDate' : ''}`}
            >
              {t(`${itemTitle}`)}
            </DefaultButton>
          );
        } else {
          itemRender = (
            <DefaultButton
              key={[item, index].join('_')}
              id={item}
              handleClick={() => tabsHandler(item)}
              buttonClass="tabDate"
              activeClass={`${activeTab[item] ? 'activeTabDate' : ''}`}
            >
              {t(`${itemTitle}`)}
            </DefaultButton>
          );
        }
        return itemRender;
      })}
    </div>
  ), [activeTab]);
};

export default SwitchPanel;
