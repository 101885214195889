import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import CategoryItem from './CategoryItem';
import DefaultButton from '../../DefaultButton/DefaultButton';
import TextWithTooltip from './TextWithTooltip';
import useOutsideAlerter from '../../../utils/hooks/useOutside';
import { MODAL_TYPES, CATEGORY_MAX_LENGTH } from '../dataPanelConstants';
import { ReactComponent as IconStroke } from '../../../assets/images/icons/screener/color_stroke.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/screener/plus.svg';
import styles from '../sass/DataPanel.module.scss';

const DataCategoryDropdown = ({
  categories,
  selectCategory,
  selected,
  renameCategory,
  deleteCategory,
  toggleModal,
  maxCategoryError
}) => {
  const { t } = useTranslation();
  const [isDropDownVisible, setDropdownVisible, ref] = useOutsideAlerter(false);

  const handleCategorySelect = (category) => {
    selectCategory(category);
    setDropdownVisible(false);
  };

  const handleOpenAddCategory = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (categories.length >= CATEGORY_MAX_LENGTH) {
      maxCategoryError();
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    toggleModal(true, MODAL_TYPES.ADD_CATEGORY, null);
    setDropdownVisible(false);
  };
  
  useEffect(() => {
    if (!selected && categories.length) {
      selectCategory(categories[0]);
    }
  }, [categories]);
  
  return (
    <div
      ref={ref}
      aria-hidden
      onClick={() => setDropdownVisible(!isDropDownVisible)}
      className={styles.dataCategoryDropdown}
    >
      <TextWithTooltip className={styles.dataCategoryDropdownLabel} title={selected} />
      <IconStroke className={`${styles.dataCategoryDropdownChevron} ${isDropDownVisible && styles.open}`} />
      {isDropDownVisible && (
        <div className={styles.dataCategoryDropdownList}>
          {categories.map((category) => (
            <CategoryItem
              key={[category, 'item'].join('_')}
              selected={selected}
              category={category}
              onCategorySelect={handleCategorySelect}
              onRename={renameCategory}
              onDelete={deleteCategory}
            />
          ))}
          <DefaultButton
            handleClick={handleOpenAddCategory}
            className={styles.dataCategoryDropdownAddButton}
          >
            <PlusIcon />
            {t('dataPanelAddCategory')}
          </DefaultButton>
        </div>
      )}
    </div>
  );
};

DataCategoryDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  selectCategory: PropTypes.func,
  selected: PropTypes.string,
  renameCategory: PropTypes.func,
  deleteCategory: PropTypes.func,
  toggleModal: PropTypes.func,
  maxCategoryError: PropTypes.func,
};

DataCategoryDropdown.defaultProps = {
  categories: [],
  selectCategory: () => undefined,
  selected: '',
  renameCategory: () => undefined,
  deleteCategory: () => undefined,
  toggleModal: () => undefined,
  maxCategoryError: () => undefined,
};

export default DataCategoryDropdown;
