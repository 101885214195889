import { StudyInputType } from "../../../charting_library/charting_library";

export const inputs = [
  {
    id: 'intraday_ma_1_enabled',
    name: 'Intraday MA 1', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-1',
    display: 0
  },
  {
    id: 'intraday_ma_1_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 8,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-1',
    display: 0
  },
  {
    id: 'intraday_ma_1_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Intraday Moving Averages',
    inline: 'inline-group-1',
    display: 0
  },
  {
    id: 'intraday_ma_2_enabled',
    name: 'Intraday MA 2', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-2',
    display: 0
  },
  {
    id: 'intraday_ma_2_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 21,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-2',
    display: 0
  },
  {
    id: 'intraday_ma_2_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Intraday Moving Averages',
    inline: 'inline-group-2',
    display: 0
  },
  {
    id: 'intraday_ma_3_enabled',
    name: 'Intraday MA 3', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-3',
    display: 0
  },
  {
    id: 'intraday_ma_3_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 50,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-3',
    display: 0
  },
  {
    id: 'intraday_ma_3_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Intraday Moving Averages',
    inline: 'inline-group-3',
    display: 0
  },
  {
    id: 'intraday_ma_4_enabled',
    name: 'Intraday MA 4', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-4',
    display: 0
  },
  {
    id: 'intraday_ma_4_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 150,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-4',
    display: 0
  },
  {
    id: 'intraday_ma_4_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Intraday Moving Averages',
    inline: 'inline-group-4',
    display: 0
  },
  {
    id: 'intraday_ma_5_enabled',
    name: 'Intraday MA 5', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-5',
    display: 0
  },
  {
    id: 'intraday_ma_5_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 200,
    group: 'Intraday Moving Averages',
    inline: 'inline-group-5',
    display: 0
  },
  {
    id: 'intraday_ma_5_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Intraday Moving Averages',
    inline: 'inline-group-5',
    display: 0
  },
  // Daily Moving Averages
  {
    id: 'daily_ma_1_enabled',
    name: 'Daily MA 1', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Daily Moving Averages',
    inline: 'inline-group-6',
    display: 0
  },
  {
    id: 'daily_ma_1_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 8,
    group: 'Daily Moving Averages',
    inline: 'inline-group-6',
    display: 0
  },
  {
    id: 'daily_ma_1_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-6',
    display: 0
  },
  {
    id: 'daily_ma_2_enabled',
    name: 'Daily MA 2', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Daily Moving Averages',
    inline: 'inline-group-7',
    display: 0
  },
  {
    id: 'daily_ma_2_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 10,
    group: 'Daily Moving Averages',
    inline: 'inline-group-7',
    display: 0
  },
  {
    id: 'daily_ma_2_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-7',
    display: 0
  },
  {
    id: 'daily_ma_3_enabled',
    name: 'Daily MA 3', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Daily Moving Averages',
    inline: 'inline-group-8',
    display: 0
  },
  {
    id: 'daily_ma_3_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 21,
    group: 'Daily Moving Averages',
    inline: 'inline-group-8',
    display: 0
  },
  {
    id: 'daily_ma_3_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-8',
    display: 0
  },
  {
    id: 'daily_ma_4_enabled',
    name: 'Daily MA 4', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Daily Moving Averages',
    inline: 'inline-group-9',
    display: 0
  },
  {
    id: 'daily_ma_4_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 23,
    group: 'Daily Moving Averages',
    inline: 'inline-group-9',
    display: 0
  },
  {
    id: 'daily_ma_4_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-9',
    display: 0
  },
  {
    id: 'daily_ma_5_enabled',
    name: 'Daily MA 5', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Daily Moving Averages',
    inline: 'inline-group-10',
    display: 0
  },
  {
    id: 'daily_ma_5_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 50,
    group: 'Daily Moving Averages',
    inline: 'inline-group-10',
    display: 0
  },
  {
    id: 'daily_ma_5_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-10',
    display: 0
  },
  {
    id: 'daily_ma_6_enabled',
    name: 'Daily MA 6', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Daily Moving Averages',
    inline: 'inline-group-11',
    display: 0
  },
  {
    id: 'daily_ma_6_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 65,
    group: 'Daily Moving Averages',
    inline: 'inline-group-11',
    display: 0
  },
  {
    id: 'daily_ma_6_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-11',
    display: 0
  },
  {
    id: 'daily_ma_7_enabled',
    name: 'Daily MA 7', // Updated name
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Daily Moving Averages',
    inline: 'inline-group-12',
    display: 0
  },
  {
    id: 'daily_ma_7_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 200,
    group: 'Daily Moving Averages',
    inline: 'inline-group-12',
    display: 0
  },
  {
    id: 'daily_ma_7_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Daily Moving Averages',
    inline: 'inline-group-12',
    display: 0
  },
  //weekly inputs
  {
    id: 'weekly_ma_1_enabled',
    name: 'Weekly MA 1',
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-13',
    display: 0
  },
  {
    id: 'weekly_ma_1_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 10,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-13',
    display: 0
  },
  {
    id: 'weekly_ma_1_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Weekly Moving Averages',
    inline: 'inline-group-13',
    display: 0
  },
  {
    id: 'weekly_ma_2_enabled',
    name: 'Weekly MA 2',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-14',
    display: 0
  },
  {
    id: 'weekly_ma_2_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 20,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-14',
    display: 0
  },
  {
    id: 'weekly_ma_2_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Weekly Moving Averages',
    inline: 'inline-group-14',
    display: 0
  },
  {
    id: 'weekly_ma_3_enabled',
    name: 'Weekly MA 3',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-15',
    display: 0
  },
  {
    id: 'weekly_ma_3_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 30,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-15',
    display: 0
  },
  {
    id: 'weekly_ma_3_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Weekly Moving Averages',
    inline: 'inline-group-15',
    display: 0
  },
  {
    id: 'weekly_ma_4_enabled',
    name: 'Weekly MA 4',
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-16',
    display: 0
  },
  {
    id: 'weekly_ma_4_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 40,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-16',
    display: 0
  },
  {
    id: 'weekly_ma_4_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Weekly Moving Averages',
    inline: 'inline-group-16',
    display: 0
  },
  {
    id: 'weekly_ma_5_enabled',
    name: 'Weekly MA 5',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-17',
    display: 0
  },
  {
    id: 'weekly_ma_5_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 50,
    group: 'Weekly Moving Averages',
    inline: 'inline-group-17',
    display: 0
  },
  {
    id: 'weekly_ma_5_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Weekly Moving Averages',
    inline: 'inline-group-17',
    display: 0
  },
  //monthly inputs
  {
    id: 'monthly_ma_1_enabled',
    name: 'Monthly MA 1',
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-18',
    display: 0
  },
  {
    id: 'monthly_ma_1_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 3,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-18',
    display: 0
  },
  {
    id: 'monthly_ma_1_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Monthly Moving Averages',
    inline: 'inline-group-18',
    display: 0
  },
  {
    id: 'monthly_ma_2_enabled',
    name: 'Monthly MA 2',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-19',
    display: 0
  },
  {
    id: 'monthly_ma_2_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 5,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-19',
    display: 0
  },
  {
    id: 'monthly_ma_2_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Monthly Moving Averages',
    inline: 'inline-group-19',
    display: 0
  },
  {
    id: 'monthly_ma_3_enabled',
    name: 'Monthly MA 3',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-20',
    display: 0
  },
  {
    id: 'monthly_ma_3_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 10,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-20',
    display: 0
  },
  {
    id: 'monthly_ma_3_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Monthly Moving Averages',
    inline: 'inline-group-20',
    display: 0
  },
  {
    id: 'monthly_ma_4_enabled',
    name: 'Monthly MA 4',
    type: 'bool' as StudyInputType.Bool,
    defval: true,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-21',
    display: 0
  },
  {
    id: 'monthly_ma_4_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 15,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-21',
    display: 0
  },
  {
    id: 'monthly_ma_4_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Monthly Moving Averages',
    inline: 'inline-group-21',
    display: 0
  },
  {
    id: 'monthly_ma_5_enabled',
    name: 'Monthly MA 5',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-22',
    display: 0
  },
  {
    id: 'monthly_ma_5_ma_length',
    name: '',
    type: 'integer' as StudyInputType.Integer,
    defval: 20,
    group: 'Monthly Moving Averages',
    inline: 'inline-group-22',
    display: 0
  },
  {
    id: 'monthly_ma_5_ma_type',
    name: '',
    type: 'text' as StudyInputType.Text,
    defval: 'SMA',
    options: ['SMA', 'EMA'],
    group: 'Monthly Moving Averages',
    inline: 'inline-group-22',
    display: 0
  },
];

export const defaultInputs = {
  // Intraday moving averages
  intraday_ma_1_enabled: true,
  intraday_ma_1_ma_length: 8,
  intraday_ma_1_ma_type: 'SMA',

  intraday_ma_2_enabled: true,
  intraday_ma_2_ma_length: 21,
  intraday_ma_2_ma_type: 'SMA',

  intraday_ma_3_enabled: false,
  intraday_ma_3_ma_length: 50,
  intraday_ma_3_ma_type: 'SMA',

  intraday_ma_4_enabled: false,
  intraday_ma_4_ma_length: 150,
  intraday_ma_4_ma_type: 'SMA',

  intraday_ma_5_enabled: false,
  intraday_ma_5_ma_length: 200,
  intraday_ma_5_ma_type: 'SMA',

  // Daily moving averages
  daily_ma_1_enabled: false,
  daily_ma_1_ma_length: 8,
  daily_ma_1_ma_type: 'SMA',

  daily_ma_2_enabled: true,
  daily_ma_2_ma_length: 10,
  daily_ma_2_ma_type: 'SMA',

  daily_ma_3_enabled: false,
  daily_ma_3_ma_length: 21,
  daily_ma_3_ma_type: 'SMA',

  daily_ma_4_enabled: true,
  daily_ma_4_ma_length: 23,
  daily_ma_4_ma_type: 'SMA',

  daily_ma_5_enabled: false,
  daily_ma_5_ma_length: 50,
  daily_ma_5_ma_type: 'SMA',

  daily_ma_6_enabled: true,
  daily_ma_6_ma_length: 65,
  daily_ma_6_ma_type: 'SMA',

  daily_ma_7_enabled: false,
  daily_ma_7_ma_length: 200,
  daily_ma_7_ma_type: 'SMA',

  // Weekly moving averages
  weekly_ma_1_enabled: true,
  weekly_ma_1_ma_length: 10,
  weekly_ma_1_ma_type: 'SMA',

  weekly_ma_2_enabled: false,
  weekly_ma_2_ma_length: 20,
  weekly_ma_2_ma_type: 'SMA',

  weekly_ma_3_enabled: false,
  weekly_ma_3_ma_length: 30,
  weekly_ma_3_ma_type: 'SMA',

  weekly_ma_4_enabled: true,
  weekly_ma_4_ma_length: 40,
  weekly_ma_4_ma_type: 'SMA',

  weekly_ma_5_enabled: false,
  weekly_ma_5_ma_length: 50,
  weekly_ma_5_ma_type: 'SMA',

  // Monthlu moving averages
  monthly_ma_1_enabled: true,
  monthly_ma_1_ma_length: 3,
  monthly_ma_1_ma_type: 'SMA',

  monthly_ma_2_enabled: false,
  monthly_ma_2_ma_length: 5,
  monthly_ma_2_ma_type: 'SMA',

  monthly_ma_3_enabled: false,
  monthly_ma_3_ma_length: 10,
  monthly_ma_3_ma_type: 'SMA',

  monthly_ma_4_enabled: true,
  monthly_ma_4_ma_length: 15,
  monthly_ma_4_ma_type: 'SMA',

  monthly_ma_5_enabled: false,
  monthly_ma_5_ma_length: 20,
  monthly_ma_5_ma_type: 'SMA',

};
