import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from '../sass/WidgetCharts.module.scss';
import TargetListWrapper from '../../Screener/components/TargetMenu/TargetListWrapper';
import { ITargetListItem } from '../../Screener/interfaces/ITargetList';
import useLongDescription, { descriptionCache } from '../../../utils/hooks/useLongDescription';
import { SECOND } from '../../../constants/tvWidgetOptions';
import { IPerformanceChartSymbolItem } from '../types/WidgetChartsInterfaces';
import { BAR_HEIGHT } from '../types/WidgetChartsEnums';
import { setHoveredSymbolIndex } from '../../../store/tableData/slice';

let descriptionTimeout: ReturnType<typeof setTimeout>;

const PerformanceChartSymbolItem = ({
  symbol, targetLists, widget, changeSymbolForGroup, screenerId, handlerTargetList
}: IPerformanceChartSymbolItem): React.ReactElement => {
  const [currentTargetList, setCurrentTargetList] = useState<ITargetListItem | null | undefined>(null);
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState('');
  const [toggleTargetList, setToggleTargetList] = useState(false);
  const { getDescription } = useLongDescription();
  const dispatch = useDispatch();

  useEffect(() => {
    const getCurrentTargetList = targetLists.find((list) => {
      const result = list?.data?.filters[0][0][2].find((symbolItem: number) => symbolItem === symbol?.index);
      return (result !== undefined) ? list : false;
    });
    setCurrentTargetList(getCurrentTargetList as ITargetListItem);
  }, [targetLists]);

  const showDescriptionHandler = () => {
    descriptionTimeout = setTimeout(() => setShowDescription(true), SECOND);
  };

  const handleRemoveSymbolFromTargetList = () => {
    if (currentTargetList) {
      setCurrentTargetList(null);
    }
  };

  const hideDescriptionHandler = () => {
    clearTimeout(descriptionTimeout);
    setShowDescription(false);
  };

  const descriptionHandler = async () => {
    if (symbol) {
      await getDescription(symbol?.sym);
      setDescription(descriptionCache[symbol?.sym]);
    }
  };

  useEffect(() => {
    if (showDescription) {
      descriptionHandler();
    }
  }, [showDescription]);

  return (
    <>
      {symbol
        ? (
          <div className={styles.symbol}>
            <TargetListWrapper
              item={{ ...symbol, sortIndex: symbol.index }}
              showDescription={showDescription}
              setShowDescription={setShowDescription}
              toggleTargetList={toggleTargetList}
              currentTargetList={currentTargetList}
              setToggleTargetList={setToggleTargetList}
              handleRemoveSymbolFromTargetList={handleRemoveSymbolFromTargetList}
              description={description}
              targetLists={targetLists}
              addSymbolToTargetList={handlerTargetList}
              widgetId={widget.id}
              iconSize={BAR_HEIGHT.targetListIcon}
              selectedItemFromWidget={screenerId}
              watchList={!!screenerId}
              onMouseEnter={() => dispatch(setHoveredSymbolIndex({
                index: symbol.index,
                dataId: widget.id
              }))}
              onMouseLeave={() => dispatch(setHoveredSymbolIndex({
                index: null,
                dataId: null
              }))}
            />
            <span
              aria-hidden="true"
              role="button"
              tabIndex={0}
              className={styles.symbolHover}
              onMouseEnter={showDescriptionHandler}
              onMouseLeave={hideDescriptionHandler}
              onClick={() => changeSymbolForGroup(symbol?.sym, widget.colorGroup as string, widget.id)}
            >
              {symbol?.sym}
            </span>
          </div>
        )
        : <div />}
    </>
  );
};

export default PerformanceChartSymbolItem;
