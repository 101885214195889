import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IContext, IPineSeries, IPineStudyResult, LibraryPineStudy, PineJS, PineJSStd, RawStudyMetaInfoId } from '../../charting_library/charting_library';
import { defaultFilledAreas, filledAreas } from './metainfo/filledAreas';
import { defaultInputs, inputs } from './metainfo/inputs';
import { defaultOhlcPlots, ohlcPlots } from './metainfo/ohlcPlots';
import { defaultPalettes, palettes } from './metainfo/palettes';
import { defaultStyles, mainStyles } from './metainfo/styles';
import plots from './metainfo/plots';

export default function relativeStrengthLineV2(mPineJS: PineJS): CustomIndicator {

  return {
    name: 'DV - Relative Strength Line',
    metainfo: {
      description: 'DV - Relative Strength Line',
      shortDescription: 'DV - Relative Strength Line',
      id: 'TL-RSv2@tv-basicstudies-1' as RawStudyMetaInfoId,
      isCustomIndicator: true,
      is_price_study: true,
      linkedToSeries: false,
      format: {
        type: 'price',
        precision: 2
      },
      defaults: {
        filledAreasStyle: defaultFilledAreas,
        inputs: defaultInputs,
        ohlcPlots: defaultOhlcPlots,
        palettes: defaultPalettes,
        precision: 2,
        styles: defaultStyles,
      },
      filledAreas: filledAreas,
      inputs: inputs, // Required, even if empty
      ohlcPlots: ohlcPlots,
      palettes: palettes,
      plots: plots,
      styles: mainStyles,
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;

        const [
          indexSym,
          rsPlotType,
          offset,
          maLength1,
          maType1,
          maLength2,
          maType2,
          hideRSNH,
          hideRSNHBP,
          hideRSMovingAverage,
          hideRSMovingAverage2,
          hideMovingAverageCrossover1,
          hideMovingAverageCrossover2,
        ] = getInputArray({
          inputs,
          length: 13,
        });
        const mainSym = context.symbol.info?.ticker;
        this._context.new_sym(indexSym, mPineJS.Std.period(this._context));
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_unlimited_var(this._context.symbol.time);
        // Select the secondary symbol
        this._context.select_sym(1);
        const secondarySym = context.symbol.info?.ticker;
        if (mainSym === secondarySym) {
          return [
            rsPlotType === 'Line' ? 1 : NaN,
            rsPlotType === 'Line' ? 1 : NaN,
            !hideRSMovingAverage ? 1 : NaN,
            rsPlotType === 'Line' ? 1 : NaN,
            NaN,
            NaN,
            NaN,
            rsPlotType === 'Candlesticks' ? 1 : NaN,
            rsPlotType === 'Candlesticks' ? 1 : NaN,
            rsPlotType === 'Candlesticks' ? 1 : NaN,
            rsPlotType === 'Candlesticks' ? 1 : NaN,
            rsPlotType === 'Candlesticks' ? 1 ? 0 : 1 : NaN,
          ]
        }
        const secondarySymbolTime = this._context.new_unlimited_var(this._context.symbol.time);

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolOpen = this._context.new_unlimited_var(mPineJS.Std.open(this._context));
        const secondarySymbolHigh = this._context.new_unlimited_var(mPineJS.Std.high(this._context));
        const secondarySymbolLow = this._context.new_unlimited_var(mPineJS.Std.low(this._context));
        const secondarySymbolClose = this._context.new_unlimited_var(mPineJS.Std.close(this._context));

        const newSymOpen = secondarySymbolOpen.adopt(secondarySymbolTime, mainSymbolTime, 0);
        const newSymHigh = secondarySymbolHigh.adopt(secondarySymbolTime, mainSymbolTime, 0);
        const newSymLow = secondarySymbolLow.adopt(secondarySymbolTime, mainSymbolTime, 0);
        const newSymClose = secondarySymbolClose.adopt(secondarySymbolTime, mainSymbolTime, 0);

        this._context.select_sym(0);

        const RSopen = ((mPineJS.Std.open(this._context) / newSymOpen) * 100) * offset;
        const RShigh = ((mPineJS.Std.high(this._context) / newSymHigh) * 100) * offset;
        const RSlow = ((mPineJS.Std.low(this._context) / newSymLow) * 100) * offset;
        const RSclose = (mPineJS.Std.close(this._context) / newSymClose) * 100 * offset;
        const rsCloseSeries = this._context.new_var(RSclose);

        const RSCloseMovingAverage1 = calculateMA(this._context, mPineJS.Std, maType1, maLength1, rsCloseSeries);
        const RSCloseMovingAverage2 = calculateMA(this._context, mPineJS.Std, maType2, maLength2, rsCloseSeries);

        const rSCloseMovingAverage1 = this._context.new_var(RSCloseMovingAverage1);
        const rSCloseMovingAverage2 = this._context.new_var(RSCloseMovingAverage2);
        const closeSeries = this._context.new_unlimited_var(mPineJS.Std.close(this._context));

        const rawRS = (mPineJS.Std.close(this._context) / newSymClose) * 100;
        const RSNHBP = mPineJS.Std.highest(this._context.new_unlimited_var(rawRS), 252, this._context);
        const RSNHBPCondition = rawRS >= RSNHBP && mPineJS.Std.close(this._context) < mPineJS.Std.highest(this._context.new_unlimited_var(closeSeries), 252, this._context);

        const RSNH = mPineJS.Std.highest(this._context.new_unlimited_var(rawRS), 252, this._context);
        const RSNHCondition = rawRS >= RSNH;

        const directionalcolor = (rsCloseSeries.get(0) >= rsCloseSeries.get(1) && hideRSMovingAverage) ? 0 :
          (rsCloseSeries.get(0) < rsCloseSeries.get(1) && hideRSMovingAverage) ? 1 :
            (rsCloseSeries.get(0) >= RSCloseMovingAverage1 && !hideRSMovingAverage) ? 2 :
              (rsCloseSeries.get(0) < RSCloseMovingAverage1 && !hideRSMovingAverage) ? 3 : 0;

        const crossoverUpMA1 = rsCloseSeries.get(1) < rSCloseMovingAverage1.get(1) && rsCloseSeries.get(0) > rSCloseMovingAverage1.get(0);
        const crossoveeDownMA1 = rsCloseSeries.get(1) > rSCloseMovingAverage1.get(1) && rsCloseSeries.get(0) < rSCloseMovingAverage1.get(0);

        const crossoverUpMA2 = rsCloseSeries.get(1) < rSCloseMovingAverage2.get(1) && rsCloseSeries.get(0) > rSCloseMovingAverage2.get(0);
        const crossoveeDownMA2 = rsCloseSeries.get(1) > rSCloseMovingAverage2.get(1) && rsCloseSeries.get(0) < rSCloseMovingAverage2.get(0);

        return [
          rsPlotType === 'Line' ? RSclose : NaN,
          rsPlotType === 'Line' ? directionalcolor : NaN,
          !hideRSMovingAverage ? RSCloseMovingAverage1 : NaN,
          !hideRSMovingAverage2 ? RSCloseMovingAverage2 : NaN,
          rsPlotType === 'Line' ? RSclose > RSCloseMovingAverage1 ? 0 : 1 : NaN,
          !hideMovingAverageCrossover1 && crossoverUpMA1 ? 1 : NaN,
          !hideMovingAverageCrossover1 && crossoveeDownMA1 ? 1 : NaN,
          !hideMovingAverageCrossover2 && crossoverUpMA2 ? 1 : NaN,
          !hideMovingAverageCrossover2 && crossoveeDownMA2 ? 1 : NaN,
          !hideRSNHBP && RSNHBPCondition ? RSclose : NaN,
          !hideRSNH && RSNHCondition && !RSNHBPCondition && this._context.symbol.isLastBar ? RSclose : NaN,
          rsPlotType === 'Candlesticks' ? RSopen : NaN,
          rsPlotType === 'Candlesticks' ? RShigh : NaN,
          rsPlotType === 'Candlesticks' ? RSlow : NaN,
          rsPlotType === 'Candlesticks' ? RSclose : NaN,
          rsPlotType === 'Candlesticks' ? RSclose >= RSopen ? 0 : 1 : NaN,
        ]
      }
    }
  }
}
function calculateMA(context: IContext, mPineJSStd: PineJSStd, type: string, length: number, series: IPineSeries): number {
  if (type === 'SMA') {
    return mPineJSStd.sma(series, length, context);
  }
  return mPineJSStd.ema(series, length, context);
}
