import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, FilledAreaType, IContext, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyAvailableConstSources, StudyInputType } from '../../charting_library/charting_library';
import plots from './metainfo/plots';
import { defaultPalettes, palettes } from './metainfo/palettes';
import { defaultStyles, styles } from './metainfo/styles';

export default function ripsterClouds(mPineJS: PineJS): CustomIndicator {

  return {
    name: 'DV - Ripster EMA Clouds',
    metainfo: {
      id: 'TL-ripsteremaclouds@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Ripster EMA Clouds',
      description: 'DV - Ripster EMA Clouds',
      is_price_study: true,
      isCustomIndicator: true,
      format: {
        type: 'inherit',
      },
      inputs: [
        {
          defval: 'EMA',
          id: 'ma_type',
          name: 'MA Type',
          options: ['EMA', 'SMA'],
          type: 'text' as StudyInputType.Text,
        },
        {
          defval: 8,
          id: 'short_ema1_length',
          name: 'Short EMA1 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 9,
          id: 'long_ema1_length',
          name: 'Long EMA1 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 5,
          id: 'short_ema2_length',
          name: 'Short EMA2 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 12,
          id: 'long_ema2_length',
          name: 'Long EMA2 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 34,
          id: 'short_ema3_length',
          name: 'Short EMA3 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 50,
          id: 'long_ema3_length',
          name: 'Long EMA3 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 72,
          id: 'short_ema4_length',
          name: 'Short EMA4 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 89,
          id: 'long_ema4_length',
          name: 'Long EMA4 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 180,
          id: 'short_ema5_length',
          name: 'Short EMA5 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 200,
          id: 'long_ema5_length',
          name: 'Long EMA5 Length',
          type: 'integer' as StudyInputType.Integer,
        },
        {
          defval: 'hl2',
          id: 'source',
          name: 'Source',
          options: ['open', 'high', 'low', 'close', 'hl2', 'hlc3', 'ohlc4'],
          type: 'source' as StudyInputType.Source,
        },
        {
          defval: false,
          id: 'display_ema_line',
          name: 'Display EMA line',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: true,
          id: 'show_ema_cloud_1',
          name: 'Show EMA Cloud-1',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: true,
          id: 'show_ema_cloud_2',
          name: 'Show EMA Cloud-2',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: true,
          id: 'show_ema_cloud_3',
          name: 'Show EMA Cloud-3',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'show_ema_cloud_4',
          name: 'Show EMA Cloud-4',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: false,
          id: 'show_ema_cloud_5',
          name: 'Show EMA Cloud-5',
          type: 'bool' as StudyInputType.Bool,
        },
        {
          defval: 0,
          id: 'leading_period_ema',
          max: 100000000000,
          min: 0,
          name: 'Leading Period For EMA Cloud',
          type: 'integer' as StudyInputType.Integer,
        }],
      plots: plots,
      palettes: palettes,
      filledAreas: [{
        fillgaps: false,
        id: 'fill_0',
        isHidden: false,
        objAId: 'plot_0',
        objBId: 'plot_10',
        title: 'MA Cloud1',
        palette: 'lsColor0',
        type: 'plot_plot' as FilledAreaType.TypePlots,
      }, {
        fillgaps: false,
        id: 'fill_1',
        isHidden: false,
        objAId: 'plot_2',
        objBId: 'plot_12',
        title: 'MA Cloud2',
        palette: 'lsColor1',
        type: 'plot_plot' as FilledAreaType.TypePlots
      }, {
        fillgaps: false,
        id: 'fill_2',
        isHidden: false,
        objAId: 'plot_4',
        objBId: 'plot_14',
        title: 'MA Cloud3',
        palette: 'lsColor2',
        type: 'plot_plot' as FilledAreaType.TypePlots
      }, {
        fillgaps: false,
        id: 'fill_3',
        isHidden: false,
        objAId: 'plot_6',
        objBId: 'plot_16',
        title: 'MA Cloud4',
        palette: 'lsColor3',
        type: 'plot_plot' as FilledAreaType.TypePlots
      }, {
        fillgaps: false,
        id: 'fill_4',
        isHidden: false,
        objAId: 'plot_8',
        objBId: 'plot_18',
        title: 'MA Cloud5',
        palette: 'lsColor4',
        type: 'plot_plot' as FilledAreaType.TypePlots
      }
      ],
      styles: styles,
      defaults: {
        filledAreasStyle: {
          fill_0: {
            color: '#2196F3',
            transparency: 45,
            visible: true
          },
          fill_1: {
            color: '#2196F3',
            transparency: 65,
            visible: true
          },
          fill_2: {
            color: '#2196F3',
            transparency: 70,
            visible: true
          },
          fill_3: {
            color: '#2196F3',
            transparency: 65,
            visible: true
          },
          fill_4: {
            color: '#2196F3',
            transparency: 65,
            visible: true
          }
        },
        palettes: defaultPalettes,
        inputs: {
          ma_type: 'EMA',
          short_ema1_length: 8,
          long_ema1_length: 9,
          short_ema2_length: 5,
          long_ema2_length: 12,
          short_ema3_length: 34,
          long_ema3_length: 50,
          short_ema4_length: 72,
          long_ema4_length: 89,
          short_ema5_length: 180,
          long_ema5_length: 200,
          source: 'hl2',
          offset: 0,
          show_long_alerts: false,
          show_short_alerts: false,
          display_ema_line: false,
          show_ema_cloud_1: true,
          show_ema_cloud_2: true,
          show_ema_cloud_3: true,
          show_ema_cloud_4: false,
          show_ema_cloud_5: false,
          leading_period_ema: 0,
        },
        styles: defaultStyles
      },
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [
          ma_type,
          short_ema1_length,
          long_ema1_length,
          short_ema2_length,
          long_ema2_length,
          short_ema3_length,
          long_ema3_length,
          short_ema4_length,
          long_ema4_length,
          short_ema5_length,
          long_ema5_length,
          source,
          display_ema_line,
          show_ema_cloud_1,
          show_ema_cloud_2,
          show_ema_cloud_3,
          show_ema_cloud_4,
          show_ema_cloud_5,
          leading_period_ema,
        ] = getInputArray({
          inputs,
          length: 19
        })

        const s_ma1 = calculateMA(mPineJS, this._context, source, ma_type, short_ema1_length);
        const l_ma1 = calculateMA(mPineJS, this._context, source, ma_type, long_ema1_length);
        const s_ma2 = calculateMA(mPineJS, this._context, source, ma_type, short_ema2_length);
        const l_ma2 = calculateMA(mPineJS, this._context, source, ma_type, long_ema2_length);
        const s_ma3 = calculateMA(mPineJS, this._context, source, ma_type, short_ema3_length);
        const l_ma3 = calculateMA(mPineJS, this._context, source, ma_type, long_ema3_length);
        const s_ma4 = calculateMA(mPineJS, this._context, source, ma_type, short_ema4_length);
        const l_ma4 = calculateMA(mPineJS, this._context, source, ma_type, long_ema4_length);
        const s_ma5 = calculateMA(mPineJS, this._context, source, ma_type, short_ema5_length);
        const l_ma5 = calculateMA(mPineJS, this._context, source, ma_type, long_ema5_length);

        return [
          {
            value: show_ema_cloud_1 ? s_ma1 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, s_ma1) : NaN,
          {
            value: show_ema_cloud_2 ? s_ma2 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, s_ma2) : NaN,
          {
            value: show_ema_cloud_3 ? s_ma3 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, s_ma3) : NaN,
          {
            value: show_ema_cloud_4 ? s_ma4 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, s_ma4) : NaN,
          {
            value: show_ema_cloud_5 ? s_ma5 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, s_ma5) : NaN,
          {
            value: show_ema_cloud_1 ? l_ma1 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, l_ma1) : NaN,
          {
            value: show_ema_cloud_2 ? l_ma2 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, l_ma2) : NaN,
          {
            value: show_ema_cloud_3 ? l_ma3 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, l_ma3) : NaN,
          {
            value: show_ema_cloud_4 ? l_ma4 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, l_ma4) : NaN,
          {
            value: show_ema_cloud_5 ? l_ma5 : NaN,
            offset: leading_period_ema
          },
          display_ema_line ? lineColorChecker(this._context, l_ma5) : NaN,
          s_ma1 >= l_ma1 ? 0 : 1,
          s_ma2 >= l_ma2 ? 0 : 1,
          s_ma3 >= l_ma3 ? 0 : 1,
          s_ma4 >= l_ma4 ? 0 : 1,
          s_ma5 >= l_ma5 ? 0 : 1,
        ];
      };
    }
  };
}

const calculateMA = (
  mPineJS: PineJS,
  mcontext: IContext,
  source: StudyAvailableConstSources,
  type: string,
  length: number,
) => {
  let result = 0;
  if (type == "EMA") {
    result = mPineJS.Std.ema(mcontext.new_var(mPineJS.Std[source as StudyAvailableSources](mcontext)), length, mcontext);
  } else if (type == "SMA") {
    result = mPineJS.Std.sma(mcontext.new_var(mPineJS.Std[source as StudyAvailableSources](mcontext)), length, mcontext);
  }
  return result;
}

const lineColorChecker = (mcontext: IContext, val: number): number => {
  return mcontext.new_var(val).get(0) >= mcontext.new_var(val).get(1) ? 0 : 1;
}

export type StudyAvailableSources = "open" | "high" | "low" | "close" | "hl2" | "hlc3" | "ohlc4";
