import React from 'react';
import styles from '../sass/MultiChartSettings.module.scss';
import TextField from '../../TextField/TextField';
import Button from '../../Button/Button';
import { ReactComponent as CheckIcon } from '../../../assets/images/icons/Check.svg';
import { IMultiChartSelectOption } from '../types/MultichartInterfaces';

interface IMCSettingsSelectListItem {
  selectHandler: (val: string) => void,
  selected: boolean,
  item: IMultiChartSelectOption,
}

const MCSettingsSelectListItem = ({
  item,
  selectHandler,
  selected = false
}: IMCSettingsSelectListItem): JSX.Element => (
  <Button
    handleClick={() => { selectHandler(item.id.toString()); }}
    buttonClass={`${styles.selectItem} ${selected ? styles.selected : ''}`}
  >
    <TextField text={item.title} className={styles.caption} />
    {selected ? <CheckIcon /> : null}
  </Button>
);

export default MCSettingsSelectListItem;
