import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import styles from './sass/WatchListModals.module.scss';
import TextField from '../TextField/TextField';
import { setModalType } from '../../store/watchlist/actions';
import Button from '../Button/Button';
import useWatchList from '../../utils/hooks/useWatchList';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import DeleteModalListSymbols from './DeleteModalListSymbols';
import { clearSelectedSymbolsScreener } from '../../store/screener/actions';

const DeleteSymbolsModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { deleteSymbolsFromWatchlist } = useWatchList();
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);

  const clickHandler = () => {
    deleteSymbolsFromWatchlist(selectedSymbols, selectedTab);
    dispatch(clearSelectedSymbolsScreener());
    dispatch(setModalType(''));
  };

  return (
    <ClickAwayListener
      onClickAway={() => dispatch(setModalType(''))}
    >
      <div className={styles.modal}>
        <ButtonListenerWrapper handleCancel={() => dispatch(setModalType(''))} handleConfirm={clickHandler}>
          <div className={`${styles.modal} ${styles.targetList} ${styles.deleteSymbol}`}>
            <div className={styles.modalHeader}>
              <TextField
                text={t('areYouSureShort')}
                styleType="createSelectModalHeader"
              />
            </div>
            <div className={styles.modalBody}>
              <TextField text={t('areYouSureSymbols')} />
              <DeleteModalListSymbols selectedSymbols={selectedSymbols} symbolsList={symbolsList} />
              <div className={styles.applyButtonWrapper}>
                <Button
                  handleClick={() => dispatch(setModalType(''))}
                  buttonClass={styles.cancelButton}
                >
                  <TextField text={t('cancel')} styleType="cancelButton" />
                </Button>
                <Button
                  handleClick={clickHandler}
                  buttonClass={styles.applyButton}
                >
                  <TextField text={t('delete')} styleType="applyButton" />
                </Button>
              </div>
            </div>
          </div>
        </ButtonListenerWrapper>
      </div>
    </ClickAwayListener>
  );
};

export default DeleteSymbolsModal;
