import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from '@mui/material';
import styles from './sass/FilterLayout.module.scss';
import heartRed from '../../assets/images/icons/screener/heartRed.svg';
import heartIcon from '../../assets/images/icons/screener/heart.svg';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import { useTheme } from '../../utils/hooks/useTheme';
import { handleOutsideClick, TMouseEvent } from '../../utils/handleOutsideClick';

interface IFilterLayout {
  children: React.ReactNode;
  title: string | number;
  addButtonHandler: (columnId: number) => void;
  favoriteActive?: boolean;
  id: string | number;
  columnId: number;
  disabled: boolean;
  autoCloseCalendar?: () => void;
  disabledHeart: boolean;
  handlerCloseDropdown?: () => void;
  description?: string;
  category: string;
}

const FilterLayout = ({
  children, title, addButtonHandler, favoriteActive, id, columnId,
  disabled, autoCloseCalendar, disabledHeart, handlerCloseDropdown,
  description, category
}: IFilterLayout): React.ReactElement => {
  const filter = useRef<HTMLDivElement>(null);
  const checkDesc = description || '';

  const { theme } = useTheme();

  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    document.body.addEventListener(
      'click',
      (event) => handleOutsideClick(event as TMouseEvent, autoCloseCalendar as () => void, filter)
    );

    return () => document.body.removeEventListener('click',
      (event) => handleOutsideClick(event as TMouseEvent, autoCloseCalendar as () => void, filter));
  }, []);

  const showEarn = () => {
    setShowTooltip(true);
  };

  const handleCloseEarn = () => {
    setShowTooltip(false);
  };

  useEffect(() => {
    const group = document.getElementById(category);
    if (showTooltip) {
      group?.addEventListener('scroll', handleCloseEarn, false);
    }
    return () => document.removeEventListener('scroll', handleCloseEarn);
  }, [showTooltip]);

  return (
    <div
      onMouseLeave={handlerCloseDropdown}
      className={`${styles.filter_item}
      ${disabled ? styles.disabled : ''}`}
      id={`${id}`}
      ref={filter}
    >
      <Tooltip
        arrow
        open={showTooltip}
        onOpen={showEarn}
        onClose={handleCloseEarn}
        placement="bottom-start"
        componentsProps={{
          tooltip: { className: `${styles.tooltip} ${theme}` },
          arrow: { className: `${styles.arrow} ${theme}` }
        }}
        title={checkDesc}

      >
        <h4>{title}</h4>
      </Tooltip>
      <Button
        buttonClass={styles[disabledHeart ? 'favoriteDisabled' : 'favorite']}
        handleClick={disabled || disabledHeart ? () => {} : () => addButtonHandler(columnId)}
      >
        <Icon
          icon={favoriteActive ? heartRed : heartIcon}
        />
      </Button>
      <div className={styles.filterRow}>
        {children}
      </div>
    </div>
  );
};

export default FilterLayout;
