import { shallowEqual, useSelector } from 'react-redux';

import { IUseAdditionalDataForTvHook } from '../types/Interfaces';
import { StoredSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';
import { WatermarkContentData, WatermarkLine } from '../../../charting_library';
import { SectorIndustryCache } from '../../../services/ChartPrefetchServices/SectorIndusrtryDataProcessor';
import RootStateTypes from '../../../store/RootStateTypes';
import { getFromLocalStorage } from '../../../utils/storageWorks';

const useAdditionalDataForTv = (): IUseAdditionalDataForTvHook => {
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);

  const contentHandler = (data: WatermarkContentData): WatermarkLine[] => {
    const isWebView = getFromLocalStorage('isWebView');
    const symbolIndex = symbolsList.findIndex((item: StoredSymbol) => item.sym === data?.symbolInfo?.ticker);

    const sectorIndustryCache = SectorIndustryCache.getDataFromCache(symbolIndex);
    const defaultFieldsSecondRow = { fontSize: isWebView ? 16 : 32, lineHeight: isWebView ? 20 : 40, vertOffset: 5 };
    const defaultFieldsFirstRow = { fontSize: isWebView ? 60 : 89, lineHeight: isWebView ? 70 : 90, vertOffset: 0 };

    const extraData = !sectorIndustryCache ? [] : [
      { ...defaultFieldsSecondRow, text: sectorIndustryCache?.industry || '' },
      { ...defaultFieldsSecondRow, text: sectorIndustryCache?.sector || '' }
    ];

    return [
      { ...defaultFieldsFirstRow, text: `${data.symbolInfo.ticker},${data.interval}` },
      { ...defaultFieldsSecondRow, text: data.symbolInfo.description || '' },
      ...extraData,
    ];
  };

  return { contentHandler };
};

export default useAdditionalDataForTv;
