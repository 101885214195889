import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Header.module.scss';
import { notificationsData } from '../../constants/account';
import HeaderNotifications from './components/HeaderNotifications';

const Header = ({ className }) => {
  const [notificationsDropdownVisible, setNotificationsDropdownVisible] = useState(false);

  useEffect(() => {
    const escKeyPressHandler = (event) => {
      if (event.key === 'Escape') {
        setNotificationsDropdownVisible(false);
      }
    };

    window.addEventListener('keydown', escKeyPressHandler);

    return () => window.removeEventListener('keydown', escKeyPressHandler);
  }, []);

  const closeDropDawn = useCallback(() => {
    setNotificationsDropdownVisible(false);
  }, []);

  const openNotification = useCallback(() => {
    setNotificationsDropdownVisible(!notificationsDropdownVisible);
  }, []);

  return (
    <div
      className={className}
      onMouseLeave={closeDropDawn}
    >
      <div className={styles.row}>
        <div className={styles.group}>
          <HeaderNotifications
            notificationsDropdownVisible={notificationsDropdownVisible}
            closeDropDawn={closeDropDawn}
            notificationsData={notificationsData}
            openNotification={openNotification}
          />
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

Header.defaultProps = {
  className: '',
};

export default React.memo(Header);
