import React from 'react';
import SortIcons from '../../Sorting/SortIcons/SortIcons';
import styles from '../sass/MultiChartSettings.module.scss';
import { REVERSE_SORTING_COLUMNS } from '../../../constants/screener';

type MCSettingsSortButtonProps = {
  sortDirection: number,
  setSortDirection: (value: number) => void
};
const switchDirection = (dir: number) => {
  let res = dir + 1;
  if (res > 1) { res = -1; }
  return res;
};

const MCSettingsSortButton = ({
  sortDirection,
  setSortDirection,
}: MCSettingsSortButtonProps): JSX.Element => (
  <button
    aria-hidden="true"
    className={styles.sortButton}
    title="Sort order"
    type="button"
    onClick={() => { setSortDirection(switchDirection(sortDirection)); }}
  >
    <SortIcons
      isAlertTable={false}
      reversFlag={REVERSE_SORTING_COLUMNS.includes(17)}
      value={sortDirection}
    />
  </button>
);

export default MCSettingsSortButton;
