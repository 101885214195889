import React, {
  useEffect, useState, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Button from '../Button/Button';
import { BUTTON_GROUP_SETTINGS } from '../../constants/screener';
import ThemeVariants from '../../constants/theme';
import { useTheme } from '../../utils/hooks/useTheme';
import { InfoTooltipDarkStyles, InfoTooltipStyles } from '../Screener/ScreenerTooltipStyles';
import TextWithTooltip from '../DataPanel/components/TextWithTooltip';
import styles from './sass/ScreenButtonGroup.module.scss';

const ButtonItem = ({
  item,
  handler,
  setVisibleDrop,
  visibleDrop,
  disabled,
  hidden,
  isWidget,
}) => {
  const { t } = useTranslation();
  const [viewPopUp, setViewPopUp] = useState('');

  const selectedItems = useSelector((state) => state.selectSymbolsState.selectedItems, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);

  const { theme } = useTheme();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setViewPopUp('');
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [viewPopUp]);

  const clickHandler = (subItem) => {
    if (
      subItem.titleValue === BUTTON_GROUP_SETTINGS.COPY
      || subItem.titleValue === BUTTON_GROUP_SETTINGS.DELETE
    ) {
      handler(isWidget ? subItem.titleValue : selectedItems);
      setViewPopUp(subItem.titleValue);
    }

    if (subItem.titleValue === BUTTON_GROUP_SETTINGS.COPY_TO) {
      setVisibleDrop();
    }

    if (subItem.titleValue !== BUTTON_GROUP_SETTINGS.COPY_TO && visibleDrop) {
      setVisibleDrop();
    }
  };

  const popUpValue = !viewPopUp ? t(item.tooltipValue) : t(item.popUpValue);

  return (
    <Button
      disabled={disabled}
      key={item.titleValue}
      handleClick={() => clickHandler(item)}
      buttonClass={
        `${styles[`${item.className}`]}
         ${styles.smallButton}
         ${hidden && styles.hide}
        ${isWidget && styles.widgetButton}
         ${(item.titleValue === BUTTON_GROUP_SETTINGS.COPY) && selectedTab.isFavorite && styles.offset}`
      }
    >
      <div className={styles.iconWrapper}>
        <TextWithTooltip
          tooltipStyles={theme === ThemeVariants.DARK_MODE ? InfoTooltipDarkStyles : InfoTooltipStyles}
          className={styles.iconWrapper}
          placement="bottom"
          isStatic
          title={useMemo(() => (item.watchListIcon ? <item.watchListIcon /> : <item.icon />), [])}
          textTooltip={popUpValue}
        />
      </div>
    </Button>
  );
};

ButtonItem.propTypes = {
  setVisibleDrop: PropTypes.func.isRequired,
  handler: PropTypes.func.isRequired,
  visibleDrop: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    watchListIcon: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    titleValue: PropTypes.string,
    popUpValue: PropTypes.string,
    className: PropTypes.string,
    tooltipValue: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool.isRequired,
  isWidget: PropTypes.bool
};

ButtonItem.defaultProps = {
  disabled: false,
  isWidget: false
};
export default React.memo(ButtonItem);
