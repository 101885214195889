import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  selectedItems: [],
};

const selectSymbol = createSlice({
  name: 'selectSymbol',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedItems(state, action) {
      state.selectedItems = action.payload;
    },
    setSingleItem(state, action) {
      const position = state.selectedItems.indexOf(action.payload);

      if (position !== -1) {
        state.selectedItems.splice(position, 1);
      } else {
        state.selectedItems.push(action.payload);
      }
    }
  },
});

export const { setSelectedItems, setSingleItem } = selectSymbol.actions;
export default selectSymbol;
