import ACTION_LOADING_PROGRESS from './types';

const INITIAL_STATE = {
  filesLoaded: false
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_LOADING_PROGRESS.SET_FILES_LOADED:
      return { ...state, filesLoaded: payload };
    default:
      return state;
  }
};
