import React, { useEffect, useState } from 'react';

import Portal from '@mui/material/Portal';
import { ClickAwayListener } from '@mui/material';
import { IItemDropdownTabs } from '../types/interfaces';
import styles from '../sass/DashboardItemDropdown.module.scss';
import DropdownTabBlock from './DropdownTabBlock';
import AddItemBlock from './AddItemBlock';
import SearchDropdownBlock from './SearchDropdownBlock';
import { DasboardsItemsDropdownTypes, defaultSort } from '../types/constants';
import ItemDropdownList from './ItemDropdownList';
import { deepEqual } from '../../../utils/objectHelper';

const ItemDropdownTabs = ({
  selectedTab,
  handleSetTab,
  sortedPresetItems,
  sortedSavedItems,
  isOpen,
  positionStyles,
  theme,
  labelSaved,
  labelPreset,
  labelAdd,
  labelDescription,
  handleAddItem,
  iconAddMenu,
  sortStatus,
  search,
  setSearch,
  setSort,
  setIsOpen,
  selectedItem,
  actionDuplicate,
  actionShare,
  actionDelete,
  actionEdit,
  actionMove,
  setSelectedItem,
  actionFavorite,
  actionRemove,
  itemType,
  adminRole,
  setUpdateState
}: IItemDropdownTabs): React.ReactElement => {
  const [dndHideFromSearch, setDndHideFromSearch] = useState<boolean>(false);

  useEffect(() => {
    if (search || search?.length > 0 || !deepEqual(sortStatus, defaultSort)) {
      setDndHideFromSearch(true);
    } else {
      setDndHideFromSearch(false);
    }
  }, [sortStatus, search]);

  return (
    <>
      {isOpen && (
      <Portal>
        <ClickAwayListener
          onClickAway={() => setIsOpen(false)}
        >
          <div className={`${styles.dashboardItemDropdown} ${theme}`} style={positionStyles}>
            <DropdownTabBlock
              theme={theme}
              selectedTab={selectedTab}
              handleSetTab={handleSetTab}
              labelSaved={labelSaved}
              labelPreset={labelPreset}
            />
            <AddItemBlock
              handleAddItem={handleAddItem}
              theme={theme}
              selectedTab={selectedTab}
              labelAdd={labelAdd}
              labelDescription={labelDescription}
              iconAdd={iconAddMenu}
              adminRole={adminRole}
              itemType={itemType}
              setIsOpen={setIsOpen}
            />
            <SearchDropdownBlock
              selectedTab={selectedTab}
              sortStatus={sortStatus}
              search={search}
              setSearch={setSearch}
              itemType={itemType}
              setSort={setSort}
            />
            <ItemDropdownList
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              itemList={selectedTab === DasboardsItemsDropdownTypes.SAVED
                ? sortedSavedItems
                : sortedPresetItems}
              theme={theme}
              actionDuplicate={actionDuplicate}
              actionShare={actionShare}
              actionDelete={actionDelete}
              actionEdit={actionEdit}
              actionMove={actionMove}
              actionFavorite={actionFavorite}
              actionRemove={actionRemove}
              search={search}
              itemType={itemType}
              adminRole={adminRole}
              selectedTab={selectedTab}
              hideDnd={dndHideFromSearch}
              setUpdateState={setUpdateState}
            />
          </div>
        </ClickAwayListener>
      </Portal>
      )}
    </>
  );
};

export default ItemDropdownTabs;
