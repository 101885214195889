import React, {
  useEffect, Suspense, useState
} from 'react';
import { createBrowserHistory } from 'history';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Switch, BrowserRouter, Route
} from 'react-router-dom';
import moment from 'moment/moment';
import useLastStateUpdateStore from '../utils/hooks/useLastStateUpdateStore';
import routes, { webViewRoutes } from './Routes';
import Page from '../layout/Page/Page';
import { getFromLocalStorage, setToLocalStorage } from '../utils/storageWorks';
import '../styles/globals.scss';
import AppLayout from '../layout/AppLayout/AppLayout';
import useAuth from '../utils/hooks/useAuth';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import LayoutLoader from '../components/LayoutLoader/LayoutLoader';
import {
  FIVE_MINUTES, SCREENS_WITH_LAYOUT, TABLE_SCREEN_PATH
} from '../constants/screener';
import useShortcuts from '../utils/hooks/useShortcuts';
import ThemeContextProvider from '../utils/hooks/useTheme';
import ROUTES_URLS from '../constants/routesUrls';
import {
  setRoute,
} from '../store/screener/actions';
import useSpeedCheckup from '../utils/hooks/useSpeedCheckup';
import WidgetLayout from '../layout/WidgetLayout/WidgetLayout';
import useNewVersion from '../utils/hooks/useNewVersion';
import {
  ACCESS_TOKEN, ALERT_SYMBOL, LAST_TABLE_PATH, RE_AUTH_QUERY, REFRESH_TOKEN
} from '../constants/storage';

const App = () => {
  const dispatch = useDispatch();
  const history = createBrowserHistory();
  const lastTablePath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);
  const theme = useSelector(
    (state) => state.accountState.userSettings.theme, shallowEqual
  );
  const [isTokenValid, setIsTokenValid] = useState(false);

  const { updateStoreHandler } = useLastStateUpdateStore();
  const { checkVersion } = useNewVersion();
  const { checkAuthWorker } = useAuth();
  const { sendValuesHandler } = useSpeedCheckup();
  const checkWPToken = async (key) => {
    let value = window.localStorage.getItem(key);
    const data = await Cookies.get();

    if (data?.wp_token) {
      // eslint-disable-next-line no-console
      console.log('checkWPToken');

      setToLocalStorage(ACCESS_TOKEN, data?.wp_token);
      setToLocalStorage(REFRESH_TOKEN, data?.wp_refresh);
      value = data?.wp_token;

      history.push(ROUTES_URLS.TABLE);

      Cookies.remove('wp_token', { path: '', domain: '.deepvue.com' });
      Cookies.remove('wp_refresh', { path: '', domain: '.deepvue.com' });
    }

    if (!value && process.env.REACT_APP_IS_AMPLIFY === 'false') {
      if (getFromLocalStorage('isWebView')) {
        window.ReactNativeWebView.postMessage('logout');
      } else {
        window.location.replace(`${process.env.REACT_APP_WP_URL}?${RE_AUTH_QUERY}`);
      }
    }
    return value;
  };

  const checkTokenPeriod = () => {
    const token = getFromLocalStorage(ACCESS_TOKEN);
    return token ? moment(
      jwtDecode(token).exp * 1000
    ).subtract(10, 'minutes').format('hh:mm:ss') : '';
  };

  useShortcuts();

  useEffect(() => {
    const interval = setInterval(sendValuesHandler, FIVE_MINUTES);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    checkVersion();
    // eslint-disable-next-line no-console
    console.log(`Result = ${navigator?.connection?.effectiveType}`, 'Possible result and average values: '
      + 'slow-2g = 0–50 Kbps / 2g = 50–70 Kbps / 3g = 70-700 Kbps / 4g = 700 - infinity Kbps');

    const interval = setInterval(() => {
      if (checkTokenPeriod() === moment(new Date()).format('hh:mm:ss')) {
        checkAuthWorker();
      }
    }, 480000);

    checkWPToken(ACCESS_TOKEN).then((res) => {
      if (res) {
        setIsTokenValid(true);
      }
    });

    if (lastTablePath) {
      dispatch(setRoute(history.location.pathname === lastTablePath || lastTablePath !== TABLE_SCREEN_PATH.DASHBOARDS
        ? lastTablePath : TABLE_SCREEN_PATH.SCREENER));
    }

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (lastTablePath) {
      dispatch(setRoute(history.location.pathname === lastTablePath || lastTablePath !== TABLE_SCREEN_PATH.DASHBOARDS
        ? lastTablePath : TABLE_SCREEN_PATH.SCREENER));
    }
  }, [lastTablePath]);

  useEffect(() => {
    const routesData = Object.values(ROUTES_URLS);
    if (history.location.pathname.includes(ROUTES_URLS.ALERT)) {
      const linkArray = history.location.pathname.split('/');
      setToLocalStorage(ALERT_SYMBOL, linkArray[linkArray.length - 1]);
      history.push(ROUTES_URLS.TABLE);
      updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.DEEPLIST);
      window.location.reload();
    }

    if (history.location.pathname.includes(ROUTES_URLS.SHARE_ITEM)) {
      const linkArray = history.location.pathname.split('/');
      setToLocalStorage('sharedItemHash', linkArray[linkArray.length - 1]);
      history.push(ROUTES_URLS.TABLE);
      window.location.reload();
    }
    if (!routesData.includes(history.location.pathname)) {
      history.push(ROUTES_URLS.TABLE);
      window.location.reload();
    }
  }, [history]);

  const renderRoute = (route, index) => (
    <Route
      exact={route.exact}
      key={index}
      path={route.path}
      render={(props) => route.render({ ...props })}
    />
  );

  // eslint-disable-next-line consistent-return
  const arrayRoute = () => {
    if (isTokenValid && SCREENS_WITH_LAYOUT.includes(history.location.pathname)) {
      return (
        <AppLayout>
          {routes.map(renderRoute)}
        </AppLayout>
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const webViewRoute = () => {
    if (isTokenValid) {
      return (
        <WidgetLayout>
          {webViewRoutes.map(renderRoute)}
        </WidgetLayout>
      );
    }
  };

  return (
    <ErrorBoundary>
      <BrowserRouter history={history}>
        <Suspense fallback={<LayoutLoader />}>
          <ThemeContextProvider themeState={theme}>
            <Page>
              <Switch>
                {getFromLocalStorage('isWebView') ? webViewRoute() : arrayRoute()}
              </Switch>
            </Page>
          </ThemeContextProvider>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
