import { LONG_COLUMN } from '../constants/screener';

const getInfoTooltip = (name, shortName) => {
  switch (name) {
    case LONG_COLUMN[0].shortName:
      return LONG_COLUMN[0].name;
    case LONG_COLUMN[1].shortName:
      return LONG_COLUMN[1].name;
    default:
      return shortName;
  }
};

export default getInfoTooltip;
