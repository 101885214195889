import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './sass/SidebarScreener.module.scss';
import {
  resetSelectedColumnsScreenerAction,
  setLoaderAction,
  setRoute,
  setSortData
} from '../../store/screener/actions';
import { popUpAction } from '../../store/auth/actions';
import { toggleAlertNotificationListDropdownVisible } from '../../store/alerts/slice';
import DefaultLink from '../DefaultLink/DefaultLink';
import ROUTES_URLS from '../../constants/routesUrls';
import { applyDefaultSorting } from '../../utils/helperFilter';
import { LAST_TABLE_PATH } from '../../constants/storage';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { changeTabAction } from '../../store/tableData/slice';

const SideBarSecondaryItem = ({
  className,
  Icon,
  text,
  tooltipText,
  currentPage,
  isFree,
  isVisible,
  alertsListVisible
}) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);

  let timeout = null;

  const clickHandler = () => {
    if (text !== t('hideMenu') && text !== pagePath) {
      const defaultSorting = applyDefaultSorting(
        text,
        userSettings?.screenerSorting || [],
        selectedTab?.data?.sortData || []
      );
      dispatch(changeTabAction());
      dispatch(resetSelectedColumnsScreenerAction());
      dispatch(setSortData(defaultSorting));
      dispatch(setLoaderAction(true));
      dispatch(popUpAction(false));
      dispatch(setRoute(text));
      updateStoreHandler(LAST_TABLE_PATH, text);
    }

    if (alertsListVisible) {
      dispatch(toggleAlertNotificationListDropdownVisible());
    }

    setVisible(true);

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => setVisible(false), 1500);
  };

  return (
    <div
      aria-hidden="true"
      onClick={() => !isFree && clickHandler()}
      className={`${styles.sideBarItemWrapper}`}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <DefaultLink
        className={`
          ${className}
          ${pagePath === text && ![ROUTES_URLS.DASHBOARDS, ROUTES_URLS.EDUCATION]
          .includes(currentPage) ? styles.sideBarSelectedItem : ''}
          ${isFree ? styles.blur : ''}
      `}
        href={ROUTES_URLS.TABLE}
      >
        <Icon />
        <span>{text}</span>
      </DefaultLink>
      {visible && !isVisible ? <div className={styles.sideBarInfoTool}>{tooltipText}</div> : null}
    </div>
  );
};

SideBarSecondaryItem.propTypes = {
  currentPage: PropTypes.string,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.shape({}),
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  alertsListVisible: PropTypes.bool,
  isFree: PropTypes.bool
};

SideBarSecondaryItem.defaultProps = {
  currentPage: '',
  isVisible: false,
  className: '',
  Icon: null,
  text: '',
  tooltipText: '',
  alertsListVisible: false,
  isFree: false
};

export default SideBarSecondaryItem;
