export default {
  alerts: 'Alerts',
  manageAlerts: 'Manage alerts',
  past15min: 'Past 15 mins',
  past24h: 'Past 24 hours',
  pastWeek: 'Past week',
  pastMonth: 'Past month',
  relVol: 'Rel Vol',
  priceSurge: 'Price Surge',
  note: 'Note: ',
  emptyTitleAlert: 'No alerts have been triggered yet',
  emptyDescriptionAlert: 'You may need to create an alert or wait until your alerts are triggered',
  labelValue: 'Value',
  valuePlaceholder: 'Input value',
  addAlert: 'Add alert',
  alertHeaderLabel: 'Edit alert',
  alertHeaderLabelCreate: 'Create an alert on Trend Line',
  alertHeaderLabelPriceCreate: 'Create an alert on price',
  deleteAlert: 'Delete alert',
  saveAlert: 'Save changes',
  alertLabelNotiy: 'Notify me when',
  noteLabel: 'Note',
  notifySettings: 'Notification settings',
  optionalMessPlaceholder: 'Type an optional message...',
  alertOn: 'Alert on',
  alertOf: 'Alert off',
  alertTimeLabel: 'Alert time',
  labelRises: 'Rises above',
  labelFalls: 'Falls below',
  labelEmail: 'Send email',
  labelSMS: 'Send SMS',
  labelPopUp: 'Show pop-up',
  createAlertTitle: 'Alert successfully created',
  createAlertNotion: 'Alert for {{ symbol }} was created',
  updateAlertTitle: 'Alert successfully updated',
  updateAlertNotion: 'Alert for {{ symbol }} was updated',
  deleteAlertTitle: 'Alert successfully removed',
  deleteMultipleAlertTitle: 'Alerts successfully deleted',
  deleteAlertNotion: 'Alert was removed',
  deleteMultipleAlertNotion: 'Alerts were removed',
  alertNoteError: 'Note field should not be empty',
  alertValueError: 'Value field should not be empty',
  alertSearchPlaceholder: 'Search alerts...',
  manageAlertsTitle: 'Manage Alerts',
  alertConfirmRemoveHeader: 'Are you sure?',
  alertsWantToDelete: 'Are you sure you want to delete:',
  warningAlertAdd: 'Add alert error',
  warningAlertAddTitle: 'Please add alert on empty chart space or Trending Line',
  alertAddButton: 'Add an alert',
  price: 'Price',
  cleanAll: 'Clear all',
  priceError: 'Price cannot be zero',
};
