import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import styles from './sass/NewScreenButtonGroup.module.scss';
import { ReactComponent as IconStroke } from '../../assets/images/icons/screener/color_stroke.svg';
import TextField from '../TextField/TextField';
import DefaultButton from '../DefaultButton/DefaultButton';
import { ReactComponent as IconFilter } from '../../assets/images/icons/screener/filterWhite.svg';
import constants from '../../constants/filters';
import {
  setVisibleColumnsScreenAction,
  setCurrentTabAction,
  setSortData,
  setMarketsFilterScreenerAction
} from '../../store/screener/actions';
import ComingSoonTooltip from '../ComingSoonTooltip/ComingSoonTooltip';
import { setNewScreenFlag, setSelectedScreen } from '../../store/newScreen/actions';
import ClearScreenButton from './ClearScreenButton';
import { CURRENT_TAB_NAME } from '../../constants/screener';
import { setCategoryName, setRequestStateAction } from '../../store/filters/slice';
import DashboardsItemDropdown from '../DashboardsItemsDropdown/DasboardsItemDropdown';
import { ReactComponent as IconStar } from '../../assets/images/icons/screener/screenerIcon.svg';
import useNewScreen from '../../utils/hooks/useNewScreen';
import { changeTabAction } from '../../store/tableData/slice';
import useMultiChartWorker from '../MultichartView/hooks/useMultiChartWorker';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';

const NewScreenButtonGroup = ({
  setCurrentPresetItem,
  isFree,
  setIsVisibleFilters,
  setHeaderFilter,
  currentItemSelected,
  isVisibleFilters,
  activeSymbolIndex,
  resetSearchHandler,
  searchHeaderValue
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();
  const dropdown = useRef();
  const openTimeout = useRef(null);
  const dropdownButtonRef = useRef();
  const { refreshSymbolsMultiChart } = useMultiChartWorker(false);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);
  const itemsNewScreen = useSelector((state) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state) => state.newScreenState.presetsNewScreen, shallowEqual);
  const itemId = useSelector((state) => state.newScreenState.itemId, shallowEqual);
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [activeDropDown, setActiveDropDown] = useState(false);
  const [largeTitleVisible, setLargeTitleVisible] = useState(false);
  const [prevPressedTitle, setPrevPressedTitle] = useState('');
  const [tooltipEditVisible, setTooltipEditVisible] = useState(false);

  const {
    getItems,
    getItemsPreset,
    getColumnSetsItems,
  } = useNewScreen();

  const openFiltersHandler = (title) => {
    if (isVisibleFilters && prevPressedTitle === title) {
      setHeaderFilter(title);
      setIsVisibleFilters(false);
    } else if (isVisibleFilters && prevPressedTitle !== title) {
      setIsVisibleFilters(false);
      openTimeout.current = setTimeout(() => {
        setHeaderFilter(title);
        setIsVisibleFilters(true);
        clearTimeout(openTimeout.current);
      }, 200);
    } else {
      setHeaderFilter(title);
      setIsVisibleFilters(true);
    }
  };

  const viewFilter = useCallback((title, newScreenFlag, widgetId) => {
    if (!widgetId) {
      openFiltersHandler(title);
    }
    setActiveDropDown(false);
    dispatch(setCategoryName(''));
    dispatch(setNewScreenFlag(newScreenFlag));
    setPrevPressedTitle(title);

    if (searchHeaderValue) {
      resetSearchHandler();
    }
  }, [isVisibleFilters, searchHeaderValue]);

  const dropDownHandler = useCallback(() => {
    setActiveDropDown(!activeDropDown);
  }, [activeDropDown]);

  const handlerEditButton = () => {
    viewFilter(constants.filters, false);
    dispatch(setVisibleColumnsScreenAction(false));
  };

  const viewEditTooltip = () => {
    setTooltipEditVisible(true);
  };

  const closeEditTooltip = () => {
    setTooltipEditVisible(false);
  };

  const calculateFilters = () => {
    let counter = 0;
    if (itemId?.data?.filters) {
      itemId?.data?.filters.forEach((filter) => {
        counter += filter.length;
      });
    }
    return counter;
  };

  useEffect(() => {
    if (activeDropDown) {
      setIsVisibleFilters(false);
    }
  }, [activeDropDown, isVisibleFilters]);

  useEffect(() => {
    if (selectedScreen) {
      const currentSaved = itemsNewScreen?.filter((item) => item.id === selectedScreen.id);
      if (currentSaved?.length) {
        dispatch(setCurrentTabAction(CURRENT_TAB_NAME.SAVED));
      }
    }
  }, [selectedScreen]);

  useEffect(() => {
    return () => {
      clearTimeout(openTimeout.current);
      dropdown.current = null;
      openTimeout.current = null;
    };
  }, []);

  const actionCreateNew = (selectedTab) => {
    viewFilter(selectedTab === 0 ? constants.newScreen : constants.newPresets, true);
  };

  const actionEdit = (item) => {
    currentItemSelected(item?.id);
    dispatch(setSelectedScreen(item));
    dispatch(setVisibleColumnsScreenAction(false));
    viewFilter(item?.title, false);
  };

  const actionSetCurrentItem = (screen) => {
    if (screen) {
      const currentId = typeof screen === 'number' ? screen : screen.id;
      resetSearchHandler(currentId);
      currentItemSelected(currentId);
      dispatch(setSortData(screen?.data?.sortData || []));
      setActiveDropDown(false);
      if (typeof screen !== 'number') {
        dispatch(setSelectedScreen(screen));
      }
      updateStoreHandler(constants.selectedScreenId, currentId);
    } else {
      dispatch(changeTabAction());
      dispatch(setSelectedScreen({}));
      setCurrentPresetItem(-1);
      currentItemSelected(0);
      dispatch(setSortData(userSettings?.screenerSorting || []));
      dispatch(setRequestStateAction([]));
      if (!searchHeaderValue) {
        dispatch(setMarketsFilterScreenerAction(activeSymbolIndex
          ? { data: [[[0, 0, [activeSymbolIndex]]]] } : { data: 0 }));
      }
      setIsVisibleFilters(false);
      updateStoreHandler(constants.selectedScreenId, 0);
      refreshSymbolsMultiChart(true);
    }
  };

  const actionUpdateState = () => {
    getItems();
    getItemsPreset();
    getColumnSetsItems();
    getColumnSetsItems(true);
  };

  return (
    <div ref={dropdown} className={styles.buttonGroupWrapper}>
      <Button
        refObject={dropdownButtonRef}
        handleClick={dropDownHandler}
        buttonClass={styles.buttonCurrentScreenElement}
        mouseEnterHandler={() => setLargeTitleVisible(true)}
        mouseLeaveHandler={() => setLargeTitleVisible(false)}
      >
        <TextField text={selectedScreen.title || t('myScreens')} styleType="CurrentScreenButtonGroup" />
        {selectedScreen?.title && (
          <ClearScreenButton
            currentItemSelected={currentItemSelected}
            setCurrentPresetItem={setCurrentPresetItem}
            setIsVisibleFilters={setIsVisibleFilters}
            activeSymbolIndex={activeSymbolIndex}
          />
        ) }
        <IconStroke className={styles[activeDropDown ? 'activeStroke' : 'defaultStroke']} />
      </Button>
      <Button
        handleClick={() => (isFree ? {} : viewFilter(constants.newScreen, true))}
        buttonClass={styles.buttonNewScreen}
        mouseEnterHandler={() => (!isFree ? null : setTooltipVisible(true))}
        mouseLeaveHandler={() => (!isFree ? null : setTooltipVisible(false))}
      >
        <TextField text={t('addNewScreen')} styleType="NewScreenButtonGroup" />
      </Button>
      {(selectedScreen?.title && !selectedScreen.isHoldingView) && (
        <DefaultButton
          buttonClass={!selectedScreen?.title ? constants.editDisabledFilterButton : constants.editFilterButton}
          handleClick={!selectedScreen?.title ? viewEditTooltip : handlerEditButton}
          mouseLeaveHandler={closeEditTooltip}
        >
          <IconFilter />
          {t('editFilter')}
          {!!calculateFilters() && (
            <span className={styles.countOfFilters}>
              {calculateFilters()}
            </span>
          )}
        </DefaultButton>
      )}
      {
        tooltipVisible
        && <ComingSoonTooltip hasIcon={false} title={t('upgradeToSeeMore')} tooltipClass={styles.tooltipFree} />
      }
      {
        tooltipEditVisible
        && (
          <ComingSoonTooltip
            hasIcon={false}
            title={t('pleaseChooseScreen')}
            tooltipClass={styles.tooltipEditFilters}
          />
        )
      }
      <DashboardsItemDropdown
        itemType={0}
        savedItems={itemsNewScreen}
        presetItems={presetsNewScreen}
        isOpen={activeDropDown}
        setIsOpen={setActiveDropDown}
        parrentRef={dropdownButtonRef}
        labelPreset={t('presetsScreen')}
        labelSaved={t('savedScreens')}
        labelAdd={t('screener')}
        labelDescription={t('myScreens')}
        iconAddMenu={<IconStar />}
        actionCreateNewCallback={actionCreateNew}
        actionEditCallback={actionEdit}
        selectedItem={selectedScreen}
        currentItemHandler={actionSetCurrentItem}
        updateAfterDragActions={actionUpdateState}
        topMargin={9}
      />
      {
        selectedScreen?.title?.length > 14
        && !activeDropDown
        && largeTitleVisible
        && <div className={styles.largeTitleDrop}>{selectedScreen?.title}</div>
      }
    </div>
  );
};

NewScreenButtonGroup.propTypes = {
  searchHeaderValue: PropTypes.string,
  resetSearchHandler: PropTypes.func.isRequired,
  isFree: PropTypes.bool.isRequired,
  setCurrentPresetItem: PropTypes.func.isRequired,
  setIsVisibleFilters: PropTypes.func.isRequired,
  setHeaderFilter: PropTypes.func.isRequired,
  currentItemSelected: PropTypes.func.isRequired,
  isVisibleFilters: PropTypes.bool.isRequired,
  currentModalRef: PropTypes.shape({}).isRequired,
  activeSymbolIndex: PropTypes.number
};

NewScreenButtonGroup.defaultProps = {
  searchHeaderValue: '',
  activeSymbolIndex: 0,
};

export default NewScreenButtonGroup;
