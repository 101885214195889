import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IContext, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyAvailableConstSources, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function quallamaggieMovingAverages(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Qullamaggie Moving Averages',
    metainfo: {
      id: 'TL-qullamaggieMovingAverages@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Qullamaggie Moving Averages',
      description: 'DV - Qullamaggie Moving Averages',
      is_price_study: true,
      isCustomIndicator: true,
      precision: 10,
      format: {
        type: 'inherit',
      },
      palettes: {
        palette_0: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'MAs in Order & Rising'
            },
            1: {
              name: 'MAs in Order, MA1 Rising'
            },
            2: {
              name: 'MAs in Order but Declining'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2
          }
        }
      },
      inputs: [
        {
          defval: 'SMA',
          id: 'in_0',
          name: 'Moving Averages Type',
          options: [
            'SMA',
            'EMA'
          ],
          type: 'text' as StudyInputType.Text
        },
        {
          defval: 10,
          id: 'in_1',
          max: 1000000000000,
          min: 1,
          name: 'First MA length',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 'close',
          id: 'in_2',
          name: 'OHLC',
          options: [
            'open',
            'high',
            'low',
            'close',
          ],
          type: 'source' as StudyInputType.Source
        },
        {
          defval: 20,
          id: 'in_3',
          max: 1000000000000,
          min: 0,
          name: 'Second MA length',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 'close',
          id: 'in_4',
          name: 'OHLC',
          options: [
            'open',
            'high',
            'low',
            'close',
          ],
          type: 'source' as StudyInputType.Source
        },
        {
          defval: 5,
          id: 'in_5',
          max: 1000000000000,
          min: 1,
          name: 'number of bars to determine uptrend',
          type: 'integer' as StudyInputType.Integer
        }
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_1',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_2',
          palette: 'palette_0',
          type: 'bg_colorer' as StudyPlotType.BgColorer
        }
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'First MA'
        },
        plot_1: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Second MA'
        },
        plot_2: {
          isHidden: false,
          title: 'Background Color'
        }
      },
      defaults: {
        inputs: {
          in_0: 'SMA',
          in_1: 10,
          in_2: 'close',
          in_3: 20,
          in_4: 'close',
          in_5: 5
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#4CAF50',
                style: 0,
                width: 1
              },
              1: {
                color: '#00BCD4',
                style: 0,
                width: 1
              },
              2: {
                color: '#FFEB3B',
                style: 0,
                width: 1
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#FF5252',
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
            visible: true
          },
          plot_1: {
            color: '#363A45',
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
            visible: true
          },
          plot_2: {
            transparency: 80
          }
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [maType, maLength1, maSource1, maLength2, maSource2, trendLength] = getInputArray({
          inputs,
          length: 6
        });

        const ma1 = calculateMA(mPineJS, this._context, maSource1, maType, maLength1);
        const ma2 = calculateMA(mPineJS, this._context, maSource2, maType, maLength2);

        const maSeries1 = this._context.new_var(ma1);
        const maSeries2 = this._context.new_var(ma2);

        const ma1up = ma1 > maSeries1.get(trendLength);
        const ma2up = ma2 > maSeries2.get(trendLength);

        const green = ma1 > ma2 && ma1up && ma2up;
        const lightgreen = ma1 > ma2 && ma1up && !ma2up;
        const yellow = ma1 > ma2 && !ma1up && !ma2up;

        return [
          ma1,
          ma2,
          green ? 0 : lightgreen ? 1 : yellow ? 2 : NaN
        ];
      };
    }
  };
}
const calculateMA = (
  mPineJS: PineJS,
  mcontext: IContext,
  source: StudyAvailableConstSources,
  type: string,
  length: number,
) => {
  let result = 0;
  if (type == "EMA") {
    result = mPineJS.Std.ema(mcontext.new_var(mPineJS.Std[source as StudyAvailableSources](mcontext)), length, mcontext);
  } else if (type == "SMA") {
    result = mPineJS.Std.sma(mcontext.new_var(mPineJS.Std[source as StudyAvailableSources](mcontext)), length, mcontext);
  }
  return result;
}
export type StudyAvailableSources = "open" | "high" | "low" | "close";
