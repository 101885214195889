import { shallowEqual, useSelector } from 'react-redux';
import { IUseDataPositionSizeHelper } from '../types/PostionSizeInterfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import { GET_ITEMS_LIGHT_SERVER, HTTPS_PREFIX, WSS_PREFIX } from '../../../constants/paths';
import $api, { REACT_APP_PUBLIC_LIGHT_SERVER_URL } from '../../../http';

const useDataPositionSizeHelper = (): IUseDataPositionSizeHelper => {
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);

  const getQuoteHandler = async (symIndex: number): Promise<number> => {
    const apiEndpoint = userLastState.currentMarketEndpoint
      ? `${userLastState.currentMarketEndpoint.replace(WSS_PREFIX, HTTPS_PREFIX)}/`
      : REACT_APP_PUBLIC_LIGHT_SERVER_URL;
    const res = await $api.post(
      `${apiEndpoint}${GET_ITEMS_LIGHT_SERVER}`,
      [
        [3],
        [[[0, 0, [symIndex]]]],
        [],
        [0, 3]
      ]
    );
    const [quote] = res.data[1];
    return quote;
  };

  return { getQuoteHandler };
};

export default useDataPositionSizeHelper;
