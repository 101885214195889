import numberConstant from '../constants/numberConstants';
import { getDataForRequest } from './helpers';
import { getUrl, getUrlSecond } from './helpersWorkItems';
import $api from '../http';
import constants from '../constants/filters';
import { setToLocalStorage } from './storageWorks';
import { fillSpeedValuesHandler } from './hooks/useSpeedCheckup';

const requestHandler = (firstArray, count, type, saveData) => {
  const start = Date.now();

  const numberRequest = Math.ceil(count / numberConstant.limitItems);
  const totalItems = numberRequest - numberConstant.decreaseCount > numberConstant.zeroCount ? [...firstArray] : [];
  const array = getDataForRequest(numberRequest);
  const url = getUrl(type);
  const urlSecond = getUrlSecond(type);

  const requests = array.map((item) => {
    return new Promise((resolve) => {
      $api.get(`${url}?${urlSecond}&page=${item}&limit=${numberConstant.limitItems}`).then((response) => {
        let data = response.data.items;
        if (type === constants.columnSetFolder || type === constants.columnSetFolderPreset) {
          data = totalItems.concat(response.data.items).map((itemFolder) => {
            return {
              id: itemFolder.id, items: [], title: itemFolder.name, type: constants.folder
            };
          });
        }
        resolve(data);
      });
    });
  });
  Promise.all(requests).then((body) => {
    const result = [...totalItems];

    body.forEach((res) => {
      if (res) {
        result.push(...res);
      }
    });
    saveData(result, type);

    // speed check temporary data
    const end = Date.now();
    const speedCheck = end - start;
    fillSpeedValuesHandler(`get_dashboardItem_type_${type}`, speedCheck);

    // eslint-disable-next-line no-console
    console.log(`=> get_dashboardItem_type_${type}`, speedCheck);
  }).catch((e) => setToLocalStorage('errorMessage', e.message));
};

export default requestHandler;
