import {
  useEffect, useRef, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  DATA_PANEL_ADD_WIDTH,
  DATA_PANEL_WIDTH,
  LAYOUT_DELTA_WIDTH,
  ONE_HUNDRED,
  SCREENER_MIN_WIDTH
} from '../../constants/screener';
import { PANEL_CONTENT_GRID_TYPES } from '../../components/DataPanel/dataPanelConstants';

const useResize = (getScreenerLayout, getCurrentInnerWidth) => {
  const [initCurrentGridTemplate, setInitCurrentGridTemplate] = useState('');

  const openPanelDataAdd = useSelector((state) => state.accountState?.openPanelDataAdd, shallowEqual);
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);

  const openDataPanel = userSettings?.openDataPanel;
  const isDataPanelHalf = useRef(userSettings?.dataPanelGridType === PANEL_CONTENT_GRID_TYPES.COMPACT);
  const isPanelOpen = useRef(openDataPanel);
  const isAdditionalPanelOpen = useRef(openPanelDataAdd);
  const currentChartWidth = useRef(getScreenerLayout?.chartWidth);

  const getPanelWidth = () => {
    let currentDeltaInnerWidth = (window.innerWidth / LAYOUT_DELTA_WIDTH);
    if (currentDeltaInnerWidth < 1) currentDeltaInnerWidth = 1;
    return isDataPanelHalf.current
      ? (DATA_PANEL_WIDTH / 2) / currentDeltaInnerWidth
      : DATA_PANEL_WIDTH / currentDeltaInnerWidth;
  };

  const getAdditionalPanelWidth = () => {
    const innerWidth = getCurrentInnerWidth();
    return DATA_PANEL_ADD_WIDTH / innerWidth;
  };

  const getGridTemplateColumns = () => {
    let currentDeltaInnerWidth = (window.innerWidth / LAYOUT_DELTA_WIDTH);
    if (currentDeltaInnerWidth < 1) currentDeltaInnerWidth = 1;
    if (!isPanelOpen.current) {
      return `${ONE_HUNDRED - currentChartWidth.current}% ${currentChartWidth.current}%`;
    }
    let screenerWidth = ONE_HUNDRED - currentChartWidth.current - getPanelWidth();
    if (isAdditionalPanelOpen.current) {
      screenerWidth -= getAdditionalPanelWidth();
      if (screenerWidth < (SCREENER_MIN_WIDTH / currentDeltaInnerWidth)) {
        screenerWidth = SCREENER_MIN_WIDTH / currentDeltaInnerWidth;
        currentChartWidth.current = ONE_HUNDRED - screenerWidth - getAdditionalPanelWidth() - getPanelWidth();
      }
    }
    if (screenerWidth < (SCREENER_MIN_WIDTH / currentDeltaInnerWidth)) {
      screenerWidth = SCREENER_MIN_WIDTH / currentDeltaInnerWidth;
      currentChartWidth.current = ONE_HUNDRED - screenerWidth - getPanelWidth();
    }
    const layout = `${screenerWidth}% ${currentChartWidth.current}% ${getPanelWidth()}%`;
    return isAdditionalPanelOpen.current ? `${layout} ${getAdditionalPanelWidth()}%` : layout;
  };

  const resizeLayout = () => {
    setInitCurrentGridTemplate(getGridTemplateColumns());
  };

  useEffect(() => {
    isDataPanelHalf.current = userSettings?.dataPanelGridType === PANEL_CONTENT_GRID_TYPES.COMPACT;
    isPanelOpen.current = openDataPanel;
    isAdditionalPanelOpen.current = openPanelDataAdd;
    currentChartWidth.current = getScreenerLayout?.chartWidth;
  }, [userSettings?.dataPanelGridType, openDataPanel, openPanelDataAdd, getScreenerLayout?.chartWidth]);

  useEffect(() => {
    resizeLayout();
    window.addEventListener('resize', resizeLayout);
    return () => {
      window.removeEventListener('resize', resizeLayout);
      isDataPanelHalf.current = null;
      isPanelOpen.current = null;
      isAdditionalPanelOpen.current = null;
      currentChartWidth.current = null;
    };
  }, []);

  useEffect(() => {
    resizeLayout();
  }, [userSettings, openPanelDataAdd]);

  return {
    initCurrentGridTemplate, getPanelWidth
  };
};

export default useResize;
