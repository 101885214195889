import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { IUseArticles } from '../types/EducationComponentsInterfaces';

const useArticles: IUseArticles = (articleParams, setArticleParams) => {
  const articlesList = useSelector((state: RootStateTypes) => state.educationState?.articlesList, shallowEqual);
  const levels = useSelector((state: RootStateTypes) => state.educationState?.levels, shallowEqual);
  const articleCategories = useSelector((state: RootStateTypes) => state.educationState?.articleCategories,
    shallowEqual);

  const [atBottom, setAtBottom] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (atBottom) {
      setArticleParams((prev) => ({ ...prev, page: prev.page + 1, isLoadMore: true }));
    }
  }, [atBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight >= scrollHeight - 50 && articlesList.max_pages > articleParams.page) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    const scrollableDiv = ref.current;
    if (scrollableDiv) scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollableDiv) scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [articlesList]);

  return {
    articlesList, ref, articleCategories, levels
  };
};

export default useArticles;
