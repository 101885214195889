import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { SocketDataResponse } from '../types/WidgetChartsypes';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';
import { addWidgetChartsConnectionQueries } from '../../../store/dashboards/slice';
import SocketContext from '../../../context/SocketContext';

const useWidgetAddQuery = (): {
  addWidgetQuery: (resp: SocketDataResponse, mWidget: Widget) => void
} => {
  const dispatch = useDispatch();
  const { Connection } = useContext(SocketContext);

  const addWidgetQuery = (resp: SocketDataResponse, mWidget: Widget): void => {
    dispatch(addWidgetChartsConnectionQueries({
      [mWidget.id]: {
        screenerId: resp?.screenerId, socketId: Connection?.socketId
      }
    }));
  };

  return {
    addWidgetQuery
  };
};

export default useWidgetAddQuery;
