import React, { useState } from 'react';
import styles from '../ScreenerMenu/sass/ScreenerMenu.module.scss';
import { ReactComponent as TargetIcon } from '../../assets/images/download.svg';
import TextField from '../TextField/TextField';

const TargetMenuButton = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const hoverEnterHandler = () => {
    setTooltipVisible(true);
  };
  const hoverLeaveHandler = () => {
    setTooltipVisible(false);
  };

  return (
    <div className={styles.targetIconWrapper} onMouseEnter={hoverEnterHandler} onMouseLeave={hoverLeaveHandler}>
      <TargetIcon />
      {tooltipVisible && (
        <div className={styles.tooltip}>
          <TextField text="Make it easy to spot symbols" />
          <div className={styles.triangle} />
        </div>
      )}
    </div>
  );
};

export default TargetMenuButton;
