import { WIDGET_CHARTS_UPSERT, WIDGET_CHARTS_REMOVE_WIDGET, WIDGET_CHARTS_REMOVE_DASHBOARD } from '../constants/storage';

const findDashboardIndex = (widgetChartsData, dashboardId) => {
  return widgetChartsData.findIndex((item) => item.dashboardId === dashboardId);
};

const handleUpsert = (widgetChartsData, { dashboardId, data }) => {
  const dashboardIndex = findDashboardIndex(widgetChartsData, dashboardId);

  if (dashboardIndex === -1) {
    widgetChartsData.push({ dashboardId, widgets: [data] });
  } else {
    const { widgets } = widgetChartsData[dashboardIndex];
    const widgetIndex = widgets.findIndex((widget) => widget.id === data.id);

    if (widgetIndex === -1) {
      widgets.push(data);
    } else {
      widgets[widgetIndex] = { ...widgets[widgetIndex], ...data };
    }
  }
};

const handleRemoveWidget = (widgetChartsData, { dashboardId, data }) => {
  const dashboardIndex = findDashboardIndex(widgetChartsData, dashboardId);

  if (dashboardIndex !== -1) {
    const { widgets } = widgetChartsData[dashboardIndex];
    const widgetIndex = widgets.findIndex((widget) => widget.id === data);

    if (widgetIndex !== -1) {
      widgets.splice(widgetIndex, 1);
    }
  }
};

const handleRemoveDashboard = (widgetChartsData, { dashboardId }) => {
  const index = findDashboardIndex(widgetChartsData, dashboardId);

  if (index !== -1) {
    widgetChartsData.splice(index, 1);
  }
};

const updateWidgetChartsHandler = (prevData, payload) => {
  const newData = { ...prevData };
  const widgetChartsData = [...prevData[payload.key] || []];
  switch (payload.type) {
    case WIDGET_CHARTS_UPSERT:
      handleUpsert(widgetChartsData, payload);
      break;
    case WIDGET_CHARTS_REMOVE_WIDGET:
      handleRemoveWidget(widgetChartsData, payload);
      break;
    case WIDGET_CHARTS_REMOVE_DASHBOARD:
      handleRemoveDashboard(widgetChartsData, payload);
      break;
    default: 
      break;
  }

  newData[payload.key] = widgetChartsData;
  return newData;
};

export default updateWidgetChartsHandler;
