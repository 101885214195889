import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/WatchListModals.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/closeBlue.svg';

const SelectedSymbolItem = ({ item, removeSelectedItem }) => {
  return (
    <div
      aria-hidden="true"
      className={styles.selectedItem}
    >
      <TextField text={item.sym} styleType="selectedItemAddSymbolModal" />
      <CloseIcon onClick={() => removeSelectedItem(item)} className={styles.removeItemIcon} />
    </div>
  );
};

SelectedSymbolItem.propTypes = {
  item: PropTypes.shape({
    sym: PropTypes.string,
    index: PropTypes.number
  }).isRequired,
  removeSelectedItem: PropTypes.func.isRequired
};

export default React.memo(SelectedSymbolItem);
