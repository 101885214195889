import React, { useMemo } from 'react';

import styles from '../sass/FilterScreen.module.scss';
import TextField from '../../TextField/TextField';
import FilterItem from './FilterItem';
import { ReactComponent as Chunk } from '../../../assets/images/icons/screener/filter/plus-circle.svg';
import { ReactComponent as OrChunk } from '../../../assets/images/icons/screener/filter/plus-circle-or.svg';
import Tooltip from '../../Tooltip/Tooltip';
import ChunkImage from '../../ChunkImage/ChunkImage';
import constants from '../../../constants/filters';
import { TFilterGroupe } from '../Types';

interface IFilterSelectList {
  clearItemFilter: (id: number, activeGroupIndex: number, checkMap: number) => void;
  active: boolean;
  title: string;
  activeGroupIndex: number;
  selectedItems: TFilterGroupe;
  handlerGroup: (id: number) => void;
  id: number;
  textTooltip?: string;
  groupType?: string;
  handlerSearchFilter: (id: number, type: string, columnId: number, list: TFilterGroupe[]) => void;
  hideButton: boolean;

}

const FilterSelectList = ({
  clearItemFilter, active, title, activeGroupIndex,
  selectedItems, handlerGroup, id, textTooltip, groupType,
  handlerSearchFilter, hideButton
}: IFilterSelectList): React.ReactElement => useMemo(() => (
  <div
    className={`${styles.blockFilters} ${active ? styles.activeBlockFilters : ''}`}
    onClick={() => handlerGroup(id)}
    onKeyDown={() => handlerGroup(id)}
    role="button"
    aria-hidden="true"
    tabIndex={0}
  >
    <TextField styleType="titleBox" text={title} />
    {textTooltip && (
      <div className={styles.wrapperTooltipOr}>
        <Tooltip text={textTooltip} classType="tooltipDropWrap" />
      </div>
    )}
    <div className={styles.contentFilters}>
      {selectedItems.items.length > 1 && (
        <ChunkImage
          Image={groupType === constants.or ? OrChunk : Chunk}
          styleType={groupType === constants.or}
        />
      )}
      <div className={styles.listFilters}>
        {selectedItems.items && selectedItems.items.map((item, index) => (
          <FilterItem
            key={[item.sName, index].join('_')}
            arrayData={item.fVal}
            id={item.id}
            title={item.sName ? item.sName : item.name}
            activeGroupIndex={activeGroupIndex}
            clearItem={clearItemFilter}
            type={item.fType}
            handlerSearchFilter={handlerSearchFilter}
            hideButton={hideButton}
          />
        ))}
      </div>
    </div>
  </div>
), [title, activeGroupIndex, selectedItems, active, clearItemFilter]);

export default FilterSelectList;
