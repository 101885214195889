import { t } from 'i18next';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../../../pages/Table/sass/TableScreener.module.scss';

const EmptyStats = () => {
  const statsChartData = useSelector((state) => state.statsChartState.lineChart, shallowEqual);

  return (
    <>
      {statsChartData.length && !statsChartData[0]?.length && !statsChartData[1]?.length && (
        <>
          <div className={styles.widget_stats_empty}>
            {t('noDataChart')}
          </div>
        </>
      )}
    </>
  );
};

export default EmptyStats;
