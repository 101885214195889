export type PositionSizeInputBoxProps = {
  changeHandler: (name: string, value: string) => void
  placeholder?: string,
  name?: string
  value?: string | number
  inputClass?: string,
  validator?: (value: string, target: string, source: string, operator: number) => void;
  keyUpHandler?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  source?: string,
  target?: string,
  operator?: number,
  maxPercentLimit?: boolean,
};

export type StoredSymbol = {
  index: number,
  sym: string,
  last: number,
  icon: string,
};

export type IDetails = {
  id: string,
  acctSize: string,
  desrdPosSz: string,
  percentPosSz: string,
  desrdPFRisk: string,
  percentPFRisk: string,
  symbol: string,
  entryPrice: string,
  exitPrice: string,
  percentExitPrice: string,
  potentialPFTakingPc: string,
  percentPotentialPFTakingPc: string,
};

export enum AllowedInputStyles {
  Dollar = 'dollar',
  Percent = 'percent',
  Regular = 'regular',
}
