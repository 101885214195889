import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import ArticlesCard from './ArticlesCard';
import { IArticles } from './types/EducationComponentsInterfaces';
import useArticles from './hooks/useArticles';
import SearchBlock from './SearchBlock';
import CategoryFilter from './CategoryFilter';
import { ARTICLES } from './types/EducationComponentsEnum';
import LevelFilter from './LevelFilter';
import SortBlock from './SortBlock';

const Articles = ({ articleParams, setArticleParams }: IArticles): React.ReactElement => {
  const {
    articlesList, ref, articleCategories, levels
  } = useArticles(articleParams, setArticleParams);
  return (
    <div className={styles.educationWrapper} ref={ref}>
      <div className={styles.dropdowns}>
        <div className={styles.leftBlock}>
          <SearchBlock
            activeTab={ARTICLES}
            params={articleParams}
            setParams={setArticleParams}
          />
        </div>

        <div className={styles.rightBlock}>
          <CategoryFilter
            params={articleParams}
            setParams={setArticleParams}
            categories={articleCategories}
          />
          <LevelFilter
            params={articleParams}
            setParams={setArticleParams}
            categories={levels}
          />
          <SortBlock
            params={articleParams}
            setParams={setArticleParams}
          />
        </div>
      </div>

      <div className={styles.cards}>
        {articlesList.posts?.map((article) => (
          <ArticlesCard
            key={article.id}
            title={article.title}
            date={article.publish_date}
            image={article.featured_image}
            link={article.link}
            categories={article.categories}
            articleCategories={articleCategories}
            reading_time={article.reading_time}
            views={article.views}
          />
        ))}
      </div>
    </div>
  );
};

export default Articles;
