import { IContext, IPineSeries, PineJSStd } from "../../../charting_library/charting_library";

export default function isLaunchPad(close: number, mPineJSStd: PineJSStd, mcontext: IContext): boolean {
  const dailyCloseSeries = mcontext.new_var(mPineJSStd.close(mcontext));
  const DSMA21 = mPineJSStd.isdaily(mcontext) ? calculateMA(mcontext, mPineJSStd, 'SMA', 21, dailyCloseSeries) : null;
  const DSMA50 = mPineJSStd.isdaily(mcontext) ? calculateMA(mcontext, mPineJSStd, 'SMA', 50, dailyCloseSeries) : null;
  const DEMA65 = mPineJSStd.isdaily(mcontext) ? calculateMA(mcontext, mPineJSStd, 'EMA', 65, dailyCloseSeries) : null;
  if (close && DEMA65 && DSMA21 && DSMA50) {
    return (close > DSMA50 && ((Math.max(close, DSMA21, DSMA50, DEMA65) / Math.min(close, DSMA21, DSMA50, DEMA65)) - 1) <= 0.03);
  }
  return false;
}

function calculateMA(context: IContext, mPineJSStd: PineJSStd, type: string, length: number, series: IPineSeries): number {
  if (type === 'SMA') {
    return mPineJSStd.sma(series, length, context);
  }
  return mPineJSStd.ema(series, length, context);
}
