import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './sass/ScreenButtonGroup.module.scss';
import { BUTTON_GROUP_SETTINGS, buttonData } from '../../constants/screener';
import ButtonItem from './ButtonItem';
import ScreenerDropDown from '../ScreenerDropDown/ScreenerDropDown';
import { setModalType, setSelectedTab } from '../../store/watchlist/actions';
import useOutsideAlerter from '../../utils/hooks/useOutside';
import { FAVOURITE_TYPE, UNIVERSE_TYPE } from '../../constants/watchlist';
import { setSelectedSymbolsBatchScreener } from '../../store/screener/actions';

const areEqual = (prevProps, nextProps) => {
  return prevProps.selectedFromWidget === nextProps.selectedFromWidget
  && JSON.stringify(prevProps.selectedItemsFromWidgets) === JSON.stringify(nextProps.selectedItemsFromWidgets);
};

const ScreenerButtonGroup = ({
  handleCopySymbols, watchList,
  removeAlerts, isOnlyRemove, isSearchResult,
  selectedItemsFromWidgets, isWidget, selectedFromWidget
}) => {
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const [visibleDrop, setVisibleDrop, ref] = useOutsideAlerter(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getTab = (id) => tabsList?.find((item) => item.id === id);

  const getTabList = () => tabsList?.filter((item) => (!item?.parentId
    && item?.data?.subType !== UNIVERSE_TYPE
    && item?.data?.subType !== FAVOURITE_TYPE));

  const isHidden = (item) => {
    const checkedTab = isWidget ? getTab(selectedFromWidget) : selectedTab;
    const isAlertTab = checkedTab?.id === alertTab?.id;

    switch (true) {
      case (watchList && (item.titleValue === BUTTON_GROUP_SETTINGS.DELETE) && checkedTab?.isFavorite):
      case (!watchList && (item.titleValue === BUTTON_GROUP_SETTINGS.DELETE)):
      case (isSearchResult && (item.titleValue === BUTTON_GROUP_SETTINGS.DELETE) && !isAlertTab):
      case (watchList && (checkedTab?.data?.subType === UNIVERSE_TYPE
        && item.titleValue === BUTTON_GROUP_SETTINGS.DELETE)):
      case (watchList && (checkedTab?.data?.subType === UNIVERSE_TYPE
        && item.titleValue === BUTTON_GROUP_SETTINGS.COPY_TO)):
        return true;
      default:
        return false;
    }
  };

  const currentButtons = isOnlyRemove ? [buttonData[2]] : buttonData;

  const removeSymbols = () => {
    if (isWidget) {
      dispatch(setSelectedTab(getTab(selectedFromWidget)));
      dispatch(setSelectedSymbolsBatchScreener(selectedItemsFromWidgets));
    }
    dispatch(setModalType(t('deleteSymbols')));
  };

  const getHandlerType = (handlerType) => {
    if (handlerType === 'delete') {
      return isOnlyRemove ? removeAlerts : removeSymbols;
    }
    return handleCopySymbols;
  };

  return (
    <div
      ref={ref}
      className={`${styles.buttonGroupWrapper}
        ${styles.buttonGroupWrapperWatchlist}
        ${isWidget && styles.buttonGroupWrapperWidget}`}
    >
      {currentButtons.map((item) => (
        <React.Fragment key={item.titleValue}>
          <ButtonItem
            hidden={isHidden(item)}
            item={item}
            visibleDrop={visibleDrop}
            handler={getHandlerType(item.titleValue)}
            setVisibleDrop={() => setVisibleDrop(!visibleDrop)}
            isWidget={isWidget}
          />
          {item.titleValue === BUTTON_GROUP_SETTINGS.COPY_TO && visibleDrop && !isOnlyRemove && (
            <ScreenerDropDown
              watchListDropDownData={getTabList()}
              setVisibleDrop={setVisibleDrop}
              watchList={watchList}
              selectedSymbols={isWidget ? selectedItemsFromWidgets : selectedSymbols}
              multiSelect
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

ScreenerButtonGroup.propTypes = {
  watchList: PropTypes.bool,
  handleCopySymbols: PropTypes.func.isRequired,
  removeAlerts: PropTypes.func,
  isOnlyRemove: PropTypes.bool,
  isSearchResult: PropTypes.bool,
  selectedItemsFromWidgets: PropTypes.arrayOf(PropTypes.number || PropTypes.string),
  isWidget: PropTypes.bool,
  selectedFromWidget: PropTypes.number,
};

ScreenerButtonGroup.defaultProps = {
  watchList: false,
  removeAlerts: () => undefined,
  isOnlyRemove: false,
  isSearchResult: false,
  selectedItemsFromWidgets: [],
  isWidget: false,
  selectedFromWidget: 0,
};

export default React.memo(ScreenerButtonGroup, areEqual);
