import { useState, useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setSelectedCategory as setStoreSelectedCategory } from '../../../store/newScreen/actions';
import { FIFTY, ONE_HUNDRED } from '../../../constants/screener';
import useDataPanelItems from './useDataPanelItems';
import { DATAPOINTS_MAX_LENGTH } from '../dataPanelConstants';

const useDropdownCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { updateUserDataPanels, errorHandler } = useDataPanelItems();

  const [dataPanelCategory, setDataPanelCategory] = useState([]);
  const [dataPoints, setDataPoints] = useState([]);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [requestedPoints, setRequestedPoints] = useState([]);
  const [requestNumber, setRequestNumber] = useState(FIFTY);
  const [search, setSearch] = useState('');

  const storedDataPanels = useSelector((state) => state.newScreenState?.dataPanels, shallowEqual);
  const listColumns = useSelector((state) => state.screenerState.listColumns, shallowEqual);
  const selectedCategory = useSelector((state) => state.newScreenState.selectedCategory, shallowEqual);
  const selectedDataPanel = useSelector((state) => state.newScreenState?.selectedDataPanel, shallowEqual);

  const [isOpen, setIsOpen] = useState(false);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const toggleDropOpen = (value) => {
    setIsOpen(value);
  };

  const mergePanel = () => {
    const { leftColumn, rightColumn } = selectedDataPanel ? selectedDataPanel?.data : storedDataPanels[0]?.data;
    return [leftColumn, rightColumn];
  };

  const concatData = (panels) => {
    return panels?.length ? panels[0]?.concat(panels[1]) : [];
  };

  const findCategory = (concatedPanels) => {
    return concatedPanels.find((item) => item.title === selectedCategory);
  };

  const updadeAndSavePanels = (panels) => {
    const newDataPanels = { ...selectedDataPanel };
    const leftColumn = panels[0];
    const rightColumn = panels[1];
    const updatedPanels = {
      leftColumn,
      rightColumn,
    };
    newDataPanels.data = updatedPanels;
    updateUserDataPanels(newDataPanels);
  };

  const getPointsFromColumnsList = (columnsList) => {
    const mappedPoints = [];
    columnsList.forEach((item) => {
      if (item?.items?.length > 0) {
        item?.items?.forEach((point) => {
          mappedPoints.push({
            label: point?.name,
            value: point?.id,
            type: point?.type,
          });
        });
      }
    });
    return mappedPoints;
  };

  const scrollHandler = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;

    if (scrollTop + clientHeight + ONE_HUNDRED >= scrollHeight) {
      setRequestNumber((prev) => prev + FIFTY);
    }
  };

  const createCategoryDropdown = (joinedData) => {
    const mappedCategories = [];

    if (joinedData) {
      const { leftColumn, rightColumn } = joinedData;
      leftColumn?.forEach((item) => {
        mappedCategories.push(item.title);
      });
      rightColumn?.forEach((item) => {
        mappedCategories.push(item.title);
      });
    }
    return mappedCategories;
  };

  const getIdsFromCategory = () => {
    const panels = mergePanel();
    const mergedPanels = concatData(panels);
    const findedCategory = findCategory(mergedPanels);
    return findedCategory?.columns || [];
  };

  const checkMaxPoints = (data) => {
    if (data?.length >= DATAPOINTS_MAX_LENGTH) {
      errorHandler({
        message: t('maxDatapointsError', { max: DATAPOINTS_MAX_LENGTH }),
        title: t('maxDatapointsErrorTitle'),
        type: t('error')
      });
      return true;
    }
    return false;
  };

  const addPoint = (pointId) => {
    const panels = mergePanel();
    const mergedPanels = concatData(panels);
    const findedCategory = findCategory(mergedPanels);
    if (!checkMaxPoints(findedCategory.columns)) {
      findedCategory.columns.push(pointId);
      updadeAndSavePanels(panels);
    }
  };

  const removePoint = (pointId) => {
    const panels = mergePanel();
    const mergedPanels = concatData(panels);
    const findedCategory = findCategory(mergedPanels);
    const indexPoint = findedCategory?.columns?.findIndex((item) => item === pointId);
    if (indexPoint !== -1) {
      findedCategory?.columns?.splice(indexPoint, 1);
    }
    updadeAndSavePanels(panels);
  };

  const getPointTitle = (pointId) => {
    return dataPoints?.find((item) => item.value === pointId)?.label;
  };

  const getPointData = (pointId) => {
    return dataPoints?.find((item) => item.value === pointId);
  };

  const checkSelected = () => {
    const panels = mergePanel();
    const mergedPanels = concatData(panels);
    const findedCategory = findCategory(mergedPanels);
    return !!findedCategory;
  };

  const setSelectedCategory = (category) => {
    dispatch(setStoreSelectedCategory(category));
  };

  const selectRemove = () => {
    if (!checkSelected()) {
      let currentSelected;
      const indexLeft = selectedDataPanel?.data?.leftColumn?.length - 1;
      if (indexLeft < 0) {
        currentSelected = selectedDataPanel?.data?.rightColumn[0]?.title;
      } else {
        currentSelected = selectedDataPanel?.data?.leftColumn[0]?.title;
      }
      setSelectedCategory(currentSelected);
    }
  };

  const filterDataPoints = () => {
    return dataPoints?.filter((item) => item.label.toLowerCase()?.includes(search.toLowerCase()));
  };

  useEffect(() => {
    if (selectedDataPanel) {
      const newCategories = createCategoryDropdown(selectedDataPanel?.data);
      if (newCategories?.length > dataPanelCategory?.length) {
        const index = selectedDataPanel?.data?.rightColumn?.length - 1;
        const currentSelected = selectedDataPanel?.data?.rightColumn[index]?.title;
        setSelectedCategory(currentSelected);
      }
      if (newCategories?.length < dataPanelCategory?.length) {
        selectRemove();
      }
      setDataPanelCategory(newCategories);
      if (!selectedCategory) {
        setSelectedCategory(selectedDataPanel?.data?.leftColumn[0]?.title);
      }
    }
  }, [selectedDataPanel]);

  useEffect(() => {
    setDataPoints(getPointsFromColumnsList(listColumns));
  }, [listColumns]);

  useEffect(() => {
    if (dataPoints?.length) {
      setRequestedPoints(filterDataPoints().slice(0, requestNumber));
      setRequestNumber(FIFTY);
    }
  }, [dataPoints, search]);

  useEffect(() => {
    if (requestNumber > FIFTY) {
      if (dataPoints?.length < requestNumber && !search) {
        setRequestedPoints(dataPoints.slice(0, dataPoints?.length - 1));
      } else if (search) {
        setRequestedPoints(filterDataPoints().slice(0, requestNumber));
      } else {
        setRequestedPoints(dataPoints.slice(0, requestNumber));
      }
    }
  }, [requestNumber]);

  useEffect(() => {
    if (selectedCategory && (Object.keys(selectedDataPanel ?? {}).length || storedDataPanels.length)) {
      setSelectedPoints(getIdsFromCategory());
    }
  }, [selectedCategory, selectedDataPanel]);

  return {
    isOpen,
    toggleDropOpen,
    dataPanelCategory,
    selectedCategory,
    setSelectedCategory,
    requestedPoints,
    scrollHandler,
    addPoint,
    removePoint,
    selectedPoints,
    search,
    handleSearch,
    getPointTitle,
    getPointData,
  };
};

export default useDropdownCategory;
