import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/ScreenerTable.module.scss';
import VolumeDiagram from './VolumeDiagram';

const ScreenerVolumeItem = ({
  value, item, volumesHistoryData
}) => {
  const volumes = volumesHistoryData.slice(0, 4).reverse();
  const priceChange = volumesHistoryData.slice(5, 9).reverse();

  return (
    <div className={`${styles.itemWrapper} ${styles.volumeItem} ${styles.itemNumberWrapper}`}>
      <span>
        {value}
      </span>
      <VolumeDiagram
        volumesHistory={volumes}
        priceChangesHistory={priceChange}
        currentVolume={item}
        currentPriceChange={volumesHistoryData[4]}
      />
    </div>
  );
};

ScreenerVolumeItem.propTypes = {
  colorSettings: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  size: PropTypes.shape({ minWidth: PropTypes.number }),
  item: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.shape({})]),
  volumesHistoryData: PropTypes.arrayOf(PropTypes.number)
};

ScreenerVolumeItem.defaultProps = {
  colorSettings: {},
  size: {},
  volumesHistoryData: [],
  item: null,
  value: null
};

export default ScreenerVolumeItem;
