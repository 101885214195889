import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/WatchListModals.module.scss';
import Icon from '../Icon/Icon';
import { IMG_PATH } from '../../constants/screener';
import TextField from '../TextField/TextField';

const DeleteModalListSymbols = ({ selectedSymbols, symbolsList }) => (
  <div className={styles.symbolsList}>
    {selectedSymbols.map((item, index) => {
      const currentItem = symbolsList.find((subItem) => subItem.index === item);

      return (
        <div key={[item, index].join('_')} className={styles.symbolsListItem}>
          <Icon icon={`${IMG_PATH}${currentItem?.icon}`} iconClass={styles.symbolIcon} />
          <TextField text={currentItem?.sym} styleType="symbolTitle" />
        </div>
      );
    })}
  </div>
);

DeleteModalListSymbols.propTypes = {
  selectedSymbols: PropTypes.arrayOf(PropTypes.number).isRequired,
  symbolsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default DeleteModalListSymbols;
