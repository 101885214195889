export default {
  dataPanelAddCategory: 'Add Category',
  searchPlaceholder: 'Search Data Points',
  emptySearch: 'Data Point',
  addCategoryHeader: 'Add new category to Data Panel',
  renameCategoryHeader: 'Rename category of Data Panel',
  modalInputLabel: 'Category Name',
  modalInputPlaceholder: 'Enter category name',
  removeCategoryContent: 'Are you sure want to delete\n',
  removeCategory: 'category?',
  restoreCategoryContent: 'Are you sure want to\nrestore default?',
  restoreToDefault: 'Restore Default',
  dataPanelSuccess: 'Data Panel successfully saved',
  dataPanelSuccessTitle: 'Saved Successfully',
  dataPanelRestoreTitle: 'Restored Successfully',
  dataPanelSuccessRestore: 'Data Panel successfully restored',
  dataPanelModalInputError: 'Category name is required',
  dataPanelModalInputExist: 'Category name is already exist',
  maxCategoryErrorTitle: 'Maximum number of categories reached',
  maxCategoryError: 'You cannot add more than {{max}} categories',
  maxDatapointsErrorTitle: 'Maximum number of points in category reached',
  maxDatapointsError: 'You cannot add more than {{max}} points in category',
  maxCategoryName: 'Category name cannot be longer than {{max}} characters',
  maxCategoryNameTitle: 'Maximum category name length reached',
  emptyCategoryName: 'Category name cannot be empty',
  emptyCategoryNameTitle: 'Category name is required',
  dataPanelModalInputLengthError: 'Category name cannot be longer than {{max}} characters',
  categoryUniqueError: 'Category name must be unique',
  categoryUniqueErrorTitle: 'Category unique name error',
  dataPanelSuccessRemove: 'Category successfully removed',
  dataPanelSuccessRemoveTitle: 'Removed Successfully',
  dataPanelSuccessDelete: 'Data Panel successfully deleted',
  Deepvue: 'Deepvue',
  category: 'category',
  categories: 'categories',
  dataPanels: 'Data Panels',
  dataPanel: 'Data Panel',
  dataPanelsAllSmall: 'data panels',
  savedDataPanels: 'Saved Data Panels',
  presetsDataPanels: 'Data Panels Presets',
  searchByDataPanels: 'Search by data panels...',
  searchByDataPanelsPresets: 'Search by data panels presets...',
  dataPanelName: 'Data Panel name',
  dataPanelCreated: 'Data Panel created!',
  createDataPanel: 'Create Data Panel',
  saveDataPanel: 'Save Data Panel',
  createDataPanelPreset: 'Create Data Panel Preset',
  emptyDataPanelsTitle: 'Data Panel is empty',
  emptyAddNewCategory: 'Click on the button below to add your first category',
  addNewCategoryButton: 'Add category',

};
