import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../sass/PickColumnWrapper.module.scss';
import ColumnsListGroup from './ColumnsListGroup';
import ColumnItem from './ColumnsListItem';
import { allowColumns } from '../../../../constants/account';

const ColumnsListItems = ({
  data,
  columnsSelectedHandler,
  currentGroup,
  setCurrentGroup,
  disabled,
  match,
  openedGroupHandler,
  openedGroup,
}) => (
  <div className={styles.itemGroupsWrapper}>
    {data.map((groupItem, index) => (
      groupItem.items.length ? (
        <ColumnsListGroup
          key={[groupItem.groupName, index].join('_')}
          title={groupItem.groupName}
          setCurrentGroup={setCurrentGroup}
          searchActiveGroup={currentGroup === groupItem.groupId}
          disabled={disabled && !allowColumns.includes(groupItem.groupName)}
          mainCounter={groupItem?.items.length}
          groupId={groupItem.groupId}
          openedGroupHandler={openedGroupHandler}
        >
          <>
            {openedGroup.includes(groupItem.groupId) && groupItem.items.map((columnItem) => {
              return (
                <ColumnItem
                  checkStatus={match(columnItem.id, columnItem.group)}
                  columnsSelectedHandler={columnsSelectedHandler}
                  key={columnItem.id}
                  item={columnItem}
                  disabled={disabled && !allowColumns.includes(groupItem.groupName)}
                />
              );
            })}
          </>
        </ColumnsListGroup>
      ) : null
    ))}
  </div>
);

ColumnsListItems.defaultProps = {
  currentGroup: -1,
  disabled: false,
};

ColumnsListItems.propTypes = {
  setCurrentGroup: PropTypes.func.isRequired,
  openedGroupHandler: PropTypes.func.isRequired,
  currentGroup: PropTypes.number,
  columnsSelectedHandler: PropTypes.func.isRequired,
  openedGroup: PropTypes.arrayOf(PropTypes.number).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    groupId: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.shape({})),
  })).isRequired,
  disabled: PropTypes.bool,
  match: PropTypes.func.isRequired,
};
export default ColumnsListItems;
