import { CustomIndicator, FilledAreaType, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';

export default function rmv(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Relative Measured Volatility (RMV)',
    metainfo: {
      id: 'TL-dvrmv@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Relative Measured Volatility (RMV)',
      description: 'DV - Relative Measured Volatility (RMV)',
      is_price_study: false,
      isCustomIndicator: true,
      format: {
        type: 'inherit',
      },
      plots: [{
        id: 'plot_0',
        type: 'line' as StudyPlotType.Line,
      }],
      bands: [{
        id: 'hline_0',
        isHidden: false,
        name: 'Level 1'
      }, {
        id: 'hline_1',
        isHidden: false,
        name: 'Level 2'
      }, {
        id: 'hline_2',
        isHidden: false,
        name: 'Level 3'
      }, {
        id: 'hline_3',
        isHidden: false,
        name: 'Level 4'
      }],
      filledAreas: [{
        fillgaps: false,
        id: 'fill_0',
        isHidden: false,
        objAId: 'hline_0',
        objBId: 'hline_1',
        title: 'Zone 1',
        type: 'hline_hline' as FilledAreaType.TypeHlines
      }, {
        fillgaps: false,
        id: 'fill_1',
        isHidden: false,
        objAId: 'hline_1',
        objBId: 'hline_2',
        title: 'Zone 2',
        type: 'hline_hline' as FilledAreaType.TypeHlines
      }, {
        fillgaps: false,
        id: 'fill_2',
        isHidden: false,
        objAId: 'hline_2',
        objBId: 'hline_3',
        title: 'Zone 3',
        type: 'hline_hline' as FilledAreaType.TypeHlines
      }],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Plot'
        }
      },
      inputs: [
        {
          id: 'lookback_period0',
          name: 'Lookback Period',
          type: 'integer' as StudyInputType.Integer,
          defval: 15,
        },
//        {
//          id: 'lookback_period1',
//          name: 'Loopback Period 1',
//          type: 'integer' as StudyInputType.Integer,
//          defval: 3,
//        }
      ],
      defaults: {
        inputs: {
          lookback_period0: 15,
          // lookback_period1: 3,
        },
        bands: [{
          color: '#808080',
          linestyle: 2,
          linewidth: 1,
          value: 15,
          visible: true
        }, {
          color: '#808080',
          linestyle: 2,
          linewidth: 1,
          value: 10,
          visible: true
        }, {
          color: '#808080',
          linestyle: 2,
          linewidth: 1,
          value: 5,
          visible: true
        }, {
          color: '#808080',
          linestyle: 2,
          linewidth: 1,
          value: 0,
          visible: true
        }],
        filledAreasStyle: {
          fill_0: {
            color: '#00897B',
            transparency: 90,
            visible: true
          },
          fill_1: {
            color: '#4CAF50',
            transparency: 90,
            visible: true
          },
          fill_2: {
            color: '#9C27B0',
            transparency: 90,
            visible: true
          }
        },
        styles: {
          plot_0: {
            display: 15,
            color: '#2196F3',
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          }
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;

        const [lookback_period0] = getInputArray({ inputs, length: 2 });

        // lookback_period = 2 // lookback period to calculate the volatility of the past 2 bars
        // Calculate the highest and lowest of the past three bar highs,closes,lows
        const highest_high = mPineJS.Std.highest(this._context.new_var(mPineJS.Std.high(this._context)), 2, this._context);
        const lowest_high = mPineJS.Std.lowest(this._context.new_var(mPineJS.Std.high(this._context)), 2, this._context);

        const highest_close = mPineJS.Std.highest(this._context.new_var(mPineJS.Std.close(this._context)), 2, this._context);
        const lowest_close = mPineJS.Std.lowest(this._context.new_var(mPineJS.Std.close(this._context)), 2, this._context);

        const highest_low = mPineJS.Std.highest(this._context.new_var(mPineJS.Std.low(this._context)), 2, this._context);
        const lowest_low = mPineJS.Std.lowest(this._context.new_var(mPineJS.Std.low(this._context)), 2, this._context);

        // Calculate the percent change between each of these highs and lows
        const percent_changeH = ((highest_high - lowest_high) / lowest_close) * 100 // percent change between highs
        const percent_changeC = ((highest_close - lowest_close) / lowest_close) * 100 // percent changes between closes
        const percent_changeL = ((highest_low - lowest_low) / lowest_low) * 100 // percent changes between lows

        const RMV1 = (percent_changeH + (1.5 * percent_changeC) + percent_changeL) / 3 // average percent changes

        const highest_high1 = mPineJS.Std.highest(this._context.new_var(mPineJS.Std.high(this._context)), 3, this._context);
        const lowest_high1 = mPineJS.Std.lowest(this._context.new_var(mPineJS.Std.high(this._context)), 3, this._context);

        const highest_close1 = mPineJS.Std.highest(this._context.new_var(mPineJS.Std.close(this._context)), 3, this._context);
        const lowest_close1 = mPineJS.Std.lowest(this._context.new_var(mPineJS.Std.close(this._context)), 3, this._context);

        // Calculate the percent change
        const percent_changeH1 = ((highest_high1 - lowest_high1) / lowest_close1) * 100;
        const percent_changeC1 = 1.5 * ((highest_close1 - lowest_close1) / lowest_close1) * 100;

        const RMV2 = (percent_changeH1 + percent_changeC1) / 2
        const RMVt = (3 * RMV1 + RMV2) / 4 //calculate the overall volatillity and weight the first higher

        const hs = mPineJS.Std.highest(this._context.new_var(RMVt), lookback_period0, this._context); //get highest volatility in the period
        const ls = mPineJS.Std.lowest(this._context.new_var(RMVt), lookback_period0, this._context); // get lowest volatility in the period

        const RMVstoc = ((RMVt - ls) / (hs - ls)) * 100 // calculate the stochastic of the volatility ove rthe range - this is the final plotted value

        return [
          RMVstoc
        ];
      };
    }
  };
}
