import { shallowEqual, useSelector } from 'react-redux';
import { NEW_SCREEN_DATA, TABLE_SCREEN_PATH } from '../../../constants/screener';
import RootStateTypes from '../../../store/RootStateTypes';
import { IUsePlaceData } from '../types/interfaces';

const usePlaceData = (): IUsePlaceData => {
  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);
  const pagePath = useSelector((state: RootStateTypes) => state.screenerState.pagePath, shallowEqual);

  const isScreener = pagePath === TABLE_SCREEN_PATH.SCREENER;
  const isWatchlist = pagePath !== TABLE_SCREEN_PATH.SCREENER;
  const isEmptyScreener = isScreener && !Object.keys(selectedScreen)?.length;
  const isSelectedScreen = isScreener && selectedScreen && Object.keys(selectedScreen).length
    && selectedScreen.type === NEW_SCREEN_DATA.SCREENER;
  const isSelectedPreset = isScreener && selectedScreen && Object.keys(selectedScreen).length
    && selectedScreen.type === NEW_SCREEN_DATA.PRESETS;
  const isDeepList = isWatchlist && selectedTab && Object.keys(selectedTab).length;

  return {
    isEmptyScreener, isSelectedScreen, isSelectedPreset, isDeepList
  };
};

export default usePlaceData;
