import React from 'react';
import PropTypes from 'prop-types';

import styles from '../sass/DataPanel.module.scss';
import DefaultButton from '../../DefaultButton/DefaultButton';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { OPEN_DATA_PANEL } from '../../../constants/tvWidgetOptions';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close_dlack.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';

const DataPanelHeaderActions = ({
  togglePanelAdd, editMode, setEditMode, isEditAllowed
}) => {
  const { updateStoreHandler } = useLastStateUpdateStore();

  const handleCloseDataPanel = () => {
    updateStoreHandler(OPEN_DATA_PANEL, false);
    setEditMode(false);
  };

  return (
    <div className={styles.dataPanelHeaderActions}>
      {
        !editMode && isEditAllowed && (
          <DefaultButton
            handleClick={togglePanelAdd}
            className={`${styles.dataPanelHeaderActionsButton} ${styles.dataPanelEditActionsButton}
          ${editMode && styles.checked}`}
          >
            <FilterIcon />
          </DefaultButton>
        )
      }
      <DefaultButton
        handleClick={handleCloseDataPanel}
        className={`${styles.dataPanelHeaderActionsButton} ${styles.dataPanelCloseActionsButton}`}
      >
        {<CloseIcon />}
      </DefaultButton>
    </div>
  );
};

DataPanelHeaderActions.propTypes = {
  togglePanelAdd: PropTypes.func,
  editMode: PropTypes.bool,
  isEditAllowed: PropTypes.bool,
  setEditMode: PropTypes.func,
};

DataPanelHeaderActions.defaultProps = {
  togglePanelAdd: () => undefined,
  editMode: false,
  isEditAllowed: true,
  setEditMode: () => undefined,
};

export default DataPanelHeaderActions;
