import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/WatchListModals.module.scss';
import Icon from '../Icon/Icon';
import { ALLOWED_TITLE_SEARCH_LENGTH, IMG_PATH } from '../../constants/screener';
import TextField from '../TextField/TextField';
import Checkbox from '../Checkbox/Checkbox';

const SearchDropDownItem = React.forwardRef(({
  item, setSelectedListHandler, selectedList, lastElement
}, ref) => {
  const [viewTool, setViewTool] = useState(false);

  const onMouseLeaveItem = () => {
    setViewTool(false);
  };

  const onMouseEnterItem = () => {
    setViewTool(true);
  };

  const preparedItem = { sym: item.sym, index: item.index };
  const isSelected = () => selectedList.findIndex((subItem) => subItem.sym === preparedItem.sym) !== -1;

  return (
    <div
      ref={ref}
      className={styles.resultItem}
      aria-hidden="true"
      onClick={() => {
        setSelectedListHandler(preparedItem);
      }}
    >
      <Checkbox checked={isSelected()} id={`${item.sym}`} />
      <Icon
        icon={item.icon ? `${IMG_PATH}${item.icon}` : `${IMG_PATH}no_icon.png`}
        iconClass={styles.symbolImg}
      />
      <div
        className={styles.resultItemTextWrapper}
        onMouseLeave={onMouseLeaveItem}
        onMouseEnter={onMouseEnterItem}
      >
        <TextField text={`${item.sym} - `} styleType="resultItem" />
        <TextField
          text={item.name.length <= ALLOWED_TITLE_SEARCH_LENGTH
            ? item.name : `${item.name.slice(0, ALLOWED_TITLE_SEARCH_LENGTH)}...`}
          styleType="resultItemName"
        />
      </div>
      {viewTool && item.name.length > ALLOWED_TITLE_SEARCH_LENGTH && (
        <div className={`${styles.toolDropDown} ${lastElement && styles.toolTop}`}>{item.name}</div>
      )}

    </div>
  );
});

SearchDropDownItem.propTypes = {
  lastElement: PropTypes.bool,
  selectedList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedListHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    icon: PropTypes.string,
    name: PropTypes.string,
    sym: PropTypes.string,
    index: PropTypes.number
  }),
};

SearchDropDownItem.defaultProps = {
  lastElement: false,
  item: {}
};

export default SearchDropDownItem;
