import { StudyInputType } from "../../../charting_library/charting_library";

export const defaultInputs = {
  vol_ma_daily: 50,
  vol_ma_weekly: 10,
  vol_ma_monthly: 10,
  truncateVolume: false,
  previousClose: false,
};

export const inputs = [
  {
    id: 'vol_ma_daily',
    name: 'Daily Averages',
    type: 'integer' as StudyInputType.Integer,
    defval: 50,
    group: 'Volume Moving Average Settings',
  },
  {
    id: 'vol_ma_weekly',
    name: 'Weekly Averages',
    type: 'integer' as StudyInputType.Integer,
    defval: 10,
    group: 'Volume Moving Average Settings',
  },
  {
    id: 'vol_ma_monthly',
    name: 'Monthly Averages',
    type: 'integer' as StudyInputType.Integer,
    defval: 10,
    group: 'Volume Moving Average Settings',
  },
  {
    id: 'truncateVolume',
    name: 'Truncate Volume',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Options',
  },
  {
    id: 'previousClose',
    name: 'Color bars based on previous close',
    type: 'bool' as StudyInputType.Bool,
    defval: false,
    group: 'Options',
  }
];
