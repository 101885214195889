import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../Button/Button';

import stylesTargetList from './sass/TargeList.module.scss';
import TargetListIcon from '../../../../assets/images/icons/targetList/TargetListIcon';
import { ReactComponent as Empty } from '../../../../assets/images/icons/targetList/empty.svg';

const TargetListDropdownItem = ({
  id,
  addSymbolToTargetList,
  className,
  colorStart,
  colorEnd
}) => {
  return (
    <Button
      buttonClass={stylesTargetList.targetItem}
      handleClick={addSymbolToTargetList}
    >
      <div
        className={className}
      >
        {(id && colorStart && colorEnd) ? (
          <TargetListIcon
            colorStart={colorStart}
          />
        ) : (
          <Empty />
        )}
      </div>
    </Button>
  );
};

TargetListDropdownItem.propTypes = {
  id: PropTypes.number,
  addSymbolToTargetList: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  colorStart: PropTypes.string,
  colorEnd: PropTypes.string
};

TargetListDropdownItem.defaultProps = {
  id: null,
  colorStart: null,
  colorEnd: null
};

export default TargetListDropdownItem;
