import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyPlotType } from '../../charting_library/charting_library';

interface Flags {
  stage_1a_flag: boolean;
  stage_1b_flag: boolean;
  stage_2a_flag: boolean;
  stage_2b_flag: boolean;
  stage_3a_flag: boolean;
  stage_3b_flag: boolean;
  stage_4a_flag: boolean;
  stage_4b_flag: boolean;
}

const flagIndices: Record<keyof Flags, number> = {
  stage_1a_flag: 0,
  stage_1b_flag: 1,
  stage_2a_flag: 2,
  stage_2b_flag: 3,
  stage_3a_flag: 4,
  stage_3b_flag: 5,
  stage_4a_flag: 6,
  stage_4b_flag: 7,
};

export default function stageAnalysisV2(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Stage Analysis',
    metainfo: {
      format: {
        type: 'inherit'
      },
      defaults: {
        palettes: {
          stage_analysis_bg_palette: {
            colors: [
              { color: '#EDEDED' },
              { color: '#E2E2E5' },
              { color: '#CCFDE6' },
              { color: '#99FBCD' },
              { color: '#FFFEDC' },
              { color: '#FEFDBA' },
              { color: '#FEBBBB' },
              { color: '#FFDDDD' },
            ],
          },
        },
        precision: 2,
        styles: {
          stage_analysis_bg: {
            transparency: 50
          },
        }
      },
      description: 'DV - Stage Analysis',
      id: 'TL-SAv2@tv-basicstudies-1' as RawStudyMetaInfoId,
      inputs: [], // Required, even if empty
      isCustomIndicator: true,
      is_price_study: true,
      palettes: {
        stage_analysis_bg_palette: {
          colors: [
            {
              name: 'Stage 1A',
            },
            {
              name: 'Stage 1',
            },
            {
              name: 'Stage 2A',
            },
            {
              name: 'Stage 2',
            },
            {
              name: 'Stage 3A',
            },
            {
              name: 'Stage 3',
            },
            {
              name: 'Stage 4',
            },
            {
              name: 'Stage 4B-',
            },
          ],
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            6: 6,
            7: 7,
          },
        }
      },
      plots: [
        {
          id: 'stage_analysis_bg',
          palette: 'stage_analysis_bg_palette',
          type: 'bg_colorer' as StudyPlotType.BgColorer,
        },
      ],
      styles: {
        stage_analysis_bg: {
          title: 'Stage',
        },
      },
      shortDescription: 'DV - Stage Analysis'
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
        this._context.new_sym(mPineJS.Std.ticker(this._context), 'W');

        // Flags
        this.init_flag = true
        this.stage_1a_flag = false
        this.stage_1b_flag = false
        this.stage_2a_flag = false
        this.stage_2b_flag = false
        this.stage_3a_flag = false
        this.stage_3b_flag = false
        this.stage_4a_flag = false
        this.stage_4b_flag = false

        this.stage_4_low = 1000000000.0

        this.stage_1a_flag_count = 0
        this.stage_2a_flag_count = 0

      };
      this.main = (context) => {
        this._context = context;
        // context.select_sym(1);
        // Using weekly closes across the mas fixes the issue above

        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_unlimited_var(this._context.symbol.time);
        // Select the secondary symbol
        this._context.select_sym(1);
        const secondarySymbolTime = this._context.new_unlimited_var(this._context.symbol.time);

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolClose = this._context.new_unlimited_var(mPineJS.Std.close(this._context));
        const weekly_close = secondarySymbolClose.adopt(secondarySymbolTime, mainSymbolTime, 1);

        this._context.select_sym(0);


        const close = mPineJS.Std.close(this._context);
        const low = mPineJS.Std.low(this._context);
        const closeSeries = context.new_unlimited_var(close);
        const ma_50 = mPineJS.Std.isdaily(context)
          ? mPineJS.Std.sma(closeSeries, 50, this._context)
          : mPineJS.Std.isweekly(this._context) ? mPineJS.Std.sma(closeSeries, 10, this._context)
            : 0;

        const ma_150 = mPineJS.Std.isdaily(context)
          ? mPineJS.Std.sma(closeSeries, 150, this._context)
          : mPineJS.Std.isweekly(this._context) ? mPineJS.Std.sma(closeSeries, 30, this._context)
            : 0;
        const ma_200 = mPineJS.Std.isdaily(context)
          ? mPineJS.Std.sma(closeSeries, 200, this._context)
          : mPineJS.Std.isweekly(this._context) ? mPineJS.Std.sma(closeSeries, 40, this._context)
            : 0;

        // Stage 1a:
        if ((this.init_flag || this.stage_1a_flag || this.stage_4a_flag || this.stage_4b_flag) && close > ma_50 && close > ma_150 && close < ma_200) {
          this.init_flag = false;
          this.stage_1a_flag = true;
          this.stage_1b_flag = false;
          this.stage_2a_flag = false;
          this.stage_2b_flag = false;
          this.stage_3a_flag = false;
          this.stage_3b_flag = false;
          this.stage_4a_flag = false;
          this.stage_4b_flag = false;
          this.stage_1a_flag_count = this.stage_1a_flag_count + 1
          this.stage_2a_flag_count = 0
        }

        // Stage 1b:
        if ((this.init_flag || this.stage_1b_flag || this.stage_1a_flag) && this.stage_1a_flag_count >= 10) {
          this.init_flag = false
          this.stage_1a_flag = false
          this.stage_1b_flag = true
          this.stage_2a_flag = false
          this.stage_2b_flag = false
          this.stage_3a_flag = false
          this.stage_3b_flag = false
          this.stage_4a_flag = false
          this.stage_4b_flag = false

          this.stage_1a_flag_count = 0
          this.stage_2a_flag_count = 0
        }

        // Stage 2a:
        if ((this.init_flag || this.stage_2a_flag || this.stage_1a_flag || this.stage_1b_flag || this.stage_3a_flag || this.stage_3b_flag || this.stage_4a_flag || this.stage_4b_flag) && close > ma_50 && close > ma_150 && close > ma_200) {
          this.init_flag = false
          this.stage_1a_flag = false
          this.stage_1b_flag = false
          this.stage_2a_flag = true
          this.stage_2b_flag = false
          this.stage_3a_flag = false
          this.stage_3b_flag = false
          this.stage_4a_flag = false
          this.stage_4b_flag = false

          this.stage_1a_flag_count = 0
          this.stage_2a_flag_count = this.stage_2a_flag_count + 1
          this.stage_4_low = 1000000000.0
        }

        // Stage 2b:
        if ((this.init_flag || this.stage_2b_flag || this.stage_2a_flag) && this.stage_2a_flag_count >= 10) {
          this.init_flag = false
          this.stage_1a_flag = false
          this.stage_1b_flag = false
          this.stage_2a_flag = false
          this.stage_2b_flag = true
          this.stage_3a_flag = false
          this.stage_3b_flag = false
          this.stage_4a_flag = false
          this.stage_4b_flag = false
          this.stage_1a_flag_count = 0
        }

        // Stage 3a:
        if ((this.init_flag || this.stage_3a_flag || this.stage_2a_flag || this.stage_2b_flag) && weekly_close < ma_50 && weekly_close > ma_150 && weekly_close > ma_200) {
          this.init_flag = false
          this.stage_1a_flag = false
          this.stage_1b_flag = false
          this.stage_2a_flag = false
          this.stage_2b_flag = false
          this.stage_3a_flag = true
          this.stage_3b_flag = false
          this.stage_4a_flag = false
          this.stage_4b_flag = false

          this.stage_1a_flag_count = 0
          this.stage_2a_flag_count = 0

        }

        // Stage 3b:
        if ((this.init_flag || this.stage_3b_flag || this.stage_3a_flag) && close < ma_50 && close < ma_150 && close > ma_200) {
          this.init_flag = false
          this.stage_1a_flag = false
          this.stage_1b_flag = false
          this.stage_2a_flag = false
          this.stage_2b_flag = false
          this.stage_3a_flag = false
          this.stage_3b_flag = true
          this.stage_4a_flag = false
          this.stage_4b_flag = false

          this.stage_1a_flag_count = 0
          this.stage_2a_flag_count = 0
        }

        // Stage 4a:
        if ((this.init_flag || this.stage_4a_flag || this.stage_1a_flag || this.stage_1b_flag || this.stage_2a_flag || this.stage_2b_flag || this.stage_3a_flag || this.stage_3b_flag || this.stage_4b_flag) && close < ma_50 && close < ma_150 && close < ma_200 && close <= this.stage_4_low) {
          this.init_flag = false
          this.stage_1a_flag = false
          this.stage_1b_flag = false
          this.stage_2a_flag = false
          this.stage_2b_flag = false
          this.stage_3a_flag = false
          this.stage_3b_flag = false
          this.stage_4a_flag = true
          this.stage_4b_flag = false
          this.stage_1a_flag_count = 0
          this.stage_2a_flag_count = 0

          this.stage_4_low = Math.min(this.stage_4_low, low);
        }

        if (this.stage_4a_flag) {
          this.stage_4_low = Math.min(this.stage_4_low, low)
        }

        // Stage 4b:
        if ((this.init_flag || this.stage_4b_flag || this.stage_4a_flag) && this.stage_4a_flag && close > ma_50 && close < ma_150 && close < ma_200) {
          this.init_flag = false;
          this.stage_1a_flag = false;
          this.stage_1b_flag = false;
          this.stage_2a_flag = false;
          this.stage_2b_flag = false;
          this.stage_3a_flag = false;
          this.stage_3b_flag = false;
          this.stage_4a_flag = false;
          this.stage_4b_flag = true;
          this.stage_1a_flag_count = 0
          this.stage_2a_flag_count = 0
        }

        let checkIndex: number = flagIndices.stage_1a_flag;
        for (const flag in flagIndices) {
          if (this[flag as keyof Flags]) {
            checkIndex = flagIndices[flag as keyof Flags];
            break;
          }
        }

        return [
          checkIndex
        ];
      };
    }
  };
}
