import { BAR_CHART_COLORS } from '../types/WidgetChartsEnums';

const getHeatMapColor = (value, singleBlockRange) => {
  if ((value > (singleBlockRange * 2))) {
    return BAR_CHART_COLORS.dark_Blue;
  }
  if ((value > singleBlockRange) && value <= (singleBlockRange * 2)) {
    return BAR_CHART_COLORS.medium_Blue;
  }
  if (value > 0 && value <= singleBlockRange) {
    return BAR_CHART_COLORS.light_Blue;
  }
  if (value < 0 && value >= (singleBlockRange * -1)) {
    return BAR_CHART_COLORS.light_Pink;
  }
  if ((value < (singleBlockRange * -1)) && value >= (singleBlockRange * -2)) {
    return BAR_CHART_COLORS.medium_Pink;
  }
  if (value < (singleBlockRange * -2)) {
    return BAR_CHART_COLORS.dark_Pink;
  }
  return BAR_CHART_COLORS.nuetral;
};

export default getHeatMapColor;
