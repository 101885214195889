import React from 'react';
import styles from '../sass/WidgetCharts.module.scss';
import { ReactComponent as IconArrowRight } from '../../../assets/images/icons/chevronDown.svg';
import { IScreenerList, IScreenerSearch } from '../types/WidgetChartsInterfaces';
import { TScreenListItem } from '../types/WidgetChartsypes';
import { ScreenItemData } from '../../../utils/Types';
import { FOLDER_ITEM } from '../types/WidgetChartsEnums';

const ScreenerList = ({
  setScreenerStates,
  selectedType,
  screenerStates,
  screenerSearch,
}: IScreenerList): React.ReactElement => {
  const handleClick = (item: TScreenListItem | ScreenItemData) => {
    if (item.type !== 'folder') {
      setScreenerStates((prev) => ({
        ...prev,
        screenerIsOpen: false,
        dataPointsXIsOpen: false,
        folderMenuIsOpen: -1,
        selectedScreenerTitle: item?.title,
        filtersData: JSON.stringify(item?.data?.filters),
        screenerId: item?.id
      }));
    }
  };

  return (
    <div className={styles.screenerItemsRight}>
      <div className={styles.itemsWrapper}>
        {screenerStates.searchText && screenerSearch.map((mainItem: IScreenerSearch) => mainItem.data.length > 0 && (
          <React.Fragment key={mainItem.title}>
            <span>
              {mainItem.title}
            </span>
            {mainItem.data.map(((item: ScreenItemData, index: number) => (
              <div
                className={styles.singleItem}
                role="button"
                tabIndex={0}
                onMouseDown={() => handleClick(item)}
                key={item.id}
              >
                <span
                  onMouseEnter={() => setScreenerStates((prev) => ({
                    ...prev,
                    folderMenuIsOpen: (item.type === FOLDER_ITEM && item.items.length > 0) ? index : -1,
                    selectedSearchListTitle: (item.type === FOLDER_ITEM && item.items.length > 0) ? mainItem.title : '',
                  }))}
                  key={item.id}
                >
                  {item.title}
                </span>
                {item.type === FOLDER_ITEM && item.items.length > 0 && <IconArrowRight />}
              </div>
            )))}
          </React.Fragment>
        ))}
        {!screenerStates.searchText
          && screenerStates.selectedScreener
          && selectedType.map(((item: TScreenListItem, index: number) => (
            <div
              className={styles.singleItem}
              role="button"
              tabIndex={0}
              onMouseDown={() => handleClick(item)}
              key={item.id}
            >
              <span
                onMouseEnter={() => setScreenerStates((prev) => ({
                  ...prev,
                  folderMenuIsOpen: (item.type === FOLDER_ITEM && item.items.length > 0) ? index : -1,
                }))}
                key={item.id}
              >
                {item.title}
              </span>
              {item.type === FOLDER_ITEM && item.items.length > 0 && <IconArrowRight />}
            </div>
          )))}
      </div>
    </div>
  );
};

export default ScreenerList;
