import { shallowEqual, useSelector } from 'react-redux';
import useLastStateUpdateStore from './useLastStateUpdateStore';
import { ALERTS_SYMBOL, DL_SYMBOL, SCREENER_SYMBOL } from '../../constants/storage';

const useLastSymbolState = () => {
  const { updateStoreHandler } = useLastStateUpdateStore();
  const industryOfSymbols = useSelector((state) => state.tableDataState.industryOfSymbols, shallowEqual);
  const searchValue = useSelector((state) => state.screenerState.searchValue, shallowEqual);
  
  const lastScreenerSymbol = useSelector(
    (state) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual
  );
  const lastDeepListSymbol = useSelector(
    (state) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual
  );
  const lastAlertsSymbol = useSelector(
    (state) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual
  );
  const updateLastSymbolState = (
    row, keyboard = false, isDeepList = false, isAlerts = false, index
  ) => {
    const symbolIndex = keyboard ? row.index : row[0].index;
  
    if (!isDeepList && !isAlerts) {
      updateStoreHandler(SCREENER_SYMBOL, {
        name: keyboard ? row.sym : row[0].sym,
        index: keyboard ? row.sortIndex : row[0].sortIndex,
        position: index,
        industry: industryOfSymbols.find((item) => item?.index === symbolIndex)?.industry
          || (Object.keys(searchValue).length && industryOfSymbols[0].industry) || lastScreenerSymbol?.industry,
        sector: industryOfSymbols.find((item) => item?.index === symbolIndex)?.sector
          || (Object.keys(searchValue).length && industryOfSymbols[0].sector) || lastScreenerSymbol?.sector,
        group: industryOfSymbols.find((item) => item?.index === symbolIndex)?.group
          || (Object.keys(searchValue).length && industryOfSymbols[0].group) || lastScreenerSymbol?.group,
        subIndustry: industryOfSymbols.find((item) => item?.index === symbolIndex)?.subIndustry
          || (Object.keys(searchValue).length && industryOfSymbols[0].subIndustry) || lastScreenerSymbol?.subIndustry
      });
    }

    if (isDeepList && !isAlerts) {
      updateStoreHandler(DL_SYMBOL, {
        name: keyboard ? row.sym : row[0].sym,
        index: keyboard ? row.sortIndex : row[0].sortIndex,
        position: index,
        industry: industryOfSymbols.find((item) => item?.index === symbolIndex)?.industry
          || (Object.keys(searchValue).length && industryOfSymbols[0].industry)
          || lastDeepListSymbol?.industry,
        sector: industryOfSymbols.find((item) => item?.index === symbolIndex)?.sector
          || (Object.keys(searchValue).length && industryOfSymbols[0].sector) || lastDeepListSymbol?.sector,
        group: industryOfSymbols.find((item) => item?.index === symbolIndex)?.group
          || (Object.keys(searchValue).length && industryOfSymbols[0].group) || lastDeepListSymbol?.group,
        subIndustry: industryOfSymbols.find((item) => item?.index === symbolIndex)?.subIndustry
          || (Object.keys(searchValue).length && industryOfSymbols[0].subIndustry)
          || lastDeepListSymbol?.subIndustry
      });
    }

    if (isAlerts && row) {
      const sortIndex = keyboard ? row.sortIndex : row[1]?.sortIndex;
      updateStoreHandler(ALERTS_SYMBOL, {
        name: keyboard ? row.sym : row[1]?.sym,
        index: sortIndex,
        position: index,
        industry: industryOfSymbols.find((item) => item?.index === sortIndex)?.industry
          || lastAlertsSymbol?.industry,
        sector: industryOfSymbols.find((item) => item?.index === sortIndex)?.sector || lastAlertsSymbol?.sector,
        group: industryOfSymbols.find((item) => item?.index === sortIndex)?.group || lastAlertsSymbol?.group,
        subIndustry: industryOfSymbols.find((item) => item?.index === sortIndex)?.subIndustry
          || lastAlertsSymbol?.subIndustry
      });
    }
  };

  return { updateLastSymbolState };
};

export default useLastSymbolState;
