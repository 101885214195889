import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/AlertsList.module.scss';
import { ALERTS_TOAST_SETTING } from '../../../constants/screener';
import { roundedNumber } from '../../ChartContainer/utils/alertHelper';
import { DEFAULT_PRECISION } from '../../../constants/alerts';
import TextWithTooltip from '../../DataPanel/components/TextWithTooltip';

const ToastRelVol = ({ relVol }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.relVol}>
      <TextWithTooltip
        placement="bottom-end"
        isStatic
        title={t('relVol')}
        textTooltip={t('tooltipRelativeVolume')}
      />
      <span
        className={`${styles.relVolValue} 
          ${relVol > ALERTS_TOAST_SETTING.REL_VOL_RULE ? styles.grow : styles.loss}`}
      >
        {roundedNumber(relVol, DEFAULT_PRECISION)}
      </span>
    </div>
  );
};

ToastRelVol.propTypes = {
  relVol: PropTypes.number.isRequired
};
export default ToastRelVol;
