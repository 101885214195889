import moment from 'moment-timezone';

import { layoutStructureValidator } from '../../components/ChartContainer/utils/tradingViewHelper';
import { TEMPLATE_TYPE } from '../../constants/screener';
import SaveLoadAdapterHandlers from './saveLoadAdapterHandlers';
import { ACTIVE_CHART_LAYOUT_ID, LAST_UI_SETTINGS, MOBILE_ACTIVE_CHART_LAYOUT_ID } from '../../constants/storage';
import { getFromLocalStorage } from '../../utils/storageWorks';
import { DEFAULT_TIMEZONE } from '../../constants/account';
import { DASHBOARD_ITEM_EXISTS } from '../../constants/responseStatuses';
import { CHART_PROPERTIES } from '../../constants/tvWidgetOptions';

export const currentSymbol = { sym: '' };

const saveLoadAdapterNew = (
  errorHandler,
  updateStoreHandler,
  userLastState,
  setSymbol,
  setLayoutId,
  layoutsList,
  widgetId,
  saveChartLayoutId,
  defaultLayoutId,
  setUpdateInstance,
  successHandler
) => {
  const state = {
    currentChartId: userLastState?.activeChartLayoutId,
    charts: layoutsList.map((item) => item.content),
    studyTemplates: [],
    chartTemplates: [],
    drawingTemplates: [],
  };

  const getCurrentTimestamp = () => moment().tz(DEFAULT_TIMEZONE).valueOf() / 1000;

  const updateInstanceAfterRemoving = (cb) => {
    if (typeof cb === 'function') {
      cb(false);
      const timer = setTimeout(() => {
        cb(true);
        clearTimeout(timer);
      }, 100);
    }
  };

  const updateStoreSettingsAfterChartChange = (chartId) => {
    if (widgetId) return;
    updateStoreHandler(LAST_UI_SETTINGS, chartId ? 'true' : '');
    const layoutIdKey = getFromLocalStorage('isWebView') ? MOBILE_ACTIVE_CHART_LAYOUT_ID : ACTIVE_CHART_LAYOUT_ID;
    updateStoreHandler(layoutIdKey, chartId || 0);
  };

  const updateStoreWithNewChartContent = (content) => {
    if (widgetId) return;
    const layoutIdKey = getFromLocalStorage('isWebView') ? MOBILE_ACTIVE_CHART_LAYOUT_ID : ACTIVE_CHART_LAYOUT_ID;
    if (!userLastState[layoutIdKey]) {
      updateStoreHandler(LAST_UI_SETTINGS, '');
    }
    updateStoreHandler(CHART_PROPERTIES, content);
  };

  const replaceOrPushChart = (chart) => {
    const existingChartIndex = state.charts.findIndex((chartItem) => chartItem.id === chart.id);
    if (existingChartIndex !== -1) {
      state.charts[existingChartIndex] = chart;
    } else {
      state.charts.push(chart);
    }
  };

  const handleSaveOrUpdateChart = async (chart, isUpdate = false) => {
    try {
      JSON.parse(layoutStructureValidator(chart.content));
    } catch (e) {
      return Promise.reject(e);
    }

    try {
      const contentWithTimestamp = {
        ...JSON.parse(layoutStructureValidator(chart.content)),
        timestamp: getCurrentTimestamp()
      };

      const response = isUpdate
        ? await SaveLoadAdapterHandlers.updateChart(
          typeof chart.id === 'object' ? chart.id.data.id : chart.id,
          chart.name,
          TEMPLATE_TYPE.CHART_WIDGET,
          { content: contentWithTimestamp }
        )
        : await SaveLoadAdapterHandlers.saveChart(
          chart.name,
          TEMPLATE_TYPE.CHART_WIDGET,
          { content: contentWithTimestamp }
        );

      const newChart = { ...chart, id: response?.id };
      replaceOrPushChart(newChart);
      updateStoreSettingsAfterChartChange(response?.id);
      state.currentChartId = response?.id;

      return Promise.resolve(response?.data?.content);
    } catch (error) {
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        errorHandler(chart.name);
      }
      return Promise.reject(error);
    }
  };

  return {
    // Fetches all charts and updates state
    async getAllCharts() {
      try {
        const charts = await SaveLoadAdapterHandlers.getAllCharts();
        state.charts = charts;
        return charts;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // Saves a new chart or updates an existing one
    async saveChart(chart) {
      const existingChart = state.charts.find(
        (chartItem) => chartItem.id === chart.id
          || chartItem.id === chart.id?.data?.id
          || chartItem.name === chart.name
      );

      return handleSaveOrUpdateChart(chart, !!existingChart);
    },
    // Removes a chart by its ID
    async removeChart(id) {
      try {
        await SaveLoadAdapterHandlers.removeChart(id);
        const item = state.charts.find((chart) => chart.id === +id);

        // apply default after remove
        if (+state.currentChartId === +id) {
          updateInstanceAfterRemoving(setUpdateInstance);
          updateStoreSettingsAfterChartChange(defaultLayoutId);
        }

        if (+id !== defaultLayoutId) {
          state.charts = state.charts.filter((chart) => chart.id !== +id);
        }

        if (+id === defaultLayoutId) {
          return Promise.reject();
        }

        if (item) {
          successHandler(item?.name);
        }

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // Fetches and validates the content of a specific chart
    async getChartContent(id) {
      const chart = state.charts.find((chartItem) => chartItem.id === id);

      if (!widgetId) {
        setLayoutId(id);
        setSymbol(chart?.symbol);
      }
      if (saveChartLayoutId && typeof saveChartLayoutId === 'function' && widgetId) {
        saveChartLayoutId(id, widgetId);
      }

      if (chart) {
        const validatedContent = layoutStructureValidator(JSON.stringify(chart.content));
        updateStoreSettingsAfterChartChange(id);
        state.currentChartId = id;
        return Promise.resolve(validatedContent);
      }
      return Promise.reject();
    },

    // Fetches all study templates
    async getAllStudyTemplates() {
      try {
        const studyTemplates = await SaveLoadAdapterHandlers.getAllStudiesTemplate();
        state.studyTemplates = studyTemplates;

        return studyTemplates;
      } catch (error) {
        return Promise.reject(error);
      }
    },
    // Save update study templates
    async saveStudyTemplate(studyTemplateData) {
      const replaceItem = state.studyTemplates.find(
        (templateItem) => templateItem.name === studyTemplateData.name
      );

      const dataForSave = {
        timestamp: getCurrentTimestamp(),
        ...studyTemplateData
      };

      if (replaceItem && replaceItem.id) {
        await SaveLoadAdapterHandlers.updateChart(
          replaceItem.id,
          replaceItem.name,
          TEMPLATE_TYPE.CHART_TEMPLATE,
          dataForSave
        );
      } else {
        await SaveLoadAdapterHandlers.saveChart(
          studyTemplateData.name,
          TEMPLATE_TYPE.CHART_TEMPLATE,
          dataForSave
        );
        state.studyTemplates.push(studyTemplateData);
      }
    },
    // Get study templates content
    async getStudyTemplateContent(studyTemplateData) {
      const template = state.studyTemplates.find(
        (templateItem) => templateItem.name === studyTemplateData.name
      );

      if (template) {
        return template.content;
      }
      throw new Error('Template not found');
    },
    // Remove study templates
    async removeStudyTemplate(studyTemplateData) {
      const templateIndex = state.studyTemplates.findIndex(
        (templateItem) => templateItem.name === studyTemplateData.name
      );

      if (templateIndex !== -1) {
        await SaveLoadAdapterHandlers.removeChart(state.studyTemplates[templateIndex].id);
        state.studyTemplates.splice(templateIndex, 1);
      } else {
        throw new Error('Template not found');
      }
    },

    // Get all chart templates
    async getAllChartTemplates() {
      const response = await SaveLoadAdapterHandlers.getAllSettingsTemplate();
      state.chartTemplates = response;
      return response.map((subItem) => subItem.name);
    },
    // Save update chart templates
    async saveChartTemplate(templateName, content) {
      const theme = state.chartTemplates.find((templateItem) => templateItem.name === templateName);

      if (theme) {
        theme.content = content;
        await SaveLoadAdapterHandlers.updateChart(
          theme.id,
          theme.name,
          TEMPLATE_TYPE.CHART_SETTINGS,
          theme
        );
        updateStoreWithNewChartContent(content);
      } else {
        await SaveLoadAdapterHandlers.saveChart(
          templateName,
          TEMPLATE_TYPE.CHART_SETTINGS,
          { name: templateName, content }
        );
        state.chartTemplates.push({ name: templateName, content });
        updateStoreWithNewChartContent(content);
      }
    },
    // Remove chart templates
    async removeChartTemplate(templateName) {
      const currentTemplateIndex = state.chartTemplates.findIndex(
        (templateItem) => templateItem.name === templateName
      );

      if (currentTemplateIndex !== -1) {
        await SaveLoadAdapterHandlers.removeChart(state.chartTemplates[currentTemplateIndex].id);
        this.chartTemplates = state.chartTemplates.filter((_, index) => index !== currentTemplateIndex);
      } else {
        throw new Error('Template not found');
      }
    },
    // Get chart templates content
    async getChartTemplateContent(templateName) {
      const theme = {};
      const content = state.chartTemplates.find((templateItem) => templateItem.name === templateName)?.content;

      if (content) {
        theme.content = structuredClone(content);
        updateStoreWithNewChartContent(content);
      }

      return theme;
    },

    // Get all drawing templates
    async getDrawingTemplates() {
      const response = await SaveLoadAdapterHandlers.getAllDrawingTemplate();
      state.drawingTemplates = response;
      return response.map((subItem) => subItem.name);
    },
    // Save update drawing template
    async saveDrawingTemplate(toolName, templateName, content) {
      const existedItem = state.drawingTemplates.find((drawItem) => drawItem.name === templateName);

      if (existedItem) {
        existedItem.content = content;
        await SaveLoadAdapterHandlers.updateChart(
          existedItem.id,
          existedItem.name,
          TEMPLATE_TYPE.DRAWING_TEMPLATES,
          existedItem
        );
      } else {
        state.drawingTemplates.push({ name: templateName, content });
        try {
          await SaveLoadAdapterHandlers.saveChart(
            templateName,
            TEMPLATE_TYPE.DRAWING_TEMPLATES,
            { name: templateName, content }
          );
        } catch (error) {
          if (error.response?.data?.message === DASHBOARD_ITEM_EXISTS) {
            errorHandler(DASHBOARD_ITEM_EXISTS);
          } else {
            throw error;
          }
        }
      }
    },
    // Remove drawing template
    async removeDrawingTemplate(toolName, templateName) {
      const currentTemplateIndex = state.drawingTemplates.findIndex((drawItem) => drawItem.name === templateName);

      if (currentTemplateIndex !== -1) {
        await SaveLoadAdapterHandlers.removeChart(state.drawingTemplates[currentTemplateIndex].id);
        this.drawingTemplates = state.drawingTemplates.filter((_, index) => index !== currentTemplateIndex);
      } else {
        throw new Error('Template not found');
      }
    },
    // Get drawing template content
    async loadDrawingTemplate(toolName, templateName) {
      const template = state.drawingTemplates.find((drawItem) => drawItem.name === templateName)?.content;
      if (template) {
        return template;
      }
      throw new Error('Template not found');
    }
  };
};

export default saveLoadAdapterNew;
