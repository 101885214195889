import React from 'react';

import TextField from '../TextField/TextField';
import Input from '../Input/Input';
import { ReactComponent as SelectArrow } from '../../assets/images/icons/selectArrow.svg';
import styles from './sass/SelectGroupDropFilter.module.scss';
import ScrollDropFilter from './ScrollDropFilter';
import { TCommonItem } from '../FilterScreen/Types';

interface IItemSelectDrop {
  title: string;
  active: boolean;
  handlerSelectedGroup: (title: string) => void;
  placeholder: string;
  searchValue: string;
  searchValueHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  items: TCommonItem[];
  handlerSelectedFilter: (filterItem: TCommonItem) => void;
  selectedFilter: TCommonItem;
}

const ItemSelectDrop = ({
  title, active, handlerSelectedGroup, placeholder, searchValue, searchValueHandler, items,
  handlerSelectedFilter, selectedFilter
}: IItemSelectDrop): React.ReactElement => (
  <div className={`${styles.containerItem} ${active ? styles.containerItemActive : ''}`}>
    <div
      className={styles.wrapper}
      onClick={() => handlerSelectedGroup(title)}
      onKeyDown={() => {}}
      role="button"
      aria-hidden="true"
      tabIndex={0}
    >
      <TextField
        text={title || ''}
        styleType={active ? 'selectedFiltersItemActive' : 'selectedFiltersItem'}
      />
      <SelectArrow />
    </div>
    <div
      className={active ? styles.content : styles.hideContent}
    >
      <Input
        value={searchValue}
        placeholder={placeholder}
        handlerChange={searchValueHandler}
        inputClass={styles.searchInput}
        iconClass="itemSearch"
      />
      <ScrollDropFilter
        id={title}
        handlerSelectedFilter={handlerSelectedFilter}
        items={items}
        selectedFilter={selectedFilter}
      />
    </div>
  </div>
);

export default ItemSelectDrop;
