import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { shallowEqual, useSelector } from 'react-redux';
import Button from '../../../components/Button/Button';
import styles from '../sass/TableScreener.module.scss';
import ModalAlertHeader from './ModalAlertHeader';
import AlertModalItemRow from './AlertModalItemRow';
import AlertSymbolRow from './AlertSymbolRow';
import {
  ALERT_MODAL_ROWS, ALERT_MODAL_VALUE_ROW, ALERT_TYPES, ALERT_TIME_PERIODS
} from '../../../constants/alerts';
import { SECOND } from '../../../constants/tvWidgetOptions';
import { alertsModalStateHandler, getDateTimeForPeriod } from '../../../components/ChartContainer/utils/alertHelper';
import useAlerts from '../../../components/ChartContainer/hooks/useAlerts';
import ButtonListenerWrapper from '../../../components/ButtonListenerWrapper/ButtonListenerWrapper';
import { currentAlertData } from '../../../components/ChartContainer/constants/constants';
import GeneralConfirmModal from '../../../components/ConfirmModal/GeneralConfirmModal';

const ModalAlert = () => {
  const { t } = useTranslation();
  const {
    openAlert,
    alertData,
    handleAlertClose,
    saveAlert,
    removeAlerts,
    saveAlertSettings
  } = useAlerts();

  const [alertState, setAlertState] = useState(alertData);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [dataTime, setDataTime] = useState(null);
  const [valueError, setValueError] = useState(false);
  const alertSettingsItemData = useSelector((state) => state?.alertsState?.alertSettingsItemData, shallowEqual);

  const handleChangeActive = (value) => {
    const newState = { ...alertState };
    newState.isActive = value;
    setAlertState(newState);
  };

  const handleTimeSelect = (selected) => {
    const newState = { ...alertState };
    setDataTime(selected);
    const currentlifetime = getDateTimeForPeriod(alertState?.pointTime1, selected.value) / SECOND;
    const deltaTime = newState.pointTime1 + currentlifetime;
    newState.activityEnd = deltaTime;
    newState.lifetime = selected.value;
    newState.pointTime2 = deltaTime;
    setAlertState(newState);
  };

  const cleanState = () => {
    setDataTime(null);
    setValueError(false);
    currentAlertData.data = null;
  };

  useEffect(() => {
    if (alertData) {
      const lastState = alertData?.id ? alertData : alertsModalStateHandler(alertData, alertSettingsItemData);

      setAlertState(lastState);
      if (alertData?.type === ALERT_TYPES.PRICE) {
        setDataTime(ALERT_TIME_PERIODS.filter((period) => period.value === alertData?.lifetime)[0]);
      }
    }
  }, [alertData]);

  const handleNote = (e) => {
    const newState = { ...alertState };
    if (e?.target.name === 'note') {
      newState.note = e?.target?.value;
    } else {
      setValueError(false);
      newState.priceTime1 = e?.target?.value;
      newState.priceTime2 = e?.target?.value;
    }
    setAlertState(newState);
  };

  const handleChangeNotifyState = (e) => {
    const newState = { ...alertState };
    newState[e?.target?.name] = e?.target?.checked;
    setAlertState(newState);
  };

  const handleSave = () => {
    if (alertState?.type === ALERT_TYPES.PRICE && (Number(alertState.priceTime1) <= 0)) {
      setValueError(true);
    }
    if (Number(alertState.priceTime1) > 0) {
      saveAlert(alertState);
      cleanState();
    }

    saveAlertSettings(alertState);
  };

  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleHideConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleRemove = () => {
    handleHideConfirmModal();
    removeAlerts([alertState?.id]);
    cleanState();
  };

  const closeAlert = () => {
    cleanState();
    handleAlertClose();
  };

  const getAlertHeaderLabel = () => {
    if (alertState?.id) {
      return t('alertHeaderLabel');
    }
    return (alertState?.type === ALERT_TYPES.PRICE ? t('alertHeaderLabelPriceCreate') : t('alertHeaderLabelCreate'));
  };

  return (
    <>
      <ButtonListenerWrapper
        handleCancel={closeAlert}
        handleConfirm={() => undefined}
      >
        <div className={`${styles.modalAlert} ${openAlert ? styles.showModal : ''}`}>
          {openAlert && (
            <div className={styles.alertWraper}>
              <ModalAlertHeader
                onClose={closeAlert}
                headLabel={getAlertHeaderLabel()}
              />
              <div className={styles.alertWrap}>
                <AlertSymbolRow
                  symbol={alertState?.symbol}
                  label={t('alertTimeLabel')}
                  selected={dataTime}
                  labelAlertState={alertState?.isActive ? t('alertOn') : t('alertOf')}
                  handleTimeSelect={handleTimeSelect}
                  type={alertState?.type}
                  isActive={alertState?.isActive}
                  changeAlert={handleChangeActive}
                />
                {ALERT_MODAL_ROWS.map((row, index) => (
                  <React.Fragment key={[row?.head, index].join('_')}>
                    { index === 1 && alertState?.type === ALERT_TYPES.PRICE && (
                    <AlertModalItemRow
                      row={ALERT_MODAL_VALUE_ROW}
                      headLabel={t(ALERT_MODAL_VALUE_ROW.head)}
                      elementLabel={t(ALERT_MODAL_VALUE_ROW.elements[0].label)}
                      index={index}
                      handleNote={handleNote}
                      alertState={alertState}
                      handleChangeNotifyState={handleChangeNotifyState}
                      valueError={valueError}
                      errorMessage={t('alertValueError')}
                    />
                    )}
                    <AlertModalItemRow
                      row={row}
                      headLabel={t(row.head)}
                      index={index}
                      handleNote={handleNote}
                      alertState={alertState}
                      handleChangeNotifyState={handleChangeNotifyState}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className={styles.alertActions}>
                {alertState?.id && (
                <Button
                  buttonClass={`${styles.alertActionsButton} ${styles.remove}`}
                  handleClick={handleShowConfirmModal}
                >
                  {t('deleteAlert')}
                </Button>
                )}
                <Button
                  buttonClass={`${styles.alertActionsButton} ${styles.createOrEdit}`}
                  handleClick={handleSave}
                >
                  {t(alertState?.id ? 'saveAlert' : 'create')}
                </Button>
              </div>
            </div>
          )}
        </div>
      </ButtonListenerWrapper>
      {showConfirmModal && (
        <GeneralConfirmModal
          title={t('confirmDeleteAlert')}
          confirmCaption="Yes"
          declineCaption="No"
          confirmHandler={handleRemove}
          declineHandler={handleHideConfirmModal}
        />
      )}
    </>
  );
};

export default React.memo(ModalAlert);
