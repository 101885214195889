import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/ScreenerTable.module.scss';
import { ReactComponent as Check } from '../../../assets/images/icons/screener/Check.svg';
import { ReactComponent as Minus } from '../../../assets/images/icons/screener/Minus.svg';
import { CHECKED, UNCHECKED } from '../../../constants/screener';
import TextWithTooltip from '../../DataPanel/components/TextWithTooltip';

const ItemValue = ({
  onClickHAndler,
  newValue,
  value,
  isCapitalize,
  colorCell,
  isNeedTooltip,
  isClickableLinkStyles
}) => {
  const handleItemValue = () => {
    let componenttoRender;
    if (value === UNCHECKED) {
      componenttoRender = <Minus className={styles.cross} />;
    } else if (value === CHECKED) {
      componenttoRender = <Check />;
    } else if (isNeedTooltip) {
      componenttoRender = <TextWithTooltip title={value} />;
    } else {
      componenttoRender = value;
    }
    return componenttoRender;
  };

  return (
    <span
      aria-hidden="true"
      onClick={onClickHAndler}
      style={colorCell}
      className={`${value > 0 ? styles.growth : styles.loss} ${isClickableLinkStyles && styles.clickableLink}`}
    >
      {isCapitalize
        ? (
          <>
            {isNeedTooltip
              ? <TextWithTooltip title={newValue[0].toUpperCase() + newValue.slice(1)} />
              : newValue[0].toUpperCase() + newValue.slice(1)}
          </>
        )
        : handleItemValue()}
    </span>
  );
};

ItemValue.propTypes = {
  isClickableLinkStyles: PropTypes.bool,
  onClickHAndler: PropTypes.func.isRequired,
  colorSettings: PropTypes.shape({}),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isCapitalize: PropTypes.bool.isRequired,
  colorCell: PropTypes.shape({}).isRequired,
  isNeedTooltip: PropTypes.bool,
};

ItemValue.defaultProps = {
  isClickableLinkStyles: false,
  colorSettings: {},
  value: null,
  isNeedTooltip: false,
};

export default ItemValue;
