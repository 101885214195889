import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DefaultLink from '../DefaultLink/DefaultLink';
import { toggleAlertNotificationListDropdownVisible } from '../../store/alerts/slice';
import { setLoaderAction } from '../../store/screener/actions';
import { popUpAction } from '../../store/auth/actions';
import styles from './sass/SidebarScreener.module.scss';
import { changeTabAction } from '../../store/tableData/slice';
import { LAST_TABLE_PATH } from '../../constants/storage';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';

const areEqual = (prevProps, nextProps) => {
  return prevProps.className === nextProps.className
    && prevProps.icon === nextProps.icon
    && prevProps.text === nextProps.text
    && prevProps.tooltipText === nextProps.tooltipText
    && prevProps.route === nextProps.route
    && prevProps.isVisible === nextProps.isVisible
    && prevProps.alertsListVisible === nextProps.alertsListVisible
    && prevProps.currentPage === nextProps.currentPage
    && prevProps.setCurrentPage === nextProps.setCurrentPage;
};

const SidebarItem = ({
  className,
  Icon,
  text,
  tooltipText,
  route,
  isFree,
  isVisible,
  currentPage,
  alertsListVisible
}) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { updateStoreHandler } = useLastStateUpdateStore();

  let timeout = null;

  const timeoutHandler = () => {
    setVisible(true);

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => setVisible(false), 1500);
  };

  const clickHandler = () => {
    if (route !== currentPage && text !== t('hideMenu')) {
      dispatch(changeTabAction());
      dispatch(setLoaderAction(true));
      dispatch(popUpAction(false));
      updateStoreHandler(LAST_TABLE_PATH, text);
    }

    if (alertsListVisible) {
      dispatch(toggleAlertNotificationListDropdownVisible());
    }
    timeoutHandler();
  };

  return (
    <div
      aria-hidden="true"
      onClick={() => !isFree && clickHandler()}
      className={`${styles.sideBarItemWrapper}`}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <DefaultLink href={route} className={className}>
        <Icon />
        <span>{text}</span>
      </DefaultLink>
      {visible && !isVisible ? <div className={styles.sideBarInfoTool}>{tooltipText}</div> : null}
    </div>
  );
};

SidebarItem.propTypes = {
  currentPage: PropTypes.string,
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.shape({}),
  text: PropTypes.string,
  route: PropTypes.string,
  tooltipText: PropTypes.string,
  alertsListVisible: PropTypes.bool,
  isFree: PropTypes.bool
};

SidebarItem.defaultProps = {
  currentPage: '',
  isVisible: false,
  className: '',
  Icon: null,
  text: '',
  route: '#',
  tooltipText: '',
  alertsListVisible: false,
  isFree: false
};

export default React.memo(SidebarItem, areEqual);
