import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styles from './sass/SwitchQuartersButton.module.scss';
import { PERIOD_SOURCES, SWITCH_QUARTERS_SETTINGS } from '../../constants/statsChart';
import SwitchButton from '../SwitchButton/SwitchButton';

const SwitchQuartersButton = ({
  numberOfQuarters,
  currentSource,
  setNumberOfQuarters,
  setSource
}) => {
  const { t } = useTranslation();

  const [switchValue, setSwitchValue] = useState(numberOfQuarters === 8);

  const switchValueHandler = () => {
    setSwitchValue((prevState) => {
      setNumberOfQuarters({ data: !prevState ? 8 : 4 });
      return !prevState;
    });
  };

  const switchSourceHandler = (value) => {
    setSource(value);
  };

  return (
    <>
      <div className={styles.headerTitle}>{t('switcherTitle')}</div>
      <div className={styles.switchBlockWrapper}>
        <div className={styles.switchSourceWrapper}>
          <div
            aria-hidden="true"
            className={
              `${styles.switchSrcButton} ${styles.left} ${currentSource === PERIOD_SOURCES.SHORT_Q && styles.active}`
            }
            onClick={() => switchSourceHandler(PERIOD_SOURCES.Q)}
          >
            {PERIOD_SOURCES.Q}
          </div>
          <div
            aria-hidden="true"
            className={
              `${styles.switchSrcButton} ${styles.right} ${currentSource === PERIOD_SOURCES.SHORT_Y && styles.active}`
            }
            onClick={() => switchSourceHandler(PERIOD_SOURCES.Y)}
          >
            {PERIOD_SOURCES.Y}
          </div>
        </div>
        <div className={styles.wrapper}>
          <div
            className={styles.quartersTitle}
            style={{ fontWeight: !switchValue && SWITCH_QUARTERS_SETTINGS.FONT_WEIGHT }}
          >
            {currentSource === PERIOD_SOURCES.SHORT_Y ? t('forYears') : t('forQuarters')}
          </div>
          <div className={styles.switchWrapper}>
            <SwitchButton
              handleDiameter={18}
              width={42}
              height={22}
              checked={switchValue}
              onChange={switchValueHandler}
            />
          </div>

          <div
            className={styles.quartersTitle}
            style={{ fontWeight: switchValue && SWITCH_QUARTERS_SETTINGS.FONT_WEIGHT }}
          >
            {currentSource === PERIOD_SOURCES.SHORT_Y ? t('eightYears') : t('eightQuarters')}
          </div>
        </div>
      </div>
    </>
  );
};

SwitchQuartersButton.propTypes = {
  numberOfQuarters: PropTypes.number,
  currentSource: PropTypes.string,
  setNumberOfQuarters: PropTypes.func,
  setSource: PropTypes.func,
};

SwitchQuartersButton.defaultProps = {
  numberOfQuarters: 8,
  currentSource: PERIOD_SOURCES.SHORT_Q,
  setNumberOfQuarters: () => {},
  setSource: () => {}
};

export default SwitchQuartersButton;
