import { GridItemHTMLElement, GridStackNode } from 'gridstack';
import {
  Dashboard, GridConstraints, GridPoint, Widget
} from '../../../pages/Dashboards/types/DashboardTypes';
import { DragWidgetTypes, WidgetColorsTypes, WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import {
  ColumnSetItemData, DeepListItemData, ScreenerRowData, ScreenItemData,
} from '../../../utils/Types';
import { FIXED_COLUMNS, NEW_SCREEN_DATA } from '../../../constants/screener';
import AccountStateType from '../../../store/account/AccountStateType';
import {
  EMPTY_DL,
  GRID_PERFORMANCE_HEIGHT,
  GRID_PERFORMANCE_WIDTH,
  GRID_ROWS,
  GRID_WIDGET_PSC_START_HEIGHT,
  GRID_WIDGET_PSC_START_WIDTH,
  GRID_WIDGET_SCREEN_START_HEIGHT,
  GRID_WIDGET_SCREEN_START_WIDTH,
  GRID_WIDGET_START_HEIGHT,
  GRID_WIDGET_START_HEIGHT_STATS_CHART,
  GRID_WIDGET_START_WIDTH,
  GRID_WIDGET_START_WIDTH_STATS_CHART,
} from '../types/Constants';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { messageIdGenerator } from '../../ChartContainer/utils/chartingHelper';
import { filtersStoreHelper } from '../../../utils/helpers';
import { ShortSymbol, StoredSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';
import { LAST_SCREENER_COLUMNS_SET } from '../../../constants/tvWidgetOptions';

export const getWidgetPoints = (element: HTMLElement): GridPoint => {
  const x = Number(element.getAttribute('gs-x'));
  const y = Number(element.getAttribute('gs-y'));
  const w = Number(element.getAttribute('gs-w'));
  const h = Number(element.getAttribute('gs-h'));
  return {
    x, y, w, h,
  };
};

type TCollideType = {
  lastRowIndex: number;
  columnIndexes: number[];
  countMatch: number[];
};

export const getWidgetType = (element: GridItemHTMLElement | undefined): string => {
  if (!element) return WidgetTypes.DEEP_LIST;
  switch (true) {
    case element.classList.contains(WidgetTypes.CHART):
      return WidgetTypes.CHART;
    case element.classList.contains(WidgetTypes.SCREENER):
      return WidgetTypes.SCREENER;
    case element.classList.contains(WidgetTypes.STATS_CHART):
      return WidgetTypes.STATS_CHART;
    case element.classList.contains(WidgetTypes.POSITION_SIZE_CALCULATOR):
      return WidgetTypes.POSITION_SIZE_CALCULATOR;
    case element.classList.contains(WidgetTypes.PERFORMANCE_CHART):
      return WidgetTypes.PERFORMANCE_CHART;
    case element.classList.contains(WidgetTypes.HEATMAP):
      return WidgetTypes.HEATMAP;
    case element.classList.contains(WidgetTypes.BUBBLE_CHART):
      return WidgetTypes.BUBBLE_CHART;
    default:
      return WidgetTypes.DEEP_LIST;
  }
};

export const prepareNewWidget = (widget: GridStackNode, deepListId: number): Widget => {
  const widgetType = getWidgetType(widget.el);
  const newWidget: Widget = {
    id: `${messageIdGenerator()}`,
    type: widgetType,
    points: {
      x: widget.x || 0,
      y: widget.y || 0,
      w: widget.w || 0,
      h: widget.h || 0,
    },
    constraints: {
      minWidth: 0,
      minHeight: 0,
      maxWidth: 0,
      maxHeight: 0
    },
    symbol: '',
    colorGroup: WidgetColorsTypes.BLUE
  };
  if (widgetType === WidgetTypes.STATS_CHART) {
    newWidget.statsChartData = {
      currentSource: 'Q',
      numberOfQuarters: 4,
      estimateVisibleData: true,
      checkedFilters: ['Sales', 'Earnings']
    };
  }
  if (widgetType === WidgetTypes.DEEP_LIST) {
    newWidget.deepListId = deepListId;
  }
  return newWidget;
};

const getDeepFound = (
  itemsColumnSets: ColumnSetItemData[],
  selector: keyof ColumnSetItemData,
  value: number | boolean
): ColumnSetItemData | null => {
  let found = null;
  itemsColumnSets.forEach((column: ColumnSetItemData) => {
    if (column[selector] === value) {
      found = column;
    }
  });
  return found;
};

export const findItem = (
  arr: (ScreenItemData | ColumnSetItemData)[],
  id: number
): ScreenItemData | ColumnSetItemData | undefined => {
  let found;
  arr?.forEach((item: ScreenItemData | ColumnSetItemData) => {
    if (item.type === NEW_SCREEN_DATA.FOLDER) {
      const deepFound = getDeepFound(item.items, 'id', id);
      if (deepFound) {
        found = deepFound;
      }
    }
    if (item.id === id) {
      found = item;
    }
  });
  return found;
};

const findDefaultColumnSet = (itemsColumnSets: ColumnSetItemData[]): ColumnSetItemData => {
  let defaultColumnSet = {};
  itemsColumnSets.forEach((column: ColumnSetItemData) => {
    if (column.type === NEW_SCREEN_DATA.FOLDER) {
      const found = getDeepFound(column.items, 'isDefault', true);
      if (found) {
        defaultColumnSet = found;
      }
    }
    if (column.isDefault) {
      defaultColumnSet = column;
    }
  });
  return defaultColumnSet as ColumnSetItemData;
};

const findKeyWithValue = (targetObj: { number: number[] }, targetValue: number) => {
  const entry = targetObj && Object.entries(targetObj).find(([, values]) => values.includes(targetValue));
  return entry ? parseInt(entry[0]) : null;
};

export const updateScreenerData = ({
  widget,
  itemsNewScreen,
  presetsNewScreen,
  setFiltersData,
  setSelectedColumns,
  itemsColumnSets,
  userSettings,
  updateStoreHandler,
  selectedScreenId,
  selectedScreen
}: {
  widget: Widget | null;
  itemsNewScreen: ScreenItemData[];
  presetsNewScreen: ScreenItemData[];
  setFiltersData: (filters: number[][][][]) => void;
  setSelectedColumns: (columns: number[]) => void;
  itemsColumnSets: ColumnSetItemData[];
  userSettings: AccountStateType['userSettings'];
  updateStoreHandler: (field: string, data: number) => void;
  selectedScreenId: number | undefined;
  selectedScreen?: ScreenItemData | undefined
}): void => {
  const screenId = widget?.screenerId ?? selectedScreenId;
  // get filters from screener
  const foundScreen = findItem(itemsNewScreen, Number(screenId));
  const foundPreset = findItem(presetsNewScreen, Number(screenId));
  let currentItem = foundScreen || foundPreset || selectedScreen;
  if (currentItem?.items?.length) {
    currentItem = currentItem?.items?.find((item: ScreenItemData) => item.id === screenId);
  }
  setFiltersData(filtersStoreHelper(currentItem?.data?.filters));
  let defaultColumnsSet = findItem(itemsColumnSets, userSettings?.lastScreenerColumnsSet);
  if (!defaultColumnsSet) {
    defaultColumnsSet = findDefaultColumnSet(itemsColumnSets);
  }
  if (!userSettings?.lastScreenerColumnsSet || userSettings?.lastScreenerColumnsSet === -1) {
    updateStoreHandler(LAST_SCREENER_COLUMNS_SET, defaultColumnsSet?.id);
  }
  // get columns sort from screener
  if (currentItem) {
    let foundColumnSet = findItem(itemsColumnSets,
      findKeyWithValue(userSettings?.presetsColumnSetsScreenerIds, currentItem.id)
      || currentItem?.data?.columnsId || 0);

    if (foundColumnSet?.items?.length) {
      foundColumnSet = foundColumnSet?.items?.find((item: ScreenItemData) => item.id === currentItem?.data?.columnsId);
    }
    let foundColumns = foundColumnSet?.data?.selectedColumns || [];
    if (!foundColumnSet) {
      foundColumns = defaultColumnsSet?.data?.selectedColumns || [];
    }
    setSelectedColumns([...FIXED_COLUMNS, ...foundColumns]);
  } else {
    const foundColumns = defaultColumnsSet?.data?.selectedColumns || [];
    setSelectedColumns([...FIXED_COLUMNS, ...foundColumns]);
  }
};

export const updateDeepListData = ({
  widget,
  tabsList,
  setFiltersData,
  setSelectedColumns,
  itemsColumnSets,
  alertFilters,
  isAlertTable,
  selectedTabId,
  selectedTab,
  userSettings
}: {
  widget: Widget | null;
  tabsList: DeepListItemData[];
  setFiltersData: (filters: number[][][][]) => void;
  setSelectedColumns: (columns: number[]) => void;
  itemsColumnSets: ColumnSetItemData[];
  selectedTabId: number | undefined;
  alertFilters?: number[][][][];
  isAlertTable: boolean;
  selectedTab?: DeepListItemData | undefined,
  userSettings: AccountStateType['userSettings'];
}): void => {
  const tabId = widget?.deepListId ?? selectedTabId;
  let foundColumns: number[] = [];
  const defaultColumnsSet = itemsColumnSets.find((column: ColumnSetItemData) => {
    if (column.type === NEW_SCREEN_DATA.FOLDER) {
      return column.items.find((item: ScreenItemData) => item.isDefault);
    }
    return column.isDefault;
  });

  const foundTab = tabsList.find((item: DeepListItemData) => item.id === tabId) || selectedTab;

  if (foundTab && Object.keys(foundTab).length) {
    let foundColumnSet = findItem(itemsColumnSets,
      findKeyWithValue(userSettings?.presetsColumnSetsScreenerIds, foundTab.id) || foundTab?.data?.columnsId || 0);
    if (foundColumnSet?.items?.length) {
      foundColumnSet = foundColumnSet?.items?.find((item: ScreenItemData) => item.id === foundTab?.data?.columnsId);
    }
    const filters = isAlertTable ? alertFilters
      : filtersStoreHelper(foundTab?.data?.filters, foundTab.parentId as number);

    setFiltersData(filters?.length ? filters : EMPTY_DL);

    if (foundColumnSet) {
      foundColumns = foundColumnSet?.data?.selectedColumns || [];
    } else {
      foundColumns = defaultColumnsSet?.data?.selectedColumns || [];
    }
  } else {
    const universeTab = tabsList.find((item: DeepListItemData) => item?.data?.subType === 'Universe');
    let foundColumnSet = findItem(itemsColumnSets, universeTab?.data?.columnsId || 0);
    if (foundColumnSet?.items?.length) {
      foundColumnSet = foundColumnSet?.items?.find((item: ScreenItemData) => item.id === universeTab?.data?.columnsId);
    }
    const filters = filtersStoreHelper(universeTab?.data?.filters);
    setFiltersData(filters.length ? filters : EMPTY_DL);

    if (foundColumnSet) {
      foundColumns = foundColumnSet?.data?.selectedColumns || [];
    } else {
      foundColumns = defaultColumnsSet?.data?.selectedColumns || [];
    }
  }
  setSelectedColumns([...FIXED_COLUMNS, ...foundColumns]);
};

export const preparedWidgetClone = (
  target: HTMLElement,
  cellWidth: number,
  cellHeight: number
): { widthElement: number, heightElement: number } => {
  let widthElement;
  let heightElement;
  const widgetType = getWidgetType(target);
  switch (true) {
    case widgetType === WidgetTypes.CHART:
      widthElement = GRID_WIDGET_START_WIDTH * cellWidth;
      heightElement = GRID_WIDGET_START_HEIGHT * cellHeight;
      break;
    case widgetType === WidgetTypes.SCREENER:
    case widgetType === WidgetTypes.DEEP_LIST:
      widthElement = GRID_WIDGET_SCREEN_START_WIDTH * cellWidth;
      heightElement = GRID_WIDGET_SCREEN_START_HEIGHT * cellHeight;
      break;
    case widgetType === WidgetTypes.STATS_CHART:
      widthElement = GRID_WIDGET_START_WIDTH_STATS_CHART * cellWidth;
      heightElement = GRID_WIDGET_START_HEIGHT_STATS_CHART * cellHeight;
      break;
    case widgetType === WidgetTypes.POSITION_SIZE_CALCULATOR:
      widthElement = GRID_WIDGET_PSC_START_WIDTH * cellWidth;
      heightElement = GRID_WIDGET_PSC_START_HEIGHT * cellHeight;
      break;
    case widgetType === WidgetTypes.HEATMAP:
    case widgetType === WidgetTypes.BUBBLE_CHART:
    case widgetType === WidgetTypes.PERFORMANCE_CHART:
      widthElement = GRID_PERFORMANCE_WIDTH * cellWidth;
      heightElement = GRID_PERFORMANCE_HEIGHT * cellHeight;
      break;
    default:
      widthElement = GRID_WIDGET_START_WIDTH * cellWidth;
      heightElement = GRID_WIDGET_START_HEIGHT * cellHeight;
      break;
  }
  return { widthElement, heightElement };
};

export const getAdditionalConstraints = (
  widget: Widget,
  width: number,
  height: number,
): GridConstraints => {
  let delta = 1;
  if (height > 0 && width > 0 && widget?.type === WidgetTypes.STATS_CHART) {
    const deltaHeight = height || 0;
    delta = 55 / (deltaHeight / GRID_ROWS);
  }
  const additionalCollection: GridConstraints = {
    minWidth: 0,
    minHeight: 0,
    maxWidth: 0,
    maxHeight: 0
  };
  if (widget?.constraints) {
    Object.keys(additionalCollection).forEach((key) => {
      if (Object.keys(widget?.constraints).includes(key)) {
        const precisionValue = Math.ceil(delta) * Number(widget?.constraints[key as keyof GridConstraints]);
        additionalCollection[key as keyof GridConstraints] = precisionValue;
      }
    });
  }
  return additionalCollection;
};

export const checkIsParentColumnSet = (
  widget: Widget | null,
  columnSetId: number,
  itemsNewScreen: ScreenItemData[],
  presetsNewScreen: ScreenItemData[],
  itemsColumnSets: ColumnSetItemData[],
  userSettings: AccountStateType['userSettings'],
  selectedScreenId?: number | undefined
): boolean => {
  const conditionId = selectedScreenId || widget?.screenerId;
  const foundScreen = findItem(itemsNewScreen, Number(conditionId));
  const foundPreset = findItem(presetsNewScreen, Number(conditionId));
  let currentItem = foundScreen || foundPreset;
  if (currentItem?.items?.length) {
    const deepFound = currentItem?.items?.find((item: ScreenItemData) => item.id === conditionId);
    currentItem = deepFound;
  }
  if (foundPreset) {
    const columnsSet = findItem(itemsColumnSets, columnSetId) as ColumnSetItemData;
    return !!findKeyWithValue(userSettings?.presetsColumnSetsScreenerIds, currentItem?.id as number)
      || columnsSet?.data?.screenIdList?.includes(currentItem?.id as number) as boolean;
  }
  return currentItem?.data?.columnsId
    ? !!findKeyWithValue(userSettings?.presetsColumnSetsScreenerIds, currentItem?.id)
    || currentItem?.data?.columnsId === columnSetId
    : userSettings?.lastScreenerColumnsSet === columnSetId;
};

export const checkIsDefaultColumnSet = (
  columnSetId: number,
  userSettings: AccountStateType['userSettings'],
  itemsColumnSets: ColumnSetItemData[]
): boolean => {
  const defaultColumnSet = itemsColumnSets.find((column: ColumnSetItemData) => {
    if (column.type === NEW_SCREEN_DATA.FOLDER) {
      return column.items.find((item: ScreenItemData) => item.isDefault);
    }
    return column.isDefault;
  });
  if (userSettings?.lastScreenerColumnsSet && userSettings?.lastScreenerColumnsSet !== -1) {
    return columnSetId === userSettings?.lastScreenerColumnsSet;
  }
  return columnSetId === defaultColumnSet?.id;
};

export const checkIsParentColumnSetDeepList = (
  widget: Widget | null,
  columnSetId: number,
  tabsList: DeepListItemData[],
  selectedTabId?: number | undefined
): boolean => {
  const conditionId = selectedTabId || widget?.deepListId;
  const defaultTab = tabsList.find((item: DeepListItemData) => item?.data?.subType === UNIVERSE_TYPE);
  const foundTab = tabsList.find((item: DeepListItemData) => item.id === conditionId);
  return foundTab ? foundTab?.data?.columnsId === columnSetId : defaultTab?.data?.columnsId === columnSetId;
};

export const getIdsInSelected = (items: ScreenerRowData[]): number[] => {
  const dataSym = items?.map((item: ScreenerRowData) => {
    const itemSymdata = item[0] as ShortSymbol;
    return itemSymdata?.sortIndex;
  });
  return dataSym;
};
export const getColumnsSetId = (
  id: number,
  isWatchlist: boolean,
  itemsNewScreen: ScreenItemData[],
  presetsNewScreen: ScreenItemData[],
  tabsList: DeepListItemData[],
  presets: DeepListItemData[],
  itemsColumnSets: ColumnSetItemData[],
  userSettings: AccountStateType['userSettings']
): number => {
  const defaultColumnsSet = itemsColumnSets.find((item) => item.isDefault);
  if (isWatchlist) {
    const deepList = [...tabsList, ...presets].find((item) => item.id === id);
    if (deepList && deepList?.data?.columnsId === 0) {
      return defaultColumnsSet?.id as number;
    }
    return deepList?.data?.columnsId || defaultColumnsSet?.id as number;
  }
  const screener = findItem(itemsNewScreen, Number(id));
  const preset = findItem(presetsNewScreen, Number(id));
  let currentItem = screener || preset;
  if (currentItem?.items?.length) {
    currentItem = currentItem?.items?.find((item: ScreenItemData) => item.id === id) as never as ScreenItemData;
  }
  if (preset) {
    let presetColumnsId;
    itemsColumnSets.forEach((columnSet: ColumnSetItemData) => {
      if (columnSet.type === NEW_SCREEN_DATA.FOLDER) {
        columnSet.items.forEach((item: ColumnSetItemData) => {
          if (item?.data?.screenIdList?.includes(preset.id as number)) {
            presetColumnsId = columnSet.id;
          }
        });
      }
      if (columnSet?.data?.screenIdList?.includes(currentItem?.id as number)) {
        presetColumnsId = columnSet.id;
      }
    });
    return presetColumnsId || userSettings?.lastScreenerColumnsSet;
  }
  if (userSettings?.lastScreenerColumnsSet === -1 || !userSettings?.lastScreenerColumnsSet) {
    return defaultColumnsSet?.id as number;
  }
  return currentItem?.data?.columnsId ? currentItem?.data?.columnsId as number : userSettings?.lastScreenerColumnsSet;
};

export const checkAndSetDropdownPosition = (
  dropdownRef: React.RefObject<HTMLDivElement>,
  setIsTopState: (isTop: boolean) => void,
  setIsLeftState: (isLeft: boolean) => void
): void => {
  const { innerHeight, innerWidth } = window;
  const { top, right, left } = dropdownRef.current?.getBoundingClientRect() as DOMRect;
  if (top + 390 > innerHeight) {
    setIsTopState(true);
  } else {
    setIsTopState(false);
  }
  if (right + 10 > innerWidth && left > 217) {
    setIsLeftState(true);
  } else {
    setIsLeftState(false);
  }
};

const convertFromIndex = (indexList: number[], symbolsList: StoredSymbol[]): string[] => {
  const stringList: string[] = [];
  indexList.forEach((item) => {
    const sym = symbolsList.find((symbol: StoredSymbol) => symbol.index === item);
    if (sym) {
      stringList.push(sym.sym as string);
    }
  });
  return stringList;
};

const checkIsCommonDeepListFilters = (filters: number[][][][]): boolean => {
  if (filters?.length && filters[0]?.length && filters?.length === 1) {
    if (filters[0][0]?.length && filters[0][0][2]?.length && filters[0][0][2]?.length > 1) {
      return true;
    }
  }
  return false;
};

export const cleanOldDataDeeplist = (
  data: ScreenerRowData[],
  currentFilters: number[][][][] | undefined,
  symbolsList: StoredSymbol[]
): ScreenerRowData[] => {
  let newData = [...data];
  if (currentFilters && checkIsCommonDeepListFilters(currentFilters as number[][][][])) {
    const currentSelectedSymbols = convertFromIndex(currentFilters[0][0][2], symbolsList);
    newData = newData.filter((item) => {
      const symbolData = item[0] as ShortSymbol;
      return currentSelectedSymbols.includes(symbolData?.sym as string);
    });
  }
  return newData;
};

export const getSymbolFromMasterWidget = (newWidget: Widget, dashboards: Dashboard): Widget => {
  let countScreen = 0;
  const clonedWidget = { ...newWidget };
  if ([WidgetTypes.CHART, WidgetTypes.STATS_CHART].includes(clonedWidget?.type as WidgetTypes)) {
    dashboards.data?.widgets?.forEach((widget: Widget) => {
      if (
        [WidgetTypes.SCREENER, WidgetTypes.DEEP_LIST].includes(widget?.type as WidgetTypes)
        && clonedWidget?.colorGroup === widget?.colorGroup
      ) {
        countScreen += 1;
        if (widget?.symbol) {
          clonedWidget.symbol = widget?.symbol;
        }
      }
    });
    if (clonedWidget?.type === WidgetTypes.STATS_CHART) {
      if (countScreen === 0 || clonedWidget?.symbol === '') {
        dashboards.data?.widgets?.forEach((widget: Widget) => {
          if (widget?.type === WidgetTypes.CHART && clonedWidget?.colorGroup === widget?.colorGroup
          ) {
            if (widget?.symbol) {
              clonedWidget.symbol = widget?.symbol;
            }
          }
        });
      }
    }
  }
  return clonedWidget;
};

export const generateGridState = (
): number[][] => Array.from(Array(GRID_ROWS).keys(), () => Array.from(Array(GRID_ROWS).keys(), () => 0));

export const getRowChunks = (arr: number[], size: number): number[][] => Array.from(
  { length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size)
);

export const setDragAddMenuElement = (
  gridState: number[][],
  item: { w: number, h: number, type: DragWidgetTypes },
  setEnableDrag: (value: boolean, type: DragWidgetTypes) => void,
): void => {
  const collide: TCollideType = {
    lastRowIndex: 0,
    columnIndexes: [],
    countMatch: []
  };
  const { w, h, type } = item;
  const currentWidth = w;
  const currentHeight = h;
  const findWidth = Array.from(Array(currentWidth).keys(), () => 0);
  let colideIndex = 0;
  gridState.forEach((row: number[], rowIndex: number) => {
    row.forEach((rowItem: number, index: number) => {
      if (rowItem === 0
        && getRowChunks(row.slice(index, index + row?.length), currentWidth)[0].join('') === findWidth.join('')) {
        if (collide.lastRowIndex === rowIndex - 1 && collide.columnIndexes.includes(index)) {
          if (collide.countMatch[colideIndex]) {
            collide.countMatch[colideIndex] += 1;
          } else {
            collide.countMatch[colideIndex] = 1;
          }
        } else if (collide.lastRowIndex < rowIndex - 2) {
          if (collide.countMatch?.length) {
            colideIndex += 1;
          }
          collide.countMatch[colideIndex] = 0;
        }
        if (collide.lastRowIndex !== rowIndex) {
          collide.lastRowIndex = rowIndex;
          collide.columnIndexes.push(index);
        }
      }
    });
  });
  const isEptyStateForType = collide.countMatch?.length ? Math.max(...collide.countMatch) >= currentHeight : false;
  setEnableDrag(isEptyStateForType, type);
};

export const compareAndCleanSelections = (
  oldSelections: number[][][][],
  newSelections: number[][][][],
  callback: () => void
): void => {
  if (checkIsCommonDeepListFilters(oldSelections) && checkIsCommonDeepListFilters(newSelections)) {
    if (oldSelections[0][0][2].length !== newSelections[0][0][2].length) {
      callback();
    }
  }
};

export const removeNonNumeric = (input: string): string => String(input).replace(/[^0-9.-]/g, '');
