import React from 'react';
import styles from '../sass/WidgetCharts.module.scss';
import { IEmptyPerformanceChart } from '../types/WidgetChartsInterfaces';

const EmptyPerformanceChart = ({
  title,
  description,
}: IEmptyPerformanceChart): React.ReactElement => (
  <div className={styles.noResults}>
    <h1 className={styles.title}>
      {title}
    </h1>
    <span className={styles.desc}>
      {description}
    </span>
  </div>
);

export default EmptyPerformanceChart;
