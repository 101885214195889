import { StudyPlotType } from "../../../charting_library/charting_library";

const plots = [
  {
    id: 'volumeBarsPlot',
    type: 'line' as StudyPlotType.Line,
  },
  {
    id: 'volumeBarsPlotColorer',
    palette: 'volumeBarsPalette',
    target: 'volumeBarsPlot',
    type: 'colorer' as StudyPlotType.Colorer,
  },
  {
    id: 'volumeMAPlot',
    type: 'line' as StudyPlotType.Line,
  },
];

export default plots;
