import React, {
  useEffect, useLayoutEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import getNewTitle from '../../utils/getDuplicateTitle';
import { setSharedDashboardItem } from '../../store/screener/actions';
import { setCreatedFolder } from '../../store/newScreen/actions';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import Input from '../Input/Input';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import { checkEmptyText, findIndexItem } from '../../utils/helpers';
import Select from '../Select/Select';
import useNewScreen from '../../utils/hooks/useNewScreen';
import { getTitleModal, getLabelModal } from '../../utils/helperNewScreenModal';
import { MAX_LENGTH_COUNT, SUBSCRIPTION_TYPE } from '../../constants/screener';
import constants from '../../constants/filters';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { DashboardItemsDialogActionTypes, DialogItemDataTypes } from '../DashboardItemsDialog/types/constants';
import { DialogItemEntityType } from '../DashboardsItemsDropdown/types/constants';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import { checkRoleUser } from '../../utils/userHelper';

const CreateScreenModal = ({
  setOpenModal,
  createScreen,
  selectedScreenId,
  itemsNewScreen,
  groupFilters,
  type,
  selectedColumns,
  selectedTab,
  dataType,
  resetSearchHandler,
  currentItemSelected,
  savedItem
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
    actionCallback
  } = UseDashboardsDialogs();
  const createdFolder = useSelector((state) => state.newScreenState.createdFolder);
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);
  const screens = useSelector((state) => state.newScreenState.screens, shallowEqual);
  const columnSets = useSelector((state) => state.newScreenState.columnSets, shallowEqual);
  const dataPanels = useSelector(
    (state) => state.newScreenState.dataPanels, shallowEqual
  );
  const deeplists = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const dashboards = useSelector((state) => state.dashboardsState.dashboards, shallowEqual);

  const [createScreenName, setCreateScreenName] = useState('');
  const [description, setDescription] = useState('');
  const [valueSelect, setValueSelect] = useState(-1);
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const title = getTitleModal(savedItem?.type || type, t, savedItem?.type);
  const label = getLabelModal(savedItem?.type || type, t);
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const { newFolderData, duplicatePreset } = useNewScreen(currentItemSelected);

  const createFolderHandler = () => {
    const folderId = createdFolder || +valueSelect;
    const indexItem = findIndexItem(itemsNewScreen, 'id', folderId);
    const filters = dataType === DialogItemDataTypes.DEEP_LIST ? [[[0, 0, selectedSymbols]]] : groupFilters;

    if (savedItem?.type) {
      const {
        subType, defName, color1, color2, bgColor, ...dataWithoutSubType
      } = savedItem?.data;
      duplicatePreset(createScreenName, dataWithoutSubType, folderId, savedItem?.type);
      setOpenModal('');
      dispatch(setSharedDashboardItem({}));
    } else {
      createScreen(
        createScreenName,
        filters,
        folderId,
        itemsNewScreen[indexItem]?.title,
        type,
        (type === constants.screener || type === constants.deepList
          || type === constants.dataPanel || type === constants.userDashboard || type === constants.columnsPreset)
          ? description : selectedColumns
      );
    }
  };

  const getItems = () => {
    switch (type) {
      case (DialogItemDataTypes.DEEP_LIST): {
        return deeplists;
      }
      case (DialogItemDataTypes.SCREENER): {
        return screens;
      }
      case (DialogItemDataTypes.DATA_PANEL): {
        return dataPanels;
      }
      case (DialogItemDataTypes.DASHBOARD): {
        return dashboards;
      }
      case (DialogItemDataTypes.COLUMN_SET): {
        return columnSets;
      }
      default: return screens;
    }
  };

  const createHandler = () => {
    createFolderHandler();
  };

  const addFolderHandler = () => {
    if (typeof selectedTab === 'number' || !selectedTab) {
      actionOpenDialog(DashboardItemsDialogActionTypes.ADD, dataType, DialogItemEntityType.FOLDER, selectedTab);
      setCreateFolderModal(true);
    } else setCreateFolderModal(true);
  };

  const actionSetCurrentItem = (screenId) => {
    resetSearchHandler(screenId);
    currentItemSelected(screenId);
  };
  const afterConfirm = () => {
    actionCallback();
    setCreateFolderModal(false);
  };

  const actionCancelDialogHandler = () => {
    actionCancelDialog();
    setCreateFolderModal(false);
  };

  useEffect(() => {
    if (newFolderData?.id) {
      setValueSelect(newFolderData.id);
    }
    setCreateFolderModal(false);
  }, [newFolderData]);

  useLayoutEffect(() => {
    setCreateScreenName(savedItem?.title);
  }, [savedItem?.title]);

  useLayoutEffect(() => {
    setDescription(savedItem?.description);
  }, [savedItem?.description]);

  useLayoutEffect(() => {
    dispatch(setCreatedFolder(null));
  }, []);

  useEffect(() => {
    if (Object.keys(savedItem).length) {
      const newTitle = getNewTitle(savedItem, getItems());
      if (newTitle !== savedItem?.title) {
        setCreateScreenName(newTitle);
      }
    }
  }, []);

  const isShown = type === constants.screener
    || type === constants.presetDeeplist || (type === constants.deepList) || (type === constants.columnSet)
    || (type === constants.userDashboard) || (type === constants.userDashboardPresets)
    || (type === constants.columns) || (type === constants.columnsPreset);

  return useMemo(() => {
    return (
      <>
        {open && (
          <DashboardItemsDialog
            open={open}
            actionCancel={actionCancelDialogHandler}
            dialogData={dialogData}
            currentItemHandler={actionSetCurrentItem}
            adminRole={adminRole}
            afterConfirm={afterConfirm}
          />
        )}
        {createFolderModal ? null : (
          <ButtonListenerWrapper
            handleCancel={setOpenModal}
            handleConfirm={createScreenName ? createHandler : undefined}
          >
            <div className={stylesModal.createScreenWrapper}>
              <div>
                <TextField text={title} styleType="createSelectModalHeader" />
              </div>
              <div className={stylesModal.body}>
                <div className={stylesModal.bodyContent}>
                  <div className={isShown ? stylesModal.infoWrapper : stylesModal.screenerInfoWrapper}>
                    <div className={stylesModal.inputWrapper}>
                      <TextField text={label} styleType="createFolderModalBody" />
                      <Input
                        handlerChange={(e) => setCreateScreenName(e.currentTarget.value)}
                        placeholder={label}
                        value={createScreenName}
                        maxLength={MAX_LENGTH_COUNT}
                        autoFocus
                      />
                    </div>

                    {isShown ? (
                      <div className={stylesModal.inputWrapper}>
                        <Select
                          title={t('folder')}
                          placeholder="Select a folder"
                          valueSelect={createdFolder || valueSelect}
                          setValueSelect={setValueSelect}
                          data={itemsNewScreen}
                          addFolderHandler={addFolderHandler}
                        />
                      </div>
                    )
                      : (
                        <Select
                          title={t('folder')}
                          valueSelect={createdFolder || valueSelect}
                          setValueSelect={setValueSelect}
                          data={itemsNewScreen}
                          showValue={false}
                          addFolderHandler={addFolderHandler}
                        />
                      )}
                  </div>

                  <div className={`${stylesModal.inputWrapper} ${stylesModal.description}`}>
                    <TextField text={t('description')} styleType="createFolderModalBody" />
                    <TextArea
                      onChange={(e) => setDescription(e.currentTarget.value)}
                      value={description}
                    />
                  </div>
                </div>

                <div className={stylesModal.buttonWrapper}>
                  <Button
                    buttonClass={stylesModal.cancelButton}
                    handleClick={setOpenModal}
                  >
                    {t('cancel')}
                  </Button>
                  <Button
                    buttonClass={stylesModal.createFolderButton}
                    handleClick={createHandler}
                    disabled={checkEmptyText(createScreenName) || !createScreenName}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
            </div>
          </ButtonListenerWrapper>
        )}
      </>
    );
  }, [selectedScreenId, itemsNewScreen, groupFilters, createScreenName, description, valueSelect, createFolderModal]);
};

CreateScreenModal.propTypes = {
  createScreen: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  selectedScreenId: PropTypes.number,
  itemsNewScreen: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupFilters: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.array)),
  newFolderData: PropTypes.shape({
    id: PropTypes.number,
  }),
  type: PropTypes.string,
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTab: PropTypes.number,
  dataType: PropTypes.number,
  resetSearchHandler: PropTypes.func,
  currentItemSelected: PropTypes.func,
  savedItem: PropTypes.shape({}),
};

CreateScreenModal.defaultProps = {
  type: '',
  groupFilters: [],
  selectedColumns: [],
  selectedScreenId: -1,
  resetSearchHandler: () => null,
  currentItemSelected: () => null,
  savedItem: {}
};

export default CreateScreenModal;
