import React from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import styles from '../sass/FilterScreen.module.scss';
import { ReactComponent as ClearIcon } from '../../../assets/images/icons/screener/close square black.svg';
import TextField from '../../TextField/TextField';
import DefaultButton from '../../DefaultButton/DefaultButton';
import constants from '../../../constants/filters';
import ItemsCheckBox from './renderItem/ItemsCheckBox';
import ItemsRange from './renderItem/ItemsRange';
import TextItems from './renderItem/TextItems';
import DateItems from './renderItem/DateItems';
import RootStateTypes from '../../../store/RootStateTypes';
import { TFilterGroupe, TValueItem } from '../Types';

interface IFilterItem {
  title: string;
  arrayData: TValueItem[];
  id: number;
  clearItem: (id: number, activeGroupIndex: number, checkMap: number) => void;
  type: string;
  activeGroupIndex: number;
  handlerSearchFilter: (id: number, type: string, columnId: number, list: TFilterGroupe[]) => void;
  hideButton: boolean;
}

const FilterItem = ({
  title, arrayData, id, clearItem, type, activeGroupIndex, handlerSearchFilter, hideButton
}: IFilterItem): React.ReactElement => {
  const initialFilterList = useSelector((state: RootStateTypes) => state.screenerState.initialFilterList, shallowEqual);
  const labelMultiCheck = arrayData[0]?.include === constants.include
    ? `${constants.include} ${title}` : `${constants.exclude} ${title}`;
  const label = arrayData[0]?.include ? labelMultiCheck : title;
  const mapType = arrayData[0]?.include === constants.include ? constants.mapInclude : constants.mapExclude;
  const checkMap = type === constants.multiselectSearch && arrayData?.length ? mapType : -1;

  return (
    <div className={styles.filterItem}>
      <div className={styles.value}>
        <div
          className={styles.pressBox}
          onClick={() => handlerSearchFilter(id, arrayData[0]?.include as string, id, initialFilterList)}
          onKeyDown={() => handlerSearchFilter(id, arrayData[0]?.include as string, id, initialFilterList)}
          role="button"
          aria-hidden="true"
          tabIndex={0}
        >
          <TextField styleType="filterItemTitle" text={`${label}:`} />
          <div className={styles.boxValue}>
            {type === constants.check && (
            <ItemsCheckBox arrayData={arrayData} />
            )}
            {(type === constants.range || type === constants.dropdown) && (
            <ItemsRange arrayData={arrayData} />
            )}
            {(type === constants.multiselectSearch || type === constants.select) && (
            <TextItems arrayData={arrayData} />
            )}
            {type?.split(':')[0] === constants.date && (
            <DateItems arrayData={arrayData} />
            )}
          </div>
        </div>

        {hideButton && (
        <DefaultButton buttonClass="btnClearItem" handleClick={() => clearItem(id, activeGroupIndex, checkMap)}>
          <ClearIcon />
        </DefaultButton>
        )}
      </div>
    </div>
  );
};

export default FilterItem;
