import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/TextArea.module.scss';
import { MAX_LENGTH_TEXT_AREA } from '../../constants/screener';

const TextArea = ({
  onChange,
  placeholder,
  name,
  value,
  disable,
  styleType,
  requiredInput,
  id,
  inputClass,
  ref
}) => (
  <textarea
    ref={ref}
    className={`${styles.textArea} ${styleType && styles[styleType]} ${inputClass}`}
    placeholder={placeholder}
    name={name}
    onChange={onChange}
    value={value}
    disabled={disable}
    maxLength={MAX_LENGTH_TEXT_AREA}
    required={requiredInput}
    id={id}
  />
);

TextArea.defaultProps = {
  placeholder: '',
  name: '',
  value: '',
  disable: '',
  styleType: '',
  requiredInput: false,
  id: '',
  onChange: () => {},
  inputClass: '',
  ref: {}
};

TextArea.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.node,
  onChange: PropTypes.func,
  disable: PropTypes.string,
  styleType: PropTypes.string,
  requiredInput: PropTypes.bool,
  id: PropTypes.string,
  inputClass: PropTypes.string,
  ref: PropTypes.shape({}),
};

export default TextArea;
