import React from 'react';
import PropTypes from 'prop-types';

const UnfilledValue = ({ value, themeColorCell }) => {
  let colorStyle;
  const newValue = parseFloat(value.replace(/[^0-9.]/g, ''));
  if (newValue !== 0) {
    colorStyle = themeColorCell;
  }
  return (
    <span style={colorStyle}>
      {newValue === 0 ? '-' : value}
    </span>
  );
};

UnfilledValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  themeColorCell: PropTypes.shape({}),
};

UnfilledValue.defaultProps = {
  value: null,
  themeColorCell: {}
};

export default UnfilledValue;
