import React from 'react';
import PropTypes from 'prop-types';
import CommonTab from './CommonTab';
import { deepEqual } from '../../utils/objectHelper';

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.tabsList, nextProps.tabsList)
    && deepEqual(prevProps.tabOrder, nextProps.tabOrder)
    && prevProps.prevItem === nextProps.prevItem
    && prevProps.search === nextProps.search;
};

const WatchlistTabsList = ({
  search,
  tabsList,
  tabOrder,
  prevItem,
  setPrevItem,
  changeOrderHandler,
  selectTabClickHandler,
  resetSearchHandler,
  endDragHandler,
}) => (
  <>
    {tabOrder.map((itemId, index) => {
      const item = tabsList.find((i) => i.id === itemId);

      return item ? (
        <CommonTab
          search={search}
          index={index}
          item={item}
          key={itemId}
          setPrevItem={setPrevItem}
          prevItemStyles={(prevItem === item.id) && 'additionalStyles'}
          changeOrderHandler={changeOrderHandler}
          selectTabClickHandler={selectTabClickHandler}
          resetSearchHandler={resetSearchHandler}
          endDragHandler={endDragHandler}
        />
      ) : null;
    })}
  </>
);

WatchlistTabsList.propTypes = {
  resetSearchHandler: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  prevItem: PropTypes.number.isRequired,
  setPrevItem: PropTypes.func.isRequired,
  selectTabClickHandler: PropTypes.func.isRequired,
  changeOrderHandler: PropTypes.func.isRequired,
  tabsList: PropTypes.arrayOf(PropTypes.shape({})),
  tabOrder: PropTypes.arrayOf(PropTypes.number),
  endDragHandler: PropTypes.func.isRequired,
};

WatchlistTabsList.defaultProps = {
  tabsList: [{}],
  tabOrder: [],
};
export default React.memo(WatchlistTabsList, areEqual);
