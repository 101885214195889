import React from 'react';
import { ReactComponent as ArrowTopColor } from '../../../assets/images/icons/screener/arrowTopColor.svg';
import { ReactComponent as ArrowDownColor } from '../../../assets/images/icons/screener/arrowDownColor.svg';
import { ReactComponent as ArrowTopWhite } from '../../../assets/images/icons/screener/arrowTopWhite.svg';
import { ReactComponent as ArrowDownWhite } from '../../../assets/images/icons/screener/arrowWhiteDown.svg';
import styles from '../SortIconsGroup/sass/SortIconsGroup.module.scss';

interface Props {
  value: number;
  isAlertTable: boolean;
  reversFlag: boolean;
}

const SortIcons = ({
  value, isAlertTable, reversFlag
}: Props) => {
  const isSymbolCondition = reversFlag ? 0 : 1;

  return (
    <>
      {value === (reversFlag ? 1 : 0) ? <ArrowTopColor /> : <ArrowTopWhite className={styles.noSort} />}
      {value === (isAlertTable ? -1 : isSymbolCondition) ? <ArrowDownColor />
        : <ArrowDownWhite className={styles.noSort} />}
    </>
  );
};

export default React.memo(SortIcons);
