import React from 'react';
import PropTypes from 'prop-types';
import SelectedSymbolItem from './SelectedSymbolItem';

const SelectedSymbolsList = ({ selectedList, removeSelectedItem }) => {
  return selectedList.map((item) => (
    <SelectedSymbolItem
      key={item.index}
      item={item}
      removeSelectedItem={removeSelectedItem}
    />
  ));
};

SelectedSymbolsList.propTypes = {
  selectedList: PropTypes.arrayOf(PropTypes.shape({})),
  removeSelectedItem: PropTypes.func
};

export default SelectedSymbolsList;
