import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from './sass/WatchListModals.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { searchItemSym } from '../../utils/helpers';
import constants from '../../constants/filters';
import SearchResultDropDown from './SearchResultDropDown';
import SelectedSymbolsList from './SelectedSymbolsList';
import MultiSelectSearchInput from './MultiSelectSearchInput';
import {
  deleteSelectedItem, setSelectedItemsFromWatchlist, setSelectedSymbolsList
} from '../../store/watchlist/actions';
import useLazySearch from '../../utils/hooks/useLazySearch';
import { KEY_CODES, PAGING_SETTINGS } from '../../constants/screener';
import { prepareSelectedSymbols } from '../../utils/watchListsHelper';
import { setGlobalSearchVisible } from '../../store/account/actions';

const MultiSelectSearch = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ref = useRef(null);
  const refInput = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [resultList, setResultList] = useState([]);
  const [viewDrop, setViewDrop] = useState(false);
  const [noMatchedSymbols, setNoMatchedSymbols] = useState([]);
  const symbols = useSelector((state) => state.screenerState.symbolsList, shallowEqual);

  const activeResult = 0;

  const setInputValueRef = (inputValueRef) => {
    refInput.current = inputValueRef;
    setInputValue(inputValueRef);
  };
  const {
    lazyResult, setLazyResult, paging, setPaging, setPagingHandler
  } = useLazySearch(resultList, setInputValueRef);

  const selectedSymbolsList = useSelector((state) => state.watchlistState.selectedSymbolsList, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);

  const focusHandler = () => {
    ref.current.focus();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      focusHandler();
    }, 100);
    const filtersData = prepareSelectedSymbols(selectedTab, symbolsList);

    dispatch(setSelectedItemsFromWatchlist(filtersData || []));
    return () => {
      setNoMatchedSymbols([]);
      clearTimeout(timeout);
    };
  }, []);

  const setSelectedListHandler = (item) => {
    const findIndex = selectedSymbolsList?.findIndex((subItem) => subItem.sym === item.sym);
    dispatch(setSelectedSymbolsList({ index: findIndex, item }));
    if (inputValue) {
      setInputValue('');
      refInput.current = null;
    }
    focusHandler();
  };

  const keyPressHandler = (event) => {
    if (event.code === KEY_CODES.ESC) {
      setGlobalSearchVisible(false);
    }
    if (event.code === KEY_CODES.ENTER) {
      const activeResultItem = lazyResult[activeResult];
      if (refInput.current && activeResultItem.sym === refInput.current.toUpperCase()) {
        refInput.current = null;
        setInputValue('');
        setSelectedListHandler(activeResultItem);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', keyPressHandler);
    return () => {
      window.removeEventListener('keyup', keyPressHandler);
    };
  }, [activeResult, resultList]);

  const removeSelectedItem = useCallback((item) => {
    dispatch(deleteSelectedItem(item));
  }, [selectedSymbolsList]);

  const searchRequest = () => {
    if (inputValue) {
      const resultOne = searchItemSym(symbols, refInput.current, constants.sym);
      setResultList([...resultOne?.matched]);
      setLazyResult(
        resultOne.matched.length > PAGING_SETTINGS
          ? [...resultOne.matched.splice(paging, PAGING_SETTINGS)] : resultOne.matched
      );
    } else {
      setResultList([]);
      setLazyResult([]);
    }
  };

  const pasteSymbolRequest = (searchString) => {
    const isMultipleRequest = true;
    const resultData = searchItemSym(symbols, searchString, constants.sym, isMultipleRequest);
    setNoMatchedSymbols(resultData?.notMatched);
    if (resultData?.matched?.length) {
      const selectedSymbolsIndexes = selectedSymbolsList?.map(({ index }) => index);
      resultData?.matched.forEach((item) => {
        if (!selectedSymbolsIndexes.includes(item?.index)) {
          setSelectedListHandler(item);
        }
      });
      setLazyResult([...resultData?.matched]);
      setViewDrop(true);
    } else {
      setResultList([]);
      setViewDrop(false);
    }
  };

  useEffect(() => {
    if (inputValue) {
      searchRequest();
      setViewDrop(true);
    }
  }, [inputValue]);

  const handleSetInputValue = (e) => {
    if (e?.nativeEvent?.inputType === constants.pasteEvent) {
      pasteSymbolRequest(e.target.value);
    } else {
      setLazyResult([]);
      setPaging(0);
      refInput.current = e.target.value;
      setInputValue(e.target.value);
      setNoMatchedSymbols([]);
    }
  };

  const getNotMatchedString = () => {
    const invertToUpper = noMatchedSymbols.map((symbol) => symbol?.toUpperCase());
    return <span>{invertToUpper?.join(', ')}</span>;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchWrapper}>
        <SearchIcon className={styles.searchIcon} />
        <div className={styles.searchWrapperSelected}>
          {!!selectedSymbolsList?.length && (
            <SelectedSymbolsList selectedList={selectedSymbolsList} removeSelectedItem={removeSelectedItem} />
          )}
          <MultiSelectSearchInput
            ref={ref}
            setInputValue={handleSetInputValue}
            inputValue={inputValue}
            placeholder={!selectedSymbolsList.length ? t('searchBySymbolInputPlaceholder') : ''}
          />
        </div>
      </div>
      <SearchResultDropDown
        setPagingHandler={setPagingHandler}
        inputValue={inputValue}
        resultList={lazyResult}
        selectedList={selectedSymbolsList}
        setSelectedListHandler={setSelectedListHandler}
        viewDrop={viewDrop}
      />
      {noMatchedSymbols?.length > 0 && (
        <div className={styles.noMatched}>
          {t('notProcessedSymbols')}
          {getNotMatchedString()}
        </div>
      )}
    </div>
  );
};

export default MultiSelectSearch;
