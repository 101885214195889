import ACTION_DASHBOARD from './types';

export const saveItemsScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_ITEMS,
  payload
});

export const setActiveSymbolScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_ACTIVE_SYMBOL,
  payload
});

export const setActiveSymbolOrderScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_ACTIVE_SYMBOL_ORDER,
  payload
});

export const setActiveSymbolTextScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_ACTIVE_SYMBOL_TEXT,
  payload
});

export const columnsWereUpdateScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.COLUMNS_WERE_UPDATE,
  payload
});

export const setSelectedShapeId = (payload) => ({
  type: ACTION_DASHBOARD.SET_SELECTED_SHAPE,
  payload
});

export const filtersWereUpdateScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.FILTERS_WERE_UPDATE,
  payload
});

export const saveFavoritesFiltersAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_FAVORITE_FILTERS,
  payload
});

export const setColumnScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_NEW_COLUMNS,
  payload
});

export const setColumnListAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_LIST_COLUMNS,
  payload
});

export const resetChartDataScreenerAction = () => ({
  type: ACTION_DASHBOARD.RESET_CHART_DATA_DASHBOARD,
});

export const updateOrdersScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.UPDATE_ORDER_COLUMNS,
  payload
});

export const toggleEstimateData = (payload) => ({
  type: ACTION_DASHBOARD.TOGGLE_ESTIMATE,
  payload
});

export const setListForFilterData = (payload) => ({
  type: ACTION_DASHBOARD.SET_LIST_FOR_FILTER,
  payload
});

export const setMarketsFilterScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_FILTER_MARKET,
  payload
});

export const setMarketsFilterWatchlistAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_FILTER_MARKET_WATCHLIST,
  payload
});

export const removeColumnItemScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.REMOVE_COLUMN_ITEM,
  payload
});

export const saveBaseUrlScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_BASE_URL,
  payload
});

export const setColumnsScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_COLUMNS,
  payload
});

export const saveFiltersStateActions = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_FILTERS_DATA,
  payload
});

export const setItemsFirstScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_ITEMS_FIRST,
  payload
});

export const setNextPageFlagScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_NEXT_PAGE_FLAG,
  payload
});

export const setStopTickScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_STOP_TICK,
  payload
});

export const setVisibleItemsScreenerAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_VISIBLE_ITEMS,
  payload
});

export const resetItemsDashboardScreenerAction = () => ({
  type: ACTION_DASHBOARD.RESET_ITEMS_DASHBOARD,
});

export const resetSelectedColumnsScreenerAction = () => ({
  type: ACTION_DASHBOARD.SET_INITIAL_COLUMNS,
});

export const setVisibleColumnsScreenAction = (payload) => ({
  type: ACTION_DASHBOARD.OPEN_COLUMNS_SCREEN,
  payload
});

export const setSymbolsDataAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_SYMBOLS_DATA,
  payload
});

export const setColumnsDataAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_COLUMNS_DATA,
  payload
});
export const setGroupsDataAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_GROUPS_DATA,
  payload
});

export const setTextsDataAction = (payload) => ({
  type: ACTION_DASHBOARD.SAVE_TEXTS_DATA,
  payload
});

export const setDefsUrlsSymbols = (payload) => ({
  type: ACTION_DASHBOARD.SET_DEFS_URLS_SYMBOLS,
  payload
});
export const setDefsUrlsColumns = (payload) => ({
  type: ACTION_DASHBOARD.SET_DEFS_URLS_COLUMNS,
  payload
});
export const setDefsUrlsColumnGroups = (payload) => ({
  type: ACTION_DASHBOARD.SET_DEFS_URLS_COLUMNS_GROUPS,
  payload
});
export const setCurrentTabAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_CURRENT_TAB,
  payload
});
export const saveInitialFiltersStateActions = (payload) => ({
  type: ACTION_DASHBOARD.SET_INITIAL_DATA,
  payload
});

export const setHasBeenUpdatedPopupVisibleAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_HASBEENUPDATED_POPUP_VISIBLE,
  payload
});
export const errorMessageAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_ERROR,
  payload
});

export const setCurrentVersionAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_CURRENT_VERSION,
  payload
});

export const setAdditionalDataScreener = (payload) => ({
  type: ACTION_DASHBOARD.SET_ADDITIONAL_DATA_SCREENER,
  payload
});

export const setSelectedSymbolsScreener = (payload) => ({
  type: ACTION_DASHBOARD.SET_SELECTED_SYMBOLS,
  payload
});

export const setSelectedSymbolsBatchScreener = (payload) => ({
  type: ACTION_DASHBOARD.SET_SELECTED_SYMBOLS_BATCH,
  payload
});

export const clearSelectedSymbolsScreener = () => ({
  type: ACTION_DASHBOARD.CLEAR_SELECTED_SYMBOLS
});

export const setActiveRowStyle = (payload) => ({
  type: ACTION_DASHBOARD.SET_ROW_STYLE,
  payload
});

export const setVolumeDayHistory = (payload) => ({
  type: ACTION_DASHBOARD.SET_VOLUME_DAY_HISTORY,
  payload
});

export const setFirstVolumeDayHistory = (payload) => ({
  type: ACTION_DASHBOARD.SET_FIRST_VOLUME_DAY_HISTORY,
  payload,
});

export const setColumnsObjectId = (payload) => ({
  type: ACTION_DASHBOARD.SET_COLUMNS_OBJECT_ID,
  payload,
});

export const setLoaderAction = (payload) => ({
  type: ACTION_DASHBOARD.SET_LOADER,
  payload,
});

export const setTableSorted = (payload) => ({
  type: ACTION_DASHBOARD.SET_TABLE_SORTED,
  payload,
});

export const setSearchValue = (payload) => ({
  type: ACTION_DASHBOARD.SET_SEARCH_VALUE,
  payload
});

export const setTvIsReady = (payload) => ({
  type: ACTION_DASHBOARD.SET_TV_IS_READY,
  payload
});

export const setTAbUpdateFlag = () => ({
  type: ACTION_DASHBOARD.SET_TAB_UPDATE_FLAG,
});

export const setSearchHeaderValue = (payload) => ({
  type: ACTION_DASHBOARD.SET_SEARCH_HEADER_VALUE,
  payload
});

export const setLayoutsList = (payload) => ({
  type: ACTION_DASHBOARD.SET_LAYOUTS_LIST,
  payload
});

export const setDefaultLayoutID = (payload) => ({
  type: ACTION_DASHBOARD.SET_DEFAULT_LAYOUT,
  payload
});

export const setRoute = (payload) => ({
  type: ACTION_DASHBOARD.SET_ROUTE,
  payload
});

export const setSortData = (payload) => ({
  type: ACTION_DASHBOARD.SET_SORT_DATA,
  payload
});

export const addSortData = (payload) => ({
  type: ACTION_DASHBOARD.ADD_SORT_DATA,
  payload
});

export const setETFSymbolIndex = (payload) => ({
  type: ACTION_DASHBOARD.SET_ETF_SYMBOL_INDEX,
  payload
});

export const toggleSameSymbol = () => ({
  type: ACTION_DASHBOARD.TOGGLE_SAME_SYMBOL_FLAG,
});

export const setDefsUrlsDataPoints = (payload) => ({
  type: ACTION_DASHBOARD.SET_DEFS_URLS_DATA_POINTS,
  payload
});

export const setSharedDashboardItem = (payload) => ({
  type: ACTION_DASHBOARD.SET_SHARED_DADHBOARD_ITEM,
  payload
});
