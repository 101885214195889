import React, { useEffect, useState } from 'react';
import { DROPDOWN_STYLES } from '../types/WidgetChartsEnums';
import { IUseWidgetHeaderHelper } from '../types/WidgetChartsInterfaces';
import { WidgetDropDownStyle } from '../types/WidgetChartsypes';

const useWidgetHeaderHelper: IUseWidgetHeaderHelper = (isOpen, offset, dropDownRef) => {
  const [isTopState, setIsTopState] = useState<boolean>(false);
  const [isLeftState, setIsLeftState] = useState<boolean>(false);
  const [isLeftFolder, setIsLeftFolder] = useState<boolean>(false);

  const getWidgetStyles = (): WidgetDropDownStyle => ({
    top: isTopState ? DROPDOWN_STYLES.TOP_BOTTOM : DROPDOWN_STYLES.TOP_UP,
    left: isLeftState ? DROPDOWN_STYLES.UNSET : DROPDOWN_STYLES.ZERO,
    right: isLeftState ? DROPDOWN_STYLES.ZERO : DROPDOWN_STYLES.UNSET,
  });

  const getFolderWidgetStyles = (): WidgetDropDownStyle => {
    if (!isLeftState) {
      return {
        top: isTopState ? DROPDOWN_STYLES.FOLDER_BOTTOM : DROPDOWN_STYLES.TOP_SUB_ITEM,
        left: isLeftFolder ? DROPDOWN_STYLES.UNSET : DROPDOWN_STYLES.LEFT,
        right: isLeftFolder ? DROPDOWN_STYLES.RIGHT_SUB_ITEM : DROPDOWN_STYLES.UNSET,
      };
    }
    return {
      top: isTopState ? DROPDOWN_STYLES.FOLDER_BOTTOM : DROPDOWN_STYLES.TOP_SUB_ITEM,
      left: isLeftFolder ? DROPDOWN_STYLES.UNSET : DROPDOWN_STYLES.LEFT,
      right: isLeftFolder ? DROPDOWN_STYLES.RIGHT : DROPDOWN_STYLES.UNSET,
    };
  };

  const checkAndSetDropdownPosition = (
    dropdownRef: React.RefObject<HTMLDivElement>,
    setTopState: (isTop: boolean) => void,
    setLeftState: (isLeft: boolean) => void,
  ): void => {
    const { innerHeight, innerWidth } = window;
    const {
      top, right, left, width
    } = dropdownRef.current?.getBoundingClientRect() as DOMRect;

    if (top + DROPDOWN_STYLES.CONTAINER_HEIGHT_OFFSET > innerHeight) {
      setTopState(true);
    } else {
      setTopState(false);
    }
    if ((innerWidth - right) < DROPDOWN_STYLES.CONTAINER_WIDTH_OFFSET
      && left > DROPDOWN_STYLES.LEFT_OFFSET && width < offset) {
      setLeftState(true);
    } else {
      setLeftState(false);
    }
    if ((innerWidth - right) < DROPDOWN_STYLES.FOLDER_WIDTH_OFFSET
      && left > DROPDOWN_STYLES.LEFT_OFFSET && width < DROPDOWN_STYLES.WIDGET_FOLDER_WIDTH) {
      setIsLeftFolder(true);
    } else {
      setIsLeftFolder(false);
    }
  };

  useEffect(() => {
    if (dropDownRef) checkAndSetDropdownPosition(dropDownRef, setIsTopState, setIsLeftState);
  }, [dropDownRef, isOpen]);

  return {
    getWidgetStyles,
    getFolderWidgetStyles
  };
};

export default useWidgetHeaderHelper;
