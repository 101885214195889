import React from 'react';
import ROLES from '../../../constants/authRoles';
import { ReactComponent as PremiumIcon } from '../../../assets/images/icons/accountSettings/premiumIcon.svg';
import { ReactComponent as StarterIcon } from '../../../assets/images/icons/accountSettings/starterIcon.svg';
import { ReactComponent as FounderIcon } from '../../../assets/images/icons/accountSettings/founderIcon.svg';
import styles from '../sass/SidebarScreener.module.scss';
import IIconGenerator from '../interfaces/IIconGenerator';

const IconGenerator = ({ plan }: IIconGenerator): React.ReactElement => {
  switch (plan) {
    case ROLES.FOUNDER:
      return <FounderIcon className={styles.additionalIcon} />;
    case ROLES.STARTER:
      return <StarterIcon className={styles.additionalIcon} />;
    case ROLES.PREMIUM:
      return <PremiumIcon className={styles.additionalIcon} />;
    default:
      return <></>;
  }
};

export default IconGenerator;
