import { useEffect, useRef } from 'react';

const useDebouncedCallback = () => {
  const timersRef = useRef(new Map());

  useEffect(() => {
    return () => {
      timersRef.current.forEach((timer) => clearTimeout(timer));
    };
  }, []);

  return (...args) => {
    const [cb, debounceTime, ...cbArgs] = args;
    const timers = timersRef.current;

    const callbackId = cb.toString();

    const timer = timers.get(callbackId);
    if (timer) clearTimeout(timer);

    timers.set(callbackId, setTimeout(() => {
      cb(...cbArgs);
      timers.delete(callbackId);
    }, debounceTime));
  };
};

export default useDebouncedCallback;
