import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import EducationTabs from './EducationTabs';
import { IEducationHeader } from './types/EducationComponentsInterfaces';

const EducationHeader = ({
  tabs,
  setActiveTab,
  activeTab,
}: IEducationHeader): React.ReactElement => (
  <div className={styles.educationHeader}>
    <div className={styles.leftBlock}>
      <EducationTabs tabs={tabs} setActiveTab={setActiveTab} activeTab={activeTab} />
    </div>
  </div>
);
export default EducationHeader;
