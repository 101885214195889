import React, { useEffect } from 'react';

import { IWidgetRendered } from '../types/DashboardComponentsInterfaces';
import ScreenWidgetTable from './ScreenWidgetTable';
import WidgetHeader from './WidgetHeader';
import useSelectItems from '../hook/useSelectItems';
import ScreenerFooter from './ScreenerFooter';

const DeepListWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe,
  changeSymbolForGroup, openModalHandler,
  activeSymbolIndexValue,
  lastActiveGroup, setSearch, openItemsModalHandler,
  isEditable
}: IWidgetRendered): React.ReactElement => {
  const {
    selectedItems,
    setAllItems,
    handleItemsSelect,
    selectedCount,
    currentCount,
    setCurrentCount,
    clearSelections,
    updatedSelectedOnScroll,
    activeSymbolIndex,
    setActiveSymbolIndex
  } = useSelectItems();

  useEffect(() => {
    clearSelections();
  }, [widget?.deepListId]);

  return (
    <>
      <WidgetHeader
        openModalHandler={openModalHandler}
        setColorGroupe={setColorGroupe}
        updateWidget={updateWidget}
        widget={widget}
        removeWidget={removeWidget}
        selectedItems={selectedItems}
        clearSelections={clearSelections}
        openItemsModalHandler={openItemsModalHandler}
        isEditable={isEditable}
      />
      <ScreenWidgetTable
        setAllItems={setAllItems}
        selectedItems={selectedItems}
        handleItemsSelect={handleItemsSelect}
        lastActiveGroup={lastActiveGroup}
        activeSymbolIndexValue={activeSymbolIndexValue}
        openModalHandler={openModalHandler}
        updateWidget={updateWidget}
        changeSymbolForGroup={changeSymbolForGroup}
        widget={widget}
        setCurrentCount={setCurrentCount}
        updatedSelectedOnScroll={updatedSelectedOnScroll}
        setActiveSymbolIndex={setActiveSymbolIndex}
        setSearch={setSearch}
        clearSelections={clearSelections}
        isDeepList
      />
      <ScreenerFooter
        activeSymbolIndex={activeSymbolIndex}
        selectedCount={selectedCount}
        currentCount={currentCount}
      />
    </>
  );
};

export default DeepListWidget;
