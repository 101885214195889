import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './sass/ChartHeaderFilter.module.scss';

import { ReactComponent as FilterIcon } from '../../assets/images/icons/screener/filterStats.svg';
import DefaultButton from '../DefaultButton/DefaultButton';
import Checkbox from '../Checkbox/Checkbox';
import { chartFilterList } from '../../constants/statsChart';

import { ESTIMATE } from '../../constants/screener';
import SwitchQuartersButton from '../SwitchQuartersButton/SwitchQuartersButton';
import getPosition from './chartHeaderUtil';

const ChartHeaderFilterView = forwardRef(({
  setOpenFilters,
  openFilters,
  checkedFilters,
  handleAddFilters,
  disabledAction,
  estimateVisibleData,
  estimateVisibleHandler,
  numberOfQuarters,
  currentSource,
  setNumberOfQuarters,
  setSource,
  isWidget,
  className,
  componentStyle
}, ref) => {
  const { t } = useTranslation();
  return (
    <div ref={ref} className={`${styles.container} ${className}`}>
      <DefaultButton
        disabled={disabledAction}
        handleClick={() => setOpenFilters(!openFilters)}
        componentStyles={componentStyle}
      >
        <FilterIcon />
      </DefaultButton>
      {openFilters && (
      <ul className={styles.dropdown} style={getPosition(ref, isWidget)}>
        <div className={styles.legend}>{t('legend')}</div>
        <div className={styles.content}>
          <li aria-hidden>
            <Checkbox
              id={ESTIMATE}
              changeHandler={estimateVisibleHandler}
              checked={estimateVisibleData}
            />
            <span>{ESTIMATE}</span>
          </li>
          {chartFilterList.map((item) => (
            <li key={item} aria-hidden>
              <Checkbox
                id={item}
                changeHandler={() => handleAddFilters(item)}
                checked={checkedFilters.find((el) => el === item) !== undefined}
              />
              <span>{item}</span>
            </li>
          ))}
        </div>
        <SwitchQuartersButton
          numberOfQuarters={numberOfQuarters}
          currentSource={currentSource}
          setNumberOfQuarters={setNumberOfQuarters}
          setSource={setSource}
        />
      </ul>
      )}
    </div>
  );
});

ChartHeaderFilterView.propTypes = {
  setOpenFilters: PropTypes.func,
  openFilters: PropTypes.bool,
  checkedFilters: PropTypes.arrayOf(PropTypes.string),
  handleAddFilters: PropTypes.func,
  disabledAction: PropTypes.bool,
  estimateVisibleData: PropTypes.bool,
  estimateVisibleHandler: PropTypes.func,
  numberOfQuarters: PropTypes.number,
  currentSource: PropTypes.string,
  setNumberOfQuarters: PropTypes.func,
  setSource: PropTypes.func,
  isWidget: PropTypes.bool,
  className: PropTypes.string,
  componentStyle: PropTypes.shape({}),
};

ChartHeaderFilterView.defaultProps = {
  disabledAction: false,
  estimateVisibleData: false,
  estimateVisibleHandler: () => {},
  setOpenFilters: () => {},
  openFilters: false,
  checkedFilters: [],
  handleAddFilters: () => {},
  numberOfQuarters: 0,
  currentSource: '',
  setNumberOfQuarters: () => {},
  setSource: () => {},
  isWidget: false,
  className: '',
  componentStyle: {},
};

export default ChartHeaderFilterView;
