import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import { NEW_SCREEN_DATA } from '../../constants/screener';
import MoveToFolderItem from './MoveToFolderItem';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import NewScreenButtonBlock from './NewScreenButtonBlock';

const MoveToModal = ({
  setOpenModal, currentSubItem, currentItemData, moveToFolder, data
}) => {
  const [currentItem, setCurrentItem] = useState(-1);
  const [details, setDetails] = useState();

  const moveToHandler = () => {
    moveToFolder(currentSubItem.id || currentItemData.id, currentItem, details);
    setOpenModal();
  };

  const handleItemClick = (item) => {
    setCurrentItem(item.id);
    setDetails({ folder: item.title, screen: currentSubItem.title || currentItemData.title });
  };

  const { t } = useTranslation();
  return (
    <ButtonListenerWrapper
      handleCancel={() => setOpenModal('')}
      handleConfirm={currentItem > -1 ? moveToHandler : () => {}}
    >
      <div className={stylesModal.moveToFolderWrapper}>
        <div className={stylesModal.moveToFolderHeader}>
          <TextField text={t('moveTo')} styleType="createFolderModalHeader" />
          <div aria-hidden="true" onMouseDown={() => setOpenModal('')}>
            <CloseIcon />
          </div>
        </div>
        <div className={stylesModal.moveToFolderBody}>
          <div className={stylesModal.titleBox}>
            <span className={stylesModal.moveToFolderBodyTitle}>
              {t('chooseFolder')}
            </span>
            <span className={stylesModal.moveToFolderBodyTitleItem}>
              {currentSubItem.title || currentItemData.title}
            </span>
            <span className={stylesModal.moveToFolderBodyTitle}>
              {t('to')}
            </span>
          </div>
          <div className={stylesModal.listFolder}>
            {data.map((item) => {
              const itemStatus = currentItem === item.id;
              return (
                item.type === NEW_SCREEN_DATA.FOLDER ? (
                  <MoveToFolderItem
                    key={item.id}
                    item={item}
                    itemStatus={itemStatus}
                    setCurrentItem={setCurrentItem}
                    handleItemClick={handleItemClick}
                  />
                ) : null
              );
            })}
          </div>
          <NewScreenButtonBlock
            cancelHandler={setOpenModal}
            cancelButton={t('cancel')}
            actionHandler={moveToHandler}
            actionButton={t('move')}
            actionDisabled={currentItem === -1}
          />
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};
MoveToModal.defaultProps = {
  currentSubItem: {
    id: 0,
    type: '',
    title: ''
  }
};
MoveToModal.propTypes = {
  moveToFolder: PropTypes.func.isRequired,
  currentItemData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    countStock: PropTypes.string,
    favorites: PropTypes.bool,
    screensArray: PropTypes.arrayOf(PropTypes.string),
  }),
  currentSubItem: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string
    }),
    PropTypes.string
  ]),
  setOpenModal: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

MoveToModal.defaultProps = {
  currentItemData: PropTypes.shape({
    id: 0,
    title: '',
    countStock: '',
    favorites: false,
    screensArray: [''],
  }),
};

export default MoveToModal;
