import React from 'react';
import styles from '../components/WatchlistTabsBlock/sass/WatchlistTabsBlock.module.scss';
import { ReactComponent as IconEdit } from '../assets/images/icons/screener/edit_square.svg';
import { ALLOWED_TITLE_DL_LENGTH, ALLOWED_TITLE_DL_LENGTH_FIXED, ALLOWED_TITLE_LENGTH } from './screener';

export const moreButtons = [
  {
    title: 'Edit',
    enabled: true,
    textClass: 'renameText'
  },
  {
    title: 'Delete',
    enabled: true,
    textClass: 'deleteText'
  }
];

export const moreButtonsTarget = [
  {
    title: 'Edit',
    enabled: true,
    textClass: 'renameText'
  },
];

export const limitation = {
  chart: ALLOWED_TITLE_DL_LENGTH_FIXED,
  table: ALLOWED_TITLE_DL_LENGTH,
  target: ALLOWED_TITLE_LENGTH
};

export const targetListButtons = [
  {
    title: 'edit',
    textClass: 'renameText',
    enabled: true,
    icon: <IconEdit className={styles.popUpIcon} />
  }
];

export const addSymbolButtons = [
  {
    title: 'cancel',
    textClass: 'cancelButton'
  },
  {
    title: 'add',
    textClass: 'applyButton'
  }
];

export const DELAY_DEBOUNCE = 300;
export const TAB_WIDTH = 75;
export const ADD_BUTTON_TITLE = 'add';
export const VIEW_TYPE_TARGET = 'target';
export const UNIVERSE_TYPE = 'Universe';
export const FAVOURITE_TYPE = 'Favorites';
export const ALERTS_TYPE = 'Alerts';
export const TARGET_TYPE = 'target';
export const MORE_POS = {
  TOP: 20,
  RIGHT: -70,
  RIGHT_TARGET: -50,
  RIGHT_SIDEBAR: 10
};
export const TAB_SYMBOLS_LIMIT = 1000;
