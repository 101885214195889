export const EPS = {
  CATEGORY: 'eps',
  VALUE_DAILY: '_EPS_LINE_DAILY',
  GROWTH_DAILY: '_EPS_GROWTH_DAILY',
};

export const SALES = {
  CATEGORY: 'sales',
  VALUE_DAILY: '_SALES_VALUE_DAILY',
  GROWTH_DAILY: '_SALES_GROWTH_DAILY',
};

export const QUARTER = 'Q';
export const VALUE = 'value';
export const GROWTH = 'growth';
export const SALES_NAME = 'SALES';
export const EPS_NAME = 'EPS';
