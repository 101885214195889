import React from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import styles from '../sass/FilterScreen.module.scss';
import FilterSelectedTitle from './FilterSelectedTitle';
import FilterButtonOrActive from './FilterButtonOrActive';
import FilterRightColContent from './FilterRightColContent';
import RootStateTypes from '../../../store/RootStateTypes';
import { TFilterGroupe } from '../Types';
import { UserProfile } from '../../../utils/Types';

interface IFilterRightCol {
  title: string;
  clearAll: string;
  clearHandler: () => void;
  clearItemFilter: (id: number, activeGroupIndex: number, checkMap: number) => void;
  btnOr: string;
  btnSaveScreen: string;
  addOrLogic: string;
  textTooltip: string;
  activeGroup: number;
  addNewGroupHandler: () => void;
  handlerGroup: (id: number) => void;
  removeGroup: (id: number) => void;
  profile: UserProfile;
  textTooltipPresets: string;
  freeUser: boolean;
  freeTooltipPresets: string;
  handlerSearchFilter: (id: number, type: string, columnId: number, list: TFilterGroupe[]) => void;
  disabled: boolean;
  titleCreateItem: string;
  hideButton: boolean;
  counterSymbols: number | undefined;
  saveScreenHandler: (action: string) => void;
}

const FilterRightCol = ({
  title,
  clearAll,
  clearHandler,
  clearItemFilter,
  btnOr,
  btnSaveScreen,
  addOrLogic,
  textTooltip,
  activeGroup,
  addNewGroupHandler,
  handlerGroup,
  removeGroup,
  profile,
  textTooltipPresets,
  freeUser,
  freeTooltipPresets,
  handlerSearchFilter,
  disabled,
  titleCreateItem,
  hideButton,
  counterSymbols,
  saveScreenHandler,
}: IFilterRightCol): React.ReactElement => {
  const selectedGroupFilters = useSelector(
    (state: RootStateTypes) => state.filtersState.selectedGroupFilters, shallowEqual
  ) as TFilterGroupe[];
  return (
    <div className={styles.col}>
      <div className={styles.applied}>
        <FilterSelectedTitle
          title={title}
          clearBtn={clearAll}
          textTooltip={freeUser ? freeTooltipPresets : textTooltipPresets}
          clearHandler={clearHandler}
          freeUser={freeUser}
          hideButton={hideButton}
        />
        <FilterRightColContent
          removeGroup={removeGroup}
          textTooltip={textTooltip}
          activeGroup={activeGroup}
          hideButton={hideButton}
          handlerGroup={handlerGroup}
          counterSymbols={counterSymbols}
          clearItemFilter={clearItemFilter}
          handlerSearchFilter={handlerSearchFilter}
          selectedGroupFilters={selectedGroupFilters}
        />
        <FilterButtonOrActive
          disabled={disabled}
          profile={profile}
          btnOr={btnOr}
          title={addOrLogic}
          textTooltip={textTooltip}
          btnDisabled={!!selectedGroupFilters[0]?.items?.length}
          btnSaveScreen={btnSaveScreen}
          addNewGroupHandler={addNewGroupHandler}
          saveScreenHandler={saveScreenHandler}
          titleCreateItem={titleCreateItem}
        />
      </div>
    </div>
  );
};

export default FilterRightCol;
