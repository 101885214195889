export const SCROLL_BAR_WIDTH = 9;

export async function drawToCanvas(stream: MediaStream): Promise<HTMLCanvasElement> {
  const canvas = document.createElement('canvas');
  const video = document.createElement('video');
  video.srcObject = stream;

  await video.play();
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  canvas.getContext('2d')?.drawImage(video, 0, 0);

  return canvas;
}

export const cropCanvas = (
  canvas: HTMLCanvasElement,
  x: number, y: number,
  width: number, height: number
): HTMLCanvasElement => {
  const canvasTarget = document.createElement('canvas');
  canvasTarget.height = height; // eslint-disable-line
  canvasTarget.width = width; // eslint-disable-line
  const ctx = canvasTarget.getContext('2d');
  ctx?.drawImage(canvas, x, y, width, height, 0, 0, width, height);
  return canvasTarget;
};

export const cropToComponent = (canvas: HTMLCanvasElement, componentRect: {
  x: number, y: number, width: number, height: number
}): HTMLCanvasElement => cropCanvas(
  canvas,
  componentRect.x,
  componentRect.y,
  componentRect.width,
  componentRect.height
);

export const snapScreen = async (): Promise<HTMLCanvasElement | null> => {
  try {
    const stream = await navigator.mediaDevices.getDisplayMedia(({
      preferCurrentTab: true,
      selfBrowserSurface: 'include',
    } as DisplayMediaStreamOptions));

    const canvas = await drawToCanvas(stream);
    stream.getTracks().forEach((track) => track.stop());

    return canvas;
  } catch (error) {
    console.error(error); // eslint-disable-line
    return null;
  }
};

export const roundedImage = (
  x: number, y: number,
  width: number, height: number,
  radius: number, ctx: CanvasRenderingContext2D
): void => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
};

export const gradientGenerator = (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): void => {
  const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
  gradient.addColorStop(0.1, '#DBBAFD');
  gradient.addColorStop(0.6, '#789AF8');

  ctx.rect(0, 0, canvas.width, canvas.height);
  // eslint-disable-next-line no-param-reassign
  ctx.fillStyle = gradient;
  ctx.fill();
};

export const generateChartRoundedImage = (sourceImg: string): Promise<string | null> => {
  const root = new Image();
  root.src = sourceImg;

  const canvas = document.createElement('canvas');

  const logo = new Image();
  logo.src = `${process.env.REACT_APP_IMG_PATH}whiteModeScreenshotLogo.svg`;

  return new Promise((resolve) => {
    logo.onload = () => {
      canvas.width = root?.width;
      canvas.height = root?.height - 4;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        gradientGenerator(canvas, ctx);

        ctx.save();
        roundedImage(10, 8, root.width - 20, root.height - 22, 10, ctx);
        ctx.clip();

        ctx.drawImage(root, 0, -3, root.width, root.height);
        ctx.restore();

        resolve(canvas.toDataURL());
      }
    };
  });
};

export const applyLogo = (sourceImg: string, theme: string): Promise<string | null> => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const image = new Image();
  image.src = sourceImg;

  const logo = new Image();
  logo.src = `${process.env.REACT_APP_IMG_PATH}whiteModeScreenshotLogo.svg`;

  const logoDark = new Image();
  logoDark.src = `${process.env.REACT_APP_IMG_PATH}shareModalLogo.svg`;

  const currentLogo = theme === 'dark-mode' ? logoDark : logo;

  return new Promise((resolve) => {
    currentLogo.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      ctx?.drawImage(image, 0, 0, image.width, image.height);
      ctx?.drawImage(currentLogo, 0, 0, currentLogo.width, currentLogo.height);
      resolve(canvas.toDataURL());
    };
  });
};
