import moment from 'moment-timezone';
import {
  DATA_TYPES,
  EMPTY_ARRAY,
  LIGHT_LENGTH,
  TABLE_SCREEN_PATH,
} from '../constants/screener';
import numberConstants, { CORRECT_DATE } from '../constants/numberConstants';
import { findIndexItem } from './helpers';
import filtersConstants from '../constants/filters';
import {
  TCommonItem, TDefineColumn,
  TFilterGroupe, TFilterItem,
  TNativeFilter, TSelectGroup, TShowFilter, TValueItem
} from '../components/FilterScreen/Types';
import { SortDataType } from './Types';

export const converterDate = (date: number): string => {
  const dateFormat = new Date(date * filtersConstants.correctData);
  return `${moment(dateFormat).format('MMM DD, YYYY')}`;
};

export const convertDateString = (date: number): Date => new Date(date);

export const refreshStateCheck = (obj: Record<string, boolean>): Record<string, boolean> => {
  const newObj = { ...obj };
  Object.keys(newObj).forEach((element) => {
    newObj[element] = false;
  });
  return newObj;
};

const getRoundValue = (newValue: number): number => {
  let valueNumber = newValue;
  const checkValueLength = `${newValue}`.split('.');
  if (checkValueLength.length > 1) {
    const roundItem = `${checkValueLength[1]}`.slice(0, 4);
    valueNumber = Number(`${checkValueLength[0]}.${roundItem}`);
  }
  return valueNumber;
};

export const workWithInputToolTip = (value: number, typeData: string): number => {
  let newValue = value;
  if (typeof value === 'number' && (DATA_TYPES.includes(typeData) || DATA_TYPES.includes(typeData?.split('_')[0]))) {
    newValue *= numberConstants.percent;
    newValue = getRoundValue(newValue);
  }
  return newValue;
};

export const workWithInputToolTipSetValue = (value: number, typeData: string): number => {
  let newValue = value;
  if (typeof value === 'number' && (DATA_TYPES.includes(typeData) || DATA_TYPES.includes(typeData?.split('_')[0]))) {
    if (newValue !== 0) newValue /= numberConstants.percent;
    newValue = getRoundValue(newValue);
  }
  return newValue;
};

export const getTypeFilter = (array: TDefineColumn[], id: number): string | undefined => {
  let type;
  const indexItem = findIndexItem(array, 'index', id);
  if (indexItem !== -1) {
    type = array[indexItem].type;
  }
  return type;
};

const checkItem = ({
  filterItem, id, category, filter, body
}: {
  filterItem: TCommonItem,
  id: number, filter: TCommonItem,
  category: TFilterGroupe,
  body: {
    categoryName: string,
    groupFilter: boolean,
    nameGroupFilters: string
  }
}): {
  categoryName: string,
  groupFilter: boolean,
  nameGroupFilters: string
} => {
  const obj = { ...body };
  const indexFilter = filterItem.id === id;
  if (indexFilter) {
    obj.categoryName = category.name;
    obj.groupFilter = true;
    obj.nameGroupFilters = filter?.name;
  }
  return obj;
};

export const getCategoryFiltersSearch = (array: TFilterGroupe[], id: number, categoryName?: string): {
  categoryName: string,
  groupFilter: boolean,
  nameGroupFilters: string
} => {
  let body = {
    categoryName: '',
    groupFilter: false,
    nameGroupFilters: ''
  };
  array.forEach((category) => {
    const indexItem = findIndexItem(category.items, 'id', id);
    if (indexItem !== -1) {
      body.categoryName = categoryName as string;
    } else {
      const filterGroups = category.items.filter((item) => item.id === -1);
      filterGroups.forEach((filter) => {
        const filterData = filter.data;
        const filterDeepDataOne = filterData?.length ? filterData[0]?.data : [];
        const filterDeepDataSecond = filterDeepDataOne?.length ? filterDeepDataOne[0]?.data : [];
        if (filterDeepDataSecond?.length && filterDeepDataSecond?.length > EMPTY_ARRAY) {
          filter?.data?.forEach((groupDeepOne) => {
            groupDeepOne?.data?.forEach((groupDeepSecond) => {
              groupDeepSecond?.data?.forEach((filterItem) => {
                body = checkItem({
                  filterItem, id, category, filter, body
                });
              });
            });
          });
        } else if (
          filterDeepDataSecond?.length === EMPTY_ARRAY
          && filterDeepDataOne?.length
          && filterDeepDataOne?.length > EMPTY_ARRAY) {
          filter?.data?.forEach((groupDeepOne) => {
            groupDeepOne?.data?.forEach((filterItem) => {
              body = checkItem({
                filterItem, id, category, filter, body
              });
            });
          });
        } else if (filterDeepDataOne?.length === EMPTY_ARRAY && filter?.data?.length > EMPTY_ARRAY) {
          filter?.data.forEach((filterItem) => {
            body = checkItem({
              filterItem, id, category, filter, body
            });
          });
        }
      });
    }
  });
  return body;
};

const deepFilterData = (
  searchData: TCommonItem[],
  id: number
): { group: TCommonItem | null, selector: TCommonItem | null } => {
  const items: { group: TCommonItem | null, selector: TCommonItem | null } = { group: null, selector: null };
  searchData.forEach((item) => {
    const deepData = item?.data as TCommonItem[];
    if (deepData?.length > EMPTY_ARRAY) {
      for (let i = 0; i < deepData?.length; i += 1) {
        if (deepData[i]?.id === Number(id)) {
          items.selector = deepData[i] as TCommonItem;
          items.group = item;
          break;
        }
      }
    }
  });
  return items;
};

export const getMultipleGroupeSearch = (
  list: TCommonItem[],
  id: number
): TSelectGroup => {
  let items: { group: TCommonItem | null, selector: TCommonItem | null } = { group: null, selector: null };
  if (!id) return items;
  if (list?.length > EMPTY_ARRAY) {
    const dataFirstRound = list;
    if (dataFirstRound[0]?.id !== -1) {
      const getSelect = dataFirstRound?.filter((item) => item?.id === Number(id));
      if (getSelect?.length > EMPTY_ARRAY) {
        const [initSelect] = getSelect;
        items.selector = initSelect;
      }
    } else {
      const deepItem = deepFilterData(dataFirstRound, id);
      items = { ...deepItem } as { group: TCommonItem | null, selector: TCommonItem | null };
    }
  }
  return items;
};

export const createStateCheckbox = (filter: TFilterItem[], id: number): Record<number, boolean> => {
  const obj: Record<number, boolean> = {};
  filter?.forEach((item, index) => {
    if (id) {
      obj[item?.id as number] = item?.id === Number(id);
    } else {
      obj[item?.id as number] = index === EMPTY_ARRAY;
    }
  });
  return obj;
};

export const resetStateCheckBox = (listCheckBox: Record<number, boolean>): Record<number, boolean> => {
  const obj: Record<number, boolean> = {};
  Object.keys(listCheckBox).forEach((item) => {
    obj[Number(item)] = false;
  });
  return obj;
};

export const getFindItem = (arrayGroupFilters: TFilterItem[], el: TNativeFilter): TFilterItem => {
  let findItem = {};
  arrayGroupFilters.forEach((filter) => {
    const filterDataOne = filter?.data[0]?.data;
    const filterDataSecond = filterDataOne?.length ? filterDataOne[0]?.data : [];
    if (filterDataSecond?.length && filterDataSecond?.length > EMPTY_ARRAY) {
      filter?.data?.forEach((groupDeepOne) => {
        groupDeepOne?.data?.forEach((groupDeepSecond) => {
          groupDeepSecond?.data?.forEach((filterItem) => {
            if (filterItem?.id === el[0]) {
              findItem = filterItem;
            }
          });
        });
      });
    } else if (filterDataSecond?.length === EMPTY_ARRAY
      && filterDataOne?.length && filterDataOne?.length > EMPTY_ARRAY) {
      filter?.data?.forEach((groupDeepOne) => {
        groupDeepOne?.data?.forEach((filterItem) => {
          if (filterItem?.id === el[0]) {
            findItem = filterItem;
          }
        });
      });
    } else if (filterDataOne?.length === EMPTY_ARRAY && filter?.data?.length > EMPTY_ARRAY) {
      filter?.data.forEach((filterItem) => {
        if (filterItem?.id === el[0]) {
          findItem = filterItem;
        }
      });
    }
  });
  return findItem as TFilterItem;
};

export const createPlaceholder = (title: string, item: TCommonItem, between = ''): string => {
  const getItem = JSON.parse(JSON.stringify(item)) as TFilterItem;
  const filterItem = getItem?.data.filter((el) => el.fShow);
  getItem.data = filterItem;
  let start = '0';
  let end = '0';
  if (getItem.data.length > EMPTY_ARRAY) {
    start = `${getItem?.data[0]?.name}`;
    end = `${getItem?.data[getItem?.data?.length - 1]?.name}`;
  }
  return `${title} ${start} ${between} ${end}`;
};

export const filterSearchItem = (array: TValueItem[], searchName: string): TValueItem[] => {
  const newArray = array.filter((el) => {
    const name = el.title ? el.title.toLowerCase() : el.title;
    return name ? name.indexOf(searchName.toLowerCase()) !== -1 : null;
  });
  return newArray.sort((a, b) => {
    const nameA = a.title.toLowerCase();
    const nameB = b.title.toLowerCase();
    if (nameA < nameB) {
      return -1;
    } if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
};

const setValue = (filter: TCommonItem, element: TNativeFilter): TCommonItem => {
  const newItem = JSON.parse(JSON.stringify(filter)) as TCommonItem;
  const lastFilterData = element[2] as number[];
  if (filter.fType === filtersConstants.range || filter.fType?.split(':')[0] === filtersConstants.date) {
    const [start, end] = lastFilterData;
    newItem.fVal[0].value = start;
    newItem.fVal[1].value = end;
  } else if (filter.fType === filtersConstants.check) {
    newItem.fVal[0].checked = lastFilterData[0] === LIGHT_LENGTH ? !newItem.fVal[0].checked : false;
    newItem.fVal[1].checked = lastFilterData[0] === EMPTY_ARRAY ? !newItem.fVal[1].checked : false;
  }
  return newItem;
};

export const changeItem = (listFilters: TFilterItem[], el: TNativeFilter): TFilterItem => {
  let findItem = {} as TFilterItem;
  listFilters?.forEach((filter) => {
    const filterDataOne = filter?.data[0]?.data;
    const filterDataSecond = filterDataOne?.length ? filterDataOne[0]?.data : [];
    if (filterDataSecond?.length && filterDataSecond?.length > EMPTY_ARRAY) {
      const newComponent = JSON.parse(JSON.stringify(filter)) as TFilterItem;
      filter?.data?.forEach((groupDeepOne, indexGroup) => {
        groupDeepOne?.data?.forEach((groupDeepSecond, indexSubGroup) => {
          groupDeepSecond?.data?.forEach((filterItem, indexFilter) => {
            if (filterItem?.id === el[0]) {
              const updatedItem = setValue(filterItem, el);
              // eslint-disable-next-line
              // @ts-ignore
              newComponent.data[indexGroup].data[indexSubGroup].data[indexFilter] = updatedItem;
              findItem = newComponent;
            }
          });
        });
      });
    } else if (filterDataSecond?.length === EMPTY_ARRAY
      && filterDataOne?.length && filterDataOne?.length > EMPTY_ARRAY) {
      const newComponent = { ...filter };
      filter?.data?.forEach((groupDeepOne, index) => {
        groupDeepOne?.data?.forEach((filterItem, indexFilter) => {
          if (filterItem?.id === el[0]) {
            const updatedItem = setValue(filterItem, el);
            // eslint-disable-next-line
              // @ts-ignore
            newComponent.data[index].data[indexFilter] = updatedItem;
            findItem = newComponent;
          }
        });
      });
    } else if (filterDataOne?.length === EMPTY_ARRAY && filter?.data?.length > EMPTY_ARRAY) {
      const newComponent = JSON.parse(JSON.stringify(filter)) as TFilterItem;
      filter?.data.forEach((filterItem, index) => {
        if (filterItem?.id === el[0]) {
          const updatedItem = setValue(filterItem, el);
          // eslint-disable-next-line
          // @ts-ignore
          newComponent.fVal[index] = updatedItem;
          findItem = newComponent;
        }
      });
    }
  });
  return findItem;
};

export const getItemFilter = (stateFilters: TFilterItem[], id: number, name: string): TFilterItem => {
  let item = {};
  let typeFilter = '';
  let param;

  if (id === -1) {
    typeFilter = filtersConstants.name;
    param = name;
  } else {
    typeFilter = 'id';
    param = id;
  }
  const indexItem = findIndexItem(stateFilters, typeFilter, param);
  if (indexItem !== -1) {
    item = stateFilters[indexItem];
  }

  return item as TFilterItem;
};

export const searchItem = (array: TShowFilter[], searchName: string, field: string): TShowFilter[] => {
  const newArray = array.filter((el) => {
    const name = el[field as keyof TShowFilter] as string;
    const sName = name ? name?.toLowerCase() : name;
    return sName ? sName?.indexOf(searchName.toLowerCase()) !== -1 : null;
  });
  newArray.splice(numberConstants.countSearch);
  return newArray.sort((a, b) => {
    const nameA = a[field as keyof TShowFilter] as string;
    const nameB = b[field as keyof TShowFilter] as string;
    const sNameA = nameA?.toLowerCase();
    const sNameB = nameB?.toLowerCase();
    if (sNameA < sNameB) {
      return -1;
    } if (sNameA > sNameB) {
      return 1;
    }
    return 0;
  });
};

export const checkStringLength = (filter: TFilterItem, length: number): boolean => {
  const array: string[] = [];
  filter.data.forEach((el) => {
    array.push(el.name);
    if (el?.data?.length && el?.data?.length > EMPTY_ARRAY) {
      el?.data.forEach((item) => {
        array.push(item.name);
      });
    }
  });
  const checkArray = array.filter((item) => item.length > length);
  return checkArray.length > EMPTY_ARRAY;
};

export const getNumbersDayOfMonth = (currentMonth = 1): number => new Date(
  new Date().getFullYear(), new Date().getMonth() + currentMonth, 0
).getDate();

export const getNumbersDayOfNextQuarter = (): number => {
  const array = [...Array(3)];
  let countDays = 0;

  array.forEach((_, index) => {
    countDays += getNumbersDayOfMonth(index + 1);
  });
  return countDays;
};

const createDataFromRequest = (item: TNativeFilter, column: TDefineColumn): TValueItem[] => {
  const items: TValueItem[] = [];
  if (item.length > EMPTY_ARRAY) {
    const lastFilterData = item[2] as number[];
    lastFilterData?.forEach((el) => {
      const body = {
        title: el === EMPTY_ARRAY ? filtersConstants.no : filtersConstants.yes,
        value: el,
        sybType: column?.type === filtersConstants.bool
          ? filtersConstants.check : column?.type,
        checked: true
      };
      items.push(body);
    });
  }
  return items;
};

export const getFilter = (columnsList: TDefineColumn[], item: TNativeFilter): TFilterItem => {
  const indexInvisibleFilter = findIndexItem(columnsList, filtersConstants.index, item[0]);
  const body = {
    id: item[0] as number,
    fVal: createDataFromRequest(item, columnsList[indexInvisibleFilter]),
    name: columnsList[indexInvisibleFilter]?.name,
    sName: columnsList[indexInvisibleFilter]?.shortName,
    fShow: columnsList[indexInvisibleFilter]?.show,
    desc: '',
    data: [],
    fType: columnsList[indexInvisibleFilter]?.type === filtersConstants.bool
      ? filtersConstants.check : columnsList[indexInvisibleFilter]?.type
  };
  return body;
};

export const getDataFilter = (
  filterItem: TFilterItem,
  itemGroup: TNativeFilter,
  indexColum: number,
  columnsList: TDefineColumn[]
): TFilterItem => {
  const getItem = JSON.parse(JSON.stringify(filterItem)) as TFilterItem;
  getItem.fVal = filterItem?.fVal?.map((el, index) => {
    const newElement = { ...el };
    const lastFilterData = itemGroup[2] as number[];
    if (itemGroup[1] === filtersConstants.typeDatePeriod) {
      newElement.value = Date.now() / CORRECT_DATE + lastFilterData[index];
    } else {
      newElement.value = lastFilterData[index];
    }
    newElement.subType = columnsList[indexColum]?.type;
    return newElement;
  });
  return getItem;
};

export const generateFilter = (
  filter: TFilterItem,
  item: TNativeFilter,
  columnsList: TDefineColumn[]
): TFilterItem => {
  let filterGenerate = JSON.parse(JSON.stringify(filter)) as TFilterItem;
  if (filterGenerate.fType === filtersConstants.check) {
    filterGenerate.fVal = filter?.fVal?.map((el) => {
      const newElement = JSON.parse(JSON.stringify(el)) as TValueItem;
      const lastFilterData = item[2] as number[];
      newElement.checked = el.value === lastFilterData[0];
      return newElement;
    });
  } else if (filterGenerate?.fType === filtersConstants.range || filterGenerate?.fType === filtersConstants.dropdown
    || filter?.fType?.split(':')[0] === filtersConstants.date1) {
    const indexColum = findIndexItem(columnsList, filtersConstants.index, filter.id);
    filterGenerate = getDataFilter(filter, item, indexColum, columnsList);
  }
  return filterGenerate;
};

const generateMultiFilter = (
  item: TNativeFilter,
  filter: TFilterItem,
  columnsList: TDefineColumn[],
  indexColum: number
): TValueItem[] => {
  const newArray: TValueItem[] = [];
  const lastFilterData = item[2] as number[];
  lastFilterData?.forEach((element) => {
    const indexElement = findIndexItem(filter.fVal, 'value', element);
    if (indexElement !== -1) {
      const newObject = { ...filter.fVal[indexElement] };
      newObject.checked = true;
      newObject.subType = columnsList[indexColum]?.type;
      newObject.include = item[1] === EMPTY_ARRAY ? filtersConstants.include : filtersConstants.exclude;
      newArray.push(newObject);
    }
  });
  return newArray;
};

const generateCheckFilter = (
  item: TNativeFilter, filter: TFilterItem, columnsList: TDefineColumn[], indexColum: number
): TValueItem[] => {
  const newArray: TValueItem[] = [];
  const lastFilterData = item[2] as number[];
  lastFilterData?.forEach((element) => {
    const indexElement = findIndexItem(filter?.fVal, 'value', element);
    if (indexElement !== -1) {
      const newObject = { ...filter.fVal[indexElement] };
      newObject.checked = true;
      newObject.subType = columnsList[indexColum]?.type;
      newArray.push(newObject);
    }
  });

  return newArray;
};

export const generateSimpleFilter = (
  filter: TFilterItem,
  item: TNativeFilter,
  columnsList: TDefineColumn[],
  indexColum: number
): TFilterItem => {
  let filterGenerate = JSON.parse(JSON.stringify(filter)) as TFilterItem;
  if (filterGenerate.fType === filtersConstants.range) {
    filterGenerate.fVal = filter?.fVal?.map((el, index) => {
      const lastItemFilter = item[2] as number[];
      const newElement = JSON.parse(JSON.stringify(el));
      newElement.value = lastItemFilter[index];
      newElement.subType = columnsList[indexColum]?.type;
      return newElement;
    });
  } else if (filter.fType === filtersConstants.multiselectSearch) {
    const newArray = generateMultiFilter(item, filter, columnsList, indexColum);
    filterGenerate.fVal = newArray;
  } else if (filter.fType === filtersConstants.check) {
    const newArray = generateCheckFilter(item, filter, columnsList, indexColum);
    filterGenerate.fVal = newArray;
  } else if (filter?.fType?.split(':')[0] === filtersConstants.date1) {
    filterGenerate = getDataFilter(filter, item, indexColum, columnsList);
  }

  return filterGenerate;
};

export const checkboxViewHandler = (filter: TFilterItem): boolean => filter && filter?.data[0]?.fType !== 'checkbox';

export const checkEmptyFilterHandler = (
  initialSelectedFilter: TFilterItem,
  selectFilter: TFilterItem
): boolean => initialSelectedFilter?.fVal?.length > 0 && selectFilter?.fVal?.length > 0;

export const dateTypeHandler = (
  selectFilter: TCommonItem
): boolean => selectFilter && selectFilter?.fType?.split(':')[0] === filtersConstants.date;

const concatStrings = (array: string[]): string => array.join(' ');

export const getDeepItemsForSearch = (array: TCommonItem[], categoryName: string, category: string): TShowFilter[] => {
  const resultArray: TShowFilter[] = [];
  if (array[0]?.data?.length) {
    array.forEach((subItem) => {
      subItem?.data?.forEach((deepItem) => {
        if (!subItem.fShow) {
          return;
        }
        const subFilterItem = {
          title: concatStrings([categoryName, subItem.name, deepItem.name]),
          category,
          id: deepItem.id,
        } as TShowFilter;
        resultArray.push(subFilterItem);
      });
    });
  } else {
    array.forEach((firstSubItem) => {
      if (!firstSubItem.fShow) {
        return;
      }
      const firstSubFilterItem = {
        title: concatStrings([categoryName, firstSubItem.name]),
        category,
        id: firstSubItem.id,
      } as TShowFilter;
      resultArray.push(firstSubFilterItem);
    });
  }
  return resultArray;
};

export const includeExcludeHelper = (
  activeTab: string,
  constant: typeof filtersConstants,
  contentOne: TValueItem[],
  contentSecond: TValueItem[]
): TValueItem[] => (activeTab === constant.leftTab ? contentOne : contentSecond);

export const setIncludeExcludeHelper = (array: TValueItem[]): TValueItem[] => (array?.length > 0 ? array : []);

export const setSelectedStatus = (
  stateSelectedOptions: TValueItem[],
  setActiveMultiSelected: (arg: boolean) => void
): void => {
  if (stateSelectedOptions.length > 0) {
    setActiveMultiSelected(true);
  } else {
    setActiveMultiSelected(false);
  }
};

export const currentTabHandler = (typeActiveFilter: string[], tabConstants: typeof filtersConstants): string => (
  typeActiveFilter[1] === tabConstants.include ? tabConstants.leftTab : tabConstants.rightTab
);

export const applyDefaultSorting = (
  path: string,
  screenerSorting: SortDataType[],
  deeplistSorting: SortDataType[]
): SortDataType[] => {
  let newSortData: SortDataType[] = [];

  if (path === TABLE_SCREEN_PATH.SCREENER) {
    newSortData = screenerSorting;
  }
  if (path === TABLE_SCREEN_PATH.DEEPLIST) {
    newSortData = deeplistSorting;
  }

  return newSortData;
};
