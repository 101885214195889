import { removeNonNumeric } from '../../DasboardComponents/utils/utils';
import { BAR_CHART_COLORS } from '../types/WidgetChartsEnums';
import getHeatMapColor from './heatMapColorRange';

// eslint-disable-next-line no-underscore-dangle
export const getHeatMapContextData = (ctx) => ctx.raw._data?.children;

export const heatMapBackgroundColor = (ctx, singleBlockRange, isDark) => {
  if (ctx.type !== 'data') {
    return BAR_CHART_COLORS.transparent;
  }
  const value = parseFloat(removeNonNumeric(ctx.raw.g ?? ''));

  if (!ctx.raw.g) {
    return BAR_CHART_COLORS.nuetral;
  }
  if (Number.isNaN(value)) {
    const isSector = getHeatMapContextData(ctx).find((localItem) => (
      ctx.raw.g === localItem.sector
    ));
    if (isSector) return isDark ? BAR_CHART_COLORS.sectorBgDark : BAR_CHART_COLORS.white;
    return isDark ? BAR_CHART_COLORS.industryBgDark : BAR_CHART_COLORS.industryBg;
  }
  return getHeatMapColor(value, singleBlockRange);
};
