import { StudyShapesPlotPreferences, StudyStylesInfo } from "../../../charting_library/charting_library";

export const defaultStyles = {
  rs_line: {
    plottype: 0,
  },
  rs_ma_line: {
    color: 'red',
    plottype: 0,
  },
  rs_ma_line2: {
    color: '#FF9800',
    plottype: 0,
    transparency: 0
  },
  ma_crossover_up1: {
    color: '#2962ff',
    plottype: 'shape_triangle_up',
    location: 'Bottom' as StudyShapesPlotPreferences['location']
  },
  ma_crossover_down1: {
    color: '#2962ff',
    plottype: 'shape_triangle_down',
    location: 'Bottom' as StudyShapesPlotPreferences['location']
  },
  ma_crossover_up2: {
    color: '#FF9800',
    plottype: 'shape_triangle_up',
    location: 'Bottom' as StudyShapesPlotPreferences['location']
  },
  ma_crossover_down2: {
    color: '#FF9800',
    plottype: 'shape_triangle_down',
    location: 'Bottom' as StudyShapesPlotPreferences['location']
  },
  pink_dot: {
    color: '#F837F8',
    plottype: 'shape_circle',
    location: 'Absolute' as StudyShapesPlotPreferences['location'],
  },
  green_dot: {
    color: '#4CAF50',
    location: 'Absolute' as StudyShapesPlotPreferences['location'],
    plottype: 'shape_circle',
  },
};

export const mainStyles = {
  rs_line: {
    title: 'RS Line',
  },
  rs_ma_line: {
    title: 'RS Line MA 1',
  },
  rs_ma_line2: {
    title: 'RS Line MA 2',
  },
  ma_crossover_up1: {
    title: 'Moving Average Crossover Above 1',
    size: 'tiny' as StudyStylesInfo['size'],
  },
  ma_crossover_down1: {
    title: 'Moving Average Crossover Below 1',
    size: 'tiny' as StudyStylesInfo['size'],
  },
  ma_crossover_up2: {
    title: 'Moving Average Crossover Above 2',
    size: 'tiny' as StudyStylesInfo['size'],
  },
  ma_crossover_down2: {
    title: 'Moving Average Crossover Below 2',
    size: 'tiny' as StudyStylesInfo['size'],
  },
  pink_dot: {
    title: 'RSNHBP',
    size: 'medium' as StudyStylesInfo['size'],
  },
  green_dot: {
    title: 'RSNH',
    size: 'medium' as StudyStylesInfo['size'],
  },
};
