import React, { useState } from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardItemDropdown.module.scss';
import { IAddItemBlock } from '../types/interfaces';
import DefaultButton from '../../DefaultButton/DefaultButton';
import TextField from '../../TextField/TextField';
import { ReactComponent as IconPlus } from '../../../assets/images/icons/screener/color_plus_circle.svg';
import { DasboardsItemsDropdownTypes, AddMenuActions } from '../types/constants';
import { ReactComponent as IconFolder } from '../../../assets/images/icons/screener/folder.svg';
import {
  getAddMenuOptions,
  getAddMenuItemOptions,
  getMenuDividerOptions
} from '../utils/MuiOptionsHelper';
import ThemeVariants from '../../../constants/theme';

const AddItemBlock = ({
  labelAdd,
  labelDescription,
  handleAddItem,
  selectedTab,
  iconAdd,
  theme,
  adminRole,
}: IAddItemBlock): React.ReactElement => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (type: AddMenuActions) => {
    handleAddItem(type);
    handleClose();
  };

  const showAddBlock = (): boolean => {
    if (adminRole) return true;
    if (selectedTab === DasboardsItemsDropdownTypes.PRESET) return false;
    return true;
  };

  const getLabelAdd = (): string => {
    if (selectedTab === DasboardsItemsDropdownTypes.PRESET) return t('labelPresetAdd');
    return labelAdd;
  };

  return (
    <div className={styles.addItemBlock}>
      <div className={styles.addItemBlockDescription}>
        {selectedTab === DasboardsItemsDropdownTypes.PRESET ? t('presets') : labelDescription}
      </div>
      {showAddBlock() && (
      <DefaultButton
        className={styles.addItemBlockButton}
        handleClick={handleClick}
      >
        <IconPlus />
        <TextField text={t('createNewLabel')} styleType="createWatchListButton" />
      </DefaultButton>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={
          {
            sx: {
              boxShadow: '1px 1px 16px 0px rgba(0, 0, 0, 0.10)',
              backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232b3b' : '#FFFFFF'
            }
          }
        }
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: getAddMenuOptions(theme)
        }}
      >
        <MenuItem
          sx={getAddMenuItemOptions(theme, true)}
          disabled
        >
          {t('createNewLabel')}
        </MenuItem>
        <MenuItem
          disableRipple
          sx={getAddMenuItemOptions(theme, false)}
          onClick={() => handleMenuClick(AddMenuActions.ADD_NEW)}
        >
          {iconAdd}
          {' '}
          {getLabelAdd()}
        </MenuItem>
        <Divider sx={getMenuDividerOptions()} />
        <MenuItem
          disableRipple
          sx={getAddMenuItemOptions(theme, false)}
          onClick={() => handleMenuClick(AddMenuActions.ADD_FOLDER)}
        >
          <IconFolder />
          {' '}
          {t('folder')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default AddItemBlock;
