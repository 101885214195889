import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardComponents.module.scss';
import { IColorDropdown } from '../types/DashboardComponentsInterfaces';
import { WidgetColorsTypes, ColorLink } from '../../../pages/Dashboards/types/DashboardEnums';
import { handleOutsideClick, TMouseEvent } from '../../../utils/handleOutsideClick';

const ColorDropdown = ({
  currentColor, setColorGroupe,
  closeDropdown, parrentReff
}: IColorDropdown): React.ReactElement => {
  const { t } = useTranslation();

  const outsideClickHandler = (event: TMouseEvent) => {
    handleOutsideClick(event, closeDropdown, parrentReff);
  };

  useEffect(() => {
    document.addEventListener('click', (event) => outsideClickHandler(event as TMouseEvent));
    return () => {
      document.removeEventListener('click', outsideClickHandler as EventListener);
    };
  }, []);

  return (
    <div className={styles.colorDropdown}>
      <div className={styles.colorDropdownHeader}>
        {t('colorGroupeLabel')}
      </div>
      <div className={styles.colorDropdownItems}>
        {Object.values(WidgetColorsTypes).map((color, index) => (
          <div
            aria-hidden
            key={[color, 'colorDropdownItem'].join('-')}
            className={`${styles.colorDropdownItem} ${color === currentColor && styles.selected}`}
            onClick={() => {
              setColorGroupe(color);
            }}
          >
            <div
              className={`${styles.colorDropdownItemPreview} ${color === WidgetColorsTypes.NONE && styles.emptyColor}`}
              style={{ backgroundColor: ColorLink[color as keyof typeof ColorLink] }}
            >
              {' '}
              {index > 0 ? index : ''}
              {' '}

            </div>
          </div>
        ))}

      </div>

    </div>
  );
};

export default ColorDropdown;
