export const defaultPalettes = {
  rs_line_palette: {
    colors: {
      0: {
        color: 'blue',
        style: 0,
        width: 1,
      },
      1: {
        color: 'fuchsia',
        style: 0,
        width: 1,
      },
      2: {
        color: 'blue',
        style: 0,
        width: 1,
      },
      3: {
        color: 'fuchsia',
        style: 0,
        width: 1,
      },
    },
  },
  rs_rsma_area_palette: {
    colors: {
      0: {
        color: '#0080FF',
        style: 0,
        width: 1,
      },
      1: {
        color: '#FF80FF',
        style: 0,
        width: 1,
      },
    },
  },
  rs_candlesticks_palette: {
    colors: [
      { color: 'blue', style: 0, width: 1 },
      { color: 'fuchsia', style: 0, width: 1 },
    ],
  },
};

export const palettes = {
  rs_line_palette: {
    colors: {
      0: {
        name: 'RS Line Up',
      },
      1: {
        name: 'RS Line Down',
      },
      2: {
        name: 'RS Line Above MA',
      },
      3: {
        name: 'RS Line Below MA',
      },
    },
  },
  rs_rsma_area_palette: {
    colors: {
      0: {
        name: 'RS Line Above MA',
      },
      1: {
        name: 'RS Line Below MA',
      },
    },
    valToIndex: {
      0: 0,
      1: 1,
    },
  },
  rs_candlesticks_palette: {
    colors: {
      0: {
        name: 'RS Candlestick Up',
      },
      1: {
        name: 'RS Candlestick Down',
      },
    },
    valToIndex: {
      0: 0,
      1: 1,
    },
  },
};
