import React from 'react';
import Calendar from 'react-datepicker';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import DefaultButton from '../DefaultButton/DefaultButton';
import styles from './sass/Calendar.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import filters from '../../constants/filters';

const CalendarSingle = ({
  handleJustOpenDatePicker,
  startDate,
  setStartDate,
  placeholder,
  openCalendar,
  cancelSelect,
  applySelectDate,
  cancelText,
  applyText,
  autoCloseCalendar,
  className,
  setActionsFromButton
}) => (
  <div
    className={`${styles.wrapper} ${className} ${openCalendar ? styles.fieldOpen : ''}`}
    onMouseLeave={autoCloseCalendar}
  >
    <CalendarIcon className={styles.datePicker} />
    <div
      aria-hidden="true"
      className={styles.calendarBox}
      onClick={handleJustOpenDatePicker}
      onKeyDown={handleJustOpenDatePicker}
      role="button"
      tabIndex="0"
    >
      <Calendar
        selected={startDate}
        onChange={(date) => {
          setStartDate(date);
          setActionsFromButton([]);
        }}
        placeholderText={placeholder}
        showMonthDropdown
        showYearDropdown
        dropdownMode="scroll"
        yearDropdownItemNumber={60}
        scrollableYearDropdown
        dateFormat="MMMM d, yyyy"
        open={openCalendar}
        popperModifiers={filters.calendarSettings}
      >
        <div className={styles.buttonWrapper}>
          <DefaultButton handleClick={cancelSelect} buttonClass="btnCancelCalendar">{cancelText}</DefaultButton>
          <DefaultButton handleClick={applySelectDate} buttonClass="btnApplyCalendar">{applyText}</DefaultButton>
        </div>
      </Calendar>
    </div>
  </div>
);

CalendarSingle.propTypes = {
  handleJustOpenDatePicker: PropTypes.func,
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  autoCloseCalendar: PropTypes.func,
  setStartDate: PropTypes.func,
  placeholder: PropTypes.string,
  openCalendar: PropTypes.bool,
  cancelSelect: PropTypes.func,
  applySelectDate: PropTypes.func,
  cancelText: PropTypes.string,
  applyText: PropTypes.string,
  className: PropTypes.string,
  setActionsFromButton: PropTypes.func
};

CalendarSingle.defaultProps = {
  handleJustOpenDatePicker: () => {},
  setStartDate: () => {},
  startDate: '',
  placeholder: '',
  openCalendar: false,
  cancelSelect: () => {},
  applySelectDate: () => {},
  cancelText: '',
  applyText: '',
  autoCloseCalendar: () => {},
  className: '',
  setActionsFromButton: () => undefined
};

export default CalendarSingle;
