import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLineChart, setTableChart } from '../../store/statsChart/actions';
import prepareDataHandler from '../statsChartHandlers';
import { WS_CHANNELS } from '../../constants/socketConnection';
import { messageIdGenerator } from '../../components/ChartContainer/utils/chartingHelper';
import { marksDataProcessor } from '../../services/ChartPrefetchServices/MarksDataProcessor';
import { PERIOD_SOURCES } from '../../constants/statsChart';
import { SocketChannels } from '../../services/ChartPrefetchServices/SocketChannelsProcessor';

const useStatsChart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const statsChartDataCallback = (res, source, symbol) => {
    if (!SocketChannels.statsChartData.includes(res.messageId)) {
      return;
    }

    const dataBasedSource = source === PERIOD_SOURCES.SHORT_Q ? res.data[0]?.statsChartQ : res.data[0]?.statsChartY;

    const statsData = dataBasedSource ? dataBasedSource.filter(
      (item) => item?.eps !== undefined && item?.sales !== undefined
    ) : [];

    marksDataProcessor.setMarksDataHandler(res.data[0]?.statsChartQ, symbol);

    const chartData = prepareDataHandler(statsData, dispatch, source, t);
    const { result } = chartData;

    dispatch(setLineChart(result[0]));
    dispatch(setTableChart(result[1]));

    SocketChannels.removeStatsSubscription(res.messageId);
  };

  const getStatsData = (connection, symbol, source) => {
    const socket = connection.getSocket();
    const itemCache = marksDataProcessor.statsChartData[symbol];

    if (itemCache) {
      const cacheDataBasedSource = source === PERIOD_SOURCES.SHORT_Q ? itemCache.quarters : itemCache.years;

      const chartData = prepareDataHandler(cacheDataBasedSource, dispatch, source, t);
      const { result } = chartData;

      dispatch(setLineChart(result[0]));
      dispatch(setTableChart(result[1]));

      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      const uniqueId = messageIdGenerator();
      SocketChannels.setStatsSubscription(uniqueId);

      connection.sendMessage(WS_CHANNELS.SYMBOL_DATA_BATCH, {
        messageId: uniqueId,
        requests: [{
          symbolIndex: symbol,
          markets: ['regular'],
          statsChartQ: true,
          statsChartY: source === PERIOD_SOURCES.SHORT_Y
        }]
      });
    }
  };

  const statsChartWidgetCallback = (res, source, setData, setBorderData, widget) => {
    if (!SocketChannels.statsChartData.includes(res.messageId) && res.messageId !== +widget.id) {
      return;
    }

    const dataBasedSource = source === PERIOD_SOURCES.SHORT_Q
      ? res.data[0]?.statsChartQ : res.data[0]?.statsChartY;
    const statsData = dataBasedSource ? dataBasedSource.filter(
      (item) => item?.eps !== undefined && item?.sales !== undefined
    ) : [];

    const chartData = prepareDataHandler(statsData, dispatch, source, t);

    setData(chartData.result[1]);
    setBorderData(chartData.borderColors);

    SocketChannels.removeStatsSubscription(res.messageId);
  };

  const getStatsDataAsync = (connection, symbol, source, uniqueId) => {
    SocketChannels.setStatsSubscription(+uniqueId);

    connection.sendMessage(WS_CHANNELS.SYMBOL_DATA_BATCH, {
      messageId: +uniqueId,
      requests: [{
        symbolIndex: symbol,
        markets: ['regular'],
        statsChartQ: true,
        statsChartY: source === PERIOD_SOURCES.SHORT_Y
      }]
    });
  };

  return {
    getStatsData, getStatsDataAsync, statsChartDataCallback, statsChartWidgetCallback
  };
};

export default useStatsChart;
