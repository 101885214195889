const closeAll = (setViewDropListSingle, setViewDropListSingleSecond) => {
  setViewDropListSingle(false);
  setViewDropListSingleSecond(false);
};

const setInitialFilterSimple = (filterList, columnId, setInitialSelectedFilter) => {
  const initialFilter = filterList?.filter((item) => columnId === item?.columnId);
  if (initialFilter?.length) {
    setInitialSelectedFilter(initialFilter[0]);
  }
};

const saveStateInitialGroup = (filterList, filterData, setInitialSelectedGroup, setSelectedGroup, isSearch) => {
  if (!isSearch) {
    setInitialSelectedGroup(filterList.filter((el) => el.fShow)[0]);
    setSelectedGroup(filterData.filter((el) => el.fShow)[0]);
  }
};

const initTab = (setHandleTab) => {
  setHandleTab(false);
};

const initCheckboxState = (
  id, filterData, filterItems, setSelectFilter, setInitialSelectedFilter, createStateCheckbox, setListCheckBox
) => {
  setSelectFilter(filterData[0]);
  setInitialSelectedFilter(filterItems[0]);
  const stateCheckbox = createStateCheckbox(filterData, id);
  setListCheckBox(stateCheckbox);
};

export {
  initTab,
  closeAll,
  initCheckboxState,
  setInitialFilterSimple,
  saveStateInitialGroup
};
