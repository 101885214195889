import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { shallowEqual, useSelector } from 'react-redux';
import TextField from '../../TextField/TextField';
import styles from '../sass/ScreenerTable.module.scss';
import { ReactComponent as IconSearch } from '../../../assets/images/icons/screener/search.svg';
import WatchlistEmptyBlock from '../../WatchlistEmptyBlock/WatchlistEmptyBlock';
import { TABLE_SCREEN_PATH } from '../../../constants/screener';

const EmptyTableItem = ({
  showLoader,
  isWidget,
  watchList,
  selectedItemFromWidget,
}) => {
  const { t } = useTranslation();
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);

  const checkEmpty = () => {
    if (showLoader) {
      return false;
    }
    return !showLoader;
  };

  const checkIsDashboardWidget = () => {
    if (isWidget) {
      return !watchList;
    }
    return pagePath === TABLE_SCREEN_PATH.SCREENER;
  };

  return (
    <div className={`${styles.listWrapper}`}>
      <div className={`${styles.screenerTableItem} ${styles.empty} ${styles.screenerTableCell} ${styles.emptyWrapper}`}>
        {checkEmpty() && (
          <>
            {checkIsDashboardWidget() ? (
              <>
                <div className={styles.iconWrapper}>
                  <IconSearch />
                </div>
                <TextField text={t('noResultsFound')} styleType="emptyTitle" />
                <TextField text={t('noFindAnyResults')} styleType="emptyDescription" />
              </>
            ) : <WatchlistEmptyBlock isWidget={isWidget} selectedItemFromWidget={selectedItemFromWidget} />}
          </>
        )}
      </div>
    </div>
  );
};

EmptyTableItem.propTypes = {
  showLoader: PropTypes.bool,
  isWidget: PropTypes.bool,
  watchList: PropTypes.bool,
  selectedItemFromWidget: PropTypes.number,
};

EmptyTableItem.defaultProps = {
  showLoader: false,
  isWidget: false,
  watchList: false,
  selectedItemFromWidget: 0,
};

export default EmptyTableItem;
