import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/AlertsList.module.scss';
import useOutsideAlerter from '../../../utils/hooks/useOutside';
import { ReactComponent as ChevronIcon } from '../../../assets/images/icons/chevronDown.svg';
import SelectDropDown from './SelectDropDown';

const AlertsSelect = ({ selectOption, activeId, setActiveId }) => {
  const { t } = useTranslation();
  const [isDropDownVisible, setDropdownVisible, ref] = useOutsideAlerter(false);

  const selectHandler = (id) => {
    setActiveId(id);
    setDropdownVisible(false);
  };

  return (
    <div className={styles.selectWrapper} ref={ref}>
      <div
        aria-hidden="true"
        className={`${styles.selectValue} ${isDropDownVisible ? styles.active : ''}`}
        onClick={() => setDropdownVisible(!isDropDownVisible)}
      >
        {t(selectOption[activeId].title)}
        <ChevronIcon />
      </div>
      {isDropDownVisible && (
        <SelectDropDown selectHandler={selectHandler} selectOption={selectOption} activeId={activeId} />
      )}
    </div>
  );
};

AlertsSelect.propTypes = {
  selectOption: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.number,
    })
  ).isRequired,
  activeId: PropTypes.number.isRequired,
  setActiveId: PropTypes.func.isRequired,
};

export default AlertsSelect;
