import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { ScreenerTooltipStyles, ScreenerTooltipDarkStyles } from '../ScreenerTooltipStyles';
import getInfoTooltip from '../../../utils/getInfoTooltip';
import ThemeVariants from '../../../constants/theme';
import { useTheme } from '../../../utils/hooks/useTheme';
import styles from '../sass/ScreenerTable.module.scss';

const HeaderItemTooltip = ({ column, isSorted }) => {
  const { theme } = useTheme();

  return (
    <Tooltip
      arrow
      componentsProps={theme === ThemeVariants.LIGHT_MODE ? ScreenerTooltipStyles : ScreenerTooltipDarkStyles}
      title={<div>{getInfoTooltip(column?.name, column?.shortName)}</div>}
    >
      <div className={isSorted ? styles.sortedHeader : ''}>{column?.shortName}</div>
    </Tooltip>
  );
};

HeaderItemTooltip.propTypes = {
  column: PropTypes.objectOf(
    PropTypes.oneOfType(
      [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]
    )
  ).isRequired,
  isSorted: PropTypes.bool.isRequired,
};

export default React.memo(HeaderItemTooltip);
