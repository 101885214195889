import React from 'react';
import PropTypes from 'prop-types';

import SelectPeriodDropdown from './SelectPeriodDropdown';
import styles from '../sass/TableScreener.module.scss';
import { ALERT_TYPES } from '../../../constants/alerts';
import SwitchButton from '../../../components/SwitchButton/SwitchButton';

const AlertSymbolRow = ({
  handleTimeSelect, selected,
  label, symbol,
  labelAlertState, type,
  isActive, changeAlert,
}) => (
  <div className={styles.alertWrapItem}>
    <div className={styles.alertWrapItemCol}>
      <SwitchButton
        className={styles.statusSwitch}
        checked={isActive}
        width={32}
        height={17}
        handleDiameter={13}
        onChange={changeAlert}
      />
      <div className={styles.symbolInfo}>
        <span>
          {labelAlertState}
        </span>
        <span>
          {symbol}
        </span>
      </div>
    </div>
    { type === ALERT_TYPES.PRICE
    && (
    <div className={styles.alertWrapItemCol}>
      <span className={styles.alertFieldLabel}>{label}</span>
      <SelectPeriodDropdown itemSelect={handleTimeSelect} selected={selected} />
    </div>
    )}
  </div>
);

AlertSymbolRow.propTypes = {
  handleTimeSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({}),
  label: PropTypes.string.isRequired,
  symbol: PropTypes.string,
  labelAlertState: PropTypes.string.isRequired,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  changeAlert: PropTypes.func,
};

AlertSymbolRow.defaultProps = {
  selected: undefined,
  symbol: '',
  type: '',
  isActive: false,
  changeAlert: () => undefined,
};

export default React.memo(AlertSymbolRow);
