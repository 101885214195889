import React, { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

import styles from '../sass/TableChart.module.scss';

import { N_A, SOURCES, STATS_CHART_WIDTH } from '../../../constants/statsChart';

import TableTooltip from '../../Tooltips/TableTooltip';
import TableChartStyles from './TableChartStyles';
import { formatter, renderItem } from '../../../utils/convertingDataHelper';
import { useTheme } from '../../../utils/hooks/useTheme';
import { currentBorderHandler, gapBorderHandler, setCurrentColorState } from '../../../utils/statsChartHandlers';

const TableChartItem = ({
  filterItem,
  width,
  data,
  tooltipId,
  setTooltipId,
  tableBorderSettings,
  isWidget
}) => {
  const [itemWidth, setItemWidth] = useState(width);
  const [borderSettings, setBorderSettings] = useState(
    {
      negative: [],
      positive: [],
      halfPositiveNegative: [],
      halfNegativePositive: [],
    }
  );
  const { theme } = useTheme();

  useEffect(() => {
    setCurrentColorState(filterItem, tableBorderSettings, setBorderSettings);
  }, [tableBorderSettings]);

  useEffect(() => {
    setItemWidth(width);
  }, [width]);

  const itemWorker = (item) => typeof item.Earnings === 'number' && item.Earnings?.toFixed(2);

  return (
    <>
      <div className={`${styles.table_content_item} ${isWidget && styles.widgetTableContentItem}`}>
        <div
          className={styles.table_item_title}
        >
          {filterItem}
        </div>
        {data.length ? data.map((item, index) => {
          const preparedItem = item.Earnings === 0 ? 0 : itemWorker(item);
          const earningsItem = item.Earnings > 0 ? `+${preparedItem}%` : `${preparedItem}%`;
          const middleItemFlag = item?.estimate === SOURCES.HISTORY && data[index + 1]?.estimate === SOURCES.ESTIMATE;
          const key = [item?.quarter, item[filterItem], index].join('_');

          return (
            <Tooltip
              open={tooltipId === key}
              key={key}
              componentsProps={{ tooltip: TableChartStyles(item?.estimate === SOURCES.HISTORY, theme) }}
              title={(
                <TableTooltip
                  value={item?.popUpData}
                  item={filterItem}
                  reportDate={item?.reportDate}
                  marketTime={item?.marketTime}
                  historyFlag={item?.estimate === SOURCES.HISTORY}
                />
              )}
            >
              <div
                onMouseEnter={() => setTooltipId(key)}
                onMouseLeave={() => setTooltipId(null)}
                className={
                  `${item.estimate === SOURCES.ESTIMATE ? styles.table_item_quarter_est : styles.table_item_quarter}
                   ${middleItemFlag && styles.table_middle_item}
                   ${styles[currentBorderHandler(borderSettings, index)]}
                   ${styles[gapBorderHandler(data, borderSettings, index)]}`
                }
                style={{ width: `${data.length === 1 ? STATS_CHART_WIDTH : itemWidth}px` }}
              >
                {item.Sales === 0 ? <span className={styles.emptyItemSales}>-</span>
                  : `$${formatter(item.Sales)}`}
                <div className={`${item.Earnings < 0 ? styles.description_red : styles.description_green}`}>
                  {item.Earnings === 0 ? (
                    <span className={styles.emptyItem}>-</span>
                  )
                    : earningsItem}
                </div>
                {item.estimate === SOURCES.ESTIMATE && (<div className={styles.arrowIcon}>{renderItem(item)}</div>)}
              </div>
            </Tooltip>
          );
        }) : <div>{N_A}</div>}
      </div>
    </>
  );
};

TableChartItem.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    Sales: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Earnings: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    quarter: PropTypes.string,
    estimate: PropTypes.string
  })).isRequired,
  filterItem: PropTypes.string.isRequired,
  tooltipId: PropTypes.string,
  setTooltipId: PropTypes.func.isRequired,
  tableBorderSettings: PropTypes.shape({}).isRequired,
  isWidget: PropTypes.bool
};

TableChartItem.defaultProps = {
  isWidget: false,
  tooltipId: null
};

export default TableChartItem;
