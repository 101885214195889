import { IContext, IPineSeries, PineJSStd } from "../../../charting_library/charting_library";

type TPowerTrendState = {
  powerTrendOn: boolean,
  daysLowAboveEMA21: number,
  daysEMA21AboveSMA50: number,
  recentHigh: number,
  prevSMA50: number,
  prevTime: number,
  isDaysLowAboveEMA21Added: boolean,
  isdaysEMA21AboveSMA50Added: boolean,
}

function updatePowerTrendState(powerTrendState: TPowerTrendState, ema21: number, sma50: number, highSeries: IPineSeries, closeSeries: IPineSeries, pineStd: PineJSStd, mcontext: IContext) {
  const newState = { ...powerTrendState };

  newState.recentHigh = pineStd.highest(highSeries, 10, mcontext);
  if (!newState.isDaysLowAboveEMA21Added) {
    const prevCheck = newState.daysLowAboveEMA21;
    newState.daysLowAboveEMA21 = pineStd.low(mcontext) > ema21 ? newState.daysLowAboveEMA21 + 1 : 0;
    if (newState.prevTime === pineStd.time(mcontext) && newState.daysLowAboveEMA21 !== prevCheck) {
      newState.isDaysLowAboveEMA21Added = true;
      newState.daysLowAboveEMA21 = prevCheck;
    }
  }
  if (!newState.isdaysEMA21AboveSMA50Added) {
    const prevCheck = newState.daysEMA21AboveSMA50;
    newState.daysEMA21AboveSMA50 = ema21 > sma50 ? newState.daysEMA21AboveSMA50 + 1 : 0;
    if (newState.prevTime === pineStd.time(mcontext) && newState.daysEMA21AboveSMA50 !== prevCheck) {
      newState.isdaysEMA21AboveSMA50Added = true;
      newState.daysEMA21AboveSMA50 = prevCheck;
    }
  }

  if (
    !newState.powerTrendOn
    && newState.daysLowAboveEMA21 >= 10
    && newState.daysEMA21AboveSMA50 >= 5
    && sma50 > newState.prevSMA50
    && closeSeries.get(0) > closeSeries.get(1)
  ) {
    newState.powerTrendOn = true;
  }

  if (newState.powerTrendOn && (ema21 < sma50 || (closeSeries.get(0) < 0.9 * newState.recentHigh && closeSeries.get(0) < sma50))) {
    newState.powerTrendOn = false;
  }

  newState.prevSMA50 = sma50;
  newState.prevTime = pineStd.time(mcontext);

  return newState;
}
export default updatePowerTrendState;
