import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconCircle } from '../../assets/images/icons/infoCircle.svg';
import TextField from '../TextField/TextField';
import styles from './sass/Tooltip.module.scss';

const Tooltip = ({ text, classType }) => {
  const [view, setView] = useState(false);

  const viewHandler = () => {
    setView(!view);
  };

  return (
    <div
      className={`${styles.container} ${classType ? styles[classType] : ''}`}
      onMouseLeave={() => viewHandler()}
      onMouseEnter={() => viewHandler()}
    >
      <IconCircle />
      {view && (
      <div className={`${styles.dropContainer} ${styles[classType]}`}>
        <div className={styles.textContainer}>
          <TextField text={text} styleType="tooltipText" />
        </div>
        <div className={styles.triangle} />
      </div>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string,
  classType: PropTypes.string
};

Tooltip.defaultProps = {
  text: '',
  classType: ''
};

export default Tooltip;
