import { OhlcStudyPlotStyle } from "../../../charting_library/charting_library";

export const defaultOhlcPlots = {
  rs_candlesticks: {
    borderColor: '#000000',
    color: '#2962ff',
    drawBorder: false,
    drawWick: true,
    plottype: 'ohlc_candles' as OhlcStudyPlotStyle.OhlcCandles,
    visible: true,
    wickColor: '#737375',
  },
};

export const ohlcPlots = {
  rs_candlesticks: {
    isHidden: false,
    title: 'RS Candlesticks',
  },
};
