import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/DcrIcon.module.scss';

const DcrIcon = ({ value }) => {
  const heightOfCandle = value < 100 ? value * 0.2 : 20;
  return (
    <span className={styles.icon}>
      <span
        className={styles.candle}
        style={{ height: `${heightOfCandle}px` }}
      />
    </span>
  );
};

DcrIcon.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
};

DcrIcon.defaultProps = {
  value: null
};
export default DcrIcon;
