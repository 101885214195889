import React from 'react';
import { SxProps } from '@mui/system';
import ThemeVariants from '../../../constants/theme';

const getColorTab = (isSelected: boolean, theme: string): string => {
  if (theme === ThemeVariants.DARK_MODE) {
    return isSelected ? '#3A75E8' : '#d5d4e9';
  }
  return isSelected ? '#3A75E8' : '#59595B';
};

const getColorAddOptions = (theme: string): string => (theme === ThemeVariants.DARK_MODE ? '#B6B6B6' : '#283555');

export const getTabOptions = (isSelected: boolean, theme: string): SxProps => ({
  textTransform: 'unset',
  fontFamily: 'Quicksand',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  color: getColorTab(isSelected, theme),
});

export const getTabsOptions = (theme: string): SxProps => ({
  borderBottom: theme === ThemeVariants.DARK_MODE ? '1px solid #293142' : '1px solid #EBEBEB',
});

export const getTabIndicatorOptions = (): React.HTMLAttributes<HTMLDivElement> => ({
  style: {
    backgroundColor: '#3A75E8',
  }
});

export const getAddMenuOptions = (theme: string): SxProps => ({
  width: '140px',
  height: '100px',
  borderRadius: '6px',
  paddingTop: '2px',
  backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232B3B' : '#FFFFFF',
});

export const getAddMenuItemOptions = (theme: string, isDisabbled: boolean): SxProps => ({
  fontSize: isDisabbled ? '11px' : '12px',
  padding: '8px 16px',
  opacity: '1 !important',
  fontFamily: 'Quicksand',
  color: isDisabbled ? '#7E8699' : getColorAddOptions(theme),
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  '&:hover': {
    backgroundColor: theme === ThemeVariants.DARK_MODE ? '#1B202C' : '#F5F6FF',
  },
  '& > svg': {
    marginRight: '8px',
    width: '16px',
    height: '16px',
  }
});

export const getMenuDividerOptions = (): SxProps => ({
  borderColor: '#283555',
  opacity: '0.07',
  marginTop: '0 !important',
  marginBottom: '0 !important',
});

export const getItemMenuListOptions = (): SxProps => ({
  width: '115px',
  borderRadius: '6px',
  padding: 0
});

export const getItemMenuItemOptions = (theme: string): SxProps => ({
  width: '100%',
  height: '32px',
  padding: '0 16px',
  textTransform: 'unset',
  fontFamily: 'Quicksand',
  fontSize: '13px',
  fontWeight: 700,
  fontStyle: 'normal',
  lineHeight: '16px',
  color: '#3A75E8',
  '& > .delete': {
    color: '#EC6D6D'
  },
  '& > svg': {
    marginRight: '8px',
    width: '16px',
    minWidth: '16px',
    height: '16px',
  },
  '&:hover': {
    backgroundColor: theme === ThemeVariants.DARK_MODE ? '#1B202C' : '#F5F6FF',
  },
});
