import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../../DefaultButton/DefaultButton';
import useMultiChartWorker from '../hooks/useMultiChartWorker';
import { ReactComponent as RefreshIcon } from '../../../assets/images/icons/refresh.svg';
import styles from '../sass/ButtonChartRefresh.module.scss';

const ButtonChartRefresh = (): React.ReactElement => {
  const { t } = useTranslation();
  const { refreshSymbolsMultiChart } = useMultiChartWorker(false);
  const [viewTool, setViewTool] = useState(false);

  const onMouseEnterHandler = () => {
    setViewTool(true);
  };

  const onMouseLeaveHandler = () => {
    setViewTool(false);
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      <DefaultButton handleClick={() => refreshSymbolsMultiChart(false)} buttonClass="btnTwitterExport">
        <RefreshIcon />
      </DefaultButton>
      {viewTool && <div className={styles.hoverDropDown}>{t('refresh')}</div>}
    </div>
  );
};

export default ButtonChartRefresh;
