export const updateUserSettingsHandler = (prevData, payload) => {
  const newData = { ...prevData };
  newData[payload.field] = payload.data;

  return newData;
};

export const updateUserSettingsNestedHandler = (prevData, payload) => {
  const newData = { ...prevData };
  const favouritesData = { ...prevData?.chartFavorites };

  favouritesData[payload.field] = payload.data;
  newData.chartFavorites = favouritesData;

  return newData;
};

export const updateSortingNestedHandler = (prevData, payload) => {
  const newData = { ...prevData };
  const sortingData = { ...prevData?.presetsSorting };

  sortingData[payload.field] = payload.data;
  newData.presetsSorting = sortingData;

  return newData;
};

export const updateColumnSetsPresetScreenerIdsNestedHandler = (prevData, payload) => {
  const newData = { ...prevData };
  const screenerIdsData = { ...prevData?.presetsColumnSetsScreenerIds };

  screenerIdsData[payload.field] = payload.data;
  newData.presetsColumnSetsScreenerIds = screenerIdsData;

  return newData;
};
