import ACTION_ACCOUNT from './types';
import {
  updateColumnSetsPresetScreenerIdsNestedHandler,
  updateSortingNestedHandler,
  updateUserSettingsHandler,
  updateUserSettingsNestedHandler,
} from '../../utils/accountHelper';
import { DEFINE_TIMESCALE_MARK } from '../../constants/tvWidgetOptions';
import { ChartType, FrameType } from '../../components/MultichartView/types/Enums';
import updatePscNestedHandler from '../../utils/positionSizeHelper';
import { chartFilterList, growth, PERIOD_SOURCES } from '../../constants/statsChart';
import updateWidgetChartsHandler from '../../utils/widgetChartsHelper';
import { getFromLocalStorage } from '../../utils/storageWorks';

const INITIAL_STATE = {
  userProfile: {},
  userAvatar: '',
  errorMessage: '',
  subcritionPlan: 'founder',
  activeSubscription: {},
  globalSearchVisible: false,
  twitter: '',
  userSettings: {
    screenerView: 'screener',
    selectedScreenId: 0,
    lastScreenerColumnsSet: 0,
    presetsColumnsList: {},
    openDataPanel: true,
    selectedDataPanelId: null,
    dataPanelSettings: {
      isIndustryOpen: false,
      isSectorOpen: false,
      isGroupOpen: false,
      isSubIndustryOpen: false,
    },
    dataPanelGridType: 'compact',
    columnsWidth: {},
    screenerLayout: {
      chartWidth: 50,
      statsOpen: true,
    },
    chartFavorites: {
      intervals: [],
      chartTypes: [],
      drawingTools: []
    },
    symbolWatermark: '',
    activeChartLayout: '',
    activeChartLayoutId: 0,
    activeChartLayoutIdMobile: 0,
    add_plus_button: '',
    chartproperties: '',
    panePropertiesChanged: {},
    mainSeriesPropertiesChanged: {},
    linetoolbrush: '',
    linetooltrendline: '',
    linetoolfibretracement: '',
    linetooltext: '',
    linetool5pointspattern: '',
    linetoolriskrewardlong: '',
    linetoolemoji: '',
    linetoolray: '',
    linetoolextended: '',
    drawings: '',
    mainSeriesProperties: '',
    priceScale: 1,
    activeRowStyle: {},
    rightOffset: 5,
    rightOffsetBarSpacing: 5,
    lastResolution: '1D',
    screenerSorting: [],
    multiChartSorting: {},
    multiChartTimeframe: FrameType.Daily,
    multiChartType: ChartType.Candlestick,
    presetsSorting: {},
    presetsColumnSetsScreenerIds: {},
    [DEFINE_TIMESCALE_MARK]: '',
    linetoolhorzray: '',
    sessions: '',
    currentSessionFromStore: 'extended',
    currentSessionFromStoreMobile: 'extended',
    layoutFlag: '',
    isAutoMarket: false,
    selectedDashboardId: 0,
    statsSources: PERIOD_SOURCES.SHORT_Q,
    searchHistory: '',
    visibilitySetting: '[]',
    lastTablePath: '',
    theme: getFromLocalStorage('theme'),
    selectedTabId: 0,
    positionSizeLastState: [],
    performanceLastState: [],
    bubbleLastState: [],
    heatmapLastState: [],
    lastScreenerSymbol: {
      name: 'A',
      index: 0,
      position: 0,
      group: 'Pharmaceuticals, Biotechnology & Life Sciences',
      industry: 'Life Sciences Tools & Services',
      sector: 'Health Care',
      subIndustry: 'Life Sciences Tools & Services',
    },
    lastDeepListSymbol: {
      name: 'A',
      index: 0,
      position: 0,
      group: 'Pharmaceuticals, Biotechnology & Life Sciences',
      industry: 'Life Sciences Tools & Services',
      sector: 'Health Care',
      subIndustry: 'Life Sciences Tools & Services',
    },
    lastAlertsSymbol: {
      name: 'A',
      index: 0,
      position: 0,
      group: 'Pharmaceuticals, Biotechnology & Life Sciences',
      industry: 'Life Sciences Tools & Services',
      sector: 'Health Care',
      subIndustry: 'Life Sciences Tools & Services',
    },
    alertColumns: [-3, -4, -5],
    alertSorting: [],
    symbolLock: true,
    sidebarToggle: false,
    isHideSideMenu: false,
    numberOfQuarters: 8,
    checkedFilters: chartFilterList,
    growsData: growth,
    estimateVisibleData: true,
    educationActiveTab: '',
  },
  updatedLastState: false,
  sortData: [],
  userSettingsLoaded: false,
  openPanelDataAdd: false,
  overwriteFlag: false,
  openConfirmModal: false,
  lastStateId: 0,
  isGlobalSearchOnKeyDown: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_ACCOUNT.SAVE_USER_PROFILE:
      return { ...state, userProfile: payload };
    case ACTION_ACCOUNT.SAVE_USER_AVATAR:
      return { ...state, userAvatar: payload };
    case ACTION_ACCOUNT.SAVE_ERROR_MESSAGE:
      return { ...state, errorMessage: payload?.message };
    case ACTION_ACCOUNT.SAVE_USER_ACTIVE_SUBSCRIPTION:
      return { ...state, activeSubscription: payload };
    case ACTION_ACCOUNT.SAVE_TWITTER:
      return { ...state, twitter: payload?.username };
    case ACTION_ACCOUNT.SET_GLOBAL_SEARCH_VISIBLE:
      return { ...state, globalSearchVisible: payload };
    case ACTION_ACCOUNT.SET_LAST_SORT_DATA:
      return { ...state, sortData: payload };
    case ACTION_ACCOUNT.TOGGLE_PANEL_DATA_ADD:
      return { ...state, openPanelDataAdd: payload };
    case ACTION_ACCOUNT.SET_OVERWRITE_FLAG:
      return { ...state, overwriteFlag: payload };
    case ACTION_ACCOUNT.SET_OPEN_CONFIRM_MODAL:
      return { ...state, openConfirmModal: payload };
    case ACTION_ACCOUNT.SET_LAST_STATE_ID:
      return { ...state, lastStateId: payload };
    case ACTION_ACCOUNT.SET_GLOBAL_SEARCH_ON_KEY_DOWN:
      return { ...state, isGlobalSearchOnKeyDown: payload };
    case ACTION_ACCOUNT.LOG_OUT:
      return { ...INITIAL_STATE };

    case ACTION_ACCOUNT.LOADED_USER_SETTINGS:
      return { ...state, userSettingsLoaded: payload };
    case ACTION_ACCOUNT.SAVE_USER_STATE:
      return { ...state, userSettings: { ...state.userSettings, ...payload } };
    case ACTION_ACCOUNT.SAVE_SELECTED_SCREEN_ID:
      return { ...state, userSettings: { ...state.userSettings, selectedScreenId: payload } };
    case ACTION_ACCOUNT.UPDATE_STORE_ACTION:
      return { ...state, userSettings: updateUserSettingsHandler(state.userSettings, payload) };
    case ACTION_ACCOUNT.UPDATE_NESTED_STORE_ACTION:
      return { ...state, userSettings: updateUserSettingsNestedHandler(state.userSettings, payload) };
    case ACTION_ACCOUNT.UPDATE_NESTED_SORTING_ACTION:
      return { ...state, userSettings: updateSortingNestedHandler(state.userSettings, payload) };
    case ACTION_ACCOUNT.UPDATE_NESTED_COLUMN_SETS_PRESETS_SCREENER_IDS_ACTION:
      return { ...state, userSettings: updateColumnSetsPresetScreenerIdsNestedHandler(state.userSettings, payload) };
    case ACTION_ACCOUNT.UPDATED_LAST_STATE:
      return { ...state, updatedLastState: payload };
    case ACTION_ACCOUNT.SET_POSITION_SIZE_CALCULATOR:
      return { ...state, userSettings: updatePscNestedHandler(state.userSettings, payload) };
    case ACTION_ACCOUNT.SET_WIDGET_CHARTS:
      return { ...state, userSettings: updateWidgetChartsHandler(state.userSettings, payload) };
    default:
      return state;
  }
};
