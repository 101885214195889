import screener from './screener';
import tableScreener from './tableScreener';
import sessionDisconnected from './sessionDisconnected';
import filters from './filters';
import statsChart from './statsChart';
import sessionExpired from './sessionExpired';
import bannerMobile from './bannerMobile';
import watchlist from './watchlist';
import errorBoundary from './errorBoundary';
import alerts from './alerts';
import dataPanel from './dataPanel';
import tooltips from './tooltips';
import confirmModal from './confirmModal';
import dashboards from './dashboards';
import positionSize from './positionSize';
import performanceChart from './performanceChart';

export default {
  ...screener,
  ...statsChart,
  ...tableScreener,
  ...sessionExpired,
  ...sessionDisconnected,
  ...filters,
  ...bannerMobile,
  ...watchlist,
  ...errorBoundary,
  ...alerts,
  ...dataPanel,
  ...tooltips,
  ...confirmModal,
  ...dashboards,
  ...positionSize,
  ...performanceChart
};
