import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/RadioButton.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CheckedItem } from '../../assets/images/icons/Checkbox.svg';
import { ReactComponent as InactiveState } from '../../assets/images/icons/Inactive.svg';

const RadioButton = ({
  id,
  handleCheck,
  checked,
  title,
  styleInput,
}) => (
  <label
    htmlFor={id}
    className={styles.container}
  >
    {checked ? <CheckedItem /> : <InactiveState /> }
    <TextField text={title} styleType="checkInput" />
    <input
      id={id}
      type="checkbox"
      checked={checked}
      className={`${styleInput ? styles[styleInput] : ''}`}
      onChange={(event) => handleCheck(event, id)}
    />
  </label>
);

RadioButton.defaultProps = {
  id: -1,
  title: '',
  checked: false,
  styleInput: '',
  handleCheck: () => {},
};

RadioButton.propTypes = {
  id: PropTypes.number,
  checked: PropTypes.bool,
  title: PropTypes.string,
  handleCheck: PropTypes.func,
  styleInput: PropTypes.string,
};

export default RadioButton;
