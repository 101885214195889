import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import ColumnSetsLabel from '../../ColumnSetsItem/ColumnSetsLabel';
import { countColumns } from '../../../utils/helperNewScreenModal';
import TextBlock from './TextBlock';

const BlockSwitcher = ({ hideIcon, currentItem, currentPresetItem }) => (
  <>
    {hideIcon ? (
      <ColumnSetsLabel
        label={currentItem?.title}
        description={countColumns(currentItem?.data?.selectedColumns)
          ? `${countColumns(currentItem?.data?.selectedColumns)} ${t('descriptionColumnsSets')}`
          : t('noDescriptionColumnsSets')}
      />
    ) : (
      <TextBlock 
        title={currentItem.title || currentPresetItem.title}
        countStock={currentItem.countStock || currentPresetItem.countStock}
      />
    )}
  </>
);

BlockSwitcher.propTypes = {
  hideIcon: PropTypes.bool,
  currentItem: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.shape({
      selectedColumns: PropTypes.arrayOf(PropTypes.number)
    }),
    countStock: PropTypes.string
  }),
  currentPresetItem: PropTypes.shape({
    title: PropTypes.string,
    countStock: PropTypes.string
  }),
};

BlockSwitcher.defaultProps = {
  hideIcon: false,
  currentItem: {},
  currentPresetItem: {}
};

export default BlockSwitcher;
