import React from 'react';
import styles from '../sass/DashboardComponents.module.scss';
import ButtonTwitterShare from '../../TwitterShare/ButtonTwitterShare';

const DashboardsHeaderActions = (): React.ReactElement => (
  <div className={styles.dashboardsHeaderActionBlock}>
    <ButtonTwitterShare />
  </div>
);

export default DashboardsHeaderActions;
