import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useEffect } from 'react';
import { userPanel } from '../../constants/account';
import {
  setDashboards, setDashboardFolders, setDashboardPresets,
  setDashboardPresetsFolders, setDashboardsItems, setDashboardsItemsPresets
} from '../../store/dashboards/slice';
import $api from '../../http';
import {
  getPresetsNewScreen,
  getItemsNewScreen,
  setFolders,
  setScreens,
  setPresets,
  setPresetFolders,
  setColumnsSetFolders,
  setColumnsSet,
  getColumnsSetItems,
  setDataPanels,
  setDataPanelsPresets,
  setDataPanelsFolder,
  setDataPanelsPresetsFolders,
  setDataPanelsItems,
  setDataPanelsItemsPresets,
  setSelectedDataPanel,
  getColumnsSetItemsPreset,
  setColumnsSetFoldersPreset,
  setColumnsSetPreset
} from '../../store/newScreen/actions';
import numberConstant from '../../constants/numberConstants';
import { setToLocalStorage } from '../storageWorks';
import constants from '../../constants/filters';
import { getDataForRequest } from '../helpers';
import { getUrl, getUrlSecond } from '../helpersWorkItems';
import { fillSpeedValuesHandler } from './useSpeedCheckup';
import {
  setItemsDeeplist, setPresetsDeeplist, setWatchlistData,
  setWatchlistFolder,
  setWatchlistPreset,
  setWatchlistPresetFolder
} from '../../store/watchlist/actions';
import useLastStateUpdateStore from './useLastStateUpdateStore';
import { SELECTED_DATA_PANEL_ID } from '../../constants/storage';
import { SendGAEvent } from '../ga';

const useWorkerItems = () => {
  const foldersState = useSelector((state) => state.newScreenState.folders, shallowEqual);
  const screenState = useSelector((state) => state.newScreenState.screens, shallowEqual);
  const presetFoldersState = useSelector((state) => state.newScreenState.presetFolders, shallowEqual);
  const presetsState = useSelector((state) => state.newScreenState.presets, shallowEqual);
  const columnSetFolder = useSelector((state) => state.newScreenState.columnSetFolder, shallowEqual);
  const columnSets = useSelector((state) => state.newScreenState.columnSets, shallowEqual);
  const columnSetPresetsFolder = useSelector((state) => state.newScreenState.columnSetPresetFolder, shallowEqual);
  const columnSetsPresets = useSelector((state) => state.newScreenState.columnSetsPreset, shallowEqual);
  const itemsNewScreen = useSelector((state) => state.newScreenState.itemsNewScreen, shallowEqual);
  const itemsWatchlist = useSelector((state) => state.watchlistState.itemsWatchlist, shallowEqual);
  const dataPanels = useSelector((state) => state.newScreenState.dataPanels, shallowEqual);
  const dataPanelsFolders = useSelector((state) => state.newScreenState.dataPanelsFolders, shallowEqual);
  const dataPanelsPresets = useSelector((state) => state.newScreenState.dataPanelsPresets, shallowEqual);
  const dataPanelsPresetsFolders = useSelector((state) => state.newScreenState.dataPanelsPresetsFolders, shallowEqual);
  const dashboards = useSelector((state) => state.dashboardsState.dashboards, shallowEqual);
  const dashboardFolders = useSelector((state) => state.dashboardsState.dashboardFolders, shallowEqual);
  const dashboardPresets = useSelector((state) => state.dashboardsState.dashboardPresets, shallowEqual);
  const dashboardPresetFolders = useSelector((state) => state.dashboardsState.dashboardPresetFolders, shallowEqual);
  const deeplistsFoldersState = useSelector((state) => state.watchlistState.folders, shallowEqual);
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const deeplistsPresetFoldersState = useSelector((state) => state.watchlistState.presetFolders, shallowEqual);
  const deeplistsPresetsState = useSelector((state) => state.watchlistState.presets, shallowEqual);
  const selectedDataPanel = useSelector(
    (state) => state.newScreenState?.selectedDataPanel, shallowEqual
  );
  const selectedDataPanelId = useSelector(
    (state) => state.accountState?.userSettings?.selectedDataPanelId, shallowEqual
  );

  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);

  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const getCleanedCount = (items, isWatchlist = false) => {
    if (isWatchlist) {
      return items.filter((item) => ![constants.folder, 'target'].includes(item?.type)
      && !item.isDefault && item?.data?.subType !== 'Universe').length;
    }
    return items.filter((item) => item.type !== constants.folder && !item.isDefault).length;
  };

  const getMapWidgets = (items) => {
    const widgetsMap = {};
    items.forEach((item) => {
      if (item.type !== constants.folder) {
        if (item?.data && item?.data?.widgets) {
          getMapWidgets(item?.data?.widgets);
        } else {
          widgetsMap[item?.type] = widgetsMap[item?.type] ? widgetsMap[item?.type] + 1 : 1;
        }
      }
    });
    return widgetsMap;
  };

  const saveData = (data, type) => {
    switch (type) {
      case constants.folder:
        dispatch(setFolders(data));
        break;
      case constants.folderDeeplist:
        dispatch(setWatchlistFolder(data));
        break;
      case constants.deepList:
        dispatch(setWatchlistData(data));
        break;
      case constants.screener:
        dispatch(setScreens(data));
        break;
      case constants.preset:
        dispatch(setPresets(data));
        break;
      case constants.presetDeeplist:
        dispatch(setWatchlistPreset(data));
        break;
      case constants.presetFolder:
        dispatch(setPresetFolders(data));
        break;
      case constants.presetFolderDeeplist:
        dispatch(setWatchlistPresetFolder(data));
        break;
      case constants.columnSetFolder:
        dispatch(setColumnsSetFolders(data));
        break;
      case constants.columns:
        dispatch(setColumnsSet(data));
        break;
      case constants.columnSetFolderPreset:
        dispatch(setColumnsSetFoldersPreset(data));
        break;
      case constants.columnsPreset:
        dispatch(setColumnsSetPreset(data));
        break;
      case constants.dataPanel:
        dispatch(setDataPanels(data));
        break;
      case constants.dataPanelFolder:
        dispatch(setDataPanelsFolder(data));
        break;
      case constants.dataPanelPresets:
      {
        dispatch(setDataPanelsPresets(data));
        if (!selectedDataPanel) {
          const defaultDataPanel = dataPanels?.find((dataPanel) => dataPanel?.title === userPanel)
            || dataPanels[0];
          const presetDataPanel = data?.find((dataPanel) => dataPanel?.id === selectedDataPanelId);
          dispatch(setSelectedDataPanel(presetDataPanel || defaultDataPanel));
          updateStoreHandler(SELECTED_DATA_PANEL_ID, presetDataPanel?.id || defaultDataPanel?.id);
        }
        break;
      }
      case constants.dataPanelPresetFolder:
        dispatch(setDataPanelsPresetsFolders(data));
        break;
      case constants.userDashboard:
        if (JSON.stringify(dashboards) !== JSON.stringify(data)) {
          dispatch(setDashboards(data));
        }
        break;
      case constants.userDashboardFolder:
        if (JSON.stringify(dashboardFolders) !== JSON.stringify(data)) {
          dispatch(setDashboardFolders(data));
        }
        break;
      case constants.userDashboardPresets:
        if (JSON.stringify(dashboardPresets) !== JSON.stringify(data)) {
          dispatch(setDashboardPresets(data));
        }
        break;
      case constants.userDashboardPresetsFolder:
        if (JSON.stringify(dashboardPresetFolders) !== JSON.stringify(data)) {
          dispatch(setDashboardPresetsFolders(data));
        }
        break;
      default:
        break;
    }
  };

  const requestHandler = (firstArray, count, type) => {
    const start = Date.now();
    const numberRequest = Math.ceil(count / numberConstant.limitItems);
    const totalItems = numberRequest - numberConstant.decreaseCount > numberConstant.zeroCount ? [...firstArray] : [];
    const array = getDataForRequest(numberRequest);

    const url = getUrl(type);
    const urlSecond = getUrlSecond(type);
    const requests = array.map((item) => {
      return new Promise((resolve) => {
        $api.get(
          `${url}?${urlSecond}&page=${item}&limit=${numberConstant.limitItems}`,
        ).then((response) => {
          let data = response.data.items;
          if (type === constants.folder
            || type === constants.folderDeeplist
            || type === constants.presetFolder
            || type === constants.presetFolderDeeplist
            || type === constants.columnSetFolder
            || type === constants.columnSetFolderPreset
            || type === constants.dataPanelFolder
            || type === constants.dataPanelPresetFolder
            || type === constants.userDashboardFolder
            || type === constants.userDashboardPresetsFolder
          ) {
            data = totalItems.concat(response.data.items).map((itemFolder) => {
              return {
                id: itemFolder.id, items: [], title: itemFolder.name, type: constants.folder
              };
            });
          }
          resolve(data);
        });
      });
    });
    Promise.all(requests).then((body) => {
      let newArray;
      if (
        type === constants.folder
        || type === constants.folderDeeplist
        || type === constants.presetFolderDeeplist
        || type === constants.presetFolder
        || type === constants.columnSetFolder
        || type === constants.columnSetFolderPreset
        || type === constants.dataPanelFolder
        || type === constants.dataPanelPresetFolder
        || type === constants.userDashboardFolder
        || type === constants.userDashboardPresetsFolder
      ) {
        newArray = [...body[0]];
      } else {
        newArray = [...totalItems];
        body.forEach((res) => {
          if (res) {
            newArray.push(...res);
          }
        });
      }
      saveData(newArray, type);

      // speed check temporary data
      const end = Date.now();
      const speedCheck = end - start;
      fillSpeedValuesHandler(`get_dashboardItem_type_${type}`, speedCheck);
      // eslint-disable-next-line no-console
      console.log(`=> get_dashboardItem_type_${type}`, speedCheck);
    }).catch((e) => setToLocalStorage('errorMessage', e.message));
  };

  const getFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.folder);
  };

  const getDeeplistFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.folderDeeplist);
  };

  const getScreens = (firstArray, count) => {
    requestHandler(firstArray, count, constants.screener);
  };

  const getPresets = (firstArray, count) => {
    requestHandler(firstArray, count, constants.preset);
  };

  const getDataPanelFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.dataPanelFolder);
  };

  const getDataPanelPresetFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.dataPanelPresetFolder);
  };

  const getDataPanelPreset = (firstArray, count) => {
    requestHandler(firstArray, count, constants.dataPanelPresets);
  };

  const getDataPanel = (firstArray, count) => {
    requestHandler(firstArray, count, constants.dataPanel);
  };

  const getPresetFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.presetFolder);
  };

  const getDeeplistPresetFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.presetFolderDeeplist);
  };

  const getDeeplistPreset = (firstArray, count) => {
    requestHandler(firstArray, count, constants.presetDeeplist);
  };

  const getDeeplists = (firstArray, count) => {
    requestHandler(firstArray, count, constants.deepList);
  };

  const getColumnsPresetFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.columnSetFolderPreset);
  };

  const getColumnsPreset = (firstArray, count) => {
    requestHandler(firstArray, count, constants.columnsPreset);
  };

  const getColumnsSetFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.columnSetFolder);
  };

  const getColumnsSet = (firstArray, count) => {
    requestHandler(firstArray, count, constants.columns);
  };

  const getDashboardsFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.userDashboardFolder);
  };

  const getDashboardsItems = (firstArray, count) => {
    requestHandler(firstArray, count, constants.userDashboard);
  };

  const getDashboardsPresetsFolders = (firstArray, count) => {
    requestHandler(firstArray, count, constants.userDashboardPresetsFolder);
  };

  const getDashboardsPresets = (firstArray, count) => {
    requestHandler(firstArray, count, constants.userDashboardPresets);
  };

  const joinState = (foldersItems, screensItems, type) => {
    let newArray = foldersItems.map((item) => {
      return {
        id: item.id, items: [], title: item.title, type: item.type
      };
    });

    screensItems?.forEach((item) => {
      const findIndex = newArray.findIndex((subItem) => subItem.id === item.folder?.id);
      if (findIndex !== -1) {
        newArray[findIndex].items.push(item);
      } else {
        newArray.push({ ...item, items: [] });
      }
    });

    switch (type) {
      case constants.preset: {
        dispatch(getPresetsNewScreen(newArray));
        break;
      }
      case constants.presetDeeplist: {
        dispatch(setPresetsDeeplist(newArray));
        break;
      }
      case constants.screener: {
        newArray = newArray.map((item) => {
          if (item.type !== constants.folder) {
            const newItem = itemsNewScreen.find((subItem) => subItem.id === item.id);
            if (newItem) {
              return { ...item, isFavorite: newItem?.isFavorite };
            }
            return item;
          }
          return item;
        });
        dispatch(getItemsNewScreen(newArray));
        SendGAEvent('user_custom_screeners', {
          count: newArray.length,
          cust_identify: profile?.id,
          event_category: 'engagement',
          event_label: 'User Custom Screeners',
        });
        break;
      }
      case constants.columns: {
        dispatch(getColumnsSetItems(newArray));
        SendGAEvent('user_custom_cs', {
          count: getCleanedCount(newArray),
          cust_identify: profile?.id,
          event_category: 'engagement',
          event_label: 'User Custom Column Sets',
        });
        break;
      }
      case constants.columnsPreset: {
        dispatch(getColumnsSetItemsPreset(newArray));
        break;
      }
      case constants.dataPanel: {
        dispatch(setDataPanelsItems(newArray));
        SendGAEvent('user_custom_dp', {
          count: getCleanedCount(newArray),
          cust_identify: profile?.id,
          event_category: 'engagement',
          event_label: 'User Custom Data Panels',
        });
        break;
      }
      case constants.dataPanelPresets: {
        dispatch(setDataPanelsItemsPresets(newArray));
        break;
      }
      case constants.userDashboard: {
        dispatch(setDashboardsItems(newArray));
        SendGAEvent('user_custom_dashboards', {
          count: getCleanedCount(newArray),
          cust_identify: profile?.id,
          event_category: 'engagement',
          event_label: 'User Custom Dashboards',
        });
        const mappedWidgets = getMapWidgets(newArray);
        Object?.keys(mappedWidgets)?.forEach((key) => {
          SendGAEvent('user_popular_dash_widgets', {
            count: mappedWidgets[key],
            cust_identify: profile?.id,
            widget_type: key,
            event_category: 'engagement',
            event_label: 'User Widget',
          });
        });
        break;
      }
      case constants.userDashboardPresets: {
        dispatch(setDashboardsItemsPresets(newArray));
        break;
      }
      case constants.deepList: {
        newArray = newArray.map((item) => {
          if (item.type !== constants.folder) {
            const newItem = itemsWatchlist.find((subItem) => subItem.id === item.id);
            if (newItem) {
              return { ...item, isFavorite: newItem?.isFavorite };
            }
            return item;
          }
          return item;
        });
        dispatch(setItemsDeeplist(newArray));
        SendGAEvent('user_custom_watchlist', {
          count: getCleanedCount(newArray, true),
          cust_identify: profile?.id,
          event_category: 'engagement',
          event_label: 'User Custom Watchlists',
        });
        break;
      }
      default: break;
    }
  };

  useEffect(() => {
    joinState(deeplistsFoldersState, tabsList, constants.deepList);
  }, [deeplistsFoldersState, tabsList]);

  useEffect(() => {
    joinState(foldersState, screenState, constants.screener);
  }, [foldersState, screenState]);

  useEffect(() => {
    joinState(presetFoldersState, presetsState, constants.preset);
  }, [presetFoldersState, presetsState]);

  useEffect(() => {
    joinState(deeplistsPresetFoldersState, deeplistsPresetsState, constants.presetDeeplist);
  }, [deeplistsPresetFoldersState, deeplistsPresetsState]);

  useEffect(() => {
    joinState(dataPanelsFolders, dataPanels, constants.dataPanel);
  }, [dataPanels, dataPanelsFolders]);

  useEffect(() => {
    joinState(dataPanelsPresetsFolders, dataPanelsPresets, constants.dataPanelPresets);
  }, [dataPanelsPresets, dataPanelsPresetsFolders]);

  useEffect(() => {
    joinState(dashboardFolders, dashboards, constants.userDashboard);
  }, [dashboards, dashboardFolders]);

  useEffect(() => {
    joinState(dashboardPresetFolders, dashboardPresets, constants.userDashboardPresets);
  }, [dashboardPresetFolders, dashboardPresets]);

  useEffect(() => {
    joinState(columnSetFolder, columnSets, constants.columns);
  }, [columnSetFolder, columnSets]);

  useEffect(() => {
    joinState(columnSetPresetsFolder, columnSetsPresets, constants.columnsPreset);
  }, [columnSetPresetsFolder, columnSetsPresets]);

  return {
    getFolders,
    getScreens,
    getPresets,
    getColumnsSet,
    getPresetFolders,
    getColumnsSetFolders,
    getDeeplists,
    getDeeplistFolders,
    getDeeplistPreset,
    getDeeplistPresetFolders,
    getDataPanelFolders,
    getDataPanelPresetFolders,
    getDataPanelPreset,
    getDataPanel,
    getDashboardsFolders,
    getDashboardsPresetsFolders,
    getDashboardsItems,
    getDashboardsPresets,
    getColumnsPresetFolders,
    getColumnsPreset
  };
};

export default useWorkerItems;
