import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { saveUserState } from '../../../store/account/actions';
import { ALERT_COLUMNS_SETTINGS, SELECT_SIZE_BY_TYPE, TABLE_COLUMNS_MIN_SIZES } from '../../../constants/screener';
import { ALERT_COLUMN_INDEXES } from '../../../constants/alerts';

const useColumnsSizeState = (alertTable) => {
  const dispatch = useDispatch();
  const ref = useRef({});
  const userLastState = useSelector((state) => state.accountState.userSettings, shallowEqual);
  const columnSetId = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const userColumnsSizeState = useSelector((state) => state.accountState.userSettings?.columnsWidth, shallowEqual);
  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns);

  useEffect(() => {
    ref.current = userLastState;
  }, [userLastState]);

  const columnSizeStartState = () => {
    if (userColumnsSizeState) {
      return userColumnsSizeState[alertTable ? ALERT_COLUMNS_SETTINGS : columnSetId];
    }
    return {};
  };

  const startState = columnSizeStartState();

  const [columnsSize, setColumnSize] = useState(startState);

  const saveScreenerColumnsState = (columnSet, idFromWidget) => {
    const currentColumnSetId = idFromWidget || columnSetId;

    const newState = {
      ...ref.current,
      columnsWidth: {
        ...ref.current?.columnsWidth,
        [alertTable ? ALERT_COLUMNS_SETTINGS : currentColumnSetId]: columnSet
      }
    };

    dispatch(saveUserState(newState));
  };

  const fillColumnsSizeState = () => {
    const result = {};

    const newState = { ...ref.current };
    const currentId = [alertTable ? ALERT_COLUMNS_SETTINGS : columnSetId];

    if (alertTable) {
      Object.values(ALERT_COLUMN_INDEXES).forEach((item) => {
        const alertState = userColumnsSizeState[ALERT_COLUMNS_SETTINGS];
        if (alertState && alertState[item]) {
          result[item] = userColumnsSizeState[ALERT_COLUMNS_SETTINGS][item];
        } else {
          result[item] = {
            minWidth: TABLE_COLUMNS_MIN_SIZES[item] || 60,
            startWidth: 60,
            maxWidth: 0
          };
        }
      });
    }

    selectedColumns.forEach((item) => {
      const TYPE = item?.type?.split('_')[0] || '';
      const conditions = userColumnsSizeState && userColumnsSizeState[currentId];

      if (conditions && userColumnsSizeState[currentId][item.id]) {
        result[item.id] = userColumnsSizeState[currentId][item.id];
      } else {
        result[item.id] = {
          minWidth: SELECT_SIZE_BY_TYPE[TYPE] || 60,
          startWidth: 60,
          maxWidth: 0
        };
      }
    });

    newState.columnsWidth = {
      ...newState.columnsWidth,
      ...{ [currentId]: result }
    };

    dispatch(saveUserState(newState));
  };

  useEffect(() => {
    if (selectedColumns.length > 0 && columnSetId) {
      fillColumnsSizeState();
    }
  }, [selectedColumns, columnSetId]);

  useEffect(() => {
    if ((columnSetId > 0 || alertTable) && userColumnsSizeState) {
      setColumnSize(userColumnsSizeState[alertTable ? ALERT_COLUMNS_SETTINGS : columnSetId]);
    }
  }, [columnSetId, userColumnsSizeState, alertTable]);

  useEffect(() => () => {
    ref.current = null;
  }, []);

  return {
    saveScreenerColumnsState,
    columnsSize
  };
};

export default useColumnsSizeState;
