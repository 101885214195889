import React from 'react';
import { useTranslation } from 'react-i18next';
import Gleap from 'gleap';
import { useDispatch } from 'react-redux';

import styles from './sass/NewVersionPopUp.module.scss';
import { ReactComponent as ShuttleIcon } from '../../assets/images/icons/shuttle.svg';
import DefaultButton from '../DefaultButton/DefaultButton';
import { PRODUCTION } from '../../constants/environment';
import { setHasBeenUpdatedPopupVisibleAction } from '../../store/screener/actions';

const NewVersionPopUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (process.env.NODE_ENV === PRODUCTION) {
    Gleap.initialize(`${process.env.REACT_APP_GLEAP_KEY}`);
  }

  const openSupport = () => {
    if (Gleap.isOpened()) {
      Gleap.close();
    } else {
      Gleap.openNews();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.popUp}>
        <div className={styles.popUpIconWrapper}>
          <ShuttleIcon />
        </div>
        <h1 className={styles.popUpTitle}>
          {t('hasBeenUpdated')}
        </h1>
        <div className={styles.popUpButtons}>
          <DefaultButton
            className={`${styles.btn} ${styles.readmore}`}
            handleClick={openSupport}
          >
            {t('checkUpdateDetails')}
          </DefaultButton>
          <DefaultButton
            className={styles.btn}
            handleClick={() => dispatch(setHasBeenUpdatedPopupVisibleAction(false))}
          >
            {t('gotIt')}
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default NewVersionPopUp;
