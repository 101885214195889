import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { IUseTutorials } from '../types/EducationComponentsInterfaces';

const useTutorials: IUseTutorials = (tutorialParams, setTutorialParams) => {
  const tutorialsList = useSelector((state: RootStateTypes) => state.educationState?.tutorialsList, shallowEqual);
  const levels = useSelector((state: RootStateTypes) => state.educationState?.levels, shallowEqual);
  const tutorialCategories = useSelector((state: RootStateTypes) => state.educationState?.tutorialCategories,
    shallowEqual);
  const [atBottom, setAtBottom] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (atBottom) {
      setTutorialParams((prev) => ({ ...prev, page: prev.page + 1, isLoadMore: true }));
    }
  }, [atBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight >= scrollHeight - 50 && tutorialsList.max_pages > tutorialParams.page) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    const scrollableDiv = ref.current;
    if (scrollableDiv) scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollableDiv) scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [tutorialsList]);

  return {
    tutorialsList, ref, tutorialCategories, levels
  };
};

export default useTutorials;
