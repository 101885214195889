import React from 'react';

import FilterSelectList from './FilterSelectList';
import NoFilters from './NoFilters';
import { TFilterGroupe } from '../Types';

interface ISelectedFiltersContainer {
  index: number;
  title: string;
  active: boolean;
  element: TFilterGroupe;
  hideButton: boolean;
  handlerGroup: (index: number) => void;
  clearItemFilter: (id: number, activeGroupIndex: number, checkMap: number) => void;
  handlerSearchFilter: (id: number, type: string, columnId: number, list: TFilterGroupe[]) => void;
}

const SelectedFiltersContainer = ({
  index,
  title,
  active,
  element,
  hideButton,
  handlerGroup,
  clearItemFilter,
  handlerSearchFilter
}: ISelectedFiltersContainer): React.ReactElement => (
  <>
    {element.items.length > 0 ? (
      <FilterSelectList
        id={index}
        title={title}
        active={active}
        hideButton={hideButton}
        activeGroupIndex={index}
        handlerGroup={handlerGroup}
        selectedItems={element}
        clearItemFilter={clearItemFilter}
        handlerSearchFilter={handlerSearchFilter}
      />
    ) : (
      <NoFilters
        id={index}
        handlerGroup={handlerGroup}
        active={active}
      />
    )}
  </>
);

export default SelectedFiltersContainer;
