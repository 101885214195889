import React from 'react';
import PropTypes from 'prop-types';

import styles from '../sass/DataPanel.module.scss';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { ReactComponent as IconClose } from '../../../assets/images/icons/screener/close_square_red.svg';
import TextWithTooltip from './TextWithTooltip';

const DataPanelItemEdit = ({
  itemId, removeItem, getTitle
}) => {
  const handleCleanItem = () => {
    removeItem(itemId);
  };

  return (
    <div
      className={styles.dataPanelItemEdit}
    >
      <div className={styles.dataPanelItemEditTitle}>
        <div className={styles.dataPanelItemEditDnd}>
          <DndControl />
        </div>
        <TextWithTooltip title={getTitle(itemId)} />
      </div>
      <div className={styles.dataPanelItemEditRemove}>
        <IconClose onClick={handleCleanItem} />
      </div>
    </div>
  );
};

DataPanelItemEdit.propTypes = {
  itemId: PropTypes.number.isRequired,
  removeItem: PropTypes.func.isRequired,
  getTitle: PropTypes.func.isRequired,
};

export default DataPanelItemEdit;
