import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../TextField/TextField';
import styles from './sass/HederModal.module.scss';
import CloseButton from '../CloseButton/CloseButton';

const HeaderModal = ({ text, closeModal }) => (
  <div className={styles.container}>
    <TextField text={text} styleType="addSymbolModalHeader" />
    <CloseButton click={closeModal} btnClass="closeModal" />
  </div>
);

HeaderModal.propTypes = {
  text: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default React.memo(HeaderModal);
