import moment from 'moment/moment';
import { MS_INTERVAL } from '../../constants/screener';
import { realTimeBarInterface } from '../../components/ChartContainer/utils/chartingHelper';
import { NORMALIZE_RESOLUTIONS } from '../../constants/tvWidgetOptions';
import useSpeedCheckup from '../../utils/hooks/useSpeedCheckup';

const { sendErrorHandler } = useSpeedCheckup();

class RtAccumProcessor {
  constructor() {
    this.accum = {};
    this.periodStarts = {};
  }

  initNewAccum(symbolIndex, uniqId) {
    this.accum[symbolIndex] = {
      ...this.accum[symbolIndex],
      [uniqId]: {
        accumVolume: {},
        candleOpen: {},
        candleHigh: {},
        candleLow: {},
        lastReadBar: {},
        dailyOpen: undefined
      }
    };
  }

  setLastReadBar(symbolIndex, uniqId, bar) {
    this.accum[symbolIndex][uniqId].lastReadBar = bar;
  }

  openHandler(symbolIndex, uniqId, periodStart, openValue, historicalOpen) {
    if (!periodStart && !this.accum[symbolIndex][uniqId].dailyOpen) {
      return historicalOpen;
    }

    if (!this.accum[symbolIndex][uniqId].dailyOpen) {
      this.accum[symbolIndex][uniqId].dailyOpen = openValue;
      return openValue;
    }
    return this.accum[symbolIndex][uniqId].dailyOpen;
  }

  highHandler(prevTick, currentTick, historyTick, periodStart) {
    if (!periodStart && !prevTick?.high) {
      return Math.max(currentTick.high, historyTick.high);
    }

    if (!prevTick?.high) {
      return periodStart ? currentTick.high : historyTick.high;
    }

    return Math.max(prevTick.high, currentTick.high);
  }

  lowHandler(prevTick, currentTick, historyTick, periodStart) {
    if (!periodStart && !prevTick?.low) {
      return Math.min(currentTick.low, historyTick.low);
    }

    if (!prevTick?.low) {
      return periodStart ? currentTick.low : historyTick.low;
    }
    return Math.min(prevTick.low, currentTick.low);
  }

  adjustTimestamp(timestamp, period) {
    if (this.periodStarts[period]) return;

    const date = moment.utc(timestamp);
    let isFirstDay = false;
    let adjustedDate;

    switch (period) {
      case '1d':
        adjustedDate = date.clone().startOf('day');
        isFirstDay = date.isSame(adjustedDate, 'day');
        break;
      case '1w':
        adjustedDate = date.clone().startOf('isoWeek');
        isFirstDay = date.isSame(adjustedDate, 'day');
        break;
      case '1M':
        adjustedDate = date.clone().startOf('month');
        isFirstDay = date.isSame(adjustedDate, 'day');
        break;
      case '3M':
        adjustedDate = date.clone().startOf('quarter');
        isFirstDay = date.isSame(adjustedDate, 'day');
        break;
      case '6M':
        if (date.month() < 6) {
          adjustedDate = date.clone().startOf('year');
        } else {
          adjustedDate = date.clone().startOf('year').add(6, 'months');
        }
        isFirstDay = date.isSame(adjustedDate, 'day');
        break;
      case '12M':
        adjustedDate = date.clone().startOf('year');
        isFirstDay = date.isSame(adjustedDate, 'day');
        break;
      default:
    }

    this.periodStarts[period] = { time: adjustedDate.valueOf(), isFirstDay };
  }

  accumItemHandler(
    item,
    allowedChanel,
    resolutionIndex,
    subscriptionItem,
  ) {
    const {
      symbolIndex, channelString, lastHistoricalBar
    } = subscriptionItem;

    const cacheItem = this.accum[symbolIndex][channelString];
    const allowedTime = !cacheItem.lastReadBar?.time || item[0] * MS_INTERVAL.SECOND > cacheItem.lastReadBar?.time;

    if (allowedTime && allowedChanel) {
      let candle = realTimeBarInterface(item);

      if (candle.volume < 0 || candle.accVolume < 0) {
        sendErrorHandler({ error: [NORMALIZE_RESOLUTIONS[resolutionIndex], ...item] });
      }

      if (resolutionIndex < 14) {
        candle = {
          ...candle,
          volume: cacheItem.lastReadBar.accVolume
            ? candle.accVolume - cacheItem.lastReadBar.accVolume : candle.accVolume
        };
      }
      if (resolutionIndex >= 14) {
        const period = NORMALIZE_RESOLUTIONS[resolutionIndex];
        this.adjustTimestamp(candle.time, period);
        const currentCacheItem = this.periodStarts[period];

        candle = {
          time: currentCacheItem.time,
          open: this.openHandler(
            symbolIndex, channelString, currentCacheItem.isFirstDay, candle.open, lastHistoricalBar.open
          ),
          high: this.highHandler(
            cacheItem.lastReadBar, candle, lastHistoricalBar, currentCacheItem.isFirstDay
          ),
          low: this.lowHandler(
            cacheItem.lastReadBar, candle, lastHistoricalBar, currentCacheItem.isFirstDay
          ),
          close: candle.close,
          volume: !currentCacheItem.isFirstDay ? lastHistoricalBar.volume + candle.accVolume : candle.accVolume
        };
      }

      subscriptionItem.handlers.forEach((handler) => handler.callback(candle));
      this.setLastReadBar(symbolIndex, channelString, candle);
    }
  }

  removeAccumSubItem(symbolIndex, uniqId) {
    if (this.accum[symbolIndex] && this.accum[symbolIndex][uniqId]) {
      delete this.accum[symbolIndex][uniqId];
    }
  }

  removeAccumItem(symbolIndex) {
    delete this.accum[symbolIndex];
  }
}

// eslint-disable-next-line import/prefer-default-export
export const rtAccumProcessor = new RtAccumProcessor();
