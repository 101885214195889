import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import EducationTabsItem from './EducationTabsItem';
import { IEducationTabs } from './types/EducationComponentsInterfaces';

const EducationTabs = ({ tabs, setActiveTab, activeTab }: IEducationTabs): React.ReactElement => (
  <div className={styles.educationTabsWrapper}>
    {tabs.map(({ title, Icon }) => (
      <EducationTabsItem
        key={title}
        title={title}
        Icon={Icon}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
      />
    ))}
  </div>
);

export default EducationTabs;
