import { useState, useEffect, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import constants from '../../constants/filters';
import { searchItemSym } from '../helpers';
import useLazySearch from './useLazySearch';
import { PAGING_SETTINGS } from '../../constants/screener';

const useSearch = () => {
  const [search, setSearch] = useState('');
  const [listResults, setListResults] = useState([]);
  const [focus, setFocus] = useState(false);

  const symbols = useSelector((state) => state.screenerState.symbolsList, shallowEqual);

  const {
    lazyResult, setLazyResult, paging, setPaging, setPagingHandler
  } = useLazySearch(listResults, setSearch);

  const handlerSearch = useCallback(
    (event) => {
      setPaging(0);
      setSearch(event.target.value);
    }, [search]
  );

  useEffect(() => {
    if (!focus) {
      setSearch('');
    }
  }, [focus]);

  useEffect(() => {
    if (search === '') {
      setListResults([]);
    }
  }, [search]);

  const searchRequest = useCallback(() => {
    if (search) {
      const cleanSymbols = symbols.filter((sym) => sym?.enabled);
      const resultOne = searchItemSym(cleanSymbols, search, constants.sym);
      const resultSecond = searchItemSym(cleanSymbols, search, constants.name);

      const ids = new Set(resultOne.matched.map((d) => d.index));
      const result = [...resultOne.matched, ...resultSecond.matched.filter((d) => !ids.has(d.index))];

      setListResults(result);
      setLazyResult(result.length > PAGING_SETTINGS ? [...result.splice(paging, PAGING_SETTINGS)] : result);
    } else {
      setListResults([]);
      setLazyResult([]);
    }
  }, [search]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchRequest();
    }, 100);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return {
    search, setSearch, handlerSearch, lazyResult, focus, setFocus, setPagingHandler
  };
};

export default useSearch;
