import React from 'react';

import TextField from '../../../TextField/TextField';
import styles from '../../sass/FilterScreen.module.scss';
import { TValueItem } from '../../Types';

interface IItemsCheckBox {
  arrayData: TValueItem[];
}

const ItemsCheckBox = ({ arrayData }: IItemsCheckBox): React.ReactElement => (
  <div className={styles.checkBoxValue}>
    {arrayData && arrayData.map((item, index) => (
      <React.Fragment key={[item.title, index].join('_')}>
        {item.checked && (
        <TextField
          styleType="filterItemCheckbox"
          text={`${item.title}`}
          key={[item.title + index, index].join('_')}
        />
        )}
      </React.Fragment>
    ))}
    { typeof arrayData === 'object' && arrayData.length === 0
    && <TextField styleType="filterItemCheckbox" text="No" />}
  </div>
);

export default ItemsCheckBox;
