import React, { useCallback, useEffect, useState } from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Input from '../Input/Input';
import styles from './sass/WatchListModals.module.scss';
import TextField from '../TextField/TextField';
import { ALLOWED_TITLE_DELETE_MODAL_LENGTH } from '../../constants/screener';
import { ICommonModalInput } from './types/Interfaces';
import RootStateTypes from '../../store/RootStateTypes';

const CommonModalInput = ({
  type, setDisabledFlag,
  inputValue, setInputValue,
  isDashboard, dashboardName, autoFocus
}: ICommonModalInput): React.ReactElement => {
  const { t } = useTranslation();
  const [viewTool, setViewTool] = useState(false);

  const selectedTab = useSelector((state: RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const tabFromDropDown = useSelector((state: RootStateTypes) => state.watchlistState.tabFromDropDown, shallowEqual);
  const currentItem = Object.keys(tabFromDropDown).length > 0 ? tabFromDropDown : selectedTab;

  const getRemoveTitle = () => {
    if (isDashboard && dashboardName) {
      return dashboardName?.length <= ALLOWED_TITLE_DELETE_MODAL_LENGTH
        ? dashboardName : `${dashboardName?.slice(0, ALLOWED_TITLE_DELETE_MODAL_LENGTH)}...`;
    }
    return currentItem.title.length <= ALLOWED_TITLE_DELETE_MODAL_LENGTH
      ? currentItem.title : `${currentItem.title.slice(0, ALLOWED_TITLE_DELETE_MODAL_LENGTH)}...`;
  };

  const onMouseLeaveTab = useCallback(() => {
    setViewTool(false);
  }, [viewTool]);

  const onMouseEnterTab = useCallback(() => {
    setViewTool(true);
  }, [viewTool]);

  useEffect(() => {
    if (!inputValue && (type === t('rename') || type === t('create'))) {
      setDisabledFlag(true);
    } else if (!inputValue.replace(/\s/g, '').length && (type === t('create') || type === t('rename'))) {
      setDisabledFlag(true);
    } else {
      setDisabledFlag(false);
    }
  }, [inputValue]);

  return (
    <>
      {type === t('rename') && (
        <Input
          isFocused={autoFocus}
          inputClass={styles.watchlistModalsInput}
          value={inputValue}
          handlerChange={(e) => setInputValue(e.target.value)}
        />
      )}
      {type === t('delete') && (
        <div
          className={styles.textWrapper}
          onMouseEnter={onMouseEnterTab}
          onMouseLeave={onMouseLeaveTab}
        >
          <TextField
            text={getRemoveTitle()}
            styleType="removeText"
          />
          {viewTool && currentItem.title.length > ALLOWED_TITLE_DELETE_MODAL_LENGTH && (
            <div className={styles.toolDropDown}>{currentItem.title}</div>
          )}
        </div>
      )}
      {type === t('create') && (
        <Input
          isFocused={autoFocus}
          value={inputValue}
          inputClass={styles.watchlistModalsInput}
          handlerChange={(e) => setInputValue(e.target.value)}
          placeholder={isDashboard ? t('createDashboardPlaceholder') : t('createPlaceholder')}
        />
      )}
    </>
  );
};

export default CommonModalInput;
