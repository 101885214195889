import { StudyLinePlotPreferences } from '../../../charting_library/charting_library';

export const defaultStyles = {
  intraday_ma_1_plot: {
    color: '#3281D0',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  intraday_ma_2_plot: {
    color: '#4CF64B',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  intraday_ma_3_plot: {
    color: '#87FBFB',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  intraday_ma_4_plot: {
    color: '#8828E0',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  intraday_ma_5_plot: {
    color: '#FFA500',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_1_plot: {
    // default is 8DEMA, matching color provided
    color: '#3281D0', // Updated color to match 8DEMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_2_plot: {
    // default is 10DSMA, matching color provided
    color: '#4CF64B', // Updated color to match 10DSMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_3_plot: {
    // default is 21DSMA, matching color provided
    color: '#87FBFB', // Updated color to match 21DSMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_4_plot: {
    // default is 23DEMA, matching color provided
    color: '#8828E0', // Updated color to match 23DEMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_5_plot: {
    // default is 50DSMA, matching color provided
    color: '#FFA500', // Updated color to match 50DSMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_6_plot: {
    // default is 65DEMA, matching color provided
    color: '#F86000', // Updated color to match 65DEMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  daily_ma_7_plot: {
    // default is 200DSMA, matching color provided
    color: '#D00030', // Updated color to match 200DSMA
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  weekly_ma_1_plot: {
    // default is 10WSMA
    color: '#CCA4F2',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  weekly_ma_2_plot: {
    // default is 20WSMA
    color: '#5454AD',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  weekly_ma_3_plot: {
    // default is 30WSMA
    color: '#FFA500',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  weekly_ma_4_plot: {
    // default is 40WSMA
    color: '#5454AD',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  weekly_ma_5_plot: {
    // default is 50WSMA
    color: '#D00030',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  monthly_ma_1_plot: {
    // default is 10WSMA
    color: '#CCA4F2',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  monthly_ma_2_plot: {
    // default is 20WSMA
    color: '#5454AD',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  monthly_ma_3_plot: {
    // default is 30WSMA
    color: '#FFA500',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  monthly_ma_4_plot: {
    // default is 40WSMA
    color: '#5454AD',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
  monthly_ma_5_plot: {
    // default is 50WSMA
    color: '#D00030',
    plottype: 0 as StudyLinePlotPreferences['plottype'],
  },
};

export const styles = {
  intraday_ma_1_plot: {
    title: 'Intraday MA 1'
  },
  intraday_ma_2_plot: {
    title: 'Intraday MA 2'
  },
  intraday_ma_3_plot: {
    title: 'Intraday MA 3'
  },
  intraday_ma_4_plot: {
    title: 'Intraday MA 4'
  },
  intraday_ma_5_plot: {
    title: 'Intraday MA 5'
  },
  daily_ma_1_plot: {
    // default is 8DEMA, matching color provided
    title: 'Daily MA 1'
  },
  daily_ma_2_plot: {
    // default is 10DSMA, matching color provided
    title: 'Daily MA 2'
  },
  daily_ma_3_plot: {
    // default is 21DSMA, matching color provided
    title: 'Daily MA 3'
  },
  daily_ma_4_plot: {
    // default is 23DEMA, matching color provided
    title: 'Daily MA 4'
  },
  daily_ma_5_plot: {
    // default is 50DSMA, matching color provided
    title: 'Daily MA 5'
  },
  daily_ma_6_plot: {
    // default is 65DEMA, matching color provided
    title: 'Daily MA 6'
  },
  daily_ma_7_plot: {
    // default is 200DSMA, matching color provided
    title: 'Daily MA 7'
  },
  weekly_ma_1_plot: {
    // default is 10WSMA
    title: 'Weekly MA 1'
  },
  weekly_ma_2_plot: {
    // default is 20WSMA
    title: 'Weekly MA 2'
  },
  weekly_ma_3_plot: {
    // default is 30WSMA
    title: 'Weekly MA 3'
  },
  weekly_ma_4_plot: {
    // default is 40WSMA
    title: 'Weekly MA 4'
  },
  weekly_ma_5_plot: {
    // default is 50WSMA
    title: 'Weekly MA 5'
  },
  monthly_ma_1_plot: {
    // default is 10WSMA
    title: 'Monthly MA 1'
  },
  monthly_ma_2_plot: {
    // default is 20WSMA
    title: 'Monthly MA 2'
  },
  monthly_ma_3_plot: {
    // default is 30WSMA
    title: 'Monthly MA 3'
  },
  monthly_ma_4_plot: {
    // default is 40WSMA
    title: 'Monthly MA 4'
  },
  monthly_ma_5_plot: {
    // default is 50WSMA
    title: 'Monthly MA 5'
  },
};
