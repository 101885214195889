import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../sass/AlertsList.module.scss';
import { ReactComponent as TickIcon } from '../../../assets/images/icons/Check.svg';
import useAlerts from '../../ChartContainer/hooks/useAlerts';
import { DEFAULT_LIMIT } from '../../../constants/alerts';
import useLocalStorage from '../../../utils/hooks/useLocalStorage';
import { ACTIVE_LIMITATION } from '../../../constants/storage';
import { currentLimitationHandler } from '../../ChartContainer/utils/alertHelper';

const SelectListItem = ({
  option,
  activeId,
  selectHandler,
  showChevron,
  isAlertList,
}) => {
  const { t } = useTranslation();
  const { getAlertsNotificationListWithLimitation } = useAlerts();
  const [, setStoredLimitation] = useLocalStorage(ACTIVE_LIMITATION, `${DEFAULT_LIMIT}`);

  const clickHandler = () => {
    selectHandler(option.id);

    if (isAlertList) {
      setStoredLimitation(option.id);

      const currentLimitValue = currentLimitationHandler();

      const currentLimitation = currentLimitValue[option.id];

      getAlertsNotificationListWithLimitation(...currentLimitation);
    }
  };

  return (
    <li
      className={option.id === activeId ? styles.active : ''}
      aria-hidden="true"
      onClick={clickHandler}
    >
      {showChevron && option.id === activeId && <TickIcon /> }
      {t(option.title)}
    </li>
  );
};

SelectListItem.propTypes = {
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    title: PropTypes.string.isRequired,
    urlQuery: PropTypes.string
  }).isRequired,
  selectHandler: PropTypes.func.isRequired,
  isAlertList: PropTypes.bool,
  showChevron: PropTypes.bool,
};

SelectListItem.defaultProps = {
  showChevron: true,
  isAlertList: true,
};

export default SelectListItem;
