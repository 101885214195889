import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import WatchListItem from './WatchListItem';
import styles from './sass/ScreenerDropDown.module.scss';

interface IWLItem {
  data: { filters: [[]] }
  id: number
}
interface IWatchlistItemWrapper {
  watchlistItem: IWLItem,
  selectedSymbols: number[],
  actionHandler: (item: IWLItem, checked: boolean) => void,
  isLast: boolean,
  multiSelect: boolean,
}

const WatchlistItemWrapper = ({
  watchlistItem,
  selectedSymbols,
  actionHandler,
  isLast,
  multiSelect
}: IWatchlistItemWrapper): JSX.Element => {
  const listItems: number[] = watchlistItem.data?.filters?.flat(2)[2] || [];
  const checked = selectedSymbols.reduce((res, symbolId) => (listItems.includes(symbolId) ? res : false), true);
  return (
    <div className={styles.checkBoxWrapper}>
      {multiSelect ? (
        <Checkbox
          id={`${watchlistItem.id}`}
          key={`${watchlistItem.id}_check`}
          changeHandler={() => actionHandler(watchlistItem, checked)}
          checked={checked}
        />
      ) : null}
      <WatchListItem
        key={watchlistItem.id}
        watchlistItem={watchlistItem}
        copyHandler={() => actionHandler(watchlistItem, checked)}
        lastElement={isLast}
      />
    </div>
  );
};

export default WatchlistItemWrapper;
