import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CalendarPeriodView from './CalendarPeriodView';
import DateHelper from '../../utils/DatePeriodHelper';
import { PERIOD_PICKERS_TYPE, PERIOD_BUTTONS_TYPES, DATE_CONTAINER_HEIGHT } from '../../constants/rangeDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import numberConstants from '../../constants/numberConstants';
import { getNumbersDayOfMonth, getNumbersDayOfNextQuarter } from '../../utils/helperFilter';

const CalendarPeriod = ({
  handleJustOpenDatePicker,
  placeholder,
  openCalendar,
  cancelSelect,
  applySelectDate,
  cancelText,
  applyText,
  startDate,
  endDate,
  shortFilterName,
  onFieldChange,
  idCategory,
  columnId,
  correctPeriod,
}) => {
  const { t } = useTranslation();
  const defaultStartDate = startDate ? new Date(startDate) : null;
  const defaultEndDate = endDate ? new Date(endDate) : null;
  const [isOpenStartPeriodDate, setOpenStartPeriodDate] = useState(false);
  const [isOpenEndPeriodDate, setOpenEndPeriodDate] = useState(false);
  const [startDateFromPicker, setStartDateFromPicker] = useState(defaultStartDate);
  const [endDateFromPicker, setEndDateFromPicker] = useState(defaultEndDate);
  const [actionsFromButton, setActionsFromButton] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const [isPlacementTop, setIsPlacementTop] = useState(false);

  useEffect(() => {
    setStartDateFromPicker(defaultStartDate);
    setEndDateFromPicker(defaultEndDate);
  }, [startDate, endDate]);

  const handleOpen = (id) => {
    if (id === PERIOD_PICKERS_TYPE.START) {
      return setOpenStartPeriodDate(true);
    }
    return setOpenEndPeriodDate(true);
  };

  const handleClose = (id) => {
    if (id === PERIOD_PICKERS_TYPE.START) {
      return setOpenStartPeriodDate(false);
    }
    return setOpenEndPeriodDate(false);
  };

  const handleCancel = (id) => {
    if (id === PERIOD_PICKERS_TYPE.START) {
      setStartDateFromPicker(startDate);
    } else {
      setEndDateFromPicker(endDate);
    }
    setIsCancel(!isCancel);
  };

  const handleApply = (id) => {
    const changeValue = id === PERIOD_PICKERS_TYPE.START ? startDateFromPicker : endDateFromPicker;
    onFieldChange(id, changeValue);
  };

  const setPeriod = (actions) => {
    if (actionsFromButton && actions.join('') === actionsFromButton.join('')) {
      setStartDateFromPicker(startDate);
      setEndDateFromPicker(endDate);
      return setActionsFromButton([]);
    }
    const currentDateTime = new Date().getTime();
    const helper = new DateHelper({
      date: currentDateTime,
      actions,
      isNeedCorrect: correctPeriod,
      endDate: currentDateTime
    });
    const [startPeriodDate, endPeriodDate] = helper.getPeriod();
    setStartDateFromPicker(startPeriodDate);
    if (endPeriodDate) {
      setEndDateFromPicker(endPeriodDate);
    } else {
      setEndDateFromPicker(startPeriodDate);
      setOpenEndPeriodDate(true);
    }
    return setActionsFromButton(actions);
  };

  const correctDate = (action) => {
    let startCorrect = 0;
    let endCorrect = 0;
    switch (action.join('')) {
      case PERIOD_BUTTONS_TYPES.LAST_MONTH.actions.join(''):
        startCorrect = -numberConstants.secondDay
         * getNumbersDayOfMonth() - numberConstants.secondDay;
        break;
      case PERIOD_BUTTONS_TYPES.LAST_WEEK.actions.join(''):
        startCorrect = -numberConstants.secondDay * numberConstants.weekDays;
        break;
      case PERIOD_BUTTONS_TYPES.NEXT_WEEK.actions.join(''):
        endCorrect = numberConstants.secondDay * numberConstants.weekDays;
        break;
      case PERIOD_BUTTONS_TYPES.NEXT_MONTH.actions.join(''):
        endCorrect = numberConstants.secondDay
         * getNumbersDayOfMonth();
        break;
      case PERIOD_BUTTONS_TYPES.LAST_YEAR.actions.join(''):
        startCorrect = -numberConstants.secondDay * numberConstants.yearDays;
        break;
      case PERIOD_BUTTONS_TYPES.NEXT_QUARTER.actions.join(''):
        endCorrect = numberConstants.secondDay * getNumbersDayOfNextQuarter();
        break;
      default:
        break;
    }
    return action.length > 0 ? [columnId, 3, [startCorrect, endCorrect]] : [];
  };

  const handleApplySelected = (event) => {
    const correctDataPicker = correctDate(actionsFromButton);
    const start = startDateFromPicker || new Date();
    const end = endDateFromPicker || new Date();
    applySelectDate(event, [start, end], correctDataPicker);
  };

  useEffect(() => {
    setOpenStartPeriodDate(false);
    setOpenEndPeriodDate(false);
  }, [startDate, endDate, isCancel]);

  useEffect(() => {
    if (openCalendar) {
      const categoryContainer = document.getElementById(idCategory);
      const picker = document.getElementById(`calendar_${shortFilterName}`);
      const categoryRect = categoryContainer?.getBoundingClientRect();
      const pickerRect = picker?.getBoundingClientRect();
      if ((categoryRect?.bottom - pickerRect?.bottom) < DATE_CONTAINER_HEIGHT) {
        setIsPlacementTop(true);
      }
    }
  }, [openCalendar]);

  return (
    <CalendarPeriodView
      placeholder={placeholder}
      filterType={shortFilterName}
      handleJustOpenDatePicker={handleJustOpenDatePicker}
      openCalendar={openCalendar}
      isPlacementTop={isPlacementTop}
      actionsFromButton={actionsFromButton}
      setPeriod={setPeriod}
      startDateFromPicker={startDateFromPicker}
      isOpenStartPeriodDate={isOpenStartPeriodDate}
      startDateLabel={t('startDateLabel')}
      endDateLabel={t('endDateLabel')}
      setStartDateFromPicker={setStartDateFromPicker}
      handleOpen={handleOpen}
      handleApply={handleApply}
      handleCancel={handleCancel}
      cancelText={cancelText}
      applyText={applyText}
      endDateFromPicker={endDateFromPicker}
      isOpenEndPeriodDate={isOpenEndPeriodDate}
      setEndDateFromPicker={setEndDateFromPicker}
      handleApplySelected={handleApplySelected}
      startDatePlaceholder={t('startDatePlaceholder')}
      endDatePlaceholder={t('endDatePlaceholder')}
      cancelSelect={cancelSelect}
      handleClose={handleClose}
      setActionsFromButton={setActionsFromButton}
    />
  );
};

CalendarPeriod.propTypes = {
  handleJustOpenDatePicker: PropTypes.func,
  placeholder: PropTypes.string,
  openCalendar: PropTypes.bool,
  cancelSelect: PropTypes.func,
  applySelectDate: PropTypes.func,
  cancelText: PropTypes.string,
  applyText: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  shortFilterName: PropTypes.string,
  onFieldChange: PropTypes.func,
  idCategory: PropTypes.string,
  columnId: PropTypes.number.isRequired,
  correctPeriod: PropTypes.bool,
};

CalendarPeriod.defaultProps = {
  handleJustOpenDatePicker: () => {},
  placeholder: '',
  openCalendar: false,
  cancelSelect: () => {},
  applySelectDate: () => {},
  cancelText: '',
  applyText: '',
  startDate: null,
  endDate: null,
  shortFilterName: '',
  onFieldChange: () => {},
  idCategory: '',
  correctPeriod: false,
};

export default CalendarPeriod;
