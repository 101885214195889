import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../sass/PickColumnWrapper.module.scss';
import Button from '../../Button/Button';
import useOutsideAlerter from '../../../utils/hooks/useOutside';
import { ROW_STYLES } from '../../../constants/screener';
import { setActiveRowStyle } from '../../../store/screener/actions';

import { ReactComponent as NormalIcon } from '../../../assets/images/icons/screener/big.svg';
import { ReactComponent as MediumIcon } from '../../../assets/images/icons/screener/medium.svg';
import { ReactComponent as SmallIcon } from '../../../assets/images/icons/screener/small.svg';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { ROW_STYLE } from '../../../constants/tvWidgetOptions';

const SizeSelect = () => {
  const [isDrodownVisible, setIsDrodownVisible, ref] = useOutsideAlerter(false);
  const dispatch = useDispatch();
  const activeRowStyle = useSelector((state) => state.screenerState.activeRowStyle);
  const { updateStoreHandler } = useLastStateUpdateStore();
  const selectRowHandler = (item) => {
    dispatch(setActiveRowStyle(item));
    setIsDrodownVisible(false);
    updateStoreHandler(ROW_STYLE, item);
  };

  const getIcon = (title, isActive) => {
    switch (title) {
      case ROW_STYLES[1].title:
        return <MediumIcon className={isActive ? styles.isActive : ''} />;
      case ROW_STYLES[2].title:
        return <SmallIcon className={isActive ? styles.isActive : ''} />;
      default:
        return <NormalIcon className={isActive ? styles.isActive : ''} />;
    }
  };

  return (
    <div className={styles.SizeSelect} ref={ref}>
      <Button
        handleClick={() => setIsDrodownVisible(!isDrodownVisible)}
        buttonClass={`${styles.selectButton} ${isDrodownVisible ? styles.active : ''}`}
      >
        {getIcon(activeRowStyle.title, true)}
      </Button>
      {
        isDrodownVisible && (
          <ul className={styles.dropdown}>
            {
              ROW_STYLES.map((item, index) => (
                <li
                  key={[item.title, index].join('_')}
                  className={activeRowStyle.title === item.title ? styles.active : ''}
                >
                  <Button
                    handleClick={() => selectRowHandler(item)}
                    buttonClass={styles.button}
                  >
                    {getIcon(item.title, activeRowStyle.title === item.title)}
                  </Button>
                </li>
              ))
            }

          </ul>
        )
      }
    </div>
  );
};

export default SizeSelect;
