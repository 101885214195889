import ACTION_DASHBOARD from './types';
import {
  filtersStoreHelper, filterColumns
} from '../../utils/helpers';
import {
  CLICKABLE_CELLS_NAMES,
  CURRENT_PAGE_DEFAULT,
  FILTER_SETTINGS
} from '../../constants/screener';

const INITIAL_STATE = {
  itemsSymbol: [],
  itemsType: [],
  estimateVisibleData: true,
  selectedColumns: [],
  listColumns: [],
  filterMarket: [],
  selectedSymbols: [],
  visibleItemsTable: [CURRENT_PAGE_DEFAULT.INIT_VALUE, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT],
  nextPageFlag: false,
  showIcons: true,
  favoritesFilter: [],
  filterList: [],
  visibleColumnsScreen: false,
  symbolsList: [],
  columnsList: [],
  groupsList: [],
  stopTick: false,
  listItemsForFilter: [],
  baseUrl: '',
  defsUrlsSymbols: '',
  defsUrlsColumns: '',
  defsUrlsColumnsGroups: '',
  defUrlsDataPoints: '',
  graphicView: true,
  currentTab: 'Saved',
  columnsWereUpdate: false,
  filtersWereUpdate: false,
  initialFilterList: [],
  activeSymbol: 0,
  activeSymbolOrder: 0,
  activeSymbolText: '',
  isHasBeenUpdatePopupVisible: false,
  error: '',
  additionalStoreData: {
    earningsValue: {},
    dividendValue: {},
    earningTimeValue: {},
    isETF: {}
  },
  version: null,
  activeRowStyle: {},
  volumeDayHistory: [],
  changePageFlag: false,
  columnsObjectId: undefined,
  loader: true,
  alertListDropdownVisible: false,
  tableSorted: false,
  reInitSocketFlag: true,
  searchValue: {},
  tvIsReady: true,
  tableLoaded: false,
  tabUpdateFlag: false,
  globalSearchHeaderValue: '',
  tvLayoutsList: [],
  defaultLayoutId: 0,
  selectedShapeId: null,
  pagePath: 'Screener',
  sortData: [],
  etfSymbolIndex: null,
  sameSymbolFlag: false,
  sectorIndustryItems: [],
  sharedDashboardItem: {},
};

const setNewColumnsCondition = (payload, state) => {
  const foundItem = state.selectedColumns.find((findItem) => findItem.id === payload.data.id);
  if (foundItem) {
    return state.selectedColumns.filter((item) => item.id !== payload.data.id);
  }
  const oldSelected = state.selectedColumns.map((item) => item);
  if (oldSelected.length <= 2) {
    oldSelected.push(payload.data);
  } else {
    oldSelected.splice(2, 0, payload.data);
  }
  return oldSelected;
};

const collectSectorIndustryItems = (data) => {
  const items = [];
  data?.forEach((category) => {
    category?.items.forEach((subCategory) => {
      if (CLICKABLE_CELLS_NAMES.includes(subCategory?.sName)) {
        items.push(...subCategory?.fVal);
      }
    });
  });
  return items;
};

const setFilterMarketCondition = (payload) => (
  payload.data ? filtersStoreHelper(payload?.data) : []
);

const setFilterMarkenWatchlistCondition = (payload) => {
  return payload?.data?.filters?.length
    ? filtersStoreHelper(payload?.data?.filters)
    : [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, []]]];
};

const setSelectedSymbolsCondition = (payload, state) => (
  state.selectedSymbols.findIndex((item) => item === payload) !== -1
    ? state.selectedSymbols.filter((subItem) => subItem !== payload)
    : [...state.selectedSymbols, payload]
);

const removeSortDuplicates = (state, payload) => {
  let replaced = false;
  const sortData = (state.sortData || [])
    .map(({ indexElement, sortValue }) => {
      if (indexElement === payload.indexElement) {
        replaced = true;
        return { indexElement, sortValue: payload.sortValue };
      }
      return { indexElement, sortValue };
    });
  if (!replaced) {
    sortData.push(payload);
  }
  return sortData;
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_DASHBOARD.SET_COLUMNS:
      return { ...state, selectedColumns: filterColumns(payload?.data) };
    case ACTION_DASHBOARD.SET_FAVORITE_FILTERS:
      return { ...state, favoritesFilter: payload.data };
    case ACTION_DASHBOARD.SET_NEXT_PAGE_FLAG:
      return { ...state, nextPageFlag: payload.data };
    case ACTION_DASHBOARD.TOGGLE_ESTIMATE:
      return { ...state, estimateVisibleData: payload };
    case ACTION_DASHBOARD.SET_NEW_COLUMNS:
      return {
        ...state,
        selectedColumns: setNewColumnsCondition(payload, state)
      };
    case ACTION_DASHBOARD.SET_LIST_COLUMNS:
      return { ...state, listColumns: payload.data };
    case ACTION_DASHBOARD.UPDATE_ORDER_COLUMNS:
      return { ...state, selectedColumns: payload.data };
    case ACTION_DASHBOARD.REMOVE_COLUMN_ITEM:
      return {
        ...state,
        selectedColumns: state.selectedColumns.filter((item) => item.id !== payload.data),
      };
    case ACTION_DASHBOARD.COLUMNS_WERE_UPDATE:
      return { ...state, columnsWereUpdate: payload };
    case ACTION_DASHBOARD.FILTERS_WERE_UPDATE: return { ...state, filtersWereUpdate: payload };
    case ACTION_DASHBOARD.SET_FILTER_MARKET:
      if (!payload.data) return state;
      return { ...state, filterMarket: setFilterMarketCondition(payload) };
    case ACTION_DASHBOARD.SET_FILTER_MARKET_WATCHLIST:
      return {
        ...state,
        filterMarket: setFilterMarkenWatchlistCondition(payload)
      };
    case ACTION_DASHBOARD.GET_SYMBOLS:
      return { ...state, itemsSymbol: payload.data };
    case ACTION_DASHBOARD.GET_TYPES:
      return { ...state, itemsType: payload.data };
    case ACTION_DASHBOARD.SAVE_FILTERS_DATA:
      return {
        ...state,
        filterList: payload.data,
        sectorIndustryItems: collectSectorIndustryItems(payload.data)
      };
    case ACTION_DASHBOARD.OPEN_COLUMNS_SCREEN:
      return { ...state, visibleColumnsScreen: payload };
    case ACTION_DASHBOARD.SET_DEFS_URLS_SYMBOLS:
      return { ...state, defsUrlsSymbols: payload };
    case ACTION_DASHBOARD.SET_DEFS_URLS_COLUMNS:
      return { ...state, defsUrlsColumns: payload };
    case ACTION_DASHBOARD.SET_DEFS_URLS_COLUMNS_GROUPS:
      return { ...state, defsUrlsColumnsGroups: payload };
    case ACTION_DASHBOARD.SAVE_SYMBOLS_DATA:
      return { ...state, symbolsList: payload.data };
    case ACTION_DASHBOARD.SAVE_COLUMNS_DATA:
      return { ...state, columnsList: payload.data };
    case ACTION_DASHBOARD.SAVE_GROUPS_DATA:
      return { ...state, groupsList: payload.data };
    case ACTION_DASHBOARD.SAVE_BASE_URL:
      return { ...state, baseUrl: payload.data };
    case ACTION_DASHBOARD.SET_STOP_TICK:
      return { ...state, stopTick: payload.data };
    case ACTION_DASHBOARD.SET_LIST_FOR_FILTER:
      return { ...state, listItemsForFilter: payload.data };
    case ACTION_DASHBOARD.SET_ACTIVE_SYMBOL:
      return { ...state, activeSymbol: payload.data };
    case ACTION_DASHBOARD.SET_ACTIVE_SYMBOL_ORDER:
      return { ...state, activeSymbolOrder: payload.data };
    case ACTION_DASHBOARD.SET_ACTIVE_SYMBOL_TEXT:
      return { ...state, activeSymbolText: payload.data };
    case ACTION_DASHBOARD.SET_SELECTED_SHAPE:
      return { ...state, selectedShapeId: payload };
    case ACTION_DASHBOARD.SET_INITIAL_COLUMNS:
      return {
        ...state,
        selectedColumns: [],
        filterMarket: [],
      };
    case ACTION_DASHBOARD.SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: payload
      };
    case ACTION_DASHBOARD.SET_INITIAL_DATA:
      return { ...state, initialFilterList: JSON.parse(JSON.stringify(payload.data)) };
    case ACTION_DASHBOARD.SET_HASBEENUPDATED_POPUP_VISIBLE:
      return { ...state, isHasBeenUpdatePopupVisible: payload };
    case ACTION_DASHBOARD.SET_CURRENT_VERSION:
      return { ...state, version: payload };
    case ACTION_DASHBOARD.SET_ADDITIONAL_DATA_SCREENER:
      return {
        ...state,
        additionalStoreData: {
          earningsValue: { ...state.additionalStoreData.earningsValue, ...payload.earningsValue },
          dividendValue: { ...state.additionalStoreData.dividendValue, ...payload.dividendValue },
          earningTimeValue: { ...state.additionalStoreData.earningTimeValue, ...payload.earningTimeValue },
          isETF: { ...state.additionalStoreData.isETF, ...payload.isETF }
        }
      };
    case ACTION_DASHBOARD.SET_SELECTED_SYMBOLS:
      return {
        ...state,
        selectedSymbols: setSelectedSymbolsCondition(payload, state)
      };
    case ACTION_DASHBOARD.SET_SELECTED_SYMBOLS_BATCH:
      return {
        ...state, selectedSymbols: payload
      };
    case ACTION_DASHBOARD.CLEAR_SELECTED_SYMBOLS:
      return { ...state, selectedSymbols: [] };
    case ACTION_DASHBOARD.SET_ROW_STYLE:
      return { ...state, activeRowStyle: payload };
    case ACTION_DASHBOARD.SET_VOLUME_DAY_HISTORY:
      return { ...state, volumeDayHistory: { ...state.volumeDayHistory, ...payload } };
    case ACTION_DASHBOARD.SET_FIRST_VOLUME_DAY_HISTORY:
      return { ...state, volumeDayHistory: payload };
    case ACTION_DASHBOARD.SET_COLUMNS_OBJECT_ID:
      return { ...state, columnsObjectId: payload };
    case ACTION_DASHBOARD.SET_LOADER:
      return { ...state, loader: payload };
    case ACTION_DASHBOARD.SET_TABLE_SORTED: {
      return { ...state, tableSorted: payload };
    }
    case ACTION_DASHBOARD.SET_SEARCH_VALUE: {
      return { ...state, searchValue: payload };
    }
    case ACTION_DASHBOARD.SET_TV_IS_READY: {
      return { ...state, tvIsReady: payload };
    }
    case ACTION_DASHBOARD.SET_TAB_UPDATE_FLAG: {
      return { ...state, tabUpdateFlag: !state.tabUpdateFlag };
    }
    case ACTION_DASHBOARD.SET_SEARCH_HEADER_VALUE:
      return { ...state, globalSearchHeaderValue: payload };
    case ACTION_DASHBOARD.SET_LAYOUTS_LIST:
      return { ...state, tvLayoutsList: payload };
    case ACTION_DASHBOARD.SET_DEFAULT_LAYOUT:
      return { ...state, defaultLayoutId: payload };
    case ACTION_DASHBOARD.SET_ROUTE:
      return { ...state, pagePath: payload };
    case ACTION_DASHBOARD.SET_SORT_DATA:
      return { ...state, sortData: payload };
    case ACTION_DASHBOARD.ADD_SORT_DATA:
      return {
        ...state,
        sortData: removeSortDuplicates(state, payload),
      };
    case ACTION_DASHBOARD.SET_ETF_SYMBOL_INDEX:
      return { ...state, etfSymbolIndex: payload };
    case ACTION_DASHBOARD.TOGGLE_SAME_SYMBOL_FLAG:
      return { ...state, sameSymbolFlag: !state.sameSymbolFlag };
    case ACTION_DASHBOARD.SET_DEFS_URLS_DATA_POINTS:
      return { ...state, defUrlsDataPoints: payload };
    case ACTION_DASHBOARD.SET_SHARED_DADHBOARD_ITEM:
      return { ...state, sharedDashboardItem: payload };

    default:
      return state;
  }
};
