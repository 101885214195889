import { rtAccumProcessor } from './RtAccumProcessor';

class RtDataProcessor {
  constructor() {
    this.rtData = {};
  }

  rtInitDataHandler(symbolIndex, resolution, rtData) {
    if (!this.rtData[symbolIndex]) {
      this.rtData[symbolIndex] = {};
    }
    this.rtData[symbolIndex][resolution] = rtData;
  }

  rtCellCleanUpAdditionalData(symbolIndex) {
    delete this.rtData[symbolIndex];
    rtAccumProcessor.removeAccumItem(symbolIndex);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const rtProcessor = new RtDataProcessor();
