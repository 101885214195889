import React, { useEffect, useState } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { TDashboardItemExt } from '../types/types';
import RootStateTypes from '../../../store/RootStateTypes';
import {
  DashboardInnerData,
  DataPanelItemData, DeepListItemData, FolderItemData, ScreenItemData, TFolderInnerData
} from '../../../utils/Types';
import { ReactComponent as IconFolder } from '../../../assets/images/icons/screener/folder.svg';
import { DashboardItemsListsTitle, DialogItemDataTypes } from '../types/constants';

interface IDialogContentMoveTo {
  onChange: (item: TDashboardItemExt) => void,
  item: TDashboardItemExt,
  theme: string,
  type: DialogItemDataTypes,
}

const DialogContentMoveTo = ({
  onChange, item, theme, type
}: IDialogContentMoveTo): React.ReactElement => {
  const { t } = useTranslation();
  const [valueSelect, setValueSelect] = useState<string>('');
  const currentItem = item as TDashboardItemExt;
  const itemsWatchlist = useSelector((state: RootStateTypes) => state.watchlistState.itemsWatchlist, shallowEqual);
  const itemsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.itemsNewScreen, shallowEqual);
  const itemsColumnSets = useSelector((state: RootStateTypes) => state.newScreenState.itemsColumnSets, shallowEqual);
  const dataPanelsItems = useSelector((state: RootStateTypes) => state.newScreenState.dataPanelsItems, shallowEqual);
  const itemsDashboard = useSelector((state: RootStateTypes) => state.dashboardsState.itemsDashboard, shallowEqual);

  const getData = () => {
    switch (type) {
      case (DialogItemDataTypes.DEEP_LIST): {
        return itemsWatchlist.filter(
          (itemWatchlist: DeepListItemData | TFolderInnerData) => itemWatchlist?.type === 'folder'
          && itemWatchlist?.id !== item?.folder?.id
        );
      }
      case (DialogItemDataTypes.SCREENER): {
        return itemsNewScreen.filter(
          (itemNewScreen: ScreenItemData | TFolderInnerData) => itemNewScreen?.type === 'folder'
          && itemNewScreen?.id !== item?.folder?.id
        );
      }
      case (DialogItemDataTypes.DATA_PANEL): {
        return dataPanelsItems.filter(
          (dataPanelsItem: DataPanelItemData | TFolderInnerData) => dataPanelsItem?.type === 'folder'
          && dataPanelsItem?.id !== item?.folder?.id
        );
      }
      case (DialogItemDataTypes.DASHBOARD): {
        return itemsDashboard.filter(
          (dashboard: DashboardInnerData | TFolderInnerData) => dashboard?.type === 'folder'
          && dashboard?.id !== item?.folder?.id
        );
      }
      case (DialogItemDataTypes.COLUMN_SET): {
        return itemsColumnSets.filter(
          (columnSet: DashboardInnerData | TFolderInnerData) => columnSet?.type === 'folder'
          && columnSet?.id !== item?.folder?.id
        );
      }
      default: return itemsNewScreen.filter(
        (itemNewScreen: ScreenItemData | TFolderInnerData) => itemNewScreen?.type === 'folder'
        && itemNewScreen?.id !== item?.folder?.id
      );
    }
  };

  const folders = getData();
  const handleChanges = (value: string): void => {
    setValueSelect(value);
    onChange({
      ...currentItem,
      folderId: value
    });
  };

  useEffect(() => {
    if (folders.length) {
      handleChanges(folders[0].id.toString());
    }
  }, []);

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={styles.dialogContentFieldItem}>
        <label className={`${styles.dialogContentFieldItemLabel} ${styles.asText}`} htmlFor="folderName">
          {`${t('chooseFolderLabel')} `}
          <b>{item?.title}</b>
          {` ${t('to')}`}
        </label>
        <div className={styles.dialogContentList}>
          {folders.map((folder: FolderItemData) => (
            <div
              aria-hidden
              aria-selected={valueSelect === folder?.id.toString()}
              key={folder?.id}
              className={`${styles.dialogContentListItem}
                ${valueSelect === folder?.id.toString() ? styles.active : ''}`}
              onClick={() => handleChanges(folder?.id.toString())}
            >
              <div className={styles.folderIcon}>
                <IconFolder />
              </div>
              <div className={styles.folderTitle}>
                <span>{folder.title}</span>
                <span>
                  {`${folder?.items?.length} ${DashboardItemsListsTitle[type]}`}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DialogContentMoveTo;
