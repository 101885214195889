import React from 'react';

import FilterGroup from './FilterGroup';
import FilterItemsList from './FilterItemsList';
import NoOptions from '../../NoOptions/NoOptions';
import { TDefineColumn, TFilterGroupe } from '../Types';

interface IFilterGroupList {
  disabled: boolean;
  adminUser: boolean;
  columnsList: TDefineColumn[];
  addFavorite: () => void;
  favoriteData: number[];
  searchFilter: string | number;
  isVisibleFilters: boolean;
  initialFilterList: TFilterGroupe[];
  noFiltersAvailable: string;
  stateFiltersHandler: (filter: (number | number[])[]) => void;
}
const FilterGroupList = ({
  disabled,
  adminUser,
  columnsList,
  addFavorite,
  favoriteData,
  searchFilter,
  isVisibleFilters,
  initialFilterList,
  noFiltersAvailable,
  stateFiltersHandler,
}: IFilterGroupList): React.ReactElement => {
  const filterGroupHandler = (list: TFilterGroupe[]) => JSON.parse(JSON.stringify(list)).map((
    category: {
      name: string,
      items: []
    },
    index: number
  ) => (category?.items.length ? (
    <FilterGroup
      disabled={disabled}
      title={category?.name}
      key={[category?.name, index].join('_')}
      type=""
    >
      <FilterItemsList
        disabled={disabled}
        disabledHeart={disabled}
        columnsList={columnsList}
        addFavorite={addFavorite}
        favoriteData={favoriteData}
        stateFiltersHandler={stateFiltersHandler}
        searchFilter={searchFilter as number}
        adminUser={adminUser}
        categoryName={category?.name}
        categoryItems={category?.items}
        typeList=""
      />
    </FilterGroup>
  ) : <></>));

  return (
    <>
      {(isVisibleFilters && initialFilterList?.length > 0)
        ? filterGroupHandler(initialFilterList) : (
          <NoOptions text={noFiltersAvailable} styleType="category" />
        )}
    </>
  );
};

export default FilterGroupList;
