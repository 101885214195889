import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/DefaultButton.module.scss';

const DefaultButton = ({
  handleClick, buttonClass, className, children, submit, disabled, name, id, activeClass,
  mouseLeaveHandler, mouseDownHandler, mouseEnterHandler, blurClass, componentStyles, buttonRef
}) => {
  return (
    <button
      id={id}
      style={componentStyles}
      name={name}
      type={submit ? 'submit' : 'button'}
      disabled={disabled ? 'disabled' : null}
      onClick={handleClick}
      className={`
    ${styles.button} ${className}
    ${styles[buttonClass] ? styles[buttonClass] : ''}
    ${styles[blurClass] ? styles[blurClass] : ''}
    ${activeClass ? styles[activeClass] : ''}`}
      onMouseLeave={mouseLeaveHandler}
      onMouseDown={mouseDownHandler}
      onMouseEnter={mouseEnterHandler}
      ref={buttonRef}
    >
      {children}
    </button>
  );
};

DefaultButton.defaultProps = {
  handleClick: () => {},
  buttonClass: '',
  blurClass: '',
  className: '',
  submit: false,
  disabled: false,
  name: '',
  id: '',
  activeClass: '',
  mouseLeaveHandler: () => undefined,
  mouseDownHandler: () => undefined,
  mouseEnterHandler: () => undefined,
  componentStyles: {},
};

DefaultButton.propTypes = {
  buttonClass: PropTypes.string,
  blurClass: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  submit: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  activeClass: PropTypes.string,
  mouseLeaveHandler: PropTypes.func,
  mouseDownHandler: PropTypes.func,
  mouseEnterHandler: PropTypes.func,
  componentStyles: PropTypes.shape({}),
  buttonRef: PropTypes.shape({}),
};
DefaultButton.defaultProps = {
  buttonRef: null,
};

export default DefaultButton;
