import WATCHLIST_ACTIONS from './types';

const INITIAL_STATE = {
  tabsList: [],
  favoriteTab: {},
  alertsTab: {},
  folders: [],
  presetFolders: [],
  presets: [],
  itemsWatchlist: [],
  presetsWatchlist: [],
  selectedTab: {},
  selectedTabID: 0,
  modalType: '',
  isOpenModal: false,
  selectedSymbolsList: [],
  tabFromDropDown: {},
  targetList: {},
  currentTargetList: {},
  targetSymbol: {},
  holidaysList: '',
  activeAlertID: 0,
};

const updateSpecificWL = (newItem, data) => {
  const result = [...data];
  const index = result.findIndex((item) => item.id === newItem.id);
  result[index] = newItem;

  return result;
};

const updateColumnsIdForSelectedTab = (selectedTab, id) => {
  const data = { ...selectedTab };
  data.data.columnsId = id;

  return data;
};

const watchlistReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case WATCHLIST_ACTIONS.UPDATE_TABS_ORDER:
      return { ...state, tabsList: payload };
    case WATCHLIST_ACTIONS.SET_WATCHLIST_DATA:
      return { ...state, tabsList: payload };
    case WATCHLIST_ACTIONS.SET_FAVORITES_DATA:
      return { ...state, favoriteTab: payload };
    case WATCHLIST_ACTIONS.SET_SELECTED_TAB:
      if (JSON.stringify(state.selectedTab) === JSON.stringify(payload)) return state;
      return { ...state, selectedTab: payload };
    case WATCHLIST_ACTIONS.SET_SELECTED_TAB_NEW_COLUMNS_SET:
      return { ...state, selectedTab: updateColumnsIdForSelectedTab(state.selectedTab, payload) };
    case WATCHLIST_ACTIONS.SET_MODAL_TYPE:
      return { ...state, modalType: payload };
    case WATCHLIST_ACTIONS.SET_IS_OPEN_MODAL:
      return { ...state, isOpenModal: payload };
    case WATCHLIST_ACTIONS.SET_SELECTED_SYMBOLS_LIST:
      return {
        ...state,
        selectedSymbolsList: payload.index !== -1
          ? state.selectedSymbolsList?.filter((subItem) => subItem.sym !== payload.item.sym)
          : [...state.selectedSymbolsList, payload.item]
      };
    case WATCHLIST_ACTIONS.DELETE_SELECTED_ITEM:
      return {
        ...state,
        selectedSymbolsList: state.selectedSymbolsList?.filter((subItem) => subItem !== payload)
      };
    case WATCHLIST_ACTIONS.CLEAR_SELECTED_ITEMS:
      return { ...state, selectedSymbolsList: [] };
    case WATCHLIST_ACTIONS.SET_SELECTED_ITEMS_FROM_WATCHLIST:
      return { ...state, selectedSymbolsList: payload };
    case WATCHLIST_ACTIONS.SET_TAB_FROM_DROPDOWN:
      return {
        ...state,
        tabFromDropDown: payload
      };
    case WATCHLIST_ACTIONS.UPDATE_SPECIFIC_WATCHLIST:
      return {
        ...state,
        tabsList: updateSpecificWL(payload, state.tabsList)
      };
    case WATCHLIST_ACTIONS.UPDATE_FAVOURITE_WATCHLIST:
      return {
        ...state,
        favoriteTab: payload
      };
    case WATCHLIST_ACTIONS.SET_SELECTED_TAB_ID_WATCHLIST:
      return {
        ...state,
        selectedTabID: payload
      };
    case WATCHLIST_ACTIONS.SET_TARGET_LIST:
      return { ...state, targetList: payload };
    case WATCHLIST_ACTIONS.SET_CURRENT_TARGET_LIST:
      return { ...state, currentTargetList: payload };
    case WATCHLIST_ACTIONS.SET_TARGET_SYMBOL:
      return { ...state, targetSymbol: payload };
    case WATCHLIST_ACTIONS.SET_HOLIDAYS_LIST:
      return { ...state, holidaysList: payload };
    case WATCHLIST_ACTIONS.SET_ALERTS_DATA:
      return { ...state, alertsTab: payload };
    case WATCHLIST_ACTIONS.SET_ACTIVE_ALERT:
      return { ...state, activeAlertID: payload };
    case WATCHLIST_ACTIONS.SET_FOLDER:
      return { ...state, folders: payload };
    case WATCHLIST_ACTIONS.SET_FOLDER_PRESET:
      return { ...state, presetFolders: payload };
    case WATCHLIST_ACTIONS.SET_PRESET:
      return { ...state, presets: payload };
    case WATCHLIST_ACTIONS.SET_ITEMS_DEEPLIST:
      return { ...state, itemsWatchlist: payload };
    case WATCHLIST_ACTIONS.SET_PRESETS_DEEPLIST:
      return { ...state, presetsWatchlist: payload };
    default:
      return state;
  }
};

export default watchlistReducer;
