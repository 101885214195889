import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TextField from '../TextField/TextField';
import { NEW_SCREEN_DATA } from '../../constants/screener';
import styles from './sass/Select.module.scss';
import Button from '../Button/Button';
import useOutsideAlerter from '../../utils/hooks/useOutside';
import { ReactComponent as Chevron } from '../../assets/images/icons/screener/blueChevronDown.svg';
import { ReactComponent as AddFolderIconIcon } from '../../assets/images/icons/addFolder.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/Check.svg';
import { ReactComponent as FolderIcon } from '../../assets/images/icons/screener/folder.svg';
import { setCreatedFolder } from '../../store/newScreen/actions';

const Select = ({
  title,
  valueSelect,
  setValueSelect,
  placeholder,
  data,
  showValue,
  addFolderHandler,
  wrapperClass
}) => {
  const dispatch = useDispatch();
  const [dropdownVisible, setDropdownVisible, ref] = useOutsideAlerter(false);
  const [selectedTitle, setSelectedTitle] = useState(placeholder);

  const selectOptionHandler = (event) => {
    dispatch(setCreatedFolder(null));
    setValueSelect(event.currentTarget.value);
    setDropdownVisible(false);
  };

  const toggleDropDownVisible = () => {
    setDropdownVisible(!dropdownVisible);
  };

  useEffect(() => {
    const selectedItem = data.filter((item) => item.id === +valueSelect);
    if (selectedItem.length) {
      setSelectedTitle(selectedItem[0]?.title);
    } else {
      setSelectedTitle(placeholder);
    }
  }, [valueSelect, data]);

  return (
    <div
      className={`${styles.inputWrapper} ${styles.selectWrapper}
        ${dropdownVisible ? styles.active : ''} ${wrapperClass}`}
      ref={ref}
    >
      <TextField text={title} styleType="createFolderModalBody" />
      <div
        className={`
          ${styles.customSelect}
          ${valueSelect === -1 ? styles.empty : ''}
          ${selectedTitle === placeholder ? styles.placeholder : ''}
        `}
        onClick={toggleDropDownVisible}
        aria-hidden="true"
      >
        {showValue ? selectedTitle || placeholder : ''}
      </div>
      <Chevron onClick={toggleDropDownVisible} className={styles.chevronIcon} />
      <Button
        buttonClass={styles.addFolderButton}
        handleClick={addFolderHandler}
      >
        <AddFolderIconIcon />
      </Button>
      {
        dropdownVisible && (
          <div className={styles.dropdown}>
            {
              data.map((item) => (
                item.type === NEW_SCREEN_DATA.FOLDER
                  ? (
                    <button
                      type="button"
                      key={item.id}
                      className={`${styles.dropdown_item} ${+valueSelect === item.id ? styles.active : ''}`}
                      onClick={selectOptionHandler}
                      value={item.id}
                      title={item.title}
                    >
                      {
                        +valueSelect === item.id ? <CheckIcon /> : <FolderIcon />
                      }
                      {item.title}
                    </button>
                  ) : null
              ))
            }
          </div>
        )
      }

    </div>
  );
};

Select.propTypes = {
  title: PropTypes.string,
  valueSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValueSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addFolderHandler: PropTypes.func.isRequired,
  showValue: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

Select.defaultProps = {
  showValue: true,
  title: '',
  placeholder: '',
  wrapperClass: '',
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.valueSelect === nextProps.valueSelect,
    prevProps.data === nextProps.data);
};

export default React.memo(Select, areEqual);
