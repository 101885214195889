import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../../DefaultButton/DefaultButton';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import ScreenerViewEnum from '../enums';
import { IScreenerMenu } from '../interfaces/ScreenerInterfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import { setTvIsReady } from '../../../store/screener/actions';
import { SCREENER_VIEW } from '../../../constants/screener';
import { ReactComponent as ScreenerViewIcon } from '../../../assets/images/icons/screener/ScreenerView.svg';
import { ReactComponent as ChartViewIcon } from '../../../assets/images/icons/screener/ChartView.svg';
import styles from '../sass/ScreenerMenu.module.scss';
import { setMultiChartSymbolsList } from '../../../store/multiChart/actions';

const ScreenerMenuView = ({ isAlertTab }: IScreenerMenu): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [viewToolSingle, setViewToolSingle] = useState(false);
  const [viewToolMulti, setViewToolMulti] = useState(false);
  const screenerView = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.screenerView, shallowEqual
  );
  const { updateStoreHandler } = useLastStateUpdateStore();

  const onChangeView = (view: ScreenerViewEnum) => {
    if (view === ScreenerViewEnum.CHART) {
      dispatch(setTvIsReady(true));
    }
    dispatch(setMultiChartSymbolsList([]));
    updateStoreHandler(SCREENER_VIEW, view);
  };

  return (
    <div className={styles.menuView}>
      <div
        className={styles.menuButton}
        onMouseEnter={() => setViewToolSingle(true)}
        onMouseLeave={() => setViewToolSingle(false)}
      >
        <DefaultButton
          handleClick={() => onChangeView(ScreenerViewEnum.SCREENER)}
          className={`${styles.menuViewButton} ${screenerView === ScreenerViewEnum.SCREENER && styles.activeView}`}
        >
          <ScreenerViewIcon />
        </DefaultButton>
        {viewToolSingle && <div className={styles.hoverDropDownSingle}>{t('singleChart')}</div>}
      </div>
      <div
        className={styles.menuButton}
        onMouseEnter={() => setViewToolMulti(true)}
        onMouseLeave={() => setViewToolMulti(false)}
      >
        <DefaultButton
          handleClick={() => onChangeView(ScreenerViewEnum.CHART)}
          disabled={isAlertTab}
          className={`${styles.menuViewButton}
            ${screenerView === ScreenerViewEnum.CHART && styles.activeView} ${isAlertTab ? styles.blurButton : ''}`}
        >
          <ChartViewIcon />
        </DefaultButton>
        {viewToolMulti && <div className={styles.hoverDropDownMulti}>{t('multiChartView')}</div>}
      </div>
    </div>
  );
};

export default ScreenerMenuView;
