const WATCHLIST_ACTIONS = {
  UPDATE_TABS_ORDER: '@@UPDATE_TABS_ORDER',
  SET_SELECTED_TAB: '@@SET_SELECTED_TAB',
  SET_SELECTED_TAB_NEW_COLUMNS_SET: '@@SET_SELECTED_TAB_NEW_COLUMNS_SET',
  SET_MODAL_TYPE: '@@SET_MODAL_TYPE',
  SET_IS_OPEN_MODAL: '@@SET_IS_OPEN_MODAL',
  SET_WATCHLIST_DATA: '@@SET_WATCHLIST_DATA',
  SET_FAVORITES_DATA: '@@SET_FAVORITES_DATA',
  SET_SELECTED_SYMBOLS_LIST: '@@SET_SELECTED_SYMBOLS_LIST',
  DELETE_SELECTED_ITEM: '@@DELETE_SELECTED_ITEM',
  CLEAR_SELECTED_ITEMS: '@@CLEAR_SELECTED_ITEMS',
  SET_SELECTED_ITEMS_FROM_WATCHLIST: '@@SET_SELECTED_ITEMS_FROM_WATCHLIST',
  SET_TAB_FROM_DROPDOWN: '@@SET_TAB_FROM_DROPDOWN',
  UPDATE_SPECIFIC_WATCHLIST: '@@UPDATE_SPECIFIC_WATCHLIST',
  UPDATE_FAVOURITE_WATCHLIST: '@@UPDATE_FAVOURITE_WATCHLIST',
  SET_SELECTED_TAB_ID_WATCHLIST: '@@SET_SELECTED_TAB_ID_WATCHLIST',
  SET_TARGET_LIST: '@@SET_TARGET_LIST',
  SET_CURRENT_TARGET_LIST: '@@SET_CURRENT_TARGET_LIST',
  SET_TARGET_SYMBOL: '@@SET_TARGET_SYMBOL',
  SET_HOLIDAYS_LIST: '@@SET_HOLIDAYS_LIST',
  SET_ALERTS_DATA: '@@SET_ALERTS_DATA',
  SET_ACTIVE_ALERT: '@@SET_ACTIVE_ALERT',
  SET_FOLDER: '@@SET_FOLDER',
  SET_FOLDER_PRESET: '@@SET_FOLDER_PRESET',
  SET_PRESET: '@@SET_PRESET',
  SET_ITEMS_DEEPLIST: '@@SET_ITEMS_DEEPLIST',
  SET_PRESETS_DEEPLIST: '@@SET_PRESETS_DEEPLIST',
};

export default WATCHLIST_ACTIONS;
