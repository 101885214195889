import React from 'react';
import PropTypes from 'prop-types';
import stylesModal from './sass/NewScreenModals.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import TextField from '../TextField/TextField';

const NewScreenModalHeader = ({
  cancelHandler, headerText
}) => (
  <div className={stylesModal.moveToFolderHeader}>
    <TextField text={headerText} styleType="createFolderModalHeader" />
    <div aria-hidden="true" onMouseDown={cancelHandler}>
      <CloseIcon />
    </div>
  </div>
);

NewScreenModalHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  cancelHandler: PropTypes.func.isRequired,
};

export default NewScreenModalHeader;
