import { IExtendCurrentWidget } from '../components/ChartContainer/types/Interfaces';

type TResetRealTime = {
  cb: () => void
};

interface IResetChartDataProcessor {
  chart: IExtendCurrentWidget | null;
  resetRealTime: TResetRealTime[];
  setChart: (chart: IExtendCurrentWidget | null) => void;
  setResetRealTime: (cb: TResetRealTime) => void;
  resetDataHandler: () => void;
}

class ResetChartDataProcessor implements IResetChartDataProcessor{
  chart: IExtendCurrentWidget | null;

  resetRealTime: TResetRealTime[];

  constructor() {
    this.chart = null;
    this.resetRealTime = [];
  }

  setChart(chart: IExtendCurrentWidget | null): void {
    this.chart = chart;
  }

  setResetRealTime(cb: TResetRealTime): void{
    this.resetRealTime.push(cb);
  }

  resetDataHandler(): void {
    this.resetRealTime = this.resetRealTime.filter((item) => {
      item.cb();
      return item;
    });

    const timeout = setTimeout(() => {
      const chartCount = this.chart?.chartsCount() || 0;

      for (let i = 0; i < chartCount; i += 1) {
        if (this.chart?.chart()) {
          this.chart?.chart(i)?.resetData();
        }
      }

      clearTimeout(timeout);
    }, 300);

    if (this.resetRealTime.length >= 20) {
      this.resetRealTime = this.resetRealTime.slice(10, this.resetRealTime.length);
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const resetChartData = new ResetChartDataProcessor();
