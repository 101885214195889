export enum InputNames {
  ID = 'id',
  ACCOUNTSIZE = 'acctSize',
  EQUITYRISK = 'desrdPFRisk',
  EQUITYRISKPERC = 'percentPFRisk',
  MAXPOSITION = 'desrdPosSz',
  MAXPOSITIONPERC = 'percentPosSz',
  ENTRYPOINT = 'entryPrice',
  SYMBOL = 'symbol',
  POTENTIALPROFIT = 'potentialPFTakingPc',
  POTENTIALPROFITPERC = 'percentPotentialPFTakingPc',
  EXITPRICE = 'exitPrice',
  EXITPRICEPERC = 'percentExitPrice'
}

export enum InputDesignTypes {
  regular = 'inputBox',
  dollar = 'dollar',
  percent = 'percent',
  dollarDark = 'dollarDark',
  percentDark = 'percentDark'
}

export enum PositionSizeConstants {
  WIDTH_BREAKPOINT = 500,
}

export const THOUSAND_SEPARATOR = ',';
export const DECIMAL_SCALE = 3;
export const MAX_LIMIT = 100;
