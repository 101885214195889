import { StudyLinePlotPreferences } from "../../../charting_library/charting_library";

export const defaultStyles = {
  volumeBarsPlot: {
    plottype: 5 as StudyLinePlotPreferences['plottype'],
  },
  volumeMAPlot: {
    color: 'fuchsia',
  },
};

export const styles = {
  volumeBarsPlot: {
    histogramBase: 0,
    title: 'Volume',
  },
  volumeMAPlot: {
    title: 'Volume Moving Average',
  },
};

