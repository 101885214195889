import { useCallback, useEffect, useState } from 'react';
import { PAGING_SETTINGS } from '../../constants/screener';

const useLazySearch = (resultList, setInputValue) => {
  const [lazyResult, setLazyResult] = useState([]);
  const [paging, setPaging] = useState(0);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(resultList)).filter((item) => item.enabled === true);
    setLazyResult([...lazyResult, ...data.splice(paging, PAGING_SETTINGS)]);
  }, [paging]);

  const setPagingHandler = useCallback(
    (flag = false) => {
      if (flag) {
        setPaging(0);
        setInputValue('');
      } else {
        setPaging((prevState) => prevState + PAGING_SETTINGS);
      }
    },
    [paging],
  );

  return {
    lazyResult, setLazyResult, paging, setPaging, setPagingHandler
  };
};

export default useLazySearch;
