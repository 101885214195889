import { IUseNewVersionHook } from './HooksInterphaces';

const useNewVersion: IUseNewVersionHook = () => {
  const checkVersion = () => {
    try {
      const localStorageVersion = localStorage.getItem('appVersion');
      fetch(`${process.env.REACT_APP_PUB_APP_URL}version.txt`)
        .then((response) => response.text())
        .then((currentVersion) => {
          // eslint-disable-next-line no-console
          console.log('=> current_version', currentVersion);
          if (currentVersion !== localStorageVersion) {
            localStorage.setItem('appVersion', currentVersion);
            window.location.reload();
          }
        });
    } catch (e: unknown) {
      throw new Error(e as string);
    }
  };

  return { checkVersion };
};

export default useNewVersion;
