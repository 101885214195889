import ThemeVariants from '../../../../constants/theme';
import { changeColorHandler } from '../../../../utils/convertingDataHelper';
import { BAR_CHART_COLORS } from '../../types/WidgetChartsEnums';

const pLabelColor = (theme, dataPointsXType, context, findIndex, index, sortBy) => {
  let finalColor = theme === ThemeVariants.DARK_MODE
    ? BAR_CHART_COLORS.gray : BAR_CHART_COLORS.midGray;
  const types = dataPointsXType?.split('_');
  if (context?.dataset?.data[0] != null) {
    const value = (findIndex !== -1 && index >= findIndex)
      ? (-context.dataset.data[0]) : context.dataset.data[0];
    types.forEach((typeItem) => {
      const newColor = changeColorHandler(typeItem, (sortBy === 1 ? value : (-value)));

      if (newColor && newColor.color && newColor.title === 'colorTxt') {
        finalColor = `#${newColor.color}`;
      }
    });
  }
  return finalColor;
};

export default pLabelColor;
