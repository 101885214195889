import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../../sass/PickColumnWrapper.module.scss';
import { ReactComponent as IconClose } from '../../../../assets/images/icons/screener/close_square_red.svg';
import { ReactComponent as IconBrush } from '../../../../assets/images/icons/screener/brush.svg';
import TextField from '../../../TextField/TextField';
import { COLUMNS_CLASS } from '../../../../constants/screener';

const areEqual = (prevProps, nextProps) => {
  return prevProps.columnSetId === nextProps.columnSetId
    && JSON.stringify(prevProps.defaultSelectedColumns) === JSON.stringify(nextProps.defaultSelectedColumns)
    && JSON.stringify(prevProps.sortData) === JSON.stringify(nextProps.sortData)
    && JSON.stringify(prevProps.selectedColumns) === JSON.stringify(nextProps.selectedColumns);
};

const ColumnsSelectedFooter = ({
  clearAllHandler, defaultSelectedColumns,
  selectedColumns, clearSortHandler,
  saveColumnsHandler, columnSetId,
  sortData,
}) => {
  const { t } = useTranslation();
  const [currentSortStatus, setCurrentSortStatus] = useState(false);

  const checkIsDefault = () => {
    return styles[
      JSON.stringify(defaultSelectedColumns) === JSON.stringify(selectedColumns)
        ? COLUMNS_CLASS.SELECT_BUTTON_DISABLED : COLUMNS_CLASS.SELECT_BUTTON
    ];
  };

  useEffect(() => {
    setCurrentSortStatus(sortData?.length > 0);
  }, [sortData]);
  return (
    <div className={styles.rightBottomContent}>
      <div
        className={checkIsDefault()}
        aria-hidden="true"
        onMouseDown={() => {
          saveColumnsHandler(columnSetId);
          clearAllHandler();
        }}
      >
        <IconClose />
        <TextField text={t('resetColumn')} styleType="rightBottomContentText" />
      </div>
      {currentSortStatus && sortData && (
        <>
          <div className={`${styles.sortPositionNumber} ${styles.sortPositionNumberBottom}`}>{sortData.length}</div>
          <div
            className={styles.bottomButton}
            aria-hidden="true"
            onMouseDown={clearSortHandler}
          >
            <IconBrush />
            <TextField text={t('clearSort')} styleType="rightBottomContentText" />
          </div>
        </>
      )}
    </div>
  );
};

ColumnsSelectedFooter.propTypes = {
  columnSetId: PropTypes.number,
  saveColumnsHandler: PropTypes.func.isRequired,
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  defaultSelectedColumns: PropTypes.arrayOf(PropTypes.shape({})),
  clearAllHandler: PropTypes.func.isRequired,
  clearSortHandler: PropTypes.func,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
};

ColumnsSelectedFooter.defaultProps = {
  columnSetId: -1,
  defaultSelectedColumns: [{}],
  clearSortHandler: () => undefined,
  sortData: [],
};

export default React.memo(ColumnsSelectedFooter, areEqual);
