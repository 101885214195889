import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import { IEvents } from './types/EducationComponentsInterfaces';
import useEvents from './hooks/useEvents';
import EventCard from './EventCard';
import WebinarCard from './WebinarCard';
import { WEBINAR_SLUG } from './types/EducationComponentsEnum';
import CategoryFilter from './CategoryFilter';
import LevelFilter from './LevelFilter';
import SortBlock from './SortBlock';

const Events = ({ eventsParams, setEventsParams }: IEvents): React.ReactElement => {
  const {
    ref, eventsList, webinarsList, webinarsCategories, levels
  } = useEvents(eventsParams, setEventsParams);

  return (
    <div className={styles.educationWrapper} ref={ref}>
      {eventsList?.events?.length > 0 && (
        <>
          <h1 className={styles.mainTitle}>Upcoming</h1>
          <div className={styles.events}>
            {eventsList.events?.map((item) => (
              <EventCard
                key={item.id}
                title={item.title}
                date={item.start_date}
                link={item.video_link}
                image={item.featured_image}
                categories={[]}
                articleCategories={[]}
              />
            ))}
          </div>
        </>
      )}
      {webinarsList.posts?.length > 0 && (
        <>
          <div className={styles.dropdowns}>
            <div className={styles.leftBlock}>
              <h1 className={styles.mainTitle}>Previous</h1>
            </div>
            <div className={styles.rightBlock}>
              <CategoryFilter
                params={eventsParams}
                setParams={setEventsParams}
                categories={webinarsCategories}
              />
              <LevelFilter
                params={eventsParams}
                setParams={setEventsParams}
                categories={levels}
              />
              <SortBlock
                params={eventsParams}
                setParams={setEventsParams}
              />
            </div>
          </div>
          <div className={styles.cards}>
            {webinarsList.posts?.map((item) => (
              <WebinarCard
                key={item.id}
                title={item.title}
                date={item.publish_date}
                excerpt={item.excerpt}
                videoSrc={item.video_src}
                posterSrc={item.poster_src}
                categories={item.categories.filter((value) => value.toLowerCase() !== WEBINAR_SLUG)}
                isGridView
                tutorialCategories={webinarsCategories}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default Events;
