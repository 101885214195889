import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './sass/ButtonExportScreener.module.scss';
import ExportItem from './ExportItem';
import constants from '../../constants/filters';
import { SCREENER_VIEWS } from '../../constants/screener';
import { exportScreenerCase, exportScreenerList, EXPORT_TYPES } from '../../lists/exportScreenerList';
import { ReactComponent as CopyIcon } from '../../assets/images/icons/copy.svg';
import { ReactComponent as PaperIcon } from '../../assets/images/icons/paper.svg';

const areEqual = (prevProps, nextProps) => {
  return prevProps.watchList === nextProps.watchList
  && prevProps.viewMenu === nextProps.viewMenu;
};

const DropList = ({
  exportFileHandler,
  copyToClipBoard,
  closeMenu,
  setViewPopUp,
  copyToClipBoardTwitter,
  viewMenu,
  watchList,
  screenerView,
  setViewLabelPopUp
}) => {
  const { t } = useTranslation();
  const filteredExportScreenerList = exportScreenerList
    .filter(({ type }) => (screenerView === SCREENER_VIEWS.CHART ? type !== EXPORT_TYPES.PAPER : type));

  const clickHandler = useCallback((id, label) => {
    if (id === constants.copy) {
      setViewPopUp(true);
    } else if (id === constants.copyLabel) {
      setViewLabelPopUp(true);
    }
    closeMenu();
    exportScreenerCase(label, exportFileHandler, copyToClipBoard, copyToClipBoardTwitter, id);
  }, [viewMenu]);

  const getIcon = (type) => {
    return type === EXPORT_TYPES.COPY ? CopyIcon : PaperIcon;
  };

  return (
    <div className={`${styles.dropMenu} ${watchList && styles.watchlistDropMenu}`}>
      {filteredExportScreenerList.map((item, index) => (
        <ExportItem
          label={item.label}
          Icon={getIcon(item.type)}
          exportClick={clickHandler}
          text={t(`${item.label}`)}
          key={[item.label, index].join('_')}
          id={item.id}
        />
      ))}
    </div>
  );
};

DropList.propTypes = {
  watchList: PropTypes.bool.isRequired,
  viewMenu: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  setViewPopUp: PropTypes.func.isRequired,
  setViewLabelPopUp: PropTypes.func,
  screenerView: PropTypes.string.isRequired,
  copyToClipBoard: PropTypes.func.isRequired,
  copyToClipBoardTwitter: PropTypes.func.isRequired,
  exportFileHandler: PropTypes.func.isRequired,
};

DropList.defaultProps = {
  setViewLabelPopUp: () => {}
};

export default React.memo(DropList, areEqual);
