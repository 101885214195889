import { createSlice } from '@reduxjs/toolkit';
import {
  Categories, Articles, Tutorials, Events,
  Levels
} from '../../components/EducationComponents/types/EducationComponentsTypes';

const INITIAL_STATE = {
  isArticlesLoading: true,
  articlesList: {
    posts: [],
    total_posts: 0,
    max_pages: 0,
    current_page: 0,
  } as Articles,
  isTutorialsLoading: true,
  tutorialsList: {
    posts: [],
    total_posts: 0,
    max_pages: 0,
    current_page: 0,
  } as Tutorials,
  articleCategories: [{
    name: 'All',
    slug: 'all',
    id: 0,
  }] as Categories[],
  levels: [{
    name: 'All',
    slug: 'all',
    id: 0,
  }] as Levels[],
  tutorialCategories: [{
    name: 'All',
    slug: 'all',
    id: 0,
  }] as Categories[],
  eventsList: {
    events: [],
    total_posts: 0,
    max_pages: 0,
    current_page: 0,
  } as Events,
  webinarsList: {
    posts: [],
    total_posts: 0,
    max_pages: 0,
    current_page: 0,
  } as Tutorials,
};

const educationSlice = createSlice({
  name: 'educationSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setArticlesList(state, action) {
      state.articlesList.posts = action.payload.posts;
      state.articlesList.max_pages = action.payload.max_pages;
      state.articlesList.current_page = action.payload.current_page;
      state.articlesList.total_posts = action.payload.total_posts;
    },
    updateArticlesList(state, action) {
      return {
        ...state,
        articlesList: {
          posts: [...state.articlesList.posts, ...action.payload.posts],
          max_pages: action.payload.max_pages,
          current_page: action.payload.current_page,
          total_posts: action.payload.total_posts,
        }
      };
    },
    resetArticlesList(state) {
      return {
        ...state,
        articlesList: {
          posts: [],
          max_pages: 0,
          current_page: 0,
          total_posts: 0,
        }
      };
    },
    setIsArticlesLoading(state, action) {
      state.isArticlesLoading = action.payload;
    },
    setAllCategories(state, action) {
      state.articleCategories = [...state.articleCategories, ...action.payload.blog_categories];
      state.tutorialCategories = [...state.tutorialCategories, ...action.payload.webinars_categories];
      state.levels = [...state.levels, ...action.payload.levels];
    },
    resetCategories(state) {
      return {
        ...state,
        articleCategories: [{
          name: 'All',
          slug: 'all',
          id: 0,
        }],
        levels: [{
          name: 'All',
          slug: 'all',
          id: 0,
        }],
        tutorialCategories: [{
          name: 'All',
          slug: 'all',
          id: 0,
        }],
      };
    },
    setTutorialsList(state, action) {
      state.tutorialsList.posts = action.payload.posts;
      state.tutorialsList.max_pages = action.payload.max_pages;
      state.tutorialsList.current_page = action.payload.current_page;
      state.tutorialsList.total_posts = action.payload.total_posts;
    },
    updateTutorialsList(state, action) {
      return {
        ...state,
        tutorialsList: {
          posts: [...state.tutorialsList.posts, ...action.payload.posts],
          max_pages: action.payload.max_pages,
          current_page: action.payload.current_page,
          total_posts: action.payload.total_posts,
        }
      };
    },
    resetTutorialsList(state) {
      return {
        ...state,
        tutorialsList: {
          posts: [],
          max_pages: 0,
          current_page: 0,
          total_posts: 0,
        }
      };
    },
    setIsTutorialsLoading(state, action) {
      state.isTutorialsLoading = action.payload;
    },
    setWebinarsList(state, action) {
      state.webinarsList.posts = action.payload.posts;
      state.webinarsList.max_pages = action.payload.max_pages;
      state.webinarsList.current_page = action.payload.current_page;
      state.webinarsList.total_posts = action.payload.total_posts;
    },
    updateWebinarsList(state, action) {
      return {
        ...state,
        webinarsList: {
          posts: [...state.webinarsList.posts, ...action.payload.posts],
          max_pages: action.payload.max_pages,
          current_page: action.payload.current_page,
          total_posts: action.payload.total_posts,
        }
      };
    },
    resetWebinarsList(state) {
      return {
        ...state,
        webinarsList: {
          posts: [],
          max_pages: 0,
          current_page: 0,
          total_posts: 0,
        }
      };
    },
    setEventsList(state, action) {
      state.eventsList.events = action.payload.events;
      state.eventsList.max_pages = action.payload.max_pages;
      state.eventsList.current_page = action.payload.current_page;
      state.eventsList.total_posts = action.payload.total_posts;
    },
  },
});

export const {
  setIsArticlesLoading,
  setArticlesList,
  updateArticlesList,
  resetArticlesList,
  setIsTutorialsLoading,
  setTutorialsList,
  updateTutorialsList,
  resetTutorialsList,
  setAllCategories,
  setWebinarsList,
  updateWebinarsList,
  resetWebinarsList,
  setEventsList,
  resetCategories,
} = educationSlice.actions;

export default educationSlice;
