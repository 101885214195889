import React from 'react';
import ROUTES_URLS from '../constants/routesUrls';
import Dashboards from '../pages/Dashboards/Dashboards';
import Widget from '../pages/Widget/Widget';
import Table from '../pages/Table/Table';
import Education from '../pages/Education/Education';

const routes = [
  {
    path: ROUTES_URLS.DASHBOARDS,
    exact: true,
    render: () => <Dashboards />,
    title: 'Dashboard',
    isProtected: true
  },
  {
    path: ROUTES_URLS.EDUCATION,
    exact: true,
    render: () => <Education />,
    title: 'Education',
    isProtected: true
  },
  {
    path: ROUTES_URLS.TABLE,
    render: () => <Table />,
    title: 'table',
    isProtected: true,
    exact: true,
  },
];

export const webViewRoutes = [
  {
    path: ROUTES_URLS.WIDGET,
    exact: true,
    render: () => <Widget />,
    title: 'widget',
  },
];

export default routes;
