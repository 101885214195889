import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Image.module.scss';

const Image = ({
  src, alt, styleType
}) => (
  <img src={src} alt={alt} className={styles[styleType]} />
);

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  styleType: PropTypes.string,
};

Image.defaultProps = {
  src: '',
  alt: '',
  styleType: '',
};

export default React.memo(Image);
