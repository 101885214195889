import React, { useEffect, useRef } from 'react';

import { GRID_COLUMNS_MASK, GRID_ROWS_MASK } from './types/Constants';
import styles from './sass/DashboardComponents.module.scss';

const GridMask = (
  { parrentWidth, parrentHeight }: {
    parrentWidth: number;
    parrentHeight: number;
  }
): React.ReactElement => {
  const canvasRef = useRef(null);

  const updateCanvas = (): void => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas?.getContext('2d') as CanvasRenderingContext2D;

    // Clear the canvas before drawing
    ctx.clearRect(0, 0, parrentWidth, parrentHeight);

    function drawGridPoints() {
      const pointRadius = 0.5;
      const padding = 2; // Padding from the edge of the canvas
      const gridWidth = parrentWidth;
      const gridHeight = parrentHeight;
      const xSpacing = gridWidth / (GRID_COLUMNS_MASK);
      const ySpacing = gridHeight / (GRID_ROWS_MASK);
      for (let row = 0; row < GRID_ROWS_MASK; row += 1) {
        for (let col = 0; col < GRID_COLUMNS_MASK; col += 1) {
          const x = padding + col * xSpacing;
          const y = padding + row * ySpacing;

          ctx.beginPath();
          ctx.arc(x, y, pointRadius, 0, Math.PI * 2, true); // Draw a circle for each point
          ctx.fillStyle = 'rgba(146,164,245,1)'; // Color of the point
          ctx.fill();
          ctx.closePath();
        }
      }
    }
    // Draw the grid points on the canvas
    drawGridPoints();
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    updateCanvas();
  }, [canvasRef.current]);

  useEffect(() => {
    updateCanvas();
  }, [parrentWidth, parrentHeight]);

  return (
    <div className={styles.gridMask}>
      <canvas width={parrentWidth} height={parrentHeight} ref={canvasRef} />
    </div>
  );
};

export default GridMask;
