export enum IndustryGroupNames {
  INDUSTRY,
  SECTOR,
  GROUP,
  SUBINDUSTRY
}

export const industryColumns = [
  218, 1741, 1995
];

export const groupIndex = 29;

export const industryIndex = 21;

export const sectorIndex = 20;

export const subIndustryIndex = 30;

export const priceChangeToday = 218;

export const industryGroupIndexes = [
  20, 21, 29, 30
];

export const industryDataRowsNames = {
  [IndustryGroupNames.INDUSTRY]: 'industryDataRows',
  [IndustryGroupNames.SECTOR]: 'sectorDataRows',
  [IndustryGroupNames.GROUP]: 'groupDataRows',
  [IndustryGroupNames.SUBINDUSTRY]: 'subIndustryDataRows'
};

export const industryDataColumnsNames = {
  [IndustryGroupNames.INDUSTRY]: 'industryDataColumns',
  [IndustryGroupNames.SECTOR]: 'sectorDataColumns',
  [IndustryGroupNames.GROUP]: 'groupDataColumns',
  [IndustryGroupNames.SUBINDUSTRY]: 'subIndustryDataColumns'
};

export const industryDataScreenerIds = {
  [IndustryGroupNames.INDUSTRY]: 'industryScreenerId',
  [IndustryGroupNames.SECTOR]: 'sectorScreenerId',
  [IndustryGroupNames.GROUP]: 'groupScreenerId',
  [IndustryGroupNames.SUBINDUSTRY]: 'subIndustryScreenerId'
};
