import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CheckedIcon } from '../../../assets/images/icons/Check.svg';
import styles from '../sass/TableScreener.module.scss';
import TextField from '../../../components/TextField/TextField';
import { ALERT_TIME_DROP_DELTA } from '../../../constants/alerts';

const TimeDropList = ({
  data, handlerChange, selectedItem
}) => {
  const ref = useRef();
  const [itemHeight, setItemHeight] = useState(110);

  useEffect(() => {
    setItemHeight(ref?.current?.getBoundingClientRect()?.height + ALERT_TIME_DROP_DELTA);
  }, []);

  return (
    <div
      ref={ref}
      className={styles.dropDown}
      style={{ bottom: -itemHeight }}
    >
      <div className={styles.dropDownWraper}>
        {data?.map((item, index) => (
          <div
            key={[item?.name, index].join('_')}
            className={styles.optionContainer}
            onClick={() => handlerChange(item)}
            onKeyDown={() => handlerChange(item)}
            role="button"
            aria-hidden="true"
            tabIndex="0"
          >
            {item?.name === selectedItem?.name
              ? <CheckedIcon /> : <div className={styles.emptyBox} />}
            <TextField
              styleType={item?.name === selectedItem?.name ? 'selectedFiltersOptionActive' : 'selectedFiltersOption'}
              text={item?.name || ''}
              key={[item?.name, index].join('_')}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

TimeDropList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  handlerChange: PropTypes.func,
  selectedItem: PropTypes.shape({
    name: PropTypes.string
  }),
};

TimeDropList.defaultProps = {
  data: [],
  handlerChange: () => undefined,
  selectedItem: {},
};

export default TimeDropList;
