class OrderedSymbolCache {
  constructor() {
    this.data = [];
  }

  setData(data) {
    this.data = data?.map((item) => (
      { sym: item[0]?.sym, sortIndex: item[0]?.sortIndex }
    ));
  }

  setWidgetData(data) {
    this.data = data.map((item) => (
      { sym: item?.sym, sortIndex: item?.index }
    ));
  }
}

// eslint-disable-next-line import/prefer-default-export
export const orderedCache = new OrderedSymbolCache();
