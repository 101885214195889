import React from 'react';
import PropTypes from 'prop-types';
import CreateFolderModal from './CreateFolderModal';
import { MODAL_TITLES } from '../../constants/screener';
import constants from '../../constants/filters';

const CreateFolderModalHandler = ({
  openModal,
  closeModalHandler,
  createFolder
}) => (
  <>
    {openModal === MODAL_TITLES.CREATE_FOLDER && (
      <CreateFolderModal
        type={constants.screener}
        createFolder={createFolder}
        setOpenModal={closeModalHandler}
      />
    )}
    {openModal === MODAL_TITLES.CREATE_FOLDER_PRESET && (
      <CreateFolderModal
        type={constants.preset}
        createFolder={createFolder}
        setOpenModal={closeModalHandler}
      />
    )}
    {openModal === MODAL_TITLES.CREATE_FOLDER_COLUMNS && (
      <CreateFolderModal
        type={constants.columns}
        createFolder={createFolder}
        setOpenModal={closeModalHandler}
      />
    )}

  </>
);

CreateFolderModalHandler.propTypes = {
  openModal: PropTypes.string.isRequired,
  closeModalHandler: PropTypes.func,
  createFolder: PropTypes.func,
};

CreateFolderModalHandler.defaultProps = {
  closeModalHandler: () => undefined,
  createFolder: () => undefined,
};

export default React.memo(CreateFolderModalHandler);
