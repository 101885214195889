import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './sass/PositionSize.module.scss';
import Button from '../Button/Button';
import PositionSizeResult from './components/PositionSizeResult';
import PositionSizeInputBox from './components/PositionSizeInputBox';
import FormLayout from './components/FormLayout';
import { InputNames, PositionSizeConstants } from './types/PositionSizeEnums';
import usePositionSize from './hooks/usePositionSize';
import { AllowedInputStyles } from './types/PositionSizeTypes';
import { IPositionSize } from './types/PostionSizeInterfaces';

const PositionSize = ({
  width, widget, dashboardId, updateWidget
}: IPositionSize): React.ReactElement => {
  const { t } = useTranslation();

  const {
    details,
    getQuote,
    handleInputEvent,
    dollarsToPercent,
    percentToDollars,
    calcPercentChange,
    calcPercentProfit,
    calcPercentLoss,
    handleCalculations,
    recshares,
    amountatrisk,
    posvalue,
    rmultiple,
    getInputDesignTypeByTheme,
    warning
  } = usePositionSize(widget, dashboardId, updateWidget);

  const handleSymbolChange = (e: React.ChangeEvent) => {
    const inputElement = e.target as HTMLInputElement;
    const { name, value } = inputElement;
    handleInputEvent(name, value);
  };

  return (
    <div className={width > PositionSizeConstants.WIDTH_BREAKPOINT ? styles.containerRow : styles.containerCol}>
      <div className={styles.formWrapper}>
        <FormLayout title={t('accountSize')}>
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Dollar)}
            value={details.acctSize}
            name={InputNames.ACCOUNTSIZE}
            changeHandler={handleInputEvent}
          />
        </FormLayout>

        <FormLayout title={t('equityRisk')}>
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Dollar)}
            name={InputNames.EQUITYRISK}
            value={details.desrdPFRisk}
            validator={dollarsToPercent}
            changeHandler={handleInputEvent}
            target={InputNames.EQUITYRISKPERC}
            source={details.acctSize}
          />
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Percent)}
            name={InputNames.EQUITYRISKPERC}
            value={details.percentPFRisk}
            validator={percentToDollars}
            changeHandler={handleInputEvent}
            target={InputNames.EQUITYRISK}
            source={details.acctSize}
            maxPercentLimit
          />
        </FormLayout>

        <FormLayout title={t('maxPosition')}>
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Dollar)}
            name={InputNames.MAXPOSITION}
            value={details.desrdPosSz}
            validator={dollarsToPercent}
            changeHandler={handleInputEvent}
            target={InputNames.MAXPOSITIONPERC}
            source={details.acctSize}
          />
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Percent)}
            name={InputNames.MAXPOSITIONPERC}
            value={details.percentPosSz}
            validator={percentToDollars}
            changeHandler={handleInputEvent}
            target={InputNames.MAXPOSITION}
            source={details.acctSize}
            maxPercentLimit
          />
        </FormLayout>

        <FormLayout title={t('potentialProfit')}>
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Dollar)}
            name={InputNames.POTENTIALPROFIT}
            value={details.potentialPFTakingPc}
            validator={calcPercentProfit}
            changeHandler={handleInputEvent}
            target={InputNames.POTENTIALPROFITPERC}
            source={details.entryPrice}
          />
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Percent)}
            name={InputNames.POTENTIALPROFITPERC}
            value={details.percentPotentialPFTakingPc}
            validator={calcPercentChange}
            changeHandler={handleInputEvent}
            target={InputNames.POTENTIALPROFIT}
            source={details.entryPrice}
          />
        </FormLayout>

        <FormLayout title={t('potentialExit')}>
          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Dollar)}
            name={InputNames.EXITPRICE}
            value={details.exitPrice}
            validator={calcPercentLoss}
            changeHandler={handleInputEvent}
            target={InputNames.EXITPRICEPERC}
            source={details.entryPrice}
          />

          <PositionSizeInputBox
            inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Percent)}
            name={InputNames.EXITPRICEPERC}
            value={details.percentExitPrice}
            validator={calcPercentChange}
            changeHandler={handleInputEvent}
            target={InputNames.EXITPRICE}
            source={details.entryPrice}
            operator={-1}
          />
        </FormLayout>

        <div className={styles.rowWrapper}>
          <FormLayout title={t('entryPrice')}>
            <PositionSizeInputBox
              inputClass={getInputDesignTypeByTheme(AllowedInputStyles.Dollar)}
              name={InputNames.ENTRYPOINT}
              value={details.entryPrice}
              changeHandler={handleInputEvent}
            />
          </FormLayout>
          <FormLayout title={t('symbol')}>
            <input
              className={styles.inputBox}
              name={InputNames.SYMBOL}
              value={details.symbol}
              onChange={handleSymbolChange}
              autoComplete="off"
            />
          </FormLayout>

          <div className={styles.buttonWrapper}>
            <Button
              buttonClass={`${styles.quoteButton} ${details.symbol.length && styles.enabled}`}
              handleClick={() => getQuote(null)}
            >
              {t('getQuote')}
            </Button>
          </div>
        </div>

        <div className={styles.buttonWrapper}>
          <Button
            buttonClass={styles.calculateButton}
            handleClick={handleCalculations}
          >
            {t('calculate')}
          </Button>
        </div>
      </div>
      <PositionSizeResult
        posvalue={posvalue}
        recshares={recshares}
        amountatrisk={amountatrisk}
        rmultiple={rmultiple}
        warning={warning}
      />
    </div>
  );
};

export default PositionSize;
