export default {
  createNewScreen: 'Create New Screen',
  noFiltersApllied: 'No filters are applied',
  selectFiltersLeft: 'Select filters on the left',
  searchByFilter: 'Search by filter...',
  favorite: 'Favorites',
  general: 'General',
  technicals: 'Technicals',
  sectorAndIndustry: 'Sector & Industry',
  earnings: 'Earnings',
  country: 'Country',
  startTyping: 'Start typing...',
  state: 'State',
  sector: 'Sector',
  industry: 'Industry',
  subIndustry: 'Sub Industry',
  showResults: 'Show Results',
  beta: 'Beta',
  marketCap: 'Market Cap',
  sharesFloat: 'Shares Float',
  sharesOutstanding: 'Shares Outstanding',
  yield: 'Yield',
  inventory: 'Inventory T/O',
  bookValue: 'Book Value',
  editFilter: 'Edit Filter',
  appliedFilters: 'Applied Filters',
  basket: 'Group',
  clearAll: 'Clear All',
  groupFilterOne: 'Adding to Group 1',
  groupFilterTwo: 'Adding to Group 2',
  addOrLogic: 'Add “Or” logic to the FIlter',
  or: 'Or',
  saveScreen: 'Save Screen',
  filtersOrAllows: '“Or” Filter allows you to...',
  noResultsFound: 'No results found',
  textTooltip: 'An “Or” Group means that only one of the criteria within needs to be satisfied to successfully pass a'
  + ' stock through this section of the screen. You can add multiple “Or” groups to your screen.'
  + ' This is in contrast to Group 1 which is an “And” Group, meaning that every criteria needs to be'
  + ' satisfied to pass a stock through that layer of the screen). ',
  min: 'Min',
  max: 'Max',
  filters: 'Filters',
  noOptionsAvailable: 'No options are available',
  noFiltersAvailable: 'No filters are available',
  noFavorites: 'No Favorites',
  yes: 'Yes',
  no: 'No',
  textTooltipPresets: 'To start customizing, duplicate the preset filter',
  freeTooltipPresets: 'To start customizing, upgrade your plan',
  startDateLabel: 'Start date',
  endDateLabel: 'End date',
  startDatePlaceholder: 'Select start date',
  endDatePlaceholder: 'Select end date',
  lastMonthLabel: 'Last Month',
  lastWeekLabel: 'Last Week',
  nextWeekLabel: 'Next Week',
  nextMonthLabel: 'Next Month',
  lastYearLabel: 'Last Year',
  nextQuarterLabel: 'Next Quarter',
  type: 'Type',
  selectCountry: 'Select countries',
  selectState: 'Select states',
  searchByCountry: 'Search by country...',
  searchByState: 'Search by state...',
  selectSector: 'Select sectors',
  selectIndustry: 'Select industries',
  selectSubIndustry: 'Select sub industries',
  searchBySector: 'Search by sector...',
  searchByIndustry: 'Search by industry...',
  searchBySubIndustry: 'Search by sub industries...',
  include: 'Include',
  exclude: 'Exclude',
  createNewPreset: 'Create New Preset',
  savePreset: 'Create Preset',
  searchBy: 'Search by',
  select: 'Select'
};
