import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/TextAreaLabel.module.scss';
import TextField from '../TextField/TextField';
import TextArea from '../TextArea/TextArea';
import { MAX_LENGTH_TEXT_AREA } from '../../constants/screener';

const TextAreaLabel = ({
  label,
  onChange,
  placeholder,
  name,
  value,
  disable,
  styleType,
  requiredInput,
  id,
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <div className={styles.container}>
      <TextField styleType="labelTextarea" text={label} />
      <TextArea
        ref={ref}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        value={value}
        disable={disable}
        styleType={styleType}
        requiredInput={requiredInput}
        id={id}
      />
      <div className={styles.counter}>
        {`${value.length} / ${MAX_LENGTH_TEXT_AREA}`}
      </div>
    </div>
  );
};

TextAreaLabel.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.node,
  onChange: PropTypes.func,
  disable: PropTypes.string,
  styleType: PropTypes.string,
  requiredInput: PropTypes.bool,
  id: PropTypes.string,
};

TextAreaLabel.defaultProps = {
  label: '',
  placeholder: '',
  name: '',
  value: '',
  disable: '',
  styleType: '',
  requiredInput: false,
  id: '',
  onChange: () => {},
};

export default TextAreaLabel;
