import ACTION_AUTH from './types';

const INITIAL_STATE = {
  isAuth: false,
  user: {},
  loading: false,
  message: '',
  popUp: false,
  errorMessage: '',
  errorMessageTitle: '',
  loginError: '',
  notificationType: 'error',
  showNotionAnimation: false,
  disconnectPopup: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTION_AUTH.CHECK_AUTH:
      return {
        ...state,
        isAuth: payload.isAuth,
        user: payload.user,
        loading: false
      };
    case ACTION_AUTH.LOADING:
      return { ...state, loading: payload.loading };
    case ACTION_AUTH.SAVE_RESPONSE:
      return { ...state, isAuth: payload.isAuth, user: payload.user };
    case ACTION_AUTH.SAVE_CHECK_RESPONSE:
      return {
        ...state,
        isAuth: payload.isAuth,
        user: payload.user,
        loading: payload.loading
      };
    case ACTION_AUTH.CHANGE_POP_UP_STATUS:
      return { ...state, popUp: payload };
    case ACTION_AUTH.SHOW_NOTION_ANIMATION:
      return { ...state, showNotionAnimation: payload };
    case ACTION_AUTH.SAVE_MASSAGE:
      return { ...state, message: payload.message };
    case ACTION_AUTH.ERROR_MASSAGE:
      return { ...state, errorMessage: payload.errorMessage };
    case ACTION_AUTH.ERROR_MASSAGE_TITLE:
      return { ...state, errorMessageTitle: payload.messageTitle };
    case ACTION_AUTH.CHANGE_NOTIFICATION_TYPE:
      return { ...state, notificationType: payload.type };
    case ACTION_AUTH.LOGIN_ERROR:
      return { ...state, loginError: payload.message };
    case ACTION_AUTH.PHONE:
      return { ...state, phone: payload.phone };
    case ACTION_AUTH.SET_DISCONNECT_POPUP:
      return { ...state, disconnectPopup: payload };
    default:
      return state;
  }
};
