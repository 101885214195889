import React from 'react';
import styles from './sass/ShareTwitterModal.module.scss';
import { IScreenBlock } from './types/interface';
import TextField from '../TextField/TextField';

const ScreenBlock = ({ screen }: IScreenBlock): ReturnType<React.FC> => (
  <div className={styles.screenBlock}>
    {screen
      ? <img src={screen} alt="sreen" className={styles.screen} />
      : (
        <div className={styles.loaderModalWrapper}>
          <TextField text="Failed to capture your screen" />
          <TextField text="Please check if you gave your browser permission for screen capture" />
        </div>
      )}
  </div>
);

export default ScreenBlock;
