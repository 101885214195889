import React from 'react';
import InfoComponent from '../../Screener/components/InfoComponent';
import TextField from '../../TextField/TextField';
import { IChartAdditionalInfo } from '../types/MultichartInterfaces';
import styles from '../sass/MultiChart.module.scss';

// eslint-disable-next-line max-len
const areEqual = (prevProps: IChartAdditionalInfo, nextProps: IChartAdditionalInfo) => prevProps.industry === nextProps.industry
    && prevProps.sector === nextProps.sector
    && prevProps.earningsValue === nextProps.earningsValue
    && prevProps.earningTimeValue === nextProps.earningTimeValue
    && prevProps.dividendValue === nextProps.dividendValue;

const ChartAdditionalInfo = ({
  industry,
  sector,
  earningsValue,
  earningTimeValue,
  dividendValue
}: IChartAdditionalInfo): React.ReactElement => (
  !industry ? (
    <div className={styles.shimmer} />
  ) : (
    <>
      <div className={styles.chartHeaderEarningsInfo}>
        <InfoComponent
          earningsValue={earningsValue}
          earningTimeValue={earningTimeValue}
          dividendValue={dividendValue}
        />
      </div>
      <div className={styles.chartHeaderSectorInfo}>
        <TextField
          text={industry}
          styleType="chartIndustry"
        />
        <TextField
          text={sector}
          styleType="chartIndustry"
        />
      </div>
    </>
  )
);

export default React.memo(ChartAdditionalInfo, areEqual);
