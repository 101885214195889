import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';
import getInputArray from '../utilities/getInputArrayTs';

export default function upDownVolRatio(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Up/Down Volume Ratio',
    metainfo: {
      id: 'TL-dvudvr@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Up/Down Volume Ratio',
      description: 'DV - Up/Down Volume Ratio',
      format: {
        precision: 1,
        type: 'price'
      },
      is_hidden_study: false,
      is_price_study: false,
      precision: 1,
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Up/Down Volume Ratio'
        },
        plot_1: {
          isHidden: false,
          title: 'Background Color'
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: '1.0'
        },
        plot_3: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: '2.0'
        }
      },
      plots: [{
        id: 'plot_0',
        type: 'line' as StudyPlotType.Line
      },
      {
        id: 'plot_1',
        palette: 'palette_0',
        type: 'bg_colorer' as StudyPlotType.BgColorer
      },
      {
        id: 'plot_2',
        type: 'line' as StudyPlotType.Line
      },
      {
        id: 'plot_3',
        type: 'line' as StudyPlotType.Line,
      }],
      palettes: {
        palette_0: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'Color'
            }
          },
          valToIndex: {
            0: 0
          }
        }
      },
      inputs: [
        {
          defval: 1,
          id: 'in_0',
          min: 0,
          name: 'Background highlight',
          type: 'float' as StudyInputType.Float
        },
        {
          defval: 50,
          id: 'in_1',
          min: 0,
          name: 'Lookback Period',
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: "Deepvue Calculations",
          id: 'in_3',
          name: 'U/D',
          type: 'text' as StudyInputType.Text,
          options: ["Deepvue Calculations", "Standard Calculations"]
        }
      ],
      defaults: {
        inputs: {
          in_0: 1,
          in_1: 50,
          in_3: "Deepvue Calculations",
        },
        styles: {
          plot_0: {
            color: '#4CAF50',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0,
          },
          plot_1: {
            transparency: 90,
          },
          plot_2: {
            color: '#363A45',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_3: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          }
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#00ff00',
                style: 0,
                width: 1
              }
            }
          }
        },
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [in_0, in_1, in_3] = getInputArray({ inputs, length: 3 });
        let upVol = 0;
        let dnVol = 0;
        const closeSeries = this._context.new_var(mPineJS.Std.close(this._context));
        if (in_3 === "Deepvue Calculations") {
          let dcr = ((mPineJS.Std.close(this._context) - mPineJS.Std.low(this._context)) / (mPineJS.Std.high(this._context) - mPineJS.Std.low(this._context))) * 100;
          upVol = (closeSeries.get(0) > closeSeries.get(1)) && dcr > 50 ? mPineJS.Std.volume(this._context) : 0;
          dnVol = (closeSeries.get(0) < closeSeries.get(1)) && dcr < 50 ? mPineJS.Std.volume(this._context) : 0;
        } else {
          upVol = closeSeries.get(0) > closeSeries.get(1) ? mPineJS.Std.volume(this._context) : 0;
          dnVol = closeSeries.get(0) < closeSeries.get(1) ? mPineJS.Std.volume(this._context) : 0;
        }
        const sumUp = mPineJS.Std.sma(this._context.new_var(upVol), in_1, this._context);
        const sumDn = mPineJS.Std.sma(this._context.new_var(dnVol), in_1, this._context);
        const upDnVolRatio = sumUp / sumDn;
        const flat = 1;
        const highUD = 2;
        return [
          upDnVolRatio,
          upDnVolRatio >= in_0 ? 0 : NaN,
          flat,
          highUD
        ];
      };
    }
  }
}
