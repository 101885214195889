import { useTranslation } from 'react-i18next';
import React, { ReactElement } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import IProfileSidebarMore from './interfaces/IProfileSidebarMore';
import TextField from '../TextField/TextField';
import SwitchButton from '../SwitchButton/SwitchButton';
import colors from '../../constants/colors';
import { useTheme } from '../../utils/hooks/useTheme';
import ThemeVariants from '../../constants/theme';
import { ReactComponent as MoonIcon } from '../../assets/images/icons/moon.svg';
import { ReactComponent as SunIcon } from '../../assets/images/icons/sun.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/icons/accountSettings/profile.svg';
import { ReactComponent as SignOut } from '../../assets/images/icons/accountSettings/SignOut.svg';
import styles from './sass/SidebarScreener.module.scss';
import { setOpenConfirmModal } from '../../store/account/actions';
import ProfileSidebarDivider from './components/ProfileSidebarDivider';
import AccountSettingsButton from './components/AccountSettingsButton';
import RootStateTypes from '../../store/RootStateTypes';
import Icon from '../Icon/Icon';
import IconGenerator from './components/IconGenerator';
import { setModalType } from '../../store/watchlist/actions';

const ProfileSidebarMore = ({ route, handleCloseSidebar }: IProfileSidebarMore): ReactElement => {
  const { userAvatar, userProfile: { firstName } } = useSelector(
    (state: RootStateTypes) => state.accountState, shallowEqual
  );
  const { membershipType } = useSelector((state: RootStateTypes) => state.accountState?.activeSubscription);
  const { t } = useTranslation();
  const { theme } = useTheme();
  const dispatch = useDispatch();

  const changeHandler = () => {
    dispatch(setOpenConfirmModal(true));
  };
  const handleSignOut = () => {
    dispatch(setModalType(t('signOut')));
  };
  return (
    <div onMouseLeave={handleCloseSidebar} className={styles.sidebarMore}>
      <div className={styles.sidebarMoreContent}>
        <div className={styles.accountSettingsContainer}>
          <div className={styles.profileContainer}>
            <div className={styles.iconsWrapper}>
              {
                userAvatar ? (
                  <Icon
                    icon={userAvatar}
                    iconClass={styles.avatarIcon}
                  />
                ) : (
                  <ProfileIcon />
                )
              }
              <IconGenerator plan={membershipType} />
            </div>
            <TextField
              text={firstName}
              styleType="tabItemTitleFavorite"
              className={styles.nameDiv}
            />
          </div>
          <AccountSettingsButton route={route} theme={theme} />
        </div>
        <ProfileSidebarDivider />
        <div className={styles.userSettingsDiv}>
          <TextField
            text={t('appSettings')}
            styleType="tabItemTitleFavorite"
            className={styles.nameDiv}
          />
          <div className={styles.themeContainer}>
            <TextField text={t('theme')} styleType="profileSidebarMore" className={styles.nameDiv} />
            <SwitchButton
              onChange={changeHandler}
              onColor={colors.darkOnColor}
              checked={theme === ThemeVariants.DARK_MODE}
              checkedIcon={(
                <div className={styles.sidebarMoreMoon}>
                  <MoonIcon />
                </div>
              )}
              uncheckedIcon={(
                <div className={styles.sidebarMoreSun}>
                  <SunIcon />
                </div>
              )}
            />
          </div>

        </div>
        <ProfileSidebarDivider />
        <div aria-hidden className={styles.signOutContainer} onClick={handleSignOut}>
          <SignOut />
          <TextField
            text={t('signOut')}
            styleType="tabItemTitleFavorite"
            className={styles.signOutButton}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileSidebarMore;
