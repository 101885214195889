import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../Button/Button';
import styles from '../sass/GlobalSearch.module.scss';
import TextField from '../../TextField/TextField';
import { IMG_PATH } from '../../../constants/screener';

const ResultRow = React.forwardRef(({
  text,
  img,
  label,
  title,
  handleClick,
  active,
  hoveredItem,
  setHoveredItem,
}, ref) => {
  const newValue = title?.toLowerCase();

  return (
    <div
      ref={ref}
      className={`${styles.resultRow}
      ${active && !hoveredItem ? styles.active : ''}`}
      onMouseEnter={() => setHoveredItem(newValue)}
      onMouseLeave={() => setHoveredItem(null)}
    >
      <Button
        buttonClass={styles.link}
        handleClick={handleClick}
      >

        <img src={`${IMG_PATH}${img}`} alt="Icon" />
        <TextField styleType="labelResult" text={label} />
        -
        <div className={styles.resultWrapper}>
          <TextField
            styleType="titleResult"
            text={newValue.split(/\s+/).map((word) => word[0]?.toUpperCase() + word?.substring(1)).join(' ')}
          />

          {text && (
            <TextField styleType="labelResult" text={text} />
          )}
        </div>
      </Button>
    </div>
  );
});

ResultRow.propTypes = {
  text: PropTypes.string,
  img: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  hoveredItem: PropTypes.string,
  handleClick: PropTypes.func,
  setHoveredItem: PropTypes.func,
  active: PropTypes.bool.isRequired,
};

ResultRow.defaultProps = {
  text: '',
  img: '',
  label: '',
  title: '',
  hoveredItem: null,
  handleClick: () => {},
  setHoveredItem: () => {},
};

export default ResultRow;
