import React from 'react';
import styles from '../sass/WidgetCharts.module.scss';
import { SCREENER_TYPES } from '../types/WidgetChartsEnums';
import { IScreenerTypes } from '../types/WidgetChartsInterfaces';

const ScreenerTypes = ({
  screenerStates,
  setScreenerStates,
}: IScreenerTypes): React.ReactElement => (
  <div className={styles.screenerItemsLeft}>
    <div className={styles.itemsWrapper}>
      {SCREENER_TYPES
        .map(((item) => (
          <span
            key={item.key}
            role="button"
            tabIndex={0}
            onMouseDown={() => {
              setScreenerStates((prev) => ({ ...prev, folderMenuIsOpen: -1, selectedScreener: item.type }));
            }}
          >
            <span className={`${item.type === screenerStates.selectedScreener && styles.selected}`}>
              {item.title}
            </span>
          </span>
        )))}
    </div>
  </div>
);

export default ScreenerTypes;
