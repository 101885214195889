import { useEffect, useState } from 'react';

const useCreateTimer = (time) => {
  const [counter, setCounter] = useState(time);
  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [counter]);

  return {
    counter,
    setCounter,
  };
};

export default useCreateTimer;
