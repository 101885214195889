export default {
  stats: 'Stats',
  tableChart: 'Table Chart',
  lineChart: 'Line Chart',
  basic: 'Basic',
  growth: 'Growth',
  legend: 'Legend',
  data: 'Stats Table',
  dataPanel: 'Data Panel',
  QoQ: 'QoQ',
  estimate: 'Estimate',
  earnings: 'Earnings',
  actual: 'Reported',
  chgPercent: 'Surprise %',
  chgDollar: 'Surprise $',
  upgradeToSeeFullStats: 'Upgrade to see full stats',
  upgrafeToSeeMore: 'Upgrade to see more!',
  noDataChart: 'No data for Stats Table',
  forQuarters: '4 Quarters',
  forYears: '4 Years',
  eightQuarters: '8 Quarters',
  eightYears: '8 Years',
  switcherTitle: 'Annual / Quarterly Settings',
  quarterShort: 'Q',
  hideChart: 'Hide Stats Table',
  openChart: 'Show Stats Table',
  layoutRemoved: 'Layout successfully deleted'
};
