import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/PositionSize.module.scss';
import { IPositionSizeResult } from '../types/PostionSizeInterfaces';
import ResultItem from './ResultItem';

const PositionSizeResult = ({
  recshares,
  posvalue,
  amountatrisk,
  rmultiple,
  warning
}: IPositionSizeResult): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div className={styles.resultsWrapper}>
      <h1 className={styles.resultsWrapper_title}>{t('results')}</h1>
      {warning && (<p className={styles.resultsWrapper_subTitle}>{t('warning')}</p>)}
      {recshares && (
        <>
          <div className={styles.resultHeader}>
            <h2>{recshares}</h2>
            <p>Position Size (Shares)</p>
          </div>
          <ResultItem title={t('positionValue')} value={posvalue} formatter="$" />
          <ResultItem title={t('dollarRisk')} value={amountatrisk} formatter="$" />
          {rmultiple && (<ResultItem title={t('rMultiple')} value={rmultiple} formatter="" />)}
        </>
      )}
    </div>
  );
};

export default PositionSizeResult;
