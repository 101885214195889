import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/AlertsList.module.scss';

const ToastPrice = ({ price }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.price}>
      <span>{t('price')}</span>
      <span
        className={styles.priceValue}
      >
        {`$${price}`}
      </span>
    </div>
  );
};

ToastPrice.propTypes = {
  price: PropTypes.number.isRequired
};
export default ToastPrice;
