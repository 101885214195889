import React from 'react';
import PropTypes from 'prop-types';

import ChartsHeaderFilter from '../ChartHeaderFilter/ChartHeaderFilter';
import UpgradeAccountBanner from '../UpgradeAccountBanner/UpgradeAccountBanner';
import styles from './sass/StatsChart.module.scss';

import TableChartView from '../Charts/TableChart/TableChartView';
import { UI_SETTINGS } from '../../constants/statsChart';
import { FREE } from '../../constants/screener';
import { blurWidthSizeHandler } from '../../utils/helpers';

const StatsChartView = ({
  isGrowth,
  wrapperRef,
  checkedFilters,
  subcritionPlan,
  profile,
  buttonTitle,
  setCheckedFilters,
  height,
  width,
  numberOfQuarters,
  setStoredStatsSources,
  showStats,
}) => {
  const { tableBlurSize } = blurWidthSizeHandler(width, numberOfQuarters);

  return (
    <div ref={wrapperRef} className={`${styles.statsWrapper} ${showStats && styles.show}`}>
      <div className={styles.settings}>
        <ChartsHeaderFilter
          height={height}
          disabledAction={false}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          setStoredStatsSources={setStoredStatsSources}
        />
      </div>
      <div
        className={`${styles.content} ${styles.scroll}`}
        style={{ overflow: subcritionPlan === FREE && UI_SETTINGS.OVERFLOW_HIDDEN }}
        id="scroll"
      >
        <UpgradeAccountBanner
          buttonTitle={buttonTitle}
          tooltipClass={styles.comingSoon}
          wrapperClass={`${styles.blurContainer} ${styles.tableUpgrade}`}
          buttonClass={styles.upgradeButton}
          height={(checkedFilters.length * 55) + 82}
          width={tableBlurSize}
          profile={profile}
        />
        <TableChartView
          width={width}
          isGrowth={isGrowth}
          checkedFilters={checkedFilters}
        />
      </div>
    </div>
  );
};

StatsChartView.propTypes = {
  setStoredStatsSources: PropTypes.func.isRequired,
  numberOfQuarters: PropTypes.number.isRequired,
  isGrowth: PropTypes.string.isRequired,
  subcritionPlan: PropTypes.string,
  buttonTitle: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    subscriptionType: PropTypes.string
  }),
  wrapperRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  checkedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  setCheckedFilters: PropTypes.func.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  showStats: PropTypes.bool.isRequired,
};

StatsChartView.defaultProps = {
  profile: {
    subscriptionType: 'customer'
  },
  subcritionPlan: 'customer',
  height: null,
  width: null,
};
export default StatsChartView;
