type TSubscriptionMessage = {
  messageId: number | string;
  chartMarkets: string[],
  symbolIndex: number,
  lastTimestamp: number,
  requestTime?: number
};

interface ISocketChannelsProcessor {
  setChartSubscription: (messageId: number, message: TSubscriptionMessage) => void;
}

class SocketChannelsProcessor implements ISocketChannelsProcessor {
  data: {
    [key: number]: TSubscriptionMessage;
  };

  statsChartData: number[];

  constructor() {
    this.data = {};
    this.statsChartData = [];
  }

  setChartSubscription(messageId: number, message: TSubscriptionMessage) {
    this.data[messageId] = message;
  }

  setStatsSubscription(message: number) {
    this.statsChartData.push(message);
  }

  removeStatsSubscription(message: number) {
    this.statsChartData = this.statsChartData.filter((item) => item === message);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const SocketChannels = new SocketChannelsProcessor();
