import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { TDialogData } from '../types/types';
import { ActionDialogTypeTitle, DashboardItemsDialogActionTypes } from '../types/constants';

interface IActionContentBlock {
  dialogData: TDialogData,
  onConfirm: () => void,
  onDecline: () => void,
  disabledConfirm: boolean,
  theme: string,
}

const ActionContentBlock = ({
  onConfirm, onDecline, dialogData,
  disabledConfirm, theme
}: IActionContentBlock): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.actionBlock} ${theme}`}>
      <button className={styles.actionBlockDecline} type="button" onClick={onDecline}>{t('cancel')}</button>
      <button className={styles.actionBlockConfirm} type="button" disabled={disabledConfirm} onClick={onConfirm}>
        {t(ActionDialogTypeTitle[dialogData?.type as DashboardItemsDialogActionTypes])}
      </button>
    </div>
  );
};

export default ActionContentBlock;
