import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import TableContext from './TableContext';
import constants from '../../../constants/filters';
import useLayoutChange from '../../../utils/hooks/useLayoutChange';
import useScreenshot from '../../../components/TwitterShare/hooks/useScreenshot';
import useGlobalSearch from '../../../utils/hooks/useGlobalSearch';
import useAlertsTableWorker from '../DeeplistUtils/hooks/useAlertsTableWorker';
import useTableWorker from '../../../utils/hooks/useTableWorker';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { setGlobalHoldingSearch, setGlobalSectorIndustrySearch } from '../../../store/dashboards/slice';
import { HOLDING_TYPE } from '../../Dashboards/types/DashboardEnums';
import { NEW_SCREEN_DATA, TABLE_SCREEN_PATH } from '../../../constants/screener';
import { saveSelectedScreenId } from '../../../store/account/actions';
import {
  filtersWereUpdateScreenerAction,
  setMarketsFilterScreenerAction,
  setSelectedShapeId, setVisibleColumnsScreenAction
} from '../../../store/screener/actions';
import { getFromLocalStorage, removeLocalStorage, setToLocalStorage } from '../../../utils/storageWorks';
import { findIndexItem } from '../../../utils/helpers';
import { setSelectedScreen } from '../../../store/newScreen/actions';
import { setIsOpenModal } from '../../../store/watchlist/actions';

const TableProvider = ({ children }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentModalRef = useRef(null);
  const dataPanel = useRef();

  const [listItems, setListItems] = useState([]);
  const [viewBanner, setViewBanner] = useState(true);
  const [headerFilter, setHeaderFilter] = useState();
  const [isVisibleFilters, setIsVisibleFilters] = useState(false);
  const [currentScreensItem, setCurrentScreensItem] = useState(-1);
  const [currentPresetItem, setCurrentPresetItem] = useState(-1);
  const [currentScreensSubItem, setCurrentScreensSubItem] = useState(-1);
  const [hoveredPresetItem, setHoveredPresetItem] = useState(-1);
  const [moreScreenItemVisible, setMoreScreenItemVisible] = useState(false);
  const [moreScreenSubItemVisible, setMoreScreenSubItemVisible] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [singleItemStatus, setSingleItemStatus] = useState(false);
  const [titleScreenDrop, setTitleScreenDrop] = useState(constants.myScreen);

  const modalType = useSelector((state) => state.watchlistState.modalType, shallowEqual);
  const screenerView = useSelector((state) => state.accountState.userSettings.screenerView, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const openConfirmModal = useSelector((state) => state.accountState.openConfirmModal, shallowEqual);
  const selectedScreenId = useSelector((state) => state.accountState.userSettings.selectedScreenId, shallowEqual);
  const itemsNewScreen = useSelector((state) => state.newScreenState.itemsNewScreen, shallowEqual);
  const itemId = useSelector((state) => state.newScreenState.itemId, shallowEqual);
  const newScreenFlag = useSelector((state) => state.newScreenState.newScreenFlag, shallowEqual);
  const globalHoldingSearch = useSelector((state) => state.dashboardsState.globalHoldingSearch, shallowEqual);
  const globalSectorIndustrySearch = useSelector(
    (state) => state.dashboardsState.globalSectorIndustrySearch, shallowEqual
  );
  const sharedDashboardItem = useSelector((state) => state.screenerState.sharedDashboardItem, shallowEqual);

  const {
    getScreenerLayout,
    hideChart,
    handleChangeLayoutSize,
    handleOpenChart,
    getCurrentInnerWidth,
  } = useLayoutChange();

  const {
    twittText,
    setTwittText,
    cleanUpScreenshotData,
  } = useScreenshot();

  const {
    searchHeaderValue,
    searchDropdownVisible,
    setActiveModalWrapper,
    resetSearchHandler,
    handlerSearch,
    search,
    lazyResult,
    setPagingHandler,
    setActiveSymbolValue,
    setSearchDropdownVisible,
    globalSearchVisible,
    setGlobalSearchVisible,
    setSearch,
    globalSearchKeyHandler,
    activeSymbolIndexValue,
  } = useGlobalSearch(currentModalRef);

  const {
    alertFilters,
    setAlertItems,
    setAllAlertItems,
    selectedAlertItems,
    currentAlertData,
    activeAlert,
    handleSetActiveAlert,
    mixedAlertColumns,
    bulkRemoveAlerts,
    getSymbolFormatedByAlertIds,
  } = useAlertsTableWorker();

  const {
    copySelectedSymbols,
    selectedItems,
    disableExport,
  } = useTableWorker();

  const {
    getSharedDashboardItem,
    getDashboardsNew
  } = useNewScreen();

  const handleSetGlobalHoldingSearch = (value) => {
    dispatch(setGlobalHoldingSearch(value));
  };

  const handleSetGlobalSectorIndustrySearch = (value) => {
    dispatch(setGlobalSectorIndustrySearch(value));
  };

  const handleSetSearch = (value) => {
    if (value?.match(HOLDING_TYPE)) {
      handleSetGlobalHoldingSearch(true);
    }
    setSearch(value);
  };

  const searchTabResetHandler = (tab) => {
    resetSearchHandler(tab);
    if (globalHoldingSearch) {
      handleSetGlobalHoldingSearch(false);
    }
    if (globalSectorIndustrySearch) {
      handleSetGlobalSectorIndustrySearch(false);
    }
  };

  const handleSearchReset = () => {
    resetSearchHandler();
    if (globalHoldingSearch) {
      handleSetGlobalHoldingSearch(false);
    }
    if (globalSectorIndustrySearch) {
      handleSetGlobalSectorIndustrySearch(false);
    }
  };

  const moreVisibleItemHandler = useCallback(
    (subItem) => {
      if (subItem) {
        setMoreScreenItemVisible(false);
        setMoreScreenSubItemVisible(true);
      } else {
        setMoreScreenItemVisible(true);
        setMoreScreenSubItemVisible(false);
      }
    }, []
  );

  const openMoreModalHandler = useCallback(
    (text, singleItem) => {
      setSingleItemStatus(singleItem);
      setOpenModal(text);
    }, []
  );

  const currentItemHandler = useCallback(
    (id) => {
      setCurrentScreensItem(id);
      setMoreScreenItemVisible(false);
      setMoreScreenSubItemVisible(false);
      setCurrentScreensSubItem(-1);
    }, []
  );

  const searchSelectedScreen = useCallback(
    (array, id) => {
      let title = constants.myScreen;
      array.forEach((el) => {
        if (el.type === NEW_SCREEN_DATA.FOLDER) {
          el.items.forEach((item) => {
            if (item.id === id) {
              title = item.title;
            }
          });
        } else if (el.id === id) {
          title = el.title;
        }
      });
      return { title };
    }, []
  );

  const setSelectedScreenId = (id) => {
    dispatch(saveSelectedScreenId(id));
  };

  const clearSelectedShapeId = () => {
    dispatch(setSelectedShapeId(null));
  };

  const currentItemSelected = useCallback(
    (id) => {
      if (id) {
        const { title } = searchSelectedScreen(itemsNewScreen, id);
        setTitleScreenDrop(title);
        setSelectedScreenId(id);
        setToLocalStorage(constants.selectedScreen, title);
      } else {
        setTitleScreenDrop(constants.myScreen);
        setSelectedScreenId(0);
        removeLocalStorage(constants.selectedScreen);
      }
    }, [itemsNewScreen]
  );

  const subItemHoverHandler = useCallback(
    (index) => {
      setCurrentScreensSubItem(index);
      setMoreScreenSubItemVisible(false);
    }, []
  );

  const handlerViewBanner = useCallback(
    () => {
      setViewBanner(false);
      Cookies.set(constants.mobileBanner, false, { expires: 30 });
    },
    [viewBanner],
  );

  const activeLastScreen = useCallback(
    (array) => {
      const itemsFolder = listItems.filter((el) => el.type === constants.folder).flatMap((obj) => obj.items);
      const arrayFolder = array.filter((el) => el.type === constants.folder).flatMap((obj) => obj.items);

      arrayFolder.forEach((newItem) => {
        const newIndexItem = findIndexItem(itemsFolder, 'id', newItem.id);
        if (newIndexItem === -1 && newItem.type === constants.screener) {
          currentItemSelected(newItem.id);
        }
      });

      array.forEach((item) => {
        const indexItem = findIndexItem(listItems, 'id', item.id);
        if (indexItem === -1 && item.type === constants.screener) {
          currentItemSelected(item.id);
        }
      });
    },
    [listItems],
  );

  useEffect(() => {
    if (itemId?.id && pagePath === TABLE_SCREEN_PATH.SCREENER) {
      dispatch(setSelectedScreen(itemId));
      dispatch(setMarketsFilterScreenerAction({ data: itemId?.data?.filters }));
    }
  }, [itemId, newScreenFlag, pagePath]);

  useEffect(() => {
    if (!openModal) {
      setGlobalSearchVisible(false);
    }
  }, [openModal]);

  useEffect(() => {
    const flagModal = Cookies.get(constants.mobileBanner);
    if (flagModal) {
      handlerViewBanner();
    }
    resetSearchHandler();
  }, []);

  useEffect(() => {
    if (itemsNewScreen) {
      setListItems(itemsNewScreen);
    }
  }, [itemsNewScreen]);

  useEffect(() => {
    const titleScreen = getFromLocalStorage(constants.selectedScreen);
    const titleScreenId = getFromLocalStorage(constants.selectedScreenId);
    if (titleScreen) {
      setTitleScreenDrop(titleScreen);
    }
    if (titleScreenId) {
      setSelectedScreenId(titleScreenId);
    }
    getDashboardsNew();
    getDashboardsNew(true);
  }, []);

  useEffect(() => {
    if (!isVisibleFilters) {
      dispatch(filtersWereUpdateScreenerAction(false));
    }
  }, [isVisibleFilters]);

  useEffect(() => {
    dispatch(setIsOpenModal(!!openModal));
  }, [openModal]);

  useEffect(() => {
    document.title = pagePath === TABLE_SCREEN_PATH.SCREENER ? 'Deepvue-Screener' : 'Deepvue-Watchlist';
    dispatch(setVisibleColumnsScreenAction(false));

    if (globalHoldingSearch || globalSectorIndustrySearch) {
      handleSearchReset();
    }
  }, [pagePath]);

  useEffect(() => {
    if (getFromLocalStorage('sharedItemHash')?.length) {
      getSharedDashboardItem(getFromLocalStorage('sharedItemHash'));
    }
  }, [getFromLocalStorage('sharedItemHash')]);

  useEffect(() => () => {
    currentModalRef.current = null;
    dataPanel.current = null;
  }, []);

  const isSearch = activeSymbolIndexValue !== undefined;
  const setFocusHandler = useCallback(() => setGlobalSearchVisible(true), [globalSearchVisible]);
  const isAlertsTab = () => (selectedTab?.id && alertTab?.id && selectedTab?.id === alertTab?.id)
    && (pagePath !== TABLE_SCREEN_PATH.SCREENER);
  return (
    <TableContext.Provider value={{
      isWatchlist: pagePath !== TABLE_SCREEN_PATH.SCREENER,
      copySelectedSymbols,
      disableExport,
      screenerView,
      alertFilters,
      setFocus: setFocusHandler,
      resetSearchHandler: handleSearchReset,
      searchHeaderValue,
      isAlertsTab: isAlertsTab(),
      removeAlerts: bulkRemoveAlerts,
      getSymbolFormatedByAlertIds,
      searchTabResetHandler,
      activeSymbolIndexValue,
      selectedItems: isAlertsTab() ? selectedAlertItems : selectedItems,
      setHeaderFilter,
      moreItemHandler: moreVisibleItemHandler,
      openModalHandler: openMoreModalHandler,
      isVisibleFilters,
      currentPresetItem,
      currentScreensItem,
      setTitleScreenDrop,
      currentItemHandler,
      setIsVisibleFilters,
      currentItemSelected,
      setHoveredPresetItem,
      setCurrentPresetItem,
      currentScreensSubItem,
      moreScreenItemVisible,
      setMoreScreenItemVisible,
      moreScreenSubItemVisible,
      currentModalRef,
      subItemHoverHandler,
      getScreenerLayout,
      hideChart,
      handleChangeLayoutSize,
      handleOpenChart,
      getCurrentInnerWidth,
      globalSearchKeyHandler,
      dataPanel,
      openModal,
      globalSearchVisible,
      setPaging: setPagingHandler,
      setGlobalSearchVisible,
      handlerSearch,
      listResults: lazyResult,
      search,
      setSearch: handleSetSearch,
      setActiveSymbolValue,
      searchDropdownVisible,
      setSearchDropdownVisible,
      openConfirmModal,
      twittText,
      setTwittText,
      cleanUpScreenshotData,
      headerFilter: t(`${headerFilter}`),
      clearSelectedShapeId,
      selectedScreenId,
      viewBanner,
      titlePopUpMobile: t('mobileVersionInfo'),
      textBtnPopUpMobile: t('continue'),
      handlerViewBanner,
      modalType,
      activeLastScreen,
      singleItemStatus,
      hoveredPresetItem,
      setCurrentScreensSubItem,
      setActiveModalWrapper,
      isSearch,
      setAlertItems,
      setAllAlertItems,
      currentAlertData,
      activeAlert,
      setActiveAlert: handleSetActiveAlert,
      alertColumns: mixedAlertColumns,
      titleScreenDrop,
      sharedDashboardItem,
    }}
    >
      {children}
    </TableContext.Provider>
  );
};

TableProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default TableProvider;
