import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './sass/WatchListEmptyBlock.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import { ReactComponent as StarIcon } from '../../assets/images/icons/screener/star.svg';
import { ReactComponent as AlertsIcon } from '../../assets/images/icons/megaphoneSideBar.svg';
import { ReactComponent as IconPlus } from '../../assets/images/icons/screener/plus_white.svg';
import { ReactComponent as DataPanelIcon } from '../../assets/images/icons/screener/dataPanel.svg';
import TextField from '../TextField/TextField';
import WatchlistAddSymbolButton from '../WatchlistAddSymbolButton/WatchlistAddSymbolButton';
import useAlerts from '../ChartContainer/hooks/useAlerts';
import { setAlertActionFromApp } from '../ChartContainer/utils/alertHelper';
import { currentAlertData } from '../ChartContainer/constants/constants';
import { UNIVERSE_TYPE } from '../../constants/watchlist';

const WatchlistEmptyBlock = ({
  isWidget,
  selectedItemFromWidget,
  isDataPanel,
  addNewCategory
}) => {
  const { t } = useTranslation();
  const { alertAction } = useAlerts();
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);

  const findTab = (id) => tabsList.find((tab) => tab.id === id);

  const currentTab = isWidget ? findTab(selectedItemFromWidget) : selectedTab;

  const checkIsAlertTab = () => alertTab?.id === selectedTab?.id && !isWidget;
  
  const getTitle = () => {
    if (checkIsAlertTab() && !isDataPanel) return t('emptyAlertTitle');
    if (isDataPanel) return t('emptyDataPanelsTitle');
    return t('emptyTitle');
  };
  
  const checkIsUniverse = () => currentTab?.data?.subType === UNIVERSE_TYPE;
  
  const addNewAlert = () => {
    setAlertActionFromApp();
    alertAction(currentAlertData.data);
  };
  const getEmptyText = () => {
    const isUniverse = currentTab?.data?.subType === UNIVERSE_TYPE;
    if (!isUniverse && !isDataPanel) {
      return (
        <TextField
          text={t('emptyDescription')}
          styleType="emptyScreenDescription"
        />
      );
    }
    if (isDataPanel) {
      return (
        <TextField
          text={t('emptyAddNewCategory')}
          styleType="emptyScreenDescription"
        />
      );
    }
    return null;
  };
  
  const getIcon = () => {
    if (checkIsAlertTab() && !isDataPanel) return <AlertsIcon className={styles.iconAlerts} />;
    if (isDataPanel) return <DataPanelIcon className={styles.iconDataPanel} />;
    return <StarIcon className={styles.iconStar} />;
  };
  
  const getButton = () => {
    if (checkIsAlertTab() && !isDataPanel) {
      return (
        <DefaultButton handleClick={addNewAlert} className={styles.btnAddAlert}>
          <IconPlus />
          {t('alertAddButton')}
        </DefaultButton>
      );
    }
    if (isDataPanel) {
      return (
        <WatchlistAddSymbolButton
          text={'addNewCategoryButton'}
          handleClick={addNewCategory}
        />
      );
    }
    return (
      <WatchlistAddSymbolButton
        isWidget={isWidget}
        selectedItemFromWidget={selectedItemFromWidget}
        hideAddSymbol={!!currentTab?.parentId || checkIsUniverse()}
      />
    );
  };
  
  return (
    <div className={styles.emptyResult}>
      <div className={`${styles.emptyResultIconWrapper} ${isDataPanel && styles.dataPanelIconWrapper}`}>
        {getIcon()}
      </div>
      <TextField text={getTitle()} styleType="emptyScreenTitle" />
      {!currentTab?.parentId && getEmptyText()}
      {getButton()}
    </div>
  );
};

export default WatchlistEmptyBlock;

WatchlistEmptyBlock.propTypes = {
  isWidget: PropTypes.bool,
  selectedItemFromWidget: PropTypes.number,
  isDataPanel: PropTypes.bool,
  addNewCategory: PropTypes.func
};

WatchlistEmptyBlock.defaultProps = {
  isWidget: false,
  selectedItemFromWidget: 0,
  isDataPanel: false,
  addNewCategory: () => null,
};
