import React from 'react';

import { t } from 'i18next';
import styles from '../sass/FilterScreen.module.scss';
import FilterListOrContainer from './FilterListOrContainer';
import constants from '../../../constants/filters';
import SelectedFiltersContainer from './SelectedFiltersContainer';
import { TFilterGroupe } from '../Types';

interface IFilterRightColContent {
  removeGroup: (id: number) => void;
  textTooltip: string;
  activeGroup: number;
  hideButton: boolean;
  handlerGroup: (id: number) => void;
  counterSymbols: number | undefined;
  clearItemFilter: (id: number, activeGroupIndex: number, checkMap: number) => void;
  handlerSearchFilter: (id: number, type: string, columnId: number, list: TFilterGroupe[]) => void;
  selectedGroupFilters: TFilterGroupe[];
}

const FilterRightColContent = ({
  removeGroup,
  textTooltip,
  activeGroup,
  hideButton,
  handlerGroup,
  counterSymbols,
  clearItemFilter,
  handlerSearchFilter,
  selectedGroupFilters,
}: IFilterRightColContent): React.ReactElement => (
  <div className={styles.groupBox}>
    {selectedGroupFilters.length > 0 && selectedGroupFilters?.map((element, index) => {
      let itemRender;
      const active = activeGroup === index && selectedGroupFilters.length > 1;
      const activeOr = activeGroup === index;
      if (index === 0) {
        itemRender = (
          <SelectedFiltersContainer
            index={index}
            element={element}
            title={element?.name}
            hideButton={hideButton}
            handlerGroup={handlerGroup}
            clearItemFilter={clearItemFilter}
            key={[element.name, index].join('_')}
            handlerSearchFilter={handlerSearchFilter}
            active={active}
          />
        );
      } else {
        itemRender = (
          <FilterListOrContainer
            id={index}
            active={activeOr}
            hideButton={hideButton}
            activeGroupIndex={index}
            removeGroup={removeGroup}
            textTooltip={textTooltip}
            groupType={constants.or}
            handlerGroup={handlerGroup}
            clearItemFilter={clearItemFilter}
            key={[element, index].join('_')}
            title={selectedGroupFilters[index]?.name}
            handlerSearchFilter={handlerSearchFilter}
            selectedItems={selectedGroupFilters[index]}
            selectedGroupFilters={selectedGroupFilters}
          />
        );
      }
      return itemRender;
    })}
    <span className={styles.counterSymbols}>
      {counterSymbols}
      {' '}
      {t('reslts')}
    </span>
  </div>
);

export default FilterRightColContent;
