import React, { useMemo } from 'react';

import styles from '../sass/FilterScreen.module.scss';
import TextField from '../../TextField/TextField';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { ReactComponent as ClearIcon } from '../../../assets/images/icons/screener/close square.svg';
import Tooltip from '../../Tooltip/Tooltip';

interface IFilterSelectedTitle {
  title: string;
  clearBtn: string;
  clearHandler: () => void;
  textTooltip: string;
  freeUser?: boolean;
  hideButton?: boolean;
}

const FilterSelectedTitle = ({
  title, clearBtn, clearHandler, textTooltip, freeUser, hideButton
}: IFilterSelectedTitle): React.ReactElement => useMemo(() => (
  <div className={styles.titleContainer}>
    <TextField styleType="titleFilterCol" text={title} />
    {hideButton ? (
      <DefaultButton handleClick={clearHandler} buttonClass="clearAll">
        <ClearIcon />
        <TextField text={clearBtn} />
      </DefaultButton>
    ) : (
      <Tooltip text={textTooltip} classType={freeUser ? 'titleFilterFree' : 'titleFilter'} />
    )}
  </div>
), [textTooltip, title, hideButton]);

export default FilterSelectedTitle;
