import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/calendar.svg';
import DefaultButton from '../DefaultButton/DefaultButton';
import styles from './sass/CalendarPeriod.module.scss';
import CalendarSingle from '../Calendar/Calendar';
import PeriodButtons from '../SetPeriodButtons/SetPeriodButtons';
import { PERIOD_PICKERS_TYPE } from '../../constants/rangeDatePicker';
import { getShortDateRange } from '../../utils/helpers';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarPeriodView = ({
  placeholder,
  filterType,
  handleJustOpenDatePicker,
  openCalendar,
  isPlacementTop,
  actionsFromButton,
  setPeriod,
  startDateFromPicker,
  isOpenStartPeriodDate,
  startDateLabel,
  endDateLabel,
  setStartDateFromPicker,
  handleOpen,
  handleApply,
  handleCancel,
  cancelText,
  applyText,
  endDateFromPicker,
  isOpenEndPeriodDate,
  setEndDateFromPicker,
  handleApplySelected,
  startDatePlaceholder,
  endDatePlaceholder,
  cancelSelect,
  handleClose,
  setActionsFromButton
}) => (
  <div className={styles.wrapper} id={`calendar_${filterType}`}>
    <CalendarIcon className={styles.datePicker} />
    <div
      aria-hidden="true"
      className={styles.calendarBox}
      onClick={handleJustOpenDatePicker}
      onKeyDown={handleJustOpenDatePicker}
      role="button"
      tabIndex="0"
    >
      <div>
        {getShortDateRange(startDateFromPicker, endDateFromPicker) || placeholder}
      </div>
      {openCalendar && (
      <div className={`${styles.periodWrapper} ${isPlacementTop && styles.topPlacement}`}>
        <PeriodButtons
          selected={actionsFromButton}
          type={filterType}
          handleSetPeriod={setPeriod}
        />
        <span className={styles.periodLabel}>{startDateLabel}</span>
        <CalendarSingle
          className={styles.periodItem}
          startDate={startDateFromPicker}
          openCalendar={isOpenStartPeriodDate}
          setStartDate={(date) => setStartDateFromPicker(date)}
          handleJustOpenDatePicker={() => handleOpen(PERIOD_PICKERS_TYPE.START)}
          applySelectDate={() => handleApply(PERIOD_PICKERS_TYPE.START)}
          cancelSelect={() => handleCancel(PERIOD_PICKERS_TYPE.START)}
          placeholder={startDatePlaceholder}
          cancelText={cancelText}
          applyText={applyText}
          autoCloseCalendar={() => handleClose(PERIOD_PICKERS_TYPE.START)}
          setActionsFromButton={setActionsFromButton}
        />
        <span className={styles.periodLabel}>{endDateLabel}</span>
        <CalendarSingle
          className={styles.periodItem}
          startDate={endDateFromPicker}
          openCalendar={isOpenEndPeriodDate}
          setStartDate={(date) => setEndDateFromPicker(date)}
          handleJustOpenDatePicker={() => handleOpen(PERIOD_PICKERS_TYPE.END)}
          applySelectDate={() => handleApply(PERIOD_PICKERS_TYPE.END)}
          cancelSelect={() => handleCancel(PERIOD_PICKERS_TYPE.END)}
          placeholder={endDatePlaceholder}
          cancelText={cancelText}
          applyText={applyText}
          autoCloseCalendar={() => handleClose(PERIOD_PICKERS_TYPE.END)}
          setActionsFromButton={setActionsFromButton}
        />
        <div className={styles.buttonWrapper}>
          <DefaultButton handleClick={cancelSelect} buttonClass="btnCancelCalendar">
            {cancelText}
          </DefaultButton>
          <DefaultButton handleClick={(e) => handleApplySelected(e)} buttonClass="btnApplyCalendar">
            {applyText}
          </DefaultButton>
        </div>
      </div>
      )}
    </div>
  </div>
);

CalendarPeriodView.propTypes = {
  placeholder: PropTypes.string,
  filterType: PropTypes.string,
  handleJustOpenDatePicker: PropTypes.func,
  openCalendar: PropTypes.bool,
  isPlacementTop: PropTypes.bool,
  actionsFromButton: PropTypes.arrayOf(PropTypes.string),
  setPeriod: PropTypes.func,
  startDateFromPicker: PropTypes.instanceOf(Date),
  endDateFromPicker: PropTypes.instanceOf(Date),
  isOpenStartPeriodDate: PropTypes.bool,
  isOpenEndPeriodDate: PropTypes.bool,
  startDateLabel: PropTypes.string,
  endDateLabel: PropTypes.string,
  setStartDateFromPicker: PropTypes.func,
  handleOpen: PropTypes.func,
  handleApply: PropTypes.func,
  handleCancel: PropTypes.func,
  cancelText: PropTypes.string,
  applyText: PropTypes.string,
  setEndDateFromPicker: PropTypes.func,
  handleApplySelected: PropTypes.func,
  startDatePlaceholder: PropTypes.string,
  endDatePlaceholder: PropTypes.string,
  cancelSelect: PropTypes.func,
  handleClose: PropTypes.func,
  setActionsFromButton: PropTypes.func,
};

CalendarPeriodView.defaultProps = {
  placeholder: '',
  filterType: '',
  handleJustOpenDatePicker: () => {},
  openCalendar: false,
  isPlacementTop: false,
  actionsFromButton: [],
  setPeriod: () => {},
  startDateFromPicker: null,
  isOpenStartPeriodDate: false,
  startDateLabel: '',
  endDateLabel: '',
  setStartDateFromPicker: () => {},
  handleOpen: () => {},
  handleApply: () => {},
  handleCancel: () => {},
  cancelText: '',
  applyText: '',
  endDateFromPicker: null,
  isOpenEndPeriodDate: false,
  setEndDateFromPicker: () => {},
  handleApplySelected: () => {},
  startDatePlaceholder: '',
  endDatePlaceholder: '',
  cancelSelect: () => undefined,
  handleClose: () => undefined,
  setActionsFromButton: () => undefined
};

export default CalendarPeriodView;
