import React, { useState, useRef, useEffect } from 'react';

import { useResizeDetector } from 'react-resize-detector';
import ChartWidgetHeader from './ChartWidgetHeader';
import StatsWidget from './StatsWidget';
import styles from '../sass/DashboardComponents.module.scss';
import { IWidgetRendered } from '../types/DashboardComponentsInterfaces';
import { PERIOD_SOURCES } from '../../../constants/statsChart';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { deepEqual } from '../../../utils/objectHelper';

const StatsChartWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe,
  openModalHandler, openItemsModalHandler,
  isEditable, activeSymbolIndexValue
}: IWidgetRendered): React.ReactElement => {
  const { statsChartData } = widget;
  const filter = useRef<HTMLButtonElement>(null);
  const { ref, width, height } = useResizeDetector();
  const [currentSource, setCurrentSource] = useState<string>(statsChartData?.currentSource as string);
  const [numberOfQuarters, setNumberOfQuarters] = useState<number>(statsChartData?.numberOfQuarters as number);
  const [estimateVisibleData, setEstimateVisibleData] = useState<boolean>(
    statsChartData?.estimateVisibleData as boolean
  );
  const [checkedFilters, setCheckedFilters] = useState<string[]>(statsChartData?.checkedFilters as string[]);
  const [openFilters, setOpenFilters] = useState<boolean>(false);
  const [showData, setShowData] = useState<boolean>(false);

  const handleAddFilters = (filterName: string) => {
    const oldFilters = [...checkedFilters];
    if (checkedFilters.includes(filterName)) {
      const index = oldFilters.indexOf(filterName);
      oldFilters.splice(index, 1);
    } else {
      oldFilters.push(filterName);
    }
    setCheckedFilters(oldFilters);
  };

  const estimateVisibleHandler = (e: Event) => {
    const value = (e.target as HTMLInputElement).checked;
    setEstimateVisibleData(value);
  };

  const handleSetSource = (source: string) => {
    const sourceName = source === PERIOD_SOURCES.Y ? PERIOD_SOURCES.SHORT_Y : PERIOD_SOURCES.SHORT_Q;
    setCurrentSource(sourceName);
  };

  const handleSetQuarters = (value: { data: number }) => {
    setNumberOfQuarters(value.data);
  };

  useEffect(() => {
    if (!deepEqual(statsChartData, {
      currentSource,
      numberOfQuarters,
      estimateVisibleData,
      checkedFilters
    })){
      updateWidget({
        ...widget,
        statsChartData: {
          currentSource,
          numberOfQuarters,
          estimateVisibleData,
          checkedFilters
        }
      });
    }
  }, [currentSource, numberOfQuarters, estimateVisibleData, checkedFilters]);

  return (
    <>
      <div className={`${styles.chartDnDBlock}
        ${showData && styles.noTransparent}
        ${styles.statsChart} handle-grid-drag`}
      >
        {isEditable && <DndControl />}
      </div>
      <ChartWidgetHeader
        openItemsModalHandler={openItemsModalHandler}
        openModalHandler={openModalHandler}
        setColorGroupe={setColorGroupe}
        updateWidget={updateWidget}
        removeWidget={removeWidget}
        widget={widget}
        height={height || 0}
        setOpenFilters={setOpenFilters}
        openFilters={openFilters}
        filter={filter}
        checkedFilters={checkedFilters}
        handleAddFilters={handleAddFilters}
        estimateVisibleData={estimateVisibleData}
        estimateVisibleHandler={estimateVisibleHandler}
        numberOfQuarters={numberOfQuarters}
        currentSource={currentSource}
        setNumberOfQuarters={handleSetQuarters}
        setSource={handleSetSource}
        showData={showData}
        statsChart
        width={width || 0}
        isEditable={isEditable}
      />
      <StatsWidget
        tableRef={ref}
        width={width || 0}
        height={height || 0}
        currentSource={currentSource}
        activeSymbolIndexValue={activeSymbolIndexValue}
        widget={widget}
        numberOfQuarters={numberOfQuarters}
        estimateVisibleData={estimateVisibleData}
        checkedFilters={checkedFilters}
        setShowData={setShowData}
      />
    </>
  );
};

export default StatsChartWidget;
