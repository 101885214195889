import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useDispatch } from 'react-redux';
import styles from './sass/WatchListModals.module.scss';
import MultiSelectSearch from './MultiSelectSearch';
import AddSymbolModalHeader from './AddSymbolModalHeader';
import AddSymbolModalButtons from './AddSymbolModalButtons';
import { setModalType } from '../../store/watchlist/actions';

const AddSymbolsModal = () => {
  const dispatch = useDispatch();
  return (
    <ClickAwayListener
      onClickAway={() => dispatch(setModalType(''))}
    >
      <div className={styles.addSymbolModalWrapper}>
        <AddSymbolModalHeader />
        <MultiSelectSearch />
        <AddSymbolModalButtons />
      </div>
    </ClickAwayListener>
  );
};

export default AddSymbolsModal;
