import React from 'react';
import PropTypes from 'prop-types';

import useAlerts from '../../ChartContainer/hooks/useAlerts';
import Button from '../../Button/Button';
import styles from '../sass/ScreenerTable.module.scss';
import { ReactComponent as EditIcon } from '../../../assets/images/icons/editAlert.svg';

const AlertAction = ({ alertStatus }) => {
  const { alertActionEdit } = useAlerts();
  const handleAlertEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    alertActionEdit(alertStatus?.id);
  };

  return (
    <Button buttonClass={styles.alertActionButton} handleClick={handleAlertEdit}>
      <EditIcon />
    </Button>
  );
};

AlertAction.propTypes = {
  alertStatus: PropTypes.shape({
    id: PropTypes.number,
    isActive: PropTypes.bool
  }).isRequired
};

export default AlertAction;
