import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from '../sass/DataPanel.module.scss';
import useOutsideAlerter from '../../../utils/hooks/useOutside';
import { ReactComponent as IconDot } from '../../../assets/images/icons/screener/more-circle-light.svg';
import { ReactComponent as CheckedIcon } from '../../../assets/images/icons/Check.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/screener/edit_square.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/screener/trash.svg';

const DotMenu = ({
  onRename, onDelete, isSelected,
}) => {
  const { t } = useTranslation();
  const [isDropDownVisible, setDropdownVisible, ref] = useOutsideAlerter(false);

  const handleToggleMenu = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDropdownVisible(!isDropDownVisible);
  };
  return (
    <div aria-hidden onClick={handleToggleMenu} className={styles.dataPanelDotMenu}>
      {isSelected && <CheckedIcon className={styles.checkedIcon} />}
      <IconDot className={styles.dataPanelDotMenuIcon} />
      {isDropDownVisible && (
        <div ref={ref} className={styles.dataPanelDotMenuList}>
          <div aria-hidden className={styles.dataPanelDotMenuItem} onClick={onRename}>
            <IconEdit /> 
            <span>{t('rename')}</span>
          </div>
          <div aria-hidden className={styles.dataPanelDotMenuItem} onClick={onDelete}>
            <IconRemove />
            <span>{t('delete')}</span>
          </div>
        </div>
      )}
    </div>
  );
};
DotMenu.propTypes = {
  onRename: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default DotMenu;
