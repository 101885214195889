export const TABLE_LISTENER_ID = 'tableTarget';

const SHORT_CUTS_LIST = {
  TABLE_FOCUS: 'shift+x',
  SHOW_ALERT_MODAL: 'alt+a',
  X: 'X',
  x: 'x',
  LISTENER_EVENT: 'keydown'
};

export const ALERT_TV_SHORT_CUTS = {
  MAC: '⌥A',
  WINDOWS: 'Alt+A'
};

export default SHORT_CUTS_LIST;
