import { useDispatch } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { setOpenConfirmModal, setOverwriteFlag } from '../../store/account/actions';
import { useTheme } from '../../utils/hooks/useTheme';
import ThemeVariants from '../../constants/theme';
import GeneralConfirmModal from './GeneralConfirmModal';

const ConfirmModal = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { toggleTheme, theme } = useTheme();

  const confirmHandler = (option: boolean) => {
    dispatch(setOverwriteFlag(option));
    toggleTheme();
    dispatch(setOpenConfirmModal(false));
  };

  return (
    <GeneralConfirmModal
      title={t('confirmModalTitle')}
      body={`You’re changing the user interface to ${theme === ThemeVariants.DARK_MODE
        ? 'Light' : 'Dark'} theme `}
      description={t('confirmModalDescription')}
      confirmCaption={t('buttonYes')}
      declineCaption={t('buttonNo')}
      confirmHandler={() => confirmHandler(true)}
      declineHandler={() => confirmHandler(false)}
    />
  );
};

export default ConfirmModal;
