import React from 'react';
import PropTypes from 'prop-types';

import styles from '../sass/TableScreener.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close_dlack.svg';
import { ReactComponent as MegaIcon } from '../../../assets/images/icons/megaphoneModal.svg';

const ModalAlertHeader = ({ onClose, headLabel }) => (
  <div className={styles.alertHeader}>
    <span className={styles.alertHeaderTitle}>
      <MegaIcon className={styles.megaIcon} />
      {headLabel}
    </span>
    <CloseIcon className={styles.alertHeaderClose} onClick={onClose} />
  </div>
);

ModalAlertHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  headLabel: PropTypes.string.isRequired,
};

export default ModalAlertHeader;
