import { useEffect, useRef, useState } from 'react';

export default function useOutsideAlerter(initialVisiblity) {
  const [isShow, setIsShow] = useState(initialVisiblity);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
      ref.current = null;
    };
  }, []);

  return [isShow, setIsShow, ref];
}
