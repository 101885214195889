import React from 'react';
import { NumericFormat } from 'react-number-format';

import styles from '../sass/PositionSize.module.scss';
import { PositionSizeInputBoxProps } from '../types/PositionSizeTypes';
import { DECIMAL_SCALE, MAX_LIMIT, THOUSAND_SEPARATOR } from '../types/PositionSizeEnums';

const PositionSizeInputBox = ({
  name,
  inputClass = '',
  value,
  changeHandler,
  validator,
  target = '',
  source = '',
  operator = 1,
  maxPercentLimit = false,
}: PositionSizeInputBoxProps): React.ReactElement => (
  <NumericFormat
    className={styles[inputClass]}
    name={name}
    value={value}
    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
      if (validator) {
        const val = e.target as HTMLInputElement;
        validator(val.value, target, source, operator);
      }
    }}
    onValueChange={(values, { event }) => {
      const inputName = (event?.target as HTMLInputElement)?.name;
      if (inputName) {
        changeHandler((event?.target as HTMLInputElement)?.name, values.value);
      }
    }}
    autoComplete="off"
    thousandSeparator={THOUSAND_SEPARATOR}
    decimalScale={DECIMAL_SCALE}
    isAllowed={(values) => {
      if (maxPercentLimit) {
        const { formattedValue, floatValue } = values;
        return formattedValue === '' || Number(floatValue) <= MAX_LIMIT;
      }
      return true;
    }}
  />
);

export default PositionSizeInputBox;
