import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IContext, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function webbyTight(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Webby Tight',
    metainfo: {
      id: 'TL-webbyTight@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Webby Tight',
      description: 'DV - Webby Tight',
      is_price_study: false,
      isCustomIndicator: true,
      precision: 10,
      format: {
        type: 'inherit',
      },
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Positive'
            },
            1: {
              name: 'Negative'
            }
          },
          valToIndex: {
            0: 0,
            1: 1
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Positive'
            },
            1: {
              name: 'Negative'
            }
          },
          valToIndex: {
            2: 0,
            3: 1
          }
        },
        palette_common: {
          colors: {
            0: {
              name: 'Positive'
            },
            1: {
              name: 'Color 1'
            },
            2: {
              name: 'Color 2'
            },
            3: {
              name: 'Color 3'
            },
            4: {
              name: 'Color 4'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4
          }
        }
      },
      inputs: [
        {
          id: 'showEma',
          name: 'Show EMA',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
        {
          id: 'showHiLo',
          name: 'Show Highest/Lowest Lines',
          type: 'bool' as StudyInputType.Bool,
          defval: false,
        },
      ],
      plots: [{
        id: 'plot_0',
        type: 'line' as StudyPlotType.Line
      },
      {
        id: 'plot_1',
        palette: 'palette_0',
        target: 'plot_0',
        type: 'colorer' as StudyPlotType.Colorer
      }, {
        id: 'plot_2',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_3',
        palette: 'palette_1',
        target: 'plot_2',
        type: 'colorer' as StudyPlotType.Colorer
      },
      {
        id: 'plot_4',
        type: 'line' as StudyPlotType.Line,
      },
      {
        id: 'plot_5',
        type: 'line' as StudyPlotType.Line,
      },
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Webby Tight Ratio'
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Plot EMA of Ratio'
        },
        plot_4: {
          title: 'High Line',
        },
        plot_5: {
          title: 'Low Line',
        }
      },
      defaults: {
        inputs: {
          showEma: true,
          showHiLo: false,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#3A75E8',
                style: 0,
                width: 1
              },
              1: {
                color: '#E83AAD',
                style: 0,
                width: 1
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#4CAF50',
                style: 0,
                width: 1
              },
              1: {
                color: '#E83AAD',
                style: 0,
                width: 1
              }
            }
          },
          palette_common: {
            colors: {
              0: {
                color: '#3A75E8'
              },
              1: {
                color: '#E83AAD'
              },
              2: {
                color: '#4CAF50'
              },
              3: {
                color: '#E83AAD'
              },
              4: {
                color: '#363A45'
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: '#3A75E8',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 1,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            color: '#3A75E8',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_4: {
            color: '#4CAF50',
            plottype: 0,
            linestyle: 0,
            linewidth: 1
          },
          plot_5: {
            color: '#E83AAD',
            plottype: 0,
            linestyle: 0,
            linewidth: 1
          },
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;
        const [showEma, showHiLo] = getInputArray({
          inputs,
          length: 2
        });

        const short1 = mPineJS.Std.atr(3, this._context);
        const short2 = mPineJS.Std.atr(5, this._context);
        const short3 = mPineJS.Std.atr(8, this._context);
        const shortAvg = (short1 + short2 + short3) / 3;

        const long1 = mPineJS.Std.atr(55, this._context);
        const long2 = mPineJS.Std.atr(89, this._context);
        const long3 = mPineJS.Std.atr(144, this._context);
        const longAvg = (long1 + long2 + long3) / 3;

        const ratio = (longAvg - shortAvg) / Math.max(longAvg, shortAvg);
        const ratioSeries = this._context.new_var(ratio);
        const ema = mPineJS.Std.ema(ratioSeries, 5, this._context);
        const highest = mPineJS.Std.highest(ratioSeries, 251, this._context);
        const lowest = mPineJS.Std.lowest(ratioSeries, 251, this._context);

        return [
          ratio,
          ratio > 0 ? 0 : 1,
          showEma ? ema : NaN,
          lineColorChecker(this._context, ema),
          showHiLo ? highest : NaN,
          showHiLo ? lowest : NaN,
        ];
      };
    }
  };
}
const lineColorChecker = (mcontext: IContext, val: number): number => {
  return mcontext.new_var(val).get(0) > mcontext.new_var(val).get(1) ? 0 : 1;
}
