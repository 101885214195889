import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { FolderItemData, TDashboardItemsData } from '../../../utils/Types';
import Input from '../../Input/Input';
import { MAX_LENGTH_COUNT } from '../../../constants/screener';

interface IDialogContentFolder {
  onChange: (item: TDashboardItemsData) => void,
  item: TDashboardItemsData,
  theme: string
}
const DialogContentFolder = ({
  onChange, item, theme,
}: IDialogContentFolder): React.ReactElement => {
  const { t } = useTranslation();
  const currentItem = item as FolderItemData;

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({
      ...currentItem,
      title: e.target.value
    });
  };

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={styles.dialogContentFieldItem}>
        <label className={styles.dialogContentFieldItemLabel} htmlFor="folderName">{t('folderName')}</label>
        <Input
          type="text"
          inputClass={styles.dialogContentFieldItemInput}
          handlerChange={handleChanges}
          autoFocus
          placeholder={t('typeFolderName')}
          value={currentItem?.title || ''}
          maxLength={MAX_LENGTH_COUNT}
        />
      </div>
    </div>
  );
};

export default DialogContentFolder;
