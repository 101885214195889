import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styles from './sass/SidebarScreener.module.scss';
import { ReactComponent as ScreenerIcon } from '../../assets/images/icons/screener/screener.svg';
import { ReactComponent as WatchlistIcon } from '../../assets/images/icons/screener/watchlist.svg';
import { ReactComponent as SupportIcon } from '../../assets/images/icons/screener/support.svg';
import { ReactComponent as AlertsIcon } from '../../assets/images/icons/megaphoneSideBar.svg';
import { ReactComponent as HideMenuIcon } from '../../assets/images/icons/screener/hideMenu.svg';
import { ReactComponent as DashboardIcon } from '../../assets/images/icons/Dashboards.svg';
import { ReactComponent as EducationIcon } from '../../assets/images/icons/education.svg';
import SidebarItem from './SidebarItem';
import ROUTES_URLS from '../../constants/routesUrls';
import { ReactComponent as ProfileIcon } from '../../assets/images/icons/accountSettings/profile.svg';
import Logo from './Logo';
import ProfileSidebarItem from './ProfileSidebarItem';
import SidebarItemAlerts from './SidebarItemAlerts';
import AlertsList from '../AlertsList/AlertsList';
import { free } from '../../constants/statsChart';
import { setSearchValue } from '../../store/screener/actions';
import { defaultPage, TABLE_SCREEN_PATH } from '../../constants/screener';
import SideBarSecondaryItem from './SideBarSecondaryItem';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { MAIN_SIDE_BAR_TOGGLE } from '../../constants/storage';
import { setVisibleItemsTableStore } from '../../store/tableData/slice';
import ButtonSupport from '../ButtonSupport/ButtonSupport';

const SidebarScreener = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { updateStoreHandler } = useLastStateUpdateStore();

  const [currentPage, setCurrentPage] = useState(history.location.pathname);
  const isHideMenu = useSelector((state) => state.accountState.userSettings.isHideSideMenu, shallowEqual);
  const alertsNotificationCounter = useSelector((state) => state.alertsState.alertsNotificationCounter, shallowEqual);
  const alertsListVisible = useSelector((state) => state.alertsState.alertListDropdownVisible, shallowEqual);
  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);
  const sidebarItemClassName = isHideMenu ? styles.sidebarItemVisible : styles.sidebarItemHidden;
  const sidebarWrapperClassName = isHideMenu ? styles.sidebar : styles.sidebarHidden;

  const [showMarket, setShowMarket] = useState(false);
  const [showProfile, setShowProfile] = useState(false);

  const handlerToggleLeftMenu = useCallback(() => {
    updateStoreHandler(MAIN_SIDE_BAR_TOGGLE, !isHideMenu);
  }, [isHideMenu]);

  useEffect(() => {
    setCurrentPage(history.location.pathname);
    dispatch(setSearchValue({}));
    dispatch(setVisibleItemsTableStore(defaultPage));
  }, [history.location.pathname]);

  return (
    <>
      <div className={`${sidebarWrapperClassName} ${styles.sidebarContainer}`}>
        <div className={styles.wrapperMainButton}>
          <Logo
            route={ROUTES_URLS.TABLE}
          />
          <button
            type="button"
            aria-hidden="true"
            onClick={handlerToggleLeftMenu}
            className={styles.sidebarMenuButton}
          >
            <SidebarItem
              Icon={HideMenuIcon}
              text={t('hideMenu')}
              className={`${sidebarItemClassName} ${styles.sidebarItemActive} ${styles.sidebarItemIcon}`}
              isVisible={isHideMenu}
              tooltipText={t('openMenu')}
            />
          </button>
          <hr className={isHideMenu ? styles.sidebarHrVisible : styles.sidebarHrHidden} />
          <SidebarItem
            Icon={DashboardIcon}
            text={t('dashboards')}
            currentPage={currentPage}
            className={`${sidebarItemClassName} ${styles.sidebarItemActive}
              ${currentPage === ROUTES_URLS.DASHBOARDS ? styles.sideBarSelectedItem : ''} ${styles.sidebarItemIcon}`}
            route={ROUTES_URLS.DASHBOARDS}
            isVisible={isHideMenu}
            tooltipText={t('dashboards')}
            alertsListVisible={alertsListVisible}
            largeTooltip
          />
          <SideBarSecondaryItem
            Icon={ScreenerIcon}
            text={TABLE_SCREEN_PATH.SCREENER}
            currentPage={currentPage}
            className={`${sidebarItemClassName} ${styles.sidebarItemActive} ${styles.sidebarItemIcon}`}
            isVisible={isHideMenu}
            tooltipText={t('screener')}
            alertsListVisible={alertsListVisible}
            largeTooltip
          />
          <SideBarSecondaryItem
            Icon={WatchlistIcon}
            text={TABLE_SCREEN_PATH.DEEPLIST}
            currentPage={currentPage}
            className={`${sidebarItemClassName} ${styles.sidebarItemActive} ${styles.sidebarItemIcon}`}
            isVisible={isHideMenu}
            tooltipText={t('watchlist')}
            alertsListVisible={alertsListVisible}
            largeTooltip
          />
          <SidebarItemAlerts
            Icon={AlertsIcon}
            text={t('alerts')}
            currentPage={currentPage}
            tooltipText={t('alerts')}
            className={`${sidebarItemClassName} ${styles.sidebarItemAlerts}
              ${alertsListVisible ? styles.sideBarSelectedItem : ''} ${styles.sidebarItemIcon}`}
            isVisible={isHideMenu}
            counter={alertsNotificationCounter}
            isFree={profile?.role === free}
            setShowMarket={setShowMarket}
            setShowProfile={setShowProfile}
            alertsListVisible={alertsListVisible}
          />
        </div>
        <div className={styles.wrapperMainButton}>
          <SidebarItem
            Icon={EducationIcon}
            text={t('education')}
            currentPage={currentPage}
            className={`${sidebarItemClassName} ${styles.sidebarItemActive}
              ${currentPage === ROUTES_URLS.EDUCATION ? styles.sideBarSelectedItem : ''} ${styles.sidebarItemIcon}`}
            route={ROUTES_URLS.EDUCATION}
            isVisible={isHideMenu}
            tooltipText={t('educationTooltip')}
            alertsListVisible={alertsListVisible}
            largeTooltip
          />
          <ProfileSidebarItem
            IconDefault={ProfileIcon}
            text={t('account')}
            tooltipText={t('account')}
            className={`${sidebarItemClassName} ${styles.sidebarItemActive}
            ${currentPage.includes(ROUTES_URLS.ACCOUNT_SETTINGS) ? styles.sideBarSelectedItem : ''}`}
            route={process.env.REACT_APP_WP_URL}
            isVisible={isHideMenu}
            currentPage={currentPage}
            showProfile={showProfile}
            setShowProfile={setShowProfile}
            showMarket={showMarket}
            setShowMarket={setShowMarket}
            alertsListVisible={alertsListVisible}
          />
          <ButtonSupport
            isVisible={isHideMenu}
            className={`
                ${sidebarItemClassName} ${styles.sidebarItemActive} ${styles.sidebarItemSupport}
                ${styles.sidebarItemSupport}
              `}
            IconElement={SupportIcon}
            text={t('support')}
            setShowMarket={setShowMarket}
            setShowProfile={setShowProfile}
          />
        </div>
      </div>
      {alertsListVisible && <AlertsList isMenuOpened={isHideMenu} />}
    </>
  );
};

export default SidebarScreener;
