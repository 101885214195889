import moment from 'moment-timezone';
import { WS_CHANNELS } from '../constants/socketConnection';
import { repeatItems } from '../utils/hooks/useRepeat';
import { ONE_THOUSAND } from '../constants/screener';
import { DEFAULT_TIMEZONE } from '../constants/account';
import { SocketConnectionService } from './Interfaces';
import { GET_BARS_SETTINGS } from '../constants/tvWidgetOptions';
import { SocketChannels } from './ChartPrefetchServices/SocketChannelsProcessor';
import { MarketConstants } from '../components/MarketDropdown/types/MarketEnums';

const dayStart = moment().tz(DEFAULT_TIMEZONE).startOf('day').valueOf();

// eslint-disable-next-line import/prefer-default-export
export const emitter = (
  ChartConnection: SocketConnectionService,
  symbolIndex: number,
  chanelId: number | string,
  isExtended: string
): void => {
  if (!ChartConnection) return;
  const initChartMessage = {
    chartMarkets: isExtended === MarketConstants.SUBSESSION_EXTENDED_ID
      ? GET_BARS_SETTINGS.MARKET_TYPES_EXTENDED : GET_BARS_SETTINGS.MARKET_TYPES,
    messageId: chanelId,
    symbolIndex,
    lastTimestamp: dayStart / ONE_THOUSAND,
  };

  SocketChannels.setChartSubscription(chanelId as number, { ...initChartMessage, requestTime: moment().valueOf() });
  const requestEmitter = () => ChartConnection?.sendMessage(WS_CHANNELS.CHART_SUBSCRIBE, initChartMessage);

  requestEmitter();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  repeatItems[WS_CHANNELS.CHART_SUBSCRIBE] = requestEmitter;
};
