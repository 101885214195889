import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../sass/AlertsList.module.scss';
import ToastHeader from './ToastHeader';
import ToastRelVol from './ToastRelVol';
import ToastPriceSurge from './ToastPriceSurge';
import ToastNote from './ToastNote';
import ToastPrice from './ToastPrice';
import { IMG_PATH } from '../../../constants/screener';
import { DEFAULT_ICON, NOTIFICATIONS_TITLE } from '../../../constants/alerts';

const Toast = ({
  item, setItemsRef, index, clickHandler, toggleAlertStatus, checkActive,
}) => {
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const currentIconHandler = useMemo(() => (symbolName) => {
    const currentItem = symbolsList.find((subItem) => subItem.sym === symbolName);
    return currentItem?.icon ? `${IMG_PATH}${currentItem?.icon}` : `${IMG_PATH}${DEFAULT_ICON}`;
  }, [item]);

  const handleToggleAlertStatus = (val, e) => {
    e.stopPropagation();
    toggleAlertStatus(item.alertId, val);
  };

  return (
    <li
      aria-hidden
      className={`
        ${styles.toast}
        ${!item.isViewed ? styles.new : ''}`}
      ref={(el) => setItemsRef(el, index)}
      id={`${item.id}:${item.isViewed}`}
      onClick={() => clickHandler(item.alertId, item.symbol)}
    >
      <ToastHeader
        isActive={checkActive(item.alertId)}
        icon={currentIconHandler(item.symbol)}
        sym={item.symbol}
        percent={item.pricePercent}
        title={NOTIFICATIONS_TITLE[item.direction]}
        time={item.createdAt}
        handleToggleAlertStatus={handleToggleAlertStatus}
      />
      <ToastPrice price={item.price} />
      <ToastRelVol relVol={item.relativeVolume} />
      <ToastPriceSurge priceSurge={item.priceSurge} />
      {item?.note && (<ToastNote note={item.note} />)}
    </li>
  );
};

Toast.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  setItemsRef: PropTypes.func.isRequired,
  item: PropTypes.shape({
    alertId: PropTypes.number,
    id: PropTypes.number,
    isViewed: PropTypes.bool,
    symbol: PropTypes.string,
    icon: PropTypes.string,
    pricePercent: PropTypes.string,
    direction: PropTypes.string,
    createdAt: PropTypes.string,
    relativeVolume: PropTypes.number,
    note: PropTypes.string,
    priceSurge: PropTypes.string,
    price: PropTypes.number,
  }).isRequired,
  toggleAlertStatus: PropTypes.func.isRequired,
  checkActive: PropTypes.func.isRequired,
};

export default Toast;
