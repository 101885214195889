import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/PickColumnWrapper.module.scss';
import HeaderBlock from './components/HeaderBlock';
import ColumnsSelected from './components/ColumnsSelectedComponents/ColumnsSelected';
import ColumnsList from './components/ColumnsListComponents/ColumnsList';

const ColumnsView = ({
  visibleColumnsScreen,
  sortStatus,
  clearSorting,
  currentModalRef,
  setNewSortData,
  openModalHandler,
  theme,
  isWatchlist,
  isWidget,
  sortData,
  widgetId,
  widgetType
}) => (
  <div
    className={`${styles.columns} ${visibleColumnsScreen ? styles.visible : styles.hide} ${theme}`}
    ref={visibleColumnsScreen ? currentModalRef : null}
  >
    <HeaderBlock
      widgetId={widgetId}
      isWidget={isWidget}
      openModalHandler={openModalHandler}
      widgetType={widgetType}
    />
    <div className={styles.columnsBodyWrapper}>
      <ColumnsList isWatchlist={isWatchlist} visibleColumnsScreen={visibleColumnsScreen} />
      <ColumnsSelected
        isWatchlist={isWatchlist}
        sortStatus={sortStatus}
        clearSorting={clearSorting}
        setNewSortData={setNewSortData}
        sortData={sortData}
      />
    </div>
  </div>
);

ColumnsView.propTypes = {
  visibleColumnsScreen: PropTypes.bool.isRequired,
  sortStatus: PropTypes.func.isRequired,
  clearSorting: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  openModalHandler: PropTypes.func.isRequired,
  currentModalRef: PropTypes.shape({}),
  setNewSortData: PropTypes.func,
  isWatchlist: PropTypes.bool,
  isWidget: PropTypes.bool,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
  widgetId: PropTypes.string,
  widgetType: PropTypes.string,
};

ColumnsView.defaultProps = {
  setNewSortData: () => {},
  currentModalRef: null,
  isWatchlist: false,
  isWidget: false,
  sortData: [],
  widgetId: '',
  widgetType: '',
};

export default ColumnsView;
