import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from './sass/ShareTwitterModal.module.scss';
import TextAreaLabel from '../TextAreaLabel/TextAreaLabel';
import DefaultButton from '../DefaultButton/DefaultButton';
import useTwitter from './hooks/useTwitter';
import { useTheme } from '../../utils/hooks/useTheme';
import ScreenBlock from './ScreenBlock';
import { IShareTwitterModal } from './types/interface';
import RootStateTypes from '../../store/RootStateTypes';
import {
  setScreenShot,
  showShareModal,
} from '../../store/twitter/slice';
import { TWEET_LIMIT } from '../../constants/screener';
import { SendGAEvent } from '../../utils/ga';

const ShareTwitterModal = ({
  handlerTextTweet,
  textTweet,
  cleanUpScreenshotData,
}: IShareTwitterModal): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showModal = useSelector((state: RootStateTypes) => state.twitterState.showShareModal, shallowEqual);
  const resultScreenshot: (HTMLCanvasElement | null) = useSelector(
    (state: RootStateTypes) => state.twitterState.screenShot, shallowEqual
  );
  const profile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);

  const [previewImg, setPreviewImg] = useState('');
  const { theme } = useTheme();
  const { shareHandler } = useTwitter();

  const clickHandler = () => {
    dispatch(showShareModal(false));
    dispatch(setScreenShot(null));
    cleanUpScreenshotData();
    setPreviewImg('');
  };

  const shareTwittHandler = () => {
    if (previewImg) {
      shareHandler(textTweet, previewImg);
      dispatch(showShareModal(false));
      dispatch(setScreenShot(null));
      setPreviewImg('');
      cleanUpScreenshotData();
      SendGAEvent('user_share_x', { cust_identify: profile?.id, event_label: 'User Share to X', count: 1 });
    }
  };

  const textHandler = (text: string) => {
    if (text.length < TWEET_LIMIT) {
      handlerTextTweet(text);
    }
  };

  useEffect(() => {
    if (resultScreenshot) {
      const img = typeof resultScreenshot === 'string' ? resultScreenshot
        : (resultScreenshot as HTMLCanvasElement).toDataURL();
      setPreviewImg(img);
    }
  }, [resultScreenshot]);

  return (
    <>
      {showModal && (
        <div className={`${styles.container} ${theme}`}>
          <div className={styles.modalBody}>
            <ScreenBlock screen={previewImg} />
            <div className={styles.infoBlock}>
              <TextAreaLabel
                label={t('tweetText')}
                onChange={(e) => textHandler(e.currentTarget.value)}
                value={textTweet}
              />
              <div className={styles.buttonBlock}>
                <DefaultButton handleClick={clickHandler} buttonClass="tweetCancel">
                  {t('cancel')}
                </DefaultButton>
                <DefaultButton
                  handleClick={shareTwittHandler}
                  buttonClass="tweetOk"
                  disabled={!textTweet || !previewImg}
                >
                  {t('tweet')}
                </DefaultButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ShareTwitterModal;
