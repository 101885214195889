import React from 'react';

import DefaultButton from '../../DefaultButton/DefaultButton';
import styles from '../sass/DashboardComponents.module.scss';
import { ReactComponent as ChevronBlue } from '../../../assets/images/icons/screener/chevronBlue.svg';
import ColorDropdown from './ColorDropdown';
import { WidgetColorsTypes, WidgetColorsNumbers, ColorLink } from '../../../pages/Dashboards/types/DashboardEnums';
import { IColorGroupDropdownButton } from '../types/DashboardComponentsInterfaces';

const ColorGroupDropdownButton = ({
  toggleColorDrop,
  isColorDropOpen,
  widget,
  colorDropdownRef,
  handleSetColorGroupe,
  componentStyle
}: IColorGroupDropdownButton): React.ReactElement => (
  <DefaultButton
    className={`${styles.colorDropHandler} ${isColorDropOpen && styles.active}`}
    handleClick={() => toggleColorDrop(!isColorDropOpen)}
    componentStyles={componentStyle}
  >
    <div
      className={`${styles.colorDropHandlerPreview}
        ${widget.colorGroup === WidgetColorsTypes.NONE && styles.empty}`}
      style={{ backgroundColor: ColorLink[widget.colorGroup as keyof typeof ColorLink] }}
    >
      {widget.colorGroup !== WidgetColorsTypes.NONE
        ? WidgetColorsNumbers[widget.colorGroup as keyof typeof WidgetColorsNumbers]
        : ''}
    </div>
    <ChevronBlue />
    {isColorDropOpen && (
      <ColorDropdown
        parrentReff={colorDropdownRef}
        currentColor={widget.colorGroup as WidgetColorsTypes}
        setColorGroupe={(color) => handleSetColorGroupe(widget.id, color)}
        closeDropdown={() => toggleColorDrop(false)}
      />
    )}
  </DefaultButton>
);

export default ColorGroupDropdownButton;
