import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../utils/hooks/useTheme';
import { darkStyleGenerator, removeHashFromHex } from '../../utils/convertingDataHelper';
import styles from './sass/TableTooltip.module.scss';

const TableTooltip = ({
  item, value, reportDate, marketTime, historyFlag
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  return (
    <div className={theme}>
      <div className={styles.title}>
        <div className={styles.markerWrapper}>
          <span>
            {item}
          </span>
        </div>
        {historyFlag && (
          <div>
            {!!reportDate && <span className={styles.dateDescription}>{reportDate}</span>}
            <span className={styles.description}>{marketTime}</span>
          </div>
        )}
      </div>
      <div>
        <div className={styles.item}>
          <div>{t('actual')}</div>
          <div>{`${value?.actual}`}</div>
        </div>
        <div className={styles.item}>
          <div>{t('estimate')}</div>
          <div>{`${value?.estimates}`}</div>
        </div>
        <div className={styles.item}>
          <div>{`${t('chgPercent')}`}</div>
          <div style={{ color: `#${darkStyleGenerator(removeHashFromHex(value.percentColor), theme)}` }}>
            {`${value?.surprisePercent}`}
          </div>
        </div>
        <div className={styles.item}>
          <div>{`${t('chgDollar')}`}</div>
          <div style={{ color: `#${darkStyleGenerator(removeHashFromHex(value.valueColor), theme)}` }}>
            {`${value?.surpriseValue}`}
          </div>
        </div>
      </div>
    </div>
  );
};

TableTooltip.propTypes = {
  historyFlag: PropTypes.bool.isRequired,
  item: PropTypes.string.isRequired,
  value: PropTypes.shape({
    actual: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    estimates: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    surprisePercent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    surpriseValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    percentColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    valueColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  reportDate: PropTypes.string,
  marketTime: PropTypes.string,
};

TableTooltip.defaultProps = {
  reportDate: '',
  marketTime: '',
  value: {}
};

export default TableTooltip;
