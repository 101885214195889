import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ChartHeaderFilterView from './ChartHeaderFilterView';
import { toggleEstimateData } from '../../store/screener/actions';
import { ESTIMATE_VISIBLE_DATA, NUMBER_OF_QUARTERS, STATS_CHART_SOURCES } from '../../constants/storage';
import { setNumberOfQuartersAction } from '../../store/statsChart/actions';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { PERIOD_SOURCES } from '../../constants/statsChart';
import { sharedChartData } from '../ChartContainer/constants/constants';

const ChartsHeaderFilter = ({
  checkedFilters,
  setCheckedFilters,
  height,
  disabledAction
}) => {
  const dispatch = useDispatch();
  const [openFilters, setOpenFilters] = useState(false);
  const filter = useRef();
  const estimateVisibleData = useSelector((state) => state.screenerState.estimateVisibleData, shallowEqual);
  const numberOfQuarters = useSelector((state) => state.accountState.userSettings.numberOfQuarters, shallowEqual);
  const currentSource = useSelector((state) => state.accountState.userSettings.statsSources, shallowEqual);
  const storedEstimateVisibleData = useSelector(
    (state) => state.accountState.userSettings.estimateVisibleData, shallowEqual
  );

  const { updateStoreHandler } = useLastStateUpdateStore();

  const estimateVisibleHandler = () => {
    dispatch(toggleEstimateData(!estimateVisibleData));
    updateStoreHandler(ESTIMATE_VISIBLE_DATA, !estimateVisibleData);
  };

  const handleSetNumberOfQuarters = (value) => {
    updateStoreHandler(NUMBER_OF_QUARTERS, value.data);
    dispatch(setNumberOfQuartersAction(value));
  };

  const handleSetSource = (value) => {
    updateStoreHandler(STATS_CHART_SOURCES, value === PERIOD_SOURCES.Y
      ? PERIOD_SOURCES.SHORT_Y : PERIOD_SOURCES.SHORT_Q);
  };

  useEffect(() => {
    const handleClose = (event) => {
      const path = event.path || (event.composedPath && event.composedPath());
      if (!path.includes(filter.current)) {
        setOpenFilters(false);
      }
    };

    document.body.addEventListener('click', (event) => handleClose(event));
    dispatch(toggleEstimateData(storedEstimateVisibleData));
    return document.body.removeEventListener('click', (event) => handleClose(event));
  }, []);

  useEffect(() => {
    if (!sharedChartData.currentWidgetRef) return;
    sharedChartData?.currentWidgetRef?.onChartReady(() => {
      const frame = document.getElementById('tv_chart_container')?.children[0];

      const clickHandler = () => {
        setOpenFilters(false);
      };

      if (frame) {
        frame?.contentDocument?.addEventListener('click', clickHandler);
      }

      return () => frame?.contentDocument?.removeEventListener('click', clickHandler);
    });
  }, []);

  return (
    <ChartHeaderFilterView
      height={height}
      handleAddFilters={setCheckedFilters}
      setOpenFilters={setOpenFilters}
      ref={filter}
      openFilters={openFilters}
      checkedFilters={checkedFilters}
      disabledAction={disabledAction}
      estimateVisibleData={estimateVisibleData}
      estimateVisibleHandler={estimateVisibleHandler}
      numberOfQuarters={numberOfQuarters}
      currentSource={currentSource}
      setNumberOfQuarters={handleSetNumberOfQuarters}
      setSource={handleSetSource}
    />
  );
};

ChartsHeaderFilter.propTypes = {
  height: PropTypes.number,
  setCheckedFilters: PropTypes.func.isRequired,
  checkedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  disabledAction: PropTypes.bool,
};

ChartsHeaderFilter.defaultProps = {
  height: null,
  disabledAction: false,
};

export default ChartsHeaderFilter;
