import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './sass/Input.module.scss';
import { DEFAULT_MAX_LENGTH, MAX_LENGTH_COUNT, favoriteTimer } from '../../constants/screener';
import useNewScreen from '../../utils/hooks/useNewScreen';

const Input = ({
  title, type, placeholder, name, handlerChange, IconBefore, error, value, codeValid, inputClass,
  iconClass, autoFocus, maxLength, step, isFocused,
}) => {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const { alertHandler } = useNewScreen();
  const [lengthValue, setLengthValue] = useState();

  const handleLengthAlert = () => {
    alertHandler(t('characterLimitWarning'), t('characterLimit'));
  };

  const timeoutHandler = () => setLengthValue(value?.length);

  const onTextChange = (event) => {
    if (event.target.value.length > MAX_LENGTH_COUNT) {
      setLengthValue(event.target.value.length);
      event.target.value.slice(0, -1);
    } else {
      handlerChange(event);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(timeoutHandler, favoriteTimer);
    if (lengthValue > maxLength) {
      handleLengthAlert();
    }
    return () => clearTimeout(timeout);
  }, [lengthValue]);

  useEffect(() => {
    if (inputRef?.current && isFocused) {
      inputRef?.current?.focus();
    }
  }, [inputRef, isFocused]);

  return (
    <div className={`${styles.Input} ${inputClass} ${error || codeValid ? styles.error : null}`}>
      <p className={styles.Input_title}>
        {title}
      </p>
      <div className={styles.Input_wrapper}>
        {IconBefore && (
          <IconBefore className={iconClass ? styles[iconClass] : styles.Input_icon} />
        )}
        <input
          ref={inputRef}
          tabIndex="0"
          autoFocus={autoFocus}
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onTextChange}
          className={!IconBefore ? styles.single : null}
          maxLength={maxLength && maxLength + 1}
          step={step}
        />
        {error && (
          <span className={styles.message}>
            {t('priceError')}
          </span>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  handlerChange: PropTypes.func.isRequired,
  IconBefore: PropTypes.shape({}),
  error: PropTypes.bool,
  codeValid: PropTypes.bool,
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  iconClass: PropTypes.string,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.string,
  step: PropTypes.string,
  isFocused: PropTypes.bool
};

Input.defaultProps = {
  IconBefore: null,
  error: false,
  codeValid: false,
  inputClass: '',
  title: '',
  type: '',
  name: '',
  placeholder: '',
  iconClass: '',
  autoFocus: false,
  maxLength: DEFAULT_MAX_LENGTH,
  step: '',
  isFocused: false,
};

export default React.memo(Input);
