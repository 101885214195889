/* eslint-disable */
declare global {
  interface Window {
    gtag?: any;
  }
}
/* eslint-enable */

export type TEventParams = {
  [key: string]: string | number | boolean | undefined | null;
};

export const SendGAEvent = (eventName: string, eventParams: TEventParams): void => {
  const newParams = { ...eventParams };
  const curerentTimestamp = new Date().getTime();
  newParams.last_timestamp = curerentTimestamp;
  window?.gtag('event', eventName, newParams);
};
