const OUTER_URLS = {
  EDUCATION_OUTER: 'https://deepvue.com/education/',
  SUPPORT_OUTER: 'https://deepvue.com/support/',
  PRICING: 'https://deepvue.com/pricing',
  TERMS_AND_CONDITIONS: 'https://deepvue.com/terms-of-use/',
  PRIVACY_POLICY: 'https://deepvue.com/privacy-policy/',
  SIGN_IN: 'https://deepvue.com/account-dashboard/'
};

export default OUTER_URLS;
