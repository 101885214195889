import React, { useEffect, useState } from 'react';
import styles from '../sass/MultiChartSettings.module.scss';
import { ReactComponent as ChevronBlue } from '../../../assets/images/icons/screener/chevronBlue.svg';
import MCSettingsSelectListItem from './MCSettingsSelectItem';
import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import Input from '../../Input/Input';
import { IMultiChartSelectOption } from '../types/MultichartInterfaces';

type IMCSettingsSelect = {
  selectOption: IMultiChartSelectOption[]
  activeId: string
  setActiveId: (val: string) => void
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  title: string
  optionSearch?: boolean
  className?: string
};

const NoResultsSplash = () => (
  <div className={styles.noResults}>
    <SearchIcon />
    <span>
      No Results Found
    </span>
  </div>
);

const MCSettingsSelect = ({
  selectOption,
  activeId,
  setActiveId,
  title,
  optionSearch = false,
  className,
  setIsOpen,
  isOpen
}: IMCSettingsSelect): React.JSX.Element => {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<IMultiChartSelectOption[]>([]);

  useEffect(() => {
    setOptions(selectOption);
  }, []);

  useEffect(() => {
    if (search) {
      const searchResult = selectOption
        .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()));
      setOptions(searchResult);
    } else {
      setOptions(selectOption);
    }
  }, [search]);
  return (
    <div className={`
      ${className ?? ''}
      ${styles.select}
      ${isOpen ? styles.active : styles.unActive}`}
    >
      <div
        aria-hidden="true"
        className={`${styles.title} ${activeId ? styles.selectedTitle : ''}`}
        onClick={() => { setIsOpen(!isOpen); }}
        role="button"
        tabIndex={0}
      >
        <span>
          {title}
        </span>
        <ChevronBlue className={styles.chevron} />
      </div>
      {optionSearch && isOpen ? (
        <div className={styles.searchWrapper}>
          <Input
            IconBefore={SearchIcon}
            placeholder="Search"
            value={search}
            handlerChange={(e) => setSearch(e.target.value)}
            inputClass={styles.searchInput}
          />
        </div>
      ) : null}
      <div className={`${styles.content} ${isOpen ? styles.isOpen : ''}`}>
        {!options.length ? <NoResultsSplash /> : null}
        {options.map((item) => (
          <MCSettingsSelectListItem
            key={item.id}
            item={item}
            selectHandler={setActiveId}
            selected={activeId === item.id.toString()}
          />
        ))}
      </div>
    </div>
  );
};

export default MCSettingsSelect;
