import React, { BaseSyntheticEvent, useState } from 'react';

import { DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styles from '../sass/DashboardItemDropdown.module.scss';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { ReactComponent as IconFolder } from '../../../assets/images/icons/screener/folder.svg';
import { ReactComponent as ChevronIcon } from '../../../assets/images/icons/chevronDown.svg';
import ItemMenu from './ItemMenu';
import { DasboardsItemsDropdownTypes, MENU_FOLDER } from '../types/constants';
import { TDashboardItemsData } from '../../../utils/Types';
import ThemeVariants from '../../../constants/theme';
import { DashboardItemsListsTitle, DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';

export interface IItemDropdownListFolder {
  theme: string;
  item: TDashboardItemsData;
  selectedItem: TDashboardItemsData | null;
  actionDuplicate: (item: TDashboardItemsData) => void;
  actionShare: (item: TDashboardItemsData) => void;
  actionDelete: (item: TDashboardItemsData) => void;
  actionEdit: (item: TDashboardItemsData) => void;
  actionMove: (item: TDashboardItemsData) => void;
  actionFavorite: (item: TDashboardItemsData) => void;
  actionRemove: (item: TDashboardItemsData) => void;
  selectedTab: DasboardsItemsDropdownTypes;
  adminRole: boolean;
  provided: DroppableProvided;
  handleMouseEnter: (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
  handleCloseSubitem: (e: BaseSyntheticEvent) => void;
  snapshot: DroppableStateSnapshot,
  disableDND: boolean;
  itemType: DialogItemDataTypes;
  setDragStart: (dragStart: boolean) => void;
}

const DashboardItemFolder = ({
  item,
  theme,
  actionDuplicate,
  actionShare,
  actionDelete,
  actionEdit,
  actionMove,
  selectedTab,
  adminRole,
  handleMouseEnter,
  handleCloseSubitem,
  provided,
  snapshot,
  disableDND,
  itemType,
  setDragStart
}: IItemDropdownListFolder): React.ReactElement => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorElement(null);
  };

  const getFullAccessItemMenu = () => {
    if (adminRole) return true;
    if (selectedTab === DasboardsItemsDropdownTypes.PRESET) return false;
    return true;
  };

  const getListStyle = (isDraggingOver: boolean): React.CSSProperties => {
    const overColor = theme === ThemeVariants.DARK_MODE ? '#1A1F2B' : '#F5F6FF';
    return {
      background: isDraggingOver ? overColor : '',
      cursor: isDraggingOver ? 'cell' : 'default',
    };
  };

  return (
    <div
      id={`${item.id}`}
      ref={provided?.innerRef}
      {...provided?.droppableProps} // eslint-disable-line
      onMouseEnter={handleMouseEnter}
      onTouchStart={handleMouseEnter}
      style={getListStyle(snapshot.isDraggingOver)}
      onMouseLeave={handleCloseSubitem}
      key={item.id}
      className={`${styles.itemDropdownListItem} ${styles.folderItem}`}
      aria-hidden
      onMouseDown={() => {
        setDragStart(true);
      }}
    >
      <div className={styles.leftBlock}>
        {!disableDND && (
          <DndControl
            className={styles.dndIcon}
          />
        )}
        <IconFolder className={styles.folderIcon} />
        <div className={`${styles.itemDropdownListItemText} ${styles.notActive}`}>
          <span className={styles.itemDropdownListItemTextDescription}>
            {item?.title}
          </span>
          <span className={styles.itemDropdownListItemTextSub}>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            {`${item?.items?.length} ${DashboardItemsListsTitle[itemType as DialogItemDataTypes]}`}
          </span>
        </div>
      </div>
      <div className={`${styles.rightBlock} ${styles.endPosition}`}>
        {getFullAccessItemMenu() && (
        <ItemMenu
          item={item}
          anchorElement={anchorElement}
          handleOpen={handleOpen}
          menuOpen={Boolean(anchorElement)}
          setMenuClose={handleClose}
          theme={theme}
          actionDuplicate={actionDuplicate}
          actionShare={actionShare}
          actionMove={actionMove}
          actionEdit={actionEdit}
          actionDelete={actionDelete}
          menuObject={MENU_FOLDER}
        />
        )}
        {!!item?.items?.length && <ChevronIcon className={styles.moreItemIcon} />}
      </div>
    </div>
  );
};

export default DashboardItemFolder;
