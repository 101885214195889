import React from 'react';
import PropTypes from 'prop-types';
import styles from './scss/TitleColumnBar.module.scss';
import SizeSelect from '../Columns/components/SizeSelect';

const areEqual = (prevProps, nextProps) => {
  return prevProps.title === nextProps.title;
};

const TitleColumnBar = ({ title }) => (
  <div className={styles.wrapper}>
    <h2 className={styles.titleText}>
      {title}
    </h2>
    <SizeSelect />
  </div>
);

TitleColumnBar.propTypes = {
  title: PropTypes.string
};

TitleColumnBar.defaultProps = {
  title: ''
};

export default React.memo(TitleColumnBar, areEqual);
