import { FilledAreaType } from "../../../charting_library/charting_library";

export const defaultFilledAreas = {
  rs_rsma_filled_area: {
    color: 'yellow',
    transparency: 75,
    visible: true,
  },
};

export const filledAreas = [
  {
    id: 'rs_rsma_filled_area',
    objAId: 'rs_line',
    objBId: 'rs_ma_line',
    title: 'RS Area',
    type: 'plot_plot' as FilledAreaType.TypePlots,
    palette: 'rs_rsma_area_palette',
  },
];
