import { useDispatch } from 'react-redux';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralConfirmModal from '../ConfirmModal/GeneralConfirmModal';
import useAccount from '../../utils/hooks/useAccount';
import { setModalType } from '../../store/watchlist/actions';

const ConfirmSignOutModal = (): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    logOutHandler
  } = useAccount();

  const confirmHandler = (confirm: boolean) => {
    if (confirm) logOutHandler();
    dispatch(setModalType(''));
  };

  return (
    <GeneralConfirmModal
      title={t('confirmSignOut')}
      body=""
      description=""
      confirmCaption={t('buttonYes')}
      declineCaption={t('buttonNo')}
      confirmHandler={() => confirmHandler(true)}
      declineHandler={() => confirmHandler(false)}
      isCentered
    />
  );
};

export default ConfirmSignOutModal;
