import { TDashboardItemsData } from './Types';

function getNewTitle(item: TDashboardItemsData, itemsDashboard: TDashboardItemsData[]): string {
  const copyPattern = /^(.*? copy)( \((\d+)\))?$/i;
  let baseTitle;

  const match = item.title.match(copyPattern);
  if (match) {
    [, baseTitle] = match;
  } else {
    baseTitle = item.title;
  }

  let newTitle = `${baseTitle} copy`;

  // eslint-disable-next-line @typescript-eslint/no-loop-func
  while (itemsDashboard.find((dashboardItem: TDashboardItemsData) => dashboardItem.title === newTitle)) {
    newTitle = `${newTitle} copy`;
  }
  return newTitle;
}

export default getNewTitle;
