import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { TDashboardItemsData } from '../../../utils/Types';
import { TDialogData } from '../types/types';
import { DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';
import { ReactComponent as IconFolder } from '../../../assets/images/icons/screener/folder.svg';
import { DashboardItemsDialogActionTypes } from '../types/constants';

interface IDialogContentRemove {
  item: TDashboardItemsData,
  theme: string,
  dialogData: TDialogData
}

const DialogContentRemove = ({ item, theme, dialogData }: IDialogContentRemove): React.ReactElement => {
  const { t } = useTranslation();
  const currentItem = item as TDashboardItemsData;

  const getRemoveTitle = (): string => {
    if (dialogData?.type === DashboardItemsDialogActionTypes.REMOVE) {
      return t('labelRemoveFromFolder', { itemName: currentItem?.title });
    }
    return t('youSureYouWant');
  };

  const getRemoveContent = (): React.ReactElement => {
    if (dialogData?.type === DashboardItemsDialogActionTypes.REMOVE) {
      const folder = item?.folder;
      return (
        <div className={styles.itemTitle}>
          <IconFolder className={styles.removeItemIcon} />
          <div className={styles.itemTitleLabel}>{ folder?.name }</div>
        </div>
      );
    }
    return (
      <div className={styles.itemTitle}>
        {dialogData?.entityType === DialogItemEntityType.FOLDER && (
        <IconFolder className={styles.removeItemIcon} />
        )}
        <div className={styles.itemTitleLabel}>{ currentItem?.title }</div>
      </div>
    );
  };

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={styles.dialogContentFieldItem}>
        <div className={styles.sureLabel}>
          {getRemoveTitle()}
        </div>
        {getRemoveContent()}
      </div>
    </div>
  );
};

export default DialogContentRemove;
