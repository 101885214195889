import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import $api, { REACT_APP_PUBLIC_ALERTS } from '../../../http';
import {
  ALERTS_NOTIFICATION_PATH,
  ALERT_PATH,
  ALERT_TICK,
  DASHBOARD_ITEMS,
  DASHBOARD_ITEM_TYPE_ALERTS
} from '../../../constants/paths';
import { errorMessageAction } from '../../../store/account/actions';
import {
  setAlertsNotificationCounter,
  setAlertsNotificationList,
  setAlertsList,
  setOpenAlertModal,
  setCurrentAlert,
  setAlertNotifications,
  updateAlertCheckedNotifications,
  setAlertsListCounter,
  removeAlertCheckedNotifications
} from '../../../store/alerts/slice';
import {
  createAlertModalData,
  currentLimitationHandler,
  alertsModalStateHandler,
  getDateTimeForPeriod, chunkArrayWorker
} from '../utils/alertHelper';
import { ACTIVE_LIMITATION } from '../../../constants/storage';
import {
  PAGE,
  LIMITS,
  WAIT_MODAL,
  DEFAULT_LIMIT,
  ALERT_MODAL_SETINGS_TITLE,
  ALERT_TYPES,
  DEFAULT_ALERT_STATE,
  COMMON_LINE,
} from '../../../constants/alerts';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { TEMPLATE_TYPE, VIEW_TYPES } from '../../../constants/screener';
import { SECOND } from '../../../constants/tvWidgetOptions';
import { getFromLocalStorage } from '../../../utils/storageWorks';
import { sharedChartData } from '../constants/constants';

let lastPrice = null;

const useAlerts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alertListFromStore = useSelector((state) => state?.alertsState?.alertsList, shallowEqual);
  const openAlert = useSelector((state) => state?.alertsState?.openAlertModal, shallowEqual);
  const alertData = useSelector((state) => state?.alertsState?.currentAlert, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const alertSettingsItemData = useSelector((state) => state?.alertsState?.alertSettingsItemData, shallowEqual);

  const successHandler = (title, message) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const errorHandler = (title, message) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('error') }));
  };

  const getAlertByIdFromState = (id) => {
    const foundAlert = alertListFromStore.filter((alert) => alert?.id === id);
    return foundAlert?.length ? foundAlert[0] : null;
  };

  const saveRequestToStore = (res) => {
    const newToastCounter = res.data.items.filter((item) => !item.isViewed).length;
    const sortedToasts = res.data.items.sort((a, b) => (a.isViewed && !b.isViewed ? 1 : -1));

    dispatch(setAlertsNotificationCounter(newToastCounter));
    dispatch(setAlertsNotificationList(sortedToasts));
  };

  const getAlertsForId = (id) => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}/${id}`;
    return $api.get(URL);
  };

  const getAlertsNotificationList = () => {
    const limitId = JSON.parse(localStorage.getItem(ACTIVE_LIMITATION));
    const limitValue = currentLimitationHandler();
    const currentLimit = limitId !== null ? limitValue[limitId] : limitValue[DEFAULT_LIMIT];

    const URL = `${
      REACT_APP_PUBLIC_ALERTS
    }${ALERTS_NOTIFICATION_PATH}?limit=${LIMITS}&from=${currentLimit[0]}&to=${currentLimit[1]}`;

    try {
      $api.get(URL).then((res) => {
        saveRequestToStore(res);
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getAlertsNotificationListWithLimitation = (from, to) => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERTS_NOTIFICATION_PATH}?from=${from}&to=${to}`;

    try {
      $api.get(URL).then((res) => {
        saveRequestToStore(res);
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateAlertNotificationsButch = (ids) => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERTS_NOTIFICATION_PATH}`;

    try {
      $api.patch(URL, { ids }).then();
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const clearAlerts = () => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERTS_NOTIFICATION_PATH}`;
    try {
      return $api.delete(URL);
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
    return Promise.resolve();
  };

  const getAlerts = (fromState) => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}`;

    if (fromState && alertListFromStore?.length) {
      return new Promise((resolve) => resolve({ data: { items: alertListFromStore } }));
    }

    return $api.get(`${URL}?page=0&limit=200`).then((res) => {
      dispatch(setAlertsListCounter(res.data.count));
      const responseData = [...res.data.items];
      const numberOfRequests = Math.ceil(res.data.count / 200) - 1;

      if (numberOfRequests > 0) {
        const requests = Array.from(Array(numberOfRequests).keys()).map(async (item) => {
          const resp = await $api.get(`${URL}?page=${item + 1}&limit=200`);
          return resp.data.items;
        });

        Promise.all(requests).then((restOfData) => {
          restOfData.forEach((item) => {
            responseData.push(...item);
          });
        });
      }

      return responseData;
    });
  };

  const getAlertsList = () => {
    try {
      getAlerts().then((res) => dispatch(setAlertsList(res)));
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getAlertsForSymbolIndex = async (symbol) => {
    if (!symbol) return [];

    try {
      const currentSymbolIndex = symbolsList?.filter((sym) => sym?.sym === symbol)[0]?.index;
      const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}/?symbolIndex=${currentSymbolIndex}`;
      const result = await $api.get(URL);
      return result?.data?.items;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching alerts:', error);
    }

    return [];
  };

  const getAlertsForSearchString = (search) => {
    let URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}`;
    if (search) {
      URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}/?search=${search}`;
    }
    return $api.get(URL);
  };

  const createAlert = (url, alert) => {
    try {
      $api.post(url, alert).then((res) => {
        dispatch(setOpenAlertModal(false));
        getAlertsList();
        successHandler(t('createAlertTitle'), t('createAlertNotion', { symbol: res?.data?.symbol }));
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateLastPriceState = (alert) => {
    if (alert?.type === ALERT_TYPES.PRICE) {
      lastPrice = alert?.priceTime1;
    }
  };

  const updateAlert = (url, alert, skipRefresh = false, showMessage = true) => {
    try {
      $api.patch(url, alert).then((res) => {
        dispatch(setOpenAlertModal(false));
        if (!skipRefresh) {
          getAlertsList();
        }
        if (showMessage) {
          successHandler(t('updateAlertTitle'), t('updateAlertNotion', { symbol: res?.data?.symbol }));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const saveAlert = (alert, skipRefresh = false, showMessage = true) => {
    const data = { ...alert };
    updateLastPriceState(alert);
    data.priceTime1 = +data.priceTime1;
    data.priceTime2 = +data.priceTime2;

    let URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}`;
    if (data?.id) {
      URL = `${URL}/${data?.id}`;
      updateAlert(URL, data, skipRefresh, showMessage);
    } else {
      URL = `${URL}`;
      createAlert(URL, data);
    }
  };

  const removeAlerts = (alertIds, isForceRemove = false) => {
    const chunks = chunkArrayWorker(alertIds, 100);
    const shapes = [];

    alertIds.forEach((id) => {
      const shape = alertListFromStore.find((alert) => alert.id === id);
      if (shape.type === ALERT_TYPES.PRICE || isForceRemove) {
        shapes.push(...shape.lineId.split(','));
      } else {
        try {
          const allShapes = sharedChartData.currentWidgetRef?.chart().getAllShapes();
          const shapeIds = shape.lineId.split(',');
          const currentShape = allShapes.find((item) => shapeIds.includes(item.id));
          const currentShapeData = sharedChartData.currentWidgetRef?.chart()?.getShapeById(currentShape.id);
          currentShapeData?.setProperties({ linecolor: COMMON_LINE });
          sharedChartData.currentWidgetRef?.saveChartToServer();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('=> no such shapes');
        }
      }
    });

    const requests = chunks.map((chunk) => {
      return new Promise((resolve) => {
        const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}`;
        const data = { ids: chunk };
        try {
          $api.delete(URL, { headers: { 'Content-Type': 'application/json' }, data: { ...data } }).then((res) => {
            if (res) {
              dispatch(setOpenAlertModal(false));
              if (shapes.length > 0) {
                shapes.forEach((shape) => {
                  sharedChartData.currentWidgetRef.chart().removeEntity(shape);
                });
              }
              getAlertsList();
              resolve(res.status);
            }

            if (alertIds.length > 1) {
              successHandler(t('deleteMultipleAlertTitle'), t('deleteMultipleAlertNotion'));
            } else {
              successHandler(t('deleteAlertTitle'), t('deleteAlertNotion'));
            }
          });
        } catch (e) {
          dispatch(errorMessageAction({ errorMessage: e.message }));
        }
      });
    });

    Promise.all(requests).then();
  };

  let timeout;
  const alertAction = (data) => {
    const {
      symbol, points, id, type
    } = data;

    const newAlert = createAlertModalData(symbol.name, symbol.index, points, lastPrice, id, type);

    if (!newAlert) {
      return errorHandler(t('warningAlertAdd'), t('warningAlertAddTitle'));
    }

    if (getFromLocalStorage('isWebView')) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ alertData: alertsModalStateHandler(newAlert, alertSettingsItemData) })
      );
    }
    dispatch(setCurrentAlert(alertsModalStateHandler(newAlert, alertSettingsItemData)));
    // eslint-disable-next-line no-return-assign
    return timeout = setTimeout(() => {
      clearTimeout(timeout);
      dispatch(setOpenAlertModal(true));
    }, WAIT_MODAL);
  };

  const handleAlertClose = () => {
    dispatch(setCurrentAlert(null));
    dispatch(setOpenAlertModal(false));
  };

  let alertActionTimeout;

  const alertActionEdit = (alertId) => {
    getAlertsForId(alertId).then((res) => {
      const alert = res?.data;
      alert.priceTime1 = +alert?.priceTime1?.toFixed(2);
      alert.priceTime2 = +alert?.priceTime2?.toFixed(2);
      dispatch(setCurrentAlert(res.data));
      alertActionTimeout = setTimeout(() => {
        dispatch(setOpenAlertModal(true));
        clearTimeout(alertActionTimeout);
      }, WAIT_MODAL);
    });
  };

  const getAlertsListWithLimitation = (limit) => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_PATH}?page=${PAGE}&limit=${LIMITS}`;
    try {
      $api.get(`${URL}&select=${limit}`).then((res) => {
        dispatch(setAlertsNotificationCounter(res.data.count));
        dispatch(setAlertsNotificationList(res.data.items));
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const toggleAlertStatus = (id, status) => {
    const alertEntity = JSON.parse(JSON.stringify(getAlertByIdFromState(id)));

    if (alertEntity) {
      const newAlerts = JSON.parse(JSON.stringify(alertListFromStore));
      newAlerts.forEach((alert) => {
        if (alert.id === id) {
        // eslint-disable-next-line no-param-reassign
          alert.isActive = status;
        }
      });
      dispatch(setAlertsList(newAlerts));
      dispatch(removeAlertCheckedNotifications(alertEntity.id));

      alertEntity.isActive = status;
      saveAlert(alertEntity, true);
    }
  };

  const triggeredAlertsListener = () => {
    const URL = `${REACT_APP_PUBLIC_ALERTS}${ALERT_TICK}`;
    try {
      $api.get(URL).then((res) => {
        if (res.data.length) {
          dispatch(setAlertNotifications(res.data));
          getAlerts().then((resAlertsList) => dispatch(setAlertsList(resAlertsList)));
          getAlertsNotificationList();
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getAlertSettings = () => {
    const URL = `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_ALERTS}`;
    try {
      $api.get(URL).then((res) => {
        if (res.data.items.length) {
          dispatch(updateAlertCheckedNotifications(res.data.items[0]));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const saveAlertSettings = (newState) => {
    const URL = `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_ALERTS}`;

    const data = {
      isAbove: newState.isAbove,
      isBellow: newState.isBellow,
      usePush: newState.usePush,
      useEmail: newState.useEmail,
      usePopup: newState.usePopup
    };

    try {
      if (Object.keys(alertSettingsItemData).length === 0) {
        $api.post(URL, {
          title: ALERT_MODAL_SETINGS_TITLE,
          type: TEMPLATE_TYPE.ALERTS,
          viewType: VIEW_TYPES.CHART,
          data
        }).then(() => getAlertSettings());
      } else {
        $api.patch(`${DASHBOARD_ITEMS}/${alertSettingsItemData.id}`, {
          data
        }).then(() => getAlertSettings());
      }
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const clearLastPrice = () => {
    lastPrice = null;
  };

  const updateAlertPoints = (alreadyAlert, points) => {
    if (!alreadyAlert) return;
    const oldAlert = JSON.parse(JSON.stringify(alreadyAlert));
    const priceStart = +points[0]?.price?.toFixed(2);
    const priceEnd = +points[1]?.price?.toFixed(2);
    if (oldAlert?.type === ALERT_TYPES.PRICE) {
      const currentlifetime = getDateTimeForPeriod(points[0]?.time, DEFAULT_ALERT_STATE.lifetime) / SECOND;
      oldAlert.pointTime1 = points[0]?.time;
      oldAlert.pointTime2 = points[0]?.time + currentlifetime;
      oldAlert.priceTime1 = priceStart;
      oldAlert.priceTime2 = priceStart;
    } else {
      oldAlert.pointTime1 = points[0]?.time;
      oldAlert.pointTime2 = points[1]?.time;
      oldAlert.priceTime1 = priceStart;
      oldAlert.priceTime2 = priceEnd;
    }
    saveAlert(oldAlert, false);
  };

  const updateAlertLinks = (alert, newId) => {
    if (!newId) return;
    const oldAlert = JSON.parse(JSON.stringify(alert));
    const ids = oldAlert.lineId?.split(',').map((id) => id);
    ids?.push(newId);
    oldAlert.lineId = ids?.join(',');

    saveAlert(oldAlert, false, false);
  };

  const checkIsAlertActive = (id) => {
    const alertEntity = getAlertByIdFromState(id);
    if (alertEntity) {
      return alertEntity?.isActive;
    }
    return false;
  };

  return {
    openAlert,
    alertData,
    alertAction,
    handleAlertClose,
    saveAlert,
    removeAlerts,
    getAlertsNotificationList,
    getAlertsNotificationListWithLimitation,
    getAlertsList,
    getAlertsListWithLimitation,
    alertActionEdit,
    alertListFromStore,
    toggleAlertStatus,
    getAlertsForSearchString,
    triggeredAlertsListener,
    updateAlertNotificationsButch,
    getAlertSettings,
    saveAlertSettings,
    clearLastPrice,
    updateAlertPoints,
    checkIsAlertActive,
    clearAlerts,
    updateAlertLinks,
    getAlertsForSymbolIndex
  };
};

export default useAlerts;
