import React, {
  memo, useEffect, useMemo, useRef, useState
} from 'react';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import {
  LabelAlign, LabelOverflow, TreemapController, TreemapElement, TreemapScriptableContext
} from 'chartjs-chart-treemap';
import { Chart } from 'react-chartjs-2';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../sass/WidgetCharts.module.scss';
import { IHeatMap } from '../types/WidgetChartsInterfaces';
import {
  BAR_CHART_COLORS, BAR_HEIGHT, FONT_FAMILY, HEATMAP_GROUPS
} from '../types/WidgetChartsEnums';
import { HeatMapDataset } from '../types/WidgetChartsypes';
import { removeNonNumeric } from '../../DasboardComponents/utils/utils';
import { getHeatMapContextData, heatMapBackgroundColor } from '../utils/heatMapColors';
import { useTheme } from '../../../utils/hooks/useTheme';
import ThemeVariants from '../../../constants/theme';
import RootStateTypes from '../../../store/RootStateTypes';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TreemapElement,
  TreemapController,
  Title,
  Tooltip,
  Legend
);

const HeatMap = ({
  dataSet,
  height,
  isDarkMode,
  widget,
  changeSymbolForGroup,
}: IHeatMap): React.ReactElement => {
  const ref = useRef();
  const { theme } = useTheme();
  const [isVisible, setVisible] = useState(false);
  const singleBlockRange = Math.abs([...dataSet].sort((a: HeatMapDataset, b: HeatMapDataset) => (
    parseFloat(removeNonNumeric(b.value.toString())) - parseFloat(removeNonNumeric(a.value.toString())))).slice(0, 10)
    .reduce((acc, current) => acc + parseFloat(removeNonNumeric(current.value.toString())), 0) / 10) / 3;

  const showModal = useSelector((state: RootStateTypes) => state.twitterState.showShareModal, shallowEqual);

  useEffect(() => {
    setVisible(false);
    setTimeout(() => setVisible(true), 500);
  }, [isDarkMode, showModal]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('=> widget_ready', widget.id, new Date().getTime());
  }, []);

  const data = useMemo(() => (
    {
      datasets: [
        {
          data: dataSet,
          tree: dataSet,
          key: 'marketCap',
          groups: HEATMAP_GROUPS,
          spacing: 0.5,
          borderWidth: 0.5,
          borderColor: BAR_CHART_COLORS.gray,
          backgroundColor: (ctx: TreemapScriptableContext) => heatMapBackgroundColor(ctx, singleBlockRange,
            theme === ThemeVariants.DARK_MODE),
          hoverBackgroundColor: (ctx: TreemapScriptableContext) => heatMapBackgroundColor(ctx, singleBlockRange,
            theme === ThemeVariants.DARK_MODE),
          labels: {
            align: 'center' as LabelAlign,
            display: true,
            formatter: (ctx: TreemapScriptableContext) => {
              if (ctx.type !== 'data') {
                return [];
              }
              return [getHeatMapContextData(ctx)?.[0]?.sym, ctx.raw.g ?? 0];
            },
            color: [BAR_CHART_COLORS.white, BAR_CHART_COLORS.white],
            hoverColor: [BAR_CHART_COLORS.white, BAR_CHART_COLORS.white],
            font: [{ size: 16, weight: 700, family: FONT_FAMILY }, { size: 14, weight: 700, family: FONT_FAMILY }],
            overflow: 'fit' as LabelOverflow,

          },
          captions: {
            color(ctx: TreemapScriptableContext) {
              if (ctx.type !== 'data') {
                return isDarkMode ? BAR_CHART_COLORS.white : BAR_CHART_COLORS.midGray;
              }
              const isTopIndustry = getHeatMapContextData(ctx).find((localItem: HeatMapDataset) => (
                localItem.topIndustry === 1 && ctx.raw.g === localItem.industry && ctx.raw.g !== 'N/A'
              ));
              if (isTopIndustry) return BAR_CHART_COLORS.blue;
              return isDarkMode ? BAR_CHART_COLORS.white : BAR_CHART_COLORS.midGray;
            },
            hoverColor: '',
            font: { size: 12, weight: 500, family: FONT_FAMILY },
          }
        }
      ],
    }
  ), [dataSet, theme]);

  return isVisible ? (
    <div className={styles.chartWrapper} style={{ height: height - BAR_HEIGHT.heatMapOffset }}>
      <Chart
        ref={ref}
        type="treemap"
        data={data}
        options={{
          animation: false,
          maintainAspectRatio: false,
          responsive: true,
          onClick(event, elements, chart) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const points = chart.getElementsAtEventForMode(event, 'point', {
              intersect: false, includeInvisible: true
            }, true);
            if (points.length) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const firstPoint = points[points.length - 1]?.element?.$context?.raw;
              // eslint-disable-next-line no-underscore-dangle
              const localData = firstPoint._data.children.find((localItem: HeatMapDataset) => (
                localItem.marketCap === firstPoint.v
              ));
              if (localData?.sym) changeSymbolForGroup(localData?.sym, widget.colorGroup as string, widget.id);
            }
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          }
        }}
      />
    </div>
  ) : <></>;
};

const areEqual = (prevProps: IHeatMap, nextProps: IHeatMap) => (
  prevProps.dataSet === nextProps.dataSet
  && prevProps.isDarkMode === nextProps.isDarkMode
  && prevProps.height === nextProps.height);

export default memo(HeatMap, areEqual);
