import { ISharedChartData } from '../types/Interfaces';
import { TAlertData } from '../types/Types';

export const currentAlertData = {
  data: null as TAlertData | null
};
export const pricePoints = {
  data: []
};

export const sharedChartData: ISharedChartData = {
  currentWidgetRef: null,
  alertList: [],
  currentSymbolData: {},
  selectedShapeId: null,
  preventGlobalSearch: false,
  currentHistoryCacheKey: ''
};
