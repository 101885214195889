import { createSlice } from '@reduxjs/toolkit';
import { compareColumnArray, compareDataArray, compareCounter } from '../../utils/helpers';
import { CURRENT_PAGE_DEFAULT, MIN_COLUMNS_CLEAR } from '../../constants/screener';
import { ScreenerRowData } from '../../utils/Types';
import { ShortSymbol } from '../../pages/Table/DeeplistUtils/types/AlertTypes';
import { priceChangeToday } from '../../constants/dataPanel';

interface RowObject {
  index: number;
  sym: string;
  name: string;
  icon: string;
  enabled: boolean;
  sortIndex: number;
}

type RowItem = [RowObject, ...(string | number)[]];

const INITIAL_STATE = {
  dataRows: [] as ScreenerRowData[],
  dataRowsRt: [] as RowItem[],
  dataColumns: [],
  queryScreenerId: null,
  visibleItemsTable: [CURRENT_PAGE_DEFAULT.INIT_VALUE, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT],
  currentPage: [CURRENT_PAGE_DEFAULT.INIT_VALUE, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT],
  dataPanelsQueryId: null,
  counterSymbols: 0,
  screenerRowTopIndex: 0,
  industryDataRows: [] as ScreenerRowData[],
  industryDataColumns: [],
  industryOfSymbols: [],
  sectorDataRows: [] as ScreenerRowData[],
  sectorDataColumns: [],
  groupDataRows: [] as ScreenerRowData[],
  groupDataColumns: [],
  subIndustryDataRows: [] as ScreenerRowData[],
  subIndustryDataColumns: [],
  industryScreenerId: null,
  sectorScreenerId: null,
  groupScreenerId: null,
  subIndustryScreenerId: null,
  dataRowsIndexes: [],
  hoveredSymbolIndex: {
    index: null,
    dataId: null
  },
};

interface ColumnItem {
  index: number,
  name: string,
  shortName: string,
  group: number,
  type: string,
  default: boolean,
  show: boolean,
  enabled: boolean,
  roles: string[]
}

const tableDataSlice = createSlice({
  name: 'tableDataSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setDataRows(state, action) {
      state.dataRows = compareDataArray(state, action.payload);
      state.dataRowsRt = compareDataArray(state, action.payload);
    },
    setDataColumns(state, action) {
      state.dataColumns = compareColumnArray(state, action.payload);
    },
    setIndustryDataRows(state, action) {
      const { name } = action.payload;
      state[name as 'industryDataRows' | 'sectorDataRows'
      | 'groupDataRows' | 'subIndustryDataRows'] = action.payload.data;
    },
    updateIndustryDataRows(state, action) {
      const { name, data }:
      { name: 'industryDataColumns'
      | 'groupDataColumns'
      | 'sectorDataColumns'
      | 'subIndustryDataColumns', data: [number, number, number]
      } = action.payload;

      const targetIndex = state[name].findIndex((item) => item[0] === data[0]);

      // checking if updated data is for Price % Change Today data point or for Relative Measured Volatility - 15 Days
      const dataIndex = data[1] === priceChangeToday ? 2 : 4;
      if (targetIndex !== -1) {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line prefer-destructuring
        state[name][targetIndex][dataIndex] = data[2];
      }
    },
    setIndustryDataColumns(state, action) {
      const { name } = action.payload;
      state[name as 'industryDataColumns' | 'groupDataColumns'
      | 'sectorDataColumns' | 'subIndustryDataColumns'] = action.payload.data;
    },
    setIndustryOfSymbols(state, action) {
      state.industryOfSymbols = action.payload;
    },
    setIndustryQueryScreenerId(state, action) {
      const { name } = action.payload;
      if (name) {
        state[name as 'industryScreenerId' | 'sectorScreenerId'
        | 'groupScreenerId' | 'subIndustryScreenerId'] = action.payload.data;
      }
    },
    setQueryScreenerId(state, action) {
      state.queryScreenerId = action.payload;
    },
    setVisibleItemsTableStore(state, action) {
      state.visibleItemsTable = action.payload;
    },
    setCleanedItemsForTarget(state, action) {
      state.dataRows = state.dataRows.filter(
        (item: ScreenerRowData) => {
          const firstElement = item[0] as ShortSymbol;
          return !action.payload.includes(firstElement?.index);
        }
      );
      state.dataColumns = [...state.dataColumns];
    },
    setCleanedItemsColumnsAction(state) {
      state.dataRows = [...state.dataRows.map((item: ScreenerRowData) => item.slice(0, MIN_COLUMNS_CLEAR))];
      state.dataColumns = [...state.dataColumns];
    },
    setDataPanelsQueryId(state, action) {
      state.dataPanelsQueryId = action.payload;
    },
    changeTabAction(state) {
      state.dataRows = [];
      state.dataRowsRt = [];
      state.dataColumns = [];
      state.currentPage = [CURRENT_PAGE_DEFAULT.INIT_VALUE, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT];
      state.visibleItemsTable = [CURRENT_PAGE_DEFAULT.INIT_VALUE, CURRENT_PAGE_DEFAULT.SYMBOLS_AMOUNT];
    },
    resetCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setCounterSymbols(state, action) {
      state.counterSymbols = compareCounter(state, action.payload);
    },
    setScreenerRowTopIndex(state, action) {
      state.screenerRowTopIndex = action.payload;
    },
    setDataRowsIndexes(state, action) {
      state.dataRowsIndexes = action.payload;
    },
    updateDataRows(state, action) {
      const responseData = action.payload;

      for (let i = 0; i < responseData.length; i += 2) {
        const rowIndex = responseData[i];
        const columnIndex = responseData[i + 1];
        const value = responseData[i + 2];

        const row = state.dataRowsRt.find(
          (rowItem) => rowItem[0].index === rowIndex
        );

        if (row) {
          const column = state.dataColumns.find(
            (colItem:ColumnItem) => colItem?.index === columnIndex
          );

          if (column) {
            const columnIndexInRow = state.dataColumns.findIndex(
              (colItem:ColumnItem) => colItem?.index === columnIndex
            );
            if (columnIndexInRow !== -1) {
              row[columnIndexInRow] = value;
            }
          }
        }
      }
    },
    setHoveredSymbolIndex(state, action) {
      state.hoveredSymbolIndex = action.payload;
    },
  },
});

export const {
  setDataRows,
  setDataColumns,
  setIndustryDataRows,
  updateIndustryDataRows,
  setIndustryDataColumns,
  setIndustryOfSymbols,
  setIndustryQueryScreenerId,
  setQueryScreenerId,
  setVisibleItemsTableStore,
  setCleanedItemsForTarget,
  setCleanedItemsColumnsAction,
  setDataPanelsQueryId,
  changeTabAction,
  resetCurrentPage,
  setCounterSymbols,
  setScreenerRowTopIndex,
  setDataRowsIndexes,
  updateDataRows,
  setHoveredSymbolIndex
} = tableDataSlice.actions;

export default tableDataSlice;
