import PropTypes from 'prop-types';
import React from 'react';
import styles from '../sass/GlobalSearch.module.scss';

const SearchField = ({
  id,
  type,
  placeholder,
  value,
  handlerChange,
  inputRef,
}) => (
  <div className={`${styles.container} ${styles.active}`}>
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={handlerChange}
      autoComplete="off"
      ref={inputRef}
      autoFocus
    />
  </div>
); 

SearchField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  handlerChange: PropTypes.func,
  placeholder: PropTypes.string,
  inputRef: PropTypes.shape({}),
};

SearchField.defaultProps = {
  type: '',
  id: '',
  value: '',
  handlerChange: () => undefined,
  placeholder: '',
  inputRef: null,
};

export default React.memo(SearchField);
