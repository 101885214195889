const filters = {
  or: 'or',
  object: 'object',
  selectDate: 'selectDate',
  name: 'name',
  dropdown: 'dropdown',
  check: 'checkbox',
  range: 'range',
  select: 'select',
  multiselectSearch: 'multiselectSearch',
  date: 'date',
  period: 'period',
  greater: 'greater',
  less: 'less',
  main: 'main',
  minData: 0,
  maxData: 1000,
  copy: 'copy',
  copyLabel: 'copyLabel',
  columnId: 'columnId',
  value: 'value',
  favorite: 'favorite',
  number: 'number',
  startDateCount: 1856028800,
  newScreen: 'Create New Screen',
  filters: 'Filters',
  myScreen: 'My screen',
  selectedScreen: 'selectedScreen',
  selectedScreenId: 'selectedScreenId',
  selectedFilter: 'selectedFilter',
  editFilterButton: 'editFilterButton',
  editDisabledFilterButton: 'editDisabledFilterButton',
  saved: 'Saved',
  presets: 'Presets',
  currentScreenType: 'presets',
  mobileBanner: 'mobileBanner',
  folder: 'folder',
  folderScreener: 'folderScreener',
  folderDeeplist: 'folderDeeplist',
  userDashboard: 'userDashboard',
  userDashboardFolder: 'userDashboardFolder',
  userDashboardPresets: 'userDashboardPresets',
  userDashboardPresetsFolder: 'userDashboardPresetsFolder',
  screener: 'screener',
  columns: 'columns',
  columnsPreset: 'columnsPreset',
  columnSetFolderPreset: 'columnSetFolderPreset',
  deepList: 'watchList',
  error: 'error',
  enter: 'Enter',
  max: 'max',
  min: 'min',
  down: 'down',
  downRevert: 'downRevert',
  upRevert: 'upRevert',
  minMax: 'min/max',
  handle: 'handle',
  handleSecondHide: 'handleSecondHide',
  handleHideFirst: 'handleHideFirst',
  yes: 'Yes',
  no: 'No',
  emptyString: 0,
  emptyField: 'NaN',
  candle: 'candle',
  percentConvert: 100,
  yesNumber: 1,
  noNumber: 0,
  index: 'index',
  date1: 'date',
  subitems: 'subitems',
  initialBody: {
    greater: false,
    less: false,
    range: false
  },
  calendarSettings: [
    {
      name: 'offset',
      options: {
        offset: [20, 0],
      },
    },
    {
      name: 'computeStyles',
      options: {
        roundOffsets: ({ x, y }) => ({
          x: Math.round(x - 6),
          y: Math.round(y + 2),
        }),
      },
    },
  ],
  arrowLeft: 'ArrowLeft',
  arrowRight: 'ArrowRight',
  correctData: 1000,
  title: 'title',
  sym: 'sym',
  bool: 'bool',
  id: 'id',
  newPresets: 'Create New Preset',
  typeDatePeriod: 3,
  typeDatePeriodRange: 1,
  leftTab: 'leftTab',
  rightTab: 'rightTab',
  include: 'Include',
  exclude: 'Exclude',
  mapInclude: 0,
  mapExclude: 4,
  preset: 'presets',
  presetDeeplist: 'presetsWatchlist',
  watchlistPresets: 'watchlistPresets',
  presetsScreener: 'presetsScreener',
  presetFolder: 'preset folder',
  presetFolderDeeplist: 'presetFolderDeeplist',
  withoutLayout: 'withoutLayout',
  columnSet: 'columns',
  TYPE_PERIOD: 3,
  columnSetFolder: 'columns set folder',
  pasteEvent: 'insertFromPaste',
  dataPanel: 'dataPanel',
  dataPanelPresets: 'presetsDataPanel',
  dataPanelPresetFolder: 'presetFolderDataPanel',
  dataPanelFolder: 'folderDataPanel',
};

export const STYLES_FILTER = {
  FULL_WIDTH: 'fullWidth',
  INPUT_TEXT: 'inputText',
  CENTRAL_TEXT: 'centralText'
};

export const CORRECT_VALUE = -1;

export const VALUE_COLUMNS = {
  PERCENT: 'percent',
  CANDLE: 'candle'
};

export const STEP = {
  RANGE: 1,
  CANDLE: 0.01
};

export default filters;
