import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LAST_DASHBOARDS_TAB, LAST_TABLE_PATH, SELECTED_DATA_PANEL_ID } from '../../constants/storage';
import { removeLocalStorage } from '../storageWorks';
import { fillSpeedValuesHandler } from './useSpeedCheckup';
import $api from '../../http';
import {
  DASHBOARD_FOLDERS,
  DASHBOARD_ITEM_CHANGE_ORDER,
  DASHBOARD_ITEM_CHANGE_ORDER_FOLDER, DASHBOARD_ITEM_GET_SHARED_ITEM, DASHBOARD_ITEM_SHARE,
  DASHBOARD_ITEM_TYPE_DASHBOARDS, DASHBOARD_ITEM_TYPE_DASHBOARDS_PRESET,
  DASHBOARD_ITEM_TYPE_PRESETS,
  DASHBOARD_ITEM_TYPE_PRESETS_FOLDERS,
  DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST,
  DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST_FOLDERS,
  DASHBOARD_ITEM_TYPE_SCREENER,
  DASHBOARD_ITEMS,
} from '../../constants/paths';
import { errorMessageAction } from '../../store/account/actions';
import {
  getItemsId,
  getPresetsNewScreen,
  setColumnSetsId,
  setColumnSetUpdateFlag,
  setCreatedFolder,
  setNameColumnSets, setSelectedDataPanel,
  setSelectedScreen,
} from '../../store/newScreen/actions';
import constants from '../../constants/filters';
import {
  FIXED_COLUMNS, TEMPLATE_TYPE, VIEW_TYPES, TABLE_SCREEN_PATH
} from '../../constants/screener';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../store/auth/actions';
import { DASHBOARD_ITEM_EXISTS, DASHBOARD_ITEM_FOLDER_EXISTS } from '../../constants/responseStatuses';
import numberConstant from '../../constants/numberConstants';
import useWorkerItems from './useWorkerItems';
import {
  getBodyRequest, getTitle, getTitleCreateSuccess, typeHandler
} from '../helperNewScreenModal';
import { getActiveColumns, getAllColumns } from '../helperColumnsWorker';
import useWatchList from './useWatchList';
import useMultiChartWorker from '../../components/MultichartView/hooks/useMultiChartWorker';
import useGetColumnsList from './useGetColumnsList';
import useLastStateUpdateStore from './useLastStateUpdateStore';
import { DialogItemDataTypes } from '../../components/DashboardItemsDialog/types/constants';
import { setColumnsScreenerAction, setSharedDashboardItem } from '../../store/screener/actions';
import { SELECTED_TAB_ID } from '../../constants/tvWidgetOptions';
import { setModalType, setSelectedTab } from '../../store/watchlist/actions';
import useDataPanelItems from '../../components/DataPanel/hooks/useDataPanelItems';
import ROUTES_URLS from '../../constants/routesUrls';

const useNewScreen = (
  currentItemSelected,
  activeLastScreen = () => undefined,
  modalHandler = () => undefined,
  setHoveredPresetItem = () => undefined,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const favoritesFilter = useSelector((state) => state.screenerState.favoritesFilter, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);
  const itemsColumnSets = useSelector((state) => state.newScreenState.itemsColumnSets, shallowEqual);
  const columnSetIdItem = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const columnSets = useSelector((state) => state.newScreenState.columnSets, shallowEqual);
  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const openDataPanel = useSelector(
    (state) => state.accountState.userSettings.openDataPanel, shallowEqual
  );
  const lastPath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);

  const { updateStoreHandler } = useLastStateUpdateStore();
  const { removeWatchlist, getWatchLists, updateOrdersWatchlists } = useWatchList();
  const history = useHistory();

  const { getDataPanelItems } = useDataPanelItems();
  const [newFolderData, setNewFolderData] = useState({});

  const {
    getFolders,
    getScreens,
    getPresets,
    getPresetFolders,
    getDeeplistFolders,
    getDeeplistPreset,
    getDeeplistPresetFolders,
    getDataPanel,
    getDataPanelPreset,
    getDataPanelPresetFolders,
    getDataPanelFolders,
    getDashboardsFolders,
    getDashboardsPresetsFolders,
    getDashboardsItems,
    getDashboardsPresets,
    getColumnsPresetFolders,
    getColumnsPreset,
    getColumnsSet,
    getDeeplists
  } = useWorkerItems(activeLastScreen);

  const { getColumnSetsItems } = useGetColumnsList();
  const { refreshSymbolsMultiChart } = useMultiChartWorker(false);

  const successHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
    modalHandler();
  };

  const errorHandler = (title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: `The name ${title} already exists` }));
    dispatch(messageAction({ message: t('changeName') }));
    dispatch(changeNotificationTypeAction({ type: t('error') }));
  };

  const alertHandler = (message, title) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('alert') }));
  };

  const getItems = (selectedTabID = null) => {
    try {
      $api.get(`${DASHBOARD_FOLDERS}?${DASHBOARD_ITEM_TYPE_SCREENER}&page=0&limit=${numberConstant.limitItems}`)
        .then((resFolders) => {
          getFolders(resFolders.data.items, resFolders.data.count);
          const data = resFolders.data.items.map((itemFolder) => {
            return {
              id: itemFolder.id, items: [], title: itemFolder.name, type: 'folder'
            };
          });
          $api.get(`${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_SCREENER}&limit=${numberConstant.limitItems}`)
            .then((resItems) => {
              getScreens(resItems.data.items, resItems.data.count);
              resItems.data.items.forEach((item) => {
                if (item.id === (selectedTabID || selectedScreen?.id)) {
                  dispatch(setSelectedScreen(item));
                }

                const findIndex = data.findIndex((subItem) => subItem.id === item.folder?.id);
                if (findIndex !== -1) {
                  data[findIndex].items.push(item);
                } else {
                  data.push({ ...item, items: [] });
                }
              });
              activeLastScreen(data);
            });
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getItemId = (id) => {
    if (!id) return;
    try {
      $api.get(`${DASHBOARD_ITEMS}/${id}`).then((item) => {
        if (item?.data.type === TEMPLATE_TYPE.WATCHLIST || item?.data.type === TEMPLATE_TYPE.WATCHLIST_PRESETS) {
          dispatch(setSelectedTab(item.data));
          updateStoreHandler(SELECTED_TAB_ID, item.data.id);
        } else if (item?.data.type === TEMPLATE_TYPE.SCREENER) {
          dispatch(setSelectedScreen(item.data));
        } else if (item?.data.type === TEMPLATE_TYPE.DATA_PANEL) {
          dispatch(setSelectedDataPanel(item.data));
          updateStoreHandler(SELECTED_DATA_PANEL_ID, item?.data.id);
        } else if (item?.data.type === TEMPLATE_TYPE.DASHBOARDS) {
          updateStoreHandler(LAST_DASHBOARDS_TAB, item?.data.id);
        } else {
          dispatch(getItemsId(item.data));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getItemsPreset = (isDeeplistPreset) => {
    const secondFolderLink = isDeeplistPreset
      ? DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST_FOLDERS
      : DASHBOARD_ITEM_TYPE_PRESETS_FOLDERS;
    const secondLink = isDeeplistPreset
      ? DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST
      : DASHBOARD_ITEM_TYPE_PRESETS;
    try {
      $api.get(`${DASHBOARD_FOLDERS}?${secondFolderLink}&page=0&limit=${numberConstant.limitItems}`)
        .then((resFolders) => {
          if (isDeeplistPreset) getDeeplistPresetFolders((resFolders.data.items, resFolders.data.count));
          else getPresetFolders(resFolders.data.items, resFolders.data.count);
          const data = resFolders.data.items.map((itemFolder) => {
            return {
              id: itemFolder.id, items: [], title: itemFolder.name, type: 'folder'
            };
          });
          $api.get(`${DASHBOARD_ITEMS}?${secondLink}&page=0&limit=${numberConstant.limitItems}`)
            .then((resItems) => {
              if (isDeeplistPreset) getDeeplistPreset((resFolders.data.items, resFolders.data.count));
              else getPresets(resItems.data.items, resItems.data.count);
              resItems.data.items.forEach((item) => {
                const findIndex = data.findIndex((subItem) => subItem.id === item.folder?.id);
                if (findIndex !== -1) {
                  data[findIndex].items.push(item);
                } else {
                  data.push({ ...item, items: [] });
                }
              });
              dispatch(getPresetsNewScreen(data));
              activeLastScreen(data);
            });
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getDashboardsNew = (isPreset = false) => {
    const start = Date.now();
    try {
      const link = isPreset ? DASHBOARD_ITEM_TYPE_DASHBOARDS_PRESET : DASHBOARD_ITEM_TYPE_DASHBOARDS;
      $api.get(`${DASHBOARD_FOLDERS}?${link}&page=0&limit=${numberConstant.limitItems}`)
        .then((resFolders) => {
          if (isPreset) {
            getDashboardsPresetsFolders(resFolders.data.items, resFolders.data.count);
          } else getDashboardsFolders(resFolders.data.items, resFolders.data.count);
          $api.get(`${DASHBOARD_ITEMS}?${link}&page=0&limit=${numberConstant.limitItems}`)
            .then((res) => {
              if (isPreset) {
                getDashboardsPresets(resFolders.data.items, resFolders.data.count);
              } else getDashboardsItems(res.data.items, res.data.count);
              // speed check temporary data
              const end = Date.now();
              const speedCheck = end - start;
              fillSpeedValuesHandler('get_dashboards_data', speedCheck);
              // eslint-disable-next-line no-console
              console.log('=> get_dashboards_data', speedCheck);
            });
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const getData = (resolve) => {
    if (resolve?.data?.type === constants.columns) {
      getColumnSetsItems();
      getColumnSetsItems(true);
    } else if (resolve?.data?.type === constants.deepList) {
      getWatchLists(resolve.data.id);
      getItemsPreset(true);
    } else if (resolve?.data?.type === constants.userDashboard) {
      getDashboardsItems();
      getDashboardsPresets();
      getDashboardsFolders();
      getDashboardsPresetsFolders();
    } else {
      getItems(resolve.data.id);
      getItemsPreset();
    }
    if (openDataPanel) {
      getDataPanel();
      getDataPanelPreset();
      getDataPanelPresetFolders();
      getDataPanelFolders();
    } else {
      getItems();
      getItemsPreset();
    }
  };

  const getDataForFolders = (resolve) => {
    if (resolve?.data?.type === constants.columns) {
      getColumnsSet();
    } else if (resolve?.data?.type === constants.columnsPreset) {
      getColumnsPreset();
    } else if (resolve?.data?.type === constants.deepList) {
      getDeeplists();
    } else if (resolve?.data?.type === constants.presetDeeplist) {
      getDeeplistPreset();
    } else if (resolve?.data?.type === constants.userDashboard) {
      getDashboardsItems();
    } else if (resolve?.data?.type === constants.userDashboardPresets) {
      getDashboardsPresets();
    } else if (resolve?.data?.type === constants.screener) {
      getScreens();
    } else if (resolve?.data?.type === constants.presets) {
      getPresets();
    } else if (openDataPanel) {
      if (resolve?.data?.type === constants.dataPanel) {
        getDataPanel();
      } else if (resolve?.data?.type === constants.dataPanelPresets) {
        getDataPanelPreset();
      }
    } else {
      getScreens();
      getPresets();
    }
  };

  const moveToFolder = (id, folderId, details, skipRefresh) => {
    try {
      $api.patch(
        `${DASHBOARD_ITEMS}/${id}`,
        {
          folder: +folderId || null
        }
      ).then((resolve) => {
        if (!skipRefresh) {
          getDataForFolders(resolve);
        }
        if (details) {
          successHandler(t('folderMove', { folderName: details.folder, screenerName: details.screen }));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const removeSubItemFromFolder = (id, details, skipRefresh) => {
    try {
      $api.patch(
        `${DASHBOARD_ITEMS}/${id}`,
        { folder: null }
      ).then((resolve) => {
        if (!skipRefresh) {
          getData(resolve);
        }
        if (details) {
          successHandler(t('removeSubItemMsg', { folderName: details.folderName, subItem: details.subItem }));
        }
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const refreshItem = (type, selectedTabID = undefined) => {
    if (type === constants.presets) {
      getItemsPreset();
    } else if (type === constants.columns) {
      getColumnSetsItems();
      getColumnSetsItems(true);
    } else if (type === constants.deepList) {
      getWatchLists(selectedTabID);
    } else if (type === constants.presetDeeplist) {
      getItemsPreset(true);
    } else if (type === constants.userDashboard) {
      getDashboardsItems();
    } else if (type === constants.userDashboardPresets) {
      getDashboardsPresets();
    } else {
      getItems(selectedTabID);
    }
    getDataPanelItems(selectedTabID);
    getDataPanelPreset();
    getDataPanelPresetFolders();
  };

  const saveScreenIdToColumnSet = (data, columnSetId, currentPlace, itemId) => {
    if (columnSetId) {
      try {
        $api.patch(
          `${DASHBOARD_ITEMS}/${columnSetId}`, { data }
        ).then(() => {
          if (currentPlace === TEMPLATE_TYPE.WATCHLIST || currentPlace === TEMPLATE_TYPE.WATCHLIST_PRESETS) {
            getWatchLists(itemId);
          }
          if (currentPlace === TEMPLATE_TYPE.SCREENER) {
            getItems();
          }
          if (currentPlace === TEMPLATE_TYPE.PRESET) {
            getItemsPreset();
          }
        });
      } catch (e) {
        dispatch(errorMessageAction({ errorMessage: e.message }));
      }
    }
  };

  const requestItems = (nameScreen, filters, folderId, nameFolder, type, columns, description) => {
    const body = getBodyRequest(type, nameScreen, filters, favoritesFilter, columns, folderId, nameFolder, description);
    const labelSuccess = getTitleCreateSuccess(type, t);

    $api.post(
      DASHBOARD_ITEMS,
      body
    ).then((res) => {
      if (res.data.id && res.data.id !== 0 && type !== constants.columns) {
        getItemId(res.data.id);
      }
      if (lastPath === TABLE_SCREEN_PATH.DASHBOARDS && type === constants.deepList) {
        currentItemSelected(res.data);
      }
      successHandler(nameScreen, labelSuccess);
      refreshSymbolsMultiChart(true, res.data.data.filters);
      refreshItem(type, res.data.id);
      dispatch(setModalType(''));
      dispatch(setCreatedFolder(null));
    }).catch((error) => {
      if (error?.response?.data?.message?.message === DASHBOARD_ITEM_EXISTS) {
        errorHandler(nameScreen);
      }
    });
  };

  const createScreen = (nameScreen, filters, folderId, nameFolder, type, description) => {
    requestItems(nameScreen, filters, folderId, nameFolder, type, false, description);
  };

  const updateScreenFilters = (screenId, filters, data) => {
    if (screenId) {
      try {
        $api.patch(
          `${DASHBOARD_ITEMS}/${screenId}`,
          {
            data: {
              ...data,
              filters,
              favoriteFilters: favoritesFilter
            },
          }
        ).then(() => {
          getItems();
          getWatchLists();
          getItemsPreset();
          getItemsPreset(true);
          getItemId(screenId);
        });
      } catch (e) {
        dispatch(errorMessageAction({ errorMessage: e.message }));
      }
    } else {
      createScreen(constants.myScreen);
    }
  };

  const updateFiltersState = (screenId, data) => {
    if (screenId) {
      try {
        $api.patch(
          `${DASHBOARD_ITEMS}/${screenId}`, { data }
        ).then();
      } catch (e) {
        dispatch(errorMessageAction({ errorMessage: e.message }));
      }
    }
  };

  const updateColumnsSetState = (columnsSetId, selectedColumns, callback = () => {}) => {
    const columns = getAllColumns(itemsColumnSets);
    const currentItem = columns.find((el) => el.id === columnsSetId);
    const data = { ...currentItem?.data };
    data.selectedColumns = selectedColumns;
    if (columnsSetId) {
      try {
        $api.patch(
          `${DASHBOARD_ITEMS}/${columnsSetId}`,
          {
            data
          }
        ).then(() => {
          callback();
        });
        getColumnSetsItems();
        getColumnSetsItems(true);
      } catch (e) {
        dispatch(errorMessageAction({ errorMessage: e.message }));
      }
    }
  };

  const updateScreen = (screenId, name, data, description) => {
    if (screenId) {
      $api.patch(
        `${DASHBOARD_ITEMS}/${screenId}`,
        {
          title: name,
          data: {
            ...data,
            description
          }
        }
      ).then((res) => {
        successHandler(name, res.data.type === TEMPLATE_TYPE.COLUMN_SET ? t('columnsSetUpdate') : t('screenUpdate'));

        if (res.data.type === TEMPLATE_TYPE.WATCHLIST) {
          getWatchLists(screenId);
          getItemsPreset(true);
        }
        if (res.data.type === TEMPLATE_TYPE.SCREENER) {
          getItems();
          getItemsPreset();
        }
        getColumnSetsItems();
        getColumnSetsItems(true);
        getDataPanel();
        getDataPanelPreset();
        getDataPanelPresetFolders();
        getDataPanelFolders();
        if (res.data.id === selectedScreen.id) {
          dispatch(setSelectedScreen(res.data));
        }
        if (res.data.type === TEMPLATE_TYPE.COLUMN_SET && res.data.id === columnSetIdItem) {
          dispatch(setNameColumnSets(res.data.title));
        }
      }).catch((error) => {
        if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
          errorHandler(name);
        }
      });
    } else {
      createScreen(constants.myScreen);
    }
  };

  const saveSortingToMyScreen = (screenId, sortData) => {
    const data = { ...selectedScreen.data };
    data.sortData = sortData;

    if (screenId) {
      $api.patch(
        `${DASHBOARD_ITEMS}/${screenId}`,
        { data }
      ).then().catch((error) => {
        if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
          errorHandler(selectedScreen.name);
        }
      });
    }
  };

  const updateCurrentPreset = (id, title, saveColumns) => {
    saveColumns(id);
    dispatch(setColumnSetsId(id));
    dispatch(setNameColumnSets(title));
    dispatch(setColumnSetUpdateFlag(false));
  };

  const duplicatePreset = (titlePreset, dataPreset, folderId, type, saveColumns) => {
    const typeScreen = typeHandler(type);
    $api.post(
      DASHBOARD_ITEMS,
      {
        title: titlePreset,
        type: typeScreen,
        viewType: VIEW_TYPES.TABLE,
        data: dataPreset,
        isFavorite: false,
      }
    ).then((res) => {
      if (type !== TEMPLATE_TYPE.COLUMN_SET) {
        setHoveredPresetItem(-1);
        getItemId(res.data.id);
        dispatch(setModalType(''));
        dispatch(setCreatedFolder(null));
      }
      if (folderId && folderId !== -1) {
        $api.patch(
          `${DASHBOARD_ITEMS}/${res.data.id}`,
          {
            folder: folderId
          }
        ).then(() => {
          getData(res);
          if (type === TEMPLATE_TYPE.COLUMN_SET) {
            updateCurrentPreset(res.data?.id, res.data?.title, saveColumns);
          }
        });
      } else {
        getData(res);
        if (type === TEMPLATE_TYPE.COLUMN_SET) {
          updateCurrentPreset(res.data?.id, res.data?.title, saveColumns);
        }
      }
      successHandler(titlePreset, getTitleCreateSuccess(type, t));
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
        errorHandler(titlePreset);
      }
    });
  };

  const removeScreen = (id, name = '', itemType) => {
    try {
      switch (itemType) {
        case DialogItemDataTypes.SCREENER:
          $api.delete(`${DASHBOARD_ITEMS}/${id}`).then(() => {
            getItems();
            getWatchLists();
            getItemsPreset();
            getItemsPreset(true);
            getColumnSetsItems();
            getColumnSetsItems(true);
            successHandler(name, t('screenDelete'));
            refreshSymbolsMultiChart(true);
          });
          currentItemSelected();
          break;
        case DialogItemDataTypes.DEEP_LIST:
          removeWatchlist(id, name);
          getItemsPreset(true);
          currentItemSelected();
          break;
        default:
          $api.delete(`${DASHBOARD_ITEMS}/${id}`).then(() => {
            getItems();
            getWatchLists();
            getItemsPreset();
            getItemsPreset(true);
            getColumnSetsItems();
            getColumnSetsItems(true);
            getDataPanel();
            getDataPanelPreset();
            getDataPanelPresetFolders();
            getDataPanelFolders();
            successHandler(name,
              itemType === DialogItemDataTypes.DATA_PANEL ? t('dataPanelSuccessDelete') : t('screenDelete'));
            refreshSymbolsMultiChart(true);
          });
          currentItemSelected();
      }
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const restoreToRecommendedHandler = (id) => {
    if (id === columnSetIdItem) {
      const recommended = columnSets.find((item) => item.isDefault);

      if (recommended) {
        const columns = getActiveColumns(columnsList, [...FIXED_COLUMNS, ...recommended?.data.selectedColumns], []);
        dispatch(setColumnSetsId(recommended.id));
        dispatch(setColumnsScreenerAction({ data: columns }));
        dispatch(setNameColumnSets(t('recommended')));
      }
    }
  };

  const removeColumnsSet = (id, title) => {
    try {
      $api.delete(`${DASHBOARD_ITEMS}/${id}`).then(() => {
        getColumnSetsItems();
        getColumnSetsItems(true);
        restoreToRecommendedHandler(id);
        successHandler(title, t('columnsSetDelete'));
      });
      currentItemSelected();
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const removeFolder = (id, name = '') => {
    try {
      $api.delete(`${DASHBOARD_FOLDERS}/${id}`).then(() => {
        getItems();
        getWatchLists();
        getItemsPreset();
        getItemsPreset(true);
        getDataPanel();
        getDataPanelPreset();
        getColumnSetsItems();
        getColumnSetsItems(true);
        getDataPanelPresetFolders();
        getDataPanelFolders();
        getDashboardsNew();
        getDashboardsNew(true);
        successHandler(name, t('folderDelete'));
      });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const updateFolder = (id, name) => {
    $api.patch(
      `${DASHBOARD_FOLDERS}/${id}`,
      {
        name
      }
    ).then((resolve) => {
      getData(resolve);
      successHandler(name, t('folderUpdate'));
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_FOLDER_EXISTS) {
        errorHandler(name);
      }
    });
  };

  const updateFavoriteFlag = (id, favoriteStatus) => {
    try {
      $api.patch(
        `${DASHBOARD_ITEMS}/${id}`,
        {
          isFavorite: favoriteStatus
        }
      ).then(() => getItems());
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  const createPresets = (nameScreen, filters, folderId = -1, nameFolder = '') => {
    requestItems(nameScreen, filters, folderId, nameFolder, constants.presets);
  };

  const hideItem = useCallback(
    (screenId, flag) => {
      if (screenId) {
        $api.patch(
          `${DASHBOARD_ITEMS}/${screenId}`,
          {
            isVisible: flag,
          }
        ).then((resolve) => {
          getData(resolve);
        }).catch((error) => {
          if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
            errorHandler(screenId);
          }
        });
      } else {
        createScreen(constants.myScreen);
      }
    },
    [],
  );

  const createFolder = (name, type) => {
    $api.post(
      DASHBOARD_FOLDERS,
      {
        name,
        type
      }
    ).then((resolve) => {
      getData(resolve);
      setNewFolderData(resolve.data);
      successHandler(name, t('folderCreated'));
    }).catch((error) => {
      if (error.response.data.message === DASHBOARD_ITEM_FOLDER_EXISTS) {
        errorHandler(name);
      }
    });
  };

  const createFolderAsync = async (name, type) => {
    return $api.post(
      DASHBOARD_FOLDERS,
      {
        name,
        type
      }
    );
  };

  const updateFolderAsync = async (folderItem) => {
    return $api.patch(
      `${DASHBOARD_FOLDERS}/${folderItem?.id}`,
      {
        name: folderItem?.title
      }
    );
  };

  const removeColumnSetFromFolder = (columnSetId, nameFolder) => {
    if (columnSetId) {
      $api.patch(
        `${DASHBOARD_ITEMS}/${columnSetId}`,
        {
          folder: null,
        }
      ).then(() => {
        successHandler(nameFolder, t('screenUpdate'));
        getColumnSetsItems();
        getColumnSetsItems(true);
      }).catch((error) => {
        if (error.response.data.message === DASHBOARD_ITEM_EXISTS) {
          errorHandler(nameFolder);
        }
      });
    }
  };

  const saveOrder = (itemId, placeAfterId, itemType) => {
    $api.post(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_CHANGE_ORDER}`,
      {
        itemId,
        placeAfterId
      }).then(() => {
      if (itemType === constants.deepList || itemType === DialogItemDataTypes.DEEP_LIST) {
        updateOrdersWatchlists();
      } else if (itemType === constants.watchlistPresets) {
        getDeeplistPreset();
        getDeeplistPresetFolders();
      } else if (itemType === constants.userDashboard) {
        getDashboardsItems();
      } else if (itemType === constants.userDashboardPresets) {
        getDashboardsPresets();
      } else if (itemType === constants.dataPanel) {
        getDataPanel();
      } else if (itemType === constants.columns) {
        getColumnSetsItems();
      } else if (itemType === constants.columnsPreset) {
        getColumnSetsItems(true);
      } else if (itemType === constants.dataPanelPresets) {
        getDataPanelPreset();
      } else {
        getScreens();
        getDataPanelPresetFolders();
        getDataPanelFolders();
      }
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    });
  };

  const saveOrderFolder = (folderId, placeAfterId, itemType) => {
    $api.post(`${DASHBOARD_FOLDERS}/${DASHBOARD_ITEM_CHANGE_ORDER_FOLDER}`,
      {
        folderId,
        placeAfterId
      }).then(() => {
      if (itemType === DialogItemDataTypes.DEEP_LIST) {
        getDeeplistFolders();
      } else if (itemType === DialogItemDataTypes.COLUMN_SET) {
        getColumnsPresetFolders();
        getColumnsPreset();
      } else {
        getFolders();
      }
      getDashboardsFolders();
      getDashboardsPresetsFolders();
      getDataPanel();
      getDataPanelPreset();
      getDataPanelPresetFolders();
      getDataPanelFolders();
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    });
  };

  const shareDashboardItem = (id, itemType, itemName) => {
    $api.get(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_SHARE}/${id}`).then((resolve) => {
      const title = getTitle(itemType);
      const linkId = resolve.data?.split(':')[resolve.data?.split(':').length - 1];
      const copiedLink = `${process.env.REACT_APP_PUB_APP_URL}share-item/${linkId}`;
      const textField = document.createElement('textarea');
      textField.innerText = copiedLink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      successHandler(itemName, t('linkCopied', { itemType: title }));
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    });
  };

  const getSharedDashboardItem = (hash) => {
    $api.get(`${DASHBOARD_ITEMS}/${DASHBOARD_ITEM_GET_SHARED_ITEM}/${hash}`).then((resolve) => {
      dispatch(setSharedDashboardItem(resolve.data));
      removeLocalStorage('sharedItemHash');
      switch (resolve.data.type) {
        case TEMPLATE_TYPE.WATCHLIST: {
          history.push(ROUTES_URLS.TABLE);
          updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.DEEPLIST);
          break;
        }
        case TEMPLATE_TYPE.SCREENER: {
          history.push(ROUTES_URLS.TABLE);
          updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.SCREENER);
          break;
        }
        default: {
          history.push(ROUTES_URLS.TABLE);
          updateStoreHandler(LAST_TABLE_PATH, TABLE_SCREEN_PATH.SCREENER);
          break;
        }
      }
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    });
  };

  return {
    createFolder,
    removeFolder,
    updateFolder,
    getItemsPreset,
    getItems,
    createScreen,
    removeScreen,
    removeColumnsSet,
    duplicatePreset,
    moveToFolder,
    updateFavoriteFlag,
    updateScreen,
    getItemId,
    updateScreenFilters,
    updateFiltersState,
    createPresets,
    hideItem,
    newFolderData,
    getColumnSetsItems,
    updateColumnsSetState,
    removeColumnSetFromFolder,
    saveScreenIdToColumnSet,
    removeSubItemFromFolder,
    alertHandler,
    successHandler,
    errorHandler,
    saveSortingToMyScreen,
    getDashboardsNew,
    createFolderAsync,
    updateFolderAsync,
    saveOrder,
    saveOrderFolder,
    shareDashboardItem,
    getSharedDashboardItem
  };
};

export default useNewScreen;
