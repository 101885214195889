import React, { useEffect, useRef, useState } from 'react';

import Scroll from 'react-scroll';
import { ReactComponent as SelectArrow } from '../../assets/images/icons/selectArrow.svg';
import TextField from '../TextField/TextField';
import styles from './sass/SelectGroupDropFilter.module.scss';
import { findIndexItem } from '../../utils/helpers';
import constants from '../../constants/filters';
import ListOptionsFilters from './ListOptionsFilters';
import { TMouseEvent } from '../../utils/handleOutsideClick';
import { TCommonItem } from '../FilterScreen/Types';

interface ISelectGroupDropFilter {
  data: TCommonItem[];
  handlerSelectFilter: (filterItem: TCommonItem) => void;
  setViewDropList: (value: boolean) => void;
  viewDropList: boolean;
  selectFilter: TCommonItem;
}

const SelectGroupDropFilter = ({
  data, handlerSelectFilter, setViewDropList, viewDropList, selectFilter
}: ISelectGroupDropFilter): React.ReactElement => {
  const { scroller } = Scroll;
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<TCommonItem>({} as TCommonItem);
  const [selectedItem, setSelectedItem] = useState<TCommonItem>({} as TCommonItem);
  const [searchValue, setSearchValue] = useState<string>('');

  const dropdown = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (Object.keys(selectFilter).length > 0) {
      setSelectedFilter(selectFilter);
    }
  }, [selectFilter]);

  useEffect(() => {
    const indexItem = findIndexItem(data, constants.name, selectedGroup);
    if (indexItem !== -1) {
      setSelectedItem(data[indexItem]);
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedItem?.data) {
      const indexItem = findIndexItem(selectedItem?.data, constants.name, searchValue);
      if (indexItem !== -1) {
        scroller.scrollTo(`${selectedItem.data[indexItem].id}`, {
          duration: 1500,
          delay: 100,
          smooth: true,
          containerId: `${selectedGroup}`,
          offset: 0,
        });
      }
    }
  }, [searchValue]);

  const handlerSelectedGroup = (itemName: string): void => {
    if (itemName === selectedGroup) {
      setSelectedGroup('');
    } else {
      setSelectedGroup(itemName);
    }
  };

  const searchValueHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);
  };

  const handlerSelectedFilter = (item: TCommonItem): void => {
    setSelectedFilter(item);
  };

  const outsideClickHandler = (event: TMouseEvent) => {
    if (dropdown.current && !dropdown.current.contains(event.target as HTMLDivElement)) {
      setViewDropList(false);
      setSearchValue('');
      setSelectedGroup('');
    }
  };

  useEffect(() => {
    handlerSelectFilter(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    document.addEventListener('click', outsideClickHandler as EventListener);
    return () => {
      document.removeEventListener('click', outsideClickHandler as EventListener);
      setViewDropList(false);
      setSearchValue('');
      setSelectedGroup('');
    };
  }, []);

  return (
    <div className={styles.container} ref={dropdown}>
      tut
      <div
        className={`${styles.containerWrapper} ${viewDropList ? styles.dropOpen : ''}`}
        onClick={() => setViewDropList(!viewDropList)}
        onKeyDown={() => {}}
        role="button"
        aria-hidden="true"
        tabIndex={0}
      >
        <TextField styleType="selectedFilters" text={selectedFilter?.name || ''} />
        <SelectArrow />
      </div>

      {viewDropList && (
        <ListOptionsFilters
          data={data}
          handlerSelectedFilter={handlerSelectedFilter}
          handlerSelectedGroup={handlerSelectedGroup}
          searchValue={searchValue}
          searchValueHandler={searchValueHandler}
          selectedFilter={selectedFilter}
          selectedGroup={selectedGroup}
        />
      )}
    </div>
  );
};

export default SelectGroupDropFilter;
