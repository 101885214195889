import { configureStore } from '@reduxjs/toolkit';
import reducers from './translates/reducers';
import authReducer from './auth/reducer';
import screenerReducer from './screener/reducer';
import accountReducer from './account/reducer';
import multiChartReducer from './multiChart/reducer';
import loadingProgressReducer from './loadingProgress/reducer';
import newScreenReducer from './newScreen/reducer';
import filtersState from './filters/slice';
import watchlistReducer from './watchlist/reducer';
import statsChartReducer from './statsChart/reducer';
import twitterSlice from './twitter/slice';
import alertSlice from './alerts/slice';
import selectSymbol from './selectSymbol/slice';
import dashboardsSlice from './dashboards/slice';
import tableDataSliclice from './tableData/slice';
import educationSlice from './education/slice';

const store = configureStore({
  reducer: {
    translates: reducers,
    authState: authReducer,
    screenerState: screenerReducer,
    accountState: accountReducer,
    multiChartState: multiChartReducer,
    newScreenState: newScreenReducer,
    filtersState: filtersState.reducer,
    watchlistState: watchlistReducer,
    statsChartState: statsChartReducer,
    alertsState: alertSlice.reducer,
    loadingState: loadingProgressReducer,
    twitterState: twitterSlice.reducer,
    selectSymbolsState: selectSymbol.reducer,
    dashboardsState: dashboardsSlice.reducer,
    tableDataState: tableDataSliclice.reducer,
    educationState: educationSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  })
});

export default store;
