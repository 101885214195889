import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/SwitcherItem.module.scss';
import TextField from '../TextField/TextField';
import SwitchButton from '../SwitchButton/SwitchButton';

const SwitcherItem = ({
  text, Icon, active, setActive, id
}) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <Icon />
      <TextField text={text} styleType="textModal" />
    </div>
    <SwitchButton checked={active} onChange={() => setActive(id)} />
  </div>
);

SwitcherItem.propTypes = {
  active: PropTypes.bool,
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  Icon: PropTypes.shape({}).isRequired,
  setActive: PropTypes.func.isRequired
};

SwitcherItem.defaultProps = {
  active: true,
};

export default React.memo(SwitcherItem);
