import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import TutorialCard from './TutorialCard';
import { ITutorials } from './types/EducationComponentsInterfaces';
import useTutorials from './hooks/useTutorials';
import { TUTORIAL_SLUG, TUTORIALS } from './types/EducationComponentsEnum';
import SearchBlock from './SearchBlock';
import CategoryFilter from './CategoryFilter';
import SortBlock from './SortBlock';
import LevelFilter from './LevelFilter';

const Tutorials = ({ tutorialParams, setTutorialParams }: ITutorials): React.ReactElement => {
  const {
    tutorialsList, ref, tutorialCategories, levels
  } = useTutorials(tutorialParams, setTutorialParams);

  return (
    <div className={styles.educationWrapper} ref={ref}>
      <div className={styles.dropdowns}>
        <div className={styles.leftBlock}>
          <SearchBlock
            activeTab={TUTORIALS}
            params={tutorialParams}
            setParams={setTutorialParams}
          />
        </div>

        <div className={styles.rightBlock}>
          <CategoryFilter
            params={tutorialParams}
            setParams={setTutorialParams}
            categories={tutorialCategories}
          />
          <LevelFilter
            params={tutorialParams}
            setParams={setTutorialParams}
            categories={levels}
          />
          <SortBlock
            params={tutorialParams}
            setParams={setTutorialParams}
          />
        </div>
      </div>

      <div className={`${styles.cards} ${tutorialParams.viewMode && styles.cards_list}`}>
        {tutorialsList.posts?.map((item) => (
          <TutorialCard
            key={item.id}
            title={item.title}
            date={item.publish_date}
            excerpt={item.excerpt}
            videoSrc={item.video_src}
            posterSrc={item.poster_src}
            categories={item.categories.filter((value) => value.toLowerCase() !== TUTORIAL_SLUG)}
            isGridView={tutorialParams.viewMode === 0}
            tutorialCategories={tutorialCategories}
          />
        ))}
      </div>
    </div>
  );
};
export default Tutorials;
