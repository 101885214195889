import React from 'react';
import styles from './sass/Education.module.scss';
import EducationHeader from '../../components/EducationComponents/EducationHeader';
import { APIParams, ActiveTab } from '../../components/EducationComponents/types/EducationComponentsTypes';
import Articles from '../../components/EducationComponents/Articles';
import Tutorials from '../../components/EducationComponents/Tutorials';
import { ReactComponent as ArticlesIcon } from '../../assets/images/icons/articles.svg';
import { ReactComponent as TutorialsIcon } from '../../assets/images/icons/tutorials.svg';
import { ReactComponent as EventsIcon } from '../../assets/images/icons/events.svg';
import useEducation from '../../components/EducationComponents/hooks/useEducation';
import { ARTICLES, EVENTS, TUTORIALS } from '../../components/EducationComponents/types/EducationComponentsEnum';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import Events from '../../components/EducationComponents/Events';
import ClientOnlyPortal from '../../components/ClientOnlyPortal/ClientOnlyPortal';
import WatchListModals from '../../components/WatchListModals/WatchListModals';
import { MODAL_TITLES } from '../../constants/screener';

const Tabs = [
  {
    title: ARTICLES as ActiveTab,
    component: (
      articleParams: APIParams,
      setArticleParams: React.Dispatch<React.SetStateAction<APIParams>>
    ) => <Articles articleParams={articleParams} setArticleParams={setArticleParams} />,
    Icon: ArticlesIcon
  },
  {
    title: TUTORIALS as ActiveTab,
    component: (
      tutorialParams: APIParams,
      setTutorialParams: React.Dispatch<React.SetStateAction<APIParams>>
    ) => <Tutorials tutorialParams={tutorialParams} setTutorialParams={setTutorialParams} />,
    Icon: TutorialsIcon
  },
  {
    title: EVENTS as ActiveTab,
    component: (
      eventsParams: APIParams,
      setEventsParams: React.Dispatch<React.SetStateAction<APIParams>>
    ) => <Events eventsParams={eventsParams} setEventsParams={setEventsParams} />,
    Icon: EventsIcon
  },
];

const Education = (): React.ReactElement => {
  const {
    articleParams,
    setArticleParams,
    tutorialParams,
    setTutorialParams,
    eventsParams,
    setEventsParams,
    activeTab,
    openConfirmModal,
    onTabChange,
    modalTypeWatchList,
  } = useEducation();

  let activeParams;
  let setActiveParams;

  if (activeTab === ARTICLES) {
    activeParams = articleParams;
    setActiveParams = setArticleParams;
  } else if (activeTab === TUTORIALS) {
    activeParams = tutorialParams;
    setActiveParams = setTutorialParams;
  } else {
    activeParams = eventsParams;
    setActiveParams = setEventsParams;
  }

  return (
    <div className={styles.container}>
      {modalTypeWatchList && modalTypeWatchList !== MODAL_TITLES.CREATE && (
        <ClientOnlyPortal selector="#watchlist_modals">
          <WatchListModals />
        </ClientOnlyPortal>
      )}
      <EducationHeader
        tabs={Tabs}
        setActiveTab={onTabChange}
        activeTab={activeTab}
      />
      {Tabs.find((tab) => tab.title === (activeTab))?.component(activeParams, setActiveParams)}
      {openConfirmModal && <ConfirmModal />}
    </div>
  );
};

export default Education;
