import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import styles from '../sass/ScreenerTable.module.scss';
import Checkbox from '../../Checkbox/Checkbox';
import SwitchButton from '../../SwitchButton/SwitchButton';
import { getColumnStyle } from '../../../utils/helpers';
import useAlerts from '../../ChartContainer/hooks/useAlerts';

const StatusAlertComponent = ({
  item,
  name,
  checked,
  changeHandler,
  size,
  columnIndex,
}) => {
  const [activeStatus, setActiveStatus] = useState(item?.isActive);
  const { toggleAlertStatus } = useAlerts();

  const changeAlert = (value) => {
    setActiveStatus(value);
    toggleAlertStatus(item?.id, value);
  };

  useEffect(() => {
    if (item?.isActive === activeStatus) return;
    setActiveStatus(item?.isActive);
  }, [item]);

  return (
    <div
      className={`${styles.symbol} ${styles.screenerTableCell} ${styles.status} columnResize_${columnIndex}`}
      key={name}
      style={getColumnStyle(size, null, null, { index: columnIndex })}
    >
      <div className={styles.wrapper}>
        <Checkbox
          id={`${item?.id}`}
          checked={checked}
          changeHandler={() => changeHandler(item)}
        />
        <SwitchButton checked={activeStatus} onChange={debounce(changeAlert, 100)} />
      </div>
    </div>
  );
};

StatusAlertComponent.propTypes = {
  item: PropTypes.shape({
    isActive: PropTypes.bool,
    id: PropTypes.number
  }).isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  size: PropTypes.shape({ minWidth: PropTypes.number }),
  columnIndex: PropTypes.number,
};

StatusAlertComponent.defaultProps = {
  size: {
    maxWidth: 200,
    minWidth: 160,
    startWidth: 160
  },
  columnIndex: 0,
};

export default StatusAlertComponent;
