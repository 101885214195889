import React from 'react';

import styles from './sass/FilterGroupEmaSma.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import NoOptions from '../NoOptions/NoOptions';
import { TValueItem } from '../FilterScreen/Types';

interface ICheckboxPanel {
  setCheck: (value: string) => void;
  buttons: TValueItem[];
  noOptionsAvailable: string;
}

const CheckboxPanel = ({ setCheck, buttons, noOptionsAvailable }: ICheckboxPanel): React.ReactElement => (
  <div className={styles.checkboxContainer}>
    {buttons?.length > 0 ? buttons.map((item, index) => (
      <DefaultButton
        key={[item.title, index].join('_')}
        buttonClass={`${item.checked ? 'activeCheckButton' : 'checkboxButton'}`}
        handleClick={() => { setCheck(item.value as string); }}
      >
        {item.title}
      </DefaultButton>
    )) : (
      <NoOptions text={noOptionsAvailable} />
    ) }
  </div>
);

export default CheckboxPanel;
