const colors = {
  offHandleColor: '#ffffff',
  offColor: '#E2E6ED',
  onColor: '#3A75E8',
  darkOnColor: '#5B6781',
  lightOrangeList: '#FFFAF5',
  lightRedList: '#FFF7F9',
  lightPurpleList: '#F9F8FE',
  lightBlueList: '#F2FDFF',
  lightGreenList: '#F5FDFC',
  darkOrangeList: '#C2A07D',
  darkRedList: '#9C6875',
  darkPurpleList: '#8D87AB',
  darkBlueList: '#5C9EAA',
  darkGreenList: '#79B1AA',
};

export default colors;
