import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ChartContainer from '../../../components/ChartContainer/ChartContainer';
import styles from '../sass/TableScreener.module.scss';

import GraphicStatsChart from './GraphicStatsChart';

const GraphicView = ({
  profile,
  hideEvents,
  isAlertTable,
  watchList,
  screenerLayout,
}) => {
  const graphicView = useSelector((state) => state.screenerState.graphicView, shallowEqual);
  const statsChartData = useSelector((state) => state.statsChartState.lineChart, shallowEqual);
  const [updateInstance, setUpdateInstance] = useState(true);
  const renderCondition = graphicView && profile && updateInstance;

  return (
    <>
      <div
        className={`${styles.graphics} ${hideEvents && styles.stopEvents}`}
      >
        <div
          className={styles.widget}
        >
          {renderCondition && (
            <ChartContainer
              isAlertTable={isAlertTable}
              watchList={watchList}
              setUpdateInstance={setUpdateInstance}
            />
          )}
        </div>
        <GraphicStatsChart
          statsChartData={statsChartData}
          showStats={screenerLayout?.statsOpen}
        />
      </div>
    </>
  );
};

GraphicView.propTypes = {
  isAlertTable: PropTypes.bool,
  watchList: PropTypes.bool,
  profile: PropTypes.shape({
    role: PropTypes.string
  }),
  hideEvents: PropTypes.bool,
  screenerLayout: PropTypes.shape({ statsOpen: PropTypes.bool }),
};

GraphicView.defaultProps = {
  isAlertTable: false,
  watchList: false,
  profile: {},
  hideEvents: false,
  screenerLayout: {}
};

export default GraphicView;
