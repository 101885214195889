import React, { useState } from 'react';
import styles from './sass/EducationComponents.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { ISearchBlock } from './types/EducationComponentsInterfaces';
import { ARTICLES, EVENTS, TUTORIALS } from './types/EducationComponentsEnum';
import useSearch from './hooks/useSearch';

const SearchBlock = ({
  activeTab,
  params,
  setParams
}: ISearchBlock): React.ReactElement => {
  const { handleSearch, handleClear } = useSearch(params, setParams);
  const [value, setValue] = useState({
    [ARTICLES]: params.search || '',
    [TUTORIALS]: params.search || '',
    [EVENTS]: params.search || ''
  });

  return (
    <div className={styles.educationHeaderSearchBlock}>
      <SearchIcon />
      <input
        value={value[activeTab]}
        type="text"
        className={styles.searchInput}
        placeholder={`Search ${activeTab.toLowerCase()}`}
        onChange={(e) => {
          setValue((prev) => ({ ...prev, [activeTab]: e.target.value }));
          handleSearch(e);
        }}
      />
      {value[activeTab] && (
        <CloseIcon onClick={() => {
          setValue((prev) => ({ ...prev, [activeTab]: '' }));
          handleClear();
        }}
        />
      )}
    </div>
  );
};

export default SearchBlock;
