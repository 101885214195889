import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from '../../sass/PickColumnWrapper.module.scss';
import { ReactComponent as ChevronBlue } from '../../../../assets/images/icons/screener/chevronBlue.svg';
import CounterComponent from '../../../CounterComponent/CounterComponent';
import { DEFAULT_ALERT_GROUP } from '../../../../constants/alerts';
import { checkRoleUser } from '../../../../utils/userHelper';
import { SUBSCRIPTION_TYPE } from '../../../../constants/screener';

const ColumnsListGroup = ({
  title, children, searchActiveGroup, setCurrentGroup, disabled, mainCounter, groupId, openedGroupHandler
}) => {
  const { t } = useTranslation();
  const [activeGroup, setActiveGroup] = useState(false);
  const [isColumnSetPreset, setIsColumnSetPreset] = useState(false);
  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns);
  const alertColumns = useSelector((state) => state.accountState.userSettings.alertColumns);
  const columnSetsPreset = useSelector((state) => state.newScreenState.columnSetsPreset, shallowEqual);
  const columnSetId = useSelector((state) => state.newScreenState.columnSetId, shallowEqual);
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  useEffect(() => {
    if (searchActiveGroup) {
      setActiveGroup(true);
    }
  }, [searchActiveGroup]);

  const selectedColumnsInGroupHandler = () => {
    if (groupId === DEFAULT_ALERT_GROUP.groupId) {
      return alertColumns.length;
    }
    const selectedCounter = selectedColumns.filter((item) => item.group === groupId).length;
    const hasTopIndustry = selectedColumns.find((item) => item.id === 258);

    let returnCounter = selectedCounter;
    if (title === t('general')) returnCounter -= 2;
    if (title === t('technicals') && hasTopIndustry) returnCounter -= 1;

    return returnCounter;
  };

  const toggleGroup = () => {
    setCurrentGroup(-1);
    openedGroupHandler(groupId);
    setActiveGroup(!activeGroup);
  };

  useEffect(() => {
    const columnSetsPresetsIds = columnSetsPreset.map((column) => column?.id);
    setIsColumnSetPreset(!adminRole ? columnSetsPresetsIds.includes(columnSetId) : false);
  }, [columnSetId]);

  return (
    <div className={`
      ${styles.group}
      ${activeGroup || searchActiveGroup ? styles.active : styles.unActive}
      ${(disabled || isColumnSetPreset) ? styles.disabled : ''}`}
    >
      <div
        aria-hidden="true"
        className={styles.title}
        onClick={toggleGroup}
        role="button"
        tabIndex="0"
      >
        {title}
        <CounterComponent mainCounter={mainCounter} selectedCounter={selectedColumnsInGroupHandler()} />
        <ChevronBlue className={styles.chevron} />
      </div>
      <div className={styles.content} style={activeGroup || searchActiveGroup ? { maxHeight: '300px' } : {}}>
        {children}
      </div>
    </div>
  );
};

ColumnsListGroup.propTypes = {
  groupId: PropTypes.number.isRequired,
  mainCounter: PropTypes.number.isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
  searchActiveGroup: PropTypes.bool,
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  openedGroupHandler: PropTypes.func.isRequired,
};

ColumnsListGroup.defaultProps = {
  searchActiveGroup: false,
  children: (<div className={styles.empty} />),
  disabled: false,
};

export default ColumnsListGroup;
