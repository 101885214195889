import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './sass/AlertsList.module.scss';
import AlertsSelect from './components/AlersSelect';
import { DEFAULT_LIMIT, selectOptionMock } from '../../constants/alerts';
import DefaultButton from '../DefaultButton/DefaultButton';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/closeBlack.svg';
import { toggleAlertNotificationListDropdownVisible } from '../../store/alerts/slice';
import { getFromLocalStorage } from '../../utils/storageWorks';
import { ACTIVE_LIMITATION } from '../../constants/storage';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';

const AlertsListHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [
    activeSelectId, setActiveSelectId
  ] = useState(+JSON.parse(getFromLocalStorage(ACTIVE_LIMITATION)) || DEFAULT_LIMIT);

  const closeHandler = () => {
    dispatch(toggleAlertNotificationListDropdownVisible());
  };

  return (
    <ButtonListenerWrapper handleCancel={closeHandler} handleConfirm={() => {}}>
      <div className={styles.list_header}>
        <h2>{t('alerts')}</h2>
        <AlertsSelect
          selectOption={selectOptionMock}
          activeId={activeSelectId}
          setActiveId={setActiveSelectId}
        />
        <DefaultButton handleClick={closeHandler}>
          <CloseIcon />
        </DefaultButton>
      </div>
    </ButtonListenerWrapper>
  );
};

export default AlertsListHeader;
