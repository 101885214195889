import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './sass/Header.module.scss';
import DefaultLink from '../DefaultLink/DefaultLink';
import { ReactComponent as BlueArrowIcon } from '../../assets/images/icons/arrow.svg';

const NotificationDropdown = ({ notificationsData, closeDropDawn }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.dropdown} ${styles.notifications_dropdown}`}
      onMouseLeave={closeDropDawn}
      role="button"
      aria-hidden="true"
      tabIndex={0}
    >
      <h2>
        {t('whatsNew')}
        <span>
          {t('new')}
          {' '}
          <span className={styles.redDot}>•</span>
          {' '}
          {notificationsData.filter((item) => item.new).length}
        </span>
      </h2>
      <div>
        {
          notificationsData.map((notification, index) => (
            <div
              className={`${styles.notification} ${notification.new ? styles.unread : ''}`}
              key={[notification.title, index].join('_')}
            >
              <img
                className={styles.notification_img}
                src={notification.image}
                alt=""
              />
              <div className={styles.notification_content}>
                <h3 className={styles.notification_title}>
                  {notification.title}
                </h3>
                <p className={styles.notification_text}>
                  {notification.shortContent}
                </p>
              </div>
            </div>
          ))
        }
        <div className={styles.allNotification}>
          <DefaultLink
            href="#"
            className={styles.allNotificationLink}
            linkText={t('checkAllNotifikations')}
          >
            <BlueArrowIcon className={styles.icon} />
          </DefaultLink>
        </div>
      </div>
    </div>
  );
};

NotificationDropdown.propTypes = {
  notificationsData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    shortContent: PropTypes.string,
    image: PropTypes.string,
    new: PropTypes.bool
  })).isRequired,
  closeDropDawn: PropTypes.func.isRequired
};

export default NotificationDropdown;
