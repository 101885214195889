import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { KEY_CODES } from '../../constants/screener';

function useKeyboardListenerHook(handleCancel, handleConfirm) {
  const showModal = useSelector((state) => state.twitterState.showShareModal, shallowEqual);

  useEffect(() => {
    const listener = (event) => {
      if ((event.code === KEY_CODES.ENTER || event.code === KEY_CODES.NUM_ENTER) && !showModal) {
        handleConfirm();
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleConfirm]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === KEY_CODES.ESC) {
        handleCancel();
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleCancel]);
}

export default useKeyboardListenerHook;
