import React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  DasboardsItemsDropdownTypes,
  DASHBOARD_ITEMS_DROPDOWN_TABS,
} from '../types/constants';
import { IDropdownTabBlock } from '../types/interfaces';
import { getTabOptions, getTabsOptions, getTabIndicatorOptions } from '../utils/MuiOptionsHelper';

const DropdownTabBlock = ({
  selectedTab,
  handleSetTab,
  labelSaved,
  labelPreset,
  theme
}: IDropdownTabBlock): React.ReactElement => (
  <Tabs
    sx={getTabsOptions(theme)}
    variant="fullWidth"
    value={selectedTab}
    onChange={(e, val) => handleSetTab(val as DasboardsItemsDropdownTypes)}
    TabIndicatorProps={getTabIndicatorOptions()}
  >
    {DASHBOARD_ITEMS_DROPDOWN_TABS.map((item) => (
      <Tab
        disableRipple
        sx={getTabOptions(selectedTab === item?.value, theme)}
        label={item?.value === DasboardsItemsDropdownTypes.SAVED ? labelSaved : labelPreset}
        value={item?.value}
        key={item?.value}
      />
    ))}
  </Tabs>
);

export default DropdownTabBlock;
