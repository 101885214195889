import React, { useState } from 'react';

import DefaultButton from '../DefaultButton/DefaultButton';
import styles from './sass/InputRangePanel.module.scss';
import InputEdit from '../InputEdit/InputEdit';
import TextField from '../TextField/TextField';
import { STYLES_FILTER } from '../../constants/filters';

interface IInputRangePanel {
  centralText: string;
  touched: boolean;
  addHandler: () => void;
  labelButton: string;
  minInputValue: number;
  maxInputValue: number;
  typeData: string;
  setMinValueHandler: (value: number) => void;
  setMaxValueHandler: (value: number) => void;
  setMinPosition: () => void;
  setMaxPosition: () => void;
  activeTab: Record<string, boolean>;
  initialMax: number;
  initialMin: number;
}

const InputRangePanel = ({
  centralText, touched, addHandler, labelButton, minInputValue, maxInputValue, typeData,
  setMinValueHandler, setMaxValueHandler, setMinPosition, setMaxPosition, activeTab, initialMax, initialMin,
}: IInputRangePanel): React.ReactElement => {
  const [isChangedGreater, setIsChangedGreater] = useState(false);
  const [isChangedLess, setIsChangedLess] = useState(false);
  const widthItem = activeTab.greater || activeTab.less ? STYLES_FILTER.FULL_WIDTH : '';

  return (
    <div className={styles.container}>
      <div className={styles.wrapperInput}>
        {(activeTab.greater || activeTab.range) && (
          <InputEdit
            typeData={typeData}
            widthItem={widthItem}
            setIsChanged={setIsChangedGreater}
            value={minInputValue === initialMin && activeTab.greater && !isChangedGreater ? 0 : minInputValue}
            setPosition={setMinPosition}
            setInputValue={setMinValueHandler}
          />
        )}
        {activeTab.range && (
          <TextField
            styleType={STYLES_FILTER.CENTRAL_TEXT}
            text={centralText}
          />
        )}
        {(activeTab.less || activeTab.range) && (
          <InputEdit
            typeData={typeData}
            widthItem={widthItem}
            setIsChanged={setIsChangedLess}
            value={maxInputValue === initialMax && activeTab.less && !isChangedLess ? 0 : maxInputValue}
            setPosition={setMaxPosition}
            setInputValue={setMaxValueHandler}
          />
        )}
      </div>
      <DefaultButton
        className={`${styles.addButton} ${touched ? styles.touched : ''}`}
        handleClick={addHandler}
      >
        {labelButton}
      </DefaultButton>
    </div>
  );
};

export default InputRangePanel;
