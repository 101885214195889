import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/WatchListModals.module.scss';

const MultiSelectSearchInput = React.forwardRef(
  ({ inputValue, placeholder, setInputValue }, ref) => (
    <input
      ref={ref}
      type="text"
      placeholder={placeholder}
      value={inputValue}
      onChange={(e) => setInputValue(e)}
      className={`${styles.multiSelectSearch} ${!placeholder && styles.inputWidth}`}
    />
  )
);

MultiSelectSearchInput.propTypes = {
  inputValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired
};

export default MultiSelectSearchInput;
