import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/ChunkButton.module.scss';

const ChunkButton = ({ clickButton, id, text }) => (
  <button className={styles.buttonChunk} type="button" onClick={() => clickButton(id)}>
    {text}
  </button>
);

ChunkButton.propTypes = {
  clickButton: PropTypes.func,
  id: PropTypes.number,
  text: PropTypes.string
};

ChunkButton.defaultProps = {
  clickButton: () => {},
  id: 0,
  text: ''
};

export default ChunkButton;
