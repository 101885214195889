export enum MarketTypes {
  PRE_MARKET,
  REGULAR_MARKET,
  POST_MARKET,
  PRE_REGULAR_MARKET,
  PRE_REGULAR_POST_MARKET,
}

export enum MarketLabels {
  PRE_MARKET = 'preMarket',
  REGULAR_MARKET = 'regularMarket',
  POST_MARKET = 'postMarket',
  PRE_REGULAR_MARKET = 'preRegularMarket',
  PRE_REGULAR_POST_MARKET = 'preRegularPostMarket',
}

export enum MarketTimeLabels {
  PRE_MARKET = '4:00am - 9:30am EST',
  REGULAR_MARKET = '9:30am - 4:00pm EST',
  POST_MARKET = '4:00pm - 8:00pm EST',
  PRE_REGULAR_MARKET = '4:00am - 4:00pm EST',
  PRE_REGULAR_POST_MARKET = '4:00am - 8:00pm EST',
}

export enum MarketDropdown {
  PRE = 'PRE_MARKET',
  REGULAR = 'REGULAR_MARKET',
  POST = 'POST_MARKET',
}

export enum MarketEndpoints {
  PRE_MARKET = 'wss://premarket.lightserver.deepvue.com',
  REGULAR_MARKET = 'wss://lightserver.deepvue.com',
  POST_MARKET = 'wss://postmarket.lightserver.deepvue.com',
  PRE_REGULAR_MARKET = 'wss://preregularmarket.lightserver.deepvue.com/',
  PRE_REGULAR_POST_MARKET = 'wss://preregularpostmarket.lightserver.deepvue.com',
}

export enum MarketStageEndpoints {
  PRE_MARKET = 'wss://premarket.lightserver.staging.deepvue.com',
  REGULAR_MARKET = 'wss://lightserver.staging.deepvue.com',
  POST_MARKET = 'wss://postmarket.lightserver.staging.deepvue.com',
  PRE_REGULAR_MARKET = 'wss://preregularmarket.lightserver.staging.deepvue.com',
  PRE_REGULAR_POST_MARKET = 'wss://preregularpostmarket.lightserver.staging.deepvue.com',
}

export enum MarketConstants {
  STORED_MARKET_LABEL = 'currentMarket',
  SUBSESSION_EXTENDED_ID = 'extended',
  SUBSESSION_REGULAR_ID = 'regular',
  STORED_MARKET_ENDPOINT = 'currentMarketEndpoint',
  SERIES_SESSION_ID = 'mainSeriesProperties.sessionId',
  SERIES_SESSION = 'mainSeriesProperties.session',
  USER_TROTTLE = 1200,
}

export enum MarketSession {
  REGULAR = 'rth',
  EXTENDED = 'eth',
}

export const MarketShortNames = {
  [MarketTypes.PRE_MARKET]: 'pre',
  [MarketTypes.REGULAR_MARKET]: 'regular',
  [MarketTypes.POST_MARKET]: 'post',
};
