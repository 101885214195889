import moment from 'moment';
import STATS_ACTION from './types';
import { PERIOD_SOURCES } from '../../constants/statsChart';

const INITIAL_STATE = {
  lineChart: [],
  tableChart: [],
  tableBorderSettings: {},
  numberOfQuarters: 8,
  currentSource: PERIOD_SOURCES.SHORT_Q,
  mergeTimestamp: moment().valueOf(),
};

const statsChartReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case STATS_ACTION.SET_LINE_DATA:
      return { ...state, lineChart: payload };
    case STATS_ACTION.SET_TABLE_DATA:
      return { ...state, tableChart: payload };
    case STATS_ACTION.SET_NUMBER_OF_QUARTERS:
      return { ...state, numberOfQuarters: payload.data };
    case STATS_ACTION.SET_MERGE_TIMESTAMP:
      return { ...state, mergeTimestamp: payload };
    case STATS_ACTION.SET_BORDER_SETTINGS:
      return { ...state, tableBorderSettings: payload };
    case STATS_ACTION.SET_SOURCE:
      return {
        ...state,
        currentSource: payload === PERIOD_SOURCES.Y ? PERIOD_SOURCES.SHORT_Y : PERIOD_SOURCES.SHORT_Q
      };
    default:
      return state;
  }
};

export default statsChartReducer;
