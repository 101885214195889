import { useDispatch } from 'react-redux';
import $api from '../../http';
import {
  DASHBOARD_FOLDERS,
  DASHBOARD_ITEM_TYPE_COLUMNS,
  DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS,
  DASHBOARD_ITEMS
} from '../../constants/paths';
import numberConstant from '../../constants/numberConstants';
import {
  getColumnsSetItems, getColumnsSetItemsPreset, setColumnsSet,
  setColumnsSetFolders, setColumnsSetFoldersPreset, setColumnsSetPreset,
} from '../../store/newScreen/actions';
import { errorMessageAction } from '../../store/account/actions';
import constants from '../../constants/filters';
import requestHandler from '../getColumnsListHelper';

const useGetColumnsList = () => {
  const dispatch = useDispatch();

  const saveDataHandler = (data, type) => {
    switch (type) {
      case constants.columnSetFolder:
        dispatch(setColumnsSetFolders(data));
        break;
      case constants.columns:
        dispatch(setColumnsSet(data));
        break;
      case constants.columnSetFolderPreset:
        dispatch(setColumnsSetFoldersPreset(data));
        break;
      case constants.columnsPreset:
        dispatch(setColumnsSetPreset(data));
        break;
      default:
        break;
    }
  };

  const getColumnSetsItems = (isPreset) => {
    try {
      $api.get(`${DASHBOARD_FOLDERS}?${isPreset ? DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS 
        : DASHBOARD_ITEM_TYPE_COLUMNS}&limit=${numberConstant.limitItems}`)
        .then((resFolders) => {
          requestHandler(
            resFolders?.data?.items,
            resFolders.data.count,
            isPreset ? constants.columnSetFolderPreset : constants.columnSetFolder,
            saveDataHandler
          );
          const data = resFolders.data.items.map((itemFolder) => {
            return {
              id: itemFolder.id, items: [], title: itemFolder.name, type: 'folder'
            };
          });
          $api.get(`${DASHBOARD_ITEMS}?${isPreset ? DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS
            : DASHBOARD_ITEM_TYPE_COLUMNS}&limit=${numberConstant.limitItems}`)
            .then((resItems) => {
              requestHandler(
                resItems?.data?.items,
                resItems?.data?.count,
                isPreset ? constants.columnsPreset : constants.columns,
                saveDataHandler
              );
              resItems?.data?.items.forEach((item) => {
                const findIndex = data.findIndex((subItem) => subItem?.id === item?.folder?.id);
                if (findIndex !== -1) {
                  data[findIndex]?.items.push(item);
                } else {
                  data.push({ ...item, items: [] });
                }
              });
              if (isPreset) {
                dispatch(getColumnsSetItemsPreset(data));
              } else dispatch(getColumnsSetItems(data));
            });
        });
    } catch (e) {
      dispatch(errorMessageAction({ errorMessage: e.message }));
    }
  };

  return { getColumnSetsItems };
};

export default useGetColumnsList;
