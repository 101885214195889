import React from 'react';
import PropTypes from 'prop-types';

import styles from '../sass/TableScreener.module.scss';

const AlertModalItem = ({ head, children, headClass }) => (
  <div className={styles.alertWrapItem}>
    <div className={`${styles.alertWrapItemHead} ${styles[headClass]}`}>
      {head}
    </div>
    {children}
  </div>
);

AlertModalItem.propTypes = {
  head: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  headClass: PropTypes.string,
};

AlertModalItem.defaultProps = {
  headClass: '',
};

export default AlertModalItem;
