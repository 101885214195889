export const isObject = (object) => {
  return object != null && typeof object === 'object';
};

export const deepEqual = (object1, object2) => {
  if (object1 && object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let i = 0; i < keys1.length; i += 1) {
      const key = keys1[i];
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2))
        || (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }
  }
  return true;
};

export const isLastIndex = (arrayObjects, id, findField, deltaLength = 1) => {
  const count = arrayObjects?.length;
  if (count < 1) return false;
  const index = arrayObjects?.indexOf(arrayObjects?.find((column) => column[findField] === id));
  return (count - deltaLength) === index;
};
