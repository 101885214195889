import React, { Suspense, useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ModalAlert from '../screenerComponents/ModalAlert';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import ClientOnlyPortal from '../../../components/ClientOnlyPortal/ClientOnlyPortal';
import WatchListModals from '../../../components/WatchListModals/WatchListModals';
import ShareTwitterModal from '../../../components/TwitterShare/ShareTwitterModal';
import NotionPopUp from '../../../components/NotionPopUp/NotionPopUp';
import { messageAction } from '../../../store/auth/actions';
import NewVersionPopUp from '../../../components/NewVersionPopUp/NewVersionPopUp';
import AlertsNotionList from '../../../components/AlertsNotionList/AlertsNotionList';
import { MODAL_TITLES, SUBSCRIPTION_TYPE } from '../../../constants/screener';
import DashboardItemsDialog from '../../../components/DashboardItemsDialog/DashboardItemsDialog';
import { DashboardItemsDialogActionTypes } from '../../../components/DashboardItemsDialog/types/constants';
import {
  DasboardsItemsDropdownTypes,
  DialogItemEntityType
} from '../../../components/DashboardsItemsDropdown/types/constants';
import { findIndexItem } from '../../../utils/helpers';
import { setModalType } from '../../../store/watchlist/actions';
import UseDashboardsDialogs from '../../../components/DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { checkRoleUser } from '../../../utils/userHelper';
import TableContext from '../context/TableContext';

const WatchlistPortalItemsWrapper = () => {
  const {
    openConfirmModal,
    twittText,
    setTwittText,
    cleanUpScreenshotData,
  } = useContext(TableContext);

  const dispatch = useDispatch();
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();
  const modalType = useSelector((state) => state.watchlistState.modalType, shallowEqual);
  const showModal = useSelector((state) => state.twitterState.showShareModal, shallowEqual);
  const openAlert = useSelector((state) => state?.alertsState?.openAlertModal, shallowEqual);
  const popUp = useSelector((state) => state.authState.popUp, shallowEqual);
  const message = useSelector((state) => state.authState.message, shallowEqual);
  const isUpdatedPopUpVisible = useSelector(
    (state) => state.screenerState.isHasBeenUpdatePopupVisible, shallowEqual
  );
  const itemsDeeplist = useSelector((state) => state.watchlistState.itemsWatchlist, shallowEqual);
  const selectedTabId = useSelector((state) => state.accountState.userSettings.selectedTabId, shallowEqual);

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const allDeeplists = itemsDeeplist.reduce(
    (array, presetFolder) => {
      array.push(presetFolder);
      presetFolder.items?.forEach((presetItem) => {
        array.push(presetItem);
      });
      return array;
    }, []
  );

  const cancelDialog = () => {
    dispatch(setModalType(''));
    actionCancelDialog();
  };

  useEffect(() => {
    if (!popUp) {
      dispatch(messageAction({ message: '' }));
    }
  }, [message, popUp]);

  useEffect(() => {
    if (modalType === MODAL_TITLES.RENAME) {
      actionOpenDialog(DashboardItemsDialogActionTypes.EDIT, 1,
        DialogItemEntityType.ITEM, DasboardsItemsDropdownTypes.SAVED,
        allDeeplists[findIndexItem(allDeeplists, 'id', selectedTabId)]);
    }
    if (modalType === MODAL_TITLES.DELETE) {
      actionOpenDialog(DashboardItemsDialogActionTypes.DELETE, 1,
        DialogItemEntityType.ITEM, DasboardsItemsDropdownTypes.SAVED,
        allDeeplists[findIndexItem(allDeeplists, 'id', selectedTabId)]);
    }
  }, [modalType]);

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={cancelDialog}
          dialogData={dialogData}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      {openAlert && <ModalAlert />}
      {openConfirmModal && <ConfirmModal />}
      <ClientOnlyPortal selector="#watchlist_modals">
        {modalType && modalType !== MODAL_TITLES.CREATE
          && modalType !== MODAL_TITLES.CREATE_DATA_PANEL
          && modalType !== MODAL_TITLES.CREATE_DATA_PANEL_PRESET
          && <WatchListModals />}
      </ClientOnlyPortal>
      <ClientOnlyPortal selector="#twitter_share_modal">
        {showModal && (
          <ShareTwitterModal
            textTweet={twittText}
            handlerTextTweet={setTwittText}
            cleanUpScreenshotData={cleanUpScreenshotData}
          />
        )}
      </ClientOnlyPortal>
      {(popUp) && (<NotionPopUp />)}
      {(isUpdatedPopUpVisible) && (
        <Suspense fallback={<></>}>
          <NewVersionPopUp />
        </Suspense>
      )}
      {(isUpdatedPopUpVisible) && (<NewVersionPopUp />)}
      <AlertsNotionList />
    </>
  );
};

export default WatchlistPortalItemsWrapper;
