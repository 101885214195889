import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IDashboardsHeader } from './types/DashboardComponentsInterfaces';
import styles from './sass/DashboardComponents.module.scss';
import DashboardsTabs from './DashboardsTabs';
import DashboardsHeaderActions from './components/DashboardsHeaderActions';
import SymbolSearch from '../ScreenerMenu/components/SymbolSearch';
import DashboardsTabDropdown from './components/DashboardsTabDropdown';
import { free } from '../../constants/statsChart';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import WatchlistAddSymbolButton from '../WatchlistAddSymbolButton/WatchlistAddSymbolButton';
import { setWidgetMenuOpen } from '../../store/dashboards/slice';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import { checkRoleUser } from '../../utils/userHelper';
import { MODAL_TITLES, SUBSCRIPTION_TYPE, TEMPLATE_TYPE } from '../../constants/screener';
import RootStateTypes from '../../store/RootStateTypes';

const DashboardsHeader = ({
  selectedDashboard,
  setCurrentDashboard,
  dashboards,
  setFocus,
  resetSearchHandler,
  morePopupCallback,
  profile,
  search,
  setOpenModal
}: IDashboardsHeader): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const widgetMenuOpen = useSelector((state: RootStateTypes) => state.dashboardsState.widgetMenuOpen, shallowEqual);
  const {
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const toggleWidgetPanel = () => {
    dispatch(setWidgetMenuOpen(!widgetMenuOpen));
  };

  const handleShowCreateModal = () => {
    setOpenModal(MODAL_TITLES.CREATE_DASHBOARD);
  };

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      <div className={styles.dashboardsHeader}>
        <div className={styles.dashboardsHeaderSearchBlock}>
          <SymbolSearch
            setFocus={setFocus}
            search={search}
            resetSearchHandler={resetSearchHandler}
            isFree={profile?.role === free}
          />
          <DashboardsTabDropdown
            selectedDashboardId={selectedDashboard?.id as number}
            setCurrentDashboard={setCurrentDashboard}
            setOpenModal={setOpenModal}
          />
          <Button
            buttonClass={styles.buttonNewScreen}
            handleClick={handleShowCreateModal}
          >
            <TextField text={t('newDashboard')} styleType="NewScreenButtonGroup" />
          </Button>
          { (adminRole || selectedDashboard?.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET) && (
            <WatchlistAddSymbolButton
              hideAddSymbol={!dashboards?.length}
              menuFlag={false}
              onlyIconButton
              handleClick={toggleWidgetPanel}
              isPlusIcon
              isWidget={false}
              selectedItemFromWidget={0}
            />
          )}
        </div>
        <DashboardsTabs
          selectedDashboard={selectedDashboard?.id as number}
          setCurrentDashboard={setCurrentDashboard}
          dashboards={dashboards}
          morePopupCallback={morePopupCallback}
        />
        <DashboardsHeaderActions />
      </div>
    </>
  );
};

export default DashboardsHeader;
