import { PSC_UPSERT, PSC_REMOVE_WIDGET, PSC_REMOVE_DASHBOARD } from '../constants/storage';

const updatePscNestedHandler = (prevData, payload) => {
  const newData = { ...prevData };
  const positionSizeLastStateData = [...prevData?.positionSizeLastState];

  if (payload.type === PSC_UPSERT) {
    const updatedObject = payload.data;
    const dashboardIndex = positionSizeLastStateData.findIndex((item) => item.dashboardId === payload.dashboardId);
    if (dashboardIndex !== -1) {
      const widgetIndex = positionSizeLastStateData[dashboardIndex]
        .widgets.findIndex((item) => item.id === updatedObject.id);
      if (widgetIndex !== -1) {
        positionSizeLastStateData[dashboardIndex].widgets[widgetIndex] = {
          ...positionSizeLastStateData[dashboardIndex].widgets[widgetIndex],
          ...updatedObject
        };
      } else {
        positionSizeLastStateData[dashboardIndex].widgets.push(updatedObject);
      }
    } else {
      positionSizeLastStateData.push({
        dashboardId: payload.dashboardId,
        widgets: [updatedObject]
      });
    }
    newData.positionSizeLastState = positionSizeLastStateData;
  } else if (payload.type === PSC_REMOVE_WIDGET) {
    const dashboardIndex = positionSizeLastStateData.findIndex((item) => item.dashboardId === payload.dashboardId);
    if (dashboardIndex !== -1) {
      const widgetIndex = positionSizeLastStateData[dashboardIndex].widgets
        .findIndex((item) => item.id === payload.data);
      if (widgetIndex !== -1) {
        positionSizeLastStateData[dashboardIndex].widgets.splice(widgetIndex, 1);
        newData.positionSizeLastState = positionSizeLastStateData;
      }
    }
  } else if (payload.type === PSC_REMOVE_DASHBOARD) {
    const dashboardIndex = positionSizeLastStateData.findIndex((item) => item.dashboardId === payload.dashboardId);
    if (dashboardIndex !== -1) {
      positionSizeLastStateData.splice(dashboardIndex, 1);
      newData.positionSizeLastState = positionSizeLastStateData;
    }
  }
  return newData;
};

export default updatePscNestedHandler;
