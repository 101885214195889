import React, { useCallback, useState, useMemo } from 'react';
import {
  shallowEqual,
  useSelector,
  useDispatch,
} from 'react-redux';
import { ReactComponent as TwitterBtn } from '../../assets/images/icons/x_share_logo.svg';
import DefaultButton from '../DefaultButton/DefaultButton';
import styles from '../ButtonExportScreener/sass/ButtonExportScreener.module.scss';
import RootStateTypes from '../../store/RootStateTypes';
import redirectHelper from './helpers/helpers';
import { SOCIALS_TYPE } from '../../constants/screener';
import { takeScreenShot } from '../../store/twitter/slice';

const ButtonTwitterShare = (): ReturnType<React.FC> => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);

  const [viewTool, setViewTool] = useState(false);

  const mouseEnterHandler = useCallback(() => {
    setViewTool(true);
  }, [viewTool]);

  const mouseLeaveHandler = useCallback(() => {
    setViewTool(false);
  }, [viewTool]);

  const clickHandler = () => {
    const twitterConnected = userProfile?.socials.find(
      (social: string) => social === SOCIALS_TYPE.TWITTER
    );

    if (!twitterConnected) {
      redirectHelper(process.env.REACT_APP_WP_URL);
      return;
    }
    mouseLeaveHandler();
    dispatch(takeScreenShot());
  };

  return (
    <div
      className={styles.containerShare}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
    >
      <DefaultButton
        buttonClass="btnTwitterExport"
        handleClick={clickHandler}
      >
        {useMemo(() => (<TwitterBtn />), [])}
      </DefaultButton>
      {viewTool && <div className={styles.hoverDropDownShare}>Share to X</div>}
    </div>
  );
};

export default ButtonTwitterShare;
