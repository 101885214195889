import numberConstant from '../../../../constants/numberConstants';
import { getDataForRequest } from '../../../../utils/helpers';
import $api from '../../../../http';
import { DASHBOARD_ITEM_TYPE_WATCHLIST, DASHBOARD_ITEMS } from '../../../../constants/paths';
import filters from '../../../../constants/filters';
import { setToLocalStorage } from '../../../../utils/storageWorks';

const requestHandler = (firstArray, count, type, cb) => {
  const numberRequest = Math.ceil(count / numberConstant.limitItems);
  const totalItems = numberRequest - numberConstant.decreaseCount > numberConstant.zeroCount ? [...firstArray] : [];
  const array = getDataForRequest(numberRequest);

  const requests = array.map((item) => {
    return $api.get(
      `${DASHBOARD_ITEMS}?${DASHBOARD_ITEM_TYPE_WATCHLIST}&page=${item}&limit=${numberConstant.limitItems}`,
    ).then((response) => {
      return response.data.items;
    });
  });
  return Promise
    .all(requests)
    .then((body) => {
      const newArray = [...totalItems];
      body.forEach((res) => {
        if (res) {
          newArray.push(...res);
        }
      });
      if (type === filters.deepList) {
        cb(newArray);
      }
    })
    .catch((e) => setToLocalStorage('errorMessage', e.message));
};

export default requestHandler;
