import React from 'react';
import styles from './sass/ManageAlerts.module.scss';
import Icon from '../Icon/Icon';
import { IMG_PATH } from '../../constants/screener';
import { IConfirmAlertRow } from './interfaces/IConfirmAlert';

const AlertConfirmDeleteRow: React.FC<IConfirmAlertRow> = ({ symbolIconPath, symbolName }) => (
  <div className={styles.alerDeleteRow}>
    <Icon
      icon={symbolIconPath ? `${IMG_PATH}${symbolIconPath}` : `${IMG_PATH}no_icon.png`}
      iconClass={styles.symbolImg}
    />
    <div className={styles.alerDeleteRowName}>
      {symbolName}
    </div>
  </div>
);
export default AlertConfirmDeleteRow;
