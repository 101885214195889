const NEW_SCREEN_ACTIONS = {
  GET_ITEMS_NEW_SCREEN: '@@GET_ITEMS_NEW_SCREEN',
  GET_PRESET_ITEMS_NEW_SCREEN: '@@GET_PRESET_ITEMS_NEW_SCREEN',
  GET_FOLDERS: '@@GET_FOLDERS',
  GET_SCREENS: '@@GET_SCREENS',
  GET_PRESETS: '@@GET_PRESETS',
  GET_ITEM_ID: '@@GET_ITEM_ID',
  SET_SELECTED_SCREEN: '@@SET_SELECTED_SCREEN',
  SET_GROUP_FILTERS: '@@SET_GROUP_FILTERS',
  SET_REQUEST_STATE_FILTERS: '@@SET_REQUEST_STATE_FILTERS',
  SET_NEW_SCREEN_FLAG: '@@SET_NEW_SCREEN_FLAG',
  SET_FOLDERS: '@@SET_FOLDERS',
  SET_SCREENS: '@@SET_SCREENS',
  SET_FOLDERS_PRESET: '@@SET_FOLDERS_PRESET',
  SET_SCREENS_PRESET: '@@SET_SCREENS_PRESET',
  SET_FOLDER_COLUMNS_SET: '@@SET_FOLDER_COLUMNS_SET',
  SET_COLUMNS_SET: '@@SET_COLUMNS_SET',
  SET_ITEMS_COLUMNS_SET: '@@SET_ITEMS_COLUMNS_SET',
  SET_FOLDER_COLUMNS_SET_PRESET: '@@SET_FOLDER_COLUMNS_SET_PRESET',
  SET_COLUMNS_SET_PRESET: '@@SET_COLUMNS_SET_PRESET',
  SET_ITEMS_COLUMNS_SET_PRESET: '@@SET_ITEMS_COLUMNS_SET_PRESET',
  SET_HOVERED_FOLDER_COLUMN_SETS_ID: '@@SET_HOVERED_FOLDER_COLUMN_SETS_ID',
  SET_HOVERED_COLUMN_SETS_ID: '@@SET_HOVERED_COLUMN_SETS_ID',
  COLUMN_SET_ID: '@@COLUMN_SET_ID',
  SAVE_SORT_DATA: '@@SAVE_SORT_DATA',
  SET_NAME_COLUMNS_SET: '@@SET_NAME_COLUMNS_SET',
  SET_DATA_PANELS: '@@SET_DATA_PANELS',
  SET_DATA_PANELS_ITEMS: '@@SET_DATA_PANELS_ITEMS',
  SET_DATA_PANELS_ITEMS_PRESETS: '@@SET_DATA_PANELS_ITEMS_PRESETS',
  SET_DATA_PANELS_FOLDERS: '@@SET_DATA_PANELS_FOLDERS',
  SET_DATA_PANELS_PRESETS_FOLDERS: '@@SET_DATA_PANELS_PRESETS_FOLDERS',
  SET_DATA_PANELS_PRESETS: '@@SET_DATA_PANELS_PRESETS',
  SET_DEFAULT_DATA_PANELS: '@@SET_DEFAULT_DATA_PANELS',
  SET_DATA_PANELS_QUERY: '@@SET_DATA_PANELS_QUERY',
  SET_ITEM_NEW_SCREEN: '@@SET_ITEM_NEW_SCREEN',
  SET_PRESET_ITEM_NEW_SCREEN: '@@SET_PRESET_ITEM_NEW_SCREEN',
  SET_SELECTED_CATEGORY: '@@SET_SELECTED_CATEGORY',
  SET_COLUMNS_SET_UPDATE_FLAG: '@@SET_COLUMNS_SET_UPDATE_FLAG',
  SET_PREV_SCREEN: '@@SET_PREV_SCREEN',
  SET_CREATED_FOLDER: '@@SET_CREATED_FOLDER',
};

export default NEW_SCREEN_ACTIONS;
