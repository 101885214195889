import React, { ReactElement } from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IAlertTab } from './interfaces/IAlertProps';
import styles from './sass/WatchlistTabsBlock.module.scss';
import TextField from '../TextField/TextField';
import RootStateTypes from '../../store/RootStateTypes';
import { ReactComponent as AlertsIcon } from '../../assets/images/icons/megaphoneSideBar.svg';

const AlertTab = ({
  alertTab,
  selectTabClickHandler,
  resetSearchHandler,
}: IAlertTab): ReactElement => {
  const { t } = useTranslation();

  const selectedTab = useSelector((state:RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const alertsListCounter = useSelector(
    (state:RootStateTypes) => state?.alertsState?.alertsListCounter, shallowEqual
  );

  const clickHandler = () => {
    if (selectedTab.id !== alertTab?.id) {
      selectTabClickHandler(alertTab);
    }
    resetSearchHandler(alertTab);
  };

  return (
    <div
      aria-hidden="true"
      className={`
        ${styles.tabItemFavorite}
        ${selectedTab.id === alertTab?.id ? styles.activeFavorite : styles.unActiveFavorite}
      `}
      onClick={clickHandler}
      id={alertTab?.id?.toString()}
    >
      <div className={styles.tabItemTitleWrapper}>
        <AlertsIcon className={styles.alertIcon} />
        <TextField styleType="tabItemTitleFavorite" text={alertTab?.title} />
      </div>
      <TextField styleType="tabItemStocks" text={`${alertsListCounter} ${t('alerts')}`} />
    </div>
  );
};

export default AlertTab;
