import React, { useState } from 'react';
import moment from 'moment';
import styles from './sass/EducationComponents.module.scss';
import TutorialCardImage from '../../assets/images/icons/videoThumbnail.png';
import CategoryBlock from './CategoryBlock';
import { ITutorialCard } from './types/EducationComponentsInterfaces';
import VideoPlayer from './VideoPlayer';

const WebinarCard = ({
  title, date, videoSrc, categories, posterSrc, tutorialCategories
}: ITutorialCard): React.ReactElement => {
  const [showCard, setShowCard] = useState(false);

  const handleClick = () => setShowCard(true);

  return (
    <div
      className={styles.cardItem}
      role="button"
      tabIndex={0}
      aria-label="webinars"
      onMouseDown={handleClick}
    >
      <div className={styles.cardImageWrapper}>
        <img
          className={styles.cardImage}
          src={posterSrc}
          alt="webinars-images"
          onError={(event) => {
            const target = event.currentTarget as HTMLImageElement;
            target.onerror = null;
            target.src = TutorialCardImage;
          }}
        />
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.tagsContainer}>
          <CategoryBlock categories={categories} allCategories={tutorialCategories} />
        </div>
        <span className={styles.cardDate}>{moment(date).format('MMMM D, YYYY')}</span>
        <p className={styles.cardTitle}>{title}</p>
      </div>
      {showCard && (
        <VideoPlayer videoSrc={videoSrc} setShowCard={setShowCard} />
      )}
    </div>
  );
};

export default WebinarCard;
