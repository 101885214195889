import React from 'react';

import styles from './sass/FilterGroupEmaSma.module.scss';
import SelectGroupFilter from '../SelectGroupFilter/SelectGroupFilter';
import SelectGroupDropFilter from '../SelectGroupDropFilter/SelectGroupDropFilter';
import { TCommonItem } from '../FilterScreen/Types';

interface IGroupDropSelector {
  filter: TCommonItem[];
  handlerSelectFilter: (filterItem: TCommonItem) => void;
  deepGroupDrop: boolean;
  setViewDropList: (value: boolean) => void;
  viewDropList: boolean;
  viewDropListSingleSecond: boolean;
  setViewDropListSingleSecond: (value: boolean) => void;
  selectFilter: TCommonItem;
  selectedGroup: TCommonItem;
  bottomFlag: boolean;
}

const GroupDropSelector = ({
  filter, handlerSelectFilter, deepGroupDrop, setViewDropList, viewDropList,
  viewDropListSingleSecond, setViewDropListSingleSecond, selectFilter, selectedGroup,
  bottomFlag
}: IGroupDropSelector): React.ReactElement => {
  const checkIsDrop = (): boolean => {
    const filterFirstData = filter[0]?.data as TCommonItem[];
    const filterSecondData = filterFirstData[0]?.data as TCommonItem[];
    return filterSecondData?.length > 0 && !deepGroupDrop && Object.keys(selectFilter).length > 0;
  };
  const checkIsSingle = (): boolean => {
    const filterFirstData = filter[0]?.data as TCommonItem[];
    const filterSecondData = filterFirstData[0]?.data as TCommonItem[];
    return (filterSecondData?.length === 0 && filterFirstData?.length > 0) && !deepGroupDrop;
  };

  return (
    <div className={styles.wrapperDrop}>
      { checkIsDrop() && (
      <SelectGroupDropFilter
        data={selectedGroup?.data as TCommonItem[]}
        handlerSelectFilter={handlerSelectFilter}
        viewDropList={viewDropList}
        setViewDropList={setViewDropList}
        selectFilter={selectFilter}
      />
      )}
      { checkIsSingle() && (
      <SelectGroupFilter
        bottomFlag={bottomFlag}
        data={selectedGroup?.data as TCommonItem[]}
        selectFilter={selectFilter}
        handlerSelectFilter={handlerSelectFilter}
        viewDropListSingle={viewDropListSingleSecond}
        setViewDropListSingle={setViewDropListSingleSecond}
      />
      )}
    </div>
  );
};

export default GroupDropSelector;
