import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconFilter } from '../../../assets/images/icons/screener/miniChartFilter.svg';
import DefaultButton from '../../DefaultButton/DefaultButton';
import styles from '../sass/MultiChartSettings.module.scss';
import useOutsideAlerter from '../../../utils/hooks/useOutside';
import MultiChartSettingsPanel from './MultiChartSettingsPanel';

const MultiChartSettingsButton = (): React.ReactElement => {
  const { t } = useTranslation();

  const [showSettings, setShowSettings] = useOutsideAlerter(false);
  const [viewTool, setViewTool] = useState(false);

  const toggleShowSettings = () => {
    setShowSettings(!showSettings);
  };

  const onMouseEnterHandler = () => {
    setViewTool(true);
  };

  const onMouseLeaveHandler = () => {
    setViewTool(false);
  };

  return (
    <div className={styles.container}>
      <div onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
        <DefaultButton
          handleClick={toggleShowSettings}
          buttonClass={showSettings ? 'multiChartButtonActive' : 'multiChartButton'}
        >
          <IconFilter />
        </DefaultButton>
      </div>
      {viewTool && !showSettings && <div className={styles.hoverDropDown}>{t('multiChart')}</div>}
      <MultiChartSettingsPanel
        setShowSettings={setShowSettings}
        showSettings={showSettings}
      />
    </div>
  );
};

export default MultiChartSettingsButton;
