import React from 'react';

import TextField from '../../../TextField/TextField';
import { getRangeText } from '../../../../utils/convertingDataHelper';
import { TValueItem } from '../../Types';

interface IItemsRange {
  arrayData: TValueItem[];
}

const ItemsRange = ({ arrayData }: IItemsRange): React.ReactElement => (
  <TextField
    styleType="filterItem"
    text={getRangeText({ arrayData })}
  />
);

export default ItemsRange;
