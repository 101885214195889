export const lineChart = 'lineChart';
export const tableChart = 'tableChart';
export const basic = 'basic';
export const growth = 'growth';
export const earning = 'earnings';
export const N_A = 'N/A';
export const free = 'free';
export const chartList = [lineChart, tableChart];
export const switcherList = [basic, growth];
export const SETTINGS_SVG = {
  PATTERN_ID: 'pattern-stripe',
  PATTERN_WIDTH: '11',
  PATTERN_HEIGHT: '8',
  PATTERN_UNITS: 'userSpaceOnUse',
  PATTERN_TRANSFORM: 'rotate(90)',
  RECT_WIDTH: '9',
  RECT_HEIGHT: '8',
  RECT_TRANSFORM: 'translate(0,0)',
  RECT_FILL: 'white',
  MASK_ID: 'mask-stripe',
  MASK_RECT_X: '0',
  MASK_RECT_Y: '0',
  MASK_RECT_WIDTH: '100%',
  MASK_RECT_HEIGHT: '100%',
  MASK_RECT_FILL: 'url(#pattern-stripe)',
  MAIN_RECT_FILL: 'url(#mask-stripe)'
};
export const UI_SETTINGS = {
  GRID: 'grid',
  NONE: 'none',
  TEMPLATE_ROWS: '1fr auto',
  HEIGHT_FIT: 'fit-content',
  HEIGHT_PERCENT: '100%',
  OVERFLOW_HIDDEN: 'hidden'
};

export const POSITION_NUMBERS = {
  STATS_HEIGHT_ONE: 50,
  STATS_HEIGHT_SECOND: 200,
  DROP_UNDER_ONE: 50,
  DROP_UNDER_SECOND: 200,
  DROP_ABOVE_SECOND: 90,
};

export const STATS_CLASS_LIST = {
  TABLE_HEADER_EST: 'table_header_quarter_est',
  TABLE_HEADER: 'table_header_quarter'
};

export const SWITCH_QUARTERS_SETTINGS = {
  FONT_WEIGHT: 'bold',
  CHECKBOX_ID: 'switcher',
  INPUT_TYPE: 'checkbox'
};

export const SOURCES = {
  HISTORY: 'H',
  ESTIMATE: 'E'
};

export const REVISION = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
};

export const PERIOD_SOURCES = {
  Y: 'Annual',
  Q: 'Quarterly',
  SHORT_Y: 'Y',
  SHORT_Q: 'Q'
};

export const BORDER_SETTINGS = {
  THIN_BLUE: 'THIN_BLUE',
  THIN_RED: 'THIN_RED',
  BOLD_BLUE: 'BOLD_BLUE',
  BOLD_RED: 'BOLD_RED',
  BLUE_RED: 'BLUE_RED',
  RED_BLUE: 'RED_BLUE',
  GRAY: 'GRAY',
  GAP: 'gap'
};

export const chartFilterList = ['Earnings', 'Sales'];
export const dataTitles = {
  Earnings: 'Earnings',
  Sales: 'Sales',
  SalesFuture: 'SalesFuture',
  EarningsFuture: 'EarningsFuture'
};
export const chartLegendFormatter = {
  EarningsFuture: 'Est. Earnings',
  SalesFuture: 'Est. Sales'
};

export const columnsForEPSLine = [91, 92, 93, 94, 95, 96, 97, 98, 99];

export const STATS_CHART_HEIGHT = 153;

export const STATS_CHART_WIDTH = 150;

export const FILTER_LIST = ['Earnings', 'Sales'];
