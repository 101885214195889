import React from 'react';

import styles from '../sass/FilterScreen.module.scss';
import FilterOr from './FilterOr';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { SUBSCRIPTION_TYPE } from '../../../constants/screener';
import { UserProfile } from '../../../utils/Types';

interface IFilterButtonOrActive {
  btnOr: string;
  title: string;
  btnSaveScreen: string;
  saveScreenHandler: (title: string) => void;
  textTooltip: string;
  addNewGroupHandler: () => void;
  profile: UserProfile;
  btnDisabled: boolean;
  disabled: boolean;
  titleCreateItem: string;
}

const FilterButtonOrActive = ({
  btnOr,
  title,
  btnSaveScreen,
  saveScreenHandler,
  textTooltip,
  addNewGroupHandler,
  profile,
  btnDisabled,
  disabled,
  titleCreateItem
}: IFilterButtonOrActive): React.ReactElement => (
  <div className={styles.bottomBox}>
    {!disabled && (
      <div className={styles.wrapperOr}>
        <FilterOr
          btnOr={btnOr}
          title={title}
          addNewGroupHandler={addNewGroupHandler}
          textTooltip={textTooltip}
        />
      </div>
    )}
    <div className={styles.containerSaveScreen}>
      <DefaultButton
        buttonClass="bigButton"
        className={styles.saveScreen}
        disabled={profile?.role === SUBSCRIPTION_TYPE.FREE
          || !btnDisabled || disabled}
        handleClick={() => saveScreenHandler(titleCreateItem)}
      >
        {btnSaveScreen}
      </DefaultButton>
    </div>
  </div>
);

export default FilterButtonOrActive;
