import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { ReactComponent as StatsChartIcon } from '../../../assets/images/icons/screener/statsChart.svg';
import { ReactComponent as DataPanelIcon } from '../../../assets/images/icons/screener/dataPanel.svg';
import styles from '../sass/ScreenerMenu.module.scss';
import useDataPanelButton from '../../DataPanel/hooks/useDataPanelButton';
import useLayoutChange from '../../../utils/hooks/useLayoutChange';

const ScreenerMenuOptions = (): React.ReactElement => {
  const { t } = useTranslation();
  const [viewToolStats, setViewToolStats] = useState(false);
  const [viewToolData, setViewToolData] = useState(false);
  const { toggleDataPanel, isOpen } = useDataPanelButton();
  const { getScreenerLayout, updateStatsChartState } = useLayoutChange();

  return (
    <div className={styles.menuOptions}>
      <div
        className={styles.menuButton}
        onMouseEnter={() => setViewToolStats(true)}
        onMouseLeave={() => setViewToolStats(false)}
      >
        <DefaultButton
          handleClick={() => updateStatsChartState(!getScreenerLayout?.statsOpen)}
          className={`${styles.menuChartButton} ${getScreenerLayout?.statsOpen && (styles.activeView)}`}
        >
          <StatsChartIcon />
        </DefaultButton>
        {viewToolStats && <div className={styles.hoverDropDownSingle}>{t('statsTable')}</div>}
      </div>
      <div
        className={styles.menuButton}
        onMouseEnter={() => setViewToolData(true)}
        onMouseLeave={() => setViewToolData(false)}
      >
        <DefaultButton
          handleClick={() => toggleDataPanel(!isOpen)}
          className={`${styles.menuPanelButton} ${isOpen && (styles.activeViewPanel)}`}
        >
          <DataPanelIcon />
        </DefaultButton>
        {viewToolData && <div className={styles.hoverDropDownMulti}>{t('dataPanel')}</div>}
      </div>
    </div>
  );
};

export default ScreenerMenuOptions;
