import React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardItemDropdown.module.scss';
import { ReactComponent as IconDot } from '../../../assets/images/icons/screener/more-circle.svg';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { IItemMenu } from '../types/interfaces';
import { ItemMenuActions } from '../types/constants';
import { getItemMenuListOptions, getItemMenuItemOptions } from '../utils/MuiOptionsHelper';
import ThemeVariants from '../../../constants/theme';

const ItemMenu = ({
  item,
  anchorElement,
  handleOpen,
  menuOpen,
  setMenuClose,
  actionDuplicate,
  actionShare,
  actionMove,
  actionEdit,
  actionDelete,
  actionRemove,
  theme,
  menuObject,
}: IItemMenu): React.ReactElement => {
  const { t } = useTranslation();

  const hadleAction = (action: ItemMenuActions) => {
    // console.log('action =>', action, item);
    switch (action) {
      case ItemMenuActions.SHARE:
        actionShare(item);
        break;
      case ItemMenuActions.DUPLICATE:
        actionDuplicate(item);
        break;
      case ItemMenuActions.MOVE:
        actionMove(item);
        break;
      case ItemMenuActions.EDIT:
        actionEdit(item);
        break;
      case ItemMenuActions.DELETE:
        actionDelete(item);
        break;
      case ItemMenuActions.REMOVE:
        if (actionRemove) actionRemove(item);
        break;
      default:
        break;
    }
    setMenuClose();
  };
  return (
    <div className={styles.itemMenu}>
      <DefaultButton
        className={styles.itemMenuButton}
        handleClick={(e) => handleOpen(e)}
      >
        <IconDot />
      </DefaultButton>
      <Menu
        id={`basic-menu-${item.id}`}
        anchorEl={anchorElement}
        open={menuOpen}
        onClose={setMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={
          {
            sx: {
              boxShadow: '1px 1px 16px 0px rgba(0, 0, 0, 0.10)',
              backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232b3b' : '#FFFFFF'
            }
          }
        }
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: getItemMenuListOptions()
        }}
      >
        {menuObject.map((itemMenu) => (
          <MenuItem
            sx={getItemMenuItemOptions(theme)}
            key={itemMenu.id}
            onClick={() => hadleAction(itemMenu.action)}
          >
            <itemMenu.icon />
            <span className={itemMenu.additionalClass}>{t(itemMenu.label)}</span>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default ItemMenu;
