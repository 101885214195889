import { useCallback, useRef } from 'react';

const useLastElementObserver = (setPagingHandler) => {
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (observer.current) observer.current?.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPagingHandler();
        }
      });

      if (node) observer.current?.observe(node);
    },
    []
  );

  return {
    lastElementRef
  };
};

export default useLastElementObserver;
