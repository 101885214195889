import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { IWidgetHeadScreenDropdown } from '../types/DashboardComponentsInterfaces';
import {
  MODAL_TITLES,
} from '../../../constants/screener';
import { TDashboardItemsData } from '../../../utils/Types';
import { getCountForLinkedWatchList } from '../../../utils/watchListsHelper';
import RootStateTypes from '../../../store/RootStateTypes';
import useWatchList from '../../../utils/hooks/useWatchList';
import DashboardsItemDropdown from '../../DashboardsItemsDropdown/DasboardsItemDropdown';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';
import { ReactComponent as IconStar } from '../../../assets/images/icons/screener/star.svg';
import constants from '../../../constants/filters';
import { setCategoryName } from '../../../store/filters/slice';
import { setNewScreenFlag } from '../../../store/newScreen/actions';
import useWorkerItems from '../../../utils/hooks/useWorkerItems';

const DeepListWidgetDropdown = ({
  selectedItem, widget, updateWidget, setDropdownTitle,
  setSelectedItem, openModalHandler,
  isDropDownOpen, setIsDropDownOpen,
  dropdownButtonRef, searchHeaderValue, resetSearchHandler,
}: IWidgetHeadScreenDropdown): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const presetsWatchlist = useSelector((state: RootStateTypes) => state.watchlistState.presetsWatchlist, shallowEqual);
  const itemsDeeplist = useSelector((state: RootStateTypes) => state.watchlistState.itemsWatchlist, shallowEqual);

  const { updateWatchlistData } = useWatchList();
  const {
    getDeeplists,
    getDeeplistFolders,
    getDeeplistPreset,
    getDeeplistPresetFolders
  } = useWorkerItems();

  const setCurrentDeepListWidgetHandler = (deepList: TDashboardItemsData) => {
    if (deepList?.parentId) {
      getCountForLinkedWatchList(deepList?.data)
        .then((resp) => {
          const data = {
            stocks: undefined,
            ...deepList.data
          };
          data.stocks = resp?.data[3];
          updateWatchlistData(deepList?.id, data);
        });
    }
    setSelectedItem(deepList);
    setDropdownTitle(deepList?.title);
    updateWidget({ ...widget, deepListId: deepList.id });
  };

  const actionUpdateState = () => {
    getDeeplistFolders();
    getDeeplistPreset();
    getDeeplistPresetFolders();
    getDeeplists();
  };

  const viewFilter = useCallback((title: string, newScreenFlag: boolean) => {
    if (title === constants.deepList) openModalHandler(MODAL_TITLES.CREATE_DEEPLIST, true);
    if (title === constants.presetDeeplist) openModalHandler(MODAL_TITLES.CREATE_DEEPLIST_PRESET, true);
    setIsDropDownOpen(false);
    dispatch(setCategoryName(''));
    dispatch(setNewScreenFlag(newScreenFlag));

    if (searchHeaderValue) {
      resetSearchHandler();
    }
  }, [searchHeaderValue]);

  const selectTabClickHandler = (tab: string | TDashboardItemsData) => {
    if (typeof tab !== 'string') {
      setCurrentDeepListWidgetHandler(tab as TDashboardItemsData);
    } setIsDropDownOpen(false);
  };
  const actionEdit = (item: TDashboardItemsData) => {
    viewFilter(item?.title as string, false);
  };
  const actionCreateNew = (selectedTabId: number) => {
    viewFilter(selectedTabId === 0 ? constants.deepList : constants.presetDeeplist, true);
  };

  return (
    <DashboardsItemDropdown
      itemType={DialogItemDataTypes.DEEP_LIST}
      savedItems={itemsDeeplist}
      presetItems={presetsWatchlist}
      isOpen={isDropDownOpen}
      setIsOpen={setIsDropDownOpen}
      parrentRef={dropdownButtonRef}
      labelPreset={t('deeplistsPresets')}
      labelSaved={t('savedDeeplists')}
      labelAdd={t('deeplist')}
      labelDescription={t('dropDownTitle')}
      iconAddMenu={<IconStar />}
      actionCreateNewCallback={actionCreateNew}
      actionEditCallback={actionEdit}
      selectedItem={selectedItem}
      currentItemHandler={selectTabClickHandler}
      topMargin={7}
      updateAfterDragActions={actionUpdateState}
    />
  );
};

export default DeepListWidgetDropdown;
