import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../sass/AlertsList.module.scss';

const ToastNote = ({ note }) => {
  const { t } = useTranslation();

  return (
    <p className={styles.note}>
      <span>{t('note')}</span>
      {note}
    </p>
  );
};

ToastNote.propTypes = {
  note: PropTypes.string.isRequired
};
export default ToastNote;
