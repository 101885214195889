const numberConstants = {
  percentNumber: 100,
  step: 1000,
  downTooltip: 60,
  downTooltipMAx: 60,
  downTooltipRevert: 30,
  downTooltipRevertMinus: 100,
  downTooltipMinus: 120,
  part: 3,
  bigPart: 20,
  NaN: 'NaN',
  countSearch: 24,
  clean: 10000,
  percent: 100,
  stepSliderToUp: 350,
  stepSliderWithBigMinus: 650,
  stepSliderWithBigDelta: 500,
  stepSliderMaxMinDiff: 400,
  stepZero: 0,
  step55: 55,
  step300: 300,
  step200: 200,
  step700: 700,
  lengthText: 15,
  limitItems: 50,
  startCount: 1,
  secondDay: 86400,
  decreaseCount: 1,
  zeroCount: 0,
  defaultCount: 1,
  weekDays: 7,
  yearDays: 365,
  defaultId: -1,
  dateStarter: 104990400,
  correctType: 3,
  correctDateSeconds: 1000,
  five: 5
};

export const CORRECT_POSITION = {
  BOTTOM_FOLDER: 50
};

export const HIDE_MENU_ITEM = {
  MOVE: 0,
  REMOVE: 1
};

export const LENGTH_CHECK = 14;

export const CORRECT_DATE = 1000;

export const MULTIPLY_PERCENT = 100;

export const DELAY_SAVE_COLUMNS = 300;

export default numberConstants;
