import { setStopTickScreenerAction } from '../store/screener/actions';
import { MS_INTERVAL } from '../constants/screener';

const scrollStopEvent = (onScrollPage, item, dispatch) => {
  const delayEvent = (after, fn) => {
    let timer;
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(fn, after);
    };
  };

  const scrollStopper = delayEvent(MS_INTERVAL.THROTTLING_SCROLL, () => {
    onScrollPage(item);
    dispatch(setStopTickScreenerAction({ data: false }));
  });

  item.addEventListener('scroll', scrollStopper);
};

export default scrollStopEvent;
