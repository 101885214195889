import { StudyPlotType } from "../../../charting_library/charting_library";

const createLinePlot = (id: string): any => {
  return {
    id,
    type: 'line' as StudyPlotType.Line,
  };
};

const createColorerPlot = (id: string, target: string, palette: string): any => {
  return {
    id,
    target,
    palette,
    type: 'colorer' as StudyPlotType.Colorer,
  };
};

const plots = [
  createLinePlot('plot_0'),
  createColorerPlot('plot_1', 'plot_0', 'lineShort1'),
  createLinePlot('plot_2'),
  createColorerPlot('plot_3', 'plot_2', 'lineShort2'),
  createLinePlot('plot_4'),
  createColorerPlot('plot_5', 'plot_4', 'lineShort3'),
  createLinePlot('plot_6'),
  createColorerPlot('plot_7', 'plot_6', 'lineShort4'),
  createLinePlot('plot_8'),
  createColorerPlot('plot_9', 'plot_8', 'lineShort5'),
  createLinePlot('plot_10'),
  createColorerPlot('plot_11', 'plot_10', 'lineLong1'),
  createLinePlot('plot_12'),
  createColorerPlot('plot_13', 'plot_12', 'lineLong2'),
  createLinePlot('plot_14'),
  createColorerPlot('plot_15', 'plot_14', 'lineLong3'),
  createLinePlot('plot_16'),
  createColorerPlot('plot_17', 'plot_16', 'lineLong4'),
  createLinePlot('plot_18'),
  createColorerPlot('plot_19', 'plot_18', 'lineLong5'),
  createColorerPlot('plot_20', 'fill_0', 'lsColor0'),
  createColorerPlot('plot_21', 'fill_1', 'lsColor1'),
  createColorerPlot('plot_22', 'fill_2', 'lsColor2'),
  createColorerPlot('plot_23', 'fill_3', 'lsColor3'),
  createColorerPlot('plot_24', 'fill_4', 'lsColor4'),
];

export default plots;
