import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from './sass/ScreenerDropDown.module.scss';
import { ReactComponent as PlusIcon } from '../../assets/images/icons/screener/plus.svg';
import Button from '../Button/Button';
import TextField from '../TextField/TextField';
import { setModalType } from '../../store/watchlist/actions';
import useWatchList from '../../utils/hooks/useWatchList';
import WatchlistItemWrapper from './WatchListItemWrapper';
import {
  SUBSCRIPTION_TYPE, TABLE_SCREEN_PATH, TEMPLATE_TYPE, VIEW_TYPES
} from '../../constants/screener';
import { UNIVERSE_TYPE } from '../../constants/watchlist';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { checkRoleUser } from '../../utils/userHelper';
import { WidgetTypes } from '../../pages/Dashboards/types/DashboardEnums';

const ScreenerDropDown = ({
  watchListDropDownData,
  setVisibleDrop,
  watchList,
  selectedSymbols,
  multiSelect,
  moveTo,
  widgetId
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    open,
    dialogData,
    actionCancelDialog,
    actionCallback
  } = UseDashboardsDialogs();
  const { copySymbolsToWatchlist, deleteSymbolsFromWatchlist } = useWatchList();
  const favoriteTab = useSelector((state) => state.watchlistState.favoriteTab, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const lastPath = useSelector((state) => state.accountState.userSettings.lastTablePath, shallowEqual);
  const tabsList = useSelector((state) => state.watchlistState.tabsList, shallowEqual);
  const itemsWatchlist = useSelector((state) => state.watchlistState.itemsWatchlist, shallowEqual);
  const dashboardsList = useSelector((state) => state.dashboardsState.dashboards, shallowEqual);
  const selectedDashboardId = useSelector(
    (state) => state.accountState.userSettings.selectedDashboardId,
    shallowEqual
  );

  const [isFavouritesShown, setIsFavouritesShown] = useState(true);
  const currentWidget = lastPath === TABLE_SCREEN_PATH.DASHBOARDS ? dashboardsList.find(
    (dashboard) => dashboard.id === selectedDashboardId
  )?.data?.widgets.find((widget) => widget.id === widgetId) : null;
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const currentDashboardDeeplist = currentWidget?.type === WidgetTypes.DEEP_LIST ? itemsWatchlist.find(
    (item) => item.id === currentWidget.deepListId
  ) : null;
  const createWatchlistHandler = () => {
    setVisibleDrop(true);
    dispatch(setModalType(t('create')));
  };
  const getAvailableTabList = () => tabsList?.filter(
    (item) => (!item?.parentId
      && item?.data?.subType !== UNIVERSE_TYPE && !!item?.data?.color1)
  );

  const getWatchListDropDownData = () => {
    let currentDeeplist;
    if (lastPath === TABLE_SCREEN_PATH.DEEPLIST) currentDeeplist = selectedTab;
    if (lastPath === TABLE_SCREEN_PATH.DASHBOARDS) currentDeeplist = currentDashboardDeeplist;
    if (currentDeeplist && moveTo) {
      if (currentDeeplist?.id === favoriteTab?.id && isFavouritesShown) {
        setIsFavouritesShown(false);
      }
      return watchListDropDownData.filter((watchlist) => watchlist.id !== currentDeeplist?.id);
    }
    return watchListDropDownData;
  };

  const actionHandler = (watchlistItem, remove = false) => {
    const promises = watchlistItem?.viewType === VIEW_TYPES.TARGET ? getAvailableTabList()
      .filter((wlItem) => {
        return wlItem.data?.filters.flat(2)[2]?.some((item) => selectedSymbols.includes(item));
      })
      .map((wlItem) => {
        return deleteSymbolsFromWatchlist(selectedSymbols, wlItem, false);
      }) : null;
    if (moveTo) {
      if (watchlistItem?.viewType === VIEW_TYPES.TARGET) {
        Promise.all(promises).then(() => {
          deleteSymbolsFromWatchlist(selectedSymbols, selectedTab, false);
          copySymbolsToWatchlist(watchlistItem, selectedSymbols);
        });
      } else if (lastPath === TABLE_SCREEN_PATH.SCREENER || currentWidget?.type === WidgetTypes.SCREENER) {
        copySymbolsToWatchlist(watchlistItem, selectedSymbols);
      } else {
        deleteSymbolsFromWatchlist(selectedSymbols,
          lastPath === TABLE_SCREEN_PATH.DEEPLIST ? selectedTab : currentDashboardDeeplist,
          !((selectedTab?.type === TEMPLATE_TYPE.WATCHLIST && lastPath === TABLE_SCREEN_PATH.DEEPLIST)
          || currentWidget?.type === WidgetTypes.DEEP_LIST));
        copySymbolsToWatchlist(watchlistItem, selectedSymbols);
      }
      setVisibleDrop(true);
    } else if (remove) {
      const isSelectedTab = watchlistItem.id === selectedTab.id && lastPath !== t('screener');

      const isNeedUpdate = (selectedTab.viewType === VIEW_TYPES.TABLE && !selectedTab.parentId && isSelectedTab)
          || (selectedTab.viewType === VIEW_TYPES.TARGET && isSelectedTab) || watchlistItem?.id === favoriteTab?.id;

      deleteSymbolsFromWatchlist(selectedSymbols, watchlistItem, !isNeedUpdate);
      if (isSelectedTab) {
        setVisibleDrop(true);
      }
    } else if (watchlistItem?.viewType === VIEW_TYPES.TARGET) {
      Promise.all(promises).then(() => {
        copySymbolsToWatchlist(watchlistItem, selectedSymbols, true);
      });
    } else {
      if (selectedSymbols.length === 1 && watchlistItem?.data?.filters[0][0][2].some(
        (item) => item === selectedSymbols[0]
      )) {
        setVisibleDrop(false);
      }
      copySymbolsToWatchlist(watchlistItem, selectedSymbols, true);
    }
  };

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          actionCallback={actionCallback}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      <div
        className={`${styles.dropdown} ${styles.markets_list} ${watchList ? styles.dropDownWatchlist : ''}`}
        onMouseLeave={() => setVisibleDrop(false)}
      >
        <h2>
          {t(moveTo ? 'moveTo' : 'addTo')}
        </h2>
        <div className={styles.dropdownBody}>
          {
            isFavouritesShown && (
            <WatchlistItemWrapper
              key={favoriteTab.id}
              watchlistItem={favoriteTab}
              selectedSymbols={selectedSymbols}
              actionHandler={actionHandler}
              multiSelect={multiSelect}
            />
            )
          }
          {getWatchListDropDownData().map((watchlistItem, index) => (
            <WatchlistItemWrapper
              key={watchlistItem.id}
              watchlistItem={watchlistItem}
              selectedSymbols={selectedSymbols}
              actionHandler={actionHandler}
              isLast={index === watchListDropDownData.length - 1}
              multiSelect={multiSelect}
            />
          ))}
        </div>
        <Button buttonClass={styles.createWatchList} handleClick={createWatchlistHandler}>
          <div>
            <PlusIcon />
            <TextField text={t('createWatchlist')} styleType="createWatchListButton" />
          </div>
        </Button>
      </div>
    </>
  );
};

ScreenerDropDown.propTypes = {
  watchList: PropTypes.bool.isRequired,
  setVisibleDrop: PropTypes.func.isRequired,
  watchListDropDownData: PropTypes.arrayOf(PropTypes.shape({
    watchlistTitle: PropTypes.string,
    countStock: PropTypes.string
  })).isRequired,
  selectedSymbols: PropTypes.arrayOf(PropTypes.number).isRequired,
  multiSelect: PropTypes.bool,
  moveTo: PropTypes.bool,
  widgetId: PropTypes.string,
};

ScreenerDropDown.defaultProps = {
  multiSelect: false,
  moveTo: false,
  widgetId: '',
};

export default ScreenerDropDown;
