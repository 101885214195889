import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardItemDropdown.module.scss';
import TextField from '../../TextField/TextField';
import { ReactComponent as IconSearch } from '../../../assets/images/icons/screener/search.svg';
import {
  CreateFolderTypes,
  DashboardItemsEmptyStateIcons,
  DialogItemDataTypes
} from '../../DashboardItemsDialog/types/constants';

const DashboardItemDropDownEmpty = ({ search, itemType }: {
  search : string,
  itemType: DialogItemDataTypes
}): React.ReactElement => {
  const { t } = useTranslation();
  const emptyState = search === '' || !search;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const IconFromType = DashboardItemsEmptyStateIcons[itemType];
  const getHeaderTest = () => {
    if (itemType === DialogItemDataTypes.DEEP_LIST) {
      return t('noDeeplistsFound');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return emptyState ? t('dashItemEmpty', { entityName: t(CreateFolderTypes[itemType]) }) : t('noResultFound');
  };

  const getTest = () => {
    if (itemType === DialogItemDataTypes.DEEP_LIST) {
      return emptyState
        ? t('labelCreateNew', { entityName: t('Deeplist') })
        : `${t('noResultFoundDashboardItems', { entityName: t('Deeplist') })} ${search}`;
    }
    return emptyState
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ? t('labelCreateNew', { entityName: t(CreateFolderTypes[itemType]) })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      : `${t('noResultFoundDashboardItems', { entityName: t(CreateFolderTypes[itemType]) })} ${search}`;
  };
  return (
    <div className={styles.emptySearchWrapper}>
      <div className={styles.iconWrapper}>
        {emptyState ? <IconFromType /> : <IconSearch />}
      </div>
      <TextField
        text={getHeaderTest()}
        styleType="emptyWLSearchTitle"
      />
      <TextField
        text={getTest()}
        styleType="emptyWLSearchDescription"
      />
    </div>
  );
};

export default DashboardItemDropDownEmpty;
