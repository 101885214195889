import { useState, useRef, useEffect } from 'react';

import { ISelectItemsHook } from '../types/DashboardComponentsInterfaces';
import { ShortSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';

const useSelectItems: ISelectItemsHook = () => {
  const selectedItemsRef = useRef<number[]>([]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [currentCount, setCurrentCount] = useState<number>(0);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [activeSymbolIndex, setActiveSymbolIndex] = useState<number>(0);
  const selectedCount = selectedItems?.length;

  const setAllItems = (array: number[]): void => {
    if (selectedItems?.length) {
      setSelectedItems([]);
      setAllSelected(false);
      return;
    }
    setAllSelected(true);
    setSelectedItems(array || []);
  };

  const handleItemsSelect = (symbol: ShortSymbol): void => {
    const index = symbol?.sortIndex;
    const newState = [...selectedItemsRef?.current];
    const position = newState.indexOf(index);

    if (position !== -1) {
      newState?.splice(position, 1);
      setSelectedItems(newState);
    } else {
      newState.push(index);
      setSelectedItems(newState);
    }
  };

  const clearSelections = (): void => {
    setSelectedItems([]);
  };

  const updatedSelectedOnScroll = (updatedIds: number[]): void => {
    const newState = [...selectedItemsRef?.current];
    if (allSelected) {
      setSelectedItems([...new Set([...newState, ...updatedIds])]);
    }
  };

  useEffect(() => {
    selectedItemsRef.current = selectedItems;

    return () => {
      selectedItemsRef.current = [];
    };
  }, [selectedItems]);

  return {
    selectedItems,
    setAllItems,
    handleItemsSelect,
    selectedCount,
    currentCount,
    setCurrentCount,
    clearSelections,
    updatedSelectedOnScroll,
    activeSymbolIndex,
    setActiveSymbolIndex
  };
};

export default useSelectItems;
