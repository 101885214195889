import React from 'react';
import styles from '../sass/ScreenerTable.module.scss';

const ETFIndicator = ({ onClick }: { onClick: () => void }): JSX.Element => (
  <button
    aria-hidden="true"
    type="button"
    onClick={onClick}
    title="ETF"
    className={styles.etfIcon}
  >
    H
  </button>
);

export default ETFIndicator;
