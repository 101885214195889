import React from 'react';
import PropTypes from 'prop-types';
import SortIcons from '../SortIcons/SortIcons';
import { REVERSE_SORTING_COLUMNS } from '../../../constants/screener';
import styles from './sass/SortIconsGroup.module.scss';

const SortIconsGroup = ({
  index, sortHandler, sortStatus, sortScreens, isAlertTable
}) => {
  const clickHandler = () => {
    if (sortHandler) sortHandler(index);
  };

  return (
    <div
      aria-hidden="true"
      onMouseDown={clickHandler}
      className={`${styles.tableHeaderIconWrapper} ${!sortHandler ? styles.noEvent : ''}`}
    >
      <SortIcons
        isAlertTable={isAlertTable}
        reversFlag={REVERSE_SORTING_COLUMNS.includes(index)}
        value={sortScreens ? sortStatus.sortValue : sortStatus(index)?.sortValue}
      />
    </div>
  );
};
SortIconsGroup.defaultProps = {
  index: 0,
  sortScreens: false,
  sortHandler: null,
  isAlertTable: false,
};

SortIconsGroup.propTypes = {
  sortScreens: PropTypes.bool,
  index: PropTypes.number,
  sortHandler: PropTypes.func,
  sortStatus: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({})
  ]).isRequired,
  isAlertTable: PropTypes.bool
};
export default React.memo(SortIconsGroup);
