import { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import SHORT_CUTS_LIST, { TABLE_LISTENER_ID } from '../../constants/shortcuts';

const useShortcuts = () => {
  const globalSearchVisible = useSelector((state) => state.accountState.globalSearchVisible, shallowEqual);
  const showModal = useSelector((state) => state.twitterState.showShareModal, shallowEqual);
  const openAlertModal = useSelector((state) => state?.alertsState?.openAlertModal, shallowEqual);
  const modalType = useSelector((state) => state.watchlistState.modalType, shallowEqual);
  const isOpenModal = useSelector((state) => state.watchlistState.isOpenModal, shallowEqual);

  const focusTableHandler = () => {
    const currentItem = document.getElementById(TABLE_LISTENER_ID);

    if (currentItem) {
      currentItem.focus();
    }
  };

  const shortCutsHandler = (event) => {
    if (event.shiftKey && (event.key === SHORT_CUTS_LIST.X || event.key === SHORT_CUTS_LIST.x)
        && !globalSearchVisible && !showModal && !openAlertModal && !modalType && !isOpenModal) {
      event.preventDefault();
      focusTableHandler();
    }

    if (event.altKey) {
      const target = document.getElementsByClassName('focusTarget');
      const chartIFrame = target[0]?.children[0];
      const chartContext = chartIFrame?.contentWindow;
      chartContext?.frames?.focus();
    }
  };

  useEffect(() => {
    window.addEventListener(SHORT_CUTS_LIST.LISTENER_EVENT, shortCutsHandler);

    return () => {
      window.removeEventListener(SHORT_CUTS_LIST.LISTENER_EVENT, shortCutsHandler);
    };
  }, [globalSearchVisible, showModal, openAlertModal,
    modalType, isOpenModal]);
};

export default useShortcuts;
