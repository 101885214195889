import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './sass/SidebarScreener.module.scss';
import { ReactComponent as ScreenerLogoIcon } from '../../assets/images/icons/sidebarNewLogo.svg';

const Logo = ({ route }) => (
  <div
    aria-hidden="true"
  >
    <Link
      to={route}
      className={styles.logoLink}
    >
      <ScreenerLogoIcon />
    </Link>
  </div>
);

Logo.propTypes = {
  route: PropTypes.string.isRequired
};

export default React.memo(Logo);
