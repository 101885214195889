import React from 'react';
import PropTypes from 'prop-types';

const TargetListWrapperIcon = ({
  colorStart,
  iconSize
}) => {
  const size = iconSize ? (iconSize + 2) : 26;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={iconSize && { top: 0 }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len 
        d="M13.2198 0.337565C13.13 0.311085 13.0349 0.296875 12.9365 0.296875C12.3843 0.296875 11.9365 0.74459 11.9365 1.29688V3.24899C11.9365 3.80128 12.3843 4.24899 12.9365 4.24899C13.4888 4.24899 13.9365 3.80128 13.9365 3.24899V2.37647C19.1444 2.83819 23.2347 7.18801 23.2854 12.5039H22.0322C21.4799 12.5039 21.0322 12.9516 21.0322 13.5039C21.0322 14.0562 21.4799 14.5039 22.0322 14.5039H23.1105C22.2743 18.9734 18.5425 22.423 13.9365 22.8313V21.6172C13.9365 21.0649 13.4888 20.6172 12.9365 20.6172C12.3842 20.6172 11.9365 21.0649 11.9365 21.6172V22.8156C7.4042 22.3412 3.7494 18.9183 2.92464 14.4996L3.77362 14.5037C4.3259 14.5064 4.77577 14.0608 4.77844 13.5086C4.78111 12.9563 4.33556 12.5064 3.78328 12.5037L2.75053 12.4987C2.77781 9.77671 3.86305 7.30997 5.61678 5.48674C5.99965 5.08871 5.98735 4.45566 5.58931 4.0728C5.19127 3.68993 4.55823 3.70223 4.17536 4.10027C2.0551 6.30455 0.75 9.30285 0.75 12.6039C0.75 19.3793 6.24255 24.8719 13.018 24.8719C19.7934 24.8719 25.2859 19.3793 25.2859 12.6039C25.2859 5.89591 19.9021 0.445372 13.2198 0.337565Z"
        fill={colorStart}
      />
    </svg>
  );
};

TargetListWrapperIcon.propTypes = {
  colorStart: PropTypes.string.isRequired,
  iconSize: PropTypes.number,
};

TargetListWrapperIcon.defaultProps = {
  iconSize: null,
};
export default TargetListWrapperIcon;
