import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/ScreenerTable.module.scss';
import { ONE_AND_HALF, TABLE_ITEM_SETTINGS } from '../../../constants/screener';

const ValueWithDot = ({ value, dotBg }) => (value
  ? (
    <div className={styles.rv}>
      <span>{+value || TABLE_ITEM_SETTINGS.VALUE_EMPTY}</span>
      <span className={styles.dot} style={{ backgroundColor: +value > ONE_AND_HALF ? dotBg : 'transparent' }} />
    </div>
  ) : <></>
);

ValueWithDot.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  dotBg: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};

ValueWithDot.defaultProps = {
  value: null,
  dotBg: null,
};
export default ValueWithDot;
