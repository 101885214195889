const STATS_ACTION = {
  SET_LINE_DATA: '@@SET_LINE_DATA',
  SET_TABLE_DATA: '@@SET_TABLE_DATA',
  SET_NUMBER_OF_QUARTERS: '@@SET_NUMBER_OF_QUARTERS',
  SET_MERGE_TIMESTAMP: '@@SET_MERGE_TIMESTAMP',
  SET_BORDER_SETTINGS: '@@SET_BORDER_SETTINGS',
  SET_SOURCE: '@@SET_SOURCE'
};

export default STATS_ACTION;
