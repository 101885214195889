import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import { SUBSCRIPTION_TYPE } from '../../constants/screener';
import OUTER_URLS from '../../constants/outerLinks';
import styles from './sass/UpgradeAccountBanner.module.scss';

const UpgradeAccountBanner = ({
  title, buttonTitle, wrapperClass, buttonClass, height, profile, width
}) => (
  <>
    { profile?.role === SUBSCRIPTION_TYPE.FREE && (
    <div
      className={`${styles.getPremiunInfo} ${wrapperClass}`}
      style={{ height: height !== '' && height - 50, width }}
    >
      {title && <p>{title}</p>}
      <Button
        buttonClass={`
          ${styles.getPremiunInfo_button}
          ${buttonClass}
        `}
        handleClick={() => window.location.replace(OUTER_URLS.PRICING)}
      >
        {buttonTitle}
      </Button>
    </div>
    ) }
  </>
);

UpgradeAccountBanner.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profile: PropTypes.shape({
    role: PropTypes.string
  }),
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buttonTitle: PropTypes.string.isRequired,
  wrapperClass: PropTypes.string,
  buttonClass: PropTypes.string,
};

UpgradeAccountBanner.defaultProps = {
  width: '100%',
  profile: {},
  title: '',
  wrapperClass: '',
  buttonClass: '',
  height: '',
};

export default UpgradeAccountBanner;
