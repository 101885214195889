import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { setWidgetsColumnVisible } from '../../../store/dashboards/slice';
import { IUseColumnsModalWorker } from '../types/DashboardComponentsInterfaces';

const useWidgetColumnsModalWorker: IUseColumnsModalWorker = () => {
  const dispatch = useDispatch();
  const widgetsColumnVisible = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetsColumnVisible, shallowEqual
  );

  const closeColumnsModal = (id: string): void => {
    const newWidgetsColumnVisible = { ...widgetsColumnVisible };
    newWidgetsColumnVisible[id] = false;
    dispatch(setWidgetsColumnVisible(newWidgetsColumnVisible));
  };

  const openColumnsModal = (id: string): void => {
    const newWidgetsColumnVisible = { ...widgetsColumnVisible };
    if (newWidgetsColumnVisible[id]) {
      closeColumnsModal(id);
    } else {
      Object.keys(newWidgetsColumnVisible).forEach((key: string) => {
        newWidgetsColumnVisible[key] = false;
      });
      newWidgetsColumnVisible[id] = true;
      dispatch(setWidgetsColumnVisible(newWidgetsColumnVisible));
    }
  };
  return {
    openColumnsModal,
  };
};

export default useWidgetColumnsModalWorker;
