import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../../sass/ScreenerTable.module.scss';

import Symbol from '../Symbol';
import TargetListDropdown from './TargetListDropdown';
import { useTheme } from '../../../../utils/hooks/useTheme';
import ThemeVariants from '../../../../constants/theme';
import {
  CompanyDescriptionTooltipStyles,
  TargetListTooltipStyles,
  TargetListTooltipDarkStyles,
  CompanyDescriptionTooltipDarkStyles
} from '../../ScreenerTooltipStyles';

const TargetListWrapper = ({
  item,
  targetLists,
  currentTargetList,
  addSymbolToTargetList,
  handleRemoveSymbolFromTargetList,
  setToggleTargetList,
  toggleTargetList,
  targetListDisabled,
  showDescription,
  setShowDescription,
  description,
  hideCheckbox,
  watchList,
  alertTable,
  defaultDescriptionHover,
  widgetId,
  selectedItemFromWidget,
  showWeight,
  selectedRowsFromWidget,
  clearSelections,
  iconSize,
  onMouseEnter,
  onMouseLeave,
}) => {
  const hoveredSymbolIndex = useSelector((state) => state.tableDataState.hoveredSymbolIndex, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreenId = useSelector((state) => state.accountState.userSettings.selectedScreenId, shallowEqual);

  const { theme } = useTheme();
  const widgetCoord = document.getElementById(`gs-item-${widgetId}`)
    && document.getElementById(`gs-item-${widgetId}`).getBoundingClientRect();

  useEffect(() => {
    if (!toggleTargetList) {
      onMouseLeave();
    }
  }, [toggleTargetList]);

  const title = alertTable ? <></> : (
    <TargetListDropdown
      item={item}
      theme={theme}
      targetLists={targetLists}
      currentTargetList={currentTargetList}
      addSymbolToTargetList={(props) => {
        addSymbolToTargetList(props);
        setToggleTargetList(false);
      }}
      handleRemoveSymbolFromTargetList={handleRemoveSymbolFromTargetList}
      setToggleTargetList={setToggleTargetList}
      watchList={watchList}
      widgetId={widgetId}
      selectedItemFromWidget={selectedItemFromWidget}
      showWeight={showWeight}
      selectedRowsFromWidget={selectedRowsFromWidget}
      clearSelections={clearSelections}
    />
  );
  return (
    <Tooltip
      title={title}
      placement="bottom-start"
      aria-label="add"
      open={toggleTargetList}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      componentsProps={theme === ThemeVariants.DARK_MODE ? TargetListTooltipDarkStyles : TargetListTooltipStyles}
    >
      <Tooltip
        title={showDescription && description ? (
          <div
            className={`${styles.descriptionWrapper} ${theme}`}
            style={{
              maxWidth: `${widgetCoord?.width - 35 || '500'}px`,
            }}
          >
            {description}
          </div>
        ) : ''}
        placement="bottom-start"
        aria-label="add"
        open={defaultDescriptionHover && showDescription}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        componentsProps={theme === ThemeVariants.DARK_MODE
          ? CompanyDescriptionTooltipDarkStyles : CompanyDescriptionTooltipStyles}
      >
        <div>
          <Symbol
            itemIcon={item.icon}
            itemIndex={item.index}
            id={currentTargetList?.id}
            colorStart={currentTargetList?.data?.color1}
            colorEnd={currentTargetList?.data?.color2}
            hideCheckbox={hideCheckbox}
            setToggleTargetList={
              () => {
                if (targetLists.length > 0 && !targetListDisabled) {
                  setShowDescription(false);
                  setToggleTargetList(!toggleTargetList);
                }
              }
            }
            iconSize={iconSize}
            onMouseEnter={() => {
              if (!alertTable) {
                const timeoutSet = setTimeout(() => {
                  onMouseEnter();
                  clearTimeout(timeoutSet);
                }, 100);
              }
            }}
            onMouseLeave={
              () => {
                if (!toggleTargetList) {
                  const timeoutSet = setTimeout(() => {
                    onMouseLeave();
                    clearTimeout(timeoutSet);
                  }, 100);
                }
              }
            }
            showTargetPlus={item.index === hoveredSymbolIndex.index
              && (widgetId === hoveredSymbolIndex.dataId || (watchList ? selectedTab.id === hoveredSymbolIndex.dataId
                : selectedScreenId === hoveredSymbolIndex.dataId))}
          />
        </div>
      </Tooltip>
    </Tooltip>
  );
};

TargetListWrapper.propTypes = {
  handleRemoveSymbolFromTargetList: PropTypes.func.isRequired,
  showDescription: PropTypes.bool.isRequired,
  defaultDescriptionHover: PropTypes.bool,
  setShowDescription: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  item: PropTypes.shape({
    icon: PropTypes.string,
    index: PropTypes.number,
    sym: PropTypes.string,
    sortIndex: PropTypes.number
  }).isRequired,
  currentTargetList: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      color1: PropTypes.string,
      color2: PropTypes.string,
    })
  }),
  setToggleTargetList: PropTypes.func.isRequired,
  toggleTargetList: PropTypes.bool.isRequired,
  targetLists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addSymbolToTargetList: PropTypes.func.isRequired,
  targetListDisabled: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  watchList: PropTypes.bool,
  alertTable: PropTypes.bool,
  widgetId: PropTypes.string,
  selectedItemFromWidget: PropTypes.number,
  iconSize: PropTypes.number,
  showWeight: PropTypes.bool,
  selectedRowsFromWidget: PropTypes.arrayOf(PropTypes.number),
  clearSelections: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

TargetListWrapper.defaultProps = {
  currentTargetList: null,
  targetListDisabled: false,
  hideCheckbox: false,
  watchList: false,
  alertTable: false,
  defaultDescriptionHover: true,
  widgetId: '',
  selectedItemFromWidget: 0,
  showWeight: false,
  selectedRowsFromWidget: [],
  clearSelections: () => undefined,
  iconSize: null,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
};

export default TargetListWrapper;
