import React from 'react';
import { ReactComponent as ArrowTopColor } from '../../../assets/images/icons/screener/arrowTopColor.svg';
import { ReactComponent as ArrowDownColor } from '../../../assets/images/icons/screener/arrowDownColor.svg';
import { ReactComponent as ArrowTopWhite } from '../../../assets/images/icons/screener/arrowTopWhite.svg';
import { ReactComponent as ArrowDownWhite } from '../../../assets/images/icons/screener/arrowWhiteDown.svg';

interface Props {
  value: number;
}

const SortIcons = ({
  value
}: Props) => (
  <>
    {!value ? <ArrowTopColor /> : <ArrowTopWhite />}
    {value ? <ArrowDownColor /> : <ArrowDownWhite />}
  </>
);

export default React.memo(SortIcons);
