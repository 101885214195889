import React from 'react';
import TableView from './TableView';
import TableProvider from './context/TableProvider';

const Table = () => (
  <TableProvider>
    <TableView />
  </TableProvider>
);

export default Table;
