import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SearchField from '../../SearchField/SearchField';
import styles from '../sass/ScreenerMenu.module.scss';
import ComingSoonTooltip from '../../ComingSoonTooltip/ComingSoonTooltip';
import TargetMenuButton from '../../TargetMenuButton/TargetMenuButton';

const SymbolSearch = ({
  setFocus, search, resetSearchHandler, isFree
}) => {
  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <>
      <TargetMenuButton />
      <SearchField
        placeholder={t('placeholderSearch')}
        headerResult={t('searchResults')}
        setFocus={setFocus}
        value={search}
        resetSearchHandler={resetSearchHandler}
        styleType="headerSearchField"
        setTooltipVisible={setTooltipVisible}
        isFree={isFree}
      />
      {
        tooltipVisible
        && <ComingSoonTooltip hasIcon={false} title={t('upgradeToSeeMore')} tooltipClass={styles.tooltipFree} />
      }
    </>
  );
};

SymbolSearch.propTypes = {
  setFocus: PropTypes.func,
  search: PropTypes.string.isRequired,
  resetSearchHandler: PropTypes.func,
  isFree: PropTypes.bool,
};

SymbolSearch.defaultProps = {
  setFocus: () => undefined,
  isFree: false,
  resetSearchHandler: () => undefined,
};

export default (SymbolSearch);
