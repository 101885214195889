import { HELPER_PERIOD, DATE_VECTOR } from '../constants/rangeDatePicker';

class DateHelper {
  /**
     * Get array with date period.
     * @param {Date || null} date  - The start date value or null.
     * @param {string[]} actions - The array with actions like that ['week', 'next'].
     */
  constructor({
    date, actions, isNeedCorrect, endDate
  }) {
    this.isNeedCorrect = isNeedCorrect;
    this.startCorrect = date;
    this.endCorrect = endDate;
    this.date = date && !isNeedCorrect ? new Date(date) : new Date();
    this.result = [];
    [this.manipulationPeriod, this.vector] = actions;
  }

  getMonth() {
    const month = this.vector === DATE_VECTOR.NEXT
      ? (this.date.getMonth() + 1)
      : (this.date.getMonth() - 1);
    this.date.setMonth(month);
  }

  getYear() {
    const year = this.vector === DATE_VECTOR.NEXT
      ? this.date.getFullYear() + 1
      : this.date.getFullYear() - 1;
    this.date.setFullYear(year);
  }

  getWeek() {
    const week = this.vector === DATE_VECTOR.NEXT
      ? this.date.getDate() + 7
      : this.date.getDate() - 7;
    this.date.setDate(week);
  }

  getQuarter() {
    const startMonth = new Date();
    const endMonth = new Date();
    endMonth.setMonth(endMonth.getMonth() + 3);
    return [startMonth, endMonth];
  }

  correctNextDate(startDate) {
    if (startDate > this.endCorrect) {
      return [startDate, this.endCorrect];
    }
    return [startDate, this.date];
  }

  correctLastDate(startDate) {
    if (startDate < this.startCorrect) {
      return [this.endCorrect, startDate];
    }
    return [this.date, startDate];
  }

  correctDate(startDate) {
    if (this.vector === DATE_VECTOR.NEXT) {
      return this.correctNextDate(startDate);
    }
    return this.correctLastDate(startDate);
  }

  getPeriod() {
    const startDate = new Date(this.date);
    switch (this.manipulationPeriod) {
      case HELPER_PERIOD.QUARTER:
        return this.getQuarter();
      case HELPER_PERIOD.MONTH:
        this.getMonth();
        break;
      case HELPER_PERIOD.YEAR:
        this.getYear();
        break;
      default:
        this.getWeek();
    }
    if (this.isNeedCorrect) {
      return this.correctDate(startDate);
    }
    if (this.vector === DATE_VECTOR.NEXT) {
      return [startDate, this.date];
    }
    return [this.date, startDate];
  }
}

export default DateHelper;
