export const GROWS_DATA = 'growsData';
export const SELECTED_CHART = 'selectedChart';
export const CHECKED_FILTERS = 'checkedFilters';
export const ACTIVE_LIMITATION = 'activeLimitation';
export const NUMBER_OF_QUARTERS = 'numberOfQuarters';
export const ACTIVE_CHART_LAYOUT_ID = 'activeChartLayoutId';
export const MOBILE_ACTIVE_CHART_LAYOUT_ID = 'activeChartLayoutIdMobile';
export const ESTIMATE_VISIBLE_DATA = 'estimateVisibleData';
export const WATCHLIST_PARENT_ID = 'selectedTabParentId';
export const ALERT_TABLE_SORTING = 'alertSorting';
export const RESET_CHART_VIEW = 'Reset chart view';
export const ACCESS_TOKEN = 'accessToken';
export const WS_CHART_ERROR = 'wsChartError';
export const WS_CHART_PING_RESPONSE = 'pingResponse';
export const STATS_CHART_SOURCES = 'statsSources';
export const SEARCH_HISTORY = 'searchHistory';
export const VISIBILITY_SETTINGS = 'visibilitySetting';
export const STORED_SESSION = 'currentSessionFromStore';
export const STORED_SESSION_MOBILE = 'currentSessionFromStoreMobile';
export const LAST_UI_SETTINGS = 'layoutFlag';
export const LAST_DASHBOARDS_TAB = 'selectedDashboardId';
export const LAST_TABLE_PATH = 'lastTablePath';
export const THEME = 'theme';
export const SELECTED_TAB_ID = 'selectedTabId';
export const SELECTED_DATA_PANEL_ID = 'selectedDataPanelId';
export const SCREENER_SYMBOL = 'lastScreenerSymbol';
export const DL_SYMBOL = 'lastDeepListSymbol';
export const ALERTS_SYMBOL = 'lastAlertsSymbol';
export const ALERTS_COLUMNS = 'alertColumns';
export const MAIN_SIDE_BAR_TOGGLE = 'isHideSideMenu';
export const SESSION_STORAGE_CLIENT_ID = 'sessionClientId';
export const PSC_UPSERT = 'pscupsert';
export const PSC_REMOVE_WIDGET = 'pscremovewidget';
export const PSC_REMOVE_DASHBOARD = 'pscremovedashboard';
export const WIDGET_CHARTS_UPSERT = 'widgetchartsupsert';
export const WIDGET_CHARTS_REMOVE_WIDGET = 'widgetchartsremovewidget';
export const WIDGET_CHARTS_REMOVE_DASHBOARD = 'widgetchartsremovedashboard';
export const REFRESH_TOKEN = 'refreshToken';
export const DATA_PANEL_SETTINGS = 'dataPanelSettings';
export const IS_INDUSTRY_OPEN = 'isIndustryOpen';
export const IS_SECTOR_OPEN = 'isSectorOpen';
export const IS_GROUP_OPEN = 'isGroupOpen';
export const IS_SUB_INDUSTRY_OPEN = 'isSubIndustryOpen';
export const RE_AUTH_QUERY = 'reauth=1';
export const ALERT_SYMBOL = 'alertSymbol';
