import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as CheckedIcon } from '../../assets/images/icons/Check.svg';
import styles from './sass/SelectGroupFilter.module.scss';
import TextField from '../TextField/TextField';
import { TCommonItem } from '../FilterScreen/Types';

interface IDropList {
  data: TCommonItem[];
  handlerChange: (nameItem: string) => void;
  selectedItem: TCommonItem;
  bottomFlag: boolean;
}

const DropList = ({
  data, handlerChange, selectedItem, bottomFlag
}: IDropList): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null);
  const [itemHeight, setItemHeight] = useState(110);

  useEffect(() => {
    const refHeight = ref?.current?.getBoundingClientRect()?.height as number;
    setItemHeight(refHeight + 7);
  }, []);
  return (
    <div
      ref={ref}
      className={styles.dropDown}
      style={!bottomFlag ? { bottom: -itemHeight } : { top: -itemHeight }}
    >
      <div className={styles.dropDownWraper}>
        {data?.map((item, index) => (
          <div
            key={[item.name, index].join('_')}
            className={styles.optionContainer}
            onClick={() => handlerChange(item?.name)}
            onKeyDown={() => handlerChange(item?.name)}
            role="button"
            aria-hidden="true"
            tabIndex={0}
          >
            {item.name === selectedItem.name
              ? <CheckedIcon /> : <div className={styles.emptyBox} />}
            <TextField
              styleType={item.name === selectedItem.name ? 'selectedFiltersOptionActive' : 'selectedFiltersOption'}
              text={item.name || ''}
              key={[item.name, index].join('_')}
            />
          </div>
        ))}

      </div>
    </div>
  );
};

export default DropList;
