import { StudyPlotType } from "../../../charting_library/charting_library";

export const plots = [
  { id: 'intraday_ma_1_plot', type: 'line' as StudyPlotType.Line },
  { id: 'intraday_ma_2_plot', type: 'line' as StudyPlotType.Line },
  { id: 'intraday_ma_3_plot', type: 'line' as StudyPlotType.Line },
  { id: 'intraday_ma_4_plot', type: 'line' as StudyPlotType.Line },
  { id: 'intraday_ma_5_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_1_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_2_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_3_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_4_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_5_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_6_plot', type: 'line' as StudyPlotType.Line },
  { id: 'daily_ma_7_plot', type: 'line' as StudyPlotType.Line },
  { id: 'weekly_ma_1_plot', type: 'line' as StudyPlotType.Line },
  { id: 'weekly_ma_2_plot', type: 'line' as StudyPlotType.Line },
  { id: 'weekly_ma_3_plot', type: 'line' as StudyPlotType.Line },
  { id: 'weekly_ma_4_plot', type: 'line' as StudyPlotType.Line },
  { id: 'weekly_ma_5_plot', type: 'line' as StudyPlotType.Line },
  { id: 'monthly_ma_1_plot', type: 'line' as StudyPlotType.Line },
  { id: 'monthly_ma_2_plot', type: 'line' as StudyPlotType.Line },
  { id: 'monthly_ma_3_plot', type: 'line' as StudyPlotType.Line },
  { id: 'monthly_ma_4_plot', type: 'line' as StudyPlotType.Line },
  { id: 'monthly_ma_5_plot', type: 'line' as StudyPlotType.Line },
];
