import { useState, useEffect, useRef } from 'react';

import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import { IWidgetHeaderHook } from '../types/DashboardComponentsInterfaces';
import RootStateTypes from '../../../store/RootStateTypes';
import { DeepListItemData, ScreenItemData, TDashboardItemsData } from '../../../utils/Types';
import { findItem } from '../utils/utils';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { setSelectedScreen, setNewScreenFlag } from '../../../store/newScreen/actions';
import { setModalType } from '../../../store/watchlist/actions';
import { setSelectedWidgetOnCreateId } from '../../../store/dashboards/slice';

const useWidgetHeader: IWidgetHeaderHook = (widget, updateWidget) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newScreenRef = useRef<ScreenItemData[]>([]);
  const deeplistsRef = useRef<DeepListItemData[]>([]);

  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [isColorDropOpen, setIsColorDropOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(t(widget.type === WidgetTypes.SCREENER ? 'screener' : 'deepListName'));
  const [selectedItem, setSelectedItem] = useState<TDashboardItemsData | null>(null);

  const tabsList = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const widgetConnectionQueries = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetConnectionQueries, shallowEqual
  );
  const itemsNewScreen = useSelector(
    (state: RootStateTypes) => state.newScreenState.itemsNewScreen, shallowEqual
  );
  const itemsDeeplist = useSelector(
    (state: RootStateTypes) => state.watchlistState.itemsWatchlist, shallowEqual
  );
  const presets = useSelector(
    (state: RootStateTypes) => state.watchlistState.presets, shallowEqual
  );
  const presetsNewScreen = useSelector(
    (state: RootStateTypes) => state.newScreenState.presetsNewScreen, shallowEqual
  );
  const selectedWidgetOnCreateId = useSelector(
    (state: RootStateTypes) => state.dashboardsState.selectedWidgetOnCreateId, shallowEqual
  );

  const getDiffScreen = (newScreen: ScreenItemData[], oldScreen: ScreenItemData[]): ScreenItemData | null => {
    if (JSON.stringify(newScreen) !== JSON.stringify(oldScreen)
      && widgetConnectionQueries[Number(widget.id)]) {
      let CleanNewScreens: ScreenItemData[] = [];
      let CleanOldScreens: ScreenItemData[] = [];
      newScreen.forEach((item: ScreenItemData) => {
        if (item?.items?.length) {
          CleanNewScreens = [...CleanNewScreens, ...item?.items];
        } else {
          CleanNewScreens.push(item);
        }
      });
      oldScreen.forEach((item: ScreenItemData) => {
        if (item?.items?.length) {
          CleanOldScreens = [...CleanOldScreens, ...item?.items];
        } else {
          CleanOldScreens.push(item);
        }
      });
      if (CleanNewScreens.length < CleanOldScreens.length) return null;
      const foundScreens = CleanNewScreens.filter(({ id: id1 }) => !CleanOldScreens.some(({ id: id2 }) => id2 === id1));
      return foundScreens?.length ? foundScreens[0] : null;
    }
    return null;
  };

  const getDiffDeeplist = (deeplists: DeepListItemData[], olddDeeplists: DeepListItemData[]):
  DeepListItemData | null => {
    if (JSON.stringify(deeplists) !== JSON.stringify(olddDeeplists)
      && widgetConnectionQueries[Number(widget.id)]) {
      let CleanNewDeeplists: DeepListItemData[] = [];
      let CleanOldDeeplists: DeepListItemData[] = [];
      deeplists.forEach((item: DeepListItemData) => {
        if (item?.items?.length) {
          CleanNewDeeplists = [...CleanNewDeeplists, ...item?.items];
        } else {
          CleanNewDeeplists.push(item);
        }
      });
      olddDeeplists.forEach((item: DeepListItemData) => {
        if (item?.items?.length) {
          CleanOldDeeplists = [...CleanOldDeeplists, ...item?.items];
        } else {
          CleanOldDeeplists.push(item);
        }
      });
      if (CleanNewDeeplists.length < CleanOldDeeplists.length) return null;
      const foundScreens = CleanNewDeeplists.filter(
        ({ id: id1 }) => !CleanOldDeeplists.some(({ id: id2 }) => id2 === id1)
      );
      return foundScreens?.length ? foundScreens[0] : null;
    }
    return null;
  };

  const setFilters = (isNew: boolean) => {
    dispatch(setSelectedWidgetOnCreateId(widget.id));
    if (selectedItem && !isNew) {
      dispatch(setSelectedScreen(selectedItem));
      dispatch(setNewScreenFlag(false));
    } else {
      dispatch(setSelectedScreen({}));
      dispatch(setNewScreenFlag(true));
    }
  };

  const createDeepListModal = () => {
    dispatch(setSelectedWidgetOnCreateId(widget.id));
    dispatch(setModalType(t('create')));
  };

  useEffect(() => {
    if (widget.type === WidgetTypes.SCREENER) {
      const foundScreen = findItem(itemsNewScreen, Number(widget?.screenerId));
      const foundPreset = findItem(presetsNewScreen, Number(widget?.screenerId));
      const foundItem = foundScreen || foundPreset as ScreenItemData;
      if (foundItem) {
        if (foundItem?.items?.length) {
          const deepFound = foundItem?.items?.find(
            (item: ScreenItemData) => item.id === widget?.screenerId
          ) as unknown as ScreenItemData;
          if (deepFound) {
            setTitle(deepFound?.title || '');
            setSelectedItem(deepFound);
          }
        } else {
          setTitle(foundItem?.title || '');
          setSelectedItem(foundItem as ScreenItemData | DeepListItemData);
        }
      }
    } else {
      const deepList = [...tabsList, ...presets].find(
        (item: DeepListItemData) => item.id === widget?.deepListId
      );
      if (deepList) {
        setTitle(deepList.title);
        setSelectedItem(deepList as DeepListItemData);
      } else {
        const universeTab = tabsList.find((item: DeepListItemData) => item.data?.subType === UNIVERSE_TYPE);
        setTitle(universeTab?.title);
        setSelectedItem(universeTab as DeepListItemData);
      }
    }
  }, [widget, tabsList, itemsNewScreen, presetsNewScreen, itemsDeeplist]);

  useEffect(() => {
    if (newScreenRef.current.length && widget.type === WidgetTypes.SCREENER) {
      const newScreen = getDiffScreen(itemsNewScreen, newScreenRef.current);
      if (newScreen && widget.id === selectedWidgetOnCreateId) {
        setTitle(newScreen?.title || '');
        setSelectedItem(newScreen);
        updateWidget({ ...widget, screenerId: newScreen?.id });
      }
    }
    newScreenRef.current = itemsNewScreen;
  }, [itemsNewScreen]);

  useEffect(() => {
    if (deeplistsRef.current.length && widget.type === WidgetTypes.DEEP_LIST) {
      const newDeeplist = getDiffDeeplist(itemsDeeplist, deeplistsRef.current);
      if (newDeeplist && widget.id === selectedWidgetOnCreateId) {
        setSelectedItem(newDeeplist);
        setTitle(newDeeplist?.title || '');
        updateWidget({ ...widget, deepListId: newDeeplist?.id });
      }
    }
    deeplistsRef.current = itemsDeeplist;
  }, [itemsDeeplist]);

  useEffect(() => {
    dispatch(setSelectedWidgetOnCreateId(null));
  }, []);

  return {
    setIsDropDownOpen,
    setIsColorDropOpen,
    setFilters,
    setTitle,
    isDropDownOpen,
    isColorDropOpen,
    title,
    selectedItem,
    setSelectedItem,
    createDeepListModal
  };
};

export default useWidgetHeader;
