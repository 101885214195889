import { ADDRESS, WS_CHANNELS } from '../../constants/socketConnection';
import { MS_INTERVAL } from '../../constants/screener';
import { isFunction } from '../helpers';

export const repeatItems: { [key: string]: () => void } = {
  [WS_CHANNELS.SCREENER_PATCH]: () => undefined
};

export const useRepeat = (address: string, widgetId = ''): void => {
  const interval: { [key: string]: NodeJS.Timer | null } = {};

  if (address === ADDRESS.CHART && isFunction(repeatItems[WS_CHANNELS.CHART_SUBSCRIBE])) {
    interval.chart = setTimeout(() => {
      repeatItems[WS_CHANNELS.CHART_SUBSCRIBE]();
      clearTimeout(interval.chart as NodeJS.Timer);
      interval.chart = null;
    }, MS_INTERVAL.TWO_SECOND);
  }

  if (address === ADDRESS.TABLE && isFunction(repeatItems[WS_CHANNELS.SCREENER_PATCH])) {
    interval.table = setTimeout(() => {
      repeatItems[WS_CHANNELS.SCREENER_PATCH]();
      clearTimeout(interval.table as NodeJS.Timer);
      interval.table = null;
    }, MS_INTERVAL.TWO_SECOND * 2);
  }

  if (address === ADDRESS.DATA_PANEL && isFunction(repeatItems[WS_CHANNELS.DATA_PANEL])) {
    interval.panel = setTimeout(() => {
      repeatItems[WS_CHANNELS.DATA_PANEL]();
      clearTimeout(interval.panel as NodeJS.Timer);
      interval.panel = null;
    }, MS_INTERVAL.TWO_SECOND * 2);
  }

  if (address === ADDRESS.WIDGET && isFunction(repeatItems[widgetId])) {
    interval[widgetId] = setTimeout(() => {
      repeatItems[widgetId]();
      clearTimeout(interval[widgetId] as NodeJS.Timer);
      interval[widgetId] = null;
    }, MS_INTERVAL.TWO_SECOND * 2);
  }
};
