import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Header.module.scss';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';
import notificationsIcon from '../../../assets/images/icons/notification.svg';
import NotificationDropdown from '../NotificationDropdown';
import ComingSoonTooltip from '../../ComingSoonTooltip/ComingSoonTooltip';

const HeaderNotifications = ({
  notificationsDropdownVisible,
  closeDropDawn,
  notificationsData,
  openNotification
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div className={styles.notifications}>
      <Button
        buttonClass={styles.notifications_button}
        handleClick={openNotification}
        mouseEnterHandler={() => { setTooltipVisible(true); }}
        mouseLeaveHandler={() => { setTooltipVisible(false); }}
      >
        <Icon
          icon={notificationsIcon}
          iconClass={styles.notifications_icon}
        />
      </Button>
      { !!notificationsData.filter((item) => item.new).length && (
      <span className={styles.notifications_counter}>
        {notificationsData.filter((item) => item.new).length}
      </span>
      )}
      {
        notificationsDropdownVisible && (
          <NotificationDropdown
            notificationsData={notificationsData}
            closeDropDawn={closeDropDawn}
          />
        )
      }
      {
        tooltipVisible && (
          <ComingSoonTooltip 
            tooltipClass={styles.notifications_tooltip}
          />
        )
      }
    </div>
  );
};

HeaderNotifications.propTypes = {
  notificationsDropdownVisible: PropTypes.bool,
  openNotification: PropTypes.func,
  closeDropDawn: PropTypes.func,
  notificationsData: PropTypes.arrayOf(PropTypes.shape({}))
};

HeaderNotifications.defaultProps = {
  notificationsDropdownVisible: false,
  openNotification: () => {},
  closeDropDawn: () => {},
  notificationsData: []
};

export default HeaderNotifications;
