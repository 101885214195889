import React, { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styles from '../sass/DataPanel.module.scss';
import ScreenerTableItem from '../../Screener/ScreenerTableItem';

const DataPanelDynamicContentItem = ({
  dataPoint, screenerId, symbolIndex, currentData
}) => {
  const volumeDayHistory = useSelector((state) => state.screenerState.volumeDayHistory, shallowEqual);

  const changeHandler = useCallback(
    () => undefined,
    [],
  );

  return (
    <div className={styles.dataPanelItemDataWrapper}>
      <ScreenerTableItem
        type={dataPoint?.type}
        firstElement={false}
        dataPanelQueryId={screenerId}
        column={3}
        columnIndex={dataPoint?.value}
        item={currentData}
        symbolIndex={symbolIndex}
        name={dataPoint?.label}
        changeHandler={changeHandler}
        volumesHistoryData={volumeDayHistory[symbolIndex]}
        dataPanel
      />
    </div>
  );
};

DataPanelDynamicContentItem.propTypes = {
  currentData: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  dataPoint: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
  }),
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  symbolIndex: PropTypes.number.isRequired,
};

DataPanelDynamicContentItem.defaultProps = {
  currentData: '',
  screenerId: null,
  dataPoint: null,
};

export default DataPanelDynamicContentItem;
