import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NumberFormatValues, NumericFormat } from 'react-number-format';
import styles from './sass/InputEdit.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import { transformData } from '../../utils/convertingDataHelper';
import { workWithInputToolTip } from '../../utils/helperFilter';
import constants, { STYLES_FILTER } from '../../constants/filters';
import InputField from '../InputField/InputField';
import TextField from '../TextField/TextField';
import { DATA_TYPES } from '../../constants/screener';
import numberConstants from '../../constants/numberConstants';

type InputEditProps = {
  value: number;
  typeData: string;
  setInputValue: (value: number) => void;
  setPosition: () => void;
  setIsChanged: (value: boolean) => void;
  widthItem: string;
};

const InputEdit = ({
  value, typeData, setInputValue, setPosition, widthItem, setIsChanged
}: InputEditProps): JSX.Element => {
  const [active, setActive] = useState(false);
  const [isMinusZero, setIsMinusZero] = useState(false);
  const labelButton = transformData(typeData, +value);
  const styleButton = widthItem || STYLES_FILTER.INPUT_TEXT;

  const activeHandler = () => {
    setActive(!active);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /^[kmbt]{1}$/i;
    if (event.key === constants.enter) {
      setActive(false);
      setPosition();
    }
    if (event.key === constants.arrowLeft || event.key === constants.arrowRight) {
      event.stopPropagation();
    }
    if (regex.test(event.key)) {
      let val = value;
      switch (true) {
        case /k/i.test(event.key):
          val = value * 10 ** 3;
          break;
        case /m/i.test(event.key):
          val = value * 10 ** 6;
          break;
        case /b/i.test(event.key):
          val = value * 10 ** 9;
          break;
        case /t/i.test(event.key):
          val = value * 10 ** 12;
          break;
        default:
      }
      if (val && (DATA_TYPES.includes(typeData) || DATA_TYPES.includes(typeData?.split('_')[0]))) {
        val *= numberConstants.percent;
      }
      setInputValue(val);
    }
  };

  const onValueChange = (values: NumberFormatValues) => {
    setIsChanged(true);
    if (values.value === '-') setIsMinusZero(true);
    if (Number(String(values.floatValue)) === 0 && !isMinusZero) {
      setInputValue(Number(String(values.floatValue) || NaN));
    } else setInputValue(Number(values.floatValue || NaN));
  };

  useEffect(() => {
    if (Number.isNaN(value)) {
      setIsMinusZero(false);
    }
  }, [String(value).length]);

  return (
    <div className={styles.container}>
      {!active ? (
        <DefaultButton
          buttonClass={styleButton}
          handleClick={activeHandler}
        >
          <TextField
            styleType={styleButton}
            text={labelButton}
          />
        </DefaultButton>
      ) : (
        <NumericFormat
          decimalScale={15}
          thousandSeparator=","
          allowNegative
          value={value === 0 ? '' : workWithInputToolTip(value, typeData)}
          onValueChange={onValueChange}
          customInput={InputField}
          name={constants.minMax}
          type="text"
          autofocus={active}
          onBlur={() => {
            activeHandler();
            setPosition();
          }}
          placeholder=""
          maxlength={20}
          disable={false}
          requiredInput={false}
          handleKeyPress={handleKeyPress}
          styleType={widthItem}
        />
      )}
    </div>
  );
};

InputEdit.propTypes = {
  typeData: PropTypes.string,
  widthItem: PropTypes.string,
  setPosition: PropTypes.func,
  setInputValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

InputEdit.defaultProps = {
  value: 0,
  typeData: '',
  widthItem: '',
  setPosition: () => null,
  setInputValue: () => null,
};

export default InputEdit;
