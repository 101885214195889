import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../components/Input/Input';
import Checkbox from '../../../components/Checkbox/Checkbox';
import AlertModalItem from './AlertModalItem';
import styles from '../sass/TableScreener.module.scss';

const AlertModalItemRow = ({
  row, index, handleChangeNotifyState, errorMessage,
  handleNote, alertState, headLabel, valueError
}) => {
  const getRowClassName = (element) => {
    return `${styles.alertWrapItemCol} ${element.additionalClass && styles?.[element.additionalClass]}`;
  };

  const numberValueValidator = (value) => {
    if (value === 0) return '';
    return typeof value === 'number' ? value?.toFixed(2) : value;
  };

  return (
    <AlertModalItem headClass={row?.headClass} head={headLabel}>
      {row?.elements.map((element) => (
        <div
          key={[element.name, index].join('_')}
          className={getRowClassName(element)}
        >
          {element.type === 'checkbox' && (
            <Checkbox
              id={element.name}
              name={element.name}
              title={element.label}
              textStyle="alertCheckbox"
              changeHandler={handleChangeNotifyState}
              checked={alertState?.[element.name]}
            />
          )}
          {element.type === 'text' && (
            <Input
              type="text"
              name={element.name}
              handlerChange={handleNote}
              value={alertState?.[element.name] || ''}
              inputClass={styles.noteInput}
              placeholder={element.label}
              maxLength="120"
            />
          )}
          {element.type === 'number' && (
            <Input
              type="number"
              name={element.name}
              handlerChange={handleNote}
              autoFocus
              value={numberValueValidator(alertState?.[element.name])}
              inputClass={styles.noteInput}
              placeholder={element.label}
              step="0.01"
              error={valueError}
              message={errorMessage}
            />
          )}
        </div>
      ))}
    </AlertModalItem>
  );
};

AlertModalItemRow.propTypes = {
  alertState: PropTypes.shape({}),
  row: PropTypes.shape({
    head: PropTypes.string,
    headClass: PropTypes.string,
    elements: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  index: PropTypes.number.isRequired,
  handleChangeNotifyState: PropTypes.func.isRequired,
  handleNote: PropTypes.func.isRequired,
  headLabel: PropTypes.string.isRequired,
  valueError: PropTypes.bool,
  errorMessage: PropTypes.string,
};

AlertModalItemRow.defaultProps = {
  alertState: null,
  valueError: false,
  errorMessage: ''
};

export default React.memo(AlertModalItemRow);
