import { useCallback, useEffect, useState } from 'react';
import { toPng } from 'html-to-image';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setScreenShot } from '../../../store/twitter/slice';

const useScreenshot = () => {
  const dispatch = useDispatch();

  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const lastScreenerSymbol = useSelector((state) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual);
  const lastDeepListSymbol = useSelector((state) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual);
  const lastAlertsSymbol = useSelector((state) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual);
  const [twittText, setTwittText] = useState('');

  const noImg = `${process.env.REACT_APP_ICON_PATH}no_icon.png`;
  const cleanUpScreenshotData = useCallback(
    () => {
      dispatch(setScreenShot(null));
      setTwittText('');
    },
    [],
  );

  const snapElement = async (elem) => toPng(elem, {
    quality: 1,
    backgroundColor: 'transparent',
    imagePlaceholder: noImg,
    skipAutoScale: true,
  });

  useEffect(() => {
    setTwittText('');
  }, [lastDeepListSymbol, pagePath, lastAlertsSymbol, lastScreenerSymbol]);

  return {
    twittText,
    setTwittText,
    cleanUpScreenshotData,
    snapElement,
  };
};

export default useScreenshot;
