import NEW_SCREEN_ACTIONS from './types';

export const getItemsNewScreen = (payload) => ({
  type: NEW_SCREEN_ACTIONS.GET_ITEMS_NEW_SCREEN,
  payload
});

export const getPresetsNewScreen = (payload) => ({
  type: NEW_SCREEN_ACTIONS.GET_PRESET_ITEMS_NEW_SCREEN,
  payload
});

export const getItemsId = (payload) => ({
  type: NEW_SCREEN_ACTIONS.GET_ITEM_ID,
  payload
});

export const setSelectedScreen = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_SELECTED_SCREEN,
  payload
});

export const setNewScreenFlag = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_NEW_SCREEN_FLAG,
  payload
});

export const setGroupFilters = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_GROUP_FILTERS,
  payload
});

export const setRequestFilters = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_REQUEST_STATE_FILTERS,
  payload
});

export const setFolders = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_FOLDERS,
  payload
});

export const setScreens = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_SCREENS,
  payload
});

export const setPresetFolders = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_FOLDERS_PRESET,
  payload
});

export const setPresets = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_SCREENS_PRESET,
  payload
});

export const setColumnsSetFolders = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_FOLDER_COLUMNS_SET,
  payload
});

export const setColumnsSet = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_COLUMNS_SET,
  payload
});

export const getColumnsSetItems = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_ITEMS_COLUMNS_SET,
  payload
});

export const setColumnsSetFoldersPreset = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_FOLDER_COLUMNS_SET_PRESET,
  payload
});

export const setColumnsSetPreset = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_COLUMNS_SET_PRESET,
  payload
});

export const getColumnsSetItemsPreset = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_ITEMS_COLUMNS_SET_PRESET,
  payload
});

export const setHoveredFolderColumnSetsId = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_HOVERED_FOLDER_COLUMN_SETS_ID,
  payload
});

export const setHoveredColumnSetsId = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_HOVERED_COLUMN_SETS_ID,
  payload
});

export const setColumnSetsId = (payload) => ({
  type: NEW_SCREEN_ACTIONS.COLUMN_SET_ID,
  payload
});

export const setSortData = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SAVE_SORT_DATA,
  payload
});

export const setNameColumnSets = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_NAME_COLUMNS_SET,
  payload
});

export const setDataPanels = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_DATA_PANELS,
  payload
});

export const setSelectedDataPanel = (payload) => {
  return ({
    type: NEW_SCREEN_ACTIONS.SET_DEFAULT_DATA_PANELS,
    payload
  });
};
export const setItemsNewScreen = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_ITEM_NEW_SCREEN,
  payload
});

export const setPresetsNewScreen = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_PRESET_ITEM_NEW_SCREEN,
  payload
});

export const setColumnSetUpdateFlag = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_COLUMNS_SET_UPDATE_FLAG,
  payload
});

export const setSelectedCategory = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_SELECTED_CATEGORY,
  payload
});

export const setPrevScreen = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_PREV_SCREEN,
  payload
});
export const setCreatedFolder = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_CREATED_FOLDER,
  payload
});
export const setDataPanelsFolder = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_DATA_PANELS_FOLDERS,
  payload
});
export const setDataPanelsPresets = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_DATA_PANELS_PRESETS,
  payload
});
export const setDataPanelsPresetsFolders = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_DATA_PANELS_PRESETS_FOLDERS,
  payload
});
export const setDataPanelsItems = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_DATA_PANELS_ITEMS,
  payload
});
export const setDataPanelsItemsPresets = (payload) => ({
  type: NEW_SCREEN_ACTIONS.SET_DATA_PANELS_ITEMS_PRESETS,
  payload
});
