import { useSelector, shallowEqual } from 'react-redux';
import {
  CHART_FAVOURITES_MAIN,
  DEFAULT_FAVORITE_OBJ,
  FAVORITES_KEYS, FAVOURITE_CHART_TYPE, FAVOURITE_DRAWING_TOOLS, FAVOURITE_INDICATORS,
  FAVOURITE_INTERVALS, FAVOURITE_LAYOUTS, FAVOURITE_STUDY_TEMPLATE
} from '../../constants/tvWidgetOptions';
import { parseChartTypes } from '../../components/ChartContainer/utils/chartingHelper';
import useLastStateUpdateStore from './useLastStateUpdateStore';

const useChartState = () => {
  const { updateStoreHandler, updateFavouritesNestedData } = useLastStateUpdateStore();

  const userLastState = useSelector((state) => state.accountState.userSettings, shallowEqual);

  const saveFavoriteState = (key, value) => {
    let newValue = value;

    if (!userLastState?.chartFavorites) {
      updateStoreHandler(CHART_FAVOURITES_MAIN, DEFAULT_FAVORITE_OBJ);
    }

    switch (key) {
      case FAVORITES_KEYS.CHART_TYPES:
        newValue = parseChartTypes(JSON.parse(newValue));
        updateFavouritesNestedData(FAVOURITE_CHART_TYPE, newValue);
        break;
      case FAVORITES_KEYS.INTERVALS:
        newValue = JSON.parse(newValue);
        updateFavouritesNestedData(FAVOURITE_INTERVALS, newValue);
        break;
      case FAVORITES_KEYS.INDICATORS:
        newValue = JSON.parse(newValue);
        updateFavouritesNestedData(FAVOURITE_INDICATORS, newValue);
        break;
      case FAVORITES_KEYS.TEMPLATES:
        newValue = JSON.parse(newValue);
        updateFavouritesNestedData(FAVOURITE_STUDY_TEMPLATE, newValue);
        break;
      case FAVORITES_KEYS.LAYOUTS:
        newValue = JSON.parse(newValue);
        updateFavouritesNestedData(FAVOURITE_LAYOUTS, newValue);
        break;
      default:
        newValue = JSON.parse(newValue);
        updateFavouritesNestedData(FAVOURITE_DRAWING_TOOLS, newValue);
    }
  };

  return {
    saveFavoriteState
  };
};

export default useChartState;
