import ACTION_AUTH from './types';

export const saveResponseAction = (payload) => ({
  type: ACTION_AUTH.SAVE_RESPONSE,
  payload
});

export const saveResponseCheckAction = (payload) => ({
  type: ACTION_AUTH.SAVE_RESPONSE,
  payload
});

export const loadingAction = (payload) => ({
  type: ACTION_AUTH.LOADING,
  payload
});

export const popUpAction = (payload) => ({
  type: ACTION_AUTH.CHANGE_POP_UP_STATUS,
  payload
});

export const notionAnimationAction = (payload) => ({
  type: ACTION_AUTH.SHOW_NOTION_ANIMATION,
  payload
});

export const messageAction = (payload) => ({
  type: ACTION_AUTH.SAVE_MASSAGE,
  payload
});

export const errorMessageTitleAction = (payload) => ({
  type: ACTION_AUTH.ERROR_MASSAGE_TITLE,
  payload
});

export const changeNotificationTypeAction = (payload) => ({
  type: ACTION_AUTH.CHANGE_NOTIFICATION_TYPE,
  payload
});

export const setDisconnectPopupAction = (payload) => ({
  type: ACTION_AUTH.SET_DISCONNECT_POPUP,
  payload
});
