import React from 'react';
import PropTypes from 'prop-types';

import styles from '../sass/DataPanel.module.scss';
import { PANEL_CONTENT_GRID_TYPES } from '../dataPanelConstants';
import DataPanelContentCartItem from './DataPanelContentCartItem';

const DataPanelContent = ({
  dataPanelType,
  dataPanels,
  dataPoints,
  screenerId,
  getPointData,
  symbolIndex,
}) => {
  const getClass = () => {
    switch (dataPanelType) {
      case PANEL_CONTENT_GRID_TYPES.COMPACT:
        return styles.compact;
      default:
        return styles.default;
    }
  };

  return (
    <div className={`${styles.dataPanelContent} ${getClass()}`}>
      <div className={styles.dataPanelContentInner}>
        {dataPanels?.leftColumn.map((item) => (
          <DataPanelContentCartItem
            key={[item?.title, 'showCart'].join('_')}
            dataPoints={dataPoints}
            data={item}
            screenerId={screenerId}
            getPointData={getPointData}
            symbolIndex={symbolIndex}
          />
        ))}
      </div>
      <div className={styles.dataPanelContentInner}>
        {dataPanels?.rightColumn.map((item) => (
          <DataPanelContentCartItem
            key={[item?.title, 'showCart'].join('_')}
            dataPoints={dataPoints}
            data={item}
            screenerId={screenerId}
            getPointData={getPointData}
            symbolIndex={symbolIndex}
          />
        ))}
      </div>
    </div>
  );
};
DataPanelContent.propTypes = {
  dataPanelType: PropTypes.string,
  dataPanels: PropTypes.shape({
    leftColumn: PropTypes.arrayOf(PropTypes.shape({})),
    rightColumn: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  dataPoints: PropTypes.shape({}).isRequired,
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  getPointData: PropTypes.func.isRequired,
  symbolIndex: PropTypes.number.isRequired,
};

DataPanelContent.defaultProps = {
  dataPanelType: 'default',
  screenerId: null,
};

export default DataPanelContent;
