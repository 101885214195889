export default {
  accountSize: 'Account Size',
  equityRisk: 'Equity at Risk Per Position',
  maxPosition: 'MAX Position Size Allowed',
  entryPrice: 'Entry Point',
  symbol: 'Symbol',
  potentialProfit: 'Profit Taking Price',
  potentialExit: 'Stop Loss Exit Price',
  calculate: 'Calculate',
  getQuote: 'Get Quote',
  results: 'Results',
  warning: 'Please, enter the values in the field to calculate.',
  positionValue: 'Position Value',
  dollarRisk: '$ at Risk',
  rMultiple: 'R Multiple'
};
