import React from 'react';
import PropTypes from 'prop-types';
import stylesModal from './sass/ButtonBlock.module.scss';

import Button from '../Button/Button';

const ButtonBlock = ({
  firstButton, secondButton, firstHandler, secondHandler 
}) => (
  <div className={stylesModal.buttonBlock}>
    <Button buttonClass={stylesModal.cancelMoveToButton} handleClick={firstHandler}>
      {firstButton}
    </Button>
    <Button buttonClass={stylesModal.saveButton} handleClick={secondHandler}>
      {secondButton}
    </Button>
  </div>
);

ButtonBlock.propTypes = {
  firstButton: PropTypes.string,
  secondButton: PropTypes.string,
  firstHandler: PropTypes.func,
  secondHandler: PropTypes.func,
};

ButtonBlock.defaultProps = {
  firstHandler: () => undefined,
  secondHandler: () => undefined,
  firstButton: '',
  secondButton: '',
};

export default ButtonBlock;
