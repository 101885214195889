const convertToOrder = (key: string): number => {
  const parts = key.split('-');
  const row = parseInt(parts[0], 10);
  const col = parseInt(parts[1], 10);
  const totalCols = 3;

  return row * totalCols + col;
};

export default convertToOrder;
