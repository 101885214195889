import $api, { REACT_APP_PUBLIC_LIGHT_SERVER_URL } from '../../http';

interface ISpeedValues {
  [key: string]: [number]
}

interface IError { [key: string]: number | string[] }

interface IReturn {
  sendValuesHandler: () => undefined | void;
  sendErrorHandler: (value: IError) => undefined | void;
}

interface IRequestValue {
  min: number;
  max: number;
  average: number;
}

const speedValues: ISpeedValues = {};

export const fillSpeedValuesHandler = (key: string, value: number):void => {
  if (value > 8000) return;

  const objKey = key.replace(/ /g, '_');
  if (!speedValues[objKey]) {
    speedValues[objKey] = [value];
  } else {
    speedValues[objKey].push(value);
  }
};

const useSpeedCheckup = ():IReturn => {
  const cleanUpObjectHandler = ():void => {
    const keys = Object.keys(speedValues);
    keys.forEach((key) => delete speedValues[key]);
  };

  const sendValues = (key: string, values: IRequestValue):void => {
    try {
      $api.post(`${REACT_APP_PUBLIC_LIGHT_SERVER_URL}metrics/latency`, {
        name: key,
        ...values
      }).then();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const sendErrorHandler = (error: IError):void => {
    try {
      $api.post(`${REACT_APP_PUBLIC_LIGHT_SERVER_URL}v1.0/log`, {
        level: 'error',
        message: JSON.stringify(error)
      }).then();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const calculateMetrics = (numbers: number[]):IRequestValue => {
    const min = Math.min(...numbers);
    const max = Math.max(...numbers);
    const average = numbers.reduce((sum, number) => sum + number, 0) / numbers.length;

    return {
      min,
      max,
      average
    };
  };

  const sendValuesHandler = ():undefined | void => {
    const objectKeys = Object.keys(speedValues);
    const promises = objectKeys.map((key) => sendValues(key, calculateMetrics(speedValues[key])));

    Promise.all(promises)
      .then(() => {
        cleanUpObjectHandler();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error in one or more requests:', error);
      });
  };

  return { sendValuesHandler, sendErrorHandler };
};

export default useSpeedCheckup;
