import { ReactComponent as DefaultEarningsDate } from '../assets/images/icons/screener/defaultEarningsDate.svg';
import { ReactComponent as DefaultExDividendDate } from '../assets/images/icons/screener/defaultExDividendDate.svg';
import { ReactComponent as DefaultDescriptionHover } from '../assets/images/icons/screener/defaultDescriptionHover.svg';

const listVisibilitySetting = [
  {
    icon: DefaultEarningsDate,
    title: 'defaultEarningsDate',
  },
  {
    icon: DefaultExDividendDate,
    title: 'defaultExDividendDate',
  },
  {
    icon: DefaultDescriptionHover,
    title: 'defaultDescriptionHover',
  },
];

export default listVisibilitySetting;
