import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  useCallback, useEffect,
  useState, useContext
} from 'react';

import {
  SAVE_USER_STATE_DELAY, TABLE_SCREEN_PATH,
} from '../../constants/screener';
import { setSelectedSymbolsBatchScreener } from '../../store/screener/actions';
import { copyHandler } from './useExportFiles';
import useWatchList from './useWatchList';
import useStatsChart from './useStatsChart';
import usePrepareLastSymbolState from './usePrepareLastSymbolState';
import useAccount from './useAccount';
import { setColumnSetUpdateFlag } from '../../store/newScreen/actions';
import useApplyColumns from '../../components/Columns/hooks/useApplyColumns';
import { setSelectedItems } from '../../store/selectSymbol/slice';
import { descriptionCache } from './useLongDescription';
import useDebouncedCallback from './useDebouncedCallback';
import { WS_CHANNELS } from '../../constants/socketConnection';
import SocketContext from '../../context/SocketContext';

const useTableWorker = () => {
  const dispatch = useDispatch();
  const { Socket, Connection } = useContext(SocketContext);

  const [disableExport, setDisableExport] = useState(false);

  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const userLastState = useSelector((state) => state.accountState.userSettings);
  const lastStateId = useSelector((state) => state.accountState.lastStateId);
  const updatedLastState = useSelector((state) => state.accountState.updatedLastState, shallowEqual);
  const lastScreenerSymbol = useSelector((state) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual);
  const lastDeepListSymbol = useSelector((state) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual);
  const lastAlertsSymbol = useSelector((state) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual);
  const searchValue = useSelector((state) => state.screenerState.searchValue, shallowEqual);
  const itemsColumnSets = useSelector((state) => state.newScreenState.itemsColumnSets, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);
  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const selectedItems = useSelector((state) => state.selectSymbolsState.selectedItems, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);
  const currentSource = useSelector((state) => state.accountState.userSettings.statsSources, shallowEqual);
  const columnSetUpdateFlag = useSelector((state) => state.newScreenState.columnSetUpdateFlag, shallowEqual);

  const { currentSymbolHandler } = usePrepareLastSymbolState();
  const { applyColumnsFromSpecificPlace } = useApplyColumns();
  const debouncedUpdateLastState = useDebouncedCallback();

  const { getStatsData, statsChartDataCallback } = useStatsChart();

  const { getWatchLists } = useWatchList();
  const { updateUserState } = useAccount();

  const copySelectedSymbols = useCallback(
    (selectedItemsUpdated) => {
      const data = {
        result: [...selectedItemsUpdated.map((index) => ([symbolsList.find((item) => item.index === index)]))]
      };
      copyHandler(data);
    }, [selectedItems, symbolsList]
  );

  useEffect(() => {
    if (columnsList && itemsColumnSets.length) {
      if (columnSetUpdateFlag) {
        applyColumnsFromSpecificPlace();
      } else {
        dispatch(setColumnSetUpdateFlag(true));
      }
    }
  }, [
    selectedTab,
    selectedScreen,
    columnsList,
    itemsColumnSets.length,
    pagePath,
    userLastState.presetsColumnSetsScreenerIds
  ]);

  useEffect(() => {
    if (updatedLastState) {
      debouncedUpdateLastState(updateUserState, SAVE_USER_STATE_DELAY, userLastState, lastStateId);
    }
  }, [userLastState]);

  useEffect(() => {
    if (!selectedSymbols.length) {
      dispatch(setSelectedItems([]));
      dispatch(setSelectedSymbolsBatchScreener([]));
    }
  }, [selectedSymbols]);

  useEffect(() => {
    const messageHandler = (res) => {
      statsChartDataCallback(res, currentSource, currentSymbolHandler(pagePath !== TABLE_SCREEN_PATH.SCREENER).index);
    };

    if (Socket?.readyState === WebSocket.OPEN) {
      getStatsData(
        Connection,
        currentSymbolHandler(pagePath !== TABLE_SCREEN_PATH.SCREENER).index,
        currentSource
      );

      Connection.messageEmitter.on(WS_CHANNELS.SYMBOL_DATA_BATCH, messageHandler);
    }

    return () => {
      if (Socket?.readyState === WebSocket.OPEN) {
        Connection.messageEmitter.off(WS_CHANNELS.SYMBOL_DATA_BATCH, messageHandler);
      }
    };
  }, [
    Socket?.readyState,
    lastScreenerSymbol,
    lastAlertsSymbol,
    lastDeepListSymbol,
    currentSource,
    searchValue,
    pagePath,
  ]);

  useEffect(() => {
    setDisableExport(!!disableExport);
    getWatchLists();
    setDisableExport(!!disableExport);
    const interval = setInterval(() => {
      Object.keys(descriptionCache).forEach((key) => {
        delete descriptionCache[key];
      });
    }, 600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return {
    copySelectedSymbols,
    selectedItems,
    disableExport,
  };
};

export default useTableWorker;
