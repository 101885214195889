import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function mansfieldRs(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Mansfield Relative Strength',
    metainfo: {
      id: 'DV-mansfieldrs@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Mansfield Relative Strength',
      description: 'DV - Mansfield Relative Strength',
      is_price_study: false,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      bands: [
        {
          id: 'hline_0',
          isHidden: false,
          name: 'Zero Line'
        }
      ],
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'Mansfield RS Line'
        },
        plot_2: {
          isHidden: false,
          title: 'Background Colors'
        }
      },
      inputs: [
        {
          defval: 'SPY',
          id: 'in_0',
          name: 'Symbol',
          type: 'symbol' as StudyInputType.Symbol
        },
        {
          defval: 52,
          id: 'in_1',
          name: 'MA Length',
          type: 'integer' as StudyInputType.Integer
        },
      ],
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line,
        },
        {
          id: 'plot_2',
          palette: 'palette_0',
          type: 'bg_colorer' as StudyPlotType.BgColorer
        },
      ],
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Positive Color'
            },
            1: {
              name: 'Neutral Color Rising MA'
            },
            2: {
              name: 'Neutral Color Failing MA'
            },
            3: {
              name: 'Negative Color'
            },
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Zone 1'
            }
          },
          valToIndex: {
            1: 0
          }
        },
        palette_2: {
          colors: {
            0: {
              name: 'Zone 2'
            }
          },
          valToIndex: {
            2: 0
          }
        },
        palette_3: {
          colors: {
            0: {
              name: 'Zone 3'
            }
          },
          valToIndex: {
            3: 0
          }
        }
      },
      defaults: {
        bands: [
          {
            color: '#2962ff',
            linestyle: 0,
            linewidth: 1,
            value: 0,
            visible: true
          },
        ],
        inputs: {
          in_0: 'SPY',
          in_1: 52,
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#3a75e8',
                style: 0,
                width: 1
              },
              1: {
                color: '#787b86',
                style: 0,
                width: 1
              },
              2: {
                color: '#d0d0d0',
                style: 0,
                width: 1
              },
              3: {
                color: '#f55bae',
                style: 0,
                width: 1
              },
            }
          },
        },
        styles: {
          plot_0: {
            color: '#3a75e8',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            display: 15,
            transparency: 85,
          }
        },
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context, inputs) => {
        this._context = context;
        this._context.new_sym(inputs(0), 'W');
      };
      this.main = (context, inputs) => {
        this._context = context;
        const [_, maLength] = getInputArray({
          inputs,
          length: 3
        });

        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_var(this._context.symbol.time);
        // Select the secondary symbol
        this._context.select_sym(1);
        const secondarySymbolTime = this._context.new_var(this._context.symbol.time);

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolClose = this._context.new_var(mPineJS.Std.close(this._context));
        const newSymClose = secondarySymbolClose.adopt(secondarySymbolTime, mainSymbolTime, 0); //must use 0 in Weekly symbol case

        this._context.select_sym(0);
        const close = mPineJS.Std.close(this._context);

        const stockDividedBySpx = close / newSymClose * 100;
        const zeroLineMA = mPineJS.Std.sma(this._context.new_var(stockDividedBySpx), maLength, this._context);
        const mansfieldRS = (((stockDividedBySpx / zeroLineMA) - 1) * 100);

        const zeroLineMAisRising = mPineJS.Std.rising(this._context.new_var(zeroLineMA), 1);
        const zeroLineMAisFalling = mPineJS.Std.falling(this._context.new_var(zeroLineMA), 1);

        let bgColorValue = NaN;
        // Compare price and averages to determine background color
        if (stockDividedBySpx > zeroLineMA && zeroLineMAisRising) {
          bgColorValue = 0;
        } else if (stockDividedBySpx < zeroLineMA && zeroLineMAisRising) {
          bgColorValue = 1;
        } else if (stockDividedBySpx > zeroLineMA && zeroLineMAisFalling) {
          bgColorValue = 2;
        } else {
          bgColorValue = 3;
        }

        return [
          mansfieldRS,
          bgColorValue
        ];
      };
    }
  };
}
