import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import TextField from '../TextField/TextField';
import { getFromLocalStorage } from '../../utils/storageWorks';
import userProfileNoPhoto from '../../assets/images/icons/accountSettings/errorBoundary.svg';
import styles from './sass/ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { t, children } = this.props;
    const { hasError } = this.state;

    return (
      <>
        {hasError
          ? (
            <div className={`${styles.wrapper} ${getFromLocalStorage('theme')}`}>
              <Icon icon={userProfileNoPhoto} iconClass={styles.avatar} />
              <TextField text={t('wentWrong')} styleType="errorBoundaryTitle" />
              <TextField text={t('refreshingPage')} styleType="errorBoundaryDescription" />
              <Button buttonClass={styles.saveButton} handleClick={() => window.location.reload()}>
                {t('refreshPage')}
              </Button>
            </div>
          )
          : children}
      </>
    );
  }
}

ErrorBoundary.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default withTranslation()(ErrorBoundary);
