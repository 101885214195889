import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../../sass/PickColumnWrapper.module.scss';
import SearchBlock from './SearchBlock';
import ColumnsListItems from './ColumnsListItems';
import { SUBSCRIPTION_TYPE } from '../../../../constants/screener';
import UpgradeAccountBanner from '../../../UpgradeAccountBanner/UpgradeAccountBanner';

const ColumnsListView = ({
  listColumns, setCurrentGroup,
  currentGroup, columnsSelectedHandler,
  profile, match, visibleColumnsScreen
}) => {
  const [openedGroup, setOpenedGroup] = useState([]);
  const { t } = useTranslation();

  const openedGroupHandler = (id) => {
    const alreadyOpened = openedGroup.indexOf(id);

    if (alreadyOpened > -1) {
      setOpenedGroup((prevState) => prevState.splice(alreadyOpened, 1));
    }
    setOpenedGroup((prevState) => [...prevState, id]);
  };

  return (
    <div className={styles.columnsContentLeft}>
      <div className={styles.columnsContentLeftTop}>
        <SearchBlock
          columnsSideData={listColumns}
          setCurrentGroup={setCurrentGroup}
          openedGroupHandler={openedGroupHandler}
          visibleColumnsScreen={visibleColumnsScreen}
        />
        <div className={styles.columnsTestWrapper}>
          <ColumnsListItems
            currentGroup={currentGroup}
            setCurrentGroup={setCurrentGroup}
            openedGroupHandler={openedGroupHandler}
            openedGroup={openedGroup}
            data={listColumns}
            columnsSelectedHandler={columnsSelectedHandler}
            disabled={profile?.role === SUBSCRIPTION_TYPE.FREE}
            match={match}
          />
        </div>
        <div className={styles.columns_upgrade}>
          <UpgradeAccountBanner
            title={t('columnsAvailableToPremiumUsers')}
            buttonTitle={t('upgradeYourAccount')}
            tooltipClass={styles.comingSoon}
            profile={profile}
          />
        </div>
      </div>
    </div>
  );
};

ColumnsListView.defaultProps = {
  profile: {
    subscriptionType: ''
  }
};

ColumnsListView.propTypes = {
  listColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentGroup: PropTypes.func.isRequired,
  currentGroup: PropTypes.number.isRequired,
  columnsSelectedHandler: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    role: PropTypes.string
  }),
  match: PropTypes.func.isRequired,
  visibleColumnsScreen: PropTypes.bool.isRequired
};

export default ColumnsListView;
