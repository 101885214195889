import { transformData } from '../../../../utils/convertingDataHelper';
import { DATA_POINT_POSITIVE } from '../../types/WidgetChartsEnums';

const pFormatter = (dataPointXIndex, dataPointsXType, value, findIndex, index, sortBy) => {
  const label = transformData(dataPointsXType, value, '');
  if (DATA_POINT_POSITIVE.includes(dataPointXIndex)) {
    return value > 1.5 ? `\u2022${label}` : label;
  }
  if ((findIndex !== -1 && index >= findIndex)) {
    return sortBy === 1 ? `-${label}` : `+${label}`;
  }
  return sortBy === 1 ? `+${label}` : `-${label}`;
};

export default pFormatter;
