import React from 'react';
import moment from 'moment';
import styles from './sass/EducationComponents.module.scss';
import { IArticlesCard } from './types/EducationComponentsInterfaces';
import { ReactComponent as CalendarIcon } from '../../assets/images/icons/education/calendar.svg';
import { ReactComponent as ClockIcon } from '../../assets/images/icons/education/clock.svg';
import { ReactComponent as VirtualIcon } from '../../assets/images/icons/education/virtual.svg';
import { ReactComponent as RegisterIcon } from '../../assets/images/icons/education/register.svg';
import EventCardImage from '../../assets/images/icons/education/eventThumbnail.png';

import Button from '../Button/Button';

const EventCard = ({
  title, date, link, image
}: IArticlesCard): React.ReactElement => (
  <div className={styles.eventItem}>
    <img
      className={styles.eventImage}
      src={image || EventCardImage}
      alt="event"
    />
    <div className={styles.eventContainer}>
      <div className={styles.eventList}>
        <span className={styles.eventTitle}>{title}</span>
        <div className={styles.eventDetails}>
          <div className={styles.details}>
            <CalendarIcon />
            <p className={styles.time}>{moment(date).format('ddd, MMMM D')}</p>
          </div>
          <div className={styles.details}>
            <ClockIcon />
            <p className={styles.time}>{moment(date).format('hh:mm a')}</p>
          </div>
          <div className={styles.details}>
            <VirtualIcon />
            <p className={styles.time}>Virtual</p>
          </div>
        </div>
      </div>
      <div className={styles.register}>
        <RegisterIcon />
        <Button
          buttonClass={styles.registerButton}
          handleClick={() => window.open(link, '_blank')}
        >
          Register
        </Button>
      </div>
    </div>
  </div>
);

export default EventCard;
