import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import { IEducationTabsItem } from './types/EducationComponentsInterfaces';

const EducationTabsItem = ({
  Icon, title, activeTab, setActiveTab
}: IEducationTabsItem): React.ReactElement => (
  <div
    className={`${styles.educationHeaderTab} ${activeTab === title && styles.active}`}
    onMouseDown={() => setActiveTab(title)}
    role="button"
    tabIndex={0}
    aria-label="tabItem"
  >
    <Icon />
    <p>{title}</p>
  </div>
);

export default EducationTabsItem;
