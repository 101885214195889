import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import styles from './sass/SidebarScreener.module.scss';
import { popUpAction } from '../../store/auth/actions';
import useAccount from '../../utils/hooks/useAccount';
import ProfileSidebarMore from './ProfileSidebarMore';
import IconGenerator from './components/IconGenerator';
import { toggleAlertNotificationListDropdownVisible } from '../../store/alerts/slice';

const areEqual = (prevProps, nextProps) => {
  return prevProps.className === nextProps.className
    && prevProps.text === nextProps.text
    && prevProps.tooltipText === nextProps.tooltipText
    && prevProps.route === nextProps.route
    && prevProps.isVisible === nextProps.isVisible
    && prevProps.icon === nextProps.icon
    && prevProps.showProfile === nextProps.showProfile
    && prevProps.alertsListVisible === nextProps.alertsListVisible
    && prevProps.showMarket === nextProps.showMarket;
};

const ProfileSidebarItem = ({
  className,
  text,
  tooltipText,
  route,
  isVisible,
  IconDefault,
  currentPage,
  showProfile,
  setShowProfile,
  showMarket,
  setShowMarket,
  alertsListVisible
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const userAvatar = useSelector((state) => state.accountState?.userAvatar, shallowEqual);
  const subscriptionPlan = useSelector((state) => state.accountState?.activeSubscription?.membershipType);

  const {
    avatarWorker,
    activeSubscriptionWorker,
  } = useAccount();

  useEffect(() => {
    avatarWorker();
    activeSubscriptionWorker();
  }, []);

  const handleHide = () => {
    setShowProfile(false);
    setVisible(false);
  };

  const clickHandler = () => {
    setShowProfile((prev) => !prev);
    setVisible(false);
    if (showMarket) {
      setShowMarket(false);
    }
    if (alertsListVisible) {
      dispatch(toggleAlertNotificationListDropdownVisible());
    }
    if (route !== currentPage && text !== t('hideMenu')) {
      dispatch(popUpAction(false));
    }
  };

  return (
    <div
      className={`${styles.sideBarItemWrapper} ${styles.profileItemWrapper}`}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      aria-hidden="true"
      onClick={clickHandler}
    >
      <div className={className}>
        <div className={styles.iconsWrapper}>
          {
            userAvatar ? (
              <Icon
                icon={userAvatar}
                iconClass={styles.avatarIcon}
              />
            ) : (
              <IconDefault className={styles.avatarIconDefault} />
            )
          }
          {<IconGenerator plan={subscriptionPlan} />}
        </div>

        <span>
          {text}
        </span>
      </div>
      {visible && !isVisible && !showProfile ? <div className={styles.sideBarInfoTool}>{tooltipText}</div> : null}
      {showProfile && <ProfileSidebarMore handleCloseSidebar={handleHide} route={route} />}
    </div>
  );
};

ProfileSidebarItem.propTypes = {
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  route: PropTypes.string,
  tooltipText: PropTypes.string,
  IconDefault: PropTypes.shape({}),
  currentPage: PropTypes.string.isRequired,
  showProfile: PropTypes.bool.isRequired,
  setShowProfile: PropTypes.func.isRequired,
  showMarket: PropTypes.bool.isRequired,
  setShowMarket: PropTypes.func.isRequired,
  alertsListVisible: PropTypes.bool,
};

ProfileSidebarItem.defaultProps = {
  isVisible: false,
  className: '',
  text: '',
  route: '#',
  tooltipText: '',
  IconDefault: null,
  alertsListVisible: false,
};

export default React.memo(ProfileSidebarItem, areEqual);
