import WATCHLIST_ACTIONS from './types';

export const updateTabsOrderAction = (payload) => ({
  type: WATCHLIST_ACTIONS.UPDATE_TABS_ORDER,
  payload
});

export const setSelectedTab = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_SELECTED_TAB,
  payload
});

export const setSelectedTabColumnsId = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_SELECTED_TAB_NEW_COLUMNS_SET,
  payload
});

export const setModalType = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_MODAL_TYPE,
  payload
});

export const setIsOpenModal = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_IS_OPEN_MODAL,
  payload
});

export const setWatchlistData = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_WATCHLIST_DATA,
  payload
});

export const setFavoritesData = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_FAVORITES_DATA,
  payload
});

export const setSelectedSymbolsList = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_SELECTED_SYMBOLS_LIST,
  payload
});

export const deleteSelectedItem = (payload) => ({
  type: WATCHLIST_ACTIONS.DELETE_SELECTED_ITEM,
  payload
});

export const clearSelectedItems = () => ({
  type: WATCHLIST_ACTIONS.CLEAR_SELECTED_ITEMS,
});

export const setSelectedItemsFromWatchlist = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_SELECTED_ITEMS_FROM_WATCHLIST,
  payload
});

export const setTabFromDropDownWatchlist = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_TAB_FROM_DROPDOWN,
  payload
});

export const updateSpecificWatchlist = (payload) => ({
  type: WATCHLIST_ACTIONS.UPDATE_SPECIFIC_WATCHLIST,
  payload
});

export const updateFavouriteWatchlist = (payload) => ({
  type: WATCHLIST_ACTIONS.UPDATE_FAVOURITE_WATCHLIST,
  payload
});

export const setSelectedTabIdWatchlist = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_SELECTED_TAB_ID_WATCHLIST,
  payload
});

export const setTargetList = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_TARGET_LIST,
  payload
});

export const setCurrentTargetList = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_CURRENT_TARGET_LIST,
  payload
});

export const setTargetSymbol = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_TARGET_SYMBOL,
  payload
});

export const setHolidaysList = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_HOLIDAYS_LIST,
  payload
});

export const setAlertsData = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_ALERTS_DATA,
  payload
});

export const setActiveAlert = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_ACTIVE_ALERT,
  payload
});

export const setWatchlistFolder = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_FOLDER,
  payload
});

export const setWatchlistPresetFolder = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_FOLDER_PRESET,
  payload
});

export const setWatchlistPreset = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_PRESET,
  payload
});

export const setItemsDeeplist = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_ITEMS_DEEPLIST,
  payload
});

export const setPresetsDeeplist = (payload) => ({
  type: WATCHLIST_ACTIONS.SET_PRESETS_DEEPLIST,
  payload
});
