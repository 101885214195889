import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './sass/ButtonExportScreener.module.scss';
import { ReactComponent as AlertCircle } from '../../assets/images/icons/alert circle.svg';
import DropList from './DropList';
import DialogBlock from '../DialogBlock/DialogBlock';
import { copyHandler, twitterDataLength, downloadSymbolsHandler } from '../../utils/hooks/useExportFiles';
import {
  TWITTER_SHARE_URL, MS_INTERVAL, SCREENER_VIEWS, TABLE_SCREEN_PATH, SUBSCRIPTION_TYPE
} from '../../constants/screener';
import ExportButton from './ExportButton';
import { checkRoleUser } from '../../utils/userHelper';

const areEqual = (prevProps, nextProps) => {
  return prevProps.disableExport === nextProps.disableExport
    && prevProps.isFree === nextProps.isFree
    && prevProps.selectedItems === nextProps.selectedItems;
};

const ButtonExportScreener = ({
  disableExport,
  isFree,
  watchList,
  screenerView,
  selectedItems
}) => {
  const { t } = useTranslation();
  const [viewMenu, setViewMenu] = useState(false);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [viewLabelPopUp, setViewLabelPopUp] = useState(false);
  const [viewTool, setViewTool] = useState(false);
  const [currentTitle, setCurrentTitle] = useState('');

  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const dataColumns = useSelector((state) => state.tableDataState.dataColumns, shallowEqual);
  const dataRowsIndexes = useSelector((state) => state.tableDataState.dataRowsIndexes, shallowEqual);
  const sortData = useSelector((state) => state.screenerState.sortData, shallowEqual);

  const multiChartSymbolsList = useSelector((state) => state.multiChartState.symbolsList, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreen = useSelector((state) => state.newScreenState.selectedScreen, shallowEqual);
  const isHoldingView = useSelector(
    (state) => state.newScreenState.selectedScreen?.isHoldingView,
    shallowEqual
  );

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN);

  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);

  const symbolsIndexes = useMemo(() => {
    return dataRowsIndexes.map((item) => ([symbolsList.find((symbol) => symbol.index === item)]));
  }, [dataRowsIndexes]);

  const copySymbolsItem = screenerView === SCREENER_VIEWS.SCREENER
    ? { result: symbolsIndexes, columns: dataColumns }
    : {
      result: symbolsList.filter(({ index }) => multiChartSymbolsList.includes(index))
        .slice(0, 75).map((item) => ([item]))
    };

  useEffect(() => {
    if (pagePath === TABLE_SCREEN_PATH.SCREENER) {
      setCurrentTitle(selectedScreen?.title || t('screenerPage'));
    } else {
      setCurrentTitle(selectedTab?.title);
    }
  }, [pagePath, selectedScreen, selectedTab]);

  useEffect(() => {
    let timeout;
    if (viewPopUp) {
      timeout = setTimeout(() => {
        setViewPopUp(false);
      }, MS_INTERVAL.TWO_SECOND);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [viewPopUp]);

  useEffect(() => {
    let timeout;
    if (viewLabelPopUp) {
      timeout = setTimeout(() => {
        setViewLabelPopUp(false);
      }, MS_INTERVAL.TWO_SECOND);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [viewLabelPopUp]);

  const handleViewMenu = useCallback(() => setViewMenu(!viewMenu), [viewMenu]);

  const closeMenu = useCallback(() => {
    setViewMenu(false);
  }, [viewMenu]);

  const copyToClipBoard = useCallback(
    () => copyHandler(copySymbolsItem), [copySymbolsItem]
  );

  const exportFileHandler = (format) => {
    let filters;
    if (selectedItems.length) {
      filters = [[[0, 0, selectedItems]]];
    } else {
      filters = [[[0, 0, dataRowsIndexes]]];
    }
    const sortBy = sortData.map((item) => [item.indexElement, item.sortValue]);
    const columns = adminRole ? dataColumns
      .filter((column) => column?.index && (isHoldingView || column.index !== 1998))
      .map((column) => column.index) : [17];

    const result = {
      sortBy, filters, columns, type: format
    };
    if (filters) {
      downloadSymbolsHandler(result, currentTitle);
    }
  };

  const copyToClipBoardTwitter = useCallback(
    () => {
      const { stringDataQuotesTwitter } = copyHandler(copySymbolsItem);
      const deepVueLength = t('viaDeepvue').length;
      const newData = twitterDataLength(stringDataQuotesTwitter, selectedTab, deepVueLength);
      window.open(
        `${TWITTER_SHARE_URL}${currentTitle}: ${newData} ${t('viaDeepvue')}`, '_blank'
      );
    }, [copySymbolsItem]
  );

  const onMouseEnterHandler = useCallback(() => {
    setViewTool(true);
  }, []);

  const onMouseLeaveHandler = () => {
    setViewTool(false);
  };

  useEffect(() => {
    let timeout;
    if (!viewTool) {
      timeout = setTimeout(() => {
        closeMenu();
      }, MS_INTERVAL.HALF_SECOND);
    } else {
      clearTimeout(timeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [viewTool]);

  return (
    <>
      {!isFree && (
        <div
          className={styles.container}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          <ExportButton
            disableExport={disableExport}
            handleViewMenu={handleViewMenu}
            isFree={isFree}
            viewMenu={viewMenu}
          />
          {viewMenu && disableExport && (
            <DialogBlock Icon={AlertCircle} text={t('alertExport')} />
          )}
          <div className={`${viewMenu && !disableExport ? styles.showMenu : styles.hideMenu}`}>
            {viewMenu && (
              <DropList
                copyToClipBoardTwitter={copyToClipBoardTwitter}
                exportFileHandler={exportFileHandler}
                copyToClipBoard={copyToClipBoard}
                closeMenu={closeMenu}
                viewMenu={viewMenu}
                screenerView={screenerView}
                watchList={watchList}
                setViewPopUp={setViewPopUp}
                setViewLabelPopUp={setViewLabelPopUp}
              />
            )}
          </div>
          {viewPopUp && (
            <DialogBlock text={t('copySymbolsToTwitter')} />
          )}
          {viewLabelPopUp && (
            <DialogBlock text={t('copySymbols')} />
          )}
          {!viewMenu && viewTool && <div className={styles.hoverDropDown}>{t('export')}</div>}
        </div>
      )}
    </>
  );
};

ButtonExportScreener.propTypes = {
  watchList: PropTypes.bool,
  isFree: PropTypes.bool.isRequired,
  disableExport: PropTypes.bool,
  screenerView: PropTypes.string.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.number).isRequired
};

ButtonExportScreener.defaultProps = {
  watchList: false,
  disableExport: true
};

export default React.memo(ButtonExportScreener, areEqual);
