import React, { useEffect, useState } from 'react';

import Input from '../Input/Input';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search_black.svg';
import RadioButton from '../RadioButton/RadioButton';
import NoOptions from '../NoOptions/NoOptions';
import styles from './sass/FilterMultiInclude.module.scss';
import { TValueItem } from '../FilterScreen/Types';

interface IListOptionMultiCheck {
  searchValue: string;
  placeholder: string;
  searchValueHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  listOptions: TValueItem[];
  handleCheck: (id: number) => void;
  bottomFlag: boolean;
  noOptionsAvailable: string;
  inputItem: number;
  selectedItems: TValueItem[];
}

const ListOptionMultiCheck = ({
  searchValue,
  placeholder,
  searchValueHandler,
  listOptions,
  handleCheck,
  bottomFlag,
  noOptionsAvailable,
  inputItem,
  selectedItems
}: IListOptionMultiCheck): React.ReactElement => {
  const [itemHeight, setItemHeight] = useState(268);

  useEffect(() => {
    setItemHeight(inputItem + 10);
  }, [searchValue, inputItem]);

  const getChecked = (find: TValueItem): boolean => {
    if (!find) return false;
    const findItem = selectedItems?.find((item) => item?.value === find?.value);
    return !!findItem && Object.keys(findItem).length > 0;
  };

  return (
    <div
      className={styles.dropDown}
      style={bottomFlag ? { bottom: itemHeight } : { top: itemHeight }}
    >
      <Input
        IconBefore={SearchIcon}
        value={searchValue}
        placeholder={placeholder}
        handlerChange={searchValueHandler}
        inputClass={styles.searchInput}
        iconClass="itemSearchSelect"
      />
      <div className={styles.listOptions}>
        {listOptions.length > 0 ? (listOptions.map((item, index) => (
          <RadioButton
            styleInput="checkInput"
            id={item?.value as number}
            title={item.title}
            checked={getChecked(item)}
            handleCheck={() => handleCheck(item.value as number)}
            key={[item.title, index].join('_')}
          />
        ))) : (
          <div className={styles.noOption}>
            <NoOptions text={noOptionsAvailable} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ListOptionMultiCheck;
