import React, { useRef, useState } from 'react';

import Portal from '@mui/material/Portal';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './sass/DashboardComponents.module.scss';
import { IDashboardTab } from './types/DashboardComponentsInterfaces';
import useDnDtabs from '../../utils/hooks/useDnDtabs';
import TextWithTooltip from '../DataPanel/components/TextWithTooltip';
import { ReactComponent as DndControl } from '../../assets/images/icons/screener/dnd_frame.svg';
import { ReactComponent as IconDot } from '../../assets/images/icons/screener/more-circle.svg';
import MorePopUp from '../WatchlistTabsBlock/MorePopUp';
import { useTheme } from '../../utils/hooks/useTheme';
import RootStateTypes from '../../store/RootStateTypes';
import DashboardItemsDialog from '../DashboardItemsDialog/DashboardItemsDialog';
import UseDashboardsDialogs from '../DashboardItemsDialog/hooks/UseDashboardsDialogs';
import { checkRoleUser } from '../../utils/userHelper';
import { SUBSCRIPTION_TYPE } from '../../constants/screener';

const DashboardTab = ({
  selected, dashboardName, handleSelect, tabId,
  changeOrderHandler, endDragHandler, selectedTabId,
  index
}: IDashboardTab): React.ReactElement => {
  const [moreVisible, setMoreVisible] = useState(false);
  const { theme } = useTheme();
  const {
    actionOpenDialog,
    open,
    dialogData,
    actionCancelDialog,
  } = UseDashboardsDialogs();
  const ref = useRef<HTMLDivElement>(null);
  const {
    drop,
    handlerId,
    drag,
    isDragging
  } = useDnDtabs(ref, index, tabId, changeOrderHandler, endDragHandler, selectedTabId);
  const dashboardsList = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const dashboardsListPresets = useSelector(
    (state: RootStateTypes) => state.dashboardsState.dashboardPresets, shallowEqual
  );

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  const selectedDashboard = [...dashboardsList, ...dashboardsListPresets].find((el) => el.id === tabId);

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleCallback = () => {
    setMoreVisible(false);
  };

  const getPopupStyles = () => {
    const parentRect = ref.current?.getBoundingClientRect();
    if (!parentRect) {
      return {};
    }
    return {
      position: 'fixed',
      left: `${parentRect?.x + 120}px`,
      width: '126px',
      zIndex: 3000,
      top: `${parentRect?.y + 25}px`
    };
  };

  return (
    <>
      {open && (
        <DashboardItemsDialog
          open={open}
          actionCancel={actionCancelDialog}
          dialogData={dialogData}
          currentItemHandler={() => {}}
          adminRole={adminRole}
        />
      )}
      <div
        data-handler-id={handlerId}
        ref={ref}
        id={`${tabId}`}
        style={{ opacity }}
        key={tabId}
        aria-hidden
        onClick={() => handleSelect(tabId)}
        className={`${styles.dashboardTab} ${selected && styles.active}`}
        onMouseLeave={() => setMoreVisible(false)}
      >
        <DndControl className={styles.dndIcon} />
        <TextWithTooltip
          className={styles.dashboardTabTitle}
          placement="bottom-end"
          isStatic={false}
          title={dashboardName}
        />
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setMoreVisible(!moreVisible);
          }}
          className={styles.moreIcon}
          aria-hidden="true"
        >
          <IconDot />
          {moreVisible && (
          <Portal>
            <MorePopUp
              theme={theme}
              callBack={handleCallback}
              setMoreVisible={setMoreVisible}
              isList
              position="bottom"
              allowDelete
              isDashboard
              lineStyles={getPopupStyles()}
              selectedDashboard={selectedDashboard}
              actionOpenDialog={actionOpenDialog}
            />
          </Portal>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardTab;
