import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import TimeDropList from './TimeDropList';
import TextField from '../../../components/TextField/TextField';
import { handleOutsideClick } from '../../../utils/handleOutsideClick';
import styles from '../sass/TableScreener.module.scss';
import { ReactComponent as SelectArrow } from '../../../assets/images/icons/selectArrow.svg';
import { ALERT_TIME_PERIODS } from '../../../constants/alerts';

const SelectPeriodDropdown = ({ itemSelect, selected }) => {
  const [selectedValue, setSelectedValue] = useState(selected);
  const [viewDropList, setViewDropList] = useState(false);
  const dropdown = useRef(null);

  useEffect(() => {
    document.addEventListener('click', (event) => handleOutsideClick(event, () => setViewDropList(false), dropdown));
    return () => {
      document.removeEventListener('click', handleOutsideClick);
      setViewDropList(false);
    };
  }, []);

  const handleSelect = (value) => {
    setSelectedValue(value);
    itemSelect(value);
  };

  useEffect(() => {
    if (selected) {
      setSelectedValue(selected);
    }
  }, [selected]);

  return (
    <div className={styles.timePeriod} ref={dropdown}>
      <div
        aria-hidden="true"
        className={`${styles.dropdownContainer} ${viewDropList ? styles.dropOpen : ''}`}
        onClick={() => setViewDropList(!viewDropList)}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
      >
        <TextField
          styleType="selectedFiltersOption"
          text={selectedValue?.name || ALERT_TIME_PERIODS[5].name}
        />
        <SelectArrow />
      </div>

      {viewDropList && (
        <TimeDropList
          data={ALERT_TIME_PERIODS}
          handlerChange={handleSelect}
          selectedItem={selectedValue}
        />
      )}
    </div>
  );
};

SelectPeriodDropdown.propTypes = {
  itemSelect: PropTypes.func.isRequired,
  selected: PropTypes.shape({})
};

SelectPeriodDropdown.defaultProps = {
  selected: null
};
export default SelectPeriodDropdown;
