import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import moment from 'moment/moment';
import TableChartWrapper from './TableChartWrapper';
import { scrollToItemStatsChart, filterEstimateHandler } from '../../../utils/helpers';

const TableChartView = ({
  checkedFilters,
  width
}) => {
  const chartSymbolData = useSelector((state) => state.statsChartState.tableChart, shallowEqual);
  const numberOfQuarters = useSelector((state) => state.accountState.userSettings.numberOfQuarters, shallowEqual);
  const estimateVisibleData = useSelector((state) => state.screenerState.estimateVisibleData, shallowEqual);
  const tableBorderSettings = useSelector((state) => state.statsChartState.tableBorderSettings, shallowEqual);

  const [currentData, setCurrentData] = useState([[], []]);
  const [lastHistoricalData, setLastHistoricalData] = useState({});

  const contentWidth = width - 150;
  const columnWidth = contentWidth / numberOfQuarters;
  const flexColumnWidth = (numberOfQuarters * columnWidth > currentData[0].length * columnWidth)
    ? contentWidth / currentData[0].length : contentWidth / numberOfQuarters;

  const currentQuarter = `${moment().year()}: Q${moment().quarter()}`;

  useEffect(() => {
    setCurrentData(chartSymbolData);

    if (!estimateVisibleData) {
      setCurrentData([
        filterEstimateHandler(chartSymbolData[0]),
        filterEstimateHandler(chartSymbolData[1])
      ]);
    }

    setLastHistoricalData(filterEstimateHandler(chartSymbolData[0])?.at(-1));
  }, [chartSymbolData, estimateVisibleData]);

  useEffect(() => {
    let timeout;

    if (currentData[0]?.length) {
      const lastQuarter = lastHistoricalData?.quarter || currentQuarter;
      const lastHistoricalIndex = currentData[0].findIndex((item) => {
        return item.quarter === lastQuarter;
      });

      const prevQuarterIndex = currentData[0][lastHistoricalIndex - 1];
      const quarterIndex = lastHistoricalIndex === -1 ? prevQuarterIndex : lastHistoricalIndex + 1;

      timeout = setTimeout(() => {
        if (!estimateVisibleData || !currentData[0][quarterIndex]) {
          scrollToItemStatsChart(currentData[0]?.at(-1)?.quarter);
        } else {
          scrollToItemStatsChart(currentData[0][numberOfQuarters === 4 ? quarterIndex : quarterIndex + 1]?.quarter);
        }
      }, 200);
    }

    return () => clearTimeout(timeout);
  }, [currentData[0], numberOfQuarters]);

  return (
    <TableChartWrapper
      checkedFilters={checkedFilters}
      currentData={currentData}
      isFullWidth={columnWidth !== flexColumnWidth}
      columnWidth={flexColumnWidth}
      tableBorderSettings={tableBorderSettings}
    />
  );
};

TableChartView.propTypes = {
  width: PropTypes.number,
  checkedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TableChartView.defaultProps = {
  width: null,
};

export default TableChartView;
