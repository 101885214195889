import { BAR_CHART_COLORS } from '../../types/WidgetChartsEnums';

const pBackgroundColor = (findIndex, index, sortBy) => {
  if (findIndex === -1 || index < findIndex) {
    return sortBy === 1 ? BAR_CHART_COLORS.blue : BAR_CHART_COLORS.pink;
  }
  return sortBy === 0 ? BAR_CHART_COLORS.blue : BAR_CHART_COLORS.pink;
};

export default pBackgroundColor;
