import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './sass/SidebarScreener.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import { toggleAlertNotificationListDropdownVisible } from '../../store/alerts/slice';

const SidebarItemAlerts = ({
  className,
  Icon,
  text,
  tooltipText,
  isVisible,
  counter,
  isFree,
  setShowMarket,
  setShowProfile,
  alertsListVisible,
}) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  let timeout = null;

  const clickHandler = () => {
    dispatch(toggleAlertNotificationListDropdownVisible());

    setVisible(true);
    setShowMarket(false);
    setShowProfile(false);
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => setVisible(false), 1500);
  };

  return (
    <div
      aria-hidden="true"
      className={`${styles.sideBarItemWrapper}`}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <DefaultButton
        className={`${className} ${isFree ? styles.blur : ''}`}
        handleClick={() => !isFree && clickHandler()}
      >
        <div className={styles.iconWrapper}>
          {!!counter && (
            <span className={`${styles.counter} ${isVisible ? styles.counterJustify : ''}`}>
              {counter}
            </span>
          )}
          <Icon />
        </div>
        <span>{text}</span>
      </DefaultButton>
      {visible && !isVisible && !alertsListVisible ? <div className={styles.sideBarInfoTool}>{tooltipText}</div> : null}
    </div>
  );
};

SidebarItemAlerts.propTypes = {
  isVisible: PropTypes.bool,
  isFree: PropTypes.bool,
  className: PropTypes.string,
  Icon: PropTypes.shape({}),
  text: PropTypes.string,
  tooltipText: PropTypes.string,
  counter: PropTypes.number,
  alertsListVisible: PropTypes.bool,
  setShowMarket: PropTypes.func.isRequired,
  setShowProfile: PropTypes.func.isRequired,
};

SidebarItemAlerts.defaultProps = {
  isVisible: false,
  isFree: false,
  className: '',
  Icon: null,
  text: '',
  tooltipText: '',
  counter: 0,
  alertsListVisible: false,
};

export default React.memo(SidebarItemAlerts);
