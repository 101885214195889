import { MUIStyledCommonProps } from '@mui/system/createStyled';
import ThemeVariants from '../../../constants/theme';

const TableChartStyles = (
  historyFlag: boolean,
  theme: ThemeVariants,
): MUIStyledCommonProps => ({
  sx: {
    fontFamily: 'Quicksand, sans-serif',
    color: theme === ThemeVariants.DARK_MODE ? '#D5D4E9' : '#7E8699',
    width: '153px',
    fontSize: '12px',
    fontWeight: '300',
    boxShadow: theme === ThemeVariants.DARK_MODE ? '0px 7px 14px #161C28' : '0px 7px 14px rgba(167, 164, 164, 0.12)',
    borderRadius: '6px',
    padding: '0',
    margin: '0',
    height: historyFlag ? '155px' : '135px',
    backgroundColor: theme === ThemeVariants.DARK_MODE ? '#232B3B' : '#FFF',
  }
});

export default TableChartStyles;
