import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/ButtonExportScreener.module.scss';
import TextField from '../TextField/TextField';

const ExportItem = ({
  Icon, text, exportClick, id, label
}) => (
  <div
    aria-hidden="true"
    className={styles.itemRow}
    onClick={() => exportClick(id, label)}
    role="button"
    tabIndex={0}
  >
    <Icon />
    <TextField styleType="dropTextExport" text={text} />
  </div>
);

ExportItem.propTypes = {
  label: PropTypes.string,
  Icon: PropTypes.shape({}),
  text: PropTypes.string,
  exportClick: PropTypes.func,
  id: PropTypes.string,
};

ExportItem.defaultProps = {
  label: '',
  Icon: null,
  text: '',
  exportClick: () => {},
  id: ''
};

export default ExportItem;
