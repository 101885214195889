import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/FilterMultiInclude.module.scss';
import TextItems from '../FilterScreen/components/renderItem/TextItems';

const FilterItem = ({
  arrayData, Image, active, handleCheck, handlerCloseDropdown, clickSelect
}) => (
  <div className={styles.filterItem}>
    <div className={styles.filterItemContent}>
      <TextItems
        arrayData={arrayData}
        classType="selectedFilterItem"
        handleCheck={handleCheck}
        handlerCloseDropdown={handlerCloseDropdown}
      />
    </div>
    <div
      aria-hidden="true"
      className={`${styles.imageContainer} ${active ? styles.activeImage : ''}`}
      onClick={clickSelect}
      onKeyDown={undefined}
      role="button"
      tabIndex="0"
    >
      <Image />
    </div>

  </div>
);

FilterItem.propTypes = {
  arrayData: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string
  })), PropTypes.bool]),
  Image: PropTypes.shape({}).isRequired,
  active: PropTypes.bool.isRequired,
  handleCheck: PropTypes.func,
  handlerCloseDropdown: PropTypes.func,
  clickSelect: PropTypes.func,
};

FilterItem.defaultProps = {
  arrayData: [],
  handlerCloseDropdown: () => {},
  handleCheck: () => {},
  clickSelect: () => {},
};

export default FilterItem;
