import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styles from '../sass/AlertsList.module.scss';
import TextField from '../../TextField/TextField';
import { ReactComponent as Megaphone } from '../../../assets/images/icons/alertsIcons/megaphoneEmptyModal.svg';
import DefaultButton from '../../DefaultButton/DefaultButton';
import { ReactComponent as IconPlus } from '../../../assets/images/icons/screener/plus_white.svg';

const EmptyAlertsList = ({ addNewAlert }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyNotificationsWrapper}>
      <div className={styles.iconWrapper}>
        <Megaphone />
      </div>
      <TextField text={t('emptyTitleAlert')} styleType="emptyNotificationTitle" />
      <TextField text={t('emptyDescriptionAlert')} styleType="emptyNotificationDescr" />
      <DefaultButton handleClick={addNewAlert} className={styles.btnAddAlert}>
        <IconPlus />
        {t('alertAddButton')}
      </DefaultButton>
    </div>
  );
};

EmptyAlertsList.propTypes = {
  addNewAlert: PropTypes.func.isRequired
};

export default EmptyAlertsList;
