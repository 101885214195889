export default {
  emptyTitle: 'Your Watchlist is empty',
  noDeeplistsFound: 'No Watchlist found',
  emptyDescription: 'Click the button below to add your first stock',
  addNewSymbolButton: 'Add New Symbol',
  addNewSymbolButtonShort: 'Add New',
  favoriteTabTitle: 'Favorites',
  stocks: 'Stocks',
  rename: 'Rename',
  delete: 'Delete',
  addSymbol: 'addSymbol',
  signOut: 'Sign Out',
  deleteSymbols: 'deleteSymbols',
  deleteSymbolsTargetList: 'deleteSymbolsTargetList',
  moveSymbolsTargetList: 'moveSymbolsTargetList',
  watchlistName: 'Watchlist name',
  areYouSureWatchlist: 'Are you sure you want to delete Watchlist:',
  areYouSureSymbols: 'Are you sure you want to delete:',
  areYouSureTargetList: 'Are you sure you want to delete symbol from',
  areYouSureMoveSymbolsToTargetList: 'Are you sure you want to move symbol to',
  cancel: 'Cancel',
  renameWatchList: 'Rename Watchlist',
  areYouSureShort: 'Are you sure?',
  create: 'Create',
  createHeader: 'Create new',
  createDescription: 'Watchlist name',
  createPlaceholder: 'Type Watchlist name',
  newWatchlistFirst: 'New Watchlist',
  newWatchlistSecond: 'created',
  ok: 'Ok',
  successCreatedWatchlist: 'Watchlist successfully added',
  addSymbolModalHeader: 'Add New Symbol',
  searchBySymbolInputPlaceholder: 'Search by symbol',
  watchListDelete: 'Watchlist successfully deleted',
  watchlistUpdate: 'Watchlist successfully updated',
  watchlistSuccessLinked: 'Watchlist successfully added',
  watchlistSuccessUnlinked: 'Watchlist successfully deleted',
  watchlistLimit: 'Symbol limit reached',
  watchlistLimitCount: 'Your Watchlist can hold up to 1000 symbols',
  watchlistExist: 'The name {{title}} already exists',
  notProcessedSymbols: 'Symbols that were not processed: ',
  viaDeepvue: 'via @Deepvue',
  dropDownTitle: 'My Watchlists',
  savedDeeplists: 'Saved Watchlists',
  deeplistsPresets: 'Watchlists Presets',
  deeplist: 'Watchlist',
  duplicateDeeplist: 'Duplicate Watchlist',
  searchByWatchlist: 'Search by Watchlist...',
  noResultFound: 'No results found',
  noResultFoundDescription: 'We couldn’t find a Watchlist named',
  dontAskAgain: 'Don’t ask me again',
  editNameOf: 'Edit name of',
  emptyAlertTitle: 'Your Alerts list is empty',
  emptyAlertDescription: 'Click the button below to add your first alert',
  lists: 'lists',
  successCreatedDeeplist: 'New watchlist successfully added',
};
