import React from 'react';

import SwitchPanel from '../SwitchPanel/SwitchPanel';
import NoOptions from '../NoOptions/NoOptions';
import { checkRangeValue } from '../../utils/helpers';
import SeekBar from '../SeekBar/SeekBar';

interface IRangePanel {
  activeTab: Record<string, boolean>;
  tabsHandler: (item: string) => void;
  viewRange: boolean;
  initialMax: number | boolean;
  initialMin: number | boolean;
  setArray: (array: (number | number[])[]) => void;
  columnId: number;
  typeData: string | undefined;
  minValue: number;
  maxValue: number;
  listButton: string[];
  handleTab: boolean;
  noOptionsAvailable: string;
}

const RangePanel = ({
  activeTab, tabsHandler, viewRange, initialMax, initialMin,
  setArray, columnId, typeData, minValue, maxValue, listButton,
  handleTab, noOptionsAvailable
}: IRangePanel): React.ReactElement => (
  <>
    <SwitchPanel
      activeTab={activeTab}
      listButton={listButton}
      tabsHandler={tabsHandler}
    />
    {checkRangeValue(initialMin, initialMax) ? (
      <SeekBar
        handleTab={handleTab}
        setArray={setArray}
        viewRange={viewRange}
        initialMax={initialMax as number}
        initialMin={initialMin as number}
        columnId={columnId}
        typeData={typeData as string}
        minValue={minValue}
        maxValue={maxValue}
        activeTab={activeTab}
      />
    ) : (
      <NoOptions text={noOptionsAvailable} />
    )}
  </>
);

export default RangePanel;
