import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import styles from './sass/DataPanel.module.scss';
import useDataPanel from './hooks/useDataPanel';
import DataPanelHeader from './components/DataPanelHeader';
import DataPanelEditContent from './components/DataPanelEditContent';
import DataPanelContent from './components/DataPanelContent';
import DataPanelAddComponent from './components/DataPanelAddComponent';
import ClientOnlyPortal from '../ClientOnlyPortal/ClientOnlyPortal';
import useDataPanelItems from './hooks/useDataPanelItems';
import useDataPanelsModals from './hooks/useDataPanelsModals';
import DataPanelModal from './components/DataPanelModal';
import useDropdownCategory from './hooks/useDropdownCategory';
import { MODAL_TYPES, MAX_CATEGORY_NAME } from './dataPanelConstants';
import WatchlistEmptyBlock from '../WatchlistEmptyBlock/WatchlistEmptyBlock';
import { SAVE_USER_STATE_DELAY } from '../../constants/screener';
import useDebouncedCallback from '../../utils/hooks/useDebouncedCallback';
import useAccount from '../../utils/hooks/useAccount';
import useWorkerItems from '../../utils/hooks/useWorkerItems';
import useDataPanelConnection from './hooks/useDataPanelConnection';

const DataPanel = ({ isWatchList, dataPanel }) => {
  const debouncedUpdateLastState = useDebouncedCallback();

  const userLastState = useSelector((state) => state.accountState.userSettings, shallowEqual);
  const updatedLastState = useSelector((state) => state.accountState.updatedLastState, shallowEqual);
  const lastStateId = useSelector((state) => state.accountState.lastStateId);

  const {
    getDataPanelItems,
    renameCategory,
    updatePanelsData,
    updateCategoryData,
    maxCategoryError,
    checkUniqueCategory,
    saveDataPanels
  } = useDataPanelItems();

  const {
    isOpen, toggleDropOpen,
    dataPanelCategory, selectedCategory,
    setSelectedCategory,
    addCategory, requestedPoints,
    scrollHandler, addPoint, removePoint, selectedPoints,
    search, handleSearch, getPointTitle, getPointData
  } = useDropdownCategory();

  const {
    toggleModal,
    isModalOpen, modalData
  } = useDataPanelsModals();

  const {
    isPanelOpen, setDataPanelGridType,
    dataPanelType, addPanelOpen,
    togglePanelAdd,
    currentDataPanels, editMode,
    setEditMode,
  } = useDataPanel();

  const {
    getDataPanelPreset,
    getDataPanelPresetFolders,
  } = useWorkerItems();

  const { updateUserState } = useAccount();

  const { dataPoints, screenerId, symbol } = useDataPanelConnection(isWatchList);

  useEffect(() => {
    getDataPanelItems();
    getDataPanelPreset();
    getDataPanelPresetFolders();
  }, []);

  const handleRestoreToDefault = () => {
    toggleModal(true, MODAL_TYPES.RESTORE_DEFAULTS, null);
  };

  const handleRemove = (category) => {
    toggleModal(true, MODAL_TYPES.REMOVE_CATEGORY, category);
  };

  const handleRename = (category, newCategory) => {
    renameCategory(category, newCategory);
    if (newCategory && newCategory?.length <= MAX_CATEGORY_NAME && checkUniqueCategory(newCategory)) {
      const timeoutSet = setTimeout(() => {
        setSelectedCategory(newCategory);
        clearTimeout(timeoutSet);
      }, 100);
    }
  };

  const handleRemovePoint = (point) => {
    removePoint(point);
  };

  const addNewCategory = () => {
    setEditMode(true);
    togglePanelAdd();
    toggleModal(true, MODAL_TYPES.ADD_CATEGORY, null);
  };

  const getDataPanelContent = () => {
    if (Object.keys(dataPoints)?.length < 1) return <></>;
    if (!editMode) {
      return (
        <DataPanelContent
          symbolIndex={symbol}
          dataPanels={currentDataPanels}
          dataPoints={dataPoints}
          screenerId={screenerId}
          getPointData={getPointData}
          dataPanelType={dataPanelType}
        />
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (updatedLastState) {
      debouncedUpdateLastState(updateUserState, SAVE_USER_STATE_DELAY, userLastState, lastStateId);
    }
  }, [userLastState]);

  return (
    <>
      {isPanelOpen && (
      <div id="dataPanelComponent" className={`${styles.dataPanel} ${isPanelOpen && styles.open}`} ref={dataPanel}>
        <DataPanelHeader
          dataPanelType={dataPanelType}
          setDataPanelGridType={setDataPanelGridType}
          addPanelOpen={addPanelOpen}
          togglePanelAdd={togglePanelAdd}
          restoreDefault={handleRestoreToDefault}
          editMode={editMode}
          setEditMode={setEditMode}
        />
        {(currentDataPanels && editMode) && (
          <DataPanelEditContent
            checkUniqueCategory={checkUniqueCategory}
            onSelect={setSelectedCategory}
            onRemove={handleRemove}
            onRename={handleRename}
            dataPanels={currentDataPanels}
            dataPanelType={dataPanelType}
            selected={selectedCategory}
            removeDataPoint={handleRemovePoint}
            getTitle={getPointTitle}
            updatePanelsData={updatePanelsData}
            updateCategoryData={updateCategoryData}
            saveDataPanels={saveDataPanels}
          />
        )}
        {
          (currentDataPanels?.leftColumn.length || currentDataPanels?.rightColumn.length)
            ? getDataPanelContent()
            : <WatchlistEmptyBlock isDataPanel addNewCategory={addNewCategory} />
        }
      </div>
      )}
      <ClientOnlyPortal selector="#watchlist_modals">
        {isModalOpen ? <DataPanelModal checkUniqueCategory={checkUniqueCategory} modalData={modalData} /> : <div />}
      </ClientOnlyPortal>

      {isPanelOpen && addPanelOpen && (
        <DataPanelAddComponent
          toggleModal={toggleModal}
          togglePanelAdd={togglePanelAdd}
          categories={dataPanelCategory}
          selectCategory={setSelectedCategory}
          selected={selectedCategory}
          isOpen={isOpen}
          toggleDropdown={toggleDropOpen}
          addCategory={addCategory}
          pointList={requestedPoints}
          addPoint={addPoint}
          selectedPoints={selectedPoints}
          scrollHandler={scrollHandler}
          removePoint={removePoint}
          searchHandler={handleSearch}
          search={search}
          maxCategoryError={maxCategoryError}
        />
      )}
    </>
  );
};

DataPanel.propTypes = {
  dataPanel: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]).isRequired,
  isWatchList: PropTypes.bool
};

DataPanel.defaultProps = {
  isWatchList: false,
};

export default DataPanel;
