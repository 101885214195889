import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from '../sass/DataPanel.module.scss';
import { DATA_PANEL_ACTION_TYPES, PANEL_CONTENT_GRID_TYPES } from '../dataPanelConstants';
import DataPanelContentEditItems from './DataPanelContentEditItems';

const DataPanelEditContent = ({
  dataPanelType, dataPanels,
  onRemove, onRename,
  selected, onSelect,
  removeDataPoint, getTitle,
  updatePanelsData, updateCategoryData,
  checkUniqueCategory, saveDataPanels
}) => {
  const [disableDrag, setDisableDrag] = useState(false);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const sourcePanel = source.droppableId;
    const destinationPanel = destination.droppableId;
    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    const sourceItem = dataPanels[sourcePanel][sourceIndex];
    dataPanels[sourcePanel].splice(sourceIndex, 1);
    dataPanels[destinationPanel].splice(destinationIndex, 0, sourceItem);
    updatePanelsData(dataPanels);
    saveDataPanels(DATA_PANEL_ACTION_TYPES.ADD_CATEGORY);
  };

  const getClass = () => {
    switch (dataPanelType) {
      case PANEL_CONTENT_GRID_TYPES.COMPACT:
        return styles.compact;
      default:
        return styles.default;
    }
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div className={`${styles.dataPanelContent} ${getClass()}`}>
        <Droppable
          droppableId="leftColumn"
        >
          {(provided) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.dataPanelContentInner}
            >
              {dataPanels?.leftColumn.map((item, index) => (
                <Draggable
                  key={[item?.title, 'dragCart'].join('_')}
                  draggableId={item?.title}
                  index={index}
                  isDragDisabled={disableDrag}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  {(provided) => (
                    <div
                      ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.dragHandleProps}
                    >
                      <DataPanelContentEditItems
                        data={item}
                        onRemove={onRemove}
                        onRename={onRename}
                        selected={selected === item?.title}
                        onSelect={onSelect}
                        removeDataPoint={removeDataPoint}
                        getTitle={getTitle}
                        updateCategoryData={updateCategoryData}
                        setDisableDrag={setDisableDrag}
                        checkUniqueCategory={checkUniqueCategory}
                        saveDataPanels={saveDataPanels}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Droppable
          droppableId="rightColumn"
        >
          {(provided) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={styles.dataPanelContentInner}
            >
              {dataPanels?.rightColumn.map((item, index) => (
                <Draggable
                  key={[item?.title, 'dragCart'].join('_')}
                  draggableId={item?.title}
                  index={index}
                  isDragDisabled={disableDrag}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  {(provided) => (
                    <div
                      ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.dragHandleProps}
                    >
                      <DataPanelContentEditItems
                        data={item}
                        onRemove={onRemove}
                        onRename={onRename}
                        selected={selected === item?.title}
                        onSelect={onSelect}
                        removeDataPoint={removeDataPoint}
                        getTitle={getTitle}
                        updateCategoryData={updateCategoryData}
                        setDisableDrag={setDisableDrag}
                        checkUniqueCategory={checkUniqueCategory}
                        saveDataPanels={saveDataPanels}
                      />
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>

    </DragDropContext>
  );
};
DataPanelEditContent.propTypes = {
  saveDataPanels: PropTypes.func.isRequired,
  dataPanelType: PropTypes.string,
  dataPanels: PropTypes.shape({
    leftColumn: PropTypes.arrayOf(PropTypes.shape({})),
    rightColumn: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onRemove: PropTypes.func.isRequired,
  onRename: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.string,
  removeDataPoint: PropTypes.func.isRequired,
  getTitle: PropTypes.func.isRequired,
  updatePanelsData: PropTypes.func.isRequired,
  updateCategoryData: PropTypes.func.isRequired,
  checkUniqueCategory: PropTypes.func.isRequired,
};

DataPanelEditContent.defaultProps = {
  dataPanelType: 'default',
  selected: '',
};

export default DataPanelEditContent;
