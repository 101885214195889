import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import { IUseSearch } from '../types/EducationComponentsInterfaces';

const useSearch: IUseSearch = (
  params,
  setParams
) => {
  const debouncedUpdateLastState = useDebouncedCallback();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      ...params,
      search: e.target.value,
      page: 1,
      isLoadMore: false,
    };

    debouncedUpdateLastState(setParams, 750, data);
  };

  const handleClear = () => {
    const resetParams = { search: '', page: 1, isLoadMore: false };
    setParams({ ...params, ...resetParams });
  };
  return { handleSearch, handleClear };
};

export default useSearch;
