import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/AlertsList.module.scss';
import SelectListItem from './SelectListItem';

const SelectDropDown = ({
  selectOption,
  selectHandler,
  activeId,
  showChevron,
  isAlertList,
}) => (
  <ul className={styles.selectDropdown}>
    {selectOption.map((option) => (
      <SelectListItem
        key={[option.title, option.id].join('_')}
        selectHandler={selectHandler}
        activeId={activeId}
        isAlertList={isAlertList}
        showChevron={showChevron}
        option={option}
      />
    ))}
  </ul>
);

SelectDropDown.propTypes = {
  selectOption: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectHandler: PropTypes.func.isRequired,
  activeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  showChevron: PropTypes.bool,
  isAlertList: PropTypes.bool,
};

SelectDropDown.defaultProps = {
  showChevron: true,
  isAlertList: true,
};

export default SelectDropDown;
