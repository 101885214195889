const ACTION_DASHBOARD = {
  GET_ITEMS: '@@GET_ITEMS',
  SET_FILTER_MARKET_WATCHLIST: '@@SET_FILTER_MARKET_WATCHLIST',
  SET_ITEMS_CLEAN: '@@SET_ITEMS_CLEAN',
  SET_ITEMS_CLEAN_COLUMNS: '@@SET_ITEMS_CLEAN_COLUMNS',
  GET_SYMBOLS: '@@GET_SYMBOLS',
  GET_TYPES: '@@GET_TYPES',
  SAVE_ITEMS: '@@SAVE_ITEMS',
  SAVE_ITEMS_FIRST: '@@SAVE_ITEMS_FIRST',
  SET_NEW_COLUMNS: '@@SET_NEW_COLUMNS',
  SET_LIST_COLUMNS: '@@SET_LIST_COLUMNS',
  UPDATE_ORDER_COLUMNS: '@@UPDATE_ORDER_COLUMNS',
  REMOVE_COLUMN_ITEM: '@@REMOVE_COLUMN_ITEM',
  COLUMNS_WERE_UPDATE: '@@COLUMNS_WERE_UPDATE',
  FILTERS_WERE_UPDATE: '@@FILTERS_WERE_UPDATE',
  SAVE_SYMBOLS_DATA: '@@SAVE_SYMBOLS_DATA',
  SAVE_COLUMNS_DATA: '@@SAVE_COLUMNS_DATA',
  SAVE_GROUPS_DATA: '@@SAVE_GROUPS_DATA',
  SET_SELECTED_SHAPE: '@@SET_SELECTED_SHAPE',
  SAVE_TEXTS_DATA: '@@SAVE_TEXTS_DATA',
  SAVE_BASE_URL: '@@SAVE_BASE_URL',
  SET_COLUMNS: '@@SET_COLUMNS',
  SET_STOP_TICK: '@@SET_STOP_TICK',
  SET_LIST_FOR_FILTER: '@@SET_LIST_FOR_FILTER',
  RESET_CURRENT_PAGE: '@@RESET_CURRENT_PAGE',
  SET_ACTIVE_SYMBOL: '@@SET_ACTIVE_SYMBOL',
  SET_ACTIVE_SYMBOL_ORDER: '@@SET_ACTIVE_SYMBOL_ORDER',
  SET_ACTIVE_SYMBOL_TEXT: '@@SET_ACTIVE_SYMBOL_TEXT',
  RESET_ITEMS_DASHBOARD: '@@RESET_ITEMS_DASHBOARD',
  CHANGE_TAB_ACTION: '@@CHANGE_TAB_ACTION',
  RESET_STATE_DASHBOARD: '@@RESET_STATE_DASHBOARD',
  RESET_CHART_DATA_DASHBOARD: '@@RESET_CHART_DATA_DASHBOARD',
  SET_FAVORITE_FILTERS: '@@SET_FAVORITE_FILTERS',
  TOGGLE_ESTIMATE: '@@TOGGLE_ESTIMATE',
  SET_NEXT_PAGE_FLAG: '@@SET_NEXT_PAGE_FLAG',
  SET_COUNTER_SYMBOLS: '@@SET_COUNTER_SYMBOLS',
  SET_FILTER_MARKET: '@@SET_FILTER_MARKET',
  SAVE_FILTERS_DATA: '@@SAVE_FILTERS_DATA',
  OPEN_COLUMNS_SCREEN: '@@OPEN_COLUMNS_SCREEN',
  SET_VISIBLE_ITEMS: '@@SET_VISIBLE_ITEMS',
  SET_CURRENT_PAGE: '@@SET_CURRENT_PAGE',
  SET_DEFS_URLS_SYMBOLS: '@@SET_DEFS_URLS_SYMBOLS',
  SET_DEFS_URLS_COLUMNS: '@@SET_DEFS_URLS_COLUMNS',
  SET_DEFS_URLS_FILTERS: '@@SET_DEFS_URLS_FILTERS',
  SET_DEFS_URLS_COLUMNS_GROUPS: '@@SET_DEFS_URLS_COLUMNS_GROUPS',
  SET_DEFS_URLS_DATA_POINTS: '@@SET_DEFS_URLS_DATA_POINTS',
  SET_SHARED_DADHBOARD_ITEM: '@@SET_SHARED_DADHBOARD_ITEM',
  SET_CURRENT_TAB: '@@SET_CURRENT_TAB',
  SET_INITIAL_COLUMNS: '@@SET_INITIAL_COLUMNS',
  SET_INITIAL_DATA: '@@SET_INITIAL_DATA',
  CHECK_CURRENT_VERSION: '@@CHECK_CURRENT_VERSION',
  SET_HASBEENUPDATED_POPUP_VISIBLE: '@@SET_HASBEENUPDATED_POPUP_VISIBLE',
  SET_ERROR: '@@SET_ERROR',
  SET_CURRENT_VERSION: '@@SET_CURRENT_VERSION',
  SET_ADDITIONAL_DATA_SCREENER: '@@SET_ADDITIONAL_DATA_SCREENER',
  SET_SELECTED_SYMBOLS: '@@SET_SELECTED_SYMBOLS',
  SET_SELECTED_SYMBOLS_BATCH: '@@SET_SELECTED_SYMBOLS_BATCH',
  CLEAR_SELECTED_SYMBOLS: '@@CLEAR_SELECTED_SYMBOLS',
  SET_ROW_STYLE: '@@SET_ROW_STYLE',
  SET_VOLUME_DAY_HISTORY: '@@SET_VOLUME_DAY_HISTORY',
  SET_FIRST_VOLUME_DAY_HISTORY: '@@SET_FIRST_VOLUME_DAY_HISTORY',
  SET_COLUMNS_OBJECT_ID: '@@SET_COLUMNS_OBJECT_ID',
  SET_LOADER: '@@SET_LOADER',
  TOGGLE_ALERTS_LIST_VISIBLE: '@@TOGGLE_ALERTS_LIST_VISIBLE',
  SET_ALERTS_LIST: '@@SET_ALERTS_LIST',
  SET_TABLE_SORTED: '@@SET_TABLE_SORTED',
  SET_QUERY_ID: '@@SET_QUERY_ID',
  SET_TV_IS_READY: '@@SET_TV_IS_READY',
  SET_SEARCH_VALUE: '@@SET_SEARCH_VALUE',
  SET_TAB_UPDATE_FLAG: '@@SET_TAB_UPDATE_FLAG',
  SET_SEARCH_HEADER_VALUE: '@@SET_SEARCH_HEADER_VALUE',
  SET_LAYOUTS_LIST: '@@SET_LAYOUTS_LIST',
  SET_DEFAULT_LAYOUT: '@@SET_DEFAULT_LAYOUT',
  SET_ROUTE: '@@SET_ROUTE',
  SET_SORT_DATA: '@@SET_SORT_DATA',
  ADD_SORT_DATA: '@@ADD_SORT_DATA',
  SET_ETF_SYMBOL_INDEX: '@@SET_ETF_SYMBOL_INDEX',
  TOGGLE_SAME_SYMBOL_FLAG: '@@TOGGLE_SAME_SYMBOL_FLAG',
};

export default ACTION_DASHBOARD;
