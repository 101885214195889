import React, {
  useContext, useEffect, useRef, useState
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import useLastSymbolState from '../../../utils/hooks/useLastSymbolState';
import usePrepareLastSymbolState from '../../../utils/hooks/usePrepareLastSymbolState';
import {
  downFunction,
  initPositionHandler,
  upFunction
} from '../../../utils/helpers';
import {
  KEY_CODES,
  SUBSCRIPTION_TYPE,
  TABLE_SCREEN_PATH
} from '../../../constants/screener';
import ScreenerTable from '../../../components/Screener/ScreenerTable';
import GraphicView from './GraphicView';
import useAlertsDataWorker from '../DeeplistUtils/hooks/useAlertsDataWorker';
import { orderedCache } from '../../../services/ChartPrefetchServices/OrderedSymbolCache';
import { eventHandler } from '../../../components/ChartContainer/utils/chartingHelper';
import useMainTableWorker from '../hooks/useMainTableWorker';
import ClientOnlyPortal from '../../../components/ClientOnlyPortal/ClientOnlyPortal';
import Columns from '../../../components/Columns/Columns';
import { DialogItemEntityType } from '../../../components/DashboardsItemsDropdown/types/constants';
import { DashboardItemsDialogActionTypes } from '../../../components/DashboardItemsDialog/types/constants';
import UseDashboardsDialogs from '../../../components/DashboardItemsDialog/hooks/UseDashboardsDialogs';
import DashboardItemsDialog from '../../../components/DashboardItemsDialog/DashboardItemsDialog';
import { checkRoleUser } from '../../../utils/userHelper';
import TableContext from '../context/TableContext';

const ScreenerViewMain = () => {
  const {
    isWatchlist,
    alertFilters,
    isAlertsTab,
    activeSymbolIndexValue,
    selectedItems,
    openModalHandler,
    clearSelectedShapeId,
    setIsVisibleFilters,
    getScreenerLayout,
    hideChart,
    handleChangeLayoutSize,
    handleOpenChart,
    globalSearchKeyHandler,
    isSearch,
    setAlertItems,
    setAllAlertItems,
    currentAlertData,
    activeAlert,
    setActiveAlert,
    alertColumns,
  } = useContext(TableContext);

  const {
    tableData,
    columnsData,
    sortHandler,
    sortStatus,
    clearSortingHandler,
    sortData,
    setNewSortData,
    setLoader,
    handleSetAllItems,
    setItems,
    setTopScreenerIndex,
    setVisibleItemsTable,
    setOpenETF,
    sectorIndustryWidgetHandler,
    volumeBarsData,
    additionalData,
    loaderStatus,
    isHoldingView,
    tableIndexesData
  } = useMainTableWorker(
    () => undefined,
    isAlertsTab,
    activeSymbolIndexValue,
    alertFilters,
  );

  const chartInputFocusRef = useRef(null);
  const activeSymbolRef = useRef(0);

  const [activePosition, setActivePosition] = useState(0);
  const [hideEvents, setHideEvents] = useState(false);
  const [scrollTop, setScrollTop] = useState(1);

  const profile = useSelector((state) => state.accountState.userProfile, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const lastScreenerSymbol = useSelector((state) => state.accountState.userSettings.lastScreenerSymbol, shallowEqual);
  const lastDeepListSymbol = useSelector((state) => state.accountState.userSettings.lastDeepListSymbol, shallowEqual);
  const lastAlertsSymbol = useSelector((state) => state.accountState.userSettings.lastAlertsSymbol, shallowEqual);
  const searchValue = useSelector((state) => state.screenerState.searchValue, shallowEqual);
  const selectedTabID = useSelector((state) => state.accountState.userSettings.selectedTabId, shallowEqual);
  const symbolsList = useSelector((state) => state.screenerState.symbolsList, shallowEqual);
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);
  const tableLoaded = useSelector((state) => state.screenerState.tableLoaded, shallowEqual);
  const userSettings = useSelector((state) => state.accountState.userSettings, shallowEqual);
  const selectedShapeId = useSelector((state) => state.screenerState.selectedShapeId, shallowEqual);

  const {
    actionOpenDialog,
    open,
    actionCancelDialog,
    dialogData,
  } = UseDashboardsDialogs();
  const { updateLastSymbolState } = useLastSymbolState();
  const { currentSymbolHandler } = usePrepareLastSymbolState();

  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  useAlertsDataWorker(tableData, columnsData, sortData);

  const setActiveSymbolValue = (activeSymbolIndex, activeSymbolOrderValue) => {
    if (!activeSymbolOrderValue) return;
    if (isAlertsTab) {
      setActiveAlert(activeSymbolOrderValue?.alertIndex);
    }
  };

  useEffect(() => {
    orderedCache.setData(tableData);
  }, [tableData]);

  useEffect(() => {
    initPositionHandler(currentSymbolHandler(isWatchlist).index, tableIndexesData, setActivePosition);
  }, [tableIndexesData, sortData, pagePath]);

  useEffect(() => {
    activeSymbolRef.current = currentSymbolHandler(isWatchlist).index;
    setActivePosition(currentSymbolHandler(isWatchlist).position);
    return () => {
      activeSymbolRef.current = null;
    };
  }, [
    lastScreenerSymbol,
    lastDeepListSymbol,
    lastAlertsSymbol,
    searchValue,
    pagePath
  ]);

  const keyDownHandler = (event) => {
    event.preventDefault();
    if (event.code === KEY_CODES.ESC) {
      clearSelectedShapeId();
    }
    if (event.code === KEY_CODES.ARROW_UP && !selectedShapeId) {
      upFunction(
        isAlertsTab ? activeAlert : activeSymbolRef.current,
        isAlertsTab,
        isAlertsTab ? currentAlertData : tableData,
        setActiveAlert,
        updateLastSymbolState,
        isWatchlist,
      );
    }

    if ((event.code === KEY_CODES.ARROW_DOWN || event.code === KEY_CODES.SPACE) && !selectedShapeId) {
      downFunction(
        isAlertsTab ? activeAlert : activeSymbolRef.current,
        isAlertsTab,
        isAlertsTab ? currentAlertData : tableData,
        setActiveAlert,
        updateLastSymbolState,
        isWatchlist,
        setScrollTop,
        scrollTop
      );
    }
  };

  useEffect(() => {
    const messageHandler = (event) => {
      const {
        type, key, ctrl, alt, meta, focused
      } = event?.data;

      if (type === 'keydown') {
        if (!chartInputFocusRef.current && (!ctrl && !alt && !meta)) {
          keyDownHandler(eventHandler(key));
          globalSearchKeyHandler(eventHandler(key));
        }
      }
      if (event.data.type === 'input-focus') {
        chartInputFocusRef.current = focused;
      }
    };

    window.addEventListener('message', messageHandler);

    return () => {
      chartInputFocusRef.current = null;
      window.removeEventListener('message', messageHandler);
    };
  }, [tableLoaded, pagePath, loaderStatus, tableData, selectedShapeId]);

  useEffect(() => {
    if (isWatchlist && selectedTab?.data?.filters?.length && !selectedTab.parentId && symbolsList.length) {
      const filtersData = selectedTab?.data?.filters.flat(3);
      const currentItem = symbolsList.find((symbol) => symbol.index === filtersData[2]);
      setActiveSymbolValue(currentItem?.index || 0, currentItem);
    }
  }, [selectedTabID, symbolsList, pagePath]);

  const getColumns = () => {
    return isAlertsTab ? alertColumns : columnsData;
  };

  const getData = () => {
    return isAlertsTab ? currentAlertData : tableData;
  };

  const folderModalHandler = (data) => {
    actionOpenDialog(
      data ? DashboardItemsDialogActionTypes.EDIT : DashboardItemsDialogActionTypes.ADD,
      2,
      DialogItemEntityType.FOLDER,
      0,
      data
    );
  };

  return (
    <>
      <ScreenerTable
        scrollTop={scrollTop}
        selectedTab={selectedTab}
        data={getData()}
        profile={profile}
        columns={getColumns()}
        setItems={isAlertsTab ? setAlertItems : setItems}
        setLoader={setLoader}
        sortStatus={sortStatus}
        setAllItems={isAlertsTab ? setAllAlertItems : handleSetAllItems}
        sortHandler={sortHandler}
        activePosition={activePosition}
        loaderStatus={loaderStatus}
        selectedItems={selectedItems}
        keyDownHandler={keyDownHandler}
        setIsVisibleFilters={setIsVisibleFilters}
        clearSorting={clearSortingHandler}
        handleChangeLayoutSize={handleChangeLayoutSize}
        hideChart={hideChart}
        handleOpenChart={handleOpenChart}
        setHideEvents={setHideEvents}
        hideColumnsSettings={isAlertsTab}
        activeAlert={activeAlert}
        watchList={isWatchlist}
        setActiveAlert={setActiveAlert}
        isSearch={isSearch}
        sortData={sortData}
        setTopIndexWidget={setTopScreenerIndex}
        setVisibleItemsTable={setVisibleItemsTable}
        setOpenEtfWidget={setOpenETF}
        sectorIndustryWidgetHandler={sectorIndustryWidgetHandler}
        volumeBarsWidget={volumeBarsData}
        additionalDataWidget={additionalData}
        isHoldingViewFromWidget={isHoldingView}
      />
      <GraphicView
        profile={profile}
        isAlertTable={isAlertsTab}
        watchList={isWatchlist}
        hideEvents={hideEvents}
        screenerLayout={getScreenerLayout}
      />
      <ClientOnlyPortal selector="#columns">
        <Columns
          sortStatus={sortStatus}
          clearSorting={clearSortingHandler}
          selectedScreenId={pagePath === TABLE_SCREEN_PATH.SCREENER ? userSettings.selectedScreenId : null}
          currentModalRef={null}
          openModalHandler={openModalHandler}
          sortData={sortData}
          setNewSortData={setNewSortData}
          folderModalHandler={folderModalHandler}
          isWatchlist={pagePath === TABLE_SCREEN_PATH.DEEPLIST}
        />
        {open && (
          <DashboardItemsDialog
            open={open}
            actionCancel={actionCancelDialog}
            dialogData={dialogData}
            currentItemHandler={() => {}}
            adminRole={adminRole}
          />
        )}
      </ClientOnlyPortal>
    </>
  );
};

export default ScreenerViewMain;
