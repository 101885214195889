import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/WatchListModals.module.scss';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { TWO_HUNDRED } from '../../constants/screener';

const SearchDropDownEmpty = ({ value, noResultTitle }) => {
  const [resultIsEmpty, setResultIsEmpty] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setResultIsEmpty(true);
    }, TWO_HUNDRED);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={`${resultIsEmpty ? styles.empty : styles.emptyBlank}`}>
      {resultIsEmpty && (
        <>
          <span className={styles.iconWrapper}>
            <SearchIcon />
          </span>
          <p>
            {noResultTitle}
            {' '}
            <strong>{value}</strong>
          </p>
        </>
      )}
    </div>
  );
};

SearchDropDownEmpty.propTypes = {
  value: PropTypes.string.isRequired,
  noResultTitle: PropTypes.string,
};

SearchDropDownEmpty.defaultProps = {
  noResultTitle: '',

};

export default SearchDropDownEmpty;
