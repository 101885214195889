import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../../sass/PickColumnWrapper.module.scss';
import useSearchByColumns from '../../../../utils/hooks/useSearchByColumns';
import TextField from '../../../TextField/TextField';
import { ReactComponent as ClearIcon } from '../../../../assets/images/icons/screener/close_blue.svg';
import { scrollToItem } from '../../../../utils/helpers';

const SearchBlock = ({
  columnsSideData,
  setCurrentGroup,
  visibleColumnsScreen,
  openedGroupHandler
}) => {
  const searchInput = useRef(null);

  const { t } = useTranslation();
  const groupsList = useSelector((state) => state.screenerState.groupsList, shallowEqual);

  const {
    search, handlerSearch, listResults, setSearch
  } = useSearchByColumns(columnsSideData);

  const searchResultHandler = (index, id, groupId) => {
    openedGroupHandler(groupId);
    setSearch('');
    setCurrentGroup(index);
    setTimeout(() => {
      scrollToItem(id, 'smooth');
    }, 100);
  };

  useEffect(() => {
    if (visibleColumnsScreen === true) {
      searchInput.current.focus();
    }
  }, [visibleColumnsScreen]);

  return (
    <div className={styles.searchBlockWrapper}>
      <input
        onChange={(e) => handlerSearch(e)}
        value={search}
        placeholder={t('searchByColumns')}
        ref={searchInput}
      />
      {search && (
        <div
          aria-hidden="true"
          onMouseDown={() => setSearch('')}
        >
          <ClearIcon className={styles.clearInput} />
        </div>
      )}
      <div className={styles.searchBlockDropWrapper}>
        {listResults.length ? (
          listResults.map((item) => {
            const parentGroup = groupsList.find(
              (groupItem) => groupItem.index === item.group
            );
            return (
              <div
                key={item.id}
                className={styles.searchBlockDropItem}
                aria-hidden="true"
                onMouseDown={() => searchResultHandler(parentGroup.index, item.name, item.group)}
              >
                <TextField text={item.name} styleType="searchResultTitle" />
                <TextField text={parentGroup?.name ? parentGroup.name : 'UnGrouping'} styleType="searchResultGroup" />
              </div>
            );
          })
        ) : null}
      </div>
    </div>
  );
};

SearchBlock.propTypes = {
  setCurrentGroup: PropTypes.func.isRequired,
  openedGroupHandler: PropTypes.func.isRequired,
  columnsSideData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  visibleColumnsScreen: PropTypes.bool.isRequired
};
export default React.memo(SearchBlock);
