import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyLinePlotPreferences, StudyPlotType } from '../../charting_library/charting_library';
import { EPS } from '../../constants/customIndicatorsSettings';

const earningsLine = (mPineJS: PineJS): CustomIndicator => {
  return {
    name: 'DV - Earnings Line',
    metainfo: {
      id: 'TL-DV-earningsline@tv-basicstudies-1' as RawStudyMetaInfoId,
      isCustomIndicator: true,
      is_price_study: true,
      //@ts-ignore:next-line
      canExtendTimeScale: true,
      description: 'DV - Earnings Line',
      shortDescription: 'DV - Earnings Line',
      priceScale: 0,
      format: {
        type: 'inherit'
      },
      defaults: {
        inputs: {
          type: 'EPS Line',
          showEstimates: true,
          showTTM: true,
        },
        precision: 2,
        styles: {
          plot_0: {
            color: '#3A75E8',
            plottype: 0 as StudyLinePlotPreferences['plottype'],
            visible: true,
            transparency: 0
          }
        },
      },
      inputs: [
        {
          id: 'type',
          name: 'Type',
          type: 'text' as StudyInputType.Text,
          defval: 'EPS Line',
          options: ['EPS Line', 'EPS Growth'],
          optionsTitles: { ['EPS Line']: 'Earnings per Share ($)', ['EPS Growth']: 'Earnings per Share Growth (%)' }
        },
        {
          id: 'showEstimates',
          name: 'Show Estimates',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
        {
          id: 'showTTM',
          name: 'Trailing Twelve Month (TTM)',
          type: 'bool' as StudyInputType.Bool,
          defval: true
        },
      ],
      styles: {
        plot_0: {
          title: 'Line',
          histogramBase: 0,
        }
      },
      plots: [
        { id: 'plot_0', type: 'line' as StudyPlotType.Line }
      ]
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = function (context, inputs) {
        this._context = context;
        this.closeSeries = NaN;
        if (inputs(0) === 'EPS Line') {
          if (mPineJS.Std.isweekly(this._context)
            || mPineJS.Std.period(this._context) === '1M') {
            context.new_sym(`${mPineJS.Std.ticker(context)}${EPS.VALUE_DAILY}`, mPineJS.Std.period(context));
          }
        } else {
          if (mPineJS.Std.isweekly(this._context)
            || mPineJS.Std.period(this._context) === '1M') {
            context.new_sym(`${mPineJS.Std.ticker(context)}${EPS.GROWTH_DAILY}`, mPineJS.Std.period(context));
          }
        }
      };
      this.main = (context, inputs) => {
        this._context = context;
        let newSymClose = NaN;
        let rollingTTM = NaN;

        if (mPineJS.Std.isweekly(this._context)
          || mPineJS.Std.period(this._context) === '1M') {
          this._context.select_sym(1);
          const IsEstimates = mPineJS.Std.volume(context);
          if (IsEstimates === 2) {
            newSymClose = mPineJS.Std.close(context);
            if (!isNaN(mPineJS.Std.close(context))) {
              this.closeSeries = context.new_unlimited_var(mPineJS.Std.close(context));
            }
          }
          else if (inputs(1) && (IsEstimates === 1)) {
            newSymClose = mPineJS.Std.close(context);
            if (!isNaN(newSymClose)) {
              this.closeSeries = context.new_unlimited_var(newSymClose);
            }
          }

          if (inputs(2)
            && !isNaN(this.closeSeries.get?.(0))
            && !isNaN(this.closeSeries.get?.(1))
            && !isNaN(this.closeSeries.get?.(2))
            && !isNaN(this.closeSeries.get?.(3))
          ) {
            rollingTTM = (this.closeSeries.get?.(0) + this.closeSeries.get?.(1) + this.closeSeries.get?.(2) + this.closeSeries.get?.(3));
          }
          if (!inputs(1) && IsEstimates === 1) {
            rollingTTM = NaN;
          }

          if (inputs(0) === 'EPS Growth' && !isNaN(newSymClose)) {
            newSymClose = newSymClose * 100;
            rollingTTM = rollingTTM * 100;
          }
        }
        return [
          !inputs(2) ? newSymClose : rollingTTM
        ];
      };
    }
  };
};

export default earningsLine;
