import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../sass/PickColumnWrapper.module.scss';
import Checkbox from '../../../Checkbox/Checkbox';
import TextField from '../../../TextField/TextField';

const areEqual = (prevProps, nextProps) => {
  return prevProps.checkStatus === nextProps.checkStatus
  && prevProps.columnsSelectedHandler === nextProps.columnsSelectedHandler;
};

const ColumnsListItem = ({
  item, columnsSelectedHandler, checkStatus, disabled
}) => {
  const setSelectedColumns = () => {
    columnsSelectedHandler(item);
  };
  return (
    <div id={item.name} className={`${styles.itemColumn} ${disabled ? styles.disabledItem : ''}`}>
      <Checkbox
        checked={checkStatus}
        changeHandler={setSelectedColumns}
        id={`${item.id}_columns`}
      />
      <TextField text={item.name} styleType="itemColumnText" />
    </div>
  );
};

ColumnsListItem.propTypes = {
  checkStatus: PropTypes.bool.isRequired,
  columnsSelectedHandler: PropTypes.func.isRequired,
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    group: PropTypes.number.isRequired
  }).isRequired,
  disabled: PropTypes.bool,
};

ColumnsListItem.defaultProps = {
  disabled: false,
};

export default React.memo(ColumnsListItem, areEqual);
