import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../sass/AlertsList.module.scss';
import { ALERTS_TOAST_SETTING } from '../../../constants/screener';
import { roundedNumber } from '../../ChartContainer/utils/alertHelper';
import { DEFAULT_PRECISION } from '../../../constants/alerts';
import TextWithTooltip from '../../DataPanel/components/TextWithTooltip';

const ToastPriceSurge = ({ priceSurge }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.priceSurge}>
      <TextWithTooltip
        placement="bottom-end"
        isStatic
        title={t('priceSurge')}
        textTooltip={t('tooltipPriceSurge')}
      />
      <span
        className={`${styles.priceSurgeValue} 
          ${+priceSurge > ALERTS_TOAST_SETTING.PERCENT_RULE ? styles.grow : styles.loss}`}
      >
        {roundedNumber(+priceSurge * ALERTS_TOAST_SETTING.MULTIPLY, DEFAULT_PRECISION)}
        %
      </span>
    </div>
  );
};

ToastPriceSurge.propTypes = {
  priceSurge: PropTypes.string.isRequired
};

export default ToastPriceSurge;
