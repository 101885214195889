import React, {
  useState, useRef, useEffect
} from 'react';
import PropTypes from 'prop-types';

import { MOUSE_BUTTONS, MOUSE_EVENTS, TOUCH_EVENT } from '../../../constants/screener';
import styles from '../sass/ScreenerTable.module.scss';

const TableResizeAnchor = ({ updateHeaderCellSize, resizeStateClass }) => {
  const refElement = useRef(null);
  const [isActive, setActive] = useState(false);

  const moveAction = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (refElement?.current) {
      const clientX = e?.type === TOUCH_EVENT.TOUCH_MOVE ? e?.changedTouches[0].clientX : e?.clientX;
      const { left, width } = refElement?.current?.getBoundingClientRect();
      updateHeaderCellSize(clientX - (left + (width / 2 - 1)));
    }
  };

  const clickAction = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (e?.button === MOUSE_BUTTONS.LEFT || e?.type === TOUCH_EVENT.TOUCH_START) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const upAction = (e) => {
    e?.stopPropagation();
    setActive(false);
    if (refElement?.current) {
      const clientX = e?.type === TOUCH_EVENT.TOUCH_END ? e?.changedTouches[0].clientX : e?.clientX;
      const { left, width } = refElement?.current?.getBoundingClientRect();
      updateHeaderCellSize(clientX - (left + (width / 2 - 1)), true);
    }
    document.removeEventListener('mouseup', upAction, true);
    document.removeEventListener('touchmove', upAction);
    document.removeEventListener('mousemove', moveAction, true);
    document.removeEventListener('touchend', moveAction);
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mouseup', upAction, true);
      document.addEventListener('mousemove', moveAction, true);
      document.addEventListener('touchmove', moveAction);
      document.addEventListener('touchend', upAction);
    }

    return () => {
      document.removeEventListener('mouseup', upAction, true);
      document.removeEventListener('mousemove', moveAction, true);
      document.removeEventListener('touchmove', moveAction);
      document.removeEventListener('touchend', upAction);
    };
  }, [isActive]);

  useEffect(() => {
    return () => {
      refElement.current = null;
    };
  }, []);

  return (
    <div
      ref={refElement}
      aria-hidden="true"
      id={MOUSE_EVENTS.RESIZE}
      onMouseDown={clickAction}
      onTouchStart={clickAction}
      className={`${styles.tableHeaderTitleMove} ${isActive && styles.active} ${styles[resizeStateClass]}`}
    />
  );
};

TableResizeAnchor.propTypes = {
  updateHeaderCellSize: PropTypes.func,
  resizeStateClass: PropTypes.string,
};

TableResizeAnchor.defaultProps = {
  updateHeaderCellSize: () => undefined,
  resizeStateClass: ''
};

export default TableResizeAnchor;
