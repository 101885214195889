import STATS_ACTION from './types';

export const setLineChart = (payload) => ({
  type: STATS_ACTION.SET_LINE_DATA,
  payload
});

export const setTableChart = (payload) => ({
  type: STATS_ACTION.SET_TABLE_DATA,
  payload
});

export const setNumberOfQuartersAction = (payload) => ({
  type: STATS_ACTION.SET_NUMBER_OF_QUARTERS,
  payload
});

export const setMergeTimestampAction = (payload) => ({
  type: STATS_ACTION.SET_MERGE_TIMESTAMP,
  payload
});

export const setSourceAction = (payload) => ({
  type: STATS_ACTION.SET_SOURCE,
  payload
});

export const setBorderSettings = (payload) => ({
  type: STATS_ACTION.SET_BORDER_SETTINGS,
  payload
});
