const ROUTES_URLS = {
  SCREENER: '/screener',
  SCREENER_AMPLIFY: '/screener/',
  SCREENER_AMPLIFY_SECOND: '/screener/undefined/',
  SCREENER_AMPLIFY_THIRD: '/screener/undefined',
  WATCHLIST: '/deeplist',
  WATCHLIST_AMPLIFY: '/deeplist/',
  WATCHLIST_AMPLIFY_SECOND: '/deeplist/undefined/',
  WATCHLIST_AMPLIFY_THIRD: '/deeplist/undefined',
  DASHBOARDS: '/dashboard',
  DASHBOARDS_AMPLIFY_SECOND: '/dashboard/undefined/',
  DASHBOARDS_AMPLIFY_THIRD: '/dashboard/undefined',
  WIDGET: '/widget',
  TABLE: '/',
  SHARE_ITEM: '/share-item',
  TEST_SCREENER: '/test-screener',
  EDUCATION: '/education',
  ALERT: '/alerts',
};

export default ROUTES_URLS;
