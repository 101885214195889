import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { AllowedInputStyles, IDetails, StoredSymbol } from '../types/PositionSizeTypes';
import { IPositionLastState, IUsePositionSize } from '../types/PostionSizeInterfaces';
import useDataPositionSizeHelper from '../helper/usePositionSizeHelper';
import { InputDesignTypes, InputNames } from '../types/PositionSizeEnums';
import { useTheme } from '../../../utils/hooks/useTheme';
import ThemeVariants from '../../../constants/theme';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';

const usePositionSize: IUsePositionSize = (widget, dashboardId, updateWidget) => {
  const widgetRef = useRef<Widget | null>(null);
  const [recshares, setRecshares] = useState<string>('');
  const [posvalue, setPosvalue] = useState<string>('');
  const [amountatrisk, setAmountatrisk] = useState<string>('');
  const [rmultiple, setRmultiple] = useState<string>('');
  const [warning, setWarning] = useState(true);

  const [details, setDetails] = useState<IDetails>({
    id: '',
    acctSize: '',
    desrdPosSz: '',
    percentPosSz: '',
    desrdPFRisk: '',
    percentPFRisk: '',
    symbol: '',
    entryPrice: '',
    exitPrice: '',
    percentExitPrice: '',
    potentialPFTakingPc: '',
    percentPotentialPFTakingPc: '',
  });

  const { getQuoteHandler } = useDataPositionSizeHelper();
  const { theme } = useTheme();
  const { symbol } = widget;

  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings, shallowEqual);

  const getQuote = async (value: string | null) => {
    let findSymIndex = null;
    if (value) {
      findSymIndex = symbolsList.find((item: StoredSymbol) => item.sym === value)?.index;
    } else {
      findSymIndex = symbolsList.find((item: StoredSymbol) => item.sym === details.symbol)?.index;
    }
    if (findSymIndex >= 0) {
      const res = await getQuoteHandler(findSymIndex);
      setDetails((prev) => ({ ...prev, entryPrice: String(res) }));
    }
  };

  const updateWidgetData = (data: IDetails) => {
    const newWidget = { ...widgetRef.current, positionSizeData: data };
    widgetRef.current = newWidget as Widget;
    updateWidget(newWidget as Widget);
  };

  const updateFields = (name: string, value: string) => {
    const oldDetails = { ...details };
    const newData = { ...oldDetails, [name]: value };

    setDetails(newData);
    if (newData.id) {
      updateWidgetData(newData);
    }
  };

  const thousandSeparator = (value: number): string => (
    value.toLocaleString('en-US', {
      maximumFractionDigits: 0
    })
  );

  const handleInputEvent = (name: string, value: string) => {
    if (name === InputNames.SYMBOL) {
      updateFields(name, value.toUpperCase());
      return;
    }
    updateFields(name, value);
  };

  const dollarsToPercent = (value: string, namefield: string, target: string) => {
    if (!value || !target) {
      updateFields(namefield, '');
    } else {
      const numericValue = parseFloat(value.replace(/,/g, ''));
      const numericTarget = parseFloat(target.replace(/,/g, ''));
      if (!Number.isNaN(numericValue) && !Number.isNaN(numericTarget) && numericTarget !== 0) {
        const res = (numericValue / numericTarget) * 100;
        updateFields(namefield, res.toFixed(3));
      } else {
        updateFields(namefield, '');
      }
    }
  };

  const percentDollarHandler = (value: string, target: string) => {
    const numericValue = parseFloat(value.replace(/,/g, ''));
    const numericTarget = parseFloat(target.replace(/,/g, ''));
    if (!Number.isNaN(numericValue) && !Number.isNaN(numericTarget)) {
      return ((numericTarget / 100) * numericValue).toFixed(2);
    }
    return '';
  };

  const percentToDollars = (value: string, namefield: string, target: string) => {
    if (!value || !target) {
      updateFields(namefield, '');
      return;
    }
    const res = percentDollarHandler(value, target);
    updateFields(namefield, res);
  };

  const calcPercentProfit = (value: string, namefield: string, target: string) => {
    if (!value || !target) {
      updateFields(namefield, '');
      return;
    }
    const numericValue = parseFloat(value.replace(/,/g, ''));
    const numericTarget = parseFloat(target.replace(/,/g, ''));
    if (!Number.isNaN(numericValue) && !Number.isNaN(numericTarget) && numericTarget !== 0) {
      const res = (((numericValue - numericTarget) / numericTarget) * 100).toFixed(3);
      updateFields(namefield, res);
    } else {
      updateFields(namefield, '');
    }
  };

  const calcPercentLoss = (value: string, namefield: string, target: string) => {
    if (!value || !target) {
      updateFields(namefield, '');
      return;
    }
    const numericValue = parseFloat(value.replace(/,/g, ''));
    const numericTarget = parseFloat(target.replace(/,/g, ''));
    if (!Number.isNaN(numericValue) && !Number.isNaN(numericTarget) && numericTarget !== 0) {
      const res = (((numericTarget - numericValue) / numericTarget) * 100).toFixed(3);
      updateFields(namefield, res);
    } else {
      updateFields(namefield, '');
    }
  };

  const convertProfitLoss = (value: string, target: string, operator: number): string => {
    const numericValue = parseFloat(value.replace(/,/g, ''));
    const numericTarget = parseFloat(target.replace(/,/g, ''));
    if (!Number.isNaN(numericValue) && !Number.isNaN(numericTarget)) {
      return (numericTarget * (1 + operator * (numericValue / 100))).toFixed(3);
    }
    return '';
  };

  const calcPercentChange = (value: string, namefield: string, target: string, operator: number) => {
    if (!value || !target) {
      updateFields(namefield, '');
      return;
    }
    const res = convertProfitLoss(value, target, operator);
    updateFields(namefield, res);
  };

  const handleValidation = (obj: IDetails) => {
    const keysToSkip = ['potentialPFTakingPc', 'percentPotentialPFTakingPc', 'id'];
    return Object.values(obj).some((value, index) => {
      const key = Object.keys(obj)[index];
      if (keysToSkip.includes(key)) {
        return false;
      }
      return value === '';
    });
  };

  const handleCalculations = () => {
    const isEmpty = handleValidation(details);
    if (isEmpty) {
      setWarning(true);
      return;
    }
    let recAmountShares = 0;
    let posValue = 0;
    let equityAtRisk = 0;
    const equity = parseFloat(details.desrdPFRisk) || 0; // B6
    const entryPrice = parseFloat(details.entryPrice) || 0; // B7
    const exitPrice = parseFloat(details.exitPrice) || 0; // B8
    const posSZ = parseFloat(details.desrdPosSz) || 0; // B5
    const diff = entryPrice - exitPrice; // B7 - B8
    // Calculation 1
    const totalPrice = (equity / diff) * entryPrice;
    recAmountShares = totalPrice > posSZ ? posSZ / entryPrice : equity / diff;
    // Calculation 2
    posValue = recAmountShares * entryPrice;
    // Calculation 3
    equityAtRisk = diff * recAmountShares;
    // Calculation 4
    const potentialPFTakingPc = parseFloat(details.potentialPFTakingPc) || 0;
    const rmult = potentialPFTakingPc ? ((potentialPFTakingPc - entryPrice) / diff).toFixed(2) : '';
    setWarning(false);
    setRmultiple(rmult);
    setRecshares(recAmountShares.toFixed(0));
    setPosvalue(thousandSeparator(posValue));
    setAmountatrisk(thousandSeparator(equityAtRisk));
  };

  const getInputDesignTypeByTheme = (type: AllowedInputStyles): InputDesignTypes => {
    switch (type) {
      case AllowedInputStyles.Dollar:
        return theme === ThemeVariants.LIGHT_MODE ? InputDesignTypes.dollar : InputDesignTypes.dollarDark;
      case AllowedInputStyles.Percent:
        return theme === ThemeVariants.LIGHT_MODE ? InputDesignTypes.percent : InputDesignTypes.percentDark;
      default:
        return InputDesignTypes.regular;
    }
  };

  useEffect(() => {
    let widgetValues: IDetails;
    if (parseInt(widget.id) > 0) {
      if (widget?.positionSizeData) {
        widgetValues = widget?.positionSizeData;
      } else {
        const dashboard = userLastState.positionSizeLastState.find(
          (item: IPositionLastState) => item.dashboardId === dashboardId
        );
        widgetValues = dashboard?.widgets?.find((child: IDetails) => child.id === widget.id);
      }
      if (widgetValues) {
        setDetails(() => widgetValues);
      } else {
        setDetails((prev) => ({ ...prev, id: widget.id }));
      }
    }
    return () => {
      widgetRef.current = null;
    };
  }, []);

  useEffect(() => {
    widgetRef.current = widget;
  }, [widget]);

  useEffect(() => {
    if (symbol && details.id) {
      setDetails((prev) => ({ ...prev, symbol }));
      getQuote(symbol);
    }
  }, [symbol, details.id]);

  useEffect(() => {
    if (details?.entryPrice && details?.percentExitPrice) {
      const exitPrice = convertProfitLoss(details.percentExitPrice, details.entryPrice, -1);
      const profitPrice = convertProfitLoss(details.percentPotentialPFTakingPc, details.entryPrice, 1);
      const oldDetails = { ...details };
      const newData = { ...oldDetails, [InputNames.POTENTIALPROFIT]: profitPrice, [InputNames.EXITPRICE]: exitPrice };
      setDetails(newData);
      if (newData.id) {
        updateWidgetData(newData);
      }
    }
  }, [details?.entryPrice]);

  useEffect(() => {
    const isEmpty = handleValidation(details);
    if (!isEmpty) {
      handleCalculations();
    }
  }, [
    details.exitPrice,
    details.potentialPFTakingPc,
    details.acctSize,
    details.desrdPosSz,
    details.desrdPFRisk
  ]);

  useEffect(() => {
    if (details?.acctSize) {
      let newData = { ...details };
      if (details?.percentPFRisk) {
        const risk = percentDollarHandler(details?.percentPFRisk, details.acctSize);
        newData = { ...newData, [InputNames.EQUITYRISK]: risk };
      }
      if (details?.percentPosSz) {
        const max = percentDollarHandler(details?.percentPosSz, details.acctSize);
        newData = { ...newData, [InputNames.MAXPOSITION]: max };
      }
      setDetails(newData);
      if (newData.id) {
        updateWidgetData(newData);
      }
    }
  }, [details.acctSize]);

  return {
    details,
    recshares,
    posvalue,
    amountatrisk,
    rmultiple,
    getQuote,
    handleInputEvent,
    dollarsToPercent,
    percentToDollars,
    calcPercentProfit,
    calcPercentLoss,
    calcPercentChange,
    handleCalculations,
    getInputDesignTypeByTheme,
    warning
  };
};

export default usePositionSize;
