import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  MODAL_TYPES,
  MODAL_HEADERS,
  MODAL_ICONS, MODAL_CONTENT,
  MODAL_ACTIONS_TEXT_CONFIRM,
  MODAL_ACTIONS_TEXT_DECLINE,
  MODAL_ACTIONS_INPUT,
  MODAL_ACTIONS_REVERCE, MAX_CATEGORY_NAME
} from '../dataPanelConstants';
import useDataPanelItems from './useDataPanelItems';
import { setSelectedCategory } from '../../../store/newScreen/actions';

const useDataPanelsModals = () => {
  const dispatch = useDispatch();

  const {
    restoreDefaults, removeCategory,
    addNewCategory, renameCategory, checkUniqueCategory
  } = useDataPanelItems();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const cleanState = () => {
    setModalData(null);
    setIsModalOpen(false);
  };

  const confirmAction = (message) => {
    const { type, category, inputValue } = message;
    switch (type) {
      case MODAL_TYPES.RESTORE_DEFAULTS:
        restoreDefaults();
        cleanState();
        break;
      case MODAL_TYPES.REMOVE_CATEGORY:
        removeCategory(category);
        cleanState();
        break;
      case MODAL_TYPES.ADD_CATEGORY:
        addNewCategory(inputValue);
        cleanState();
        break;
      case MODAL_TYPES.RENAME_CATEGORY:
        if (inputValue && inputValue?.length <= MAX_CATEGORY_NAME && checkUniqueCategory(inputValue)) {
          dispatch(setSelectedCategory(inputValue));
        }
        renameCategory(category, inputValue);
        cleanState();
        break;
      default:
        break;
    }
  };

  const declineAction = () => {
    cleanState();
  };

  const prepareModalData = (modalType, categoryName) => {
    return {
      modalType,
      header: MODAL_HEADERS[modalType],
      icon: MODAL_ICONS[modalType],
      category: categoryName,
      content: MODAL_CONTENT[modalType],
      input: MODAL_ACTIONS_INPUT[modalType],
      reverceAction: MODAL_ACTIONS_REVERCE[modalType],
      actionConfirm: {
        text: MODAL_ACTIONS_TEXT_CONFIRM[modalType],
        action: confirmAction,
      },
      actionDecline: {
        text: MODAL_ACTIONS_TEXT_DECLINE[modalType],
        action: declineAction,
      },
    };
  };

  const toggleModal = (open, modalType, categoryName) => {
    setModalData(prepareModalData(modalType, categoryName));
    setIsModalOpen(open);
  };

  return {
    toggleModal,
    isModalOpen,
    modalData,
  };
};

export default useDataPanelsModals;
