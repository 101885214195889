export const ScreenerTooltipStyles = {
  arrow: { sx: { color: '#FFF', '::before': { border: '1px solid #D8E3FA' } } },
  tooltip: {
    sx: {
      color: '#7E8699',
      width: 'fit-content',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      boxShadow: '1px 8px 14px rgba(116, 116, 116, 0.16)',
      borderRadius: '6px',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      padding: '5px 8px',
      border: '1px solid #D8E3FA',
      height: 'fit-content',
      backgroundColor: '#FFF',
    }
  },
};

export const ScreenerTooltipDarkStyles = {
  arrow: { sx: { color: '#232B3B', '::before': { border: '1px solid #293142' } } },
  tooltip: {
    sx: {
      color: '#5488EF',
      width: 'fit-content',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      boxShadow: '1px 7px 14px rgba(#A7A4A41F, 0.12)',
      borderRadius: '6px',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      padding: '5px 8px',
      border: '1px solid #293142',
      height: 'fit-content',
      backgroundColor: '#232B3B',
    }
  },
};

export const FlagTooltipStyles = {
  arrow: { sx: { color: '#FFF' } },
  tooltip: {
    sx: {
      color: '#7E8699',
      width: 'fit-content',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      boxShadow: '0px 7px 14px rgba(167, 164, 164, 0.12)',
      borderRadius: '6px',
      border: '0',
      height: 'fit-content',
      backgroundColor: '#FFF',
      padding: '12px',
    }
  },
  popper: {
    sx: {
      pointerEvents: 'none',
      zIndex: 1,
    }
  }
};

export const FlagTooltipDarkStyles = {
  arrow: { sx: { color: '#232B3B', '::before': { border: '1px solid #293142' } } },
  tooltip: {
    sx: {
      color: '#B5B4C9',
      width: 'fit-content',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      boxShadow: '0px 4px 14px #161C28',
      borderRadius: '6px',
      border: '1px solid #293142',
      height: 'fit-content',
      backgroundColor: '#232B3B',
      padding: '12px',
    }
  },
  popper: {
    sx: {
      pointerEvents: 'none',
      zIndex: 1,
    }
  }
};

export const TargetListTooltipStyles = {
  tooltip: {
    sx: {
      color: '#7E8699',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      boxShadow: '0px 7px 14px rgba(167, 164, 164, 0.12)',
      borderRadius: '6px',
      backgroundColor: '#FFF',
      padding: '0',
    }
  }
};

export const TargetListTooltipDarkStyles = {
  tooltip: {
    sx: {
      color: '#232B3B',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      boxShadow: '0px 4px 14px 0px #161C28',
      borderRadius: '6px',
      backgroundColor: '#232B3B',
      border: '1px solid #293142',
      padding: '0',
    }
  }
};

export const CompanyDescriptionTooltipStyles = {
  tooltip: {
    sx: {
      color: '#7E8699',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      borderRadius: '6px',
      backgroundColor: 'transparent',
    }
  }
};

export const CompanyDescriptionTooltipDarkStyles = {
  tooltip: {
    sx: {
      color: '#B5B4C9',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: '500',
      borderRadius: '6px',
      backgroundColor: '#232B3B',
    }
  }
};

export const InfoTooltipStyles = {
  arrow: { sx: { color: '#FFF', '::before': { border: '1px solid #D8E3FA' } } },
  tooltip: {
    sx: {
      maxWidth: '290px',
      color: '#7E8699',
      width: 'fit-content',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: 'bold',
      boxShadow: '1px 8px 14px rgba(116, 116, 116, 0.16)',
      borderRadius: '6px',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      padding: '5px 8px',
      border: '1px solid #D8E3FA',
      height: 'fit-content',
      backgroundColor: '#FFF',
    }
  },
};

export const InfoTooltipDarkStyles = {
  arrow: { sx: { color: '#232B3B', '::before': { border: '1px solid #293142' } } },
  tooltip: {
    sx: {
      maxWidth: '290px',
      color: '#B5B4C9',
      width: 'fit-content',
      fontFamily: 'Quicksand, sans-serif',
      fontSize: '12px',
      fontWeight: 'bold',
      boxShadow: '0px 2px 10px #161C28',
      borderRadius: '6px',
      transform: 'matrix(1, 0, 0, -1, 0, 0)',
      padding: '5px 8px',
      border: '1px solid #293142',
      height: 'fit-content',
      backgroundColor: '#232B3B',
    }
  }
};
