import { StudyPlotType } from "../../../charting_library/charting_library";

const plots = [
  { id: 'webbyRsi', type: 'line' as StudyPlotType.Line },
  { id: 'movingAverage', type: 'line' as StudyPlotType.Line },
  { id: 'zeroPointFiveLine', type: 'line' as StudyPlotType.Line },
  { id: 'twoLine', type: 'line' as StudyPlotType.Line },
  { id: 'fourLine', type: 'line' as StudyPlotType.Line },
  { id: 'sixLine', type: 'line' as StudyPlotType.Line }
];

export default plots;
