import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes, { oneOfType } from 'prop-types';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import NewScreenButtonBlock from './NewScreenButtonBlock';
import NewScreenFolderItem from './NewScreenFolderItem';
import NewScreenModalHeader from './NewScreenModalHeader';

const RemoveSubItemModal = ({
  setOpenModal, currentItem, currentSubItem, removeSubItemFromFolder, currentPresetItem
}) => {
  const { t } = useTranslation();

  const removeItemHandler = () => {
    removeSubItemFromFolder(
      currentSubItem.id,
      { subItem: currentSubItem.title, folderName: currentSubItem.folder.name }
    );
    setOpenModal();
  };

  return (
    <ButtonListenerWrapper
      handleCancel={setOpenModal}
      handleConfirm={removeItemHandler}
    >
      <div className={stylesModal.moveToFolderWrapper}>
        <NewScreenModalHeader
          headerText={t('areYouSure')}
          cancelHandler={setOpenModal}
        />
        <div className={stylesModal.moveToFolderBody}>
          <TextField
            text={t('removeSubItem', { subItem: currentSubItem.title })}
            styleType="moveToBodyText"
          />
          <NewScreenFolderItem
            currentPresetItem={currentPresetItem}
            currentItem={currentItem}
            currentSubItem={currentSubItem}
          />
          <NewScreenButtonBlock
            cancelHandler={setOpenModal}
            cancelButton={t('cancel')}
            actionHandler={removeItemHandler}
            actionButton={t('remove')}
          />
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

RemoveSubItemModal.propTypes = {
  removeSubItemFromFolder: PropTypes.func.isRequired,
  currentSubItem: oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string
    }),
    PropTypes.string,
  ]),
  currentItem: PropTypes.shape({}),
  setOpenModal: PropTypes.func.isRequired,
  currentPresetItem: PropTypes.shape({})
};

RemoveSubItemModal.defaultProps = {
  currentItem: {},
  currentPresetItem: {},
  currentSubItem: {} || PropTypes.string,
};

export default RemoveSubItemModal;
