import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardItemDropdown.module.scss';
import SearchField from '../../SearchField/SearchField';
import TextField from '../../TextField/TextField';
import SortIconsGroup from '../../Sorting/SortIconsGroup/SortIconsGroup';
import { DasboardsItemsDropdownTypes, SEARCH_FIELD_ID } from '../types/constants';
import { ISearchSortBlock } from '../types/interfaces';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';

const SearchDropdownBlock = ({
  selectedTab,
  search,
  setSearch,
  sortStatus,
  setSort,
  itemType
}: ISearchSortBlock): React.ReactElement => {
  const { t } = useTranslation();

  const handlerSearch = (value: string) => {
    setSearch(value);
  };

  const sortedDataHandler = () => {
    let status;
    if (sortStatus.sortValue === -1) {
      status = 1;
    } else if (sortStatus.sortValue === 1) {
      status = 0;
    } else {
      status = -1;
    }
    setSort({ sortValue: status });
  };

  const searchFieldPlaceHolder = (): string => {
    if (itemType === DialogItemDataTypes.SCREENER) {
      return selectedTab === DasboardsItemsDropdownTypes.SAVED
        ? t('searchByScreensPlaceholder') : t('searchByPresetPlaceholder');
    }
    if (itemType === DialogItemDataTypes.DATA_PANEL) {
      return selectedTab === DasboardsItemsDropdownTypes.SAVED
        ? t('searchByDataPanels') : t('searchByDataPanelsPresets');
    }

    if (itemType === DialogItemDataTypes.DEEP_LIST) {
      return selectedTab === DasboardsItemsDropdownTypes.SAVED
        ? t('searchByDLPlaceholder') : t('searchByPresetDLPlaceholder');
    }

    if (itemType === DialogItemDataTypes.DASHBOARD) {
      return selectedTab === DasboardsItemsDropdownTypes.SAVED
        ? t('searchByDBlaceholder') : t('searchByPresetPlaceholder');
    }

    if (itemType === DialogItemDataTypes.COLUMN_SET) {
      return selectedTab === DasboardsItemsDropdownTypes.SAVED
        ? t('searchColumnSetsPlaceholder') : t('searchByPresetPlaceholder');
    }

    return selectedTab === DasboardsItemsDropdownTypes.SAVED
      ? t('searchByScreensPlaceholder') : t('searchByPresetPlaceholder');
  };

  useEffect(() => {
    document.getElementById(SEARCH_FIELD_ID)?.focus();
  }, [selectedTab]);

  return (
    <div className={styles.searchBlockWrapper}>
      <div className={styles.searchWrapper}>
        <SearchField
          id={SEARCH_FIELD_ID}
          handlerChange={(e) => handlerSearch(e.target.value)}
          placeholder={searchFieldPlaceHolder()}
          value={search}
          autoFocus
          resetSearchHandler={() => handlerSearch('')}
        />
      </div>
      <div className={styles.sortStatusWrapper}>
        <div>
          <TextField text={t('A')} styleType="sortTextLetter" />
          <TextField text={t('Z')} styleType="sortTextLetter" />
        </div>
        <SortIconsGroup
          sortScreens
          sortHandler={sortedDataHandler}
          index={0}
          sortStatus={sortStatus}
        />
      </div>
    </div>
  );
};

export default SearchDropdownBlock;
