import { useDispatch } from 'react-redux';
import $api, { REACT_APP_PUBLIC_LIGHT_SERVER_URL } from '../../http';
import {
  DEF_URLS, GET_BASE_URL,
} from '../../constants/paths';
import {
  saveBaseUrlScreenerAction, saveFiltersStateActions, saveInitialFiltersStateActions, setColumnsDataAction,
  setGroupsDataAction, setSymbolsDataAction,
} from '../../store/screener/actions';
import { fillSpeedValuesHandler } from './useSpeedCheckup';
import { REVERSE_SORTING_COLUMNS } from '../../constants/screener';
import { SocketConnectionService } from '../../services/Interfaces';
import { WS_CHANNELS } from '../../constants/socketConnection';

export type TDPItem = {
  id: number;
  name: string;
  sName: string;
  cName: string;
  desc: string;
  cShow: boolean;
  fType: string;
  cType: string;
  fVal: [];
  fShow: boolean;
  enabled: boolean;
  data: [];
  empty: boolean;
  roles?: string[];
  cGroup: number;
  order: number;
};

export type TDataPoint = {
  items: TDPItem[];
};

export type TDPColumn = {
  index: number;
  name: string;
  shortName: string;
  group: number;
  type: string;
  default: boolean;
  show: boolean;
  enabled: boolean;
  roles: string[];
  order: number;
};

type TUrls = {
  symbols: string;
  columnGroups: string;
  dataPoints: string;
};

interface IUseDefFiles {
  () : {
    getBaseURL: () => void;
    getDefsUrls: () => void;
    socketInstanceCallback: (socketService: SocketConnectionService) => void;
  };
}

const useDefFiles: IUseDefFiles = () => {
  const dispatch = useDispatch();
  const createColumnDP = (item: TDPItem): TDPColumn => {
    const column = {
      index: item.id,
      name: item?.cName || item.name,
      shortName: item.sName,
      group: item.cGroup,
      type: item.cType || '',
      default: false,
      show: item.cShow,
      enabled: item.enabled,
      roles: item.roles || [],
      order: item.order,
    };
    return column;
  };

  const findAllElementsByIdNotMinusOne = (arr: TDPItem[]): TDPColumn[] => {
    const results: TDPColumn[] = [];

    const search = (item: TDPItem) => {
      if (item.id !== -1) {
        results.push(createColumnDP(item));
      }

      if (item.id === -1 && item?.data?.length && Array.isArray(item.data)) {
        item?.data?.forEach((child) => search(child));
      }
    };

    arr?.forEach((item) => search(item));
    return results;
  };

  const cleanAndSaveFilterAndColumnsData = (data: TDataPoint[]): void => {
    const cleanFiltersData: TDataPoint[] = [];
    const columnsData: TDPColumn[] = [];
    data?.forEach((item: TDataPoint) => {
      // get filters data
      const cloneFilterItem = { ...item };
      cloneFilterItem.items = cloneFilterItem?.items?.filter((i) => i.fShow && !['CEO', 'Zip Code'].includes(i.sName));
      cleanFiltersData.push(cloneFilterItem);
      // get columns data
      columnsData.push(...findAllElementsByIdNotMinusOne(item?.items));
    });
    dispatch(saveFiltersStateActions({ data: cleanFiltersData }));
    dispatch(saveInitialFiltersStateActions({ data: cleanFiltersData }));
    columnsData?.forEach((item: TDPColumn) => {
      if (item.type.includes('text')) {
        REVERSE_SORTING_COLUMNS.push(item.index);
      }
    });
    dispatch(setColumnsDataAction({ data: columnsData.sort((a, b) => a.order - b.order) }));
  };

  const getBaseURL = (): void => {
    const start = Date.now();
    try {
      $api.get(GET_BASE_URL)
        .then((res) => {
          dispatch(saveBaseUrlScreenerAction({ data: res?.data?.baseUrl }));

          // speed check temporary data
          const end = Date.now();
          const speedCheck = end - start;
          fillSpeedValuesHandler('get_base_url', speedCheck);
          // eslint-disable-next-line no-console
          console.log('=> get_base_url', speedCheck);
        });
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const getSymbolsFile = (url: string): void => {
    const start = Date.now();
    try {
      $api.get(url).then((res) => {
        dispatch(setSymbolsDataAction({ data: res?.data }));

        // speed check temporary data
        const end = Date.now();
        const speedCheck = end - start;
        fillSpeedValuesHandler('get_symbol_file', speedCheck);
        // eslint-disable-next-line no-console
        console.log('=> get_symbol_file', speedCheck);
      });
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const getColumnsGroupFile = (url: string): void => {
    const start = Date.now();

    try {
      $api.get(url).then((res) => {
        dispatch(setGroupsDataAction({ data: res.data }));
        // speed check temporary data
        const end = Date.now();
        const speedCheck = end - start;
        fillSpeedValuesHandler('get_columnsGroup_file', speedCheck);
        // eslint-disable-next-line no-console
        console.log('=> get_columnsGroup_file', speedCheck);
      });
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const getDataPointsFile = (url: string): void => {
    const start = Date.now();

    try {
      $api.get(url).then((res) => {
        cleanAndSaveFilterAndColumnsData(res.data);
        // speed check temporary data
        const end = Date.now();
        const speedCheck = end - start;
        fillSpeedValuesHandler('get_dataPoints_file', speedCheck);
        // eslint-disable-next-line no-console
        console.log('=> get_dataPoints_file', speedCheck);
      });
    } catch (error) {
      throw new Error(error as string);
    }
  };

  const getDefsUrls = (urls?: TUrls): void => {
    const start = Date.now();

    if (urls && Object.keys(urls).length > 0){
      getSymbolsFile(urls?.symbols);
      getColumnsGroupFile(urls?.columnGroups);
      getDataPointsFile(urls?.dataPoints);
    } else {
      const apiEndpoint = REACT_APP_PUBLIC_LIGHT_SERVER_URL;
      try {
        $api.get(`${apiEndpoint}${DEF_URLS}`)
          .then((res) => {
            getColumnsGroupFile(res.data?.columnGroups);
            getSymbolsFile(res.data?.symbols);
            getDataPointsFile(res.data?.dataPoints);
            // speed check temporary data
            const end = Date.now();
            const speedCheck = end - start;
            fillSpeedValuesHandler('get_definition_urls', speedCheck);
            // eslint-disable-next-line no-console
            console.log('=> get_definition_urls', speedCheck);
          });
      } catch (error) {
        throw new Error(error as string);
      }
    }
  };

  const runUpdate = (res: TUrls) => {
    getDefsUrls(res);
  };

  const socketInstanceCallback = (socketService: SocketConnectionService) => {
    if (socketService?.getSocket()?.readyState === WebSocket.OPEN) {
      // eslint-disable-next-line no-console
      console.log('subscribe to definitions ===>');
      socketService.messageEmitter.on(WS_CHANNELS.DEFINITIONS_SEND_UPDATE, runUpdate);
    }
  };

  return {
    getBaseURL,
    getSymbolsFile,
    getColumnsGroupFile,
    getDefsUrls,
    getDataPointsFile,
    socketInstanceCallback
  };
};

export default useDefFiles;
