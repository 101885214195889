import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/ScreenerTable.module.scss';
import HeaderItemTooltip from './components/HeaderItemTooltip';

const ScreenerTableHeaderItem = ({
  column,
  isSorted,
  sortPosition
}) => (
  <div className={styles.sortWraper}>
    {isSorted && <span className={styles.sortIndicator}>{sortPosition}</span>}
    <HeaderItemTooltip isSorted={isSorted} column={column} />
  </div>
);

ScreenerTableHeaderItem.propTypes = {
  sortPosition: PropTypes.number,
  column: PropTypes.objectOf(
    PropTypes.oneOfType(
      [PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]
    )
  ).isRequired,
  isSorted: PropTypes.bool.isRequired,
};

ScreenerTableHeaderItem.defaultProps = {
  sortPosition: 0
};

export default React.memo(ScreenerTableHeaderItem);
