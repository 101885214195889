import { StudyPlotType } from "../../../charting_library/charting_library";

const plots = [
  {
    id: 'rs_line',
    type: 'line' as StudyPlotType.Line
  },
  {
    id: 'rs_line_colorer',
    palette: 'rs_line_palette',
    target: 'rs_line',
    type: 'colorer' as StudyPlotType.Colorer,
  },
  {
    id: 'rs_ma_line',
    type: 'line' as StudyPlotType.Line
  },
  {
    id: 'rs_ma_line2',
    type: 'line' as StudyPlotType.Line
  },
  {
    id: 'rs_rsma_area_plot',
    palette: 'rs_rsma_area_palette',
    target: 'rs_rsma_filled_area',
    type: 'colorer' as StudyPlotType.Colorer,
  },
  {
    id: 'ma_crossover_up1',
    type: 'shapes' as StudyPlotType.Shapes,
  },
  {
    id: 'ma_crossover_down1',
    type: 'shapes' as StudyPlotType.Shapes,
  },
  {
    id: 'ma_crossover_up2',
    type: 'shapes' as StudyPlotType.Shapes,
  },
  {
    id: 'ma_crossover_down2',
    type: 'shapes' as StudyPlotType.Shapes,
  },
  {
    id: 'pink_dot',
    type: 'shapes' as StudyPlotType.Shapes,
  },
  {
    id: 'green_dot',
    type: 'shapes' as StudyPlotType.Shapes,
  },
  {
    id: 'rs_open',
    target: 'rs_candlesticks',
    type: 'ohlc_open' as StudyPlotType.OhlcOpen
  },
  {
    id: 'rs_high',
    target: 'rs_candlesticks',
    type: 'ohlc_high' as StudyPlotType.OhlcHigh
  },
  {
    id: 'rs_low',
    target: 'rs_candlesticks',
    type: 'ohlc_low' as StudyPlotType.OhlcLow
  },
  {
    id: 'rs_close',
    target: 'rs_candlesticks',
    type: 'ohlc_close' as StudyPlotType.OhlcClose
  },
  {
    id: 'rs_candlesticks_colorer',
    palette: 'rs_candlesticks_palette',
    target: 'rs_candlesticks',
    type: 'ohlc_colorer' as StudyPlotType.OhlcColorer,
  },
];

export default plots;
