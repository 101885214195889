import React from 'react';

import styles from '../sass/FilterScreen.module.scss';
import FilterSearch from './FilterSearch';
import FilterGroupList from './FilterGroupList';
import { TDefineColumn, TFilterGroupe } from '../Types';

interface IFilterContainer {
  addFavorite: () => void;
  favoriteData: number[];
  isVisibleFilters: boolean;
  stateFiltersHandler: (filter: (number | number[])[]) => void;
  disabled: boolean;
  columnsList: TDefineColumn[];
  noFiltersAvailable: string;
  initialFilterList: TFilterGroupe[];
  searchFilter: string;
  adminUser: boolean;
  handlerSearchFilter: (id: number, type: string | undefined, columnId: number, list: TFilterGroupe[]) => void
}

const FilterContainer = ({
  addFavorite,
  favoriteData,
  isVisibleFilters,
  stateFiltersHandler,
  disabled,
  columnsList,
  noFiltersAvailable,
  initialFilterList,
  searchFilter,
  adminUser,
  handlerSearchFilter
}: IFilterContainer): React.ReactElement => (
  <div className={styles.filterContainer}>
    <FilterSearch
      handlerSearchFilter={handlerSearchFilter}
      filterList={initialFilterList}
      adminUser={adminUser}
      isVisibleFilters={isVisibleFilters}
    />
    <div className={styles.filter_list} style={disabled ? { paddingBottom: '100px' } : { }} id="scrollFilter">
      <FilterGroupList
        disabled={disabled}
        adminUser={adminUser}
        columnsList={columnsList}
        addFavorite={addFavorite}
        favoriteData={favoriteData}
        searchFilter={searchFilter}
        isVisibleFilters={isVisibleFilters}
        initialFilterList={initialFilterList}
        noFiltersAvailable={noFiltersAvailable}
        stateFiltersHandler={stateFiltersHandler}
      />
    </div>
  </div>
);

export default FilterContainer;
