interface ScrollRequestsProcessor {
  range: [number, number];
  lastPushTime: number;
  intervalId: NodeJS.Timeout | null;
  requestInterval: number;
  requestCb: (range: [number, number], startIndex: number) => void;
  startIndex: number;
}

class ScrollRequestsProcessor implements ScrollRequestsProcessor{
  range: [number, number];

  lastPushTime: number;

  intervalId: NodeJS.Timeout | null;

  requestInterval: number;

  constructor() {
    this.range = [0, 200];
    this.lastPushTime = 0;
    this.intervalId = null;
    this.requestInterval = 200;
    this.requestCb = () => {};
    this.startIndex = 0;
  }

  setRange(element: [number, number], startIndex: number, cb: () => void): void {
    this.range = element;
    this.requestCb = cb;
    this.startIndex = startIndex;
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.processRequest();
      }, this.requestInterval as number);
    }

    this.lastPushTime = Date.now();
  }

  processRequest(): void {
    if (Date.now() - this.lastPushTime >= this.requestInterval) {
      this.requestCb(this.range, this.startIndex);

      clearInterval(this.intervalId as NodeJS.Timeout);
      this.intervalId = null;
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export const scrollProcessor = new ScrollRequestsProcessor();
