class MarksDataProcessor {
  constructor() {
    this.marksData = {};
    this.statsChartData = {};
  }

  cleanData() {
    this.marksData = {};
    this.statsChartData = {};
  }

  setMarksDataHandler(data, symbolIndex) {
    if (!this.marksData[symbolIndex]) {
      const marks = data?.length ? data.filter((item) => item?.source !== 'E') : [];
      this.marksData[symbolIndex] = marks.map((item, index) => ({ ...item, id: index }));
    }
  }

  setStatsDataHandler(dataQ, dataY, symbolIndex) {
    if (!this.statsChartData[symbolIndex]) {
      this.statsChartData[symbolIndex] = {
        quarters: [],
        years: []
      };

      this.statsChartData[symbolIndex].quarters = dataQ?.length ? dataQ.filter(
        (item) => item?.eps !== undefined && item?.sales !== undefined
      ) : [];

      this.statsChartData[symbolIndex].years = dataY?.length ? dataY.filter(
        (item) => item?.eps !== undefined && item?.sales !== undefined
      ) : [];
    }
  }

  removeMarkFromCache(symbolIndex) {
    if (this.marksData[symbolIndex]) {
      delete this.marksData[symbolIndex];
    }
  }

  removeStatsFromCache(symbolIndex) {
    if (this.statsChartData[symbolIndex]) {
      delete this.statsChartData[symbolIndex];
    }
  }

  getMarksByRangeHandler(from, to, symbolIndex) {
    if (this.marksData[symbolIndex] && this.marksData[symbolIndex].length) {
      return this.marksData[symbolIndex].filter((item) => item.fiscalDate >= from && item.fiscalDate <= to);
    }

    return [];
  }
}

// eslint-disable-next-line import/prefer-default-export
export const marksDataProcessor = new MarksDataProcessor();
