import React, {
  useEffect,
  useLayoutEffect,
  ReactNode,
  ReactElement
} from 'react';

import useFilesLoaded from '../../utils/hooks/useFilesLoaded';
import useDefFiles from '../../utils/hooks/useDefFiles';
import useHolidays from '../../utils/hooks/useHolidays';
import useAccount from '../../utils/hooks/useAccount';
import { GET_DEFS_URLS_TIMEOUT } from '../../constants/screener';
import { getFromLocalStorage } from '../../utils/storageWorks';
import SocketProvider from '../../context/SocketProvider';
import useAlerts from '../../components/ChartContainer/hooks/useAlerts';

interface IWidgetLayout {
  children: ReactNode
}

const WidgetLayout = ({ children }: IWidgetLayout): ReactElement => {
  const { filesLoaded } = useFilesLoaded(true);

  const {
    getBaseURL,
    getDefsUrls,
    socketInstanceCallback
  } = useDefFiles();

  const { getHolidays } = useHolidays();

  const { getAlertSettings } = useAlerts();

  const { profileWorker, userStateWorker, getUserLayouts } = useAccount();

  useLayoutEffect(() => {
    getBaseURL();
  }, []);

  useEffect(() => {
    getHolidays();
  }, []);

  useLayoutEffect(() => {
    userStateWorker();
    profileWorker();
    getDefsUrls();
    getUserLayouts();
    getAlertSettings();

    const interval = setInterval(() => getDefsUrls(), GET_DEFS_URLS_TIMEOUT);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {filesLoaded && getFromLocalStorage('isWebView') && (
      <SocketProvider socketInstanceCallback={socketInstanceCallback}>{children}</SocketProvider>)}
    </div>
  );
};

export default WidgetLayout;
