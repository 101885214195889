import React from 'react';
import PropTypes from 'prop-types';
import { MODAL_TITLES } from '../../constants/screener';
import constants from '../../constants/filters';
import DuplicateModal from './DuplicateModal';

const DuplicateModalHandler = ({
  openModal,
  closeModalHandler,
  duplicatePreset,
  currentPresetItem,
  itemsColumnSets,
  currentColumnSetItem,
}) => (
  <>
    {openModal === MODAL_TITLES.DUPLICATE && (
      <DuplicateModal
        type={constants.screener}
        setOpenModal={closeModalHandler}
        duplicatePreset={duplicatePreset}
        currentPresetItem={currentPresetItem}
      />
    )}
    {openModal === MODAL_TITLES.DUPLICATE_COLUMN && (
      <DuplicateModal
        data={itemsColumnSets}
        type={constants.columns}
        setOpenModal={closeModalHandler}
        duplicatePreset={duplicatePreset}
        currentPresetItem={currentColumnSetItem}
      />
    )}
  </>
);

DuplicateModalHandler.propTypes = {
  currentPresetItem: PropTypes.shape({}),
  currentColumnSetItem: PropTypes.shape({}),
  openModal: PropTypes.string.isRequired,
  closeModalHandler: PropTypes.func,
  duplicatePreset: PropTypes.func,
  itemsColumnSets: PropTypes.arrayOf(PropTypes.shape({})),
};

DuplicateModalHandler.defaultProps = {
  currentPresetItem: {},
  currentColumnSetItem: {},
  closeModalHandler: () => undefined,
  duplicatePreset: () => undefined,
  itemsColumnSets: [],
};

export default React.memo(DuplicateModalHandler);
