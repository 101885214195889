import { Events } from '../types/EducationComponentsTypes';

function sortEventsByDate(data: Events): Events {
  const sortedEvents = data?.events?.sort((a, b) => new Date(a.start_date).getTime() - new Date(b.start_date)
    .getTime());

  return {
    ...data,
    events: sortedEvents ?? [],
  };
}

export default sortEventsByDate;
