import { useEffect, useState } from 'react';
import { workWithInputToolTipSetValue } from '../../../utils/helperFilter';

const useSeekBar = (
  maxValue, minValue, setArray, viewRange, initialMax,
  initialMin, columnId, typeData, activeTab, handleTab,
) => {
  const [touched, setTouched] = useState(false);
  const [min, setMin] = useState(minValue);
  const [max, setMax] = useState(maxValue);
  const [minInputValue, setMinInputValue] = useState(minValue);
  const [maxInputValue, setMaxInputValue] = useState(maxValue);

  const controlButton = () => {
    if (+maxInputValue <= +minInputValue && maxInputValue !== null) {
      setTouched(false);
    } else if (maxInputValue === null
      || minInputValue === null
    ) {
      setTouched(true);
    } else {
      setTouched(true);
    }
  };

  const setGreaterInit = () => {
    setMinInputValue(minValue <= initialMin ? initialMin : minValue);
    setMin(minValue <= initialMin ? initialMin : minValue);

    setMaxInputValue(initialMax);
    setMax(initialMax);
  };

  const setLessInit = () => {
    setMaxInputValue(maxValue >= initialMax ? initialMax : maxValue);
    setMax(maxValue >= initialMax ? initialMax : maxValue);

    setMinInputValue(initialMin);
    setMin(initialMin);
  };

  const setRangeInit = () => {
    setMin(minValue);
    setMax(maxValue);
    setMinInputValue(minValue);
    setMaxInputValue(maxValue);
  };

  const setInitialData = () => {
    setMinInputValue(initialMin);
    setMaxInputValue(initialMax);
    setMin(initialMin);
    setMax(initialMax);
  };

  useEffect(() => {
    setMinInputValue(initialMin);
    setMaxInputValue(initialMax);
  }, [initialMax, initialMin]);

  const setValidData = () => {
    if (handleTab && !activeTab.less && !activeTab.greater) {
      return setInitialData();
    }
    if (activeTab.greater) {
      return setGreaterInit();
    }
    if (activeTab.less) {
      return setLessInit();
    }
    return setRangeInit();
  };

  const setMinValueHandler = (value) => {
    setMinInputValue(workWithInputToolTipSetValue(value, typeData));
    controlButton();
  };

  const setMinPosition = () => {
    if (+minInputValue > +maxInputValue && maxInputValue !== null) {
      setMin(+maxInputValue);
    } else if (+minInputValue < initialMin || minInputValue === '') {
      setMin(initialMin);
    } else {
      setMin(+minInputValue);
    }

    if (+minInputValue > +maxInputValue && maxInputValue !== null) {
      setMinInputValue(+maxInputValue);
    } else if (+minInputValue < initialMin || minInputValue === '') {
      setMinInputValue(initialMin);
    }
  };

  const setMaxValueHandler = (value) => {
    setMaxInputValue(workWithInputToolTipSetValue(value, typeData));
    controlButton();
  };

  const setMaxPosition = () => {
    if (+maxInputValue < +minInputValue) {
      setMax(+minInputValue);
    } else if (+maxInputValue > initialMax || maxInputValue === '') {
      setMax(initialMax);
    } else {
      setMax(maxInputValue);
    }

    if (+maxInputValue < +minInputValue) {
      setMaxInputValue(minInputValue);
    } else if (+maxInputValue > initialMax || maxInputValue === '') {
      setMaxInputValue(`${initialMax}`);
    }
  };

  const addHandler = () => {
    const minValueBody = min > +minInputValue ? min : +minInputValue;
    const maxValueBody = max < +maxInputValue ? max : +maxInputValue;
    const valueStart = activeTab.less ? null : minValueBody;
    const valueEnd = activeTab.greater ? null : maxValueBody;
    const filter = [columnId, 1, [valueStart, valueEnd]];
    setArray(filter);
  };

  useEffect(() => {
    setValidData();
  }, [minValue, maxValue]);

  useEffect(() => {
    setValidData();
  }, []);

  useEffect(() => {
    setTouched(false);
  }, [viewRange]);

  useEffect(() => {
    setValidData();
  }, [activeTab]);

  return {
    minInputValue,
    maxInputValue,
    touched,
    addHandler,
    setMaxPosition,
    setMaxValueHandler,
    setMinPosition,
    setMinValueHandler
  };
};

export default useSeekBar;
