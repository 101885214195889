import React from 'react';
import styles from './sass/InputField.module.scss';

type InputFieldProps = {
  onChange: (e: React.ChangeEvent) => void
  placeholder?: string,
  name?: string
  value?: string | number
  type?: string
  disable?: boolean
  maxlength?: number
  styleType?: string
  requiredInput?: boolean
  autocomplete?: string
  id?: string
  autofocus?: boolean
  onBlur: () => void
  handleKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void
};

const InputField = ({
  onChange,
  placeholder = '',
  name = '',
  value = '',
  type = 'text',
  disable,
  maxlength = 256,
  styleType = '',
  requiredInput,
  autocomplete,
  id = '',
  autofocus,
  onBlur,
  handleKeyPress
}: InputFieldProps): JSX.Element => (
  <input
    className={`${styles.input} ${styleType && styles[styleType]}`}
    placeholder={placeholder}
    name={name}
    onChange={onChange}
    value={value}
    type={type}
    disabled={disable}
    maxLength={maxlength}
    required={requiredInput}
    autoComplete={autocomplete}
    id={id}
    // eslint-disable-next-line jsx-a11y/no-autofocus
    autoFocus={autofocus}
    onBlur={onBlur}
    onKeyDown={handleKeyPress}
  />
);

export default InputField;
