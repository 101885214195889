import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Icon.module.scss';

const areEqual = (prevProps, nextProps) => {
  return prevProps.iconClass === nextProps.iconClass
    && prevProps.icon === nextProps.icon
    && prevProps.handleClick === nextProps.handleClick
    && prevProps.styleIcon === nextProps.styleIcon;
};

const Icon = ({
  icon,
  iconClass,
  handleClick,
  styleIcon,
  setDefaultClass,
  componentStyle
}) => {
  return (
    <img
      src={icon}
      className={`${setDefaultClass ? styles.Icon : ''} ${iconClass} ${styleIcon ? styles[styleIcon] : ''}`}
      onClick={handleClick}
      alt=""
      aria-hidden
      style={componentStyle}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconClass: PropTypes.string,
  handleClick: PropTypes.func,
  styleIcon: PropTypes.string,
  setDefaultClass: PropTypes.bool,
  componentStyle: PropTypes.shape({}),
};

Icon.defaultProps = {
  iconClass: '',
  styleIcon: '',
  setDefaultClass: true,
  handleClick: () => undefined,
  componentStyle: {}
};

export default React.memo(Icon, areEqual);
