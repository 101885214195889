import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import styles from '../sass/AlertsList.module.scss';
import Icon from '../../Icon/Icon';
import { ALERTS_TOAST_SETTING } from '../../../constants/screener';
import SwitchButton from '../../SwitchButton/SwitchButton';

const ToastHeader = ({
  icon, sym, percent, title, time, handleToggleAlertStatus, isActive
}) => (
  <div className={styles.toast_header}>
    <Icon
      icon={icon}
    />
    <div className={styles.info}>
      <p>
        <span className={styles.symbol}>{`${sym} `}</span>
        <span className={styles.title}>{title}</span>
      </p>
      <p>
        <span
          className={`${styles.titlePercent} 
          ${+percent > ALERTS_TOAST_SETTING.PERCENT_RULE ? styles.grow : styles.loss}`}
        >
          {`${+percent > ALERTS_TOAST_SETTING.PERCENT_RULE ? '+' : ''}`}
          {`${(+percent * ALERTS_TOAST_SETTING.MULTIPLY).toFixed(ALERTS_TOAST_SETTING.TO_FIXED)}% `}
        </span>
      </p>
      <p className={styles.time}>
        {moment(time).format('MMM DD, yyyy, hh:mm a')}
      </p>
    </div>
    <div aria-hidden onClick={(e) => e.stopPropagation()} className={styles.switchButton}>
      <SwitchButton
        handleDiameter={12} 
        width={32}
        height={16}
        checked={isActive}
        onChange={handleToggleAlertStatus}
      />
    </div>
  </div>
);

ToastHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  sym: PropTypes.string.isRequired,
  percent: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  handleToggleAlertStatus: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};
export default ToastHeader;
