import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { MODAL_TITLES } from '../../constants/screener';
import MoveToModal from './MoveToModal';

const MoveToModalHandler = ({
  openModal,
  itemsNewScreen,
  presetsNewScreen,
  itemsColumnSets,
  moveToFolder,
  currentItem,
  currentPresetItem,
  currentSubItem,
  currentColumnSetItem,
  closeModalHandler,
}) => (
  <>
    {openModal === MODAL_TITLES.MOVE_TO && (
      <MoveToModal
        data={itemsNewScreen}
        moveToFolder={moveToFolder}
        currentItemData={currentItem}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
      />
    )}
    {openModal === MODAL_TITLES.PRESET_MOVE_TO && (
      <MoveToModal
        data={presetsNewScreen}
        moveToFolder={moveToFolder}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        currentItemData={currentPresetItem}
      />
    )}
    {openModal === MODAL_TITLES.MOVE_COLUMN_TO && (
      <MoveToModal
        data={itemsColumnSets}
        moveToFolder={moveToFolder}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        currentItemData={currentColumnSetItem}
      />
    )}
  </>
);

MoveToModalHandler.propTypes = {
  itemsNewScreen: PropTypes.arrayOf(PropTypes.shape({})),
  presetsNewScreen: PropTypes.arrayOf(PropTypes.shape({})),
  itemsColumnSets: PropTypes.arrayOf(PropTypes.shape({})),
  removeColumnsSet: PropTypes.shape({}),
  currentColumnSetItem: PropTypes.shape({}),
  currentFolderSetsItem: PropTypes.shape({}),
  currentFolderPresetItem: PropTypes.shape({}),
  openModal: PropTypes.string.isRequired,
  closeModalHandler: PropTypes.func,
  moveToFolder: PropTypes.func,
  currentSubItem: oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  currentItem: PropTypes.shape({}),
  currentPresetItem: PropTypes.shape({}),
};

MoveToModalHandler.defaultProps = {
  currentColumnSetItem: {},
  removeColumnsSet: {},
  itemsNewScreen: {},
  presetsNewScreen: {},
  itemsColumnSets: {},
  currentFolderSetsItem: {},
  currentFolderPresetItem: {},
  closeModalHandler: () => undefined,
  moveToFolder: () => undefined,
  currentItem: {},
  currentPresetItem: {},
  currentSubItem: {} || PropTypes.string,
};

export default React.memo(MoveToModalHandler);
