import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import $api from '../../../http';
import useWatchList from '../../../utils/hooks/useWatchList';
import { DASHBOARD_ITEMS, LINK_TO_WATCHLIST, UNLINK_FROM_WATCHLIST } from '../../../constants/paths';
import { STATUS_200 } from '../../../constants/responseStatuses';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { errorMessageAction } from '../../../store/account/actions';
import { DasboardsItemsDropdownTypes } from '../types/constants';
import { setPresetsNewScreen, setItemsNewScreen } from '../../../store/newScreen/actions';
import RootStateTypes from '../../../store/RootStateTypes';
import { ScreenItemData } from '../../../utils/Types';

interface IFavoriteHooks {
  (): {
    linkToWatchlist: (id: number, title: string, type: DasboardsItemsDropdownTypes) => void,
    unLinkFromWatchlist: (id: number, title: string, type: DasboardsItemsDropdownTypes) => void
  };
}

const useFavoriteHooks: IFavoriteHooks = () => {
  const { t } = useTranslation();

  const { getWatchLists } = useWatchList();

  const updateState = () => {
    getWatchLists();
  };
  const dispatch = useDispatch();

  const itemsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.itemsNewScreen, shallowEqual);
  const presetsNewScreen = useSelector((state: RootStateTypes) => state.newScreenState.presetsNewScreen, shallowEqual);

  const successHandler = (message: string, title: string) => {
    dispatch(popUpAction(true));
    dispatch(errorMessageTitleAction({ messageTitle: title }));
    dispatch(messageAction({ message }));
    dispatch(changeNotificationTypeAction({ type: t('successType') }));
  };

  const updateFavorite = (id: number, fav: boolean, type: DasboardsItemsDropdownTypes): void => {
    if (type === DasboardsItemsDropdownTypes.SAVED) {
      const newItems = itemsNewScreen.map((item: ScreenItemData) => {
        if (item?.items?.length) {
          const newSubItems = item.items.map((subItem: ScreenItemData) => {
            if (subItem.id === id) {
              return { ...subItem, isFavorite: fav };
            }
            return subItem;
          });
          return { ...item, items: newSubItems };
        }
        if (item.id === id) {
          return { ...item, isFavorite: fav };
        }
        return item;
      });
      dispatch(setItemsNewScreen(newItems));
    }
    if (type === DasboardsItemsDropdownTypes.PRESET) {
      const newPresets = presetsNewScreen.map((item: ScreenItemData) => {
        if (item?.items?.length) {
          const newSubPresets = item.items.map((subPreset: ScreenItemData) => {
            if (subPreset.id === id) {
              return { ...subPreset, isFavorite: fav };
            }
            return subPreset;
          });
          return { ...item, items: newSubPresets };
        }
        if (item.id === id) {
          return { ...item, isFavorite: fav };
        }
        return item;
      });
      dispatch(setPresetsNewScreen(newPresets));
    }
  };

  const linkToWatchlist = (id: number, title: string, type: DasboardsItemsDropdownTypes): void => {
    updateFavorite(id, true, type);
    $api.post(
      `${DASHBOARD_ITEMS}${LINK_TO_WATCHLIST}/${id}`
    ).then((res) => {
      if (res.status === STATUS_200) {
        successHandler(title, t('watchlistSuccessLinked'));
      }
      updateState();
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e?.message }));
    });
  };

  const unLinkFromWatchlist = (id: number, title: string, type: DasboardsItemsDropdownTypes): void => {
    updateFavorite(id, false, type);
    $api.delete(
      `${DASHBOARD_ITEMS}${UNLINK_FROM_WATCHLIST}/${id}`
    ).then((res) => {
      if (res.status === STATUS_200) {
        successHandler(title, t('watchlistSuccessUnlinked'));
      }
      updateState();
    }).catch((e) => {
      dispatch(errorMessageAction({ errorMessage: e?.message }));
    });
  };

  return {
    linkToWatchlist,
    unLinkFromWatchlist
  };
};
export default useFavoriteHooks;
