import React from 'react';

import styles from './sass/FilterGroupEmaSma.module.scss';
import SelectGroupFilter from '../SelectGroupFilter/SelectGroupFilter';
import Checkbox from '../Checkbox/Checkbox';
import SelectGroupDropFilter from '../SelectGroupDropFilter/SelectGroupDropFilter';
import { TCommonItem } from '../FilterScreen/Types';

interface IGroupCheckboxSelector {
  activeCheckbox: boolean;
  filter: TCommonItem[];
  listCheckBox: Record<number, boolean>;
  setCheckHandler: (id: number) => void;
  deepGroupDrop: boolean;
  handlerSelectFilter: (filterItem: TCommonItem) => void;
  setViewDropList: (value: boolean) => void;
  viewDropList: boolean;
  viewDropListSingle: boolean;
  setViewDropListSingle: (value: boolean) => void;
  selectFilter: TCommonItem;
  groupDeepSecond: boolean;
  selectedGroup: TCommonItem;
  handlerSelectGroupFilter: (group: TCommonItem) => void;
  bottomFlag: boolean;
}

const GroupCheckboxSelector = ({
  activeCheckbox, filter, listCheckBox, setCheckHandler, deepGroupDrop,
  handlerSelectFilter, setViewDropList, viewDropList, viewDropListSingle,
  setViewDropListSingle, selectFilter, groupDeepSecond, selectedGroup,
  handlerSelectGroupFilter, bottomFlag
}: IGroupCheckboxSelector): React.ReactElement => (
  <div className={styles.wrapperDrop}>
    {!activeCheckbox && !deepGroupDrop && (
      <SelectGroupFilter
        bottomFlag={bottomFlag}
        data={filter.filter((el) => el.fShow)}
        selectFilter={groupDeepSecond ? selectedGroup : selectFilter}
        handlerSelectFilter={groupDeepSecond ? handlerSelectGroupFilter : handlerSelectFilter}
        viewDropListSingle={viewDropListSingle}
        setViewDropListSingle={setViewDropListSingle}
      />
    )}
    {!activeCheckbox && deepGroupDrop && (
      <SelectGroupDropFilter
        data={filter.filter((el) => el.fShow)}
        handlerSelectFilter={handlerSelectFilter}
        viewDropList={viewDropList}
        setViewDropList={setViewDropList}
        selectFilter={selectFilter}
      />
    )}
    {activeCheckbox && (
    <>
      {filter && filter?.map((item, index) => (item.fShow && (
        <Checkbox
          key={[item?.sName, index].join('_')}
          id={[item?.sName, item?.id].join('_')}
          additionalClass="checkboxLabel"
          name={item?.name}
          checked={listCheckBox[item?.id]}
          styleInput="checkboxFilters"
          changeHandler={() => setCheckHandler(item?.id)}
          textStyle="checkboxText"
          title={item?.name}
        />
      )))}
    </>
    )}
  </div>
);

export default GroupCheckboxSelector;
