import React from 'react';

import styles from '../sass/FilterScreen.module.scss';
import NoFiltersOr from './NoFiltersOr';
import FilterSelectList from './FilterSelectList';
import ChunkButton from '../../ChunkButton/ChunkButton';
import { TFilterGroupe } from '../Types';

interface IFilterListOrContainer {
  selectedItems: TFilterGroupe;
  title: string;
  clearItemFilter: (id: number, activeGroupIndex: number, checkMap: number) => void;
  textTooltip: string;
  active: boolean;
  handlerGroup: (id: number) => void;
  id: number;
  removeGroup: (id: number) => void;
  activeGroupIndex: number;
  groupType: string;
  handlerSearchFilter: (id: number, type: string, columnId: number, list: TFilterGroupe[]) => void;
  selectedGroupFilters: TFilterGroupe[];
  hideButton: boolean;
}

const FilterListOrContainer = ({
  selectedItems, title, clearItemFilter, textTooltip,
  active, handlerGroup, id, removeGroup,
  activeGroupIndex, groupType, handlerSearchFilter,
  selectedGroupFilters, hideButton
}: IFilterListOrContainer): React.ReactElement => (
  <div className={styles.containerListOr}>
    <ChunkButton clickButton={removeGroup} id={id} />
    {selectedGroupFilters[id]?.items?.length > 0 ? (
      <FilterSelectList
        id={id}
        title={title}
        active={active}
        groupType={groupType}
        hideButton={hideButton}
        textTooltip={textTooltip}
        handlerGroup={handlerGroup}
        selectedItems={selectedItems}
        clearItemFilter={clearItemFilter}
        activeGroupIndex={activeGroupIndex}
        handlerSearchFilter={handlerSearchFilter}
      />
    ) : (
      <NoFiltersOr
        id={id}
        active={active}
        textTooltip={textTooltip}
        handlerActive={handlerGroup}
      />
    )}

  </div>
);

export default FilterListOrContainer;
