import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { MODAL_TITLES } from '../../constants/screener';
import CreateScreenModal from './CreateScreenModal';
import constants from '../../constants/filters';
import { DasboardsItemsDropdownTypes } from '../DashboardsItemsDropdown/types/constants';
import { DialogItemDataTypes } from '../DashboardItemsDialog/types/constants';

const CreateScreenModalHandler = ({
  openModal,
  closeModalHandler,
  selectedScreenId,
  itemsNewScreen,
  foldersPresets,
  groupFilters,
  selectedColumns,
  columnSetFolder,
  moveToFolder,
  createScreen,
  createPresets,
  createColumnsSet,
  resetSearchHandler,
  currentItemSelected
}) => {
  const itemsDeeplist = useSelector((state) => state.watchlistState.itemsWatchlist, shallowEqual);
  const presetsDeeplist = useSelector((state) => state.watchlistState.presetsWatchlist, shallowEqual);
  const dataPanelsItems = useSelector((state) => state.newScreenState.dataPanelsItems, shallowEqual);
  const dataPanelsItemsPresets = useSelector((state) => state.newScreenState.dataPanelsItemsPresets, shallowEqual);
  const itemsDashboard = useSelector((state) => state.dashboardsState.itemsDashboard, shallowEqual);
  const itemsDashboardPresets = useSelector((state) => state.dashboardsState.itemsDashboardPresets, shallowEqual);
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);
  const sharedDashboardItem = useSelector((state) => state.screenerState.sharedDashboardItem, shallowEqual);
  const itemsColumnSetsPreset = useSelector((state) => state.newScreenState.itemsColumnSetsPreset, shallowEqual);
  const itemsColumnSets = useSelector((state) => state.newScreenState.itemsColumnSets, shallowEqual);

  const getSavedItemDataType = (type) => {
    switch (type) {
      case constants.screener: return DialogItemDataTypes.SCREENER;
      case constants.deepList: return DialogItemDataTypes.DEEP_LIST;
      case constants.dataPanel: return DialogItemDataTypes.DATA_PANEL;
      case constants.userDashboard: return DialogItemDataTypes.DASHBOARD;
      case constants.columns: return DialogItemDataTypes.COLUMN_SET;
      default: return DialogItemDataTypes.SCREENER;
    }
  };

  const getSavedItemItems = (type) => {
    switch (type) {
      case constants.deepList: return itemsDeeplist;
      case constants.screener: return itemsNewScreen;
      case constants.dataPanel: return dataPanelsItems;
      case constants.userDashboard: return itemsDashboard;
      case constants.columns: return itemsColumnSets;
      default: return itemsNewScreen;
    }
  };
  return (
    <>
      {openModal === MODAL_TITLES.CREATE_SCREEN && (
      <CreateScreenModal
        type={constants.screener}
        groupFilters={groupFilters}
        createScreen={createScreen}
        moveToFolder={moveToFolder}
        itemsNewScreen={itemsNewScreen}
        setOpenModal={closeModalHandler}
        selectedScreenId={selectedScreenId}
        selectedTab={DasboardsItemsDropdownTypes.SAVED}
        dataType={DialogItemDataTypes.SCREENER}
        resetSearchHandler={resetSearchHandler}
        currentItemSelected={currentItemSelected}
      />
      )}
      {openModal === MODAL_TITLES.CREATE_DEEPLIST && (
      <CreateScreenModal
        type={constants.deepList}
        groupFilters={groupFilters}
        createScreen={createScreen}
        moveToFolder={moveToFolder}
        itemsNewScreen={itemsDeeplist}
        setOpenModal={closeModalHandler}
        selectedScreenId={selectedScreenId}
        selectedTab={DasboardsItemsDropdownTypes.SAVED}
        dataType={DialogItemDataTypes.DEEP_LIST}
        resetSearchHandler={resetSearchHandler}
        currentItemSelected={currentItemSelected}
      />
      )}
      {openModal === MODAL_TITLES.CREATE_DATA_PANEL && (
        <CreateScreenModal
          type={constants.dataPanel}
          groupFilters={groupFilters}
          createScreen={createScreen}
          moveToFolder={moveToFolder}
          itemsNewScreen={dataPanelsItems}
          setOpenModal={closeModalHandler}
          selectedScreenId={selectedScreenId}
          selectedTab={DasboardsItemsDropdownTypes.SAVED}
          dataType={DialogItemDataTypes.DATA_PANEL}
          resetSearchHandler={resetSearchHandler}
          currentItemSelected={currentItemSelected}
        />
      )}
      {(openModal === MODAL_TITLES.CREATE_DATA_PANEL_PRESET) && (
        <CreateScreenModal
          type={constants.dataPanelPresets}
          groupFilters={groupFilters}
          createScreen={createScreen}
          moveToFolder={moveToFolder}
          itemsNewScreen={dataPanelsItemsPresets}
          setOpenModal={closeModalHandler}
          selectedScreenId={selectedScreenId}
          selectedTab={DasboardsItemsDropdownTypes.PRESET}
          dataType={DialogItemDataTypes.DATA_PANEL}
          resetSearchHandler={resetSearchHandler}
          currentItemSelected={currentItemSelected}
        />
      )}
      {openModal === MODAL_TITLES.CREATE && (
      <CreateScreenModal
        type={constants.deepList}
        groupFilters={groupFilters}
        createScreen={createScreen}
        moveToFolder={moveToFolder}
        itemsNewScreen={itemsDeeplist}
        setOpenModal={closeModalHandler}
        selectedScreenId={selectedScreenId}
        selectedTab={DasboardsItemsDropdownTypes.SAVED}
        dataType={DialogItemDataTypes.DEEP_LIST}
        resetSearchHandler={resetSearchHandler}
        currentItemSelected={currentItemSelected}
        isShownBool
      />
      )}
      {openModal === MODAL_TITLES.CREATE_DEEPLIST_PRESET && (
        <CreateScreenModal
          type={constants.presetDeeplist}
          groupFilters={groupFilters}
          createScreen={createScreen}
          moveToFolder={moveToFolder}
          itemsNewScreen={presetsDeeplist}
          setOpenModal={closeModalHandler}
          selectedScreenId={selectedScreenId}
          selectedTab={DasboardsItemsDropdownTypes.PRESET}
          dataType={DialogItemDataTypes.DEEP_LIST}
          resetSearchHandler={resetSearchHandler}
          currentItemSelected={currentItemSelected}
        />
      )}
      {openModal === MODAL_TITLES.CREATE_PRESET && (
      <CreateScreenModal
        type={constants.presets}
        groupFilters={groupFilters}
        moveToFolder={moveToFolder}
        createScreen={createPresets}
        itemsNewScreen={foldersPresets}
        setOpenModal={closeModalHandler}
        selectedScreenId={selectedScreenId}
        selectedTab={DasboardsItemsDropdownTypes.PRESET}
        dataType={DialogItemDataTypes.SCREENER}
        resetSearchHandler={resetSearchHandler}
        currentItemSelected={currentItemSelected}
      />
      )}
      {openModal === MODAL_TITLES.CREATE_COLUMNS_SET && (
      <CreateScreenModal
        type={constants.columns}
        moveToFolder={moveToFolder}
        createScreen={createColumnsSet}
        setOpenModal={closeModalHandler}
        itemsNewScreen={columnSetFolder}
        selectedColumns={selectedColumns}
        dataType={DialogItemDataTypes.COLUMN_SET}
        selectedTab={DasboardsItemsDropdownTypes.SAVED}
        resetSearchHandler={resetSearchHandler}
        currentItemSelected={currentItemSelected}
      />
      )}
      {openModal === MODAL_TITLES.CREATE_COLUMNS_SET_PRESET && (
        <CreateScreenModal
          type={constants.columnsPreset}
          moveToFolder={moveToFolder}
          createScreen={createColumnsSet}
          setOpenModal={closeModalHandler}
          itemsNewScreen={itemsColumnSetsPreset}
          selectedColumns={selectedColumns}
          dataType={DialogItemDataTypes.COLUMN_SET}
          selectedTab={DasboardsItemsDropdownTypes.PRESET}
          resetSearchHandler={resetSearchHandler}
          currentItemSelected={currentItemSelected}
        />
      )}
      {openModal === MODAL_TITLES.CREATE_DASHBOARD && (
        <CreateScreenModal
          type={constants.userDashboard}
          groupFilters={groupFilters}
          createScreen={createScreen}
          moveToFolder={moveToFolder}
          itemsNewScreen={itemsDashboard}
          setOpenModal={closeModalHandler}
          selectedScreenId={userSettings.selectedDashboardId}
          selectedTab={DasboardsItemsDropdownTypes.SAVED}
          dataType={DialogItemDataTypes.DASHBOARD}
          resetSearchHandler={resetSearchHandler}
          currentItemSelected={currentItemSelected}
        />
      )}
      {openModal === MODAL_TITLES.CREATE_DASHBOARD_PRESET && (
        <CreateScreenModal
          type={constants.userDashboardPresets}
          groupFilters={groupFilters}
          createScreen={createScreen}
          moveToFolder={moveToFolder}
          itemsNewScreen={itemsDashboardPresets}
          setOpenModal={closeModalHandler}
          selectedScreenId={userSettings.selectedDashboardId}
          selectedTab={DasboardsItemsDropdownTypes.PRESET}
          dataType={DialogItemDataTypes.DASHBOARD}
          resetSearchHandler={resetSearchHandler}
          currentItemSelected={currentItemSelected}
        />
      )}
      {openModal === MODAL_TITLES.CREATE_SHARED_ITEM
         && (
         <CreateScreenModal
           type={sharedDashboardItem?.type}
           groupFilters={groupFilters}
           createScreen={createScreen}
           moveToFolder={moveToFolder}
           itemsNewScreen={getSavedItemItems(sharedDashboardItem?.type)}
           setOpenModal={closeModalHandler}
           selectedScreenId={userSettings.selectedDashboardId}
           selectedTab={DasboardsItemsDropdownTypes.SAVED}
           dataType={getSavedItemDataType(sharedDashboardItem?.type)}
           resetSearchHandler={resetSearchHandler}
           currentItemSelected={currentItemSelected}
           savedItem={sharedDashboardItem}
         />
         )}
    </>
  );
};

CreateScreenModalHandler.propTypes = {
  selectedScreenId: PropTypes.number,
  openModal: PropTypes.string.isRequired,
  closeModalHandler: PropTypes.func,
  moveToFolder: PropTypes.func,
  createScreen: PropTypes.func,
  createPresets: PropTypes.func,
  createColumnsSet: PropTypes.func,
  itemsNewScreen: PropTypes.arrayOf(PropTypes.shape({})),
  groupFilters: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  columnSetFolder: PropTypes.arrayOf(PropTypes.shape({})),
  foldersPresets: PropTypes.arrayOf(PropTypes.shape({})),
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({})),
  resetSearchHandler: PropTypes.func,
  currentItemSelected: PropTypes.func,
};

CreateScreenModalHandler.defaultProps = {
  selectedScreenId: -1,
  closeModalHandler: () => undefined,
  moveToFolder: () => undefined,
  createScreen: () => undefined,
  createColumnsSet: () => undefined,
  createPresets: () => undefined,
  itemsNewScreen: [],
  groupFilters: [],
  columnSetFolder: [],
  foldersPresets: [],
  selectedColumns: [],
  resetSearchHandler: () => undefined,
  currentItemSelected: () => undefined,
};

export default React.memo(CreateScreenModalHandler);
