import React, { useEffect, useRef } from 'react';

import styles from './sass/FilterMultiInclude.module.scss';
import TextWithImage from '../TextWithImage/TextWithImage';
import FilterItem from './FilterItem';
import { TValueItem } from '../FilterScreen/Types';

interface ISelectDefault {
  label: string;
  Image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  clickSelect: () => void;
  active: boolean;
  activeMultiSelected: boolean;
  handleCheck: (id: number) => void;
  handlerCloseDropdown: () => void;
  setBottom?: (value: number) => void;
  items: TValueItem[];
}

const SelectDefault = ({
  label,
  Image,
  clickSelect,
  active,
  activeMultiSelected,
  handleCheck,
  handlerCloseDropdown,
  setBottom,
  items,
}: ISelectDefault): React.ReactElement => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (setBottom && typeof setBottom === 'function') {
      setBottom(ref?.current?.getBoundingClientRect()?.bottom as number);
    }
  }, [active, items]);

  return (
    <div
      aria-hidden="true"
      ref={ref}
      className={styles.containerWrapper}
      onClick={!activeMultiSelected ? clickSelect : undefined}
      onKeyDown={undefined}
      role="button"
      tabIndex={0}
    >
      {activeMultiSelected ? (
        <FilterItem
          arrayData={items.length > 0 && items}
          Image={Image}
          active={active}
          handleCheck={handleCheck}
          clickSelect={clickSelect}
          handlerCloseDropdown={handlerCloseDropdown}
        />
      ) : (
        <TextWithImage
          ImageSrc={Image}
          label={label}
          active={active}
          styleText="selectedFiltersLabel"
        />
      )}
    </div>
  );
};

export default SelectDefault;
