export const defaultPalettes = {
  volumeBarsPalette: {
    colors: {
      0: {
        color: 'blue',
        style: 0,
        width: 1,
      },
      1: {
        color: 'fuchsia',
        style: 0,
        width: 1,
      },
      2: {
        color: 'lime',
        style: 0,
        width: 1,
      },
      3: {
        color: 'orange',
        style: 0,
        width: 1,
      },
    },
  },
};

export const palettes = {
  volumeBarsPalette: {
    colors: {
      0: {
        name: 'Up',
      },
      1: {
        name: 'Down',
      },
      2: {
        name: 'Pocket Pivot',
      },
      3: {
        name: 'Reverse Pocket Pivot',
      },
    },
  },
};
