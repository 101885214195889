import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import TextField from '../TextField/TextField';
import Button from '../Button/Button';
import IWatchlistAddSymbolButton from './interfaces/IWatchlistAddSymbolButton';
import { setModalType, setSelectedTab } from '../../store/watchlist/actions';
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg';
import { ReactComponent as IconFilter } from '../../assets/images/icons/screener/filterWhite.svg';
import RootStateTypes from '../../store/RootStateTypes';

import styles from './sass/WatchlistAddSymbolButton.module.scss';
import { DeepListItemData } from '../../utils/Types';
import { sharedChartData } from '../ChartContainer/constants/constants';
import { MS_INTERVAL } from '../../constants/screener';

const WatchlistAddSymbolButton = ({
  menuFlag = false,
  hideAddSymbol = false,
  onlyIconButton = false,
  isPlusIcon = true,
  text = 'addNewSymbol',
  handleClick,
  isWidget,
  selectedItemFromWidget,
}: IWatchlistAddSymbolButton): ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tabsList = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);

  let textResult;

  if (menuFlag) {
    textResult = t('addNewSymbolButtonShort');
  } else if (text) {
    textResult = t(text);
  } else {
    textResult = t('addNewSymbol');
  }

  const handleShowAddModal = () => {
    dispatch(setModalType(t('addSymbol')));
    if (isWidget) {
      const currentTab = tabsList.find((tab: DeepListItemData) => tab.id === selectedItemFromWidget);
      dispatch(setSelectedTab(currentTab));
    }
  };

  const mouseEnterHandler = () => {
    sharedChartData.preventGlobalSearch = true;
  };

  const mouseLeaveHandler = () => {
    setTimeout(() => {
      sharedChartData.preventGlobalSearch = false;
    }, MS_INTERVAL.HALF_SECOND);
  };

  return (
    <>
      {!hideAddSymbol
        ? (
          <Button
            buttonClass={`${styles.addWatchlistLargeButton}
              ${menuFlag && styles.menuButton}
              ${onlyIconButton && styles.iconButton}`}
            disabled={hideAddSymbol}
            mouseEnterHandler={mouseEnterHandler}
            mouseLeaveHandler={mouseLeaveHandler}
            handleClick={handleClick || handleShowAddModal}
          >
            { isPlusIcon ? <PlusIcon /> : <IconFilter />}
            {!onlyIconButton && (
            <TextField
              text={textResult}
              styleType="successButton"
            />
            )}
          </Button>
        )
        : <div />}
    </>
  );
};

export default WatchlistAddSymbolButton;
