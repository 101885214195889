import { searchItemSym, rebuildSearchData } from './helpers';
import constants from '../constants/filters';

const chartSearch = (...args) => {
  // eslint-disable-next-line no-unused-vars
  const [search, exchange, symbolType, callbackFunc, symbols] = args;
  const findAndSend = () => {
    let results = [];

    const resultOne = searchItemSym(symbols, search, constants.sym);
    const resultSecond = searchItemSym(symbols, search, constants.name);
    const ids = new Set(resultOne?.matched.map((d) => d.index));
    const resultArray = [...resultOne?.matched, ...resultSecond?.matched?.filter((d) => !ids.has(d.index))];
    results = rebuildSearchData(resultArray);
    callbackFunc(results);
  };

  if (search) {
    findAndSend();
  } else {
    callbackFunc([]);
  }
};

export default chartSearch;
