import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import React, { useState, useRef } from 'react';
import { ClickAwayListener } from '@mui/material';
import { useResizeDetector } from 'react-resize-detector';
import { ReactComponent as DndControl } from '../../assets/images/icons/screener/dnd_frame.svg';
import { IWidgetPerformanceHeader } from '../DasboardComponents/types/DashboardComponentsInterfaces';
import dashboardStyles from '../DasboardComponents/sass/DashboardComponents.module.scss';
import styles from './sass/WidgetCharts.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';
import { ReactComponent as XIcon } from '../../assets/images/icons/x_share_blue.svg';
import ColorGroupDropdownButton from '../DasboardComponents/components/ColorGroupDropdownButton';
import useLastStateUpdateStore from '../../utils/hooks/useLastStateUpdateStore';
import { WIDGET_CHARTS_REMOVE_WIDGET } from '../../constants/storage';
import ScreenerDropDown from './headerComponents/ScreenerDropDown';
import DataPointDropDown from './headerComponents/DataPointDropDown';
import { HEADER_BREAK_POINT } from './types/WidgetChartsEnums';
import { setScreenShot, showShareModal } from '../../store/twitter/slice';
import useScreenshot from '../TwitterShare/hooks/useScreenshot';
import RootStateTypes from '../../store/RootStateTypes';
import { SOCIALS_TYPE } from '../../constants/screener';
import redirectHelper from '../TwitterShare/helpers/helpers';
import SortIcons from './headerComponents/SortIcons';

const WidgetChartsHeader = ({
  removeWidget,
  widget,
  setColorGroupe,
  width,
  dashboardId,
  selectedType,
  dataPointList,
  screenerStates,
  setScreenerStates,
  showX,
  showY,
  showZ,
  setDefaultFilter,
  showAxis,
  screenerSearch,
  lastStateKey,
  defaultDataPoints,
  xShare,
  baseRef,
  sort,
  isEditable
}: IWidgetPerformanceHeader): React.ReactElement => {
  const dispatch = useDispatch();
  const { snapElement } = useScreenshot();
  const { ref } = useResizeDetector();
  const colorDropdownRef = useRef<HTMLDivElement>(null);
  const [isColorDropOpen, setIsColorDropOpen] = useState<boolean>(false);
  const { updateWidgetChartsActionData } = useLastStateUpdateStore();
  const userProfile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);

  const handleRemoveWidget = () => {
    removeWidget(widget.id);
    updateWidgetChartsActionData(WIDGET_CHARTS_REMOVE_WIDGET, widget.id, dashboardId, lastStateKey);
  };

  const clickHandler = async () => {
    const twitterConnected = userProfile?.socials.find(
      (social: string) => social === SOCIALS_TYPE.TWITTER
    );

    if (!twitterConnected) {
      redirectHelper(process.env.REACT_APP_WP_URL);
      return;
    }
    const base64 = await snapElement(baseRef?.current);
    if (base64) {
      dispatch(setScreenShot(base64));
    }
    dispatch(showShareModal(true));
  };

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.stopPropagation();
        setScreenerStates((prev) => ({
          ...prev,
          screenerIsOpen: false,
          dataPointsXIsOpen: false,
          dataPointsYIsOpen: false,
          folderMenuIsOpen: -1,
        }));
      }}
    >
      <div className={styles.performanceChartHeader} ref={ref}>
        <div className={`${styles.performanceDnDBlock} handle-grid-drag`}>
          {isEditable && <DndControl />}
        </div>
        {width && width >= HEADER_BREAK_POINT.screener && (
          <ScreenerDropDown
            screenerStates={screenerStates}
            setScreenerStates={setScreenerStates}
            selectedType={selectedType}
            setDefaultFilter={setDefaultFilter}
            screenerSearch={screenerSearch}
            dropDownRef={ref}
            isEditable={isEditable}
          />
        )}

        {showX && width && width >= HEADER_BREAK_POINT.dataPoint && (
          <DataPointDropDown
            screenerStates={screenerStates}
            setScreenerStates={setScreenerStates}
            dataPointList={dataPointList}
            objectKey="X"
            showAxis={showAxis}
            defaultDataPoints={defaultDataPoints}
            dropDownRef={ref}
            isEditable={isEditable}
          />
        )}

        {showY && width && width >= HEADER_BREAK_POINT.dataPointY && (
          <DataPointDropDown
            screenerStates={screenerStates}
            setScreenerStates={setScreenerStates}
            dataPointList={dataPointList}
            objectKey="Y"
            showAxis={showAxis}
            defaultDataPoints={defaultDataPoints}
            dropDownRef={ref}
            isEditable={isEditable}
          />
        )}

        {showZ && width && width >= HEADER_BREAK_POINT.dataPointZ && (
          <DataPointDropDown
            screenerStates={screenerStates}
            setScreenerStates={setScreenerStates}
            dataPointList={dataPointList}
            objectKey="Z"
            showAxis={showAxis}
            defaultDataPoints={defaultDataPoints}
            dropDownRef={ref}
            isEditable={isEditable}
          />
        )}

        {width && width >= HEADER_BREAK_POINT.colorGroup && (
          <div
            style={{ width: 90 + (sort ? 35 : 0) + (xShare ? 20 : 0) + (isEditable ? 0 : -24) }}
            className={`${dashboardStyles.dashboardWidgetHeaderPosition}`}
          >
            <div ref={colorDropdownRef} className={dashboardStyles.dashboardWidgetHeaderRightBlock}>
              {sort && (
                <div
                  className={dashboardStyles.sortBlock}
                  tabIndex={0}
                  role="button"
                  aria-label="sort-buttons"
                  onMouseDown={() => isEditable && setScreenerStates((prev) => ({
                    ...prev,
                    sortBy: prev.sortBy === 1 ? 0 : 1
                  }))}
                >
                  <SortIcons value={screenerStates.sortBy} />
                </div>
              )}
              <ColorGroupDropdownButton
                toggleColorDrop={setIsColorDropOpen}
                isColorDropOpen={isColorDropOpen}
                widget={widget}
                colorDropdownRef={colorDropdownRef}
                handleSetColorGroupe={setColorGroupe}
                componentStyle={{}}
              />
              {xShare && <XIcon className={dashboardStyles.xShare} onClick={clickHandler} />}
              {isEditable && <CloseIcon className={dashboardStyles.butClose} onClick={handleRemoveWidget} />}
            </div>
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};

export default WidgetChartsHeader;
