const ACTION_AUTH = {
  LOGIN: '@@LOGIN',
  REGISTRATION: '@@REGISTRATION',
  LOGOUT: '@@LOGOUT',
  CHECK_AUTH: '@@CHECK_AUTH',
  LOADING: '@@LOADING',
  SAVE_RESPONSE: '@@SAVE_RESPONSE',
  SAVE_CHECK_RESPONSE: '@@SAVE_CHECK_RESPONSE',
  CHANGE_POP_UP_STATUS: '@@CHANGE_POP_UP_STATUS',
  SHOW_NOTION_ANIMATION: '@@SHOW_NOTION_ANIMATION',
  SAVE_MASSAGE: '@@SAVE_MASSAGE',
  ERROR_MASSAGE: '@@ERROR_MASSAGE',
  ERROR_MASSAGE_TITLE: '@@ERROR_MASSAGE_TITLE',
  LOGIN_ERROR: '@@LOGIN_ERROR',
  PHONE: '@@PHONE',
  CHANGE_NOTIFICATION_TYPE: '@@CHANGE_NOTIFICATION_TYPE',
  SET_DISCONNECT_POPUP: '@@SET_DISCONNECT_POPUP',
};

export default ACTION_AUTH;
