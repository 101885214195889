import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import stylesModal from './sass/RemoveModal.module.scss';
import TextField from '../TextField/TextField';
import Icon from '../Icon/Icon';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import folderIcon from '../../assets/images/icons/screener/folder.svg';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import ButtonBlock from '../ButtonBlock/ButtonBlock';

const RemoveModal = ({
  setOpenModal,
  currentItem,
  itemsColumnSets,
  handlerRemoveModal,
}) => {
  const { t } = useTranslation();
  const folder = itemsColumnSets.filter((item) => item.id === currentItem?.folder?.id);
  const countColumnSets = folder[0]?.items?.length || 0;

  const removeItemHandler = () => {
    handlerRemoveModal(currentItem?.id, folder[0].title);
  };

  return (
    <ButtonListenerWrapper
      handleCancel={setOpenModal}
      handleConfirm={removeItemHandler}
    >
      <div className={stylesModal.moveToFolderWrapper}>
        <div className={stylesModal.moveToFolderHeader}>
          <TextField text={t('areYouSure')} styleType="createFolderModalHeader" />
          <div aria-hidden="true" onMouseDown={() => setOpenModal()}>
            <CloseIcon />
          </div>
        </div>
        <div className={stylesModal.moveToFolderBody}>
          <TextField
            text={`${t('removeColumnFromFolder')} ${currentItem?.title} `}
            styleType="removeToBodyText"
          />
          <TextField
            text={`${t('fromFolder')}`}
            styleType="removeToBodyText"
          />
          <div className={stylesModal.itemFolderWrapperActive}>
            <Icon
              icon={folderIcon}
            />

            <div className={stylesModal.textBox}>
              <TextField text={currentItem.title} styleType="watchListTitleActive" />
              <TextField
                text={`${countColumnSets} ${t('columnSetsDesc')}`}
                styleType="watchListDescriptionActive"
              />
            </div>

          </div>
          <ButtonBlock
            firstButton={t('cancel')}
            firstHandler={setOpenModal}
            secondButton={t('remove')}
            secondHandler={removeItemHandler}
          />
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

RemoveModal.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  handlerRemoveModal: PropTypes.func,
  currentItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    folder: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  itemsColumnSets: PropTypes.arrayOf(PropTypes.shape({}))
};

RemoveModal.defaultProps = {
  currentItem: {},
  itemsColumnSets: [],
  handlerRemoveModal: () => undefined
};

export default RemoveModal;
