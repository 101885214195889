import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import styles from './sass/TabsPeriodDate.module.scss';
import listButton from '../../lists/datePeriodButton';
import DefaultButton from '../DefaultButton/DefaultButton';
import { DATE_TABS_LABELS } from '../../constants/rangeDatePicker';

interface ITabsPeriodDate {
  tabsHandler: (tab: string) => void;
  activeTab: { [key: string]: boolean };
  styleType?: string;
}

const TabsPeriodDate = ({ tabsHandler, activeTab, styleType }: ITabsPeriodDate): React.ReactElement => {
  const { t } = useTranslation();
  return useMemo(() => (
    <div className={`${styles.container} ${styleType ? styles[styleType] : ''}`}>
      {listButton.map((item, index) => {
        let itemRender;
        const itemTitle = DATE_TABS_LABELS[item as keyof typeof DATE_TABS_LABELS];
        if (activeTab.before) {
          itemRender = (
            <DefaultButton
              key={[item, index].join('_')}
              id={item}
              handleClick={() => tabsHandler(item)}
              buttonClass="tabDateCentral"
              activeClass={`${activeTab[item] ? 'activeTabDate' : ''}`}
            >
              {t(`${itemTitle}`)}
            </DefaultButton>
          );
        } else {
          itemRender = (
            <DefaultButton
              key={[item, index].join('_')}
              id={item}
              handleClick={() => tabsHandler(item)}
              buttonClass="tabDate"
              activeClass={`${activeTab[item] ? 'activeTabDate' : ''}`}
            >
              {t(`${itemTitle}`)}
            </DefaultButton>
          );
        }
        return itemRender;
      })}
    </div>
  ), [activeTab]);
};

export default TabsPeriodDate;
