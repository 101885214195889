import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/ColumnSetsItem.module.scss';
import TextField from '../TextField/TextField';

const ColumnSetsLabel = ({
  label, activeColumnSets, idColumnSets, description, color
}) => useMemo(() => (
  <div
    aria-hidden="true"
    role="button"
    tabIndex="0"
    className={styles.labelText}
    onMouseDown={() => activeColumnSets(idColumnSets)}
  >
    <TextField text={label} styleType="columnsSetLabelSet" color={color} />
    <TextField text={description} styleType="columnsSetDescription" />
  </div>
), [description, color, label, activeColumnSets]);

ColumnSetsLabel.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  activeColumnSets: PropTypes.func,
  idColumnSets: PropTypes.number,
  description: PropTypes.string,
};

ColumnSetsLabel.defaultProps = {
  label: '',
  color: '',
  idColumnSets: -1,
  description: '',
  activeColumnSets: () => undefined
};

export default ColumnSetsLabel;
