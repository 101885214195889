import { shallowEqual, useSelector } from 'react-redux';
import React, { useContext } from 'react';
import styles from '../sass/TableScreener.module.scss';
import DataPanel from '../../../components/DataPanel/DataPanel';
import ScreenerViewMain from './ScreenerViewMain';
import useResize from '../../../utils/hooks/useResize';
import TableContext from '../context/TableContext';

const ScreenerView = () => {
  const {
    isWatchlist,
    getScreenerLayout,
    getCurrentInnerWidth,
    dataPanel,
  } = useContext(TableContext);

  const isHideMenu = useSelector((state) => state.accountState.userSettings.isHideSideMenu, shallowEqual);
  const { initCurrentGridTemplate } = useResize(getScreenerLayout, getCurrentInnerWidth);

  return (
    <div
      id="screenerLayout"
      className={`${styles.viewGraphic} ${isHideMenu && styles.shortly}`}
      style={{ gridTemplateColumns: initCurrentGridTemplate }}
    >
      <ScreenerViewMain />
      <DataPanel isWatchList={isWatchlist} dataPanel={dataPanel} />
    </div>
  );
};

export default ScreenerView;
