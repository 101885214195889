import { useDispatch } from 'react-redux';
import { setHolidaysList } from '../../store/watchlist/actions';

const useHolidays = () => {
  const dispatch = useDispatch();
  const URL = process.env.REACT_APP_GET_HOLIDAY;
  const getHolidays = async () => {
    const response = await fetch(URL);
    const list = await response.json();
    const result = [];

    list.forEach((item) => {
      const splitedItem = item.split('-');
      result.push(splitedItem.join(''));
    });

    dispatch(setHolidaysList(result.join(',')));
  };

  return { getHolidays };
};

export default useHolidays;
