import { SESSION_STORAGE_CLIENT_ID } from '../constants/storage';

export default class UniqIdTabService {
  public static generateUniqId(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const array = new Uint32Array(18);
    window.crypto.getRandomValues(array);

    let result = '';
    array.forEach((value) => {
      result += characters.charAt(value % characters.length);
    });
    return result;
  }

  public static getUniqId(): string {
    const tabId = window.sessionStorage.getItem(SESSION_STORAGE_CLIENT_ID);
    return tabId || this.setUniqId(this.generateUniqId());
  }

  public static setUniqId(id: string): string {
    window.sessionStorage.setItem(SESSION_STORAGE_CLIENT_ID, id);
    return id;
  }

  public static removeUniqId(): void {
    window.sessionStorage.removeItem(SESSION_STORAGE_CLIENT_ID);
  }
}
