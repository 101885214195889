import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/SidebarScreener.module.scss';
import IAccountSettingsButton from '../interfaces/IAccountSettingsButton';
import ThemeVariants from '../../../constants/theme';
import TextField from '../../TextField/TextField';

const AccountSettingsButton = ({ route, theme }: IAccountSettingsButton): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <button
      aria-label="accountSettings"
      type="button"
      className={`${styles.accountButton} ${theme === ThemeVariants.DARK_MODE
        ? styles.darkAccountButton : styles.lightAccountButton}`}
    >
      <a
        aria-label="accountSettingsLink"
        href={route}
        target="_blank"
        className={styles.accountButtonText}
        rel="noreferrer"
      >
        <TextField
          text={t('accountSettings')}
          styleType="tabItemTitleFavorite"
          className={styles.buttonDiv}
        />
      </a>
    </button>
  );
};

export default AccountSettingsButton;
