import { DEF_URLS } from '../constants/paths';
import $api, { REACT_APP_PUBLIC_LIGHT_SERVER_URL } from '../http';
import AUTH_PATH from '../constants/requestPath';
import { ACCESS_TOKEN, RE_AUTH_QUERY, REFRESH_TOKEN } from '../constants/storage';

export interface ITokenService {
  checkToken(): Promise<boolean>;
  updateAccessToken(): Promise<string>;
}

export default class TokenService implements ITokenService{
  private accessTokenKey = ACCESS_TOKEN;

  private refreshTokenKey = REFRESH_TOKEN;

  async checkToken(): Promise<boolean> {
    const token = localStorage.getItem(this.accessTokenKey);
    if (!token) {
      return false;
    }

    try {
      // Placeholder for actual token check request
      return await $api.get(`${REACT_APP_PUBLIC_LIGHT_SERVER_URL}${DEF_URLS}`)
        .then(() => true).catch(() => false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Token check failed: ===>', error);
      return false;
    }
  }

  async updateAccessToken(): Promise<string> {
    const currentRefreshToken = localStorage.getItem(this.refreshTokenKey);
    try {
      return await $api.post(
        AUTH_PATH.REFRESH_TOKEN,
        {},
        {
          headers: {
            Authorization: `Bearer ${currentRefreshToken}`
          }
        }
      ).then((response) => {
        const { accessToken, refreshToken } = response.data;
        if (!accessToken) {
          window.location.replace(`${process.env.REACT_APP_WP_URL}?${RE_AUTH_QUERY}`);
          return '';
        }
        localStorage.setItem(this.accessTokenKey, accessToken);
        localStorage.setItem(this.refreshTokenKey, refreshToken);
        return accessToken;
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Token update failed: ===>', error);
      return '';
    }
  }
}
