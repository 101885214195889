import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import { EDIT_MODAL_TYPES, MODAL_TITLES } from '../../constants/screener';
import UpdateFolderModal from './UpdateFolderModal';

const UpdateFolderModalHandler = ({
  openModal,
  updateScreen,
  updateFolder,
  currentSubItem,
  closeModalHandler,
  singleItemStatus,
  currentPresetItem,
  currentFolderSetsItem,
  currentColumnSetItem,
  currentItem,
  currentFolderPresetItem,
  itemId
}) => (
  <>
    {openModal === MODAL_TITLES.EDIT && (
      <UpdateFolderModal
        updateScreen={updateScreen}
        updateFolder={updateFolder}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        singleItemStatus={singleItemStatus}
        currentPresetItem={currentPresetItem}
        currentItem={currentItem || currentPresetItem || currentFolderPresetItem || itemId}
        type={EDIT_MODAL_TYPES.SCREEN}
      />
    )}

    {openModal === MODAL_TITLES.EDIT_COLUMNS_FOLDER && (
      <UpdateFolderModal
        updateScreen={updateScreen}
        updateFolder={updateFolder}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        singleItemStatus={singleItemStatus}
        currentItem={currentFolderSetsItem}
        currentPresetItem={currentPresetItem}
        type={EDIT_MODAL_TYPES.FOLDER}
      />
    )}
    {openModal === MODAL_TITLES.EDIT_COLUMN && (
      <UpdateFolderModal
        singleItemStatus
        updateFolder={updateFolder}
        updateScreen={updateScreen}
        currentSubItem={currentSubItem}
        setOpenModal={closeModalHandler}
        currentItem={currentColumnSetItem}
        currentPresetItem={currentPresetItem}
        type={EDIT_MODAL_TYPES.COLUMN}
      />
    )}
  </>
);

UpdateFolderModalHandler.propTypes = {
  singleItemStatus: PropTypes.bool,
  itemId: PropTypes.shape({}),
  currentColumnSetItem: PropTypes.shape({}),
  currentFolderSetsItem: PropTypes.shape({}),
  currentFolderPresetItem: PropTypes.shape({}),
  openModal: PropTypes.string.isRequired,
  closeModalHandler: PropTypes.func,
  updateScreen: PropTypes.func,
  updateFolder: PropTypes.func,
  currentSubItem: oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  currentItem: PropTypes.shape({}),
  currentPresetItem: PropTypes.shape({}),
};

UpdateFolderModalHandler.defaultProps = {
  singleItemStatus: false,
  itemId: {},
  currentColumnSetItem: {},
  currentFolderSetsItem: {},
  currentFolderPresetItem: {},
  closeModalHandler: () => undefined,
  updateScreen: () => undefined,
  updateFolder: () => undefined,
  currentItem: {},
  currentPresetItem: {},
  currentSubItem: {} || PropTypes.string,
};

export default React.memo(UpdateFolderModalHandler);
