import {
  useDispatch,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import $api from '../../../http';
import { TWITTER_SHARE } from '../../../constants/paths';
import {
  changeNotificationTypeAction,
  errorMessageTitleAction,
  messageAction,
  popUpAction
} from '../../../store/auth/actions';
import { OK_STATUS } from '../../../constants/responseStatuses';
import { IUseTwitter } from '../types/interface';
import { showShareModal } from '../../../store/twitter/slice';

const useTwitter = (): IUseTwitter => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const shareHandler = (text: string, screenshot: string) => {
    $api.post(
      TWITTER_SHARE,
      {
        file: screenshot,
        title: text
      }
    ).then((res) => {
      if (res.data.status === OK_STATUS) {
        dispatch(errorMessageTitleAction({ messageTitle: t('shareSuccess') }));
        dispatch(messageAction({ message: t('congrats') }));
        dispatch(changeNotificationTypeAction({ type: 'success' }));
        dispatch(showShareModal(false));
        dispatch(popUpAction(true));
      }
    });
  };

  return {
    shareHandler
  };
};

export default useTwitter;
