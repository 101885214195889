import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import PageView from './PageView';
import { getFromLocalStorage } from '../../utils/storageWorks';

const Page = ({ children }) => (getFromLocalStorage('isWebView') ? children : <PageView>{children}</PageView>);

Page.propTypes = {
  children: PropTypes.node.isRequired
};

export default withRouter(Page);
