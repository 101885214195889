import constants from '../constants/filters';
import {
  TEMPLATE_TYPE, VIEW_TYPES, PLAN_TYPE, CORRECT_COLUMN
} from '../constants/screener';
import { findIndexItem, clearArray } from './helpers';

export const getTitleModal = (type, t, isSavedItem = false) => {
  let title = '';
  switch (type) {
    case constants.presets:
      title = t('createNewPreset');
      break;
    case constants.columns:
      title = t('createNewColumnSet');
      break;
    case constants.presetDeeplist:
      title = t('createNewPreset');
      break;
    case constants.deepList:
      title = isSavedItem ? t('saveWatchlist') : t('createWatchlist');
      break;
    case constants.dataPanel:
      title = isSavedItem ? t('saveDataPanel') : t('createDataPanel');
      break;
    case constants.dataPanelPresets:
      title = t('createDataPanelPreset');
      break;
    case constants.userDashboard:
      title = isSavedItem ? t('saveDashboard') : t('createDashboard');
      break;
    case constants.userDashboardPresets:
      title = t('createDashboardPreset');
      break;
    default:
      title = isSavedItem ? t('saveScreen') : t('createNewScreen');
      break;
  }
  return title;
};

export const getLabelModal = (type, t) => {
  let label = '';
  switch (type) {
    case constants.presets:
      label = t('presetName');
      break;
    case constants.columns:
      label = t('columnSetName');
      break;
    case constants.presetDeeplist:
      label = t('presetName');
      break;
    case constants.deepList:
      label = t('watchlistName');
      break;
    case constants.dataPanel:
      label = t('dataPanelName');
      break;
    case constants.dataPanelPresets:
      label = t('dataPanelName');
      break;
    case constants.userDashboard:
      label = t('dashboardName');
      break;
    case constants.userDashboardPresets:
      label = t('dashboardName');
      break;
    default:
      label = t('screenName');
      break;
  }
  return label;
};

export const getBodyRequest = (
  type,
  nameScreen,
  filters,
  favoritesFilter,
  columns,
  folderId,
  nameFolder,
  description
) => {
  let body;
  switch (type) {
    case constants.presets:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.PRESET,
        viewType: VIEW_TYPES.CHART,
        data: {
          filters,
          favoriteFilters: favoritesFilter
        },
        isFavorite: false,
      };
      break;
    case constants.deepList:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.WATCHLIST,
        viewType: VIEW_TYPES.TABLE,
        data: {
          filters,
          description,
          stocks: filters ? filters[0][0][2].length : 0
        },
      };
      break;
    case constants.presetDeeplist:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.WATCHLIST_PRESETS,
        viewType: VIEW_TYPES.TABLE,
        data: {
          filters,
          stocks: filters ? filters[0][0][2].length : 0
        },
      };
      break;
    case constants.dataPanel:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.DATA_PANEL,
        viewType: VIEW_TYPES.TABLE,
        data: {
          leftColumn: [],
          rightColumn: [],
        },
      };
      break;
    case constants.columns:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.COLUMN_SET,
        viewType: VIEW_TYPES.TABLE,
        data: {},
      };
      break;
    case constants.columnsPreset:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.COLUMN_SET_PRESET,
        viewType: VIEW_TYPES.TABLE,
        data: {},
      };
      break;

    case constants.dataPanelPresets:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.DATA_PANEL_PRESET,
        viewType: VIEW_TYPES.TABLE,
        data: {
          leftColumn: [],
          rightColumn: [],
        },
      };
      break;
    case constants.userDashboard:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.DASHBOARDS,
        viewType: VIEW_TYPES.TABLE,
        data: {
          widgets: [],
          description,
        },
        isFavorite: false,
      };
      break;
    case constants.userDashboardPresets:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.DASHBOARDS_PRESET,
        viewType: VIEW_TYPES.TABLE,
        data: {
          widgets: [],
          description,
        },
        isFavorite: false,
      };
      break;
    default:
      body = {
        title: nameScreen,
        type: TEMPLATE_TYPE.SCREENER,
        viewType: VIEW_TYPES.TABLE,
        data: {
          filters,
          description,
          planTypes: PLAN_TYPE.PREMIUM
        },
        isFavorite: false,
      };
      break;
  }

  if (folderId && +folderId !== -1) {
    body.folder = { id: +folderId, name: nameFolder };
  }
  return body;
};

export const getTitleDuplicateModal = (type, t, valueName) => {
  let label = '';
  switch (type) {
    case constants.presets:
      label = `${t('duplicatePreset')} ${valueName}`;
      break;
    case constants.presetDeeplist:
      label = `${t('duplicatePreset')} ${valueName}`;
      break;
    case constants.columns:
      label = `${t('duplicateColumnSets')} ${valueName}`;
      break;
    default:
      label = `${t('duplicateScreen')} ${valueName}`;
      break;
  }
  return label;
};

export const countColumns = (data) => {
  let count = null;
  const newData = clearArray(data);
  if (newData?.length > 0) {
    count = newData?.length;
  } else if (newData?.default?.length > 0) {
    count = newData?.default?.length;
  }
  return count + CORRECT_COLUMN;
};

export const findItemColumnSet = (array, id) => {
  let item = {};
  const arrayFolders = array.filter((el) => el.type === constants.folder);
  const arrayItems = array.filter((el) => el.type === constants.columns);
  arrayFolders.forEach((element) => {
    arrayItems.push(...element.items);
  });
  const indexItem = findIndexItem(arrayItems, constants.id, id);

  if (indexItem !== -1) {
    item = arrayItems[indexItem];
  }
  return item;
};

export const getTitleCreateSuccess = (type, t) => {
  let label = '';
  switch (type) {
    case constants.presets:
      label = `${t('successPresetCreated')}`;
      break;
    case constants.columns:
      label = `${t('successCreatedColumnSet')}`;
      break;
    case constants.columnsPreset:
      label = `${t('successCreatedColumnSet')}`;
      break;
    case constants.dataPanelPresets:
      label = `${t('dataPanelCreated')}`;
      break;
    case constants.dataPanel:
      label = `${t('dataPanelCreated')}`;
      break;
    case constants.deepList:
      label = `${t('successCreatedDeeplist')}`;
      break;
    case constants.userDashboard:
      label = `${t('successCreatedDashboard')}`;
      break;
    default:
      label = `${t('successCreated')}`;
  }
  return label;
};

export const getTitle = (itemType) => {
  switch (itemType) {
    case TEMPLATE_TYPE.WATCHLIST: return 'Watchlist';
    case TEMPLATE_TYPE.SCREENER: return 'Screen';
    case TEMPLATE_TYPE.DASHBOARDS: return 'Dashboard';
    case TEMPLATE_TYPE.DATA_PANEL: return 'Data Panel';
    case TEMPLATE_TYPE.COLUMN_SET: return 'Column Set';
    default: return 'Screen';
  }
};

export const typeHandler = (type) => {
  if (type === TEMPLATE_TYPE.WATCHLIST_PRESETS) {
    return TEMPLATE_TYPE.WATCHLIST;
  }

  if (type === TEMPLATE_TYPE.PRESET) {
    return TEMPLATE_TYPE.SCREENER;
  }

  if (type === TEMPLATE_TYPE.DATA_PANEL_PRESET) {
    return TEMPLATE_TYPE.DATA_PANEL;
  }

  if (type === TEMPLATE_TYPE.DATA_PANEL_PRESET) {
    return TEMPLATE_TYPE.DATA_PANEL;
  }

  if (type === TEMPLATE_TYPE.DASHBOARDS_PRESET) {
    return TEMPLATE_TYPE.DASHBOARDS;
  }
  if (type === TEMPLATE_TYPE.COLUMN_SET_PRESET) {
    return TEMPLATE_TYPE.COLUMN_SET;
  }

  return type || TEMPLATE_TYPE.SCREENER;
};
