import React, {
  useEffect, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import styles from '../sass/GlobalSearch.module.scss';
import ResultList from './ResultList';
import useLastElementObserver from '../../../utils/hooks/useLastElementObserver';
import { SEARCH_HISTORY } from '../../../constants/storage';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { deepEqual } from '../../../utils/objectHelper';
import { MAX_HISTORY_SEARCH_LENGTH } from '../../../constants/screener';
import SearchDropDownEmpty from '../../WatchListModals/SearchDropDownEmpty';

const ResultBlock = ({
  listResults,
  handleClick,
  activeResult,
  value,
  setGlobalSearchVisible,
  setPaging,
  isWidget
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const searchHistory = useSelector((state) => state.accountState.userSettings.searchHistory, shallowEqual);
  const { updateStoreHandler } = useLastStateUpdateStore();

  useEffect(() => {
    ref.current.scrollTop = (activeResult - 1) * 42;
  }, [activeResult]);

  const { lastElementRef } = useLastElementObserver(setPaging);
  let listHistory = searchHistory ? JSON.parse(searchHistory) : [];
  const rowClickHandler = (item) => {
    handleClick(item);
    if (listHistory?.length > 0) {
      const data = listHistory?.find((list) => list.index === item.index);
      if (!data) {
        if (listHistory.length >= MAX_HISTORY_SEARCH_LENGTH) {
          listHistory = listHistory.slice(0, MAX_HISTORY_SEARCH_LENGTH - 1);
        }
        listHistory?.unshift(item);
        updateStoreHandler(SEARCH_HISTORY, JSON.stringify(listHistory));
      }
    } else {
      updateStoreHandler(SEARCH_HISTORY, JSON.stringify([...listHistory, item]));
    }
    setGlobalSearchVisible(false);
    setPaging(!isWidget);
  };

  return (
    <div className={styles.resultBlock} ref={ref}>
      {listHistory && <span className={styles.recentSearch}>{t('recentSearch')}</span>}
      {(!!value && listResults?.length > 0) ? (
        <ResultList
          list={listResults?.length ? listResults : listHistory}
          rowClickHandler={rowClickHandler}
          setHoveredItem={setHoveredItem}
          hoveredItem={hoveredItem}
          activeResult={activeResult}
          lastElementRef={lastElementRef}
        />
      ) : (
        <>
          {
            !value ? (
              <ResultList
                list={listHistory}
                rowClickHandler={rowClickHandler}
                setHoveredItem={setHoveredItem}
                hoveredItem={hoveredItem}
                activeResult={activeResult}
                lastElementRef={lastElementRef}
              />
            ) : (
              <SearchDropDownEmpty value={value} noResultTitle={t('sorryNoResultsFor')} />
            )
          }
        </>
      )}
    </div>
  );
};

ResultBlock.propTypes = {
  setPaging: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  listResults: PropTypes.arrayOf(PropTypes.shape({})),
  handleClick: PropTypes.func,
  activeResult: PropTypes.number.isRequired,
  setGlobalSearchVisible: PropTypes.func.isRequired,
  isWidget: PropTypes.bool,
};

ResultBlock.defaultProps = {
  listResults: [],
  handleClick: () => {},
  isWidget: false,
};

export default React.memo(ResultBlock, (prevProps, nextProps) => {
  return deepEqual(prevProps.listResults, nextProps.listResults)
      && prevProps.activeResult === nextProps.activeResult && prevProps.value === nextProps.value;
});
