import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { IUseEvents } from '../types/EducationComponentsInterfaces';

const useEvents: IUseEvents = (eventsParams, setEventsParams) => {
  const eventsList = useSelector((state: RootStateTypes) => state.educationState?.eventsList, shallowEqual);
  const webinarsList = useSelector((state: RootStateTypes) => state.educationState?.webinarsList, shallowEqual);
  const levels = useSelector((state: RootStateTypes) => state.educationState?.levels, shallowEqual);
  const webinarsCategories = useSelector(
    (state: RootStateTypes) => state.educationState?.tutorialCategories,
    shallowEqual
  );

  const [atBottom, setAtBottom] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (atBottom) {
      setEventsParams((prev) => ({ ...prev, page: prev.page + 1, isLoadMore: true }));
    }
  }, [atBottom]);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;
      const { scrollTop, scrollHeight, clientHeight } = ref.current;
      if (scrollTop + clientHeight >= scrollHeight - 50 && webinarsList.max_pages > eventsParams.page) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    const scrollableDiv = ref.current;
    if (scrollableDiv) scrollableDiv.addEventListener('scroll', handleScroll);
    return () => {
      if (scrollableDiv) scrollableDiv.removeEventListener('scroll', handleScroll);
    };
  }, [webinarsList]);

  return {
    eventsList, ref, webinarsList, webinarsCategories, levels
  };
};

export default useEvents;
