import React from 'react';
import PropTypes from 'prop-types';
import stylesModal from '../sass/NewScreenModals.module.scss';
import TextField from '../../TextField/TextField';

const TextBlock = ({ title, countStock }) => (
  <div className={stylesModal.textBox}>
    <TextField text={title} styleType="watchListTitleActive" />
    <TextField
      text={countStock}
      styleType="watchListDescriptionActive"
    />
  </div>
);

TextBlock.propTypes = {
  title: PropTypes.string,
  countStock: PropTypes.string
};

TextBlock.defaultProps = {
  title: '',
  countStock: ''
};

export default TextBlock;
