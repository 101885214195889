import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { setHoveredSymbolIndex } from '../../../store/tableData/slice';
import InfoComponent from './InfoComponent';
import Checkbox from '../../Checkbox/Checkbox';
import TargetListWrapper from './TargetMenu/TargetListWrapper';

import { getColumnStyle } from '../../../utils/helpers';
import { setTargetList } from '../../../store/watchlist/actions';

import styles from '../sass/ScreenerTable.module.scss';
import useLongDescription, { descriptionCache } from '../../../utils/hooks/useLongDescription';
import { SECOND } from '../../../constants/tvWidgetOptions';
import ETFIndicator from './ETFIndicator';

let timeout;

const areEqual = (prevProps, nextProps) => {
  return prevProps.item === nextProps.item
  && prevProps.checked === nextProps.checked
  && prevProps.targetLists === nextProps.targetLists
  && prevProps.activeVisibility === nextProps.activeVisibility
  && prevProps.isSearch === nextProps.isSearch
  && prevProps.size === nextProps.size
  && prevProps.selectedRowsFromWidget === nextProps.selectedRowsFromWidget
  && prevProps.alertTable === nextProps.alertTable;
};

const SymbolTableItem = ({
  item,
  name,
  checked,
  changeHandler,
  size,
  columnIndex,
  earningsValue,
  dividendValue,
  earningTimeValue,
  activeVisibility,
  hideCheckbox,
  targetLists,
  addSymbolToTargetList,
  targetListDisabled,
  watchList,
  isSearch,
  alertTable,
  isETF,
  widgetId,
  widgetResizeSelector,
  setOpenEtfWidget,
  selectedItemFromWidget,
  showWeight,
  selectedRowsFromWidget,
  clearSelections
}) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const selectedScreenId = useSelector(
    (state) => state.accountState.userSettings.selectedScreenId, shallowEqual
  );
  const isHoldingView = useSelector(
    (state) => state.newScreenState.selectedScreen?.isHoldingView,
    shallowEqual
  );
  const hoveredSymbolIndex = useSelector((state) => state.tableDataState.hoveredSymbolIndex, shallowEqual);

  const [toggleTargetList, setToggleTargetList] = useState(false);
  const [currentTargetList, setCurrentTargetList] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [description, setDescription] = useState('');
  const { getDescription } = useLongDescription();

  const widgetEtfHandler = (etfItem) => () => {
    setOpenEtfWidget(etfItem);
  };

  const handleAddSymbolToTargetList = ({
    symbol, targetList, updateFlag = false
  }) => {
    if (!currentTargetList || currentTargetList.id !== targetList.id) {
      setCurrentTargetList(targetList);
    }

    addSymbolToTargetList({
      symbol, targetList, currentTargetList, updateFlag
    });
  };

  const handleRemoveSymbolFromTargetList = () => {
    if (currentTargetList) {
      setCurrentTargetList(null);
    }
  };

  const handleUpdateTargetListName = (targetList) => {
    dispatch(setTargetList(targetList));
  };

  const descriptionHandler = async () => {
    await getDescription(item.sym);
    setDescription(descriptionCache[item.sym]);
  };

  const showDescriptionHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    timeout = setTimeout(() => {
      setShowDescription(true);
    }, SECOND);
  };

  const hideDescriptionHandler = () => {
    clearTimeout(timeout);
    setShowDescription(false);
  };

  useEffect(() => {
    if (showDescription) {
      descriptionHandler();
    }
  }, [showDescription]);

  useEffect(() => {
    let getCurrentTargetList = null;
    if (watchList && !isSearch && !isHoldingView && !widgetId) {
      [getCurrentTargetList] = targetLists.filter((list) => list.id === selectedTab?.id);
    }
    if (!getCurrentTargetList) {
      getCurrentTargetList = targetLists
        .find((list) => list.data?.filters?.length && list.data.filters[0][0][2].includes(item.sortIndex));
    }
    setCurrentTargetList(getCurrentTargetList);
  }, [targetLists, isSearch, item]);

  const getResizeSelector = () => {
    if (widgetId) {
      return `columnResize_${columnIndex}_${widgetResizeSelector}`;
    }
    return `columnResize_${columnIndex}`;
  };

  const { defaultEarningsDate, defaultExDividendDate, defaultDescriptionHover } = activeVisibility;
  const earningsValueData = defaultEarningsDate ? earningsValue : null;
  const dividendValueData = defaultExDividendDate ? dividendValue : null;
  return (
    <div
      className={
        `${styles.symbol} ${styles.screenerTableCell} ${styles[name]}
        ${getResizeSelector()} ${hideCheckbox && styles.bottomBordered}`
      }
      key={name}
      style={getColumnStyle(size, null, null, { index: columnIndex })}
    >
      <div className={styles.wrapper}>
        {!hideCheckbox && (
          <Checkbox
            id={`${item.sortIndex}_${name}`}
            checked={checked}
            changeHandler={() => changeHandler(item)}
          />
        )}
        <TargetListWrapper
          item={item}
          targetLists={targetLists}
          currentTargetList={currentTargetList}
          addSymbolToTargetList={handleAddSymbolToTargetList}
          handleRemoveSymbolFromTargetList={handleRemoveSymbolFromTargetList}
          setToggleTargetList={setToggleTargetList}
          toggleTargetList={toggleTargetList}
          targetListDisabled={targetListDisabled}
          handleUpdateTargetListName={handleUpdateTargetListName}
          showDescription={showDescription}
          setShowDescription={setShowDescription}
          description={description}
          hideCheckbox={hideCheckbox}
          watchList={watchList}
          alertTable={alertTable}
          defaultDescriptionHover={defaultDescriptionHover}
          widgetId={widgetId}
          selectedItemFromWidget={selectedItemFromWidget}
          showWeight={showWeight}
          selectedRowsFromWidget={selectedRowsFromWidget}
          clearSelections={clearSelections}
          onMouseEnter={() => dispatch(setHoveredSymbolIndex({
            index: item.index,
            dataId: widgetId
              || (watchList ? selectedTab.id : selectedScreenId)
          }))}
          onMouseLeave={() => dispatch(setHoveredSymbolIndex({
            index: null,
            dataId: null
          }))}
        />
        <span
          role="button"
          tabIndex={0}
          className={`${styles.symbolHover} ${item.index === hoveredSymbolIndex.index 
          && (widgetId ? widgetId === hoveredSymbolIndex.dataId : true) && styles.targetPlus}`}
          onMouseEnter={showDescriptionHandler}
          onMouseLeave={hideDescriptionHandler}
        >
          {item.sym}
        </span>
        {isETF ? <ETFIndicator onClick={widgetEtfHandler(item)} /> : null}
        <InfoComponent
          earningTimeValue={earningTimeValue}
          earningsValue={earningsValueData}
          dividendValue={dividendValueData}
        />
      </div>
    </div>
  );
};

SymbolTableItem.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    index: PropTypes.number,
    sym: PropTypes.string,
    sortIndex: PropTypes.number
  }).isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  changeHandler: PropTypes.func.isRequired,
  size: PropTypes.shape({ minWidth: PropTypes.number, startWidth: PropTypes.number, maxWidth: PropTypes.number }),
  columnIndex: PropTypes.number,
  earningsValue: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  dividendValue: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  earningTimeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  activeVisibility: PropTypes.shape({
    defaultEarningsDate: PropTypes.bool,
    defaultExDividendDate: PropTypes.bool,
    defaultDescriptionHover: PropTypes.bool
  }),
  targetLists: PropTypes.arrayOf(PropTypes.shape({})),
  addSymbolToTargetList: PropTypes.func.isRequired,
  hideCheckbox: PropTypes.bool,
  targetListDisabled: PropTypes.bool,
  watchList: PropTypes.bool,
  isSearch: PropTypes.bool,
  alertTable: PropTypes.bool,
  isETF: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool, PropTypes.number]),
  widgetId: PropTypes.string,
  widgetResizeSelector: PropTypes.number,
  setOpenEtfWidget: PropTypes.func,
  selectedItemFromWidget: PropTypes.number,
  showWeight: PropTypes.bool,
  selectedRowsFromWidget: PropTypes.arrayOf(PropTypes.number),
  clearSelections: PropTypes.func.isRequired,
};

SymbolTableItem.defaultProps = {
  size: {
    maxWidth: 200,
    minWidth: 160,
    startWidth: 160
  },
  columnIndex: 0,
  earningsValue: null,
  dividendValue: null,
  earningTimeValue: null,
  activeVisibility: {},
  hideCheckbox: false,
  targetLists: [],
  targetListDisabled: false,
  watchList: false,
  isSearch: false,
  alertTable: false,
  isETF: null,
  widgetId: '',
  widgetResizeSelector: 0,
  setOpenEtfWidget: () => undefined,
  selectedItemFromWidget: 0,
  showWeight: false,
  selectedRowsFromWidget: []
};

export default React.memo(SymbolTableItem, areEqual);
