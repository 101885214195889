import useSpeedCheckup from './useSpeedCheckup';

const useCompression = () => {
  const { sendErrorHandler } = useSpeedCheckup();

  const compressHandler = async (data) => {
    const dataResult = data;
    delete dataResult?.s3Data;
    const jsonData = JSON.stringify(dataResult);
    const jsonSize = (jsonData.length / 1024) / 1024;

    if (jsonSize >= 0.7) {
      const strToGzipBase64 = async (str) => { // eslint-disable-next-line no-undef
        const cs = new CompressionStream('gzip');
        const writer = cs.writable.getWriter();
        writer.write(new TextEncoder().encode(str));
        writer.close();

        let charStr = '';
        const bytes = new Uint8Array(await (new Response(cs.readable).arrayBuffer()));

        bytes.forEach((byte) => {
          charStr += String.fromCharCode(byte);
        });

        return btoa(charStr);
      };

      const res = await strToGzipBase64(jsonData);
      return { s3Data: res, compressed: true };
    }

    return { data: dataResult, compressed: false };
  };

  const decompressHandler = async (data) => {
    if (data?.s3Data) {
      try {
        return await fetch(`${data.s3Data}?${Date.now()}`).then((res) => res.json()).then((resp) => resp);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        sendErrorHandler(err);
      }
    }

    return data;
  };

  const jsonHandler = (data) => {
    if (typeof data !== 'string') {
      return JSON.stringify(data);
    }

    return data;
  };

  return { compressHandler, decompressHandler, jsonHandler };
};

export default useCompression;
