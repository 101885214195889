import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styles from './sass/AlertsNotionPopUp.module.scss';
import { ReactComponent as IconMegaphone } from '../../assets/images/icons/alertsIcons/Megaphone.svg';
import { ReactComponent as IconClose } from '../../assets/images/icons/alertsIcons/close.svg';
import TextField from '../TextField/TextField';
import { NOTE_LENGTH, NOTIFICATION_DELAY_TIMES, NOTIFICATIONS_TITLE } from '../../constants/alerts';
import { setAlertCheckedNotifications } from '../../store/alerts/slice';
import { notionSoundsController } from '../ChartContainer/utils/alertHelper';

let timeout;

const AlertsNotionPopUp = ({ item, index }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [display, setDisplay] = useState(false);

  const marqueReadAlertHandler = (id) => {
    setDisplay(false);
    dispatch(setAlertCheckedNotifications(id));
    clearTimeout(timeout);
  };

  useEffect(() => {
    const displayTimeout = setTimeout(() => {
      setDisplay(true);
    }, +`${index + 1}000`);

    return () => clearTimeout(displayTimeout);
  }, []);

  useEffect(() => {
    if (display) {
      notionSoundsController();
      timeout = setTimeout(() => {
        marqueReadAlertHandler(item?.id || 0);
      }, NOTIFICATION_DELAY_TIMES.TICKS);
    }
  }, [display]);

  useEffect(() => {
    setDisplay(false);
  }, [item]);

  return display && (
    <div
      className={`${styles.alertsNotionWrapper} ${!item.params.note.length ? styles.alertsNotionWrapperEmptyNote : ''}`}
    >
      <div className={styles.megaphoneWrapper}>
        <IconMegaphone />
      </div>
      <div className={styles.alertsNotionPopUpContent}>
        <TextField
          text={`${item.params.symbol} ${NOTIFICATIONS_TITLE[item.params.direction]}`}
          styleType="alertsNotionTitle"
        />
        {!!item.params.note.length && (
        <div className={styles.textWrapper}>
          <TextField text={t('note')} styleType="alertsNotionTitle" />
          <TextField
            text={item.params.note.length <= NOTE_LENGTH
              ? item.params.note : `${item.params.note.slice(0, NOTE_LENGTH)}...`}
            styleType="alertsNotionDescription"
          />
        </div>
        )}
      </div>
      <div
        className={styles.close}
        onClick={() => marqueReadAlertHandler(item.alertId)}
        aria-hidden
      >
        <IconClose />
      </div>
    </div>
  );
};

AlertsNotionPopUp.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    alertId: PropTypes.number.isRequired,
    params: PropTypes.shape({
      symbol: PropTypes.string,
      note: PropTypes.string,
      direction: PropTypes.string,
    })
  }).isRequired,
};

export default AlertsNotionPopUp;
