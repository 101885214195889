import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import styles from './sass/WatchlistTabsBlock.module.scss';
import { ReactComponent as IconEdit } from '../../assets/images/icons/screener/edit_square.svg';
import { ReactComponent as IconRemove } from '../../assets/images/icons/screener/trash.svg';
import TextField from '../TextField/TextField';
import { setModalType } from '../../store/watchlist/actions';
import {
  MODAL_TITLES, PATH_TYPE, POPUP_POSITIONS, TEMPLATE_TYPE
} from '../../constants/screener';
import { moreButtons, moreButtonsTarget } from '../../constants/watchlist';
import { DashboardItemsDialogActionTypes, DialogItemDataTypes } from '../DashboardItemsDialog/types/constants';
import { DasboardsItemsDropdownTypes, DialogItemEntityType } from '../DashboardsItemsDropdown/types/constants';

const MorePopUp = ({
  setMoreVisible, isList,
  buttonsList, additionalStyle, callBack, allowDelete,
  position, isDashboard, lineStyles, theme, selectedDashboard, actionOpenDialog,
  setIsShow
}) => {
  const route = useRouteMatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const showDeleteButton = allowDelete ? moreButtons : moreButtonsTarget;
  const buttons = buttonsList || showDeleteButton;
  const check = (title) => {
    let item;
    if (title === t('delete')) {
      item = (<IconRemove className={styles.popUpIcon} />);
    } else {
      item = (<IconEdit className={styles.popUpIcon} />);
    }
    return item;
  };

  const setModalTypeHandler = (type) => {
    const typeNumber = selectedDashboard.type === TEMPLATE_TYPE.WATCHLIST
      ? DialogItemDataTypes.DEEP_LIST : DialogItemDataTypes.SCREENER;
    if (route?.path !== PATH_TYPE.DASHBOARD) {
      if (type === 'Edit') dispatch(setModalType(MODAL_TITLES.RENAME));
      else dispatch(setModalType(type));
    } else {
      if (!isDashboard) {
        callBack();
        setMoreVisible(false);
      }
      if (type === 'Edit') {
        actionOpenDialog(DashboardItemsDialogActionTypes.EDIT, isDashboard ? 4 : typeNumber,
          DialogItemEntityType.ITEM, DasboardsItemsDropdownTypes.SAVED, selectedDashboard);
        setMoreVisible(false);
        setIsShow(false);
      }
      if (type === 'Delete') {
        actionOpenDialog(DashboardItemsDialogActionTypes.DELETE, isDashboard ? 4 : typeNumber,
          DialogItemEntityType.ITEM, DasboardsItemsDropdownTypes.SAVED, selectedDashboard);
        setMoreVisible(false);
        setIsShow(false);
      }
    }
  };

  return (
    <>
      <div
        style={lineStyles}
        className={
          `${styles.moreModal}
         ${isList ? styles.moreModalList : ''}
         ${additionalStyle ? styles[additionalStyle] : ''}
         ${position === POPUP_POSITIONS.BOTTOM ? styles.moreModalBottom : ''}
         ${theme}
        `
        }
      >
        {buttons.map((item) => item.enabled && (
          <div
            key={item.title}
            aria-hidden="true"
            className={styles.moreItemWrapper}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setModalTypeHandler(t(item.title));
            }}
          >
            {item.icon || check(item.title)}
            <TextField text={t(item.title)} styleType={item.textClass} />
          </div>
        ))}
      </div>
    </>
  );
};

MorePopUp.propTypes = {
  allowDelete: PropTypes.bool,
  isList: PropTypes.bool,
  position: PropTypes.string,
  setMoreVisible: PropTypes.func.isRequired,
  callBack: PropTypes.func,
  buttonsList: PropTypes.arrayOf(PropTypes.shape({})),
  additionalStyle: PropTypes.string,
  isDashboard: PropTypes.bool,
  lineStyles: PropTypes.shape({}),
  theme: PropTypes.string,
  selectedDashboard: PropTypes.shape({
    type: PropTypes.string
  }),
  actionOpenDialog: PropTypes.func,
  setIsShow: PropTypes.func,
};

MorePopUp.defaultProps = {
  allowDelete: true,
  position: 'top',
  callBack: () => undefined,
  isList: false,
  buttonsList: null,
  additionalStyle: null,
  isDashboard: false,
  lineStyles: null,
  theme: '',
  selectedDashboard: {},
  actionOpenDialog: () => undefined,
  setIsShow: () => undefined
};
export default MorePopUp;
