import React from 'react';

import TextField from '../TextField/TextField';
import { ReactComponent as CheckedIcon } from '../../assets/images/icons/Check.svg';
import styles from './sass/SelectGroupDropFilter.module.scss';
import { TCommonItem } from '../FilterScreen/Types';

interface IScrollDropFilter {
  items: TCommonItem[];
  handlerSelectedFilter: (filterItem: TCommonItem) => void;
  selectedFilter: TCommonItem;
  id: string;
}

const ScrollDropFilter = ({
  items, handlerSelectedFilter, selectedFilter, id
}: IScrollDropFilter): React.ReactElement => (
  <div
    id={id}
    className={styles.contentScroll}
  >
    {items.length > 0 && items?.map((item, index) => (
      <div
        key={[item.name, index].join('_')}
        className={styles.optionContainer}
        onClick={() => {
          handlerSelectedFilter(item);
        }}
        onKeyDown={() => handlerSelectedFilter(item)}
        role="button"
        aria-hidden="true"
        tabIndex={0}
        id={`${item.id}`}
      >
        {selectedFilter?.columnId === item.columnId
          ? <CheckedIcon /> : <div className={styles.emptyBox} />}
        <TextField
          styleType={selectedFilter?.columnId === item.columnId
            ? 'selectedFiltersOptionActive' : 'selectedFiltersOption'}
          text={item.name || ''}
          key={[item.name, index].join('_')}
        />
      </div>
    ))}
  </div>
);

export default ScrollDropFilter;
