import React from 'react';

import styles from '../sass/FilterScreen.module.scss';

const FilterHeaderBlock = ({ text }: { text: string }): React.ReactElement => (
  <div className={styles.col}>
    <h2 className={styles.filter_title}>
      {text}
    </h2>
  </div>
);

export default FilterHeaderBlock;
