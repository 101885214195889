import React from 'react';
import PropTypes from 'prop-types';
import ClientOnlyPortal from '../../components/ClientOnlyPortal/ClientOnlyPortal';

import styles from './sass/ModalLayout.module.scss';

const ModalLayout = ({ children, typeModal, classModal }) => (
  <ClientOnlyPortal selector="#modal">
    <div className={`${styles.container} ${classModal ? styles[classModal] : ''}`}>
      <div className={`${styles.form} ${typeModal ? styles[typeModal] : ''}`}>
        {children}
      </div>
    </div>
  </ClientOnlyPortal>
);

export default ModalLayout;

ModalLayout.defaultProps = {
  typeModal: '',
  classModal: ''
};

ModalLayout.propTypes = {
  children: PropTypes.node.isRequired,
  typeModal: PropTypes.string,
  classModal: PropTypes.string,
};
