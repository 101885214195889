import EventEmitter from 'events';
import { WS_CHANNELS } from '../constants/socketConnection';
import { getFromLocalStorage } from '../utils/storageWorks';
import { ACCESS_TOKEN } from '../constants/storage';
import {
  SocketConnectionServiceProps, SocketConnectionService, AuthCallback, TDisconnectCallback, StringMap,
  ScreenerQueryMessage,
} from './Interfaces';
import UniqIdTabService from './UniqIdTabService';
import TokenService, { ITokenService } from './TokenService';

import {
  MarketStageEndpoints,
  MarketEndpoints,
} from '../components/MarketDropdown/types/MarketEnums';

export default class SocketConnection implements SocketConnectionService {
  URL: string;

  private authId: string;

  public authorizedCallback: AuthCallback;

  public connectionRemove: TDisconnectCallback;

  public cleanCallback?: () => void;

  private socket: WebSocket | null;

  private tabId: string;

  public socketId: string;

  messageEmitter: EventEmitter;

  private TryTimeout: NodeJS.Timeout | null;

  private TokenService: ITokenService;

  constructor({
    userId,
    authorizedCallback,
    connectionRemoveCallback,
    cleanCallback
  }: SocketConnectionServiceProps) {
    const envEndpoints = process.env.REACT_APP_ENVIRONMENT === 'staging' ? MarketStageEndpoints : MarketEndpoints;
    this.URL = envEndpoints.REGULAR_MARKET;
    this.authId = userId as string;
    this.authorizedCallback = authorizedCallback;
    this.connectionRemove = connectionRemoveCallback;
    this.tabId = getFromLocalStorage('deviceId') || UniqIdTabService.getUniqId();
    this.socketId = UniqIdTabService.generateUniqId();
    this.cleanCallback = cleanCallback;
    this.socket = null;
    this.TryTimeout = null;
    this.messageEmitter = new EventEmitter();
    this.TokenService = new TokenService();
  }

  subscribeToEvents = (): void => {
    if (this.socket) {
      this.socket.onclose = () => {
        this.unsubscribe();
      };

      this.socket.onerror = () => {
        if (this.cleanCallback) {
          this.cleanCallback();
        }
      };

      this.socket.onmessage = (event) => {
        this.getMessage(event);
      };
    }
  };

  getMessage(event: MessageEvent): void {
    const resp = JSON.parse(event?.data);
    switch (resp?.event) {
      case WS_CHANNELS.AUTHORIZED:
        this.authorizedCallback(true, this);
        break;
      case WS_CHANNELS.PING_AUTH:
        this.sendAuth();
        break;
      case WS_CHANNELS.CONNECTION_EXCEEDED:
        this.connectionRemove(resp?.message);
        break;
      case WS_CHANNELS.CLOSE_CONNECTION:
        this.authorizedCallback(false, this);
        break;
      default:
        this.messageEmitter.emit(resp?.event, resp?.data);
        break;
    }
  }

  sendMessage(event: string, data: StringMap | ScreenerQueryMessage): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ event, data }));
    }
  }

  sendAuth(): void {
    const authToken = window.localStorage.getItem(ACCESS_TOKEN);
    if (!authToken) return;
    const message = { messageId: this.authId, token: authToken, socketId: this.socketId };
    this.sendMessage(WS_CHANNELS.PONG_AUTH, message);
  }

  unsubscribe(): void {
    if (this.socket) {
      this.socket.close();
      // eslint-disable-next-line no-console
      console.log('unsubscribe ==>', this.socketId);
      this.authorizedCallback(false, this);
      if (this.cleanCallback) {
        this.cleanCallback();
      }
    }
  }

  async subscribe(): Promise<void> {
    let authToken = window.localStorage.getItem(ACCESS_TOKEN);
    const isValidToken = await this.TokenService.checkToken();
    if (!isValidToken) {
      authToken = await this.TokenService.updateAccessToken();
    }

    const containProtocol = `${this.socketId}***${authToken}***${this.tabId}`;
    try {
      if (this.TryTimeout) {
        clearTimeout(this.TryTimeout);
        this.TryTimeout = null;
      }
      // eslint-disable-next-line no-console
      console.log('subscribe ==>', this.socketId);
      this.socket = new WebSocket(`${this.URL}/ws-data`, [containProtocol]);
      if (this.socket) {
        this.subscribeToEvents();
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('WebSocket Connection error =>', e);
    }
  }

  getSocket(): WebSocket {
    return this.socket as WebSocket;
  }
}
