import React from 'react';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardComponents.module.scss';

const ScreenerFooter = ({
  selectedCount,
  currentCount,
  activeSymbolIndex
} : {
  selectedCount: number,
  currentCount: number,
  activeSymbolIndex?: number
}): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.screenWidgetFooter}>
      {t('selectedWidgetFooter', { selectedCount, currentCount, activeSymbolIndex })}
    </div>
  );
};

export default ScreenerFooter;
