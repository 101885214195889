import { useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { findIndexItem } from '../helpers';
import {
  getFindItem, changeItem, getFilter, generateFilter, generateSimpleFilter
} from '../helperFilter';
import filtersConstants from '../../constants/filters';
import { setRequestFilters } from '../../store/newScreen/actions';
import useNewScreen from './useNewScreen';
import { setStateFiltersAction, setRequestStateAction, setGroupFiltersViewAction } from '../../store/filters/slice';
import { resetItemsDashboardScreenerAction, setMarketsFilterScreenerAction } from '../../store/screener/actions';
import RootStateTypes from '../../store/RootStateTypes';
import {
  TFilterGroupe, TFilterItem, TNativeFilter, TValueItem
} from '../../components/FilterScreen/Types';

interface IUseFilters {
  (
    disabled: boolean, headerFilter: string,
    activeGroup: number, setActiveGroup: (id: number) => void,
    isVisibleFilters: boolean,
    newScreenFlag: boolean,
    openModal: string | undefined
  ): {
    clearAll: () => void;
    removeFilter: (id: number, groupIndex: number, mapType: number) => void;
    handlerGroup: (id: number) => void;
    addNewGroupHandler: () => void;
    removeGroup: (id: number) => void;
    setFilter: (filter: TNativeFilter) => void;
  };
}

const useFilters: IUseFilters = (
  disabled, headerFilter,
  activeGroup, setActiveGroup,
  isVisibleFilters, newScreenFlag, openModal
) => {
  let timeout: NodeJS.Timeout;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const columnsList = useSelector((state: RootStateTypes) => state.screenerState.columnsList, shallowEqual);
  const itemId = useSelector((state: RootStateTypes) => state.newScreenState.itemId, shallowEqual);
  const selectedScreenId = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.selectedScreenId, shallowEqual
  );
  const filterList = useSelector((state: RootStateTypes) => state.screenerState.filterList, shallowEqual);
  const initialFilterList = useSelector((state: RootStateTypes) => state.screenerState.initialFilterList, shallowEqual);
  const requestState = useSelector((state: RootStateTypes) => state.filtersState?.requestState);
  const { updateFiltersState } = useNewScreen();
  const initialGroup = {
    name: `${t('basket')} 1`,
    items: []
  };

  const [resetStates, setResetStates] = useState(false);

  const createStateFilters = (lists: TFilterGroupe[]): TFilterItem[] => {
    const newStateFilters: TFilterItem[] = [];
    if (lists?.length > 0) {
      lists.forEach((group) => {
        group?.items.forEach((filter) => {
          newStateFilters.push(filter);
        });
      });
    }
    return newStateFilters;
  };

  const createItemGroup = (arrayFilters: TFilterItem[], item: TNativeFilter): TFilterItem => {
    const filter = getFindItem(arrayFilters, item);
    let newFilter = {};
    if (Object.keys(filter)?.length > 0) {
      newFilter = generateFilter(filter, item, columnsList);
    } else {
      newFilter = getFilter(columnsList, item);
    }

    return newFilter as TFilterItem;
  };

  const createSimpleFilter = (arrayFilters: TFilterItem[], indexItem: number, item: TNativeFilter): TFilterItem => {
    const filter = arrayFilters[indexItem];
    const indexColum = findIndexItem(columnsList, filtersConstants.index, filter.id);
    const newFilter = generateSimpleFilter(filter, item, columnsList, indexColum);
    return newFilter;
  };

  const createItemsGroupFilters = (group: TNativeFilter[], arrayFilters: TFilterItem[]): TNativeFilter[] => {
    const itemsGroup: TNativeFilter[] = [];
    if (group?.length > 0) {
      group?.forEach((item) => {
        const indexItem = findIndexItem(arrayFilters, filtersConstants.id, item[0]);
        let newFilter = {};
        if (indexItem === -1) {
          newFilter = createItemGroup(arrayFilters, item);
          itemsGroup.push(newFilter as TNativeFilter);
        } else {
          newFilter = createSimpleFilter(arrayFilters, indexItem, item);
          itemsGroup.push(newFilter as TNativeFilter);
        }
      });
    }
    return itemsGroup;
  };

  const selectedFilter = (array: TFilterItem[]): void => {
    const groupFilters = [];
    if (requestState?.length > 0) {
      requestState.forEach((item, index) => {
        const blockGroup = {
          name: `${t('basket')} ${index + 1}`,
          items: createItemsGroupFilters(item as TNativeFilter[], array)
        };
        groupFilters.push(blockGroup);
      });
    } else {
      groupFilters.push(initialGroup);
    }
    dispatch(setGroupFiltersViewAction(groupFilters));
  };

  const setItem = (updatedFilter: TFilterItem, item: TNativeFilter): TValueItem[] => {
    const newArray: TValueItem[] = [];
    updatedFilter.fVal.forEach((el) => {
      const lastFilterElement = item[2] as number[];
      const indexElement = lastFilterElement?.findIndex((element) => element === el.value);
      const newObj = JSON.parse(JSON.stringify(el)) as TValueItem;
      if (indexElement !== -1) {
        newObj.checked = true;
      } else {
        newObj.checked = false;
      }
      newArray.push(newObj);
    });
    return newArray;
  };

  const updateItemHandle = (newState: TFilterItem, item: TNativeFilter): TFilterItem => {
    const updatedFilter = JSON.parse(JSON.stringify(newState)) as TFilterItem;
    if (updatedFilter.fType === filtersConstants.range
      || updatedFilter.fType?.split(':')[0] === filtersConstants.date) {
      const [valueStart, valueEnd] = item[2] as number[];
      if (item[1] === 3) {
        updatedFilter.typeMap = 3;
      }
      updatedFilter.fVal[0].value = valueStart;
      updatedFilter.fVal[1].value = valueEnd;
    } else if (updatedFilter.fType === filtersConstants.check) {
      updatedFilter.fVal = setItem(updatedFilter, item);
    } else if (updatedFilter.fType === filtersConstants.multiselectSearch) {
      const indexRequestItem = item[1] !== 4 ? 'items' : 'itemsExclude';
      // eslint-disable-next-line
      // @ts-ignore
      updatedFilter[indexRequestItem] = setItem(updatedFilter, item);
    }

    return updatedFilter;
  };

  const updateStateHandler = (state: TNativeFilter[], newState: TFilterItem[]): TFilterItem[] => {
    const array = JSON.parse(JSON.stringify(state)) as TNativeFilter[];
    const arrayState = JSON.parse(JSON.stringify(newState)) as TFilterItem[];
    array?.forEach((item) => {
      const indexItem = findIndexItem(arrayState, filtersConstants.id, item[0]);
      if (indexItem === -1) {
        const updateItem = changeItem(arrayState, item) as TFilterItem;
        const filterIndex = findIndexItem(arrayState, filtersConstants.name, updateItem.name) as number;
        if (filterIndex !== -1) {
          arrayState[filterIndex] = updateItem;
        }
      } else {
        arrayState[indexItem] = updateItemHandle(arrayState[indexItem], item);
      }
    });
    return arrayState;
  };

  const applyStateFilters = (array: TFilterItem[], stateRequest?: TNativeFilter[][] | null): void => {
    let newState = JSON.parse(JSON.stringify(array));
    const state = stateRequest || requestState;
    if (state?.length) {
      newState = updateStateHandler(state[activeGroup] as TNativeFilter[], newState);
    }
    dispatch(setStateFiltersAction(newState));
  };

  const saveFilterToScreen = (id: number, data: TNativeFilter[][]): void => {
    if (headerFilter !== t('createNewScreen') && headerFilter !== t('createNewPreset') && !disabled) {
      const newData = { ...itemId?.data };
      newData.filters = data;
      updateFiltersState(id, newData);
    }
  };

  const removeFilter = (id: number, groupIndex: number, mapType: number) => {
    const newState = JSON.parse(JSON.stringify(requestState)) as TNativeFilter[][];
    let indexItem = newState[groupIndex]?.findIndex((element) => element[0] === id);
    if (mapType !== -1) {
      indexItem = newState[groupIndex]?.findIndex((element) => element[0] === id && element[1] === mapType);
    }
    if (indexItem !== -1) {
      newState[groupIndex]?.splice(indexItem, 1);
    }
    dispatch(setRequestStateAction(newState));

    const state = createStateFilters(JSON.parse(JSON.stringify(filterList)));
    applyStateFilters(state, newState);

    if (headerFilter !== t('createNewScreen') && headerFilter !== t('createNewPreset')) {
      saveFilterToScreen(itemId.id, newState);
    }
  };

  const clearAll = (): void => {
    const screenId = selectedScreenId;
    dispatch(setRequestStateAction([]));
    if (screenId) {
      timeout = setTimeout(() => {
        if (headerFilter !== t('createNewScreen') && headerFilter !== t('createNewPreset')) {
          saveFilterToScreen(screenId, []);
        }
        clearTimeout(timeout);
      }, 200);
    }
  };

  const addNewGroupHandler = (): void => {
    const newRequestArray = [...requestState];
    newRequestArray.push([]);
    dispatch(setRequestStateAction(newRequestArray));
    if (headerFilter !== t('createNewScreen') && headerFilter !== t('createNewPreset')) {
      saveFilterToScreen(itemId.id, newRequestArray as TNativeFilter[][]);
    }
  };

  const handlerGroup = (id: number): void => {
    setActiveGroup(id);
  };

  const removeGroup = (id: number): void => {
    const newRequestArray = [...requestState];
    newRequestArray?.splice(id, 1);
    dispatch(setRequestStateAction(newRequestArray));
    if (headerFilter !== t('createNewScreen') && headerFilter !== t('createNewPreset')) {
      saveFilterToScreen(itemId.id, newRequestArray as TNativeFilter[][]);
    }
    setActiveGroup(0);
  };

  const setFilter = (filter: TNativeFilter): void => {
    const newRequestArray = JSON.parse(JSON.stringify(requestState)) as TNativeFilter[][];
    if (newRequestArray?.length > 0) {
      const indexItem = newRequestArray[activeGroup]?.findIndex((element) => {
        let checkItem = element[0] === filter[0] && element[1] === filter[1];
        if (filter[1] === filtersConstants.TYPE_PERIOD || element[1] === filtersConstants.TYPE_PERIOD) {
          checkItem = element[0] === filter[0];
        }
        return checkItem;
      });
      const endOfFilter = filter[2] as number[];
      if (indexItem === -1) {
        if (endOfFilter?.length > 0) {
          newRequestArray[activeGroup].push(filter as TNativeFilter);
        }
      } else if (endOfFilter?.length > 0) {
        newRequestArray[activeGroup][indexItem] = filter as TNativeFilter;
      } else if (endOfFilter?.length === 0) {
        newRequestArray[activeGroup]?.splice(indexItem, 1);
      }
    } else {
      newRequestArray.push([filter as TNativeFilter]);
    }
    dispatch(setRequestStateAction(newRequestArray));
    dispatch(resetItemsDashboardScreenerAction());

    if (headerFilter !== t('createNewScreen') && headerFilter !== t('createNewPreset')) {
      saveFilterToScreen(itemId.id, newRequestArray);
    }
  };

  useEffect(() => {
    dispatch(setGroupFiltersViewAction([initialGroup]));
  }, []);

  useEffect(() => {
    if (!itemId) return;
    if ((Object.keys(itemId)?.length > 0 && !isVisibleFilters && !openModal)
      || (isVisibleFilters && !newScreenFlag)) {
      dispatch(setRequestStateAction(itemId?.data?.filters));
    }
  }, [itemId, newScreenFlag, isVisibleFilters, openModal]);

  useEffect(() => {
    if (!isVisibleFilters) {
      setActiveGroup(0);
      setResetStates(false);
    }
  }, [isVisibleFilters, headerFilter]);

  useEffect(() => {
    if ((headerFilter === t('createNewScreen') && newScreenFlag && isVisibleFilters)
      || (headerFilter === t('createNewPreset') && newScreenFlag && isVisibleFilters)) {
      dispatch(setRequestStateAction([]));
    }
  }, [headerFilter, newScreenFlag, isVisibleFilters]);

  useEffect(() => {
    if (requestState && requestState?.length === 0) {
      const state = createStateFilters(JSON.parse(JSON.stringify(initialFilterList)));
      applyStateFilters(state);
      selectedFilter(state);
    } else if (requestState && requestState?.length > 0) {
      const state = createStateFilters(JSON.parse(JSON.stringify(filterList)));
      applyStateFilters(state);
      selectedFilter(state);
    }
  }, [requestState, filterList, initialFilterList]);

  useEffect(() => {
    if (filterList?.length > 0 && isVisibleFilters) {
      const state = createStateFilters(JSON.parse(JSON.stringify(filterList)));
      selectedFilter(state);
    }
  }, [requestState, activeGroup]);

  useEffect(() => {
    if (filterList?.length > 0 && isVisibleFilters) {
      const state = createStateFilters(JSON.parse(JSON.stringify(filterList)));
      applyStateFilters(state);
    }
  }, [activeGroup, isVisibleFilters, filterList]);

  const showResult = (state: TNativeFilter[]): void => {
    dispatch(setMarketsFilterScreenerAction({ data: state }));
  };

  useEffect(() => {
    if ((headerFilter === t('createNewScreen') && newScreenFlag && !resetStates)
      || (headerFilter === t('createNewPreset') && newScreenFlag && !resetStates)) {
      dispatch(setGroupFiltersViewAction([initialGroup]));
      const state = createStateFilters(JSON.parse(JSON.stringify(initialFilterList)));
      applyStateFilters(state);
      selectedFilter(state);
      setResetStates(true);
    }
    dispatch(setRequestFilters(requestState));

    showResult(requestState);
  }, [requestState, initialFilterList, headerFilter, newScreenFlag, resetStates]);

  return {
    clearAll,
    removeFilter,
    handlerGroup,
    addNewGroupHandler,
    removeGroup,
    setFilter
  };
};

export default useFilters;
