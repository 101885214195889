import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../utils/hooks/useTheme';
import { FlagTooltipDarkStyles, FlagTooltipStyles } from '../ScreenerTooltipStyles';
import { DATE_CONSTANTS } from '../../../constants/dateConstants';
import { DEFAULT_TIMEZONE } from '../../../constants/account';
import { getDeltaForCurrentTime } from '../../../utils/helpers';
import ThemeVariants from '../../../constants/theme';
import { ReactComponent as DividentIcon } from '../../../assets/images/icons/screener/Database.svg';
import { ReactComponent as FlagIcon } from '../../../assets/images/icons/screener/Flag.svg';
import styles from '../sass/ScreenerTable.module.scss';

const InfoComponent = ({
  dividendValue, earningsValue,
  earningTimeValue, widgetId
}) => {
  const { t } = useTranslation();
  const [showTooltipEarn, setShowTooltipEarn] = useState(false);
  const [showTooltipDividend, setShowTooltipDividend] = useState(false);
  const { theme } = useTheme();

  const getTooltip = (icon, name, date, time) => {
    return (
      <div className={`${styles.infoTooltip} ${theme}`}>
        <div className={styles.infoHead}>
          {icon}
          <span className={styles.infoTitle}>{name}</span>
        </div>
        <div className={styles.infoDate}>
          {moment(date * DATE_CONSTANTS.SHIFT_DATE).tz(DEFAULT_TIMEZONE).format('MMM DD, YYYY')}
          {time && <span className={styles.infoTime}>{time === 'N/A' ? '' : time}</span>}
        </div>
      </div>
    );
  };

  const showEarn = () => {
    setShowTooltipEarn(true);
  };

  const handleCloseEarn = () => {
    setShowTooltipEarn(false);
  };

  const showDividend = () => {
    setShowTooltipDividend(true);
  };

  const handleCloseDividend = () => {
    setShowTooltipDividend(false);
  };

  const hideAll = () => {
    handleCloseEarn();
    handleCloseDividend();
  };

  const clean = (element) => {
    element?.removeEventListener('scroll', hideAll);
  };

  useEffect(() => {
    const table = document.getElementById(widgetId ? `tableScroll_${widgetId}` : 'tableScroll');
    if (showTooltipEarn || showTooltipDividend) {
      table?.addEventListener('scroll', hideAll, false);
    } else {
      clean(table);
    }

    return () => table?.removeEventListener('scroll', hideAll);
  }, [showTooltipEarn, showTooltipDividend]);

  useEffect(() => {
    const table = document.getElementById(widgetId ? `tableScroll_${widgetId}` : 'tableScroll');
    return () => clean(table);
  }, []);

  const stopFiring = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const checkEarning = () => {
    if (!earningsValue) return null;
    const deltaTime = getDeltaForCurrentTime(earningsValue);
    if (deltaTime >= 0 && deltaTime <= DATE_CONSTANTS.TWO_WEEK) {
      const earningsIcon = (
        <FlagIcon
          onClick={(event) => {
            stopFiring(event);
            showEarn();
          }}
          className={`${styles.extraEarnings} ${deltaTime <= DATE_CONSTANTS.WEEK && styles.quick}`}
        />
      );

      return (
        <Tooltip
          open={showTooltipEarn}
          onOpen={showEarn}
          onClose={handleCloseEarn}
          arrow
          componentsProps={theme === ThemeVariants.DARK_MODE ? FlagTooltipDarkStyles : FlagTooltipStyles}
          title={getTooltip(earningsIcon, t('earningsTitle'), earningsValue, earningTimeValue)}
        >
          {earningsIcon}
        </Tooltip>
      );
    }
    return null;
  };

  const checkDividend = () => {
    if (!dividendValue) return null;
    const deltaTime = getDeltaForCurrentTime(dividendValue);
    if (deltaTime >= 0 && deltaTime <= DATE_CONSTANTS.TWO_WEEK) {
      const dividendIcon = (
        <DividentIcon
          onClick={(event) => {
            stopFiring(event);
            showDividend();
          }}
          className={styles.extraDividents}
        />
      );

      return (
        <Tooltip
          open={showTooltipDividend}
          onOpen={showDividend}
          onClose={handleCloseDividend}
          arrow
          componentsProps={theme === ThemeVariants.DARK_MODE ? FlagTooltipDarkStyles : FlagTooltipStyles}
          title={getTooltip(dividendIcon, t('dividendTitle'), dividendValue)}
        >
          {dividendIcon}
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <div className={styles.infoBlock}>
      {checkEarning()}
      {checkDividend()}
    </div>
  );
};

export default InfoComponent;

InfoComponent.propTypes = {
  earningsValue: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  dividendValue: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf([null])]),
  earningTimeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  widgetId: PropTypes.string,
};

InfoComponent.defaultProps = {
  earningsValue: null,
  dividendValue: null,
  earningTimeValue: null,
  widgetId: '',
};
