import { ReactComponent as IconCopy } from '../../../assets/images/icons/screener/copy.svg';
import { ReactComponent as IconEdit } from '../../../assets/images/icons/screener/edit_square.svg';
import { ReactComponent as IconDelete } from '../../../assets/images/icons/screener/trash.svg';
import { ReactComponent as IconAddToFolder } from '../../../assets/images/icons/screener/add_to_folder.svg';
import { ReactComponent as IconRemove } from '../../../assets/images/icons/screener/removeIcon.svg';

export enum DasboardsItemsDropdownTypes {
  SAVED,
  PRESET,
}

export enum AddMenuActions {
  ADD_NEW,
  ADD_FOLDER
}

export enum ItemMenuActions {
  EDIT,
  DUPLICATE,
  DELETE,
  MOVE,
  REMOVE,
  SHARE
}

export enum DashboardItemsDialogTypes {
  ADD_NEW,
  EDIT,
  MOVE_TO,
  DUPLICATE,
  DELETE,
  REMOVE
}

export enum DialogItemEntityType {
  FOLDER,
  ITEM
}

export const DASHBOARD_ITEMS_DROPDOWN_TABS = [
  {
    label: 'Saved',
    value: DasboardsItemsDropdownTypes.SAVED,
  },
  {
    label: 'Presets',
    value: DasboardsItemsDropdownTypes.PRESET,
  },
];

export const COMPARE_SETTINGS = {
  numeric: true,
  caseFirst: 'upper',
  sensitivity: 'base',
  ignorePunctuation: true
};

export const ITEM_MENU = [
  {
    id: 'duplicate-menu',
    label: 'duplicate',
    action: ItemMenuActions.DUPLICATE,
    additionalClass: '',
    icon: IconCopy
  },
  {
    id: 'move-menu',
    label: 'moveTo',
    action: ItemMenuActions.MOVE,
    additionalClass: '',
    icon: IconAddToFolder
  },
  {
    id: 'edit-menu',
    label: 'edit',
    action: ItemMenuActions.EDIT,
    icon: IconEdit,
    additionalClass: '',
  },
  {
    id: 'delete-menu',
    label: 'delete',
    action: ItemMenuActions.DELETE,
    additionalClass: 'delete',
    icon: IconDelete
  },
  {
    id: 'remove-menu',
    label: 'remove',
    action: ItemMenuActions.REMOVE,
    additionalClass: '',
    icon: IconRemove
  },
  {
    id: 'share-menu',
    label: 'share',
    action: ItemMenuActions.SHARE,
    additionalClass: '',
    icon: IconCopy
  },
];

export const MENU_FOLDER = [
  ITEM_MENU[2],
  ITEM_MENU[3],
];

export const MENU_FOLDER_ADMIN = [
  ITEM_MENU[0],
  ITEM_MENU[2],
  ITEM_MENU[3],
];

export const MENU_SUBITEM = [
  ITEM_MENU[5],
  ITEM_MENU[0],
  ITEM_MENU[1],
  ITEM_MENU[2],
  ITEM_MENU[4],
  ITEM_MENU[3],
];

export const MENU_ITEM = [
  ITEM_MENU[5],
  ITEM_MENU[0],
  ITEM_MENU[1],
  ITEM_MENU[2],
  ITEM_MENU[3],
];
export const MENU_SUBITEM_DASHBOARDS = [
  ITEM_MENU[0],
  ITEM_MENU[1],
  ITEM_MENU[2],
  ITEM_MENU[4],
  ITEM_MENU[3],
];

export const MENU_ITEM_DASHBOARDS = [
  ITEM_MENU[0],
  ITEM_MENU[1],
  ITEM_MENU[2],
  ITEM_MENU[3],
];

export const MENU_ITEM_NO_ADMIN = [
  ITEM_MENU[0]
];

export const MENU_ITEM_FAVOURITES = [
  ITEM_MENU[5],
  ITEM_MENU[1]
];

export const MENU_ITEM_SCREENER_WATCHLISTS = [
  ITEM_MENU[1]
];

export const MENU_ITEM_TARGET_LISTS = [
  ITEM_MENU[5],
  ITEM_MENU[1],
  ITEM_MENU[2],
];
export const defaultSort = { sortValue: -1 };

export const SEARCH_FIELD_ID = 'watchlistSearchField';
