import { Dispatch } from 'redux';
import { TABLE_ITEM_COLORS_SETTINGS, TV_EVENTS } from '../../../constants/screener';
import {
  LAST_RESOLUTION,
  OVERRIDES, OVERRIDES_FONTS, OVERRIDES_GRID,
  PANE_PROPERTIES_BACKGROUND,
  PANE_PROPERTIES_TYPE,
  RIGHT_OFFSET,
  RIGHT_OFFSET_BAR_SPACING,
  SOLID,
} from '../../../constants/tvWidgetOptions';
import { buttonGenerator } from './chartingHelper';
import { changeStatsSourceHandler } from './tradingViewHelper';
import SHORT_CUTS_LIST, { TABLE_LISTENER_ID } from '../../../constants/shortcuts';
import ThemeVariants from '../../../constants/theme';
import { IAutoSaveSubscribe } from '../types/Interfaces';
import {
  EventName, CurrentWidget,
  UpdateHandler, PointUpdate,
  CurrentTheme, PointUpdateContext, LayoutsList,
} from '../types/Types';
import AccountStateType from '../../../store/account/AccountStateType';
import { currentAlertData } from '../constants/constants';

export const autoSaveSubscribe = (
  { tradingViewWidget }: IAutoSaveSubscribe
): void => {
  if (tradingViewWidget) {
    tradingViewWidget?.onChartReady(() => {
      tradingViewWidget.subscribe(TV_EVENTS.AUTO_SAVE_EVENT as EventName, () => {
        // const currentMode = tradingViewWidget?.activeChart()?.getPanes()[0]?.getMainSourcePriceScale()?.getMode();
        // updateStoreHandler(PRICE_SCALE_SETTINGS, currentMode);
        tradingViewWidget.saveChartToServer(// eslint-disable-next-line no-console
          () => { console.log('Saved layout'); }, // eslint-disable-next-line no-console
          () => { console.log('Failed saving layout'); },
        );
      });
    });
  }
};

export const timeScaleApplier = (
  newWidget: CurrentWidget, updateStoreHandler: UpdateHandler
): void => {
  newWidget.activeChart().getTimeScale().defaultRightOffset().subscribe((number) => {
    updateStoreHandler(RIGHT_OFFSET_BAR_SPACING, number);
  });
  newWidget.activeChart().getTimeScale().defaultRightOffsetPercentage().subscribe((number) => {
    updateStoreHandler(RIGHT_OFFSET, number);
  });
};

export const timeScaleListener = (
  newWidget: CurrentWidget, updateStoreHandler: UpdateHandler
): void => {
  newWidget.activeChart().getTimeScale().defaultRightOffset().subscribe((number) => {
    updateStoreHandler(RIGHT_OFFSET_BAR_SPACING, number);
  });
  newWidget.activeChart().getTimeScale().defaultRightOffsetPercentage().subscribe((number) => {
    updateStoreHandler(RIGHT_OFFSET, number);
  });
};

export const headerReadySubscribe = (
  newWidget: CurrentWidget,
  setAlertActionFromTop: (widget: CurrentWidget) => void,
  userLastState: AccountStateType['userSettings'],
  dispatch: Dispatch,
  updateStoreHandler: UpdateHandler,
  widgetId?: string,
): void => {
  newWidget.headerReady().then(() => {
    if (newWidget && newWidget?.activeChart) {
      buttonGenerator(
        newWidget,
        setAlertActionFromTop,
        widgetId,
      );
      const panes = newWidget?.activeChart().getPanes();
      if (panes?.length === 1) {
        panes[0]?.getMainSourcePriceScale()?.setMode(userLastState?.priceScale || 1);
      }
      newWidget?.activeChart()?.onIntervalChanged()?.subscribe(null, (intervalChanged) => {
        changeStatsSourceHandler(dispatch, intervalChanged, updateStoreHandler);
        updateStoreHandler(LAST_RESOLUTION, intervalChanged);
      });
    }
  });
};

export const alertSubscribe = (
  newWidget: CurrentWidget,
  setPointsValue: PointUpdate,
  setPointsForClick: PointUpdateContext
): void => {
  newWidget?.onChartReady(() => {
    newWidget?.subscribe(TV_EVENTS.MOUSE_DOWN as EventName, (e: string, cb: string): void => {
      setPointsValue(e, cb, newWidget);
    });
    newWidget?.subscribe(TV_EVENTS.DRAWING_EVENT as EventName, (id: string, cb: string): void => {
      setPointsValue(id, cb, newWidget);
    });
    newWidget.activeChart().crossHairMoved().subscribe(
      null,
      ({ time, price }) => {
        if (currentAlertData.data) return;
        setPointsForClick([time, price]);
      }
    );
    newWidget?.onContextMenu((unixtime, price) => {
      setPointsForClick([unixtime, price]);
      return [];
    });
  });
};

export const unbindAll = (tradingViewWidget: CurrentWidget): void => {
  tradingViewWidget?.unsubscribe(TV_EVENTS.DRAWING_EVENT as EventName, () => null);
  tradingViewWidget?.unsubscribe(TV_EVENTS.MOUSE_DOWN as EventName, () => null);
};

export const shortcutSubscribe = (
  newWidget: CurrentWidget,
  setAlertActionFromTop: (widget: CurrentWidget, isEmptyPriceValue: boolean) => void
): void => {
  newWidget?.onShortcut(SHORT_CUTS_LIST.SHOW_ALERT_MODAL, () => setAlertActionFromTop(newWidget, true));
  newWidget?.onShortcut(SHORT_CUTS_LIST.TABLE_FOCUS, () => {
    document?.getElementById(TABLE_LISTENER_ID)?.focus();
  });
};

const overwriteObjectHandle = (lastState: AccountStateType['userSettings']) => {
  const { chartProperties, mainSourceProperties } = lastState;

  const panePropOverwrites = {
    'paneProperties.background': chartProperties?.paneProperties?.background || '',
    'paneProperties.backgroundGradientEndColor': chartProperties?.paneProperties?.backgroundGradientEndColor || '',
    'paneProperties.backgroundGradientStartColor': chartProperties?.paneProperties?.backgroundGradientStartColor || '',
    'paneProperties.backgroundType': chartProperties?.paneProperties?.backgroundType || '',
    'paneProperties.gridLinesMode': chartProperties?.paneProperties?.gridLinesMode || '',
    'paneProperties.horzGridProperties.color': chartProperties?.paneProperties?.horzGridProperties?.color || '',
    'paneProperties.legendProperties.backgroundTransparency':
      chartProperties?.paneProperties?.legendProperties?.backgroundTransparency || '',
    'paneProperties.legendProperties.showBackground':
      chartProperties?.paneProperties?.legendProperties?.showBackground || '',
    'paneProperties.legendProperties.showBarChange':
      chartProperties?.paneProperties?.legendProperties?.showBarChange || '',
    'paneProperties.legendProperties.showSeriesOHLC':
      chartProperties?.paneProperties?.legendProperties?.showSeriesOHLC || '',
    'paneProperties.legendProperties.showSeriesTitle':
      chartProperties?.paneProperties?.legendProperties?.showSeriesTitle || '',
    'paneProperties.legendProperties.showStudyArguments':
      chartProperties?.paneProperties?.legendProperties?.showStudyArguments || '',
    'paneProperties.legendProperties.showStudyTitles':
      chartProperties?.paneProperties?.legendProperties?.showStudyTitles || '',
    'paneProperties.legendProperties.showStudyValues':
      chartProperties?.paneProperties?.legendProperties?.showStudyValues || '',
    'paneProperties.legendProperties.showVolume': chartProperties?.paneProperties?.legendProperties?.showVolume || '',
    'paneProperties.separatorColor': chartProperties?.paneProperties?.separatorColor || '',
    'paneProperties.vertGridProperties.color': chartProperties?.paneProperties?.vertGridProperties?.color || '',
  };

  const mainPropOverwrites = {
    'mainSeriesProperties.areaStyle.color1': mainSourceProperties?.areaStyle?.color1 || '',
    'mainSeriesProperties.areaStyle.color2': mainSourceProperties?.areaStyle?.color2 || '',
    'mainSeriesProperties.areaStyle.linecolor': mainSourceProperties?.areaStyle?.linecolor || '',
    'mainSeriesProperties.areaStyle.linestyle': mainSourceProperties?.areaStyle?.linestyle || '',
    'mainSeriesProperties.areaStyle.linewidth': mainSourceProperties?.areaStyle?.linewidth || '',
    'mainSeriesProperties.areaStyle.priceSource': mainSourceProperties?.areaStyle?.priceSource || '',
    'mainSeriesProperties.areaStyle.transparency': mainSourceProperties?.areaStyle?.transparency || '',
    'mainSeriesProperties.barStyle.barColorsOnPrevClose': mainSourceProperties?.barStyle?.barColorsOnPrevClose || '',
    'mainSeriesProperties.barStyle.dontDrawOpen': mainSourceProperties?.barStyle?.dontDrawOpen || '',
    'mainSeriesProperties.barStyle.downColor': mainSourceProperties?.barStyle?.downColor || '',
    'mainSeriesProperties.barStyle.thinBars': mainSourceProperties?.barStyle?.thinBars || '',
    'mainSeriesProperties.barStyle.upColor': mainSourceProperties?.barStyle?.upColor || '',
    'mainSeriesProperties.baselineStyle.baseLevelPercentage':
      mainSourceProperties?.baselineStyle?.baseLevelPercentage || '',
    'mainSeriesProperties.baselineStyle.baselineColor': mainSourceProperties?.baselineStyle?.baselineColor || '',
    'mainSeriesProperties.baselineStyle.bottomFillColor1': mainSourceProperties?.baselineStyle?.bottomFillColor1 || '',
    'mainSeriesProperties.baselineStyle.bottomFillColor2': mainSourceProperties?.baselineStyle?.bottomFillColor2 || '',
    'mainSeriesProperties.baselineStyle.bottomLineColor': mainSourceProperties?.baselineStyle?.bottomLineColor || '',
    'mainSeriesProperties.baselineStyle.bottomLineWidth': mainSourceProperties?.baselineStyle?.bottomLineWidth || '',
    'mainSeriesProperties.baselineStyle.priceSource': mainSourceProperties?.baselineStyle?.priceSource || '',
    'mainSeriesProperties.baselineStyle.topFillColor1': mainSourceProperties?.baselineStyle?.topFillColor1 || '',
    'mainSeriesProperties.baselineStyle.topFillColor2': mainSourceProperties?.baselineStyle?.topFillColor2 || '',
    'mainSeriesProperties.baselineStyle.topLineColor': mainSourceProperties?.baselineStyle?.topLineColor || '',
    'mainSeriesProperties.baselineStyle.topLineWidth': mainSourceProperties?.baselineStyle?.topLineWidth || '',
    'mainSeriesProperties.baselineStyle.transparency': mainSourceProperties?.baselineStyle?.transparency || '',
    'mainSeriesProperties.bidAsk.askLineColor': mainSourceProperties?.bidAsk?.askLineColor || '',
    'mainSeriesProperties.bidAsk.bidLineColor': mainSourceProperties?.bidAsk?.bidLineColor || '',
    'mainSeriesProperties.bidAsk.lineStyle': mainSourceProperties?.bidAsk?.lineStyle || '',
    'mainSeriesProperties.bidAsk.lineWidth': mainSourceProperties?.bidAsk?.lineWidth || '',
    'mainSeriesProperties.bidAsk.visible': mainSourceProperties?.bidAsk?.visible || '',
    'mainSeriesProperties.candleStyle.barColorsOnPrevClose':
      mainSourceProperties?.candleStyle?.barColorsOnPrevClose || '',
    'mainSeriesProperties.candleStyle.borderColor': mainSourceProperties?.candleStyle?.borderColor || '',
    'mainSeriesProperties.candleStyle.borderDownColor': mainSourceProperties?.candleStyle?.borderDownColor || '',
    'mainSeriesProperties.candleStyle.borderUpColor': mainSourceProperties?.candleStyle?.borderUpColor || '',
    'mainSeriesProperties.candleStyle.downColor': mainSourceProperties?.candleStyle?.downColor || '',
    'mainSeriesProperties.candleStyle.drawBody': mainSourceProperties?.candleStyle?.drawBody || '',
    'mainSeriesProperties.candleStyle.drawBorder': mainSourceProperties?.candleStyle?.drawBorder || '',
    'mainSeriesProperties.candleStyle.drawWick': mainSourceProperties?.candleStyle?.drawWick || '',
    'mainSeriesProperties.candleStyle.upColor': mainSourceProperties?.candleStyle?.upColor || '',
    'mainSeriesProperties.candleStyle.wickColor': mainSourceProperties?.candleStyle?.wickColor || '',
    'mainSeriesProperties.candleStyle.wickDownColor': mainSourceProperties?.candleStyle?.wickDownColor || '',
    'mainSeriesProperties.candleStyle.wickUpColor': mainSourceProperties?.candleStyle?.wickUpColor || '',
    'mainSeriesProperties.columnStyle.barColorsOnPrevClose':
      mainSourceProperties?.columnStyle?.barColorsOnPrevClose || '',
    'mainSeriesProperties.columnStyle.downColor': mainSourceProperties?.columnStyle?.downColor || '',
    'mainSeriesProperties.columnStyle.priceSource': mainSourceProperties?.columnStyle?.priceSource || '',
    'mainSeriesProperties.columnStyle.upColor': mainSourceProperties?.columnStyle?.upColor || '',
    'mainSeriesProperties.haStyle.barColorsOnPrevClose': mainSourceProperties?.haStyle?.barColorsOnPrevClose || '',
    'mainSeriesProperties.haStyle.borderColor': mainSourceProperties?.haStyle?.borderColor || '',
    'mainSeriesProperties.haStyle.borderDownColor': mainSourceProperties?.haStyle?.borderDownColor || '',
    'mainSeriesProperties.haStyle.borderUpColor': mainSourceProperties?.haStyle?.borderUpColor || '',
    'mainSeriesProperties.haStyle.downColor': mainSourceProperties?.haStyle?.downColor || '',
    'mainSeriesProperties.haStyle.drawBody': mainSourceProperties?.haStyle?.drawBody || '',
    'mainSeriesProperties.haStyle.drawBorder': mainSourceProperties?.haStyle?.drawBorder || '',
    'mainSeriesProperties.haStyle.drawWick': mainSourceProperties?.haStyle?.drawWick || '',
    'mainSeriesProperties.haStyle.upColor': mainSourceProperties?.haStyle?.upColor || '',
    'mainSeriesProperties.haStyle.wickColor': mainSourceProperties?.haStyle?.wickColor || '',
    'mainSeriesProperties.haStyle.wickDownColor': mainSourceProperties?.haStyle?.wickDownColor || '',
    'mainSeriesProperties.haStyle.wickUpColor': mainSourceProperties?.haStyle?.wickUpColor || '',
    'mainSeriesProperties.highLowAvgPrice.averageClosePriceLabelVisible':
      mainSourceProperties?.highLowAvgPrice?.averageClosePriceLabelVisible || '',
    'mainSeriesProperties.highLowAvgPrice.averageClosePriceLineVisible':
      mainSourceProperties?.highLowAvgPrice?.averageClosePriceLineVisible || '',
    'mainSeriesProperties.highLowAvgPrice.averagePriceLineColor':
      mainSourceProperties?.highLowAvgPrice?.averagePriceLineColor || '',
    'mainSeriesProperties.highLowAvgPrice.averagePriceLineWidth':
      mainSourceProperties?.highLowAvgPrice?.averagePriceLineWidth || '',
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLabelsVisible':
      mainSourceProperties?.highLowAvgPrice?.highLowPriceLabelsVisible || '',
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLinesColor':
      mainSourceProperties?.highLowAvgPrice?.highLowPriceLinesColor || '',
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLinesVisible':
      mainSourceProperties?.highLowAvgPrice?.highLowPriceLinesVisible || '',
    'mainSeriesProperties.highLowAvgPrice.highLowPriceLinesWidth':
      mainSourceProperties?.highLowAvgPrice?.highLowPriceLinesWidth || '',
    'mainSeriesProperties.hiloStyle.borderColor': mainSourceProperties?.hiloStyle?.borderColor || '',
    'mainSeriesProperties.hiloStyle.color': mainSourceProperties?.hiloStyle?.color || '',
    'mainSeriesProperties.hiloStyle.labelColor': mainSourceProperties?.hiloStyle?.labelColor || '',
    'mainSeriesProperties.hiloStyle.showBorders': mainSourceProperties?.hiloStyle?.showBorders || '',
    'mainSeriesProperties.hiloStyle.showLabels': mainSourceProperties?.hiloStyle?.showLabels || '',
    'mainSeriesProperties.hlcAreaStyle.closeLineColor': mainSourceProperties?.hlcAreaStyle?.closeLineColor || '',
    'mainSeriesProperties.hlcAreaStyle.closeLineStyle': mainSourceProperties?.hlcAreaStyle?.closeLineStyle || '',
    'mainSeriesProperties.hlcAreaStyle.closeLineWidth': mainSourceProperties?.hlcAreaStyle?.closeLineWidth || '',
    'mainSeriesProperties.hlcAreaStyle.closeLowFillColor': mainSourceProperties?.hlcAreaStyle?.closeLowFillColor || '',
    'mainSeriesProperties.hlcAreaStyle.highCloseFillColor':
      mainSourceProperties?.hlcAreaStyle?.highCloseFillColor || '',
    'mainSeriesProperties.hlcAreaStyle.highLineColor': mainSourceProperties?.hlcAreaStyle?.highLineColor || '',
    'mainSeriesProperties.hlcAreaStyle.highLineStyle': mainSourceProperties?.hlcAreaStyle?.highLineStyle || '',
    'mainSeriesProperties.hlcAreaStyle.highLineWidth': mainSourceProperties?.hlcAreaStyle?.highLineWidth || '',
    'mainSeriesProperties.hlcAreaStyle.lowLineColor': mainSourceProperties?.hlcAreaStyle?.lowLineColor || '',
    'mainSeriesProperties.hlcAreaStyle.lowLineStyle': mainSourceProperties?.hlcAreaStyle?.lowLineStyle || '',
    'mainSeriesProperties.hlcAreaStyle.lowLineWidth': mainSourceProperties?.hlcAreaStyle?.lowLineWidth || '',
    'mainSeriesProperties.hollowCandleStyle.borderColor': mainSourceProperties?.hollowCandleStyle?.borderColor || '',
    'mainSeriesProperties.hollowCandleStyle.borderDownColor':
      mainSourceProperties?.hollowCandleStyle?.borderDownColor || '',
    'mainSeriesProperties.hollowCandleStyle.borderUpColor':
      mainSourceProperties?.hollowCandleStyle?.borderUpColor || '',
    'mainSeriesProperties.hollowCandleStyle.downColor': mainSourceProperties?.hollowCandleStyle?.downColor || '',
    'mainSeriesProperties.hollowCandleStyle.drawBody': mainSourceProperties?.hollowCandleStyle?.drawBody || '',
    'mainSeriesProperties.hollowCandleStyle.drawBorder': mainSourceProperties?.hollowCandleStyle?.drawBorder || '',
    'mainSeriesProperties.hollowCandleStyle.drawWick': mainSourceProperties?.hollowCandleStyle?.drawWick || '',
    'mainSeriesProperties.hollowCandleStyle.upColor': mainSourceProperties?.hollowCandleStyle?.upColor || '',
    'mainSeriesProperties.hollowCandleStyle.wickColor': mainSourceProperties?.hollowCandleStyle?.wickColor || '',
    'mainSeriesProperties.hollowCandleStyle.wickDownColor':
      mainSourceProperties?.hollowCandleStyle?.wickDownColor || '',
    'mainSeriesProperties.hollowCandleStyle.wickUpColor': mainSourceProperties?.hollowCandleStyle?.wickUpColor || '',
    'mainSeriesProperties.kagiStyle.downColor': mainSourceProperties?.kagiStyle?.downColor || '',
    'mainSeriesProperties.kagiStyle.downColorProjection': mainSourceProperties?.kagiStyle?.downColorProjection || '',
    'mainSeriesProperties.kagiStyle.upColor': mainSourceProperties?.kagiStyle?.upColor || '',
    'mainSeriesProperties.kagiStyle.upColorProjection': mainSourceProperties?.kagiStyle?.upColorProjection || '',
    'mainSeriesProperties.lineStyle.color': mainSourceProperties?.lineStyle?.color || '',
    'mainSeriesProperties.lineStyle.linestyle': mainSourceProperties?.lineStyle?.linestyle || '',
    'mainSeriesProperties.lineStyle.linewidth': mainSourceProperties?.lineStyle?.linewidth || '',
    'mainSeriesProperties.lineStyle.priceSource': mainSourceProperties?.lineStyle?.priceSource || '',
    'mainSeriesProperties.minTick': mainSourceProperties?.minTick || '',
    'mainSeriesProperties.pbStyle.borderDownColor': mainSourceProperties?.pbStyle?.borderDownColor || '',
    'mainSeriesProperties.pbStyle.borderDownColorProjection':
      mainSourceProperties?.pbStyle?.borderDownColorProjection || '',
    'mainSeriesProperties.pbStyle.borderUpColor': mainSourceProperties?.pbStyle?.borderUpColor || '',
    'mainSeriesProperties.pbStyle.borderUpColorProjection':
      mainSourceProperties?.pbStyle?.borderUpColorProjection || '',
    'mainSeriesProperties.pbStyle.downColor': mainSourceProperties?.pbStyle?.downColor || '',
    'mainSeriesProperties.pbStyle.downColorProjection': mainSourceProperties?.pbStyle?.downColorProjection || '',
    'mainSeriesProperties.pbStyle.upColor': mainSourceProperties?.pbStyle?.upColor || '',
    'mainSeriesProperties.pbStyle.upColorProjection': mainSourceProperties?.pbStyle?.upColorProjection || '',
    'mainSeriesProperties.pnfStyle.downColor': mainSourceProperties?.pnfStyle?.downColor || '',
    'mainSeriesProperties.pnfStyle.downColorProjection': mainSourceProperties?.pnfStyle?.downColorProjection || '',
    'mainSeriesProperties.pnfStyle.upColor': mainSourceProperties?.pnfStyle?.upColor || '',
    'mainSeriesProperties.pnfStyle.upColorProjection': mainSourceProperties?.pnfStyle?.upColorProjection || '',
    'mainSeriesProperties.prevClosePriceLineColor': mainSourceProperties?.prevClosePriceLineColor || '',
    'mainSeriesProperties.prevClosePriceLineWidth': mainSourceProperties?.prevClosePriceLineWidth || '',
    'mainSeriesProperties.priceAxisProperties.alignLabels':
      mainSourceProperties?.priceAxisProperties?.alignLabels || '',
    'mainSeriesProperties.priceAxisProperties.indexedTo100':
      mainSourceProperties?.priceAxisProperties?.indexedTo100 || '',
    'mainSeriesProperties.priceAxisProperties.isInverted': mainSourceProperties?.priceAxisProperties?.isInverted || '',
    'mainSeriesProperties.priceAxisProperties.log': mainSourceProperties?.priceAxisProperties?.log || '',
    'mainSeriesProperties.priceAxisProperties.percentage': mainSourceProperties?.priceAxisProperties?.percentage || '',
    'mainSeriesProperties.priceLineColor': mainSourceProperties?.priceLineColor || '',
    'mainSeriesProperties.priceLineWidth': mainSourceProperties?.priceLineWidth || '',
    'mainSeriesProperties.renkoStyle.borderDownColor': mainSourceProperties?.renkoStyle?.borderDownColor || '',
    'mainSeriesProperties.renkoStyle.borderDownColorProjection':
      mainSourceProperties?.renkoStyle?.borderDownColorProjection || '',
    'mainSeriesProperties.renkoStyle.borderUpColor': mainSourceProperties?.renkoStyle?.borderUpColor || '',
    'mainSeriesProperties.renkoStyle.borderUpColorProjection':
      mainSourceProperties?.renkoStyle?.borderUpColorProjection || '',
    'mainSeriesProperties.renkoStyle.downColor': mainSourceProperties?.renkoStyle?.downColor || '',
    'mainSeriesProperties.renkoStyle.downColorProjection': mainSourceProperties?.renkoStyle?.downColorProjection || '',
    'mainSeriesProperties.renkoStyle.upColor': mainSourceProperties?.renkoStyle?.upColor || '',
    'mainSeriesProperties.renkoStyle.upColorProjection': mainSourceProperties?.renkoStyle?.upColorProjection || '',
    'mainSeriesProperties.renkoStyle.wickDownColor': mainSourceProperties?.renkoStyle?.wickDownColor || '',
    'mainSeriesProperties.renkoStyle.wickUpColor': mainSourceProperties?.renkoStyle?.wickUpColor || '',
    'mainSeriesProperties.showCountdown': mainSourceProperties?.showCountdown || '',
    'mainSeriesProperties.showPrevClosePriceLine': mainSourceProperties?.showPrevClosePriceLine || '',
    'mainSeriesProperties.showPriceLine': mainSourceProperties?.showPriceLine || '',
    'mainSeriesProperties.statusViewStyle.showExchange': mainSourceProperties?.statusViewStyle?.showExchange || '',
    'mainSeriesProperties.statusViewStyle.showInterval': mainSourceProperties?.statusViewStyle?.showInterval || '',
    'mainSeriesProperties.statusViewStyle.symbolTextSource':
      mainSourceProperties?.statusViewStyle?.symbolTextSource || '',
    'mainSeriesProperties.style': mainSourceProperties?.style || 1,
    'mainSeriesProperties.visible': mainSourceProperties?.visible || '',
  };

  const scalePropOverwrite = {
    'scalesProperties.axisHighlightColor': chartProperties?.scalesProperties?.axisHighlightColor || '',
    'scalesProperties.axisLineToolLabelBackgroundColorActive':
      chartProperties?.scalesProperties?.axisLineToolLabelBackgroundColorActive || '',
    'scalesProperties.axisLineToolLabelBackgroundColorCommon':
      chartProperties?.scalesProperties?.axisLineToolLabelBackgroundColorCommon || '',
    'scalesProperties.crosshairLabelBgColorDark': chartProperties?.scalesProperties?.crosshairLabelBgColorDark || '',
    'scalesProperties.crosshairLabelBgColorLight': chartProperties?.scalesProperties?.crosshairLabelBgColorLight || '',
    'scalesProperties.fontSize': chartProperties?.scalesProperties?.fontSize || '',
    'scalesProperties.lineColor': chartProperties?.scalesProperties?.lineColor || '',
    'scalesProperties.seriesLastValueMode': chartProperties?.scalesProperties?.seriesLastValueMode || '',
    'scalesProperties.showBidAskLabels': chartProperties?.scalesProperties?.showBidAskLabels || '',
    'scalesProperties.showPrePostMarketPriceLabel':
      chartProperties?.scalesProperties?.showPrePostMarketPriceLabel || '',
    'scalesProperties.showPriceScaleCrosshairLabel':
      chartProperties?.scalesProperties?.showPriceScaleCrosshairLabel || '',
    'scalesProperties.showSeriesLastValue': chartProperties?.scalesProperties?.showSeriesLastValue || '',
    'scalesProperties.showStudyLastValue': chartProperties?.scalesProperties?.showStudyLastValue || '',
    'scalesProperties.showStudyPlotLabels': chartProperties?.scalesProperties?.showStudyPlotLabels || '',
    'scalesProperties.showSymbolLabels': chartProperties?.scalesProperties?.showSymbolLabels || '',
    'scalesProperties.showTimeScaleCrosshairLabel':
      chartProperties?.scalesProperties?.showTimeScaleCrosshairLabel || '',
    'scalesProperties.textColor': chartProperties?.scalesProperties?.textColor || ''
  };

  return { ...panePropOverwrites, ...mainPropOverwrites, ...scalePropOverwrite };
};

export const onChangeTradingViewTheme = (
  tradingViewWidget: CurrentWidget | null,
  theme: ThemeVariants,
  lastState: AccountStateType['userSettings'],
  overwriteFlag: boolean,
  layoutId: number,
  layoutsList: LayoutsList[],
  layoutFlag: string
): void => {
  if (tradingViewWidget) {
    tradingViewWidget?.onChartReady(() => {
      tradingViewWidget.changeTheme(theme.substring(0, theme.indexOf('-')) as CurrentTheme).then(() => {
        if (layoutFlag && layoutId && !overwriteFlag) {
          const layout = layoutsList.find(
            (item) => item.content.id === layoutId
          );
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tradingViewWidget.loadChartFromServer(layout?.content);
          return;
        }

        const lastTVSettings = {
          ...OVERRIDES_FONTS,
          ...OVERRIDES,
          ...OVERRIDES_GRID
        };

        const darkMode = {
          [PANE_PROPERTIES_BACKGROUND]: TABLE_ITEM_COLORS_SETTINGS.TRADING_VIEW,
          [PANE_PROPERTIES_TYPE]: SOLID,
          ...OVERRIDES_FONTS,
          ...OVERRIDES,
          ...OVERRIDES_GRID
        };

        const overwriteData = Object.keys(lastTVSettings).length === 0
        && theme === ThemeVariants.DARK_MODE ? darkMode : lastTVSettings;

        if (overwriteFlag) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          tradingViewWidget?.applyOverrides(overwriteData);
        } else {
          tradingViewWidget?.applyOverrides(overwriteObjectHandle(lastState));
        }
      });
    });
  }
};

export const applyOverridesHandler = (
  lastState: AccountStateType['userSettings'],
  tradingViewWidget: CurrentWidget | null,
  theme: ThemeVariants,
  layoutFlag: string
): undefined => {
  if (layoutFlag) {
    return;
  }

  if (typeof lastState === 'string') {
    const lastTVSettings = {
      ...OVERRIDES_FONTS,
      ...OVERRIDES,
      ...OVERRIDES_GRID
    };

    const darkMode = {
      [PANE_PROPERTIES_BACKGROUND]: TABLE_ITEM_COLORS_SETTINGS.TRADING_VIEW,
      [PANE_PROPERTIES_TYPE]: SOLID,
      ...OVERRIDES_FONTS,
      ...OVERRIDES,
      ...OVERRIDES_GRID
    };

    tradingViewWidget?.onChartReady(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tradingViewWidget.applyOverrides(theme !== ThemeVariants.DARK_MODE ? lastTVSettings : darkMode);
    });

    return;
  }

  tradingViewWidget?.onChartReady(() => {
    tradingViewWidget.applyOverrides(overwriteObjectHandle(lastState));
  });
};
