import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/AlertsList.module.scss';
import AlertsListHeader from './AlertsListHeader';
import AlertsListBody from './AlertsListBody';
import AlertsListBottom from './AlertsListBottom';
import useAlerts from '../ChartContainer/hooks/useAlerts';

const AlertsList = ({ isMenuOpened }) => {
  const {
    getAlertsNotificationList, alertAction,
    toggleAlertStatus, checkIsAlertActive,
    updateAlertNotificationsButch, clearAlerts
  } = useAlerts();

  useEffect(() => {
    return () => getAlertsNotificationList();
  }, []);

  return (
    <div className={`${styles.list} ${isMenuOpened ? styles.menuOpened : ''}`}>
      <AlertsListHeader />
      <AlertsListBody
        getAlertsNotificationList={getAlertsNotificationList}
        updateViewedAlerts={updateAlertNotificationsButch}
        checkActive={checkIsAlertActive}
        alertAction={alertAction}
        toggleAlertStatus={toggleAlertStatus}
        clearAlerts={clearAlerts}
      />
      <AlertsListBottom />
    </div>
  );
};
AlertsList.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
};

export default AlertsList;
