import { SortType } from '../components/MultichartView/types/Enums';

export const defaultColumnCount = 3;

export const rowsViewCount = 2;

export const overScanRowCount = 1;

export const firstMultiChartRow = 0;

export const MultiChartFiltersColumns = {
  [SortType.Alphabetically]: [[17, 0]],
  [SortType.DCR]: [[8, 1]],
  [SortType.RV20]: [[11, 1]],
  [SortType['Price % Change']]: [[218, 1]]
};

export const multiChartDefaultRange = [0, 12];

export const MULTI_CHART_COLUMNS_COUNT = 7;

export const MULTI_CHART_ADDITIONAL_COLUMNS = [20, 21, 33, 62, 34];
