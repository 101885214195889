import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import { ICategoryBlock } from './types/EducationComponentsInterfaces';
import { Categories } from './types/EducationComponentsTypes';

const CategoryBlock = ({ categories, allCategories }: ICategoryBlock): React.ReactElement => (
  <div className={styles.cardCategoryWrapper}>
    {categories.slice(0, 2).map((category) => {
      const filteredCategories: Categories | undefined = allCategories?.find(
        (value) => value.name === category
      );
      return (
        <span
          key={category}
          className={styles.cardCategory}
          style={{ backgroundColor: filteredCategories?.category_color || '#849BF6' }}
        >
          {category}
        </span>
      );
    })}
  </div>
);

export default CategoryBlock;
