import React from 'react';
import PropTypes, { oneOfType } from 'prop-types';
import stylesModal from './sass/NewScreenModals.module.scss';
import { ReactComponent as FolderIcon } from '../../assets/images/icons/screener/folder.svg';
import TextField from '../TextField/TextField';

const NewScreenFolderItem = ({
  currentPresetItem, currentItem, currentSubItem
}) => (
  <div className={stylesModal.itemFolderWrapperActive}>
    <FolderIcon />
    {currentSubItem?.id ? (
      <TextField text={currentSubItem.folder.name} styleType="watchListTitleActive" />
    ) : (
      <div className={stylesModal.textBox}>
        <TextField text={currentItem.title || currentPresetItem.title} styleType="watchListTitleActive" />
        <TextField
          text={currentItem.countStock
            || currentPresetItem.countStock}
          styleType="watchListDescriptionActive"
        />
      </div>
    )}
  </div>
);

NewScreenFolderItem.propTypes = {
  currentSubItem: oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      title: PropTypes.string
    }),
    PropTypes.string,
  ]),
  currentItem: oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      countStock: PropTypes.string,
      type: PropTypes.string,
      favorites: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.func
  ]),
  currentPresetItem: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      countStock: PropTypes.string,
      favorites: PropTypes.bool,
      items: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    PropTypes.func
  ])
};

NewScreenFolderItem.defaultProps = {
  currentItem: {
    id: 0,
    title: '',
    countStock: '',
    type: '',
    favorites: false,
    items: [{}],
  },
  currentPresetItem: {
    id: 0,
    title: '',
    countStock: '',
    type: '',
    favorites: false,
    items: [{}],
  },
  currentSubItem: {} || PropTypes.string,
};

export default NewScreenFolderItem;
