import { useEffect, useRef } from 'react';

import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  GridStack,
  GridStackElement,
  GridStackNode,
  GridStackOptions
} from 'gridstack';
import { GRID_COLUMNS, GRID_ROWS, DRAG_ELEMENTS_CONSTRAINTS } from '../types/Constants';
import { IUseGridHook } from '../types/DashboardComponentsInterfaces';
import {
  DRAG_MENU_SELECTOR,
  DRAG_ITEM_SELECTOR,
  RENDERED_ITEM_SELECTOR,
  NO_DRAG_SELECTOR,
  WidgetTypes
} from '../../../pages/Dashboards/types/DashboardEnums';
import RootStateTypes from '../../../store/RootStateTypes';
import { setDashboards } from '../../../store/dashboards/slice';
import { Dashboard, Widget } from '../../../pages/Dashboards/types/DashboardTypes';
import {
  getWidgetPoints, prepareNewWidget,
  preparedWidgetClone, getSymbolFromMasterWidget,
  generateGridState, setDragAddMenuElement,
  getWidgetType
} from '../utils/utils';
import UseDashboards from '../../../pages/Dashboards/hooks/UseDashboards';
import { DeepListItemData } from '../../../utils/Types';
import { UNIVERSE_TYPE } from '../../../constants/watchlist';
import { SUBSCRIPTION_TYPE, TEMPLATE_TYPE } from '../../../constants/screener';
import { checkRoleUser } from '../../../utils/userHelper';

const useGridStack: IUseGridHook = (
  parrentRef,
  containerRef,
  dashboard,
  setEnableDrag
) => {
  const gridRef = useRef<GridStack | null>(null);
  const dashboardRef = useRef<Dashboard | null>(null);
  const dashboardsListRef = useRef<Dashboard[] | null>(null);
  const dispatch = useDispatch();
  const { updateDashboard } = UseDashboards();
  const dashboardsList = useSelector((state: RootStateTypes) => state.dashboardsState.dashboards, shallowEqual);
  const tabsList = useSelector((state: RootStateTypes) => state.watchlistState.tabsList, shallowEqual);
  const dashboardsListPresets = useSelector(
    (state: RootStateTypes) => state.dashboardsState.dashboardPresets, shallowEqual
  );
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const setDragableItems = (gridState: number[][]) => {
    DRAG_ELEMENTS_CONSTRAINTS.forEach((item) => {
      setDragAddMenuElement(gridState, item, setEnableDrag);
    });
  };

  const checkEditType = (): boolean => adminRole || (dashboard?.type !== TEMPLATE_TYPE.DASHBOARDS_PRESET);

  const checkEmptySpace = (nodes: GridStackNode[]) => {
    const gridState = generateGridState();
    nodes.forEach((node: GridStackNode) => {
      const y = Math.abs(node?.y as number);
      const x = Math.abs(node?.x as number);
      const w = Math.abs(node?.w as number);
      const h = Math.abs(node?.h as number);
      for (let i = 0; i < GRID_ROWS; i += 1) {
        for (let j = 0; j < GRID_COLUMNS; j += 1) {
          if (i >= y && i < y + h && j >= x && j < x + w) {
            gridState[i][j] = 1;
          }
        }
      }
    });
    setDragableItems(gridState);
  };

  const clone = (event: Event) => {
    const target = event?.target as HTMLElement;
    const dragItem = target?.cloneNode(true) as HTMLDivElement;
    const parrentContainer = document.getElementById('#gridStackNode');
    const parrentRect = parrentContainer?.getBoundingClientRect();
    if (dragItem) {
      if (typeof dragItem?.lastChild === 'object') {
        dragItem?.removeChild(dragItem?.lastChild as ChildNode);
      }
      if (parrentRect) {
        const cellWidth = parrentRect?.width / GRID_COLUMNS;
        const cellHeight = parrentRect?.height / GRID_ROWS;
        const { widthElement, heightElement } = preparedWidgetClone(target, cellWidth, cellHeight);
        const widgetType = getWidgetType(target);
        dragItem.style.display = 'flex';
        dragItem.style.alignItems = 'center';
        dragItem.style.justifyContent = widgetType === WidgetTypes.CHART ? 'flex-start' : 'center';
        dragItem.style.blockSize = 'fit-content';
        dragItem.style.height = `${heightElement}px`;
        dragItem.style.width = `${widthElement}px`;
        dragItem.style.minWidth = `${widthElement}px`;
        dragItem.style.minHeight = `${heightElement}px`;
      }
      return dragItem;
    }
    return undefined as unknown as HTMLElement;
  };

  const destroyCloseEvents = () => {
    document.querySelectorAll('.gridEventDestroy').forEach((itemGrid) => {
      itemGrid?.classList?.remove('closeEvent');
    });
  };

  const updateStore = (clonedDashboard: Dashboard) => {
    const newDashboardsList = dashboardsListRef?.current?.map((dash: Dashboard) => {
      if (dash.id === clonedDashboard?.id
        && checkEditType()) {
        updateDashboard(clonedDashboard as Dashboard);
        return clonedDashboard;
      }
      if (dash.id === clonedDashboard?.id
        && clonedDashboard.type === TEMPLATE_TYPE.DASHBOARDS_PRESET) {
        return clonedDashboard;
      }
      return dash;
    });
    dispatch(setDashboards(newDashboardsList?.filter(
      ((dashboardItem) => dashboardItem?.type === TEMPLATE_TYPE.DASHBOARDS)
    )));
  };

  const initWidgets = () => {
    if (gridRef?.current && dashboardRef?.current) {
      const layout = dashboardRef.current?.data?.widgets.map(
        (widget) => {
          const { points } = widget;
          return {
            ...points,
            el: document.getElementById(`gs-item-${widget.id}`),
          };
        }
      );
      if (layout?.length) {
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line no-underscore-dangle
        gridRef.current._ignoreCB = true;
        gridRef?.current.load(layout, true);
      }
      // eslint-disable-next-line
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      delete gridRef.current._ignoreCB;
      destroyCloseEvents();
    }
  };

  const updateWidgets = () => {
    dashboardRef.current?.data?.widgets.forEach((widget) => {
      const oldWidget = containerRef?.current?.querySelector(`#gs-item-${widget.id}`);
      if (!!oldWidget && !oldWidget?.classList.contains('ui-resizable-autohide')) {
        gridRef?.current?.addWidget(oldWidget as HTMLElement);
        checkEmptySpace(gridRef?.current?.engine?.nodes as GridStackNode[]);
      }
    });
  };

  const removeWidget = (id: string) => {
    const oldWidget = containerRef.current?.querySelector(`#gs-item-${id}`);
    const container = document.getElementById('gridStackNode');
    gridRef?.current?.removeWidget(oldWidget as GridStackElement, false);
    container?.removeChild(oldWidget as Node);
    const clonedDashboard = { ...dashboardRef.current };
    const clonedWidgets = [...clonedDashboard?.data?.widgets as Widget[]];
    const newWidgetList = clonedWidgets.filter((widget) => widget.id !== id);
    clonedDashboard.data = { ...clonedDashboard.data, widgets: newWidgetList };
    updateStore(clonedDashboard as Dashboard);
    checkEmptySpace(gridRef?.current?.engine?.nodes as GridStackNode[]);
  };

  const getNewWidgetDataAndUpdateDashboard = (item: HTMLElement) => {
    const widgetId = item?.getAttribute('id')?.replace('gs-item-', '');
    const points = getWidgetPoints(item);
    const clonedDashboard = { ...dashboardRef.current };
    const clonedWidgets = [...clonedDashboard?.data?.widgets as Widget[]];
    const newWidget = { ...clonedWidgets.find((widget) => widget.id === widgetId) };
    if (newWidget) {
      newWidget.points = points;
    }
    const newWidgetList = clonedWidgets.map((widget) => {
      if (widget.id === widgetId) {
        return newWidget;
      }
      return widget;
    });
    clonedDashboard.data = { ...clonedDashboard.data, widgets: newWidgetList as Widget[] };
    updateStore(clonedDashboard as Dashboard);
  };
  const addEvent = (event: Event, items: GridStackNode[]) => {
    if (!items[0]?.el?.classList.contains(RENDERED_ITEM_SELECTOR)) {
      const defaultTab = tabsList?.find((tab: DeepListItemData) => tab?.data?.subType === UNIVERSE_TYPE);
      let newWidget = prepareNewWidget(items[0], defaultTab?.id);
      const clonedDashboard = { ...dashboardRef.current };
      newWidget = getSymbolFromMasterWidget(newWidget, clonedDashboard as Dashboard);
      const newWidgetList = [...clonedDashboard?.data?.widgets as Widget[]];
      if (newWidget) {
        newWidgetList.push(newWidget as Widget);
        clonedDashboard.data = { ...clonedDashboard.data, ...{ widgets: newWidgetList } };
      }
      updateStore(clonedDashboard as Dashboard);
    }
  };

  const dropEvent = (event: Event, previousWidget: GridStackNode, newWidget: GridStackNode) => {
    if (newWidget?.el) {
      gridRef?.current?.removeWidget(newWidget?.el);
    }
  };

  const dragStopEvent = (event: Event, item: HTMLElement) => {
    getNewWidgetDataAndUpdateDashboard(item);
    destroyCloseEvents();
    checkEmptySpace(gridRef?.current?.engine?.nodes as GridStackNode[]);
  };

  const resizeStopEvent = (event: Event, item: HTMLElement) => {
    const firstChild = item?.childNodes[0] as HTMLElement;
    firstChild?.classList.remove('resizeOverlay');
    getNewWidgetDataAndUpdateDashboard(item);
  };

  const resizeStartEvent = (event: Event, item: HTMLElement) => {
    const firstChild = item?.childNodes[0] as HTMLElement;
    firstChild?.classList.add('resizeOverlay');
  };

  const dragStartEvent = () => {
    document.querySelectorAll('.gridEventDestroy').forEach((item) => {
      item?.classList?.add('closeEvent');
    });
  };

  const initEvents = () => {
    gridRef?.current?.on('added', addEvent);
    gridRef?.current?.on('dropped', dropEvent);

    gridRef?.current?.on('dragstop', dragStopEvent);

    gridRef?.current?.on('resizestop', resizeStopEvent);

    gridRef?.current?.on('resizestart', resizeStartEvent);
    gridRef?.current?.on('dragstart', dragStartEvent);
  };

  const initGrid = () => {
    if (parrentRef?.current && containerRef?.current) {
      if (!gridRef?.current) {
        const parrentRect = parrentRef?.current?.getBoundingClientRect();
        const initialOptions: GridStackOptions = {
          float: true,
          acceptWidgets: true,
          cellHeight: parrentRect.height / GRID_ROWS,
          row: GRID_ROWS,
          column: GRID_COLUMNS,
          margin: 0,
          minRow: GRID_ROWS,
          maxRow: GRID_ROWS,
          handle: '.handle-grid-drag',
          resizable: {
            handles: 'se,sw,ne,nw'
          }
        };

        if (!checkEditType()) {
          initialOptions.disableDrag = true;
          initialOptions.disableResize = true;
        }
        const gridEl = parrentRef?.current?.getElementsByClassName('grid-stack');
        gridRef.current = new GridStack(gridEl[0] as HTMLElement, initialOptions);
        initEvents();
        initWidgets();
      } else {
        initWidgets();
      }
      checkEmptySpace(gridRef?.current?.engine?.nodes as GridStackNode[]);
      GridStack.setupDragIn(
        `.${DRAG_MENU_SELECTOR} .${DRAG_ITEM_SELECTOR}`,
        {
          appendTo: 'body',
          helper: clone,
          cancel: `.${NO_DRAG_SELECTOR}`,
        }
      );
    }
  };

  const adjustGridSize = () => {
    if (parrentRef?.current) {
      const parrentRect = parrentRef?.current?.getBoundingClientRect();
      gridRef?.current?.cellHeight(parrentRect.height / GRID_ROWS, true);
    }
  };

  useEffect(() => {
    const containerRect = containerRef?.current?.getBoundingClientRect();
    if (!gridRef?.current && containerRect?.width && containerRect?.height) {
      initGrid();
    }
  }, [containerRef?.current]);

  useEffect(() => {
    if (dashboardRef?.current && dashboardRef?.current?.id !== dashboard?.id) {
      gridRef?.current?.offAll();
      gridRef?.current?.removeAll();
      gridRef.current = null;
      dashboardRef.current = dashboard as Dashboard;
      initGrid();
    }
  }, [dashboard]);

  useEffect(() => {
    if (dashboard?.data?.widgets
      && dashboard?.data?.widgets?.length > 0
      && (dashboardRef?.current?.id === dashboard?.id)
      && JSON.stringify(dashboard?.data?.widgets) !== JSON.stringify(dashboardRef?.current?.data?.widgets)){
      dashboardRef.current = dashboard;
      updateWidgets();
    }
  }, [dashboard?.data?.widgets]);

  useEffect(() => {
    dashboardsListRef.current = [...dashboardsList, ...dashboardsListPresets];
    dashboardsList.forEach((dash: Dashboard) => {
      if (dash.id === dashboard?.id) {
        dashboardRef.current = dash;
      }
    });
  }, [dashboardsList, dashboardsListPresets]);

  useEffect(() => {
    window.addEventListener('resize', adjustGridSize);
    return () => {
      if (gridRef.current && gridRef?.current?.engine?.addedNodes?.length > 0) {
        gridRef?.current?.offAll();
        gridRef?.current?.removeAll();
        gridRef?.current?.destroy(true);
      }

      dashboardRef.current = null;
      dashboardsListRef.current = null;
      gridRef.current = null;
      window.removeEventListener('resize', adjustGridSize);
    };
  }, []);

  return {
    removeWidget
  };
};

export default useGridStack;
