import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField/TextField';
import { setModalType } from '../../store/watchlist/actions';
import CommonModalInput from './CommonModalInput';
import useWatchList from '../../utils/hooks/useWatchList';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import stylesModal from '../NewScreenModals/sass/NewScreenModals.module.scss';
import NewScreenButtonBlock from '../NewScreenModals/NewScreenButtonBlock';

const ChangeNameTargetList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [disabledFlag, setDisabledFlag] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { updateTargetListName } = useWatchList();
  const targetList = useSelector((state) => state.watchlistState.targetList, shallowEqual);

  useEffect(() => {
    setInputValue(targetList.title);
  }, []);

  const clickHandler = () => {
    if (disabledFlag) return;
    updateTargetListName(targetList.id, inputValue);
  };

  return (
    <ButtonListenerWrapper handleCancel={() => dispatch(setModalType(''))} handleConfirm={clickHandler}>
      <div className={stylesModal.duplicatePresetModal}>
        <TextField text={`${t('editNameOf')} ${targetList.data.defName}`} styleType="targetListModalTitle" />

        <TextField text={`${targetList.data.defName} ${t('name')}`} styleType="inputLabelModal" />
        <CommonModalInput
          type={t('rename')}
          setDisabledFlag={setDisabledFlag}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />

        <NewScreenButtonBlock
          cancelHandler={() => dispatch(setModalType(''))}
          cancelButton={t('cancel')}
          actionHandler={clickHandler}
          actionButton={t('rename')}
          actionDisabled={disabledFlag}
        />
      </div>
    </ButtonListenerWrapper>
  );
};

export default ChangeNameTargetList;
