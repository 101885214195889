import React from 'react';
import { Tooltip } from '@mui/material';
import styles from '../sass/WidgetCharts.module.scss';
import { IDataPointDropDown } from '../types/WidgetChartsInterfaces';
import { ReactComponent as ClearButton } from '../../../assets/images/icons/removeScreen.svg';
import { ReactComponent as IconStroke } from '../../../assets/images/icons/screener/color_stroke.svg';
import DataPointSelector from './DataPointSelector';
import ThemeVariants from '../../../constants/theme';
import { ScreenerTooltipDarkStyles, ScreenerTooltipStyles } from '../../Screener/ScreenerTooltipStyles';
import getInfoTooltip from '../../../utils/getInfoTooltip';
import { useTheme } from '../../../utils/hooks/useTheme';
import { DROPDOWN_STYLES } from '../types/WidgetChartsEnums';
import { TDataPoint } from '../types/WidgetChartsypes';
import useWidgetHeaderHelper from '../hook/useWidgetHeaderHelper';

const DataPointDropDown = ({
  screenerStates,
  setScreenerStates,
  dataPointList,
  objectKey,
  showAxis,
  defaultDataPoints,
  dropDownRef,
  isEditable
}: IDataPointDropDown): React.ReactElement => {
  const { theme } = useTheme();
  const { getWidgetStyles } = useWidgetHeaderHelper(
    screenerStates[`dataPoints${objectKey}IsOpen`],
    DROPDOWN_STYLES.DP_WIDTH_OFFSET,
    dropDownRef,
  );

  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    let dataTitle: string;
    let dataPointIndex: number | null;
    if (objectKey === 'X') {
      dataTitle = defaultDataPoints.dataPointXTitle;
      dataPointIndex = defaultDataPoints.dataPointXIndex;
    } else if (objectKey === 'Y') {
      dataTitle = defaultDataPoints.dataPointYTitle;
      dataPointIndex = defaultDataPoints.dataPointYIndex;
    } else {
      dataTitle = defaultDataPoints.dataPointZTitle;
      dataPointIndex = defaultDataPoints.dataPointZIndex;
    }

    const findDataPoint = dataPointList.find(
      (item: TDataPoint) => item.index === dataPointIndex
    );

    setScreenerStates((prev) => ({
      ...prev,
      screenerIsOpen: false,
      dataPointsXIsOpen: false,
      dataPointsYIsOpen: false,
      folderMenuIsOpen: -1,
      [`dataPoints${objectKey}Title`]: dataTitle,
      [`dataPoints${objectKey}Index`]: dataPointIndex,
      [`dataPoints${objectKey}Type`]: findDataPoint?.type,
    }));
  };

  const handleOtherClose = () => {
    if (objectKey === 'X') {
      return {
        dataPointsYIsOpen: false,
        dataPointsZIsOpen: false,
      };
    }
    if (objectKey === 'Y') {
      return {
        dataPointsXIsOpen: false,
        dataPointsZIsOpen: false,
      };
    }
    return {
      dataPointsXIsOpen: false,
      dataPointsYIsOpen: false,
    };
  };

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setScreenerStates((prev) => ({
      ...prev,
      screenerIsOpen: false,
      [`dataPoints${objectKey}IsOpen`]: !prev[`dataPoints${objectKey}IsOpen`],
      folderMenuIsOpen: -1,
      ...handleOtherClose()
    }));
  };

  return (
    <div className={styles.performanceChartDropdown}>
      <button onMouseDown={(e) => isEditable && handleOpen(e)} type="button" className={styles.screenerButton}>
        {showAxis && <span className={styles.axis}>{objectKey}</span>}
        <Tooltip
          arrow
          componentsProps={theme === ThemeVariants.LIGHT_MODE ? ScreenerTooltipStyles : ScreenerTooltipDarkStyles}
          title={(
            <div>
              {getInfoTooltip(
                screenerStates[`dataPoints${objectKey}Title`] || 'Data Point',
                screenerStates[`dataPoints${objectKey}Title`] || 'Data Point'
              )}
            </div>
          )}
        >
          <span className={styles.titleText}>{screenerStates[`dataPoints${objectKey}Title`] || 'Data Point'}</span>
        </Tooltip>
        {screenerStates[`dataPoints${objectKey}Title`] && isEditable
          && (
            <div
              className={styles.crossIconChevron}
              role="button"
              tabIndex={0}
              onMouseDown={(e) => handleClear(e)}
              aria-label="clear"
            >
              <ClearButton />
            </div>
          )}
        {isEditable
          && (
            <IconStroke
              className={
                `${styles.dropdownIconChevron} ${screenerStates[`dataPoints${objectKey}IsOpen`] && styles.open}`
              }
            />
          )}
      </button>
      {
        screenerStates[`dataPoints${objectKey}IsOpen`] && (
          <DataPointSelector
            dataPointList={dataPointList}
            screenerStates={screenerStates}
            setScreenerStates={setScreenerStates}
            objectKey={objectKey}
            defaultDataPoints={defaultDataPoints}
            widgetStyle={getWidgetStyles()}
          />
        )
      }
    </div>
  );
};

export default DataPointDropDown;
