import React, {
  useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import DcrIcon from '../../DCRIcon/DcrIcon';
import { useTheme } from '../../../utils/hooks/useTheme';
import { darkColorGenerator, styleGenerator } from '../../../utils/convertingDataHelper';
import {
  TABLE_ITEM_COLORS_SETTINGS,
  TABLE_ITEM_SETTINGS,
  VOLUME_COLUMN_INDEX,
  ACTIVE_COLOR,
  SECTOR_IMG_PATH, FALSY_VALUES, CLICKABLE_CELLS_INDEXES
} from '../../../constants/screener';
import { getColumnStyle } from '../../../utils/helpers';
import ThemeVariants from '../../../constants/theme';
import ScreenerVolumeItem from './ScreenerVolumeItem';
import ValueWithDot from './ValueWithDot';
import PercentValue from './PercentValue';
import ItemValue from './ItemValue';
import Icon from '../../Icon/Icon';
import { processArray } from '../../../utils/shortenStringUrl';
import styles from '../sass/ScreenerTable.module.scss';

function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  }, []);

  return ref.current;
}

const TableItem = ({
  value, name, type, handler, colorSettings, item, size, columnIndex,
  volumesHistoryData, statusIndustry, statusSubIndustryIndex, statusSectorIndex, statusGroupIndex,
  alertTable, dataPanelQueryId, dataPanel, widgetId,
  widgetResizeSelector, isNumberColumn
}) => {
  const types = type?.split('_');
  const styleList = types.map((typeItem) => (styles[typeItem] ? styles[typeItem] : ''));
  const showModal = useSelector((state) => state.twitterState.showShareModal, shallowEqual);

  const newValue = typeof value === 'string' && value?.toLowerCase();
  const onClickHAndler = handler ? () => handler() : () => undefined;
  const checkName = name === TABLE_ITEM_COLORS_SETTINGS.COLUMN_NAME;
  const [color, setColor] = useState(
    dataPanelQueryId
      ? TABLE_ITEM_COLORS_SETTINGS.DEFAULT_PANEL_COLOR
      : TABLE_ITEM_COLORS_SETTINGS.DEFAULT_COLOR
  );

  const prevItem = usePrevious(item);
  const { theme } = useTheme();
  const checkStatus = (name === TABLE_ITEM_COLORS_SETTINGS.INDUSTRY_NAME && statusIndustry === 1)
    || (name === TABLE_ITEM_COLORS_SETTINGS.SUB_INDUSTRY_NAME && statusSubIndustryIndex === 1)
    || (name === TABLE_ITEM_COLORS_SETTINGS.SECTOR_NAME && statusSectorIndex === 1)
    || (name === TABLE_ITEM_COLORS_SETTINGS.GROUP_NAME && statusGroupIndex === 1);

  const colorItem = checkStatus ? ACTIVE_COLOR : styleGenerator(colorSettings, theme);
  const colorCell = name === TABLE_ITEM_COLORS_SETTINGS.COLUMN_NAME ? { color } : colorItem;
  const checkCandle = type === TABLE_ITEM_SETTINGS.TYPE_CANDLE && value !== TABLE_ITEM_SETTINGS.VALUE_NA;
  const percentType = type === TABLE_ITEM_SETTINGS.TYPE_PERCENT || type === TABLE_ITEM_SETTINGS.TYPE_CANDLE;
  const sectorIconType = types.includes('icon') && columnIndex === 20;
  const capitalize = type === TABLE_ITEM_SETTINGS.TYPE_TEXT_CAPITALIZE;
  const themeColorCell = theme === ThemeVariants.DARK_MODE ? darkColorGenerator(colorCell) : colorCell;
  const isClickableLinkStyles = CLICKABLE_CELLS_INDEXES.includes(columnIndex);

  const isFilled = name.includes('filled');

  const getSectorIconPath = () => value;

  const checkTextItem = () => {
    return !!type.match('text') || !!type.match('date');
  };

  useEffect(() => {
    if (checkName && prevItem > item) {
      setColor(TABLE_ITEM_COLORS_SETTINGS.LESS_COLOR);
    }

    if (checkName && prevItem < item) {
      setColor(TABLE_ITEM_COLORS_SETTINGS.HIGH_COLOR);
    }

    const timeout = setTimeout(() => {
      setColor(
        dataPanelQueryId
          ? TABLE_ITEM_COLORS_SETTINGS.DEFAULT_PANEL_COLOR
          : TABLE_ITEM_COLORS_SETTINGS.DEFAULT_COLOR
      );
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [item]);

  const getResizeSelector = () => {
    if (widgetId) {
      return `columnResize_${columnIndex}_${widgetResizeSelector}`;
    }
    return `columnResize_${dataPanelQueryId ? '___' : columnIndex}`;
  };

  const handleTableItem = () => {
    if (type.includes(TABLE_ITEM_SETTINGS.DOT)) {
      return (
        <ValueWithDot
          value={value}
          dotBg={styleGenerator(colorSettings, theme)?.dotBG}
        />
      );
    }
    return (
      <>
        {
          (sectorIconType && !showModal && getSectorIconPath() && !FALSY_VALUES.includes(item)) && (
            <Icon
              icon={`${SECTOR_IMG_PATH}${getSectorIconPath()}.svg`}
              iconClass={styles.sectorIcon}
            />
          )
        }
        {(percentType || isFilled) ? (
          <PercentValue value={value} isFilled={isFilled} themeColorCell={themeColorCell} types={types} />
        ) : (
          <ItemValue
            name={name}
            newValue={newValue}
            onClickHAndler={onClickHAndler}
            value={processArray(value, types)}
            isCapitalize={capitalize}
            colorCell={themeColorCell}
            isNeedTooltip={!!dataPanelQueryId && checkTextItem()}
            isClickableLinkStyles={isClickableLinkStyles}
          />
        )}
        {checkCandle && (
          <DcrIcon
            value={value ? +value.replace('%', '') : value}
          />
        )}
      </>
    );
  };

  return (
    <div
      key={type}
      className={`
          ${styles[name] ? styles[name] : ''}
          ${styleList.join(' ')}
          ${getResizeSelector()}
          ${alertTable && styles.bottomBordered}
          ${sectorIconType && styles.screenerSectorItem}
          ${styles.screenerTableCell}
          ${!dataPanel ? 'cell' : ''}`}
      style={getColumnStyle(size, null, dataPanelQueryId, columnIndex)}
    >
      {columnIndex === VOLUME_COLUMN_INDEX ? (
        <ScreenerVolumeItem
          item={item}
          value={value}
          volumesHistoryData={volumesHistoryData}
        />
      ) : (
        <div className={`${styles.itemWrapper} ${isNumberColumn ? styles.itemNumberWrapper : ''}`}>
          {handleTableItem()}
        </div>
      )}
    </div>
  );
};

TableItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colorSettings: PropTypes.shape({
    title: PropTypes.string,
    color: PropTypes.string
  }),
  handler: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape({})]),
  size: PropTypes.shape({ minWidth: PropTypes.number }),
  columnIndex: PropTypes.number,
  statusIndustry: PropTypes.number,
  statusSubIndustryIndex: PropTypes.number,
  statusSectorIndex: PropTypes.number,
  statusGroupIndex: PropTypes.number,
  alertTable: PropTypes.bool,
  isNumberColumn: PropTypes.bool,
  volumesHistoryData: PropTypes.arrayOf(PropTypes.number),
  dataPanelQueryId: PropTypes.number,
  dataPanel: PropTypes.bool,
  widgetId: PropTypes.string,
  widgetResizeSelector: PropTypes.number,
};

TableItem.defaultProps = {
  item: 0,
  colorSettings: {},
  handler: null,
  size: {},
  columnIndex: 0,
  statusIndustry: 0,
  statusSubIndustryIndex: 0,
  statusSectorIndex: 0,
  statusGroupIndex: 0,
  alertTable: false,
  isNumberColumn: false,
  value: null,
  volumesHistoryData: [],
  dataPanelQueryId: 0,
  dataPanel: false,
  widgetId: '',
  widgetResizeSelector: 0,
};

export default TableItem;
