import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IContext, IPineSeries, IPineStudyResult, LibraryPineStudy, PineJS, PineJSStd, RawStudyMetaInfoId, StudyInputType, StudyPlotType } from '../../charting_library/charting_library';

export default function marketTrend(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Market Trend',
    metainfo: {
      id: 'DV-markettrend@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Market Trend',
      description: 'DV - Market Trend',
      is_price_study: true,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      styles: {
        plot_0: {
          isHidden: false,
          title: 'Background Color'
        }
      },
      inputs: [
        {
          defval: 'SPY',
          id: 'indexsym',
          name: 'Index to Compare',
          type: 'symbol' as StudyInputType.Symbol
        },
        {
          defval: 'SMA',
          id: 'ma_type',
          name: 'MA Type',
          options: [
            'EMA',
            'SMA'
          ],
          type: 'text' as StudyInputType.Text
        },
        {
          defval: 20,
          id: 'ma_length',
          name: 'MA Length',
          type: 'integer' as StudyInputType.Integer
        },
      ],
      plots: [{
        id: 'plot_0',
        palette: 'palette_0',
        type: 'bg_colorer' as StudyPlotType.BgColorer
      }],
      palettes: {
        palette_0: {
          addDefaultColor: true,
          colors: {
            0: {
              name: 'Price Above Rising MA'
            },
            1: {
              name: 'Price Below Rising MA'
            },
            2: {
              name: 'Price Above Declining MA'
            },
            3: {
              name: 'Price Below Declining MA'
            }
          },
          valToIndex: {
            0: 0,
            1: 1,
            2: 2,
            3: 3,
          }
        }
      },
      defaults: {
        inputs: {
          indexsym: 'SPY',
          ma_type: 'SMA',
          ma_length: 20
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#4caf50',
                style: 0,
                width: 1
              },
              1: {
                color: '#ffeb3b',
                style: 0,
                width: 1
              },
              2: {
                color: '#2196f3',
                style: 0,
                width: 1
              },
              3: {
                color: '#ff5252',
                style: 0,
                width: 1
              },
            }
          },
        },
        styles: {
          plot_0: {
            display: 15,
            transparency: 80
          }
        }
      }
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
      };
      this.main = (context, inputs) => {
        this._context = context;

        const [indexsym, ma_type, ma_length] = getInputArray({
          inputs,
          length: 3
        });

        this._context.new_sym(indexsym, mPineJS.Std.period(this._context));
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_unlimited_var(this._context.symbol.time);
        // Select the secondary symbol
        this._context.select_sym(1);
        const secondarySymbolTime = this._context.new_unlimited_var(this._context.symbol.time);

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolLow = this._context.new_unlimited_var(mPineJS.Std.close(this._context));
        const newSymClose = secondarySymbolLow.adopt(secondarySymbolTime, mainSymbolTime, 1);

        this._context.select_sym(0);
        const closeSeries = this._context.new_unlimited_var(newSymClose);
        const ma = calculateMA(this._context, mPineJS.Std, ma_type, ma_length, closeSeries);

        const mAisRising = mPineJS.Std.rising(this._context.new_var(ma), 1);
        const mAisFalling = mPineJS.Std.falling(this._context.new_var(ma), 1);

        let bgColorValue = NaN;
        if (newSymClose > ma && mAisRising) {
          bgColorValue = 0;
        } else if (newSymClose < ma && mAisRising) {
          bgColorValue = 1;
        } else if (newSymClose > ma && mAisFalling) {
          bgColorValue = 2;
        } else {
          bgColorValue = 3;
        }

        return [
          bgColorValue
        ];
      };
    }
  };
}

function calculateMA(context: IContext, mPineJSStd: PineJSStd, type: string, length: number, series: IPineSeries): number {
  if (type === 'SMA') {
    return mPineJSStd.sma(series, length, context);
  }
  return mPineJSStd.ema(series, length, context);
}
