const AUTH_PATH = {
  CHECK_CAPTCHA: 'auth/form-handler',
  REGISTRATION_FOUNDER: 'users/founder-token',
  PAYMENT_SUBSCRIBE_FOUNDER: 'payment/subscribe-founder',
  REFRESH_TOKEN: 'auth/refresh-tokens',
  AUTH_SIGN_IN: 'auth/sign-in',
  AUTH_SIGN_UP: 'users/sign-up',
  LOGOUT: 'auth/logout',
};

export default AUTH_PATH;
