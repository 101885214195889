import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/ScreenerTable.module.scss';
import { FIFTY } from '../../../constants/screener';
import UnfilledValue from './UnfilledValue';
import { processArray } from '../../../utils/shortenStringUrl';

const PercentValue = ({
  value, isFilled, themeColorCell, types
}) => {
  if (isFilled) {
    return (
      <UnfilledValue
        themeColorCell={themeColorCell}
        value={processArray(value, types)}
      />
    );
  }
  if (value) {
    return (
      <span className={+value.replace('%', '') > FIFTY ? styles.growth : styles.loss}>
        {value}
      </span>
    );
  }
  return (<></>);
};

PercentValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  themeColorCell: PropTypes.shape({}),
  types: PropTypes.string,
  isFilled: PropTypes.bool,
};

PercentValue.defaultProps = {
  value: null,
  themeColorCell: {},
  types: '',
  isFilled: false,
};

export default PercentValue;
