import React from 'react';
import { useResizeDetector } from 'react-resize-detector';
import styles from '../../WidgetCharts/sass/WidgetCharts.module.scss';
import { IPerformanceChartWidget } from '../types/DashboardComponentsInterfaces';
import WidgetChartsHeader from '../../WidgetCharts/WidgetChartsHeader';
import PerformanceChart from '../../WidgetCharts/charts/PerformanceChart';
import useWidgetPerformanceChart from '../../WidgetCharts/hook/useWidgetPerformanceChart';
import {
  DEFAULT_DATA_POINT_INDEX,
  DEFAULT_DATA_POINT_TITLE,
  DEFAULT_HEATMAP_Y_DATA_POINT_INDEX,
  DEFAULT_HEATMAP_Y_DATA_POINT_TITLE,
  EmptyChartNames,
  LAST_STATE_KEYS,
  SelectedScreenerTypes
} from '../../WidgetCharts/types/WidgetChartsEnums';
import EmptyContainer from '../../WidgetCharts/components/EmptyContainer';

const PerformanceChartWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe, dashboardId,
  changeSymbolForGroup, isEditable
}: IPerformanceChartWidget): React.ReactElement => {
  const {
    dataPointList,
    storedSymbols,
    totalCount,
    selectedType,
    onLoadMore,
    targetLists,
    queryId,
    screenerStates,
    setScreenerStates,
    setDefaultFilter,
    screenerSearch
  } = useWidgetPerformanceChart(widget, dashboardId, updateWidget);
  const { ref, width, height } = useResizeDetector();

  return (
    <div className={styles.performanceChartContainer} ref={ref}>
      <WidgetChartsHeader
        screenerStates={screenerStates}
        setScreenerStates={setScreenerStates}
        setColorGroupe={setColorGroupe}
        updateWidget={updateWidget}
        removeWidget={removeWidget}
        widget={widget}
        width={width}
        dashboardId={dashboardId}
        dataPointList={dataPointList}
        selectedType={selectedType}
        setDefaultFilter={setDefaultFilter}
        screenerSearch={screenerSearch}
        lastStateKey={LAST_STATE_KEYS.performance}
        defaultDataPoints={
          {
            dataPointXTitle: DEFAULT_DATA_POINT_TITLE,
            dataPointXIndex: DEFAULT_DATA_POINT_INDEX,
            dataPointYTitle: DEFAULT_HEATMAP_Y_DATA_POINT_TITLE,
            dataPointYIndex: DEFAULT_HEATMAP_Y_DATA_POINT_INDEX,
            dataPointZTitle: DEFAULT_HEATMAP_Y_DATA_POINT_TITLE,
            dataPointZIndex: DEFAULT_HEATMAP_Y_DATA_POINT_INDEX,
          }
        }
        showY={false}
        showZ={false}
        showX
        xShare
        baseRef={ref}
        sort
        isEditable={isEditable}
      />
      {
        screenerStates.dataPointsXIndex
        && screenerStates.dataPointsXIndex > -1
        && storedSymbols.length > 0
        && totalCount > 0
        && height
        && width && (
          <div className={styles.chartContainer}>
            <PerformanceChart
              height={height}
              dataPointsXType={screenerStates.dataPointsXType}
              onLoadMore={onLoadMore}
              changeSymbolForGroup={changeSymbolForGroup}
              widget={widget}
              storedSymbols={storedSymbols}
              targetLists={targetLists}
              width={width}
              dataPointXIndex={screenerStates.dataPointsXIndex}
              sortBy={screenerStates.sortBy}
              screenerId={
                (screenerStates.selectedScreener === SelectedScreenerTypes.SCREENER
                  || screenerStates.selectedScreener === SelectedScreenerTypes.SCREENERPRESET)
                  ? null : screenerStates.screenerId
              }
            />
            <span className={styles.footer}>{`${totalCount} results`}</span>
          </div>
        )
      }

      <EmptyContainer
        chartName={EmptyChartNames.performanceChart}
        dataPointsXIndex={screenerStates.dataPointsXIndex}
        dataPointsYIndex={screenerStates.dataPointsYIndex}
        length={totalCount}
        queryId={queryId}
        screener={screenerStates.selectedScreenerTitle}
        type={screenerStates.dataPointsXTitle}
      />
    </div>
  );
};

export default PerformanceChartWidget;
