import { StudyInputType } from "../../../charting_library/charting_library";

export const defaultInputs = {
  iMAVisible: true,
  iMALength: 10,
  iMAType: 'SMA',
  iHideZoneLines: false
};

export const mainInputs = [
  {
    id: 'iMAVisible',
    name: 'Show Moving Average',
    type: 'bool' as StudyInputType.Bool,
    defval: true
  },
  {
    id: 'iMALength',
    name: 'Moving Average Length',
    type: 'integer' as StudyInputType.Integer,
    defval: 10,
    min: 3,
    max: 50
  },
  {
    id: 'iMAType',
    name: 'Moving Average Type',
    type: 'text' as StudyInputType.Text,
    options: ['SMA', 'EMA'],
    defval: 'SMA'
  },
  {
    id: 'iHideZoneLines',
    name: 'Hide Zone Lines',
    type: 'bool' as StudyInputType.Bool,
    defval: false
  }
];

export default mainInputs;
