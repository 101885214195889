import React from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import styles from '../sass/DashboardComponents.module.scss';
import ScreenerButtonGroup from '../../ScreenerButtonGroup/ScreenerButtonGroup';
import RootStateTypes from '../../../store/RootStateTypes';
import { StoredSymbol } from '../../../pages/Table/DeeplistUtils/types/AlertTypes';
import { BUTTON_GROUP_SETTINGS } from '../../../constants/screener';

const SymbolsActionsMenu = ({
  selectedItems,
  isDeepList,
  selectedFromWidget
}: {
  selectedItems: number[],
  isDeepList: boolean,
  selectedFromWidget?: number
}): React.ReactElement => {
  const symbolsList = useSelector((state: RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const handleCopySymbols = (item: string) => {
    if (item === BUTTON_GROUP_SETTINGS.COPY) {
      const data = selectedItems.map((index) => symbolsList.find(
        (itemSymbol: StoredSymbol) => itemSymbol.index === index
      )?.sym);

      const symbols = data?.join(' ');
      navigator.clipboard.writeText(symbols);
    }
  };

  return (
    <div className={styles.symbolsActionHeader}>
      <ScreenerButtonGroup
        handleCopySymbols={handleCopySymbols}
        isOnlyRemove={false}
        isSearchResult={false}
        selectedItemsFromWidgets={selectedItems as number[]}
        watchList={isDeepList}
        selectedFromWidget={selectedFromWidget}
        isWidget
      />
    </div>
  );
};

export default SymbolsActionsMenu;
