import React, { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IDashboardItemsDialog } from './types/interfaces';
import UseDialogData from './hooks/UseDialogData';
import { DashboardItemsDialogActionTypes, DialogItemDataTypes } from './types/constants';
import { TDashboardItemsData } from '../../utils/Types';
import { useTheme } from '../../utils/hooks/useTheme';
import {
  getActionsProps, getContentProps, getPaperOptions, getTitleProps
} from './utils/MuiDialogProps';
import { DialogItemEntityType } from '../DashboardsItemsDropdown/types/constants';
import DialogContentFolder from './components/DialogContentFolder';
import ActionContentBlock from './components/ActionContetBlock';
import DashboardItemsDialogsCallback from './hooks/DashboardItemsDialogsCallback';
import DialogContentRemove from './components/DialogContentRemove';
import DialogContentDuplicate from './components/DialogContentDuplicate';
import { TDashboardItemExt, TDialogData } from './types/types';
import DialogContentMoveTo from './components/DialogContentMoveTo';
import DialogContentScreener from './components/DialogContentScreener';
import DialogContentDashboard from './components/DialogContentDashboard';
import { setModalType } from '../../store/watchlist/actions';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import RootStateTypes from '../../store/RootStateTypes';
import { TABLE_SCREEN_PATH } from '../../constants/screener';

const DashboardItemsDialog = ({
  open, dialogData, actionCancel, currentItemHandler, afterConfirm
}: IDashboardItemsDialog): React.ReactElement => {
  const { theme } = useTheme();
  const dispatch = useDispatch();
  const [item, setItem] = useState<TDashboardItemExt | null>(dialogData?.item || null);
  const [currentDialogData, setCurrentDialogData] = useState<TDialogData>(dialogData);
  const [dataFromDialog, setDataFromDialog] = useState<TDialogData | null>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [cbFolderId, setCbFolderId] = useState<string | null>(null);
  const pagePath = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.lastTablePath, shallowEqual
  );
  const {
    getTitle
  } = UseDialogData();

  const dataCbDialog = (data: TDialogData) => {
    setCurrentDialogData(data);
    setDataFromDialog(null);
    setItem(data.item as TDashboardItemsData);
  };

  const { dialogAction } = DashboardItemsDialogsCallback(
    actionCancel, dataFromDialog,
    dataCbDialog, setCbFolderId, currentItemHandler
  );

  const handleChangeItem = (newItem: TDashboardItemsData): void => {
    setItem(newItem);
  };

  const handleConfirm = () => {
    dialogAction(item as TDashboardItemsData, currentDialogData, cbFolderId);
    if (afterConfirm) {
      afterConfirm();
    }
  };

  const checkOnСonfirmSave = (): boolean => {
    if (isConfirmOpen) return false;
    if (currentDialogData?.type === DashboardItemsDialogActionTypes.DELETE
      || currentDialogData?.type === DashboardItemsDialogActionTypes.REMOVE) {
      return false;
    }
    if (currentDialogData?.type === DashboardItemsDialogActionTypes.MOVE_TO) {
      return !item?.folderId;
    }
    if (!item?.title || item?.title === ''
      || (item?.title === currentDialogData?.item?.title
        && String(item?.data?.description) === String(currentDialogData?.item?.data?.description))) {
      return true;
    }
    return false;
  };

  const handleOpenNewFolder = (): void => {
    setIsConfirmOpen(!checkOnСonfirmSave());
    const newDialogData = { ...currentDialogData };
    currentDialogData.item = item;
    setDataFromDialog(currentDialogData);
    newDialogData.entityType = DialogItemEntityType.FOLDER;
    newDialogData.type = DashboardItemsDialogActionTypes.ADD;
    newDialogData.item = null;
    setItem(null);
    setCurrentDialogData(newDialogData);
  };

  const handleCloseDialog = (): void => {
    if (dataFromDialog) {
      setCurrentDialogData(dataFromDialog);
      setItem(dataFromDialog.item as TDashboardItemsData);
      setDataFromDialog(null);
      dispatch(setModalType(''));
    } else {
      actionCancel();
    }
  };

  const getDialogContent = (): React.ReactElement | null => {
    switch (currentDialogData?.type) {
      case DashboardItemsDialogActionTypes.EDIT:
        if (currentDialogData?.entityType === DialogItemEntityType.FOLDER) {
          return (
            <DialogContentFolder
              theme={theme}
              item={item as TDashboardItemsData}
              onChange={handleChangeItem}
            />
          );
        }
        if (currentDialogData?.itemType === DialogItemDataTypes.SCREENER
        || currentDialogData?.itemType === DialogItemDataTypes.DEEP_LIST
          || currentDialogData?.itemType === DialogItemDataTypes.DATA_PANEL
          || currentDialogData?.itemType === DialogItemDataTypes.COLUMN_SET) {
          return (
            <DialogContentScreener
              theme={theme}
              item={item as TDashboardItemsData}
              onChange={handleChangeItem}
            />
          );
        }
        if (currentDialogData?.itemType === DialogItemDataTypes.DASHBOARD) {
          return (
            <DialogContentDashboard
              theme={theme}
              item={item as TDashboardItemsData}
              onChange={handleChangeItem}
            />
          );
        }
        return null;
      case DashboardItemsDialogActionTypes.MOVE_TO:
        return (
          <DialogContentMoveTo
            theme={theme}
            item={item as TDashboardItemsData}
            onChange={handleChangeItem}
            type={currentDialogData?.itemType as DialogItemDataTypes}
          />
        );
      case DashboardItemsDialogActionTypes.DELETE:
      case DashboardItemsDialogActionTypes.REMOVE:
        if (currentDialogData?.itemType === DialogItemDataTypes.DASHBOARD
          && pagePath !== TABLE_SCREEN_PATH.DASHBOARDS) {
          return (
            <DialogContentDashboard
              theme={theme}
              item={item as TDashboardItemsData}
              onChange={handleChangeItem}
            />
          );
        }
        return (
          <DialogContentRemove
            theme={theme}
            item={item as TDashboardItemsData}
            dialogData={currentDialogData}
          />
        );
      case DashboardItemsDialogActionTypes.DUPLICATE:
        return (
          <DialogContentDuplicate
            theme={theme}
            item={item as TDashboardItemsData}
            onChange={handleChangeItem}
            createNewFolder={handleOpenNewFolder}
            cbFolderId={cbFolderId}
            type={currentDialogData?.itemType as DialogItemDataTypes}
          />
        );
      case DashboardItemsDialogActionTypes.ADD:
        if (currentDialogData?.entityType === DialogItemEntityType.FOLDER) {
          return (
            <DialogContentFolder
              theme={theme}
              item={item as TDashboardItemsData}
              onChange={handleChangeItem}
            />
          );
        }
        if (currentDialogData?.itemType === DialogItemDataTypes.DATA_PANEL) {
          return (
            <DialogContentScreener
              theme={theme}
              item={item as TDashboardItemsData}
              onChange={handleChangeItem}
            />
          );
        }
        return (
          <DialogContentDashboard
            theme={theme}
            item={item as TDashboardItemsData}
            onChange={handleChangeItem}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ButtonListenerWrapper handleCancel={actionCancel} handleConfirm={handleConfirm}>
      <Dialog
        sx={{ zIndex: 3000 }}
        open={open}
        onClose={actionCancel}
        PaperProps={{ sx: getPaperOptions(theme) }}
        BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.3)' } }}
      >
        <DialogTitle sx={getTitleProps(theme)}>
          {getTitle(currentDialogData)}
        </DialogTitle>
        <DialogContent sx={getContentProps(theme)}>
          {getDialogContent()}
        </DialogContent>
        <DialogActions sx={getActionsProps(theme)}>
          <ActionContentBlock
            theme={theme}
            dialogData={currentDialogData}
            onDecline={handleCloseDialog}
            onConfirm={handleConfirm}
            disabledConfirm={checkOnСonfirmSave()}
          />
        </DialogActions>
      </Dialog>
    </ButtonListenerWrapper>
  );
};

export default DashboardItemsDialog;
