import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../sass/DashboardsItemsDialog.module.scss';
import { FolderItemData, TDashboardItemsData } from '../../../utils/Types';
import Input from '../../Input/Input';
import { MAX_LENGTH_COUNT } from '../../../constants/screener';

interface IDialogContentDashboard {
  onChange: (item: TDashboardItemsData) => void,
  item: TDashboardItemsData,
  theme: string
}
const DialogContentDashboard = ({
  onChange,
  item,
  theme
}: IDialogContentDashboard): React.ReactElement => {
  const { t } = useTranslation();
  const currentItem = item as FolderItemData;

  const handleChanges = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange({
      ...currentItem,
      title: e.target.value
    });
  };

  return (
    <div className={`${styles.dialogContent} ${theme}`}>
      <div className={styles.dialogContentFieldItem}>
        <label className={styles.dialogContentFieldItemLabel} htmlFor="folderName">
          {t('createDashboardDescription')}
        </label>
        <Input
          type="text"
          autoFocus
          inputClass={styles.dialogContentFieldItemInput}
          handlerChange={handleChanges}
          placeholder={t('createDashboardPlaceholder')}
          value={currentItem?.title || ''}
          maxLength={MAX_LENGTH_COUNT}
        />
      </div>
    </div>
  );
};

export default DialogContentDashboard;
