import React, { useState } from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import colors from '../../constants/colors';
import styles from './sass/SwitchButton.module.scss';

let trotleTimeout = null;

const SwitchButton = ({
  checked,
  onChange,
  width,
  height,
  handleDiameter,
  offColor,
  onColor,
  className,
  uncheckedIcon,
  checkedIcon,
  userTrottle
}) => {
  const [throttleLoader, setThrottleLoader] = useState(false);

  const handleChange = (checkState, e) => {
    if (userTrottle) {
      if (!trotleTimeout) {
        setThrottleLoader(true);
        onChange(checkState, e);
        trotleTimeout = setTimeout(() => {
          clearTimeout(trotleTimeout);
          trotleTimeout = null;
          setThrottleLoader(false);
        }, userTrottle);
      }
    } else {
      onChange(checkState, e);
    }
  };

  return (
    <>
      { throttleLoader
        ? (
          <div className={styles.switchButtonTrottleLoader}>
            <div />
            <div />
            <div />
            <div />
          </div>
        )
        : (
          <Switch
            className={className}
            offColor={offColor}
            onColor={onColor}
            offHandleColor={colors.offHandleColor}
            onHandleColor={colors.offHandleColor}
            uncheckedIcon={false}
            checkedIcon={false}
            checked={checked}
            onChange={(status, e) => handleChange(status, e)}
            uncheckedHandleIcon={uncheckedIcon}
            checkedHandleIcon={checkedIcon}
            activeBoxShadow=""
            width={width || 41}
            height={height || 22}
            handleDiameter={handleDiameter || 18}
          />
        )}
    </>
  );
};

SwitchButton.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  handleDiameter: PropTypes.number,
  offColor: PropTypes.string,
  onColor: PropTypes.string,
  className: PropTypes.string,
  uncheckedIcon: PropTypes.node,
  checkedIcon: PropTypes.node,
  userTrottle: PropTypes.number
};

SwitchButton.defaultProps = {
  width: 41,
  height: 22,
  uncheckedIcon: undefined,
  checkedIcon: undefined,
  handleDiameter: 18,
  onColor: colors.onColor,
  offColor: colors.offColor,
  className: '',
  userTrottle: 0
};

export default SwitchButton;
