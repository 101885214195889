const exportScreenerList = [
  {
    label: 'twitterCopy',
    id: 'copy',
    type: 'copy',
  },
  {
    label: 'labelCopy',
    id: 'copyLabel',
    type: 'copy',
  },
  {
    label: 'labelExportCSV',
    id: 'csv',
    type: 'paper'
  },
  {
    label: 'labelExportExcel',
    id: 'excel',
    type: 'paper'
  },
];

const EXPORT_TYPES = {
  COPY: 'copy',
  PAPER: 'paper'
};

const exportScreenerCase = (
  label,
  exportFileHandler,
  copyToClipBoard,
  copyToClipBoardTwitter,
  id
) => {
  switch (label) {
    case exportScreenerList[0].label:
      return copyToClipBoardTwitter();
    case exportScreenerList[1].label:
      return copyToClipBoard();
    case exportScreenerList[2].label:
      return exportFileHandler(id);
    case exportScreenerList[3].label:
      return exportFileHandler(id);
    default:
      return () => {};
  }
};

export { exportScreenerList, exportScreenerCase, EXPORT_TYPES };
