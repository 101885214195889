import jwtDecode from 'jwt-decode';
import { getFromLocalStorage } from './storageWorks';
import { ACCESS_TOKEN } from '../constants/storage';

export const getRoleUser = () => (getFromLocalStorage(ACCESS_TOKEN)
  ? jwtDecode(getFromLocalStorage(ACCESS_TOKEN))?.membershipType : '');

export const checkRoleUser = (subscriptionConstants) => {
  const roleUser = getRoleUser();
  return roleUser === subscriptionConstants;
};
