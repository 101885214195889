import { filtersStoreHelper } from './helpers';
import $api, { REACT_APP_PUBLIC_LIGHT_SERVER_URL } from '../http';
import { GET_ITEMS_LIGHT_SERVER } from '../constants/paths';
import { DEFAULT_COLUMNS_INDEX, defaultPage } from '../constants/screener';

export const getCountForLinkedWatchList = (data) => {
  return $api.post(
    `${REACT_APP_PUBLIC_LIGHT_SERVER_URL}${GET_ITEMS_LIGHT_SERVER}`,
    [
      [DEFAULT_COLUMNS_INDEX],
      filtersStoreHelper(data?.filters),
      [],
      defaultPage
    ]
  );
};

export const stocksControllerForEmptyDL = (item, updateStocksForEmptyDL) => {
  const filters = item?.data?.filters;
  const stocks = item?.data?.stocks;

  const isFiltersValid = Array.isArray(filters)
    && filters.length > 0
    && Array.isArray(filters[0])
    && filters[0].length > 0
    && Array.isArray(filters[0][0])
    && filters[0][0].length > 2;

  const isSpecialCondition = isFiltersValid && (
    filters[0][0][2]?.length === 0
    || filters[0][0][2][0] === 100000000
  );

  if (isSpecialCondition && stocks > 0) {
    updateStocksForEmptyDL(item);
  }
};

export const sortDataHandler = (data) => {
  return data.sort((a, b) => a - b);
};

export const prepareSelectedSymbols = (selectedTab, symbolsList) => {
  const filtersData = [];

  selectedTab?.data?.filters?.flat(2)[2]?.forEach((item) => {
    const findIndex = symbolsList?.findIndex((subItem) => subItem.index === item);

    if (symbolsList[findIndex]) {
      filtersData.push(symbolsList[findIndex]);
    }
  });

  return filtersData;
};
