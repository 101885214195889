import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import ColumnsSelectedView from './ColumnsSelectedView';

const ColumnsSelected = ({
  sortStatus, clearSorting, setNewSortData,
  isWatchlist, sortData
}) => {
  const selectedColumns = useSelector((state) => state.screenerState.selectedColumns, shallowEqual);

  return (
    <ColumnsSelectedView
      isWatchlist={isWatchlist}
      sortStatus={sortStatus}
      setNewSortData={setNewSortData}
      clearSorting={clearSorting}
      selectedColumns={selectedColumns}
      sortData={sortData}
    />
  );
};

ColumnsSelected.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  clearSorting: PropTypes.func.isRequired,
  setNewSortData: PropTypes.func,
  isWatchlist: PropTypes.bool,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
};

ColumnsSelected.defaultProps = {
  setNewSortData: () => {},
  isWatchlist: false,
  sortData: [],
};

export default ColumnsSelected;
