import { CSSProperties } from 'react';
import { DialogItemDataTypes } from '../../DashboardItemsDialog/types/constants';
import { TABLE_SCREEN_PATH } from '../../../constants/screener';

export const subitemHeight = 52;

const getDeltaWidth = (anchorRect: DOMRect, itemType: DialogItemDataTypes, lastPath: string,): number => {
  const { innerWidth } = window;
  const isNeedBiggerLeft = lastPath === TABLE_SCREEN_PATH.DASHBOARDS && (itemType === 0 || itemType === 1);

  if (anchorRect?.right + anchorRect.width + 47 > innerWidth) {
    return -(anchorRect.width + 60 - (isNeedBiggerLeft ? 13 : 0));
  }
  return anchorRect.width + 6;
};

export const getPositionForSubitems = (
  anchor: HTMLElement,
  itemsNum: number,
  itemType: DialogItemDataTypes,
  lastPath: string,
): CSSProperties => {
  const anchorRect = anchor.getBoundingClientRect();
  const numHeight = itemsNum <= 4 ? itemsNum : 4;
  const position = {
    top: anchorRect.top,
    left: anchorRect.left + getDeltaWidth(anchorRect as DOMRect, itemType, lastPath),
    minHeight: subitemHeight * numHeight,
  };
  return position;
};
