import React from 'react';
import { ClickAwayListener } from '@mui/material';
import styles from './sass/EducationComponents.module.scss';
import { ReactComponent as IconStroke } from '../../assets/images/icons/screener/color_stroke.svg';
import { ICategoryFilter } from './types/EducationComponentsInterfaces';
import { Categories } from './types/EducationComponentsTypes';
import Checkbox from '../Checkbox/Checkbox';

const CategoryFilter = ({ params, setParams, categories }: ICategoryFilter): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const handleCategory = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, value: string) => {
    e.stopPropagation();
    setParams((prev) => {
      let newCategories;
      if (value === 'all') {
        newCategories = prev.categories.includes('all') ? [] : ['all'];
      } else {
        newCategories = prev.categories.includes(value)
          ? prev.categories.filter((category) => category !== value)
          : [...prev.categories.filter((category) => category !== 'all'), value];
      }

      return {
        ...prev,
        categories: newCategories,
        page: 1,
        isLoadMore: false
      };
    });
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        className={styles.sortBlock}
        onMouseDown={() => setOpen(!open)}
        role="button"
        tabIndex={0}
        aria-label="sort-block"
      >
        <button type="button">
          Categories
          <IconStroke className={open ? styles.arrow : ''} />
          {open && (
            <div className={styles.sortDropdown}>
              {categories.map((item: Categories) => (
                <div
                  key={item.id}
                  className={styles.list}
                  role="cell"
                  aria-hidden="true"
                  onMouseDown={(e) => {
                    handleCategory(e, item.slug);
                  }}
                >
                  <Checkbox
                    checked={params?.categories.includes(item.slug)}
                    id={`${item.id}_cat`}
                  />
                  <span className={`${styles.title} ${params?.categories.includes(item.slug) ? styles.active : ''}`}>
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          )}
        </button>
      </div>
    </ClickAwayListener>
  );
};
export default CategoryFilter;
