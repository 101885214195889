import urlPattern from '../constants/regex';

export const shortenStringUrl = (url) => {
  if (urlPattern.test(url)) {
    return url.match(urlPattern)[3];
  }
  return url;
};

export const processArray = (url, arr) => {
  if (arr.includes('url')) {
    const shortenedUrl = shortenStringUrl(url);
    return shortenedUrl || '';
  }
  return url;
};
