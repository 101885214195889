import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import stylesModal from './sass/NewScreenModals.module.scss';

const NewScreenButtonBlock = ({
  cancelHandler, cancelButton, actionHandler, actionButton, actionDisabled
}) => (
  <div className={stylesModal.buttonBlock}>
    <Button buttonClass={stylesModal.cancelMoveToButton} handleClick={cancelHandler}>
      {cancelButton}
    </Button>
    <Button buttonClass={stylesModal.saveButton} handleClick={actionHandler} disabled={actionDisabled}>
      {actionButton}
    </Button>
  </div>
);

NewScreenButtonBlock.propTypes = {
  actionButton: PropTypes.string.isRequired,
  cancelButton: PropTypes.string.isRequired,
  actionHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  actionDisabled: PropTypes.bool,
};

NewScreenButtonBlock.defaultProps = {
  actionDisabled: false,
};

export default NewScreenButtonBlock;
