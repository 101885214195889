import moment from 'moment/moment';

export const NOTE_LENGTH = 160;

export const ALERT_TIME_PERIODS = [
  { value: 1, name: '1 month' },
  { value: 3, name: '3 months' },
  { value: 6, name: '6 months' },
  { value: 9, name: '9 months' },
  { value: 12, name: '1 year' },
  { value: 0, name: 'forever' },
];

export const ALERT_TIME_DROP_DELTA = 7;

export const ALERT_MODAL_ROWS = [
  {
    head: 'alertLabelNotiy',
    headClass: '',
    elements: [
      {
        type: 'checkbox',
        additionalClass: 'paddingRight39',
        name: 'isAbove',
        label: 'Rises above',
      },
      {
        type: 'checkbox',
        additionalClass: 'paddingRight39',
        name: 'isBellow',
        label: 'Falls below'
      },
    ]
  },
  {
    head: 'noteLabel',
    headClass: 'field',
    elements: [
      {
        type: 'text',
        additionalClass: 'fullWidth',
        name: 'note',
        label: 'Type an optional message...',
      },
    ]
  },
  {
    head: 'notifySettings',
    headClass: '',
    elements: [
      {
        type: 'checkbox',
        additionalClass: 'fill',
        name: 'useEmail',
        label: 'Email',
      },
      {
        type: 'checkbox',
        additionalClass: 'fill',
        name: 'usePush',
        label: 'Push Notification'
      },
      {
        type: 'checkbox',
        additionalClass: '',
        name: 'usePopup',
        label: 'Show Pop-Up'
      },
    ]
  },
];

export const ALERT_MODAL_VALUE_ROW = {
  head: 'labelValue',
  headClass: 'field',
  elements: [
    {
      type: 'number',
      additionalClass: 'fullWidth',
      name: 'priceTime1',
      label: 'Input value',
    },
  ]
};

export const selectOptionMock = [
  {
    id: 0,
    title: 'past15min',
    urlQuery: 'past15Minutes'
  },
  {
    id: 1,
    title: 'past24h',
    urlQuery: 'past24Hours'
  },
  {
    id: 2,
    title: 'pastWeek',
    urlQuery: 'pastWeek'
  },
  {
    id: 3,
    title: 'pastMonth',
    urlQuery: 'pastMonth'
  }
];

export const note = '';
export const title = 'AAPL Crossing Trendline';

export const PAGE = 0;
export const LIMITS = 200;
export const WAIT_MODAL = 300;

export const ALERT_TYPES = {
  PRICE: 'PRICE',
  TRENDLINE: 'TRENDLINE',
  RAYLINE: 'RAYLINE',
  RAYPRICE: 'RAYPRICE'
};

export const SINGLE_POINT_SHAPES = ['PRICE', 'RAYPRICE'];
export const MULTI_POINTS_SHAPES = ['TRENDLINE', 'RAYLINE'];

export const DEFAULT_ALERT_STATE = {
  isActive: true,
  symbol: '',
  symbolIndex: 0,
  isAbove: true,
  isBellow: true,
  pointTime1: 0,
  priceTime1: 0,
  pointTime2: 0,
  priceTime2: 0,
  note: '',
  usePush: true,
  useEmail: true,
  usePopup: true,
  type: '',
  lifetime: 0,
  activityStart: 0,
  activityEnd: 0,
};

export const ADDITIONAL_YEARS = 5;

export const NOTIFICATIONS_TITLE = {
  generated: 'Crossing Trendline',
  increased: 'Rising Above',
  dropped: 'Falling Below',
};

export const DEFAULT_LIMIT = 3;

const format = 'YYYY-MM-DD hh:mm:ss.ms';
const now = moment().format(format);

export const LIMITS_LIST = {
  0: [moment().subtract(15, 'minutes').format(format), now],
  1: [moment().subtract(24, 'hours').format(format), now],
  2: [moment().subtract(1, 'week').format(format), now],
  3: [moment().subtract(1, 'month').format(format), now]
};
export const ALERT_COLUMNS = [
  {
    index: -1,
    type: 'alert',
    name: 'Status',
    shortName: 'Status',
    indexData: 'isActive'
  },
  {
    index: -2,
    type: 'alert',
    name: 'Symbol',
    shortName: 'Symbol',
    indexData: 'symbol'
  },
  {
    index: -3,
    type: 'timestamp',
    name: 'Created',
    shortName: 'Created',
    indexData: 'createdAt'
  },
  {
    index: -4,
    type: 'capitalize',
    name: 'Alert type',
    shortName: 'Alert type',
    indexData: 'type'
  },
  {
    index: -5,
    type: 'alert',
    name: 'Note',
    shortName: 'Note',
    indexData: 'note'
  },
];

export const ALERT_COLUMN_INDEXES = {
  STATUS: -1,
  SYMBOL: -2,
  DATE: -3,
  TYPE: -4,
  NOTE: -5
};

export const DATE_OPTIONS = {
  year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'
};

export const TREND_LINE_NAME = 'trend_line';
export const RAY_LINE_NAME = 'ray';
export const PRICE_NAME = 'horizontal_line';
export const RAY_PRICE = 'horizontal_ray';

export const NOTIFICATION_DELAY_TIMES = {
  TICKS: 4000
};

export const DEFAULT_ICON = 'no_icon.png';

export const COLUMN_NAME_BY_INDEX = {
  '-2': 'symbol',
  '-4': 'type',
};

export const ALERT_MODAL_SETINGS_TITLE = 'alertSettings';

export const DEFAULT_PRECISION = 2;
export const FACTORIAL_MULTIPLY = 10;

export const DEFAULT_SHAPE_SETTINGS = {
  showInObjectsTree: false,
  disableSave: true,
};

export const TREND_LINE_SETTINGS = {
  showInObjectsTree: true,
  disableUndo: true,
  disableSave: false,
};

export const ALERT_ACTIVE = 'rgba(245, 184, 91, 1)';
export const COMMON_LINE = '#2962FF';
export const ALERT_HIDE = 'rgba(183, 186, 218, 1)';

export const AVALIBLE_SHAPES = ['LineToolTrendLine', 'LineToolHorzLine', 'LineToolRay', 'LineToolHorzRay'];
export const SHAPES_DATA = {
  RAY_PRICE: 'LineToolHorzRay',
  RAY_LINE: 'LineToolRay',
  HORZ_LINE: 'LineToolHorzLine',
  TRND_LINE: 'LineToolTrendLine'
};

export const DEFAULT_ALERT_GROUP = {
  groupName: 'Alerts',
  groupId: -2,
  items: [
    {
      name: 'Created',
      type: 'text',
      id: -3,
      group: -2
    },
    {
      name: 'Alert type',
      type: 'capitalize',
      id: -4,
      group: -2
    },
    {
      name: 'Note',
      type: 'alert',
      id: -5,
      group: -2
    },
  ]
};
