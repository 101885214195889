import React from 'react';
import PropTypes from 'prop-types';
import useKeyboardListenerHook from '../../utils/hooks/useKeyboardListener';

const ButtonListenerWrapper = ({ handleCancel, handleConfirm, children }) => {
  useKeyboardListenerHook(handleCancel, handleConfirm);

  return (
    <div>{children}</div>
  );
};

ButtonListenerWrapper.propTypes = {
  handleCancel: PropTypes.func,
  handleConfirm: PropTypes.func,
  children: PropTypes.element.isRequired
};

ButtonListenerWrapper.defaultProps = {
  handleConfirm: () => undefined,
  handleCancel: () => undefined,
};

export default ButtonListenerWrapper;
