import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from '../../sass/PickColumnWrapper.module.scss';
import TextField from '../../../TextField/TextField';

const ColumnsSelectedHeader = ({ counter }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.rightTopContentHeaderWrapper}>
      <TextField text={t('selectedColumn')} styleType="rightTopContentHeader" />
      <div className={styles.rightTopContentCounter}>{counter - 1}</div>
    </div>
  );
};

ColumnsSelectedHeader.propTypes = {
  counter: PropTypes.number.isRequired
};
export default React.memo(ColumnsSelectedHeader);
