import React, { ReactElement } from 'react';
import DefaultButton from '../DefaultButton/DefaultButton';
import IExportButton from './interfaces/IExportButton';
import { ReactComponent as ButtonShare } from '../../assets/images/icons/share.svg';
import styles from './sass/ButtonExportScreener.module.scss';

const ExportButton = ({
  handleViewMenu,
  isFree,
  disableExport = true,
  viewMenu = false
}: IExportButton): ReactElement => (
  <>
    {disableExport ? (
      <DefaultButton
        buttonClass="btnExportScreenerDisable"
        handleClick={handleViewMenu}
        className={isFree ? styles.disabled : ''}
      >
        <ButtonShare />
      </DefaultButton>
    ) : (
      <>
        <DefaultButton
          buttonClass={viewMenu ? 'btnExportScreenerActive' : 'btnExportScreener'}
          handleClick={handleViewMenu}
          className={isFree ? styles.disabled : ''}
        >
          <ButtonShare />
        </DefaultButton>
      </>
    )}
  </>
);

export default ExportButton;
