import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../sass/PickColumnWrapper.module.scss';
import ColumnsSelectedItems from './ColumnsSelectedItems';

const ColumnsSelectedView = ({
  selectedColumns,
  sortStatus,
  clearSorting,
  isWatchlist,
  setNewSortData,
  sortData,
}) => (
  <div className={styles.columnsContentRight}>
    <ColumnsSelectedItems
      isWatchlist={isWatchlist}
      sortStatus={sortStatus}
      setNewSortData={setNewSortData}
      clearSorting={clearSorting}
      selectedColumns={selectedColumns}
      sortData={sortData}
    />
  </div>
);

ColumnsSelectedView.propTypes = {
  selectedColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sortStatus: PropTypes.func.isRequired,
  setNewSortData: PropTypes.func,
  clearSorting: PropTypes.func.isRequired,
  isWatchlist: PropTypes.bool,
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
};

ColumnsSelectedView.defaultProps = {
  setNewSortData: () => {},
  isWatchlist: false,
  sortData: [],
};

export default ColumnsSelectedView;
