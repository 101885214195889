import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import Input from '../Input/Input';
import Button from '../Button/Button';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import { checkEmptyText } from '../../utils/helpers';
import { MAX_LENGTH_COUNT } from '../../constants/screener';

const CreateFolderModal = ({ setOpenModal, createFolder, type }) => {
  const { t } = useTranslation();
  const [createFolderName, setCreateFolderName] = useState('');

  const createFolderHandler = () => {
    createFolder(createFolderName, type);
  };

  return (
    <ButtonListenerWrapper
      handleCancel={setOpenModal}
      handleConfirm={createFolderName ? createFolderHandler : () => {}}
    >
      <div className={stylesModal.createFolderWrapper}>
        <div className={stylesModal.createFolderHeader}>
          <TextField text={t('createFolderButton')} styleType="createFolderModalHeader" />
          <div
            aria-hidden="true"
            onMouseDown={setOpenModal}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={stylesModal.createFolderBody}>
          <TextField text={t('folderName')} styleType="createFolderModalBody" />
          <Input
            handlerChange={(e) => setCreateFolderName(e.currentTarget.value)}
            placeholder={t('typeFolderName')}
            value={createFolderName}
            inputClass={stylesModal.createFolderInput}
            autoFocus
            maxLength={MAX_LENGTH_COUNT}
          />
          <div className={stylesModal.buttonWrapper}>
            <Button
              buttonClass={stylesModal.createFolderButton}
              handleClick={createFolderHandler}
              disabled={checkEmptyText(createFolderName) || !createFolderName}
            >
              {t('create')}
            </Button>
          </div>
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

CreateFolderModal.propTypes = {
  createFolder: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};
export default CreateFolderModal;
