import React from 'react';

import { useTranslation } from 'react-i18next';
import TextField from '../../TextField/TextField';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/screener/filter.svg';
import styles from '../sass/FilterScreen.module.scss';

interface INoFilters {
  active: boolean;
  handlerGroup: (id: number) => void;
  id: number;
}

const NoFilters = ({ active, handlerGroup, id }: INoFilters): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.blockNoFilters} ${active ? styles.activeBlockFilters : ''}`}
      onClick={() => handlerGroup(id)}
      onKeyPress={() => handlerGroup(id)}
      role="button"
      aria-hidden="true"
      tabIndex={0}
    >
      <TextField styleType="basket" text={t('basket')} />
      <div className={styles.contentIcon}>
        <div className={styles.iconWrapper}>
          <FilterIcon className={styles.filterIcon} />
        </div>
        <TextField styleType="basketTitle" text={t('noFiltersApllied')} />
        <TextField styleType="basket" text={t('selectFiltersLeft')} />
      </div>
    </div>
  );
};

export default NoFilters;
