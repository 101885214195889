import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '../../utils/hooks/useTheme';
import styles from './sass/Page.module.scss';

export default function PageView({ children }) {
  const { theme } = useTheme();

  return (
    <div className={`${styles.app} ${theme}`} id="modal">
      {children}
    </div>
  );
}

PageView.propTypes = {
  children: PropTypes.node.isRequired
};
