import React from 'react';
import styles from './sass/EducationComponents.module.scss';
import { IArticlesCard } from './types/EducationComponentsInterfaces';
import CategoryBlock from './CategoryBlock';

const ArticlesCard = ({
  image, title, link, categories, reading_time, views, articleCategories
}: IArticlesCard): React.ReactElement => (
  <div
    className={styles.cardItem}
    role="button"
    tabIndex={0}
    aria-label="articles"
    onMouseDown={() => window.open(link, '_blank')}
  >
    <div className={styles.cardImageWrapper}>
      <img className={styles.cardImage} src={image} alt="article-images" />
    </div>
    <div className={styles.cardDetails}>
      <div className={styles.tagsContainer}>
        <CategoryBlock categories={categories} allCategories={articleCategories} />
        <div className={styles.timerContainer}>
          <p className={styles.readtime}>{`${reading_time || 1} min Read |`}</p>
          <p className={styles.readtime}>{`${views ?? 0} Views`}</p>
        </div>
      </div>
      <p className={styles.cardTitle}>{title}</p>
    </div>
  </div>
);

export default ArticlesCard;
