import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import SelectedColumnItem from './ColumnsSelectedItem';
import styles from '../../sass/PickColumnWrapper.module.scss';
import { COLUMNS_INDEX, FIND_NAME, SUBSCRIPTION_TYPE } from '../../../../constants/screener';
import { updateOrdersScreenerAction } from '../../../../store/screener/actions';
import { getAllColumns } from '../../../../utils/helperColumnsWorker';
import { findIndexItem } from '../../../../utils/helpers';
import { DEFAULT_ALERT_GROUP } from '../../../../constants/alerts';
import { setColumnsOrderChanged } from '../../../../store/alerts/slice';
import useSaveSort from '../../../Sorting/hooks/useSaveSort';
import { checkRoleUser } from '../../../../utils/userHelper';

const ColumnsDnDList = ({
  localItems,
  setLocalItems,
  columnSetId,
  itemsColumnSets,
  sortData,
  setNewSortData,
  updateColumnsSetState,
  sortStatus,
  isWatchlist,
}) => {
  const dispatch = useDispatch();
  const [isAlertTab, setIsAlertTab] = useState(false);
  const [currentAlertColumns, setCurrentAlertColumns] = useState([]);
  const [isColumnSetPreset, setIsColumnSetPreset] = useState(false);

  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const alertColumns = useSelector((state) => state.accountState.userSettings.alertColumns, shallowEqual);
  const columnSetsPreset = useSelector((state) => state.newScreenState.columnSetsPreset, shallowEqual);

  const { saveSortHandler } = useSaveSort();
  const adminRole = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = [...localItems];
    const reorderedItem = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem[0]);
    setLocalItems(items);
    dispatch(updateOrdersScreenerAction({ data: items }));

    let indexItem = -1;
    if (!columnSetId && columnSetId === -1) {
      const allColumnSet = getAllColumns(itemsColumnSets);
      indexItem = findIndexItem(allColumnSet, FIND_NAME.TITLE, FIND_NAME.RECOMMENDED);
    }

    const currentId = columnSetId === -1 ? indexItem : columnSetId;
    const getStackId = items.map((item) => item.id);

    updateColumnsSetState(currentId, getStackId);

    const timer = setTimeout(() => {
      dispatch(setColumnsOrderChanged());
      clearTimeout(timer);
    }, 250);
  };

  useEffect(() => {
    if (selectedTab === alertTab && isWatchlist) {
      setIsAlertTab(true);
    } else {
      setIsAlertTab(false);
    }
  }, [selectedTab]);

  useEffect(() => {
    if (alertColumns?.length > 0) {
      const currentSelected = alertColumns.map((item) => {
        const currentColumn = DEFAULT_ALERT_GROUP.items.find((el) => el.id === item);
        return currentColumn;
      });
      setCurrentAlertColumns(currentSelected);
    } else {
      setCurrentAlertColumns([]);
    }
  }, [alertColumns]);

  useEffect(() => {
    const columnSetsPresetsIds = columnSetsPreset.map((column) => column?.id);
    setIsColumnSetPreset(!adminRole ? columnSetsPresetsIds.includes(columnSetId) : false);
  }, [columnSetId]);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable
        droppableId="characters"
        renderClone={(navigator.msMaxTouchPoints > 0) || (navigator.maxTouchPoints > 0) ? undefined
          : (provided, snapshot, rubric) => (
            <div // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <SelectedColumnItem
                additionalClass
                text={localItems[rubric.source.index].name}
                sortStatus={sortStatus}
                id={localItems[rubric.source.index].id}
                item={localItems[rubric.source.index]}
                isColumnSetPreset={isColumnSetPreset}
              />
            </div>
          )}
      >
        {(provided) => (
          <div
            className={styles.dropable} // eslint-disable-next-line react/jsx-props-no-spreading
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {isAlertTab && (
              <SelectedColumnItem
                text="Status"
                sortData={sortData}
                singleItem
                sortStatus={sortStatus}
                id={-1}
                isColumnSetPreset={isColumnSetPreset}
              />
            )}
            <SelectedColumnItem
              text="Symbol"
              singleItem
              sortData={sortData}
              sortStatus={sortStatus}
              id={17}
              isColumnSetPreset={isColumnSetPreset}
            />
            {isAlertTab && currentAlertColumns?.length > 0 && (
              currentAlertColumns.map((column, index) => (
                <SelectedColumnItem
                  key={[column.id, index].join('_')}
                  text={column.name}
                  singleItem
                  sortStatus={sortStatus}
                  sortData={sortData}
                  saveSortHandler={saveSortHandler}
                  id={column.id}
                  group={column.group}
                  isAlertTab
                  isColumnSetPreset={isColumnSetPreset}
                />
              )))}
            {localItems.map((column, index) => {
              return column?.id !== COLUMNS_INDEX.INDEX && column?.id !== COLUMNS_INDEX.SYM && (
                <Draggable
                  key={column.id}
                  draggableId={`${column.id}`}
                  index={index}
                  isDragDisabled={isColumnSetPreset}
                >
                  {/* eslint-disable-next-line no-shadow */}
                  {(provided) => (
                    <div
                      ref={provided.innerRef} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.draggableProps} // eslint-disable-next-line react/jsx-props-no-spreading
                      {...provided.dragHandleProps}
                    >
                      <SelectedColumnItem
                        key={[column.id, index].join('_')}
                        text={column.name}
                        sortStatus={sortStatus}
                        sortData={sortData}
                        setNewSortData={setNewSortData}
                        saveSortHandler={saveSortHandler}
                        id={column.id}
                        item={column}
                        isColumnSetPreset={isColumnSetPreset}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ColumnsDnDList.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  setNewSortData: PropTypes.func,
  localItems: PropTypes.arrayOf(PropTypes.shape({})),
  sortData: PropTypes.arrayOf(PropTypes.shape({})),
  setLocalItems: PropTypes.func.isRequired,
  columnSetId: PropTypes.number.isRequired,
  itemsColumnSets: PropTypes.arrayOf(PropTypes.shape({})),
  updateColumnsSetState: PropTypes.func.isRequired,
  isWatchlist: PropTypes.bool,
};

ColumnsDnDList.defaultProps = {
  setNewSortData: () => {},
  localItems: [{}],
  sortData: [{}],
  itemsColumnSets: [{}],
  isWatchlist: false,
};

export default ColumnsDnDList;
