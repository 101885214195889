import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styles from './sass/WatchListModals.module.scss';
import SearchDropDownItem from './SearchDropDownItem';
import SearchDropDownEmpty from './SearchDropDownEmpty';
import { deepEqual } from '../../utils/objectHelper';
import useLastElementObserver from '../../utils/hooks/useLastElementObserver';

const areEqual = (prevProps, nextProps) => {
  return deepEqual(prevProps.resultList, nextProps.resultList)
    && prevProps.selectedList === nextProps.selectedList
    && prevProps.inputValue.length === nextProps.inputValue.length
    && prevProps.viewDrop === nextProps.viewDrop;
};

const SearchResultDropDown = ({
  viewDrop, selectedList, setSelectedListHandler, inputValue, resultList, setPagingHandler
}) => {
  const { t } = useTranslation();
  const { lastElementRef } = useLastElementObserver(setPagingHandler);

  return (
    <div className={`${styles.resultBlock}`}>
      {viewDrop && (
      <div className={styles.searchResultList}>
        {resultList.length > 0 ? resultList.map((item, index) => (
          <SearchDropDownItem
            selectedList={selectedList}
            setSelectedListHandler={setSelectedListHandler}
            key={[item.sym, index].join('_')}
            lastElement={index >= resultList.length - 2}
            item={item}
            ref={resultList.length === index + 1 ? lastElementRef : null}
          />
        )) : (
          <SearchDropDownEmpty value={inputValue} noResultTitle={t('sorryNoResultsFor')} />
        )}
      </div>
      )}
    </div>
  );
};

SearchResultDropDown.propTypes = {
  setPagingHandler: PropTypes.func.isRequired,
  viewDrop: PropTypes.bool.isRequired,
  resultList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedList: PropTypes.arrayOf(PropTypes.shape({})),
  setSelectedListHandler: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired
};

SearchResultDropDown.defaultProps = {
  selectedList: []
};

export default React.memo(SearchResultDropDown, areEqual);
