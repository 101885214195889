import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from '../sass/FilterScreen.module.scss';
import Icon from '../../Icon/Icon';
import { ReactComponent as HeartRed } from '../../../assets/images/icons/screener/heartRed.svg';
import { ReactComponent as ChevronBlue } from '../../../assets/images/icons/screener/chevronBlue.svg';
import constants from '../../../constants/filters';
import timeIcon from '../../../assets/images/icons/accountSettings/timeCircle.svg';
import { setCategoryName } from '../../../store/filters/slice';
import { deepEqual } from '../../../utils/objectHelper';
import CounterComponent from '../../CounterComponent/CounterComponent';
import { filterIndexesCollector, indexes, selectedCounterHandler } from '../../../utils/counterHelper';
import RootStateTypes from '../../../store/RootStateTypes';

interface IFilterGroup {
  title: string;
  children: React.ReactNode;
  type: string;
  disabled: boolean;
}

const areEqual = (prevProps: IFilterGroup, nextProps: IFilterGroup) => deepEqual(
  prevProps.children,
  nextProps.children
);

const FilterGroup = ({
  title, children, type, disabled
}: IFilterGroup): React.ReactElement => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const categoryFilter = useSelector((state: RootStateTypes) => state.filtersState.categoryFilter, shallowEqual);
  const selectedGroupFilters = useSelector(
    (state: RootStateTypes) => state.filtersState.selectedGroupFilters, shallowEqual
  );
  const filterList = useSelector((state: RootStateTypes) => state.screenerState.filterList, shallowEqual);
  const [activeGroup, setActiveGroup] = useState(false);

  const toggleGroup = () => {
    const labelGroup = activeGroup ? '' : title;
    dispatch(setCategoryName(labelGroup));
    setActiveGroup(!activeGroup);
  };

  useEffect(() => {
    if (activeGroup) {
      const groupScrollContainer = document.getElementById(title);
      const filterScrollContainer = document.getElementById('scrollFilter');

      if (groupScrollContainer && filterScrollContainer) {
        const timerId = setTimeout(() => {
          const offsetTop = groupScrollContainer.getBoundingClientRect().top
            - filterScrollContainer.getBoundingClientRect().top;
          filterScrollContainer.scrollBy(0, offsetTop - 35);
          clearTimeout(timerId);
        }, 300);
      }

      const handleWheel = (e: WheelEvent): void => {
        if (groupScrollContainer && filterScrollContainer) {
          const scrollToBottomDiff = groupScrollContainer.scrollHeight - groupScrollContainer.scrollTop;
          const isBottom = scrollToBottomDiff === groupScrollContainer.clientHeight;
          const isTop = groupScrollContainer.scrollTop === 0;

          if (isBottom && e.deltaY > 0) {
            filterScrollContainer.focus();
            filterScrollContainer.scrollBy(0, e.deltaY);
            e.preventDefault();
          } else if (isTop && e.deltaY < 0) {
            filterScrollContainer.focus();
            filterScrollContainer.scrollBy(0, e.deltaY);
            e.preventDefault();
          }
        }
      };
      if (groupScrollContainer) {
        groupScrollContainer.scroll(0, 0);
        groupScrollContainer.addEventListener('wheel', handleWheel);
      }

      return () => {
        if (groupScrollContainer) {
          groupScrollContainer.removeEventListener('wheel', handleWheel);
        }
      };
    }

    return () => {};
  }, [activeGroup, title]);

  useEffect(() => {
    if (categoryFilter === title && !activeGroup) {
      setActiveGroup(true);
    } else if (categoryFilter !== title && activeGroup) {
      setActiveGroup(false);
    }

    if (!Object.keys(indexes).length) {
      filterIndexesCollector(filterList);
    }
  }, [categoryFilter]);

  return (
    <>
      <div
        className={`
         ${styles.group}
         ${activeGroup ? styles.active : ''}
         ${disabled ? styles.disabled : ''}
       `}
      >
        <div
          aria-hidden="true"
          className={styles.title}
          onClick={toggleGroup}
          role="button"
          tabIndex={0}
        >
          {type === constants.favorite ? (
            <HeartRed className={styles.favoriteIcon} />
          ) : ''}
          {title}
          <CounterComponent
            mainCounter={indexes[title]?.length}
            selectedCounter={selectedCounterHandler(selectedGroupFilters, title)}
          />
          <ChevronBlue className={styles.chevron} />
        </div>
        <div
          className={styles.content}
          id={title}
        >
          {children}
        </div>
      </div>
      {
        type === constants.favorite && disabled && (
          <div className={styles.tooltip}>
            <div className={styles.row}>
              <Icon
                icon={timeIcon}
                iconClass={styles.tooltip_icon}
              />
              <p className={styles.tooltip_title}>{t('thisFeatureIsComingSoon')}</p>
            </div>
            <p className={styles.tooltip_text}>{t('theNewFeatureIsFavorites')}</p>
          </div>
        )
      }
    </>
  );
};

export default React.memo(FilterGroup, areEqual);
