import React from 'react';
import styles from '../sass/PositionSize.module.scss';
import { IResultItem } from '../types/PostionSizeInterfaces';

const ResultItem = ({
  title,
  value,
  formatter
}: IResultItem): React.ReactElement => (
  <div className={styles.item}>
    <p className={styles.item_label}>{title}</p>
    <p className={styles.item_value}>{`${formatter}${value}`}</p>
  </div>
);

export default ResultItem;
