import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setSelectedSymbolsBatchScreener, setSelectedSymbolsScreener } from '../../store/screener/actions';
import { setSelectedItems, setSingleItem } from '../../store/selectSymbol/slice';
import { FILTER_SETTINGS } from '../../constants/screener';

const useSelection = (watchlist = false, isAlert = false) => {
  const dispatch = useDispatch();
  const [selectedAllItems, setSelectedAllItems] = useState(false);
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual);
  const selectedItems = useSelector((state) => state.selectSymbolsState.selectedItems, shallowEqual);
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const searchHeaderValue = useSelector((state) => state.screenerState.globalSearchHeaderValue, shallowEqual);
  const dataRowsIndexes = useSelector((state) => state.tableDataState.dataRowsIndexes, shallowEqual);

  const setItems = useCallback(
    (checkedItem) => {
      dispatch(setSelectedSymbolsScreener(checkedItem?.sortIndex));
      setSelectedAllItems(false);
      dispatch(setSingleItem(checkedItem?.sortIndex));
    }, [selectedItems, selectedSymbols]
  );

  const clearSelection = useCallback(() => {
    dispatch(setSelectedItems([]));
    setSelectedAllItems(false);
    dispatch(setSelectedSymbolsBatchScreener([]));
  }, [selectedAllItems, selectedItems]);

  const setAllItems = useCallback(
    (initial = true) => {
      if (initial && selectedItems?.length > 0) {
        clearSelection();
        return;
      }

      let itemsToSelect = dataRowsIndexes;

      if (watchlist && !selectedTab?.parentId && !searchHeaderValue) {
        itemsToSelect = selectedTab?.data?.filters?.[0]?.[0]?.[2] || [];
      } else if (selectedTab?.data?.sortData?.length > 0) {
        itemsToSelect = dataRowsIndexes;
      }

      const filteredItems = itemsToSelect.filter((item) => item !== FILTER_SETTINGS.BILLION);

      if (filteredItems.length > 0) {
        dispatch(setSelectedItems(filteredItems));
        setSelectedAllItems(true);
        dispatch(setSelectedSymbolsBatchScreener(filteredItems));
      }
    },
    [selectedItems, selectedTab, watchlist, searchHeaderValue]
  );

  useEffect(() => {
    if (
      selectedItems.length
      && selectedAllItems
      && !isAlert
    ) {
      setAllItems(false);
    }
  }, [selectedAllItems, selectedItems]);

  useEffect(() => {
    clearSelection();
  }, [selectedTab.id]);

  return { setItems, setAllItems };
};

export default useSelection;
