import React from 'react';

import TextField from '../../../TextField/TextField';
import { converterDate } from '../../../../utils/helperFilter';
import { TValueItem } from '../../Types';

interface IDateItems {
  arrayData: TValueItem[];
}

const DateItems = ({ arrayData }: IDateItems): React.ReactElement => (
  <>
    {arrayData[0].value === null && (
      <TextField
        styleType="filterItem"
        text={` < ${converterDate(arrayData[1].value as number)}`}
      />
    )}
    {arrayData[1].value === null && (
      <TextField
        styleType="filterItem"
        text={`> ${converterDate(arrayData[0].value as number)}`}
      />
    )}
    {(arrayData[0].value !== null && arrayData[1].value !== null) && (
      <>
        <TextField
          styleType="filterItem"
          text={`${converterDate(arrayData[0].value as number)}`}
        />
        <TextField
          styleType="filterItem"
          text={`${converterDate(arrayData[1].value as number)}`}
        />
      </>
    )}
  </>
);

export default DateItems;
