import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  setUpdatedLastState,
  updateNestedPscAction,
  updateNestedSortingAction,
  updateNestedStoreAction,
  updateWidgetChartsAction,
  updateStoreAction,
  updateColumnSetsPresetsScreenerIdsAction,
} from '../../store/account/actions';

const useLastStateUpdateStore = () => {
  const dispatch = useDispatch();
  const updatedLastState = useSelector((state) => state.accountState.updatedLastState, shallowEqual);

  const updateStoreHandler = (field, data) => {
    if (!updatedLastState) {
      dispatch(setUpdatedLastState(true));
    }
    dispatch(updateStoreAction({ field, data }));
  };

  const updateFavouritesNestedData = (field, data) => {
    if (!updatedLastState) {
      dispatch(setUpdatedLastState(true));
    }
    dispatch(updateNestedStoreAction({ field, data }));
  };

  const updateSortingNestedData = (field, data) => {
    if (!updatedLastState) {
      dispatch(setUpdatedLastState(true));
    }
    dispatch(updateNestedSortingAction({ field, data }));
  };

  const updateColumnSetsPresetsScreenerIdsData = (field, data) => {
    if (!updatedLastState) {
      dispatch(setUpdatedLastState(true));
    }
    dispatch(updateColumnSetsPresetsScreenerIdsAction({ field, data }));
  };

  const updateNestedPscActionData = (type, data, dashboardId) => {
    if (!updatedLastState) {
      dispatch(setUpdatedLastState(true));
    }
    dispatch(updateNestedPscAction({ type, data, dashboardId }));
  };

  const updateWidgetChartsActionData = (type, data, dashboardId, key) => {
    if (!updatedLastState) {
      dispatch(setUpdatedLastState(true));
    }
    dispatch(updateWidgetChartsAction({
      type, data, dashboardId, key
    }));
  };

  return {
    updateStoreHandler,
    updateFavouritesNestedData,
    updateSortingNestedData,
    updateNestedPscActionData,
    updateWidgetChartsActionData,
    updateColumnSetsPresetsScreenerIdsData
  };
};

export default useLastStateUpdateStore;
