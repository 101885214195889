import PropTypes from 'prop-types';
import React from 'react';
import styles from './sass/SearchField.module.scss';
import { ReactComponent as CloseBlueIcon } from '../../assets/images/icons/screener/closeBlueIcon.svg';
import DefaultButton from '../DefaultButton/DefaultButton';

const SearchField = ({
  id,
  type,
  placeholder,
  value,
  handlerChange,
  focus,
  setFocus,
  styleType,
  resetSearchHandler,
  setTooltipVisible,
  isFree,
  autoFocus,
  className
}) => (
  <div
    className={`${styles.container} ${className} ${focus ? styles.active : ''} ${styleType ? styles[styleType] : ''}`}
  >
    <input
      id={id}
      type={type}
      placeholder={placeholder}
      value={value}
      autoFocus={autoFocus}
      onChange={handlerChange}
      autoComplete="off"
      onFocus={() => setFocus(true)}
      onMouseEnter={() => (!isFree ? null : setTooltipVisible(true))}
      onMouseLeave={() => (!isFree ? null : setTooltipVisible(false))}
    />
    {
      (styleType === 'headerSearchField' || 'cleanUp') && value && (
        <DefaultButton
          handleClick={() => resetSearchHandler()}
          buttonClass="resetSearchButton"
        >
          <CloseBlueIcon />
        </DefaultButton>
      )
    }
  </div>
);

SearchField.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  handlerChange: PropTypes.func,
  placeholder: PropTypes.string,
  focus: PropTypes.bool,
  setFocus: PropTypes.func,
  styleType: PropTypes.string,
  resetSearchHandler: PropTypes.func,
  setTooltipVisible: PropTypes.func,
  isFree: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string
};

SearchField.defaultProps = {
  type: '',
  id: '',
  value: '',
  styleType: '',
  handlerChange: () => undefined,
  placeholder: '',
  focus: false,
  setFocus: () => undefined,
  resetSearchHandler: () => undefined,
  setTooltipVisible: () => undefined,
  isFree: false,
  autoFocus: false,
  className: ''
};

export default React.memo(SearchField);
