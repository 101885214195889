import { createContext } from 'react';

interface ITableContext {
  isWatchlist: boolean,
  selectedItems: number[],
}
const TableContext = createContext<ITableContext>({
  isWatchlist: false,
  selectedItems: []
});

export default TableContext;
