import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { shallowEqual, useSelector } from 'react-redux';

import TargetListDropdownItem from './TargetListDropdownItem';

import stylesTargetList from './sass/TargeList.module.scss';
import TargetBottomMenu from './TargetBottomMenu';
import { handlerAwayClick } from '../../../../utils/helpers';
import useWatchList from '../../../../utils/hooks/useWatchList';

const TargetListDropdown = ({
  item,
  targetLists,
  theme,
  currentTargetList,
  addSymbolToTargetList,
  handleRemoveSymbolFromTargetList,
  setToggleTargetList,
  watchList,
  widgetId,
  showWeight,
  selectedItemFromWidget,
  selectedRowsFromWidget,
  clearSelections
}) => {
  const [targetListTop, setTargetListTop] = useState(0);
  const ref = useRef(null);
  const { copySymbolsToWatchlist, deleteSymbolsFromWatchlist } = useWatchList();
  const selectedSymbols = useSelector((state) => state.screenerState.selectedSymbols, shallowEqual) || [];

  const awayHandler = (event) => handlerAwayClick(event, ref, () => setToggleTargetList(false));

  const selectedSymbolsArray = widgetId ? selectedRowsFromWidget : selectedSymbols;

  const addSymbolToTargetListHandler = (list) => {
    if (!selectedSymbolsArray.length) {
      addSymbolToTargetList({
        symbol: item, targetList: list, currentTargetList, updateFlag: true
      });
    } else {
      Promise
        .all(
          targetLists.map((tl) => (
            deleteSymbolsFromWatchlist(
              selectedSymbolsArray,
              tl,
              !currentTargetList ? true : currentTargetList.id !== tl.id,
              false
            )))
        )
        .then(() => copySymbolsToWatchlist(list, selectedSymbolsArray))
        .then(() => {
          setToggleTargetList(false);
          clearSelections();
        });
    }
  };

  useEffect(() => {
    return () => {
      ref.current = null;
    };
  }, []);

  return (
    <ClickAwayListener
      onClickAway={awayHandler}
    >
      <div
        ref={ref}
        onMouseEnter={(event) => setTargetListTop(event.target.getBoundingClientRect().top)}
        className={`${stylesTargetList.targetListDropdown} ${theme}`}
      >
        {selectedSymbolsArray.length ? (
          <div className={stylesTargetList.selectionCounter}>
            <div className={stylesTargetList.selectedCount}>
              {selectedSymbolsArray.length}
            </div>
            <span>Add symbol to target list</span>
          </div>
        ) : null}
        <div className={stylesTargetList.targetList}>
          <TargetListDropdownItem
            addSymbolToTargetList={() => {
              if (currentTargetList) {
                const deleteSymbols = !selectedSymbols.length ? [item.sortIndex] : selectedSymbols;
                const symbolsToDelete = widgetId && selectedSymbolsArray.length ? selectedSymbolsArray : deleteSymbols;

                deleteSymbolsFromWatchlist(symbolsToDelete, currentTargetList, true, false);
                handleRemoveSymbolFromTargetList();
                return setToggleTargetList(false);
              }
              return null;
            }}
            className={
              !currentTargetList ? stylesTargetList.active : stylesTargetList.unActive
            }
          />

          {targetLists.map((list) => (
            <TargetListDropdownItem
              key={list.id}
              id={list.id}
              addSymbolToTargetList={() => {
                if (!currentTargetList || list.id !== currentTargetList.id) {
                  addSymbolToTargetListHandler(list);
                }
              }}
              className={
                currentTargetList?.id === list.id ? stylesTargetList.active : stylesTargetList.unActive
              }
              colorStart={list.data.color1}
              colorEnd={list.data.color2}
            />
          ))}
        </div>
        <TargetBottomMenu
          currentSymbol={item}
          awayHandler={() => setToggleTargetList(false)}
          selectedSymbols={selectedSymbolsArray}
          menuOffset={targetListTop}
          watchList={watchList}
          dropdownPosition={(window.innerWidth - ref?.current?.getBoundingClientRect().left) < 400 ? 'left' : 'right'}
          widgetId={widgetId}
          selectedItemFromWidget={selectedItemFromWidget}
          showWeight={showWeight}
          selectedRowsFromWidget={selectedRowsFromWidget}
        />
      </div>
    </ClickAwayListener>
  );
};

TargetListDropdown.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    sym: PropTypes.string,
    sortIndex: PropTypes.number
  }).isRequired,
  theme: PropTypes.string.isRequired,
  currentTargetList: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      color1: PropTypes.string,
      color2: PropTypes.string,
    })
  }),
  targetLists: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      color1: PropTypes.string,
      color2: PropTypes.string,
    })
  })).isRequired,
  handleRemoveSymbolFromTargetList: PropTypes.func.isRequired,
  addSymbolToTargetList: PropTypes.func.isRequired,
  setToggleTargetList: PropTypes.func.isRequired,
  clearSelections: PropTypes.func.isRequired,
  watchList: PropTypes.bool,
  widgetId: PropTypes.string,
  selectedItemFromWidget: PropTypes.number,
  showWeight: PropTypes.bool,
  selectedRowsFromWidget: PropTypes.arrayOf(PropTypes.number)
};

TargetListDropdown.defaultProps = {
  currentTargetList: null,
  watchList: false,
  widgetId: '',
  selectedItemFromWidget: 0,
  showWeight: false,
  selectedRowsFromWidget: []
};

export default TargetListDropdown;
