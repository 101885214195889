import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import RootStateTypes from '../../../../store/RootStateTypes';
import {
  Alert, AlertData,
  MixedAlert, ShortSymbol
} from '../types/AlertTypes';
import { ALERT_COLUMN_INDEXES, ALERT_COLUMNS, DATE_OPTIONS } from '../../../../constants/alerts';
import { IColumn } from '../types/Interfaces';
import { sortingAlerts } from '../../../../components/ChartContainer/utils/alertHelper';
import { setCurrentAlertData, setMixedAlertsColumns } from '../../../../store/alerts/slice';
import { ScreenerColumnData, ScreenerRowData, SortDataType } from '../../../../utils/Types';

const useAlertsDataWorker = (
  tableData: ScreenerRowData[],
  columnsData: ScreenerColumnData[],
  sortData: SortDataType[] | null
): void => {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state:RootStateTypes) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state:RootStateTypes) => state.watchlistState.alertsTab, shallowEqual);
  const alertColumns = useSelector((state:RootStateTypes) => state.accountState.userSettings.alertColumns);
  const symbolsList = useSelector((state:RootStateTypes) => state.screenerState.symbolsList, shallowEqual);
  const alertListFromStore = useSelector((state:RootStateTypes) => state?.alertsState?.alertsList, shallowEqual);
  const columnsOrderChanged = useSelector(
    (state:RootStateTypes) => state?.alertsState?.columnsOrderChanged, shallowEqual
  );

  const getDataForSymbol = (symbolShortName:string) => {
    const filteredSymbol = symbolsList?.filter((symbol: ShortSymbol) => symbol?.sym === symbolShortName);
    if (filteredSymbol?.length > 0) {
      return { icon: filteredSymbol[0]?.icon, sym: filteredSymbol[0]?.sym, sortIndex: filteredSymbol[0]?.index };
    }
    return { icon: '', sym: '', sortIndex: 0 };
  };

  const exportAlertDataToColumn = (alert:Alert, dataRows: ScreenerRowData[]) => {
    const formattedData:AlertData = [];
    const additionalColumns = columnsData?.slice(3, columnsData?.length);
    const filteredColumns = ALERT_COLUMNS.filter(
      (column:IColumn) => alertColumns.includes(column?.index) || column?.index > -3
    );
    dispatch(setMixedAlertsColumns([...filteredColumns, ...additionalColumns]));

    ALERT_COLUMNS.forEach((column:IColumn) => {
      switch (column.index) {
        case ALERT_COLUMN_INDEXES.STATUS:
          formattedData.push({ isActive: alert.isActive, id: alert.id });
          break;
        case ALERT_COLUMN_INDEXES.SYMBOL:
          formattedData.push(getDataForSymbol(alert?.symbol));
          break;
        case ALERT_COLUMN_INDEXES.TYPE:
          if (alertColumns.includes(column?.index)) {
            formattedData.push(alert?.type?.toLowerCase());
          }
          break;
        case ALERT_COLUMN_INDEXES.DATE:
          if (alertColumns.includes(column?.index)) {
            formattedData.push(
              new Date(alert?.createdAt)?.toLocaleString('en-US', (DATE_OPTIONS as Intl.DateTimeFormatOptions) || {})
            );
          }
          break;
        default:
          if (alertColumns.includes(column?.index)) {
            formattedData.push(alert.note || '-');
          }
          break;
      }
    });
    const resultForSymbol = dataRows?.find(
      (item: ScreenerRowData) => {
        const firstElement = item[0] as ShortSymbol;
        return firstElement?.sym === alert?.symbol;
      }
    );
    additionalColumns.forEach((column: ScreenerColumnData, index: number) => {
      if (resultForSymbol && resultForSymbol?.length > 0 && resultForSymbol?.length > additionalColumns?.length) {
        const value = resultForSymbol[index + 3] as MixedAlert;
        formattedData.push(value);
      }
    });
    return formattedData;
  };

  const formatAndSetCurrentData = (alerts:Alert[], dataRows: ScreenerRowData[]) => {
    const alertRows:AlertData[] = [];
    alerts?.forEach((item:Alert) => {
      alertRows.push(exportAlertDataToColumn(item, dataRows));
    });
    dispatch(setCurrentAlertData(alertRows));
  };

  useEffect(() => {
    if (selectedTab?.id === alertTab?.id) {
      formatAndSetCurrentData(
        sortData?.length ? sortingAlerts(sortData, alertListFromStore) : alertListFromStore,
        tableData
      );
    }
  }, [
    sortData,
    alertTab,
    tableData.length,
    columnsData.length,
    columnsOrderChanged,
    alertListFromStore,
    alertColumns.length
  ]);
};

export default useAlertsDataWorker;
