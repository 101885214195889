import React from 'react';
import PropTypes from 'prop-types';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styles from '../sass/DataPanel.module.scss';
import DataPanelDynamicContentItem from './DataPanelDynamicContentItem';
import useLastSymbolState from '../../../utils/hooks/useLastSymbolState';
import { TABLE_SCREEN_PATH } from '../../../constants/screener';
import { setSearchValue } from '../../../store/screener/actions';

const IndustryDropDown = ({
  dataRows, dataColumns, screenerId, getPointData
}) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector((state) => state.watchlistState.selectedTab, shallowEqual);
  const alertTab = useSelector((state) => state.watchlistState.alertsTab, shallowEqual);
  const pagePath = useSelector((state) => state.screenerState.pagePath, shallowEqual);

  const isAlertsTab = selectedTab?.id
    ? selectedTab?.id === alertTab?.id && pagePath !== TABLE_SCREEN_PATH.SCREENER : false;

  const { updateLastSymbolState } = useLastSymbolState();

  const onClick = (column, dataRowsIndex) => {
    if (dataRowsIndex === 0) {
      dispatch(setSearchValue(''));

      updateLastSymbolState({ sym: column[1], sortIndex: column[0] },
        true, pagePath !== TABLE_SCREEN_PATH.SCREENER && pagePath !== TABLE_SCREEN_PATH.DASHBOARDS, isAlertsTab);
    }
  };
  return (
    <div className={styles.industryDropDownContainer}>
      <div className={styles.industryTableHeader}>
        {
          dataColumns.slice(1).map((column) => (
            <div className={styles.industryColumnCell}>
              {column?.shortName || '-'}
            </div>
          ))
        }
      </div>
      <div className={styles.industryTableBody}>
        {
          dataRows.map((column, columnIndex) => (
            <div
              className={styles.industryTableHeader}
            >
              {
              column.slice(1).map((dataInfo, index) => {
                return (
                  <div
                    aria-hidden
                    className={`${styles.industryBodyCell}`}
                    onClick={() => onClick(column, index)}
                  >
                    <DataPanelDynamicContentItem
                      dataPoint={getPointData(dataColumns[index + 1]?.index)}
                      screenerId={screenerId}
                      symbolIndex={dataRows[columnIndex][0]}
                      currentData={dataInfo}
                    />
                  </div>
                );
              })
            }
            </div>
          ))
      }
      </div>
    </div>
  );
};

IndustryDropDown.propTypes = {
  dataRows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataColumns: PropTypes.arrayOf(PropTypes.shape({ index: PropTypes.number })).isRequired,
  screenerId: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  getPointData: PropTypes.func,
};

IndustryDropDown.defaultProps = {
  screenerId: null,
  getPointData: () => null
};

export default IndustryDropDown;
