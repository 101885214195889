type NumberDictionary<T> = { [key: number]: T };

type PreparedData = { [key: string]: NumberDictionary<string> };

const industrySectorWorker = (result: Array<Array<number | string>>): PreparedData => {
  const preparedData: PreparedData = {
    industry: {},
    sector: {},
  };
  result.forEach((item: (number | string)[]) => {
    const index = item[0];
    const sector = item[3];
    const industry = item[4];

    preparedData.industry[(index as number)] = industry as string;
    preparedData.sector[index as number] = sector as string;
  });

  return preparedData;
};

export default industrySectorWorker;
