import { createSlice } from '@reduxjs/toolkit';
import { TNativeFilter } from '../../components/FilterScreen/Types';

const INITIAL_STATE = {
  stateFilters: [],
  requestState: [] as TNativeFilter[],
  selectedGroupFilters: [],
  categoryFilter: ''
};

const filtersSlice = createSlice({
  name: 'filtersSlice',
  initialState: INITIAL_STATE,
  reducers: {
    setStateFiltersAction: (state, action) => {
      state.stateFilters = action.payload;
    },
    setRequestStateAction: (state, action) => {
      state.requestState = action.payload;
    },
    setGroupFiltersViewAction: (state, action) => {
      state.selectedGroupFilters = action.payload;
    },
    setCategoryName: (state, action) => {
      state.categoryFilter = action.payload;
    }
  }
});

export const {
  setStateFiltersAction,
  setRequestStateAction,
  setGroupFiltersViewAction,
  setCategoryName,
} = filtersSlice.actions;

export default filtersSlice;
