import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyPlotType, StudyShapesPlotPreferences, StudyStylesInfo } from '../../charting_library/charting_library';

export default function webbyQuick(mPineJS: PineJS): CustomIndicator {
  return {
    name: `DV - Webby's Quick & Grateful Dead RS`,
    metainfo: {
      name: `DV - Webby's Quick & Grateful Dead RS`,
      id: 'TL-QGDRS@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: `QGDRS`,
      description: `DV - Webby's Quick & Grateful Dead RS`,
      is_price_study: true,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit'
      },
      styles: {
        plot_0: {
          title: 'RS'
        },
        plot_2: {
          title: 'Fast MA'
        },
        plot_4: {
          title: 'Slow MA'
        },
        quick_break: {
          title: 'Quick Break',
          size: 'medium' as StudyStylesInfo['size'],
        },
        quick_sand: {
          title: 'Quick Sand',
          size: 'medium' as StudyStylesInfo['size'],
        },
        gdb: {
          title: 'Grateful Dead Break',
          size: 'medium' as StudyStylesInfo['size'],
        },
      },
      plots: [
        {
          id: 'plot_0',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_1',
          palette: 'palette_0',
          target: 'plot_0',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_2',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_3',
          palette: 'palette_1',
          target: 'plot_2',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'plot_4',
          type: 'line' as StudyPlotType.Line
        },
        {
          id: 'plot_5',
          palette: 'palette_2',
          target: 'plot_4',
          type: 'colorer' as StudyPlotType.Colorer
        },
        {
          id: 'quick_break',
          type: 'shapes' as StudyPlotType.Shapes,
        },
        {
          id: 'quick_sand',
          type: 'shapes' as StudyPlotType.Shapes,
        },
        {
          id: 'gdb',
          type: 'shapes' as StudyPlotType.Shapes,
        },
      ],
      inputs: [
        {
          defval: 'SPY',
          id: 'indexsymbol',
          name: 'Index to Compare',
          type: 'symbol' as StudyInputType.Symbol
        },
        {
          defval: 8,
          id: 'qma_length',
          max: 50,
          min: 3,
          name: 'Quick MA Length',
          step: 1,
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 'SMA',
          id: 'q_type',
          name: 'Quick MA Type',
          options: [
            'EMA',
            'SMA'
          ],
          type: 'text' as StudyInputType.Text
        },
        {
          defval: 21,
          id: 'sma_length',
          max: 200,
          min: 3,
          name: 'Slow MA Length',
          step: 1,
          type: 'integer' as StudyInputType.Integer
        },
        {
          defval: 'SMA',
          id: 's_type',
          name: 'Slow MA Type',
          options: [
            'EMA',
            'SMA'
          ],
          type: 'text' as StudyInputType.Text
        },
        {
          defval: 15,
          id: 'offset',
          max: 500,
          min: 1,
          name: 'Vertical offset of RS Line',
          step: 1,
          type: 'integer' as StudyInputType.Integer
        },
      ],
      palettes: {
        palette_0: {
          colors: {
            0: {
              name: 'Color RS Line'
            }
          },
          valToIndex: {
            0: 0
          }
        },
        palette_1: {
          colors: {
            0: {
              name: 'Color Quick MA'
            }
          },
          valToIndex: {
            1: 0
          }
        },
        palette_2: {
          colors: {
            0: {
              name: 'Color Slow MA'
            }
          },
          valToIndex: {
            2: 0
          }
        },
      },
      defaults: {
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#2962ff',
                style: 0,
                width: 2
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#FF5252',
                style: 0,
                width: 1
              }
            }
          },
          palette_2: {
            colors: {
              0: {
                color: '#FFEB3B',
                style: 0,
                width: 1
              }
            }
          },
        },
        inputs: {
          indexsymbol: 'SPY',
          qma_length: 8,
          q_type: 'SMA',
          sma_length: 21,
          s_type: 'SMA',
          offset: 15,
        },
        styles: {
          plot_0: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          plot_4: {
            color: '#2962ff',
            display: 15,
            linestyle: 0,
            linewidth: 1,
            plottype: 0,
            trackPrice: false,
            transparency: 0
          },
          quick_break: {
            color: '#E040FB',
            location: 'Absolute' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_circle',
            transparency: 70
          },
          quick_sand: {
            color: '#FF9800',
            location: 'Absolute' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_circle',
            transparency: 70
          },
          gdb: {
            color: '#880E4F',
            location: 'Absolute' as StudyShapesPlotPreferences['location'],
            plottype: 'shape_circle',
            transparency: 70
          },
        }
      },
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
        this.qsLev = 0.0;
        this.qsBool = false;
      };
      this.main = (context, inputs) => {
        this._context = context;

        const [
          indexsymbol,
          qma_length,
          q_type,
          sma_length,
          s_type,
          offset
        ] = getInputArray({
          inputs,
          length: 6
        });

        this._context.new_sym(indexsymbol, mPineJS.Std.period(this._context));
        this._context.select_sym(0);
        const mainSymbolTime = this._context.new_unlimited_var(this._context.symbol.time);
        // Select the secondary symbol
        this._context.select_sym(1);
        const secondarySymbolTime = this._context.new_unlimited_var(this._context.symbol.time);

        // Align the times of the secondary symbol to the main symbol
        const secondarySymbolClose = this._context.new_unlimited_var(mPineJS.Std.close(this._context));
        const newSymClose = secondarySymbolClose.adopt(secondarySymbolTime, mainSymbolTime, 1);

        this._context.select_sym(0);
        //calculations

        const rs = (mPineJS.Std.close(this._context) / newSymClose) * 100;
        const quickMA = q_type === 'EMA' ? mPineJS.Std.ema(this._context.new_unlimited_var(rs), qma_length, this._context) :
          mPineJS.Std.sma(this._context.new_unlimited_var(rs), qma_length, this._context);

        const slowMA = s_type === 'EMA' ? mPineJS.Std.ema(this._context.new_unlimited_var(rs), sma_length, this._context) :
          mPineJS.Std.sma(this._context.new_unlimited_var(rs), sma_length, this._context);

        const rsSeries = context.new_unlimited_var(rs);
        const quickMASeries = context.new_unlimited_var(quickMA);
        const slowMASeries = context.new_unlimited_var(slowMA);

        const quickBreak = rsSeries.get(0) < quickMASeries.get(0) && rsSeries.get(1) >= quickMASeries.get(1);
        const quickSand = rsSeries.get(0) < quickMASeries.get(0) && rsSeries.get(0) < this.qsLev && !this.qsBool;
        const gdb = rsSeries.get(0) < slowMASeries.get(0) && rsSeries.get(1) >= slowMASeries.get(1);
        const reset = rsSeries.get(0) > quickMASeries.get(0) && rsSeries.get(1) <= quickMASeries.get(1);

        let showquickBreak = false;
        let showquickSand = false;
        let showquickgdb = false;
        if (quickBreak) {
          showquickBreak = true;
          this.qsLev = rs;
        }
        if (quickSand) {
          showquickSand = true;
          this.qsBool = true;
        }
        if (gdb) {
          showquickgdb = true;
        }
        if (reset) {
          this.qsLev = NaN;
          this.qsBool = false;
        }
        return [
          rs * offset,
          0,
          quickMA * offset,
          0,
          slowMA * offset,
          0,
          showquickBreak ? quickMA * offset : NaN,
          showquickSand ? rs * offset : NaN,
          showquickgdb ? slowMA * offset : NaN,
        ];
      };
    }
  }
}
