import { DragWidgetTypes } from '../../../pages/Dashboards/types/DashboardEnums';
import { FILTER_SETTINGS } from '../../../constants/screener';

export const GRID_ROWS = 36;
export const GRID_ROWS_MASK = 108;
export const GRID_COLUMNS = 36;
export const GRID_COLUMNS_MASK = 108;
export const GRID_PERFORMANCE_WIDTH = 16;
export const GRID_PERFORMANCE_HEIGHT = 16;
export const GRID_WIDGET_START_WIDTH = 14;
export const GRID_WIDGET_START_HEIGHT = 14;
export const GRID_WIDGET_SCREEN_START_WIDTH = 6;
export const GRID_WIDGET_SCREEN_START_HEIGHT = 12;
export const GRID_WIDGET_START_HEIGHT_STATS_CHART = 5;
export const GRID_WIDGET_START_WIDTH_STATS_CHART = 12;
export const GRID_WIDGET_PSC_START_WIDTH = 6;
export const GRID_WIDGET_PSC_START_HEIGHT = 14;
export const ALL_ITEM = 'ALL';
export const EMPTY_DL = [[[FILTER_SETTINGS.ZERO, FILTER_SETTINGS.ZERO, []]]];
export const DRAG_ELEMENTS_CONSTRAINTS = [
  {
    type: DragWidgetTypes.SCREEN,
    w: GRID_WIDGET_SCREEN_START_WIDTH,
    h: GRID_WIDGET_SCREEN_START_HEIGHT,
  },
  {
    type: DragWidgetTypes.TV,
    w: GRID_WIDGET_START_WIDTH,
    h: GRID_WIDGET_START_HEIGHT,
  },
  {
    type: DragWidgetTypes.STATS,
    w: GRID_WIDGET_START_WIDTH_STATS_CHART,
    h: GRID_WIDGET_START_HEIGHT_STATS_CHART,
  },
  {
    type: DragWidgetTypes.POSITIONSIZE,
    w: GRID_WIDGET_PSC_START_WIDTH,
    h: GRID_WIDGET_PSC_START_HEIGHT,
  },
  {
    type: DragWidgetTypes.PERFORMANCE,
    w: GRID_PERFORMANCE_WIDTH,
    h: GRID_PERFORMANCE_HEIGHT,
  },
  {
    type: DragWidgetTypes.HEATMAP,
    w: GRID_PERFORMANCE_WIDTH,
    h: GRID_PERFORMANCE_HEIGHT,
  },
  {
    type: DragWidgetTypes.BUBBLE,
    w: GRID_PERFORMANCE_WIDTH,
    h: GRID_PERFORMANCE_HEIGHT,
  },
];
