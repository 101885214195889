import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../../Icon/Icon';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';

import { IMG_PATH } from '../../../constants/screener';
import { DEFAULT_ICON } from '../../../constants/alerts';

import styles from '../sass/ScreenerTable.module.scss';
import TargetListWrapperIcon from '../../../assets/images/icons/targetList/TargetListWrapperIcon';

const Symbol = ({
  id,
  itemIcon,
  colorEnd,
  colorStart,
  setToggleTargetList,
  hideCheckbox,
  iconSize,
  onMouseEnter,
  onMouseLeave,
  showTargetPlus,
}) => {
  return (
    <div
      aria-hidden
      onClick={setToggleTargetList}
      className={`${styles.symbolImgWrapper} ${iconSize === 18 && styles.plusImgSmall} 
      ${hideCheckbox ? styles.hideCheckbox : ''} symbol`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {showTargetPlus ? (
        <div
          aria-hidden
          className={`${iconSize === 18 && styles.plusImgSmall} ${styles.plusImg}`}
          role="button"
          onMouseLeave={onMouseLeave}
        >
          <PlusIcon />
        </div>
      ) : (
        <Icon
          icon={itemIcon ? `${IMG_PATH}${itemIcon}` : `${IMG_PATH}${DEFAULT_ICON}`}
          setDefaultClass={false}
          iconClass={`${styles.symbolImg} ${(id && colorStart && colorEnd) ? styles.activeTarget : ''}`}
          componentStyle={iconSize && { width: iconSize, height: iconSize }}
        />
      )}

      {colorStart && !showTargetPlus && (
        <TargetListWrapperIcon
          colorStart={colorStart}
          iconSize={iconSize}
        />
      )}
    </div>
  );
};

Symbol.propTypes = {
  id: PropTypes.number,
  iconSize: PropTypes.number,
  colorStart: PropTypes.string,
  colorEnd: PropTypes.string,
  itemIcon: PropTypes.string,
  setToggleTargetList: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  hideCheckbox: PropTypes.bool,
  showTargetPlus: PropTypes.bool,
};

Symbol.defaultProps = {
  id: null,
  iconSize: null,
  colorStart: null,
  colorEnd: null,
  itemIcon: null,
  hideCheckbox: false,
  onMouseEnter: () => null,
  onMouseLeave: () => null,
  showTargetPlus: false,
};

export default Symbol;
