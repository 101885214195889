import React, { useCallback, useEffect, useState } from 'react';

import { shallowEqual, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import Button from '../../Button/Button';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/closeBlack.svg';
import { ReactComponent as UpdateIcon } from '../../../assets/images/icons/changeName.svg';
import { ReactComponent as InfoIcon } from '../../../assets/images/icons/infoCircle.svg';
import FilterHeaderBlock from './FilterHeaderBlock';
import TextField from '../../TextField/TextField';
import { MODAL_TITLES } from '../../../constants/screener';
import ThemeVariants from '../../../constants/theme';
import { useTheme } from '../../../utils/hooks/useTheme';
import ButtonListenerWrapper from '../../ButtonListenerWrapper/ButtonListenerWrapper';
import { InfoTooltipDarkStyles, InfoTooltipStyles } from '../../Screener/ScreenerTooltipStyles';
import styles from '../sass/FilterScreen.module.scss';
import RootStateTypes from '../../../store/RootStateTypes';

interface IFilterHeader {
  closeBar: () => void;
  headerFilter: string;
  openModalHandler: (title: string, isEdit: boolean) => void;
  description: string;
  disabled: boolean;
  closePanel: () => void;
}

const FilterHeader = ({
  closeBar,
  headerFilter,
  openModalHandler,
  description,
  disabled,
  closePanel
}: IFilterHeader): React.ReactElement => {
  const [viewTool, setViewTool] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);
  const newScreenFlag = useSelector((state: RootStateTypes) => state.newScreenState.newScreenFlag, shallowEqual);

  const { theme } = useTheme();
  // eslint-disable-next-line
  // @ts-ignore
  const isMobile = ((navigator?.msMaxTouchPoints > 0) || (navigator.maxTouchPoints > 0));

  let timeout: NodeJS.Timeout | null = null;

  const onMouseLeave = useCallback(() => setViewTool(false), []);
  const onMouseEnter = useCallback(() => setViewTool(true), []);

  const title = selectedScreen?.title;

  useEffect(() => {
    if (showDescription && isMobile) {
      clearTimeout(timeout as NodeJS.Timeout);
      timeout = setTimeout(() => setShowDescription(false), 1500);
    }

    return () => clearTimeout(timeout as NodeJS.Timeout);
  }, [showDescription]);

  return (
    <ButtonListenerWrapper
      handleCancel={closeBar}
      handleConfirm={() => { }}
    >
      <>
        <Button
          buttonClass={styles.closeButton}
          handleClick={closeBar}
        >
          <CloseIcon />
        </Button>
        <div className={styles.row}>
          <FilterHeaderBlock text={headerFilter} />
          {title && !newScreenFlag && (
            <div className={styles.currentScreenTitle}>
              <div
                onMouseLeave={onMouseLeave}
                onMouseEnter={onMouseEnter}
                className={styles.headerWrapper}
              >
                <TextField
                  text={title.length <= 15 ? title : `${title.slice(0, 15)}...`}
                  styleType="currentScreenName"
                />
                {viewTool && title.length >= 15 && (
                  <div className={`${styles.toolDropDown} ${title.length > 45 && styles.toolDropDownBreak}`}>
                    {title}
                  </div>
                )}
              </div>
              {description && (
                <Tooltip
                  title={description}
                  open={showDescription}
                  componentsProps={theme === ThemeVariants.DARK_MODE ? InfoTooltipDarkStyles : InfoTooltipStyles}
                  arrow
                >
                  <InfoIcon
                    onMouseEnter={() => setShowDescription(true)}
                    onMouseLeave={() => setShowDescription(false)}
                    onClick={() => !showDescription && setShowDescription(true)}
                  />
                </Tooltip>
              )}
              <Button
                handleClick={() => {
                  if (!disabled) {
                    closePanel();
                    openModalHandler(MODAL_TITLES.EDIT, true);
                  }
                }}
                buttonClass={`${styles.editNameButton} ${disabled && styles.editNameButtonDisabled}`}
              >
                <UpdateIcon />
              </Button>
            </div>
          )}
        </div>
      </>
    </ButtonListenerWrapper>
  );
};

export default FilterHeader;
