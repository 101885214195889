import React from 'react';
import ResultRow from './ResultRow';

const ResultList = ({
  list,
  rowClickHandler,
  hoveredItem,
  setHoveredItem,
  activeResult,
  lastElementRef
}) => (
  list?.map((item, index) => (
    <ResultRow
      img={item.icon}
      label={item.sym}
      text={item.text}
      title={item.name}
      hoveredItem={hoveredItem}
      setHoveredItem={setHoveredItem}
      handleClick={() => rowClickHandler(item)}
      index={item.index}
      active={index === activeResult}
      key={[item.sym, index].join('_')}
      ref={list.length === index + 1 ? lastElementRef : null}
    />
  ))
);

export default ResultList;
