import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { DEFAULT_TIMEZONE } from '../../../constants/account';
import {
  ARRAY_RESOLUTIONS, MS_INTERVAL, SLICE_DAY_SETTINGS, TV_EVENTS
} from '../../../constants/screener';
import { PERIOD_SOURCES } from '../../../constants/statsChart';
import { WS_CHANNELS } from '../../../constants/socketConnection';
import { messageIdGenerator } from './chartingHelper';
import { rtProcessor } from '../../../services/ChartPrefetchServices/RtDataProcessor';
import { rtAccumProcessor } from '../../../services/ChartPrefetchServices/RtAccumProcessor';
import { STATS_CHART_SOURCES } from '../../../constants/storage';
import { MarketSession } from '../../MarketDropdown/types/MarketEnums';

const momentExtended = extendMoment(moment);

export const changeActiveSymbol = ({
  symbol,
  contentHandler,
  tradingViewWidget,
}) => {
  if (tradingViewWidget && symbol) {
    tradingViewWidget.onChartReady(() => {
      tradingViewWidget?.watermark().setContentProvider((data) => contentHandler(data));
      tradingViewWidget?.activeChart().setSymbol(symbol);
    });
  }
};

export const slicedDaysData = {
  data: {}
};

export const sliceDayHandler = (marketType, floatBar) => {
  const resolutionArr = [1, 3, 5, 10, 15, 30, 39, 60, 65, 78, 120, 130, 195, 240, '1D', '1W', '1M', '3M', '6M', '12M'];
  const floatTime = floatBar ? floatBar[0] * 1000 : moment().valueOf();

  const START_DAY_DYNAMIC = marketType === MarketSession.REGULAR
    ? moment(floatTime).tz(DEFAULT_TIMEZONE).startOf('day').hours(9)
      .minutes(30)
      .valueOf()
    : moment(floatTime).tz(DEFAULT_TIMEZONE).startOf('day').hours(4)
      .minutes(0)
      .valueOf();

  const end = moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.DAY);
  const dayRange = momentExtended.range(START_DAY_DYNAMIC, end);
  const slicedDay = {};

  const sixMonth = moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.YEAR)
    .add(6, SLICE_DAY_SETTINGS.MONTH)
    .valueOf();

  const startOfPeriodHandler = (per) => {
    switch (per) {
      case resolutionArr[14]:
        return {
          start: moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.DAY).valueOf(),
          end: moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.DAY).valueOf()
        };
      case resolutionArr[15]:
        return {
          start: moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.WEEK)
            .add(1, SLICE_DAY_SETTINGS.DAY)
            .valueOf(),
          end: moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.WEEK).valueOf()
        };
      case resolutionArr[16]:
        return {
          start: moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.MONTH).valueOf(),
          end: moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.MONTH).valueOf()
        };
      case resolutionArr[17]:
        return {
          start: moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.QUARTER).valueOf(),
          end: moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.QUARTER).valueOf()
        };
      case resolutionArr[18]:
        return {
          start: moment(floatTime).tz(DEFAULT_TIMEZONE).valueOf() < sixMonth
            ? moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.YEAR).valueOf() : sixMonth,
          end: moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.QUARTER).valueOf()
        };
      case resolutionArr[19]:
        return {
          start: moment(floatTime).tz(DEFAULT_TIMEZONE).startOf(SLICE_DAY_SETTINGS.YEAR).valueOf(),
          end: moment(floatTime).tz(DEFAULT_TIMEZONE).endOf(SLICE_DAY_SETTINGS.YEAR).valueOf()
        };
      default: return null;
    }
  };

  for (let i = 0; i <= resolutionArr.length - 1; i += 1) {
    if (i <= 13) {
      const arrayMinutes = Array.from(dayRange.by(SLICE_DAY_SETTINGS.MINUTES, { step: +resolutionArr[i] }));
      slicedDay[resolutionArr[i]] = arrayMinutes.map((item) => {
        return {
          start: item.valueOf(),
          end: moment(item).tz(DEFAULT_TIMEZONE).add(+resolutionArr[i], SLICE_DAY_SETTINGS.MINUTES)
            .valueOf()
        };
      });
    } else {
      slicedDay[resolutionArr[i]] = [startOfPeriodHandler(resolutionArr[i])];
    }
  }

  slicedDay.startOfDay = START_DAY_DYNAMIC;
  slicedDaysData.data = { ...slicedDay };
};

export const convertResolutionTimeframe = (resolution) => {
  const resolutionMinutes = ['1', '3', '5', '10', '15', '30', '39', '65', '78', '130', '195'];
  const resolutionHours = ['60', '120', '240'];

  if (resolutionMinutes.includes(resolution)) {
    return `${resolution}m`;
  }

  if (resolutionHours.includes(resolution)) {
    return `${resolution / 60}H`;
  }

  return resolution;
};

export const cutCacheHandler = (rtData, acc, symbolIndex) => {
  if (!acc.lastReadBar?.time) {
    return rtData[symbolIndex].data;
  }

  const lastIndex = rtData[symbolIndex].data.findIndex(
    (item) => item[0] * MS_INTERVAL.SECOND === acc.lastReadBar?.time
  );

  return rtData[symbolIndex].data.slice(lastIndex === -1 ? 0 : lastIndex - 2, rtData[symbolIndex].data.length);
};

export const tvUnsubscribeWorker = (channelToSubscription, item, subscriberUID) => {
  const subscriptionItem = channelToSubscription.get(item);

  if (!subscriptionItem) {
    return;
  }

  const handlerIndex = subscriptionItem.handlers.findIndex((handler) => handler.id === subscriberUID);
  const rtItemFromCache = rtProcessor.rtData[subscriptionItem.symbolIndex];

  if (handlerIndex !== -1) {
    subscriptionItem.handlers.splice(handlerIndex, 1);

    if (subscriptionItem.handlers.length === 0 && rtItemFromCache) {
      const listenerIndex = rtItemFromCache.listeners.findIndex(
        (listenerItem) => listenerItem === subscriptionItem.channelString
      );

      rtProcessor.rtRemoveListener(subscriptionItem.symbolIndex, listenerIndex);
      rtAccumProcessor.removeAccumSubItem(subscriptionItem.symbolIndex, subscriptionItem.channelString);

      if (rtItemFromCache.listeners.length === 0) {
        rtProcessor.rtCellCleanUpAdditionalData(subscriptionItem.symbolIndex);
      }

      channelToSubscription.delete(item);
    }
  }
};

export const changeStatsSourceHandler = (dispatch, interval, updateStoreHandler) => {
  if (interval === ARRAY_RESOLUTIONS[11]) {
    updateStoreHandler(STATS_CHART_SOURCES, PERIOD_SOURCES.SHORT_Y);
  }

  if (interval === ARRAY_RESOLUTIONS[10]) {
    updateStoreHandler(STATS_CHART_SOURCES, PERIOD_SOURCES.SHORT_Q);
  }
};

export const layoutStructureValidator = (layout) => {
  let result = layout;

  const symbolFromLayout = JSON.parse(layout)?.symbol;

  if (typeof symbolFromLayout !== 'string') {
    const sym = symbolFromLayout[0];
    const regexNestedPattern = new RegExp(`\\[\\\\"${sym}\\\\\\"\\]`, 'g');
    const regexPattern = new RegExp(`\\[\\"${sym}\\"\\]`, 'g');

    result = layout.replace(regexNestedPattern, `\\"${sym}\\"`);
    result = result.replace(regexPattern, `"${sym}"`);
  }

  return result;
};

export const fetchEpsSalesEstimates = (symIndex, property, type, connection) => {
  const socket = connection?.getSocket();
  const messageId = messageIdGenerator();
  return new Promise((resolve) => {
    const transformData = (item) => {
      const time = item.reportDate ? item.reportDate * 1000 : item.fiscalDate * 1000;
      const price = item[property][type];

      return {
        time,
        close: price,
        open: price,
        high: price,
        low: price,
        volume: item.source === 'E' ? 1 : 2,
      };
    };

    connection?.messageEmitter?.on(WS_CHANNELS.SYMBOL_DATA_BATCH, (res) => {
      if (messageId === res.messageId) {
        const dataBasedSource = res.data[0]?.statsChartQ;
        if (dataBasedSource.length) {
          const statsData = dataBasedSource ? dataBasedSource.filter(
            (item) => item?.eps !== undefined && item?.sales !== undefined
          ) : [];
          const bars = statsData.map(transformData);
          resolve(bars);
        } else {
          resolve([]);
        }
      }
    });

    if (socket && socket.readyState === WebSocket.OPEN) {
      connection?.sendMessage(WS_CHANNELS.SYMBOL_DATA_BATCH, {
        messageId,
        requests: [{
          symbolIndex: symIndex,
          statsChartQ: true,
        }]
      });
    } else {
      resolve([]);
    }
  });
};

export const shapeMoveListener = (chart, setSelectedShapeId) => {
  chart?.subscribe(TV_EVENTS.DRAWING_EVENT, (sourceId, drawingEventType) => {
    if (drawingEventType === 'click' || drawingEventType === 'move' || drawingEventType === 'create') {
      setSelectedShapeId(sourceId);
    }
  });
  chart?.subscribe(TV_EVENTS.MOUSE_DOWN, () => {
    setSelectedShapeId(null);
  });
};

export const chartCleanUp = (chart) => {
  if (chart) {
    chart.activeChart().getTimeScale().defaultRightOffset().unsubscribe();
    chart.activeChart().getTimeScale().defaultRightOffsetPercentage().unsubscribe();
    chart.activeChart().getTimeScale().defaultRightOffset().unsubscribe();
    chart.activeChart().getTimeScale().defaultRightOffsetPercentage().unsubscribe();
    chart.activeChart().onIntervalChanged().unsubscribe();
    chart.unsubscribe(TV_EVENTS.AUTO_SAVE_EVENT);
    chart.unsubscribe(TV_EVENTS.TOGGLE_SIDEBAR);
    chart.unsubscribe(TV_EVENTS.MOUSE_DOWN);
    chart.unsubscribe(TV_EVENTS.DRAWING_EVENT);
    chart.unsubscribe(TV_EVENTS.LINE_TOOL_CHANGED);
    chart.intervalSync().unsubscribe();
    chart.timeSync().unsubscribe();
    chart.symbolSync().unsubscribe();
    chart.dateRangeSync().unsubscribe();
    chart.symbolSync().unsubscribe();
    chart?.remove();
  }
};
