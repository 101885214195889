import React from 'react';

import styles from './sass/CounterComponent.module.scss';
import TextField from '../TextField/TextField';

interface ICounterComponent {
  mainCounter: number;
  selectedCounter: number;
}

const CounterComponent = ({
  mainCounter,
  selectedCounter
}: ICounterComponent): React.ReactElement => (
  <div className={styles.counter}>
    <TextField text={selectedCounter} styleType="selectedCounter" />
    {' '}
    <TextField text="|" styleType="mainCounter" />
    {' '}
    <TextField text={mainCounter} styleType="mainCounter" />
  </div>
);

export default CounterComponent;
