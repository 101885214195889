import {
  DASHBOARD_ITEM_TYPE_PRESETS,
  DASHBOARD_ITEMS,
  DASHBOARD_FOLDERS,
  DASHBOARD_ITEM_TYPE_SCREENER,
  DASHBOARD_ITEM_TYPE_COLUMNS,
  DASHBOARD_ITEM_TYPE_DATA_PANEL,
  DASHBOARD_ITEM_TYPE_PRESETS_FOLDERS,
  DASHBOARD_ITEM_TYPE_WATCHLIST,
  DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST_FOLDERS,
  DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST,
  DASHBOARD_ITEM_TYPE_DATA_PANEL_PRESETS,
  DASHBOARD_ITEM_TYPE_DASHBOARDS_PRESET,
  DASHBOARD_ITEM_TYPE_DASHBOARDS,
  DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS
} from '../constants/paths';

import constants from '../constants/filters';
import { findIndexItem } from './helpers';
import { FIND_NAME } from '../constants/screener';

export const getUrl = (type) => {
  let url = '';
  switch (type) {
    case constants.folder:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.folderDeeplist:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.screener:
      url = DASHBOARD_ITEMS;
      break;
    case constants.deepList:
      url = DASHBOARD_ITEMS;
      break;
    case constants.preset:
      url = DASHBOARD_ITEMS;
      break;
    case constants.presetFolder:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.presetDeeplist:
      url = DASHBOARD_ITEMS;
      break;
    case constants.presetFolderDeeplist:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.columnSetFolder:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.columns:
      url = DASHBOARD_ITEMS;
      break;
    case constants.columnSetFolderPreset:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.columnsPreset:
      url = DASHBOARD_ITEMS;
      break;
    case constants.dataPanel:
      url = DASHBOARD_ITEMS;
      break;
    case constants.dataPanelFolder:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.dataPanelPresets:
      url = DASHBOARD_ITEMS;
      break;
    case constants.dataPanelPresetFolder:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.userDashboard:
      url = DASHBOARD_ITEMS;
      break;
    case constants.userDashboardFolder:
      url = DASHBOARD_FOLDERS;
      break;
    case constants.userDashboardPresets:
      url = DASHBOARD_ITEMS;
      break;
    case constants.userDashboardPresetsFolder:
      url = DASHBOARD_FOLDERS;
      break;
    default:
      break;
  }
  return url;
};

export const getUrlSecond = (type) => {
  let url = '';
  switch (type) {
    case constants.folder:
      url = DASHBOARD_ITEM_TYPE_SCREENER;
      break;
    case constants.folderDeeplist:
      url = DASHBOARD_ITEM_TYPE_WATCHLIST;
      break;
    case constants.deepList:
      url = DASHBOARD_ITEM_TYPE_WATCHLIST;
      break;
    case constants.screener:
      url = DASHBOARD_ITEM_TYPE_SCREENER;
      break;
    case constants.preset:
      url = DASHBOARD_ITEM_TYPE_PRESETS;
      break;
    case constants.presetFolder:
      url = DASHBOARD_ITEM_TYPE_PRESETS_FOLDERS;
      break;
    case constants.presetDeeplist:
      url = DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST;
      break;
    case constants.presetFolderDeeplist:
      url = DASHBOARD_ITEM_TYPE_PRESETS_WATCHLIST_FOLDERS;
      break;
    case constants.columnSetFolder:
      url = DASHBOARD_ITEM_TYPE_COLUMNS;
      break;
    case constants.columns:
      url = DASHBOARD_ITEM_TYPE_COLUMNS;
      break;
    case constants.columnSetFolderPreset:
      url = DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS;
      break;
    case constants.columnsPreset:
      url = DASHBOARD_ITEM_TYPE_COLUMNS_PRESETS;
      break;
    case constants.dataPanel:
      url = DASHBOARD_ITEM_TYPE_DATA_PANEL;
      break;
    case constants.dataPanelFolder:
      url = DASHBOARD_ITEM_TYPE_DATA_PANEL;
      break;
    case constants.dataPanelPresets:
      url = DASHBOARD_ITEM_TYPE_DATA_PANEL_PRESETS;
      break;
    case constants.dataPanelPresetFolder:
      url = DASHBOARD_ITEM_TYPE_DATA_PANEL_PRESETS;
      break;
    case constants.userDashboard:
      url = DASHBOARD_ITEM_TYPE_DASHBOARDS;
      break;
    case constants.userDashboardFolder:
      url = DASHBOARD_ITEM_TYPE_DASHBOARDS;
      break;
    case constants.userDashboardPresets:
      url = DASHBOARD_ITEM_TYPE_DASHBOARDS_PRESET;
      break;
    case constants.userDashboardPresetsFolder:
      url = DASHBOARD_ITEM_TYPE_DASHBOARDS_PRESET;
      break;
    default:
      break;
  }
  return url;
};

const updateArray = (array, index) => {
  let newArray = [...array];
  if (index !== -1) {
    const itemRecommended = newArray[index];
    newArray.splice(index, 1);
    newArray = [itemRecommended, ...newArray];
  }
  return newArray;
};

const checkFolder = (item) => {
  let check = false;
  if (item?.type === FIND_NAME.FOLDER) {
    check = findIndexItem(item?.items, FIND_NAME.TITLE, FIND_NAME.RECOMMENDED) !== -1;
  }
  return check;
};

export const upRecommended = (array) => {
  let newArray = [...array];

  const indexItem = findIndexItem(newArray, FIND_NAME.TITLE, FIND_NAME.RECOMMENDED);
  if (indexItem !== -1) {
    newArray = updateArray(newArray, indexItem);
  } else {
    const folderWithRecommended = newArray.filter((item) => checkFolder(item));
    const indexItemFolder = findIndexItem(newArray, FIND_NAME.ID, folderWithRecommended[0]?.id);
    newArray = updateArray(newArray, indexItemFolder);
  }
  return newArray;
};
