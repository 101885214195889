import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FILTERS_TYPES } from '../../constants/rangeDatePicker';
import styles from './sass/PeriodButtons.module.scss';

const PeriodButtons = ({ type, handleSetPeriod, selected }) => {
  const { t } = useTranslation();
  const buttons = FILTERS_TYPES[Number(type)];

  const getIsSelected = (actions) => {
    return `${styles?.item} ${selected.join('') === actions.join('') ? styles.selected : ''}`;
  };

  return (
    <div className={styles.container}>
      {buttons?.length && buttons?.map((button, indexItem) => (
        <button
          className={getIsSelected(button?.actions)}
          onClick={() => handleSetPeriod(button.actions)}
          type="button"
          key={[button.label, indexItem].join('_')}
        >
          {t(button.label)}
        </button>
      ))}
    </div>
  );
};

export default PeriodButtons;

PeriodButtons.propTypes = {
  type: PropTypes.string,
  handleSetPeriod: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
};

PeriodButtons.defaultProps = {
  type: '',
  handleSetPeriod: () => {},
  selected: [],
};
