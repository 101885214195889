import React from 'react';
import { ClickAwayListener } from '@mui/material';
import styles from './sass/EducationComponents.module.scss';
import { ReactComponent as IconStroke } from '../../assets/images/icons/screener/color_stroke.svg';
import { ReactComponent as RadioIcon } from '../../assets/images/icons/education/radio.svg';
import { ReactComponent as RadioActiveIcon } from '../../assets/images/icons/education/radio_active.svg';
import { ISortBlock } from './types/EducationComponentsInterfaces';
import { SORT_TYPES } from './types/EducationComponentsEnum';

const SortBlock = ({ params, setParams }: ISortBlock): React.ReactElement => {
  const [open, setOpen] = React.useState(false);

  const handleSort = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, sort: string) => {
    e.stopPropagation();
    setParams((prev) => ({
      ...prev, sort: sort.toLowerCase(), page: 1, isLoadMore: false
    }));
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div
        className={styles.sortBlock}
        onMouseDown={() => setOpen(!open)}
        role="button"
        tabIndex={0}
        aria-label="sort-block"
      >
        <button type="button">
          Sort
          <IconStroke className={open ? styles.arrow : ''} />
          {open && (
            <div className={styles.sortDropdown}>
              {SORT_TYPES.map((sort) => (
                <div
                  key={sort}
                  className={styles.list}
                  role="cell"
                  aria-hidden="true"
                  onMouseDown={(e) => {
                    handleSort(e, sort);
                  }}
                >
                  {params?.sort === sort.toLowerCase() ? <RadioActiveIcon /> : <RadioIcon />}
                  <span className={`${styles.title} ${params?.sort === sort.toLowerCase() ? styles.active : ''}`}>
                    {sort}
                  </span>

                </div>
              ))}
            </div>
          )}
        </button>

      </div>
    </ClickAwayListener>
  );
};
export default SortBlock;
