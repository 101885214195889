import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './sass/TabsButton.module.scss';
import DefaultButton from '../DefaultButton/DefaultButton';
import constants from '../../constants/filters';

const TabsButton = ({
  tabLabel, tabLabelSecond, activeTab, setActiveTab 
}) => useMemo(() => (
  <div className={styles.container}>
    <DefaultButton
      buttonClass="leftTab" 
      handleClick={() => setActiveTab(constants.leftTab)}
      activeClass={activeTab === 'leftTab' ? 'activeTabButton' : ''}
    >
      {tabLabel}
    </DefaultButton>
    <DefaultButton
      buttonClass="rightTab"
      handleClick={() => setActiveTab(constants.rightTab)}
      activeClass={activeTab === 'rightTab' ? 'activeTabButton' : ''}
    >
      {tabLabelSecond}
    </DefaultButton>
  </div>
), [activeTab]);

TabsButton.propTypes = {
  tabLabel: PropTypes.string.isRequired,
  tabLabelSecond: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired
};

export default TabsButton;
