import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/CloseButton.module.scss';

const CloseButton = ({ click, btnClass }) => (
  <button type="button" aria-hidden="true" onClick={click} className={`${styles.btnClose} ${styles[btnClass]}`}>
    <span className={styles.close} />
  </button>
);

CloseButton.propTypes = {
  click: PropTypes.func.isRequired,
  btnClass: PropTypes.string
};

CloseButton.defaultProps = {
  btnClass: ''
};

export default CloseButton;
