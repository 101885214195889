import { DialogItemEntityType } from '../../DashboardsItemsDropdown/types/constants';
import { ReactComponent as ScreenerIcon } from '../../../assets/images/icons/screener/screener.svg';
import { ReactComponent as DeepListIcon } from '../../../assets/images/icons/screener/watchlist.svg';
import { ReactComponent as ColumnSetIcon } from '../../../assets/images/icons/settings.svg';
import { ReactComponent as DataPanelIcon } from '../../../assets/images/icons/screener/dataPanel.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/images/icons/DashboardsBlue.svg';

export enum DashboardItemsDialogActionTypes {
  ADD,
  EDIT,
  DELETE,
  DUPLICATE,
  MOVE_TO,
  REMOVE
}

export enum DialogItemDataTypes {
  SCREENER,
  DEEP_LIST,
  COLUMN_SET,
  DATA_PANEL,
  DASHBOARD
}

export const CreateFolderTypes = {
  [DialogItemDataTypes.SCREENER]: 'screener',
  [DialogItemDataTypes.DEEP_LIST]: 'watchList',
  [DialogItemDataTypes.COLUMN_SET]: 'columns',
  [DialogItemDataTypes.DATA_PANEL]: 'dataPanel',
  [DialogItemDataTypes.DASHBOARD]: 'userDashboard',
};

export const CreateFolderPresetTypes = {
  [DialogItemDataTypes.SCREENER]: 'presetsScreener',
  [DialogItemDataTypes.DEEP_LIST]: 'presetsWatchlist',
  [DialogItemDataTypes.COLUMN_SET]: 'columnsPreset',
  [DialogItemDataTypes.DATA_PANEL]: 'presetsDataPanel',
  [DialogItemDataTypes.DASHBOARD]: 'userDashboardPresets',
};

export const DashboardItemsEmptyStateIcons = {
  [DialogItemDataTypes.SCREENER]: ScreenerIcon,
  [DialogItemDataTypes.DEEP_LIST]: DeepListIcon,
  [DialogItemDataTypes.COLUMN_SET]: ColumnSetIcon,
  [DialogItemDataTypes.DATA_PANEL]: DataPanelIcon,
  [DialogItemDataTypes.DASHBOARD]: DashboardIcon,
};

export const DashboardItemsDialogTitle = {
  [DashboardItemsDialogActionTypes.ADD]: 'createNewLabel',
  [DashboardItemsDialogActionTypes.EDIT]: 'changeNameLabel',
  [DashboardItemsDialogActionTypes.DELETE]: 'areYouSure',
  [DashboardItemsDialogActionTypes.MOVE_TO]: 'moveTo',
  [DashboardItemsDialogActionTypes.DUPLICATE]: 'duplicateNameLabel',
  [DashboardItemsDialogActionTypes.REMOVE]: 'areYouSure',
};

export const DashboardItemsListsTitle = {
  [DialogItemDataTypes.DEEP_LIST]: 'lists',
  [DialogItemDataTypes.SCREENER]: 'screens',
  [DialogItemDataTypes.COLUMN_SET]: 'lists',
  [DialogItemDataTypes.DATA_PANEL]: 'data panels',
  [DialogItemDataTypes.DASHBOARD]: 'dashboards',
};

export const DashboardItemsDialogTitleEntity = {
  [DialogItemEntityType.FOLDER]: 'folder',
  [DialogItemEntityType.ITEM]: '',
};

export const ActionDialogTypeTitle = {
  [DashboardItemsDialogActionTypes.ADD]: 'create',
  [DashboardItemsDialogActionTypes.EDIT]: 'saveChanges',
  [DashboardItemsDialogActionTypes.DELETE]: 'delete',
  [DashboardItemsDialogActionTypes.MOVE_TO]: 'move',
  [DashboardItemsDialogActionTypes.DUPLICATE]: 'save',
  [DashboardItemsDialogActionTypes.REMOVE]: 'remove',
};

export const DashboardTypeTitles = {
  screener: 'screenTitleLabel',
  presets: 'screenTitleLabel',
  watchList: 'deepListTitleLabel',
  columns: 'columnSetTitleLabel',
  dataPanel: 'dataPanelTitleLabel',
};
