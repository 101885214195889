import getInputArray from '../utilities/getInputArrayTs';
import { CustomIndicator, IPineStudyResult, LibraryPineStudy, PineJS, RawStudyMetaInfoId, StudyInputType, StudyLinePlotPreferences, StudyPlotType } from '../../charting_library/charting_library';

export default function webbyRsi2(mPineJS: PineJS): CustomIndicator {
  return {
    name: 'DV - Webby RSI 5.150',
    metainfo: {
      id: 'TL-webbyRsi2@tv-basicstudies-1' as RawStudyMetaInfoId,
      shortDescription: 'DV - Webby RSI 5.150',
      description: 'DV - Webby RSI 5.150',
      is_price_study: false,
      isCustomIndicator: true,
      is_hidden_study: false,
      format: {
        type: 'inherit',
      },
      bands: [{
        id: 'hline_0',
        isHidden: false,
        name: 'Stretch Level'
      }],
      inputs: [
        {
          id: 'atrLen',
          name: 'ATR Length',
          type: 'integer' as StudyInputType.Integer,
          defval: 50
        },
      ],
      plots: [{
        id: 'plot_0',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_1',
        palette: 'palette_0',
        target: 'plot_0',
        type: 'colorer' as StudyPlotType.Colorer
      }, {
        id: 'plot_2',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_3',
        palette: 'palette_1',
        target: 'plot_2',
        type: 'colorer' as StudyPlotType.Colorer
      }, {
        id: 'plot_4',
        type: 'line' as StudyPlotType.Line
      }, {
        id: 'plot_5',
        palette: 'palette_2',
        target: 'plot_4',
        type: 'colorer' as StudyPlotType.Colorer
      }],
      palettes: {
        palette_0: {
          colors: {
            0: {
              'name': 'Above 21 EMA'
            }
          },
          valToIndex: {
            0: 0
          }
        },
        palette_1: {
          colors: {
            0: {
              'name': 'Below 21 EMA'
            }
          },
          valToIndex: {
            '1': 0
          }
        },
        palette_2: {
          colors: {
            0: {
              name: 'SMA Extension'
            }
          },
          valToIndex: {
            2: 0
          }
        }
      },
      styles: {
        plot_0: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'ATR Greater Than 21 Day EMA'
        },
        plot_2: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: 'ATR Less Than 21 Day EMA'
        },
        plot_4: {
          histogramBase: 0,
          isHidden: false,
          joinPoints: false,
          title: '10 Day SMA Extension'
        }
      },
      defaults: {
        bands: [{
          color: "#E83AAD",
          linestyle: 0,
          linewidth: 2,
          value: 3,
          visible: true
        }],
        inputs: {
          atrLen: 50
        },
        palettes: {
          palette_0: {
            colors: {
              0: {
                color: '#3A75E8',
                style: 0,
                width: 2
              }
            }
          },
          palette_1: {
            colors: {
              0: {
                color: '#E83AAD',
                style: 0,
                width: 2
              }
            }
          },
          palette_2: {
            colors: {
              0: {
                color: '#FF9800',
                style: 0,
                width: 2
              }
            }
          }
        },
        styles: {
          plot_0: {
            color: "#2962ff",
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 1,
            trackPrice: false,
            transparency: 0
          },
          plot_2: {
            color: "#2962ff",
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 1,
            trackPrice: false,
            transparency: 0
          },
          plot_4: {
            color: "#2962ff",
            display: 15,
            linestyle: 0,
            linewidth: 2,
            plottype: 7,
            trackPrice: false,
            transparency: 0
          }
        }
      },
    },
    constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
      this.init = (context) => {
        this._context = context;
        this.Prev = 0;
      };
      this.main = (context, inputs): IPineStudyResult => {
        this._context = context;

        const [atrLen] = getInputArray({
          inputs,
          length: 1
        });

        const EMA21 = mPineJS.Std.ema(this._context.new_var(mPineJS.Std.close(this._context)), 21, this._context);
        const SMA10 = mPineJS.Std.sma(this._context.new_var(mPineJS.Std.close(this._context)), 10, this._context);

        const atr = mPineJS.Std.atr(atrLen, this._context);

        const lowV21 = mPineJS.Std.low(this._context) - EMA21;
        const highV21 = EMA21 - mPineJS.Std.high(this._context);
        const highV10 = mPineJS.Std.high(this._context) - SMA10;

        const rsiPos = lowV21 / atr;
        const rsiNeg = highV21 / atr;
        const smaPos = highV10 / atr;

        return [
          rsiPos > 0 ? rsiPos : NaN,
          0,
          rsiNeg > 0 ? rsiNeg : NaN,
          0,
          smaPos > 0 ? smaPos : NaN,
          0
        ];
      };
    }
  };
}
