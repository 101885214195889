const ROLES = {
  CUSTOMER: 'customer',
  FOUNDER: 'founder',
  STARTER: 'starter',
  PREMIUM: 'premium',
  ADMIN: 'admin',
  FREE: 'free'
};

export default ROLES;
