import {
  useContext,
  useEffect, useRef, useState
} from 'react';
import {
  WS_CHANNELS,
} from '../../../constants/socketConnection';
import {
  HeatMapDataset,
  PerformanceDataSet,
  SocketDataResponse,
  SocketPartialResponse,
  StoredSymbol,
  TDataPoint,
} from '../types/WidgetChartsypes';
import {
  DATA_POINT_PERFORMANCE_FILTER,
  DEEPVUE_UNIVERSE_ID_PROD,
  DEFAULT_DATA_POINT_INDEX,
  DEFAULT_DATA_POINT_TITLE,
  DEFAULT_SCREENER_TITLE,
} from '../types/WidgetChartsEnums';
import { filtersStoreHelper } from '../../../utils/helpers';
import { ITargetListItem } from '../../Screener/interfaces/ITargetList';
import { VIEW_TYPE_TARGET } from '../../../constants/watchlist';
import { IUseWidgetPerformanceChart } from '../types/WidgetChartsInterfaces';
import { orderedCache } from '../../../services/ChartPrefetchServices/OrderedSymbolCache';
import useWidgetCharts from './useWidgetCharts';
import SocketContext from '../../../context/SocketContext';
import { ScreenerQueryMessage } from '../../../services/Interfaces';
import useDebouncedCallback from '../../../utils/hooks/useDebouncedCallback';
import { sortOrder, dataCallback, getWidgetValues } from '../utils/initHelper';
import useWidgetSocketListener from './useWidgetSocketListener';
import useWidgetAddQuery from './useWidgetAddQuery';

const useWidgetPerformanceChart: IUseWidgetPerformanceChart = (widget, dashboardId, updateWidget) => {
  const refreshList = useRef(false);
  const debouncedUpdateLastState = useDebouncedCallback();
  const { addWidgetQuery } = useWidgetAddQuery();
  const { Socket, Connection, Authorized } = useContext(SocketContext);
  const [storedSymbols, setStoredSymbols] = useState<PerformanceDataSet[]>([]);
  const updateStoreSymbolsState = (data: PerformanceDataSet[] | HeatMapDataset[]) => {
    setStoredSymbols(data as PerformanceDataSet[]);
  };

  const {
    widgetQueriesRef,
    itemsDeeplist,
    columnsList,
    symbolsList,
    userSettings,
    watchLists,
    screenerStates,
    setScreenerStates,
    queryId,
    setQueryId,
    totalCount,
    setTotalCount,
    targetLists,
    setTargetLists,
    dataPointList,
    setDataPointList,
    selectedType,
    setDefaultFilter,
    screenerSearch,
    updateFilter,
    setFilter,
    selectedDashboard,
    presetsNewScreen,
    cleanRefs,
    startInterval,
    refStoredSymbols,
    intervalIdRef
  } = useWidgetCharts(
    widget,
    DEEPVUE_UNIVERSE_ID_PROD,
    DEFAULT_SCREENER_TITLE,
    updateStoreSymbolsState
  );

  const convertIntoLabelValue = (dataResponse: SocketDataResponse) => {
    const symbols: PerformanceDataSet[] = [];
    dataResponse?.data?.forEach((item) => {
      if (!Number.isNaN(item[2])) {
        const sym = symbolsList.find((subItem: StoredSymbol) => subItem.sym === item[1]);
        if (sym && sym.sym) symbols.push({ ...sym, value: Number(item[2]) });
      }
    });
    return { symbols };
  };

  const prepareDataHandler = (resp: SocketDataResponse) => {
    const { symbols } = convertIntoLabelValue(resp);
    refStoredSymbols.current = symbols;
    setStoredSymbols(symbols);
    setQueryId(resp.screenerId);
    setTotalCount(resp?.total);
    startInterval();
  };

  const preparePatchDataHandler = (resp: SocketDataResponse) => {
    const { symbols } = convertIntoLabelValue(resp);
    if (symbols.length) {
      setStoredSymbols((prev) => {
        if (refreshList.current) {
          refStoredSymbols.current = symbols;
          return symbols;
        }
        const newData = [...prev, ...symbols];
        const sortedData = sortOrder(
          [...new Map(newData.map((item) => [item.sym, item])).values()], screenerStates.sortBy
        );
        refStoredSymbols.current = sortedData;
        return sortedData;
      });
    }
    setTotalCount(resp?.total);
    startInterval();
  };

  const dataCallbackPatch = (resp: SocketDataResponse) => {
    if (resp?.messageId === Number(widget?.id)) {
      if (resp?.screenerId) {
        addWidgetQuery(resp, widget);
        preparePatchDataHandler(resp);
      }
    }
  };

  const dataCallbackAll = (resp: SocketDataResponse) => {
    if (widgetQueriesRef?.current) {
      const currentWidgetQueryId = widgetQueriesRef?.current[Number(widget.id)];
      if (resp?.screenerId === currentWidgetQueryId?.screenerId) {
        preparePatchDataHandler(resp);
      }
    }
  };

  const partialCallback = (resp: SocketPartialResponse) => {
    if (resp.screenerId === queryId && refStoredSymbols.current.length > 0 && intervalIdRef.current) {
      const [symIndex, , value] = resp.data;
      const index = refStoredSymbols.current.findIndex((item) => item.index === symIndex);
      let localData = [...refStoredSymbols.current];
      localData[index] = { ...localData[index], value: Number(value) };
      localData = sortOrder(localData as PerformanceDataSet[], screenerStates.sortBy);
      refStoredSymbols.current = localData;
    }
  };

  useWidgetSocketListener({
    widget,
    screenerStates,
    dataCallback: (resp: SocketDataResponse) => dataCallback(resp, widget, addWidgetQuery, prepareDataHandler),
    dataCallbackPatch,
    dataCallbackAll,
    partialCallback,
    queryId,
    storedSymbols
  });

  useEffect(() => {
    if (storedSymbols.length) {
      orderedCache.setWidgetData(storedSymbols);
    }
  }, [storedSymbols]);

  const initialWidget = () => {
    const findDataPoint = columnsList.find(
      (item: TDataPoint) => item.index === DEFAULT_DATA_POINT_INDEX
    ) ?? { type: '' };

    setScreenerStates((prev) => ({
      ...prev,
      selectedScreenerTitle: DEFAULT_SCREENER_TITLE,
      dataPointsXTitle: DEFAULT_DATA_POINT_TITLE,
      dataPointsXIndex: DEFAULT_DATA_POINT_INDEX,
      dataPointsXType: findDataPoint.type,
    }));
    setDefaultFilter();
  };

  useEffect(() => {
    if (parseInt(widget.id) > 0 && columnsList.length && presetsNewScreen.length) {
      getWidgetValues(
        widget,
        userSettings.performanceLastState,
        selectedDashboard,
        columnsList,
        setFilter,
        initialWidget,
        {
          itemId: dashboardId,
          defaultScreenId: DEEPVUE_UNIVERSE_ID_PROD,
          findY: false,
          findZ: false,
        }
      );
      setDataPointList(columnsList.filter((item: TDataPoint) => (item.show && item.enabled
        && DATA_POINT_PERFORMANCE_FILTER.includes(item.index))));
    }
  }, [columnsList, presetsNewScreen]);

  useEffect(() => {
    if (
      screenerStates.dataPointsXIndex
      && screenerStates.filtersData
      && Socket?.readyState === WebSocket.OPEN
      && Authorized
    ) {
      cleanRefs();
      const queryMessage: ScreenerQueryMessage = {
        messageId: Number(widget.id),
        columns: [0, 17, screenerStates.dataPointsXIndex],
        filters: filtersStoreHelper(JSON.parse(screenerStates.filtersData)),
        sortBy: [[screenerStates.dataPointsXIndex, screenerStates.sortBy]],
        range: [0, 200],
        etfSymbolIndex: -1,
      };
      let emitter;
      if (queryId) {
        queryMessage.screenerId = queryId;
        refreshList.current = true;
        emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_PATCH, queryMessage);
      } else {
        emitter = () => Connection?.sendMessage(WS_CHANNELS.SCREENER_SUBSCRIBE, queryMessage);
      }
      debouncedUpdateLastState(emitter, 500);
    }
  }, [
    screenerStates.dataPointsXIndex,
    screenerStates.filtersData,
    screenerStates.sortBy,
    Socket?.readyState,
    Authorized,
  ]);

  useEffect(() => {
    if (watchLists.length) {
      const lists = watchLists.filter(
        (list: ITargetListItem) => list.viewType === VIEW_TYPE_TARGET
      );
      setTargetLists(lists);
    }
  }, [watchLists]);

  useEffect(() => {
    if (targetLists.length) {
      updateFilter();
    }
  }, [targetLists, itemsDeeplist]);

  useEffect(() => {
    if (
      widget.id
      && screenerStates.dataPointsXIndex
    ) {
      const newWidget = {
        ...widget,
        datapointIndex: screenerStates.dataPointsXIndex,
        screenerId: screenerStates.screenerId ?? DEEPVUE_UNIVERSE_ID_PROD,
        sortBy: screenerStates.sortBy
      };
      updateWidget(newWidget);
    }
  }, [
    widget?.id,
    screenerStates.dataPointsXIndex,
    screenerStates.screenerId,
    screenerStates.sortBy,
    selectedDashboard?.type
  ]);

  const onLoadMore = (startRange: number) => {
    if (screenerStates.dataPointsXIndex && screenerStates.filtersData) {
      const queryMessage = {
        messageId: Number(widget.id),
        columns: [0, 17, screenerStates.dataPointsXIndex],
        filters: filtersStoreHelper(JSON.parse(screenerStates.filtersData)),
        sortBy: [[screenerStates.dataPointsXIndex, screenerStates.sortBy]],
        range: [startRange, 200],
        etfSymbolIndex: -1,
        screenerId: queryId,
      };
      if (Socket?.readyState === WebSocket.OPEN && Authorized) {
        refreshList.current = false;
        Connection?.sendMessage(
          WS_CHANNELS.SCREENER_PATCH,
          queryMessage
        );
      }
    }
  };

  return {
    dataPointList,
    storedSymbols,
    totalCount,
    selectedType,
    onLoadMore,
    targetLists,
    queryId,
    screenerStates,
    screenerSearch,
    setScreenerStates,
    setDefaultFilter,
  };
};

export default useWidgetPerformanceChart;
