import React, { useState } from 'react';
import styles from './sass/EducationComponents.module.scss';
import TutorialCardImage from '../../assets/images/icons/education/videoThumbnail.png';
import CategoryBlock from './CategoryBlock';
import { ITutorialCard } from './types/EducationComponentsInterfaces';
import VideoPlayer from './VideoPlayer';

const TutorialCard = ({
  title, videoSrc, categories, isGridView, posterSrc, tutorialCategories
}: ITutorialCard): React.ReactElement => {
  const [showCard, setShowCard] = useState(false);

  const handleClick = () => setShowCard(true);

  return (
    <div
      className={`${styles.cardItem} ${!isGridView && styles.cardItem_list}`}
      role="button"
      tabIndex={0}
      aria-label="tutorials"
      onMouseDown={handleClick}
    >
      <div className={styles.cardImageWrapper}>
        <img
          className={`${styles.cardImage} ${!isGridView && styles.cardImage_list}`}
          src={posterSrc}
          alt="tutorials-images"
          onError={(event) => {
            const target = event.currentTarget as HTMLImageElement;
            target.onerror = null;
            target.src = TutorialCardImage;
          }}
        />
      </div>
      <div className={styles.cardDetails}>
        <div className={styles.tagsContainer}>
          <CategoryBlock categories={categories} allCategories={tutorialCategories} />
        </div>
        <p className={styles.cardTitle}>{title}</p>
      </div>
      {showCard && (
        <VideoPlayer videoSrc={videoSrc} setShowCard={setShowCard} />
      )}
    </div>
  );
};

export default TutorialCard;
