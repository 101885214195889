import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TableItem from './components/TableItem';
import {
  changeColorHandler, getHandler, transformData,
} from '../../utils/convertingDataHelper';
import { setGlobalSectorIndustrySearch } from '../../store/dashboards/slice';
import { isNumericalColumn } from '../../utils/helperColumnsWorker';

const areEqual = (prevProps, nextProps) => {
  return prevProps.item === nextProps.item
    && prevProps.column === nextProps.column
    && prevProps.size === nextProps.size
    && prevProps.columnIndex === nextProps.columnIndex;
};

const ScreenerTableItem = ({
  item,
  name,
  type,
  size,
  columnIndex,
  statusIndustry,
  statusSubIndustryIndex,
  statusSectorIndex,
  statusGroupIndex,
  volumesHistoryData,
  alertTable,
  dataPanelQueryId,
  dataPanel,
  widgetId,
  widgetResizeSelector,
  sectorIndustryWidgetHandler
}) => {
  const [value, setValue] = useState(null);
  const [colorSettings, setColorSettings] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const types = type?.split('_');
  const isNumberColumn = isNumericalColumn(type);
  const dispatch = useDispatch();

  const updateItem = (itemValue) => {
    let newValue = itemValue;
    let newColor = colorSettings;
    types.forEach((typeItem) => { newColor = changeColorHandler(typeItem, newValue); });
    types.forEach((typeItem) => { newValue = transformData(typeItem, newValue, name); });
    setCurrentItem(itemValue);
    setValue(newValue);
    setColorSettings(newColor);
  };

  const sectorIndustryClickHAndler = (index, itemTitle) => {
    dispatch(setGlobalSectorIndustrySearch(true));
    sectorIndustryWidgetHandler(index, itemTitle);
  };

  useEffect(() => {
    updateItem(item);
  }, [item]);

  const itemHandler = getHandler(type, item, sectorIndustryClickHAndler, columnIndex);

  return value && (
    <TableItem
      columnIndex={columnIndex}
      item={currentItem}
      size={size}
      name={name}
      type={type}
      value={value}
      isNumberColumn={isNumberColumn}
      handler={itemHandler}
      colorSettings={colorSettings}
      volumesHistoryData={volumesHistoryData}
      statusIndustry={statusIndustry}
      statusSubIndustryIndex={statusSubIndustryIndex}
      statusSectorIndex={statusSectorIndex}
      statusGroupIndex={statusGroupIndex}
      alertTable={alertTable}
      dataPanelQueryId={dataPanelQueryId}
      dataPanel={dataPanel}
      widgetId={widgetId}
      widgetResizeSelector={widgetResizeSelector}
    />
  );
};

ScreenerTableItem.propTypes = {
  size: PropTypes.shape(
    { minWidth: PropTypes.number, startWidth: PropTypes.number, maxWidth: PropTypes.number }
  ).isRequired,
  sectorIndustryWidgetHandler: PropTypes.func,
  dataPanel: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  item: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  columnIndex: PropTypes.number,
  volumesHistoryData: PropTypes.arrayOf(PropTypes.number),
  statusIndustry: PropTypes.number,
  statusSubIndustryIndex: PropTypes.number,
  statusSectorIndex: PropTypes.number,
  statusGroupIndex: PropTypes.number,
  alertTable: PropTypes.bool,
  dataPanelQueryId: PropTypes.number,
  widgetId: PropTypes.string,
  widgetResizeSelector: PropTypes.number,
};

ScreenerTableItem.defaultProps = {
  sectorIndustryWidgetHandler: () => {},
  name: '',
  item: '',
  type: '',
  columnIndex: 0,
  volumesHistoryData: [],
  statusIndustry: 0,
  statusSubIndustryIndex: 0,
  statusSectorIndex: 0,
  statusGroupIndex: 0,
  alertTable: false,
  dataPanelQueryId: 0,
  dataPanel: false,
  widgetId: '',
  widgetResizeSelector: 0,
};

export default React.memo(ScreenerTableItem, areEqual);
