import { createContext } from 'react';
import { ISocketProviderContextExtended } from './Interfaces';

const SocketContext = createContext<ISocketProviderContextExtended>({
  Socket: null,
  Authorized: false,
  Connection: null,
});

export default SocketContext;
