import { useState, useEffect } from 'react';

const useSearchByColumns = (columnsList) => {
  const [search, setSearch] = useState('');
  const [listResults, setListResults] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const allColumnsPrepare = [];
  columnsList.map((groupItem) => allColumnsPrepare.push(...groupItem.items));

  useEffect(() => {
    setAllColumns(allColumnsPrepare);
  }, [columnsList]);

  const handlerSearch = (event) => {
    setSearch(event.target.value);
  };

  const searchRequest = () => {
    let items = [];
    if (search) {
      items = allColumns?.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }
    setListResults(items);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchRequest();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return {
    search, handlerSearch, listResults, setSearch
  };
};

export default useSearchByColumns;
