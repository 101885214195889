import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/TextField.module.scss';

const TextField = ({
  text,
  styleType,
  children,
  color,
  className
}) => (
  <span
    className={`${styles.textField} ${className} ${styleType ? styles[styleType] : ''} ${color ? styles[color] : ''}`}
  >
    {text}
    {children}
  </span>
);

TextField.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styleType: PropTypes.string,
  children: PropTypes.element,
  color: PropTypes.string,
  className: PropTypes.string
};

TextField.defaultProps = {
  text: '',
  color: '',
  styleType: '',
  children: null,
  className: ''
};

export default React.memo(TextField);
