import React from 'react';
import { Tooltip } from '@mui/material';
import styles from '../sass/WidgetCharts.module.scss';
import { IScreenerFolderItem } from '../types/WidgetChartsInterfaces';
import { TScreenListItem } from '../types/WidgetChartsypes';
import getInfoTooltip from '../../../utils/getInfoTooltip';
import ThemeVariants from '../../../constants/theme';
import { ScreenerTooltipDarkStyles, ScreenerTooltipStyles } from '../../Screener/ScreenerTooltipStyles';

const ScreenerFolderItem = ({
  screenerStates,
  setScreenerStates,
  selectedType,
  screenerSearch,
  folderWidgetStyle,
  theme
}: IScreenerFolderItem): React.ReactElement => {
  const searchList = screenerSearch.filter((item) => item.title === screenerStates?.selectedSearchListTitle);
  const list = screenerStates.searchText && searchList.length
    ? searchList[0].data[screenerStates.folderMenuIsOpen].items
    : selectedType[screenerStates.folderMenuIsOpen].items;

  const handleClick = (item: TScreenListItem) => {
    setScreenerStates((prev) => ({
      ...prev,
      screenerIsOpen: false,
      dataPointsXIsOpen: false,
      folderMenuIsOpen: -1,
      selectedScreenerTitle: item?.title,
      filtersData: JSON.stringify(item?.data?.filters),
      screenerId: item.id
    }));
  };

  return (
    <div className={styles.screenerFolderMenu} style={folderWidgetStyle}>
      <div className={styles.screenerItems}>
        <div className={styles.itemsWrapper}>
          {list.map(((item: TScreenListItem) => (
            <Tooltip
              arrow
              key={item.id}
              componentsProps={theme === ThemeVariants.LIGHT_MODE ? ScreenerTooltipStyles : ScreenerTooltipDarkStyles}
              title={(
                <div>
                  {getInfoTooltip(item.title, item.title)}
                </div>
              )}
            >
              <span
                key={item.id}
                className={styles.titleText}
                role="button"
                tabIndex={0}
                onMouseDown={() => handleClick(item)}
              >
                <span>
                  {item.title}
                </span>
              </span>
            </Tooltip>
          )))}
        </div>
      </div>
    </div>
  );
};

export default ScreenerFolderItem;
