import React from 'react';
import PropTypes from 'prop-types';

import styles from './sass/Checkbox.module.scss';
import { messageIdGenerator } from '../ChartContainer/utils/chartingHelper';

const Checkbox = ({
  id,
  checked,
  changeHandler,
  additionalClass,
  styleInput,
  textStyle,
  name,
  title,
  disabled,
}) => {
  const additionalId = messageIdGenerator();
  return (
    <label
      htmlFor={`${id}_${additionalId}`}
      className={`${additionalClass ? styles[additionalClass] : styles.checkbox}`}
    >
      <input
        id={`${id}_${additionalId}`}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        className={`${styleInput ? styles[styleInput] : ''} ${styles.symbolCheckbox}`}
        onChange={changeHandler}
        name={name}
      />
      <span
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={`${textStyle ? styles[textStyle] : ''} symbolCheckbox`}
      >
        {title}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  textStyle: PropTypes.string,
  styleInput: PropTypes.string,
  additionalClass: PropTypes.string,
  changeHandler: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  id: '0',
  name: '',
  title: '',
  textStyle: '',
  checked: false,
  styleInput: '',
  additionalClass: '',
  changeHandler: () => undefined,
  disabled: false,
};

export default React.memo(Checkbox);
