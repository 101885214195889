import { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import useLastStateUpdateStore from '../../../utils/hooks/useLastStateUpdateStore';
import { OPEN_DATA_PANEL } from '../../../constants/tvWidgetOptions';
import { SendGAEvent } from '../../../utils/ga';

const useDataPanelButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userSettings = useSelector((state) => state.accountState?.userSettings, shallowEqual);
  const profile = useSelector((state) => state.accountState?.profile, shallowEqual);
  const openDataPanel = userSettings?.openDataPanel;

  const { updateStoreHandler } = useLastStateUpdateStore();

  const toggleDataPanel = (isOpenDataPanel) => {
    updateStoreHandler(OPEN_DATA_PANEL, isOpenDataPanel);
    SendGAEvent('user_dataPanel_open', {
      isOpen: Number(isOpenDataPanel),
      cust_identify: profile?.id,
      event_label: 'User Opened Data Panel'
    });
  };

  useEffect(() => {
    setIsOpen(openDataPanel);
  }, [openDataPanel]);

  return {
    isOpen,
    toggleDataPanel
  };
};

export default useDataPanelButton;
