import { StudyLinePlotPreferences } from "../../../charting_library/charting_library";

export const defaultStyles = {
  webbyRsi: {
    linestyle: 0,
    visible: true,
    linewidth: 4,
    transparency: 20,
    color: '#3a75e8',
    plottype: 1 as StudyLinePlotPreferences['plottype']
  },
  movingAverage: {
    visible: true,
    linewidth: 2,
    transparency: 20,
    color: '#f55bae'
  },
  zeroPointFiveLine: {
    linestyle: 0,
    visible: true,
    linewidth: 1,
    color: '#4caf4f'
  },
  twoLine: {
    linestyle: 0,
    visible: true,
    linewidth: 1,
    color: '#4caf4f'
  },
  fourLine: {
    linestyle: 0,
    visible: true,
    linewidth: 1,
    color: '#ddcd3f'
  },
  sixLine: {
    linestyle: 0,
    visible: true,
    linewidth: 1,
    color: '#c9415f'
  }
};

export const styles = {
  webbyRsi: {
    title: 'Webby RSI',
    histogramBase: 0,
  },
  movingAverage: { title: 'Moving Average', },
  zeroPointFiveLine: { title: 'ZeroPointFive Line', },
  twoLine: { title: 'Two Line', },
  fourLine: { title: 'Four Line', },
  sixLine: { title: 'Six Line', }
};
