import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/TableScreener.module.scss';
import StatsChart from '../../../components/StatsChart/StatsChart';
import EmptyStats from '../../../components/Charts/EmptyBlock/EmptyStats';
import { STATS_CHART_HEIGHT, tableChart } from '../../../constants/statsChart';

const GraphicStatsChart = ({
  showStats,
  statsChartData,
}) => {
  const [height, setHeight] = useState(STATS_CHART_HEIGHT);
  const isEmptyStats = () => !statsChartData[0]?.length && !statsChartData[1]?.length;

  return (
    <>
      <div
        id="statsChart"
        className={`${styles.widget_stats} ${showStats && styles.widget_stats_show}`}
        style={{ minHeight: showStats ? height : 0 }}
      >
        {showStats && (
          <>
            {!isEmptyStats()
              ? (
                <StatsChart
                  isActive
                  setHeight={setHeight}
                  selectedChart={tableChart}
                  showStats={showStats}
                />
              )
              : (
                <EmptyStats />
              )}
          </>
        )}
      </div>
    </>
  );
};

GraphicStatsChart.propTypes = {
  showStats: PropTypes.bool,
  statsChartData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
};

GraphicStatsChart.defaultProps = {
  statsChartData: [[], []],
  showStats: false
};

export default GraphicStatsChart;
