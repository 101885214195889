import { IPineSeries } from "../../../charting_library/charting_library";

export default function updateAntsState(closeSeries: IPineSeries, volumeSeries: IPineSeries) {
  let momentum12D = 0;
  for (let i = 0; i < 15; i++) {
    if (closeSeries.get(i) > closeSeries.get(i + 1)) {
      momentum12D++;
    }
  }
  let sum15D = 0;
  let sum15DAgo = 0;
  for (let i = 0; i < 15; i++) {
    sum15D += volumeSeries.get(i);
  }
  for (let i = 15; i < 30; i++) {
    sum15DAgo += volumeSeries.get(i);
  }
  return (momentum12D >= 12
    && ((sum15D - sum15DAgo) / sum15DAgo) >= 0.2
    && ((closeSeries.get(0) - closeSeries.get(15)) / closeSeries.get(15)) >= 0.2) ? 1 : NaN;
}
