import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import WebViewWidget from '../../components/WebViewWidget/WebViewWidget';
import { SAVE_USER_STATE_DELAY } from '../../constants/screener';
import useAccount from '../../utils/hooks/useAccount';
import RootStateTypes from '../../store/RootStateTypes';
import useDebouncedCallback from '../../utils/hooks/useDebouncedCallback';
import SocketContext from '../../context/SocketContext';

const Widget = (): ReactElement => {
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const userLastState = useSelector((state: RootStateTypes) => state.accountState.userSettings);
  const lastStateId = useSelector((state: RootStateTypes) => state.accountState.lastStateId);
  const updatedLastState = useSelector((state: RootStateTypes) => state.accountState.updatedLastState, shallowEqual);

  const { updateUserState } = useAccount();
  const {
    Socket,
    Authorized,
    awakeSocketWidget,
  } = useContext(SocketContext);
  const debouncedUpdateLastState = useDebouncedCallback();

  useEffect(() => {
    if (!Socket && !Authorized && awakeSocketWidget) {
      setIsFirstLoad(false);
      if (!isFirstLoad) {
        awakeSocketWidget();
      }
    }
  }, [Socket, Authorized]);

  useEffect(() => {
    if (updatedLastState) {
      debouncedUpdateLastState(updateUserState, SAVE_USER_STATE_DELAY, userLastState, lastStateId);
    }
  }, [userLastState]);

  return (
    <WebViewWidget />
  );
};

export default Widget;
