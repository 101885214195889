import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import stylesModal from './sass/NewScreenModals.module.scss';
import TextField from '../TextField/TextField';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close_dlack.svg';
import Input from '../Input/Input';
import Button from '../Button/Button';
import TextArea from '../TextArea/TextArea';
import ButtonListenerWrapper from '../ButtonListenerWrapper/ButtonListenerWrapper';
import { checkEmptyText } from '../../utils/helpers';
import { EDIT_MODAL_STRINGS, EDIT_MODAL_TYPES, MAX_LENGTH_COUNT } from '../../constants/screener';

let previousName;
let previousDescription;

const UpdateFolderModal = ({
  setOpenModal, currentItem, updateFolder, singleItemStatus, updateScreen, currentSubItem, type,
}) => {
  const { t } = useTranslation();
  const [updateFolderName, setUpdateFolderName] = useState(
    singleItemStatus && currentSubItem.title ? currentSubItem.title : currentItem.title
  );
  const [description, setDescription] = useState(
    currentItem?.type === EDIT_MODAL_TYPES.SCREENER ? currentItem?.data?.description : ''
  );

  useEffect(() => {
    previousName = updateFolderName;
    previousDescription = description;
  }, []);

  const updateFolderHandler = () => {
    if (singleItemStatus) {
      updateScreen(
        singleItemStatus && currentSubItem.id ? currentSubItem.id : currentItem.id,
        updateFolderName,
        currentItem.data,
        description
      );
    } else {
      updateFolder(currentItem.id, updateFolderName);
    }
  };
  const labels = EDIT_MODAL_STRINGS[
    type === EDIT_MODAL_TYPES.SCREEN && !singleItemStatus ? EDIT_MODAL_TYPES.FOLDER : type
  ];
  return (
    <ButtonListenerWrapper
      handleCancel={setOpenModal}
      handleConfirm={updateFolderName ? updateFolderHandler : () => {}}
    >
      <div className={stylesModal.createFolderWrapper}>
        <div className={stylesModal.createFolderHeader}>
          <TextField
            text={t(labels?.head)}
            styleType="createFolderModalHeader"
          />
          <div
            aria-hidden="true"
            onMouseDown={setOpenModal}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={stylesModal.createFolderBody}>
          <div className={currentItem?.type === EDIT_MODAL_TYPES.SCREENER ? stylesModal.createFolderContent : ''}>
            <div>
              <TextField
                text={t(labels?.label)}
                styleType="createFolderModalBody"
              />
              <Input
                handlerChange={(e) => setUpdateFolderName(e.currentTarget.value)}
                placeholder={t(labels?.placeholder)}
                value={updateFolderName}
                inputClass={stylesModal.createFolderInput}
                maxLength={MAX_LENGTH_COUNT}
              />
            </div>
            {currentItem?.type === EDIT_MODAL_TYPES.SCREENER && (
              <div className={stylesModal.descriptionUpdate}>
                <TextField
                  text={t('description')}
                  styleType="createFolderModalBody"
                />
                <TextArea
                  onChange={(e) => setDescription(e.currentTarget.value)}
                  value={description}
                />
              </div>
            )}
          </div>
          <div className={stylesModal.buttonWrapper}>
            <Button
              buttonClass={stylesModal.createFolderButton}
              handleClick={updateFolderHandler}
              disabled={
              checkEmptyText(updateFolderName)
                  || !updateFolderName
                  || (previousDescription === description && previousName === updateFolderName)
              }
            >
              {t('saveChanges')}
            </Button>
          </div>
        </div>
      </div>
    </ButtonListenerWrapper>
  );
};

UpdateFolderModal.propTypes = {
  updateScreen: PropTypes.func.isRequired,
  singleItemStatus: PropTypes.bool.isRequired,
  updateFolder: PropTypes.func.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  currentSubItem: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      isFavorite: PropTypes.bool,
      title: PropTypes.string,
      type: PropTypes.string,
      viewType: PropTypes.string
    }),
    PropTypes.string
  ]),
  currentItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    countStock: PropTypes.string,
    type: PropTypes.string,
    data: PropTypes.shape({
      description: PropTypes.string,
    }),
    favorites: PropTypes.bool,
    screensArray: PropTypes.arrayOf(PropTypes.string),
  }),
  type: PropTypes.string,
};

UpdateFolderModal.defaultProps = {
  currentItem: PropTypes.shape({
    id: 0,
    title: '',
    countStock: '',
    favorites: false,
    screensArray: [''],
  }),
  currentSubItem: '',
  type: ''
};
export default UpdateFolderModal;
