import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import styles from '../sass/DataPanel.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/icons/close_dlack.svg';
import DataCategoryDropdown from './DataCategoryDropdown';
import DataPanelAddContent from './DataPanelAddContent';
import { MODAL_TYPES } from '../dataPanelConstants';
import DefaultButton from '../../DefaultButton/DefaultButton';

const DataPanelAddComponent = ({
  togglePanelAdd,
  toggleModal,
  categories,
  selectCategory,
  selected,
  isOpen,
  toggleDropdown,
  addCategory,
  pointList,
  addPoint,
  selectedPoints,
  scrollHandler,
  removePoint,
  searchHandler,
  search,
  maxCategoryError,
}) => {
  const { t } = useTranslation();

  const isClickInside = (e) => {
    const dataPanelAdditionalComponent = document.getElementById('dataPanelAdditionalComponent');
    const dataPanelComponent = document.getElementById('dataPanelComponent');
    const modalComponent = document.getElementById('watchlist_modals');
    if (
      !e.composedPath().includes(dataPanelAdditionalComponent)
      && !e.composedPath().includes(dataPanelComponent)
      && !e.composedPath().includes(modalComponent)
    ) {
      togglePanelAdd();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', isClickInside, true);
    return () => {
      document.removeEventListener('mousedown', isClickInside, true);
    };
  }, []);

  const renameCategory = (category) => {
    toggleModal(true, MODAL_TYPES.RENAME_CATEGORY, category);
  };

  const deleteCategory = (category) => {
    toggleModal(true, MODAL_TYPES.REMOVE_CATEGORY, category);
  };

  return (
    <div id="dataPanelAdditionalComponent" className={styles.dataPanelAdd}>
      <div className={styles.dataPanelAddHeader}>
        <div className={styles.dataPanelAddHeaderDropdown}>
          <span className={styles.dataPanelAddHeaderLabel}>{t('addTo')}</span>
          <DataCategoryDropdown
            isOpen={isOpen}
            toggleDropdown={toggleDropdown}
            categories={categories}
            selected={selected}
            selectCategory={selectCategory}
            renameCategory={renameCategory}
            deleteCategory={deleteCategory}
            addCategory={addCategory}
            toggleModal={toggleModal}
            maxCategoryError={maxCategoryError}
          />
        </div>
        <DefaultButton
          handleClick={togglePanelAdd}
          className={`${styles.dataPanelTogglePanelAdd}`}
        >
          {<CloseIcon />}
        </DefaultButton>
      </div>
      <DataPanelAddContent
        searchHandler={searchHandler}
        search={search}
        scrollHandler={scrollHandler}
        pointList={pointList}
        addPoint={addPoint}
        removePoint={removePoint}
        selectedPoints={selectedPoints}
      />
    </div>
  );
};

DataPanelAddComponent.propTypes = {
  togglePanelAdd: PropTypes.func,
  toggleModal: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.string),
  selectCategory: PropTypes.func,
  selected: PropTypes.string,
  isOpen: PropTypes.bool,
  toggleDropdown: PropTypes.func,
  addCategory: PropTypes.func,
  pointList: PropTypes.arrayOf(PropTypes.shape({})),
  addPoint: PropTypes.func,
  selectedPoints: PropTypes.arrayOf(PropTypes.number),
  scrollHandler: PropTypes.func,
  removePoint: PropTypes.func,
  searchHandler: PropTypes.func,
  search: PropTypes.string,
  maxCategoryError: PropTypes.func.isRequired,
};

DataPanelAddComponent.defaultProps = {
  togglePanelAdd: () => undefined,
  toggleModal: () => undefined,
  categories: [],
  selectCategory: () => undefined,
  selected: '',
  isOpen: false,
  toggleDropdown: () => undefined,
  addCategory: () => undefined,
  pointList: [],
  addPoint: () => undefined,
  selectedPoints: [],
  scrollHandler: () => undefined,
  removePoint: () => undefined,
  searchHandler: () => undefined,
  search: '',
};

export default DataPanelAddComponent;
