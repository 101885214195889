export const DATE_TABS_LABELS = {
  after: 'After',
  before: 'Before',
  range: 'Period',
};

export const PERIOD_PICKERS_TYPE = {
  START: 0,
  END: 1,
};

export const PERIOD_BUTTONS_TYPES = {
  LAST_MONTH: {
    label: 'lastMonthLabel',
    actions: ['month', 'last'], 
  },
  LAST_WEEK: {
    label: 'lastWeekLabel',
    actions: ['week', 'last']
  },
  NEXT_WEEK: {
    label: 'nextWeekLabel',
    actions: ['week', 'next']
  },
  NEXT_MONTH: {
    label: 'nextMonthLabel',
    actions: ['month', 'next']
  },
  LAST_YEAR: {
    label: 'lastYearLabel',
    actions: ['year', 'last'],
  },
  NEXT_QUARTER: {
    label: 'nextQuarterLabel',
    actions: ['quarter', 'next'],
  },
};

const PERIOD_FILTERS = {
  EARNINGS_DATE: [
    PERIOD_BUTTONS_TYPES.LAST_MONTH,
    PERIOD_BUTTONS_TYPES.LAST_WEEK,
    PERIOD_BUTTONS_TYPES.NEXT_WEEK,
    PERIOD_BUTTONS_TYPES.NEXT_MONTH,
  ],
  IPO: [
    PERIOD_BUTTONS_TYPES.LAST_YEAR,
    PERIOD_BUTTONS_TYPES.LAST_MONTH,
    PERIOD_BUTTONS_TYPES.LAST_WEEK,
    PERIOD_BUTTONS_TYPES.NEXT_WEEK,
  ],
  EX_DIVIDENT: [
    PERIOD_BUTTONS_TYPES.LAST_WEEK,
    PERIOD_BUTTONS_TYPES.NEXT_WEEK,
    PERIOD_BUTTONS_TYPES.NEXT_MONTH,
    PERIOD_BUTTONS_TYPES.NEXT_QUARTER,
  ]
};

export const FILTERS_TYPES = {
  1: PERIOD_FILTERS.IPO,
  2: PERIOD_FILTERS.EARNINGS_DATE,
  3: PERIOD_FILTERS.EARNINGS_DATE,
  4: PERIOD_FILTERS.EX_DIVIDENT,
};

export const HELPER_PERIOD = {
  MONTH: 'month',
  YEAR: 'year',
  WEEK: 'week',
  QUARTER: 'quarter',
};

export const DATE_VECTOR = {
  NEXT: 'next',
  LAST: 'last',
};

export const DATE_CONTAINER_HEIGHT = 254;
