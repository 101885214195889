import NEW_SCREEN_ACTIONS from './types';

const INITIAL_STATE = {
  itemsNewScreen: [],
  presetsNewScreen: [],
  itemId: {},
  selectedScreen: {},
  prevScreen: null,
  groupFilters: [],
  requestStateFilters: [],
  folders: [],
  screens: [],
  presetFolders: [],
  presets: [],
  columnSetFolder: [],
  columnSets: [],
  itemsColumnSets: [],
  columnSetPresetFolder: [],
  columnSetsPreset: [],
  itemsColumnSetsPreset: [],
  newScreenFlag: true,
  hoveredFolderColumnSetsId: -1,
  hoveredColumnSetsId: -1,
  columnSetId: -1,
  columnSetUpdateFlag: true,
  sortData: [],
  stateColumnsSetName: '',
  selectedCategory: null,
  dataPanels: [],
  dataPanelsFolders: [],
  dataPanelsPresets: [],
  dataPanelsPresetsFolders: [],
  dataPanelsItems: [],
  dataPanelsItemsPresets: [],
  selectedDataPanel: null,
  createdFolder: null,
};

const newScreenReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NEW_SCREEN_ACTIONS.GET_ITEMS_NEW_SCREEN:
      return { ...state, itemsNewScreen: payload };
    case NEW_SCREEN_ACTIONS.GET_PRESET_ITEMS_NEW_SCREEN:
      return { ...state, presetsNewScreen: payload };
    case NEW_SCREEN_ACTIONS.GET_PRESETS:
      return { ...state, presetsNewScreen: payload };
    case NEW_SCREEN_ACTIONS.GET_ITEM_ID:
      return { ...state, itemId: payload };
    case NEW_SCREEN_ACTIONS.SET_SELECTED_SCREEN:
      return { ...state, selectedScreen: payload, itemId: payload };
    case NEW_SCREEN_ACTIONS.SET_GROUP_FILTERS:
      return { ...state, groupFilters: payload };
    case NEW_SCREEN_ACTIONS.SET_REQUEST_STATE_FILTERS:
      return { ...state, requestStateFilters: payload };
    case NEW_SCREEN_ACTIONS.SET_NEW_SCREEN_FLAG:
      return { ...state, newScreenFlag: payload };
    case NEW_SCREEN_ACTIONS.SET_FOLDERS:
      return { ...state, folders: payload };
    case NEW_SCREEN_ACTIONS.SET_SCREENS:
      return { ...state, screens: payload };
    case NEW_SCREEN_ACTIONS.SET_FOLDERS_PRESET:
      return { ...state, presetFolders: payload };
    case NEW_SCREEN_ACTIONS.SET_SCREENS_PRESET:
      return { ...state, presets: payload };
    case NEW_SCREEN_ACTIONS.SET_FOLDER_COLUMNS_SET:
      return { ...state, columnSetFolder: payload };
    case NEW_SCREEN_ACTIONS.SET_COLUMNS_SET:
      return { ...state, columnSets: payload };
    case NEW_SCREEN_ACTIONS.SET_ITEMS_COLUMNS_SET:
      return { ...state, itemsColumnSets: payload };
    case NEW_SCREEN_ACTIONS.SET_FOLDER_COLUMNS_SET_PRESET:
      return { ...state, columnSetPresetFolder: payload };
    case NEW_SCREEN_ACTIONS.SET_COLUMNS_SET_PRESET:
      return { ...state, columnSetsPreset: payload };
    case NEW_SCREEN_ACTIONS.SET_ITEMS_COLUMNS_SET_PRESET:
      return { ...state, itemsColumnSetsPreset: payload };
    case NEW_SCREEN_ACTIONS.SET_HOVERED_FOLDER_COLUMN_SETS_ID:
      return { ...state, hoveredFolderColumnSetsId: payload };
    case NEW_SCREEN_ACTIONS.SET_HOVERED_COLUMN_SETS_ID:
      return { ...state, hoveredColumnSetsId: payload };
    case NEW_SCREEN_ACTIONS.COLUMN_SET_ID:
      return { ...state, columnSetId: payload };
    case NEW_SCREEN_ACTIONS.SAVE_SORT_DATA:
      return { ...state, sortData: payload };
    case NEW_SCREEN_ACTIONS.SET_NAME_COLUMNS_SET:
      return { ...state, stateColumnsSetName: payload };
    case NEW_SCREEN_ACTIONS.SET_DATA_PANELS:
      return { ...state, dataPanels: payload };
    case NEW_SCREEN_ACTIONS.SET_DATA_PANELS_FOLDERS:
      return { ...state, dataPanelsFolders: payload };
    case NEW_SCREEN_ACTIONS.SET_DATA_PANELS_PRESETS:
      return { ...state, dataPanelsPresets: payload };
    case NEW_SCREEN_ACTIONS.SET_DATA_PANELS_PRESETS_FOLDERS:
      return { ...state, dataPanelsPresetsFolders: payload };
    case NEW_SCREEN_ACTIONS.SET_DATA_PANELS_ITEMS:
      return { ...state, dataPanelsItems: payload };
    case NEW_SCREEN_ACTIONS.SET_DATA_PANELS_ITEMS_PRESETS:
      return { ...state, dataPanelsItemsPresets: payload };
    case NEW_SCREEN_ACTIONS.SET_DEFAULT_DATA_PANELS:
      return { ...state, selectedDataPanel: payload };
    case NEW_SCREEN_ACTIONS.SET_ITEM_NEW_SCREEN:
      return { ...state, itemsNewScreen: [...payload] };
    case NEW_SCREEN_ACTIONS.SET_PRESET_ITEM_NEW_SCREEN:
      return { ...state, presetsNewScreen: [...payload] };
    case NEW_SCREEN_ACTIONS.SET_SELECTED_CATEGORY:
      return { ...state, selectedCategory: payload };
    case NEW_SCREEN_ACTIONS.SET_COLUMNS_SET_UPDATE_FLAG:
      return { ...state, columnSetUpdateFlag: payload };
    case NEW_SCREEN_ACTIONS.SET_PREV_SCREEN:
      return { ...state, prevScreen: payload };
    case NEW_SCREEN_ACTIONS.SET_CREATED_FOLDER:
      return { ...state, createdFolder: payload };
    default:
      return state;
  }
};

export default newScreenReducer;
