import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../TextField/TextField';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/screener/arrow-or.svg';
import styles from '../sass/FilterScreen.module.scss';
import Tooltip from '../../Tooltip/Tooltip';

interface INoFiltersOr {
  active: boolean;
  handlerActive: (id: number) => void;
  textTooltip: string;
  id: number;
}

const NoFiltersOr = ({
  active, handlerActive, textTooltip, id
}: INoFiltersOr): React.ReactElement => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles.blockNoFilters} ${active ? styles.activeBlockFilters : ''}`}
      onClick={() => handlerActive(id)}
      onKeyDown={() => handlerActive(id)}
      role="button"
      aria-hidden="true"
      tabIndex={0}
    >
      <div className={styles.wrapperTooltip}>
        <Tooltip text={textTooltip} classType={id === 1 ? 'tooltipDropWrap' : ''} />
      </div>
      <div className={styles.contentIconOr}>
        <div className={styles.iconWrapperOr}>
          <FilterIcon className={styles.filterIconOr} />
        </div>
        <TextField styleType="basketTitle" text={t('noFiltersApllied')} />
        <TextField styleType="basketOr" text={t('filtersOrAllows')} />
      </div>
    </div>
  );
};

export default NoFiltersOr;
