import { DOWNLOAD_FILE, DOWNLOAD_FILE_LIGHT_SERVER } from '../../constants/paths';
import {
  FILE_FORMATS,
  TWITTER_CHARACTER_LIMIT,
  SPACE_COUNT,
} from '../../constants/screener';
import $api, { REACT_APP_PUBLIC_LIGHT_SERVER_URL } from '../../http';

const downloadSymbolsHandler = (data, currentTitle) => {
  $api.post(
    `${REACT_APP_PUBLIC_LIGHT_SERVER_URL}${DOWNLOAD_FILE_LIGHT_SERVER}`,
    data,
    { responseType: 'blob' }
  ).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(DOWNLOAD_FILE,
      `${currentTitle}.${data.type === FILE_FORMATS.CSV ? FILE_FORMATS.CSV : FILE_FORMATS.XLSX}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
};

const copyHandler = (data) => {
  const dataPrepared = `${data.result.map((item) => item[0].sym)}`;
  const dataPreparedTwitter = `${data.result.map((item) => `$${item[0].sym}`)}`;

  const stringData = JSON.stringify(dataPrepared.replace(/[\s,%]/g, ' '));
  const stringDataTwitter = JSON.stringify(dataPreparedTwitter.replace(/[\s,%]/g, ' '));

  const stringDataQuotes = stringData.slice(1, stringData.length - 1);
  const stringDataQuotesTwitter = stringDataTwitter.slice(1, stringDataTwitter.length - 1);

  const textField = document.createElement('textarea');
  textField.innerText = stringDataQuotes;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
  return { stringDataQuotesTwitter };
};

const twitterDataLength = (data, tab, deepVueLength) => {
  const totalLength = TWITTER_CHARACTER_LIMIT;
  const tabLength = tab.title?.length;
  const twitterStringLength = data.length;
  const requiredLength = tabLength + deepVueLength + SPACE_COUNT;

  if (twitterStringLength > (totalLength - requiredLength)) {
    let newData = '';
    for (let i = 0; i < (totalLength - requiredLength); i += 1) {
      newData += data[i];
    }
    if (newData.endsWith('')) {
      const lastIndex = newData.lastIndexOf(' ');
      newData = newData.substring(0, lastIndex);
    }
    return newData;
  }
  return data;
};

export {
  copyHandler, twitterDataLength, downloadSymbolsHandler
};
