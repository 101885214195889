import React, {
  useRef, useEffect, useState
} from 'react';

import PropTypes from 'prop-types';
import SymbolHeaderColumn from './SymbolHeaderColumn';
import TableResizeAnchor from './components/TableResizeAnchor';
import {
  symbolColumn, RESIZE_COLUMN_CLASSES,
  TABLE_COLUMNS_MIN_SIZES, TABLE_COLUMNS_MAX_SIZES,
  MAX_COLUMN_WIDTH, ONE_HUNDRED, COLUMNS_INDEX
} from '../../constants/screener';
import { ALERT_COLUMNS, ALERT_COLUMN_INDEXES } from '../../constants/alerts';
import styles from './sass/ScreenerTable.module.scss';
import { defaultSizeValue, getColumnStyle } from '../../utils/helpers';
import CellContainer from './CellContainer';
import { isNumericalColumn } from '../../utils/helperColumnsWorker';

const TableHeaderCell = ({
  column,
  checked,
  setAllItems,
  toggleIconHandler,
  sortHandler,
  sortStatus,
  columnsSize,
  updateColumnsSize,
  initColumnsSize,
  loaderStatus,
  zIndex,
  setScrollLeft,
  alertTable,
  setGridWidth,
  widgetResizeSelector,
  isWidget,
}) => {
  const itemRef = useRef(null);
  const itemTimeout = useRef(null);
  const [resizeClass, setResizeClass] = useState('');
  const isNumberColumn = isNumericalColumn(column?.type);

  const setInitialHeaderCellSize = () => {
    if (!columnsSize[column?.index]) {
      const element = itemRef?.current;
      let columnWidth = element?.getBoundingClientRect()?.width;
      if (columnWidth > MAX_COLUMN_WIDTH) {
        columnWidth = MAX_COLUMN_WIDTH;
      }
      initColumnsSize(columnWidth, column?.index);
    }
  };

  const updateAllColumns = (width) => {
    const resizeClassSelector = isWidget
      ? `columnResize_${column?.index}_${widgetResizeSelector}`
      : `columnResize_${column?.index}`;
    const columnsArray = document.getElementsByClassName(resizeClassSelector);
    const columnLength = columnsArray?.length;
    if (columnLength) {
      let proced = 0;
      while (proced < columnLength) {
        columnsArray[proced].style.minWidth = `${width}px`;
        columnsArray[proced].style.maxWidth = `${width}px`;
        proced += 1;
      }
    }
  };

  const getMinWidth = () => {
    if (
      columnsSize[column?.index]?.startWidth < TABLE_COLUMNS_MIN_SIZES[column?.index]
        || column?.index === COLUMNS_INDEX.SYM
    ) {
      return TABLE_COLUMNS_MIN_SIZES[column?.index];
    }
    return columnsSize[column?.index]?.startWidth || TABLE_COLUMNS_MIN_SIZES.default;
  };

  const updateHeaderCellSize = (delta, isSave) => {
    let minResize = getMinWidth();
    let maxResize = columnsSize[column?.index]?.maxWidth;
    let oldWidth = columnsSize[column?.index]?.minWidth || columnsSize[column?.index]?.startWidth;
    if (!columnsSize[column?.index]) {
      oldWidth = itemRef.current?.getBoundingClientRect().width;
      minResize = TABLE_COLUMNS_MIN_SIZES[column?.index];
      maxResize = TABLE_COLUMNS_MAX_SIZES[column?.index];
    }
    oldWidth += delta;
    setResizeClass('');
    if (oldWidth <= minResize) {
      oldWidth = minResize;
      setResizeClass(RESIZE_COLUMN_CLASSES.min);
    }
    if (maxResize && oldWidth >= maxResize) {
      oldWidth = maxResize;
      setResizeClass(RESIZE_COLUMN_CLASSES.max);
    }
    itemRef.current.style.minWidth = `${oldWidth}px`;
    itemRef.current.style.maxWidth = `${oldWidth}px`;
    updateColumnsSize(oldWidth, column?.index, isSave);
    updateAllColumns(oldWidth);

    setGridWidth(itemRef.current.parentElement.offsetWidth);
  };

  useEffect(() => {
    if (itemRef?.current && !loaderStatus && columnsSize) {
      itemTimeout.current = setTimeout(setInitialHeaderCellSize, ONE_HUNDRED);
    }

    return () => {
      clearTimeout(itemTimeout.current);
      itemTimeout.current = null;
      itemRef.curren = null;
    };
  }, [itemRef?.current]);

  const showSortIcons = () => {
    if (!alertTable) return true;
    return [
      ALERT_COLUMN_INDEXES.STATUS,
      ALERT_COLUMN_INDEXES.SYMBOL,
      ALERT_COLUMN_INDEXES.DATE,
      ALERT_COLUMN_INDEXES.TYPE,
    ].includes(column?.index);
  };

  const handleMouseDown = () => {
    if (showSortIcons()) {
      sortHandler(column?.index);
    }
    setScrollLeft();
  };

  const isRenderSymbolColumn = (index) => (index === symbolColumn)
    || (alertTable && (index === ALERT_COLUMNS[0].index || index === ALERT_COLUMNS[1].index));

  return (
    <div
      ref={itemRef}
      className={`${styles.screenerTableHeaderCell} ${styles[column?.name]} ${alertTable ? styles.headerStatus : ''}`}
      style={getColumnStyle(columnsSize[column?.index] || defaultSizeValue(column?.index), zIndex, null, column)}
    >
      {isRenderSymbolColumn(column?.index) && (
        <SymbolHeaderColumn
          setAllItems={setAllItems}
          checked={checked}
          index={column?.index}
          toggleIconHandler={toggleIconHandler}
          hideButton={column?.index === ALERT_COLUMNS[0].index}
          hideCheckbox={column?.index === ALERT_COLUMNS[1].index}
        />
      )}
      <CellContainer
        column={column}
        sortStatus={sortStatus}
        sortHandler={sortHandler}
        isNumberColumn={isNumberColumn}
        showSortIcons={showSortIcons}
        handleMouseDown={handleMouseDown}
        isAlertTable={alertTable}
      />
      <TableResizeAnchor resizeStateClass={resizeClass} updateHeaderCellSize={updateHeaderCellSize} />
    </div>
  );
};

TableHeaderCell.propTypes = {
  sortStatus: PropTypes.func.isRequired,
  sortHandler: PropTypes.func.isRequired,
  setAllItems: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  column:
    PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
  toggleIconHandler: PropTypes.func.isRequired,
  columnsSize: PropTypes.shape({}),
  updateColumnsSize: PropTypes.func,
  initColumnsSize: PropTypes.func,
  loaderStatus: PropTypes.bool,
  zIndex: PropTypes.number,
  setScrollLeft: PropTypes.func,
  alertTable: PropTypes.bool,
  setGridWidth: PropTypes.func.isRequired,
  widgetResizeSelector: PropTypes.number,
  isWidget: PropTypes.bool,
};

TableHeaderCell.defaultProps = {
  columnsSize: {},
  updateColumnsSize: () => undefined,
  initColumnsSize: () => undefined,
  setScrollLeft: () => undefined,
  loaderStatus: false,
  zIndex: 0,
  checked: false,
  alertTable: false,
  widgetResizeSelector: 0,
  isWidget: false,
};

export default TableHeaderCell;
