import React, {
  useState, useEffect, useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';
import styles from './sass/FilterScreen.module.scss';
import useFilters from '../../utils/hooks/useFilters';
import UpgradeAccountBanner from '../UpgradeAccountBanner/UpgradeAccountBanner';
import FilterRightCol from './components/FilterRightCol';
import FilterContainer from './components/FilterContainer';
import FilterHeader from './components/FilterHeader';
import {
  MODAL_TITLES,
  NEW_SCREEN_DATA,
  SCREENER_VIEWS,
  SUBSCRIPTION_TYPE,
  defaultPage,
} from '../../constants/screener';
import { scrollToItem, getTitleModal } from '../../utils/helpers';
import { getCategoryFiltersSearch } from '../../utils/helperFilter';

import constants from '../../constants/filters';
import { checkRoleUser } from '../../utils/userHelper';
import { setCategoryName } from '../../store/filters/slice';
import { useTheme } from '../../utils/hooks/useTheme';
import GeneralConfirmModal from '../ConfirmModal/GeneralConfirmModal';
import { setNewScreenFlag } from '../../store/newScreen/actions';
import useNewScreen from '../../utils/hooks/useNewScreen';
import { setMarketsFilterScreenerAction } from '../../store/screener/actions';
import { setVisibleItemsTableStore } from '../../store/tableData/slice';
import RootStateTypes from '../../store/RootStateTypes';
import { TFilterGroupe } from './Types';

interface IFilterDropdownProps {
  setIsVisibleFilters: (isVisible: boolean) => void;
  isVisibleFilters: boolean;
  headerFilter: string;
  openModalHandler: (id: string, status: boolean) => void;
  currentModalRef: React.RefObject<HTMLDivElement>;
  widgetSymbolCounter?: number;
  widgetId?: string;
  openModal?: string;
}

const FilterDropdown = ({
  setIsVisibleFilters,
  isVisibleFilters,
  headerFilter,
  openModalHandler,
  currentModalRef,
  widgetSymbolCounter,
  widgetId,
  openModal
}: IFilterDropdownProps): React.ReactElement => {
  const searchRefTimeout = useRef<NodeJS.Timeout | null>(null);
  const scrollRefTimeout = useRef<NodeJS.Timeout | null>(null);
  const { theme } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isDirty, setIsDirty] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [activeGroup, setActiveGroup] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [originalFilters, setOriginalFilters] = useState([]);

  const columnsList = useSelector((state: RootStateTypes) => state.screenerState.columnsList, shallowEqual);
  const profile = useSelector((state: RootStateTypes) => state.accountState.userProfile, shallowEqual);
  const newScreenFlag = useSelector((state: RootStateTypes) => state.newScreenState.newScreenFlag, shallowEqual);
  const initialFilterList = useSelector((state: RootStateTypes) => state.screenerState.initialFilterList, shallowEqual);
  const selectedScreen = useSelector((state: RootStateTypes) => state.newScreenState.selectedScreen, shallowEqual);
  const filters = useSelector((state: RootStateTypes) => state.newScreenState.itemId?.data?.filters, shallowEqual);
  const groupFilters = useSelector((state: RootStateTypes) => state.newScreenState.requestStateFilters, shallowEqual);
  const requestState = useSelector((state: RootStateTypes) => state.filtersState.requestState, shallowEqual);
  const screenerCounterSymbols = useSelector(
    (state: RootStateTypes) => state.tableDataState.counterSymbols, shallowEqual
  );
  const multiChartCounterSymbols = useSelector(
    (state: RootStateTypes) => state.multiChartState.symbolsList, shallowEqual
  );
  const screenerView = useSelector(
    (state: RootStateTypes) => state.accountState.userSettings.screenerView, shallowEqual
  );

  const adminUser = checkRoleUser(SUBSCRIPTION_TYPE.ADMIN) || checkRoleUser(SUBSCRIPTION_TYPE.EDITOR);
  const checkFreeUser = checkRoleUser(SUBSCRIPTION_TYPE.FREE);
  const disabled = selectedScreen?.type === constants.currentScreenType && !adminUser && !newScreenFlag;
  const titleCreateItem = getTitleModal(headerFilter);
  const hideButton = !disabled || adminUser;

  const { updateScreenFilters } = useNewScreen();

  const {
    removeFilter,
    clearAll,
    handlerGroup,
    addNewGroupHandler,
    removeGroup,
    setFilter,
  } = useFilters(
    disabled,
    headerFilter,
    activeGroup,
    setActiveGroup,
    isVisibleFilters,
    newScreenFlag,
    openModal
  );

  const getSymbolsCounter = (): number | undefined => {
    if (widgetId) {
      return widgetSymbolCounter;
    }
    return screenerView === SCREENER_VIEWS.SCREENER
      ? screenerCounterSymbols : multiChartCounterSymbols.length;
  };

  useEffect(() => {
    if (!isVisibleFilters) {
      setActiveGroup(0);
      setOriginalFilters([]);
    }
  }, [isVisibleFilters]);

  useEffect(() => {
    if (!originalFilters.length && filters) {
      setOriginalFilters(cloneDeep(filters));
    }
  }, [filters]);

  useEffect(() => () => setSearchFilter(''), []);

  const clearSearch = (): void => {
    setSearchFilter('');
    clearTimeout(searchRefTimeout?.current as NodeJS.Timeout);
    searchRefTimeout.current = null;
  };

  const clearScroll = () => {
    clearTimeout(scrollRefTimeout?.current as NodeJS.Timeout);
    scrollRefTimeout.current = null;
  };

  const handlerSearchFilter = (
    id: number,
    type: string | undefined,
    columnId: number,
    list: TFilterGroupe[],
    category?: string
  ): void => {
    const categoryFilterSearch = getCategoryFiltersSearch(list, id, category);
    const { groupFilter, nameGroupFilters, categoryName } = categoryFilterSearch;
    const typeItem = type ? `${id}:${type}:${columnId}` : `${id}`;
    setSearchFilter(typeItem);
    searchRefTimeout.current = setTimeout(() => {
      clearSearch();
      clearTimeout(searchRefTimeout.current as NodeJS.Timeout);
    }, 600);
    dispatch(setCategoryName(categoryName));
    scrollRefTimeout.current = setTimeout(() => {
      scrollToItem(`${groupFilter ? nameGroupFilters : id}`);
      clearScroll();
    }, 500);
  };

  const closePanel = (): void => {
    setIsConfirmModalOpen(false);
    setIsVisibleFilters(false);
    setIsDirty(false);
  };

  const saveScreenHandler = (action: string): void => {
    setActiveGroup(0);
    if (action !== MODAL_TITLES.EDIT) {
      openModalHandler(action, false);
      dispatch(setMarketsFilterScreenerAction({ data: requestState }));
      dispatch(setVisibleItemsTableStore(defaultPage));
    }
    if (action === MODAL_TITLES.EDIT) {
      updateScreenFilters(selectedScreen.id, groupFilters, selectedScreen.data);
    }
    setIsVisibleFilters(false);
  };

  const modalConfirmHandler = (): void => {
    closePanel();
    if (!newScreenFlag && selectedScreen?.type !== NEW_SCREEN_DATA.PRESETS) {
      updateScreenFilters(selectedScreen.id, groupFilters, selectedScreen.data);
      dispatch(setNewScreenFlag(false));
    } else if (newScreenFlag) {
      saveScreenHandler(titleCreateItem);
    }
  };

  const modalDeclineHandler = (): void => {
    closePanel();
    dispatch(setNewScreenFlag(false));
    if (!newScreenFlag && selectedScreen?.type !== NEW_SCREEN_DATA.PRESETS) {
      updateScreenFilters(selectedScreen.id, originalFilters, { ...selectedScreen.data, filters: originalFilters });
    }
  };

  const stateFiltersHandler = (filter: (number | number[])[]): void => {
    setFilter(filter);
    setIsDirty(true);
  };

  const removeFilterHandler = (id: number, activeGroupIndex: number, checkMap: number): void => {
    removeFilter(id, activeGroupIndex, checkMap);
    setIsDirty(true);
  };

  return (
    <div
      className={`${styles.filter} ${isVisibleFilters ? styles.visible : styles.hide} ${theme}`}
      ref={isVisibleFilters ? currentModalRef : null}
    >
      {isConfirmModalOpen && (
        <div className={styles.popupBg}>
          <GeneralConfirmModal
            title="Do you want to save changes you've made for this Screen?"
            body=""
            description=""
            confirmCaption={t('saveChanges')}
            declineCaption={t('discard')}
            confirmHandler={modalConfirmHandler}
            declineHandler={modalDeclineHandler}
          />
        </div>
      )}
      <FilterHeader
        closeBar={() => {
          if (isDirty) {
            setIsConfirmModalOpen(true);
          } else {
            closePanel();
            dispatch(setNewScreenFlag(false));
          }
        }}
        disabled={disabled}
        headerFilter={headerFilter}
        description={selectedScreen?.data?.description}
        openModalHandler={openModalHandler}
        closePanel={closePanel}
      />
      <div className={`${styles.row} ${styles.contentRow}`}>
        <div className={styles.col}>
          <FilterContainer
            columnsList={columnsList}
            noFiltersAvailable={t('noFiltersAvailable')}
            isVisibleFilters={isVisibleFilters}
            initialFilterList={initialFilterList as TFilterGroupe[]}
            stateFiltersHandler={stateFiltersHandler}
            disabled={checkFreeUser || disabled}
            adminUser={adminUser}
            searchFilter={searchFilter}
            addFavorite={() => undefined}
            handlerSearchFilter={handlerSearchFilter}
            favoriteData={[]}
          />
          <div className={styles.filter_upgrade}>
            <UpgradeAccountBanner
              title={t('filtersAvailableToPremiumUsers')}
              buttonTitle={t('upgradeYourAccount')}
              profile={profile}
            />
          </div>
        </div>
        <FilterRightCol
          disabled={disabled}
          profile={profile}
          btnOr={t('or')}
          clearHandler={clearAll}
          clearAll={t('clearAll')}
          activeGroup={activeGroup}
          removeGroup={removeGroup}
          handlerGroup={handlerGroup}
          title={t('appliedFilters')}
          addOrLogic={t('addOrLogic')}
          textTooltip={t('textTooltip')}
          btnSaveScreen={t('saveScreen')}
          handlerSearchFilter={handlerSearchFilter}
          textTooltipPresets={t('textTooltipPresets')}
          freeTooltipPresets={t('freeTooltipPresets')}
          clearItemFilter={removeFilterHandler}
          addNewGroupHandler={addNewGroupHandler}
          freeUser={checkFreeUser}
          titleCreateItem={titleCreateItem}
          hideButton={hideButton}
          counterSymbols={getSymbolsCounter()}
          saveScreenHandler={saveScreenHandler}
        />
      </div>
    </div>
  );
};

export default FilterDropdown;
