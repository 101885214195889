import React, { useRef, useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import ChartContainer from '../../ChartContainer/ChartContainer';
import styles from '../sass/DashboardComponents.module.scss';
import ChartWidgetHeader from './ChartWidgetHeader';
import { IWidgetRendered } from '../types/DashboardComponentsInterfaces';
import { ReactComponent as DndControl } from '../../../assets/images/icons/screener/dnd_frame.svg';
import { Widget } from '../../../pages/Dashboards/types/DashboardTypes';
import { TV_EVENTS } from '../../../constants/screener';
import { CurrentWidget } from '../../ChartContainer/types/Types';

const ChartWidget = ({
  widget, removeWidget,
  updateWidget, setColorGroupe,
  openModalHandler, openItemsModalHandler,
  globalSearchKeyHandler, isEditable, activeSymbolIndexValue
}: IWidgetRendered): React.ReactElement => {
  const widgetRef = useRef<Widget | null>(null);
  const chartRef = useRef<CurrentWidget | null>(null);
  const { ref, width } = useResizeDetector();

  const updateWidgetAndSaveState = (value: number | boolean | string, field: string) => {
    const newWidget = { ...widgetRef.current, [field]: value };
    widgetRef.current = newWidget as Widget;
    updateWidget(newWidget as Widget);
  };

  const saveChartLayoudId = (id: number, widgetId: string) => {
    if (widget?.id === widgetId && widget?.chartLayoutId !== id && id !== 0) {
      updateWidgetAndSaveState(id, 'chartLayoutId');
    }
  };

  const updateChartSymbolSync = (value: boolean, widgetId: string) => {
    if (widget?.id === widgetId) {
      updateWidgetAndSaveState(value, 'symbolSync');
    }
  };
  const updateIntervalSync = (value: boolean, widgetId: string) => {
    if (widget?.id === widgetId) {
      updateWidgetAndSaveState(value, 'intervalSync');
    }
  };
  const updateTimeSync = (value: boolean, widgetId: string) => {
    if (widget?.id === widgetId) {
      updateWidgetAndSaveState(value, 'timeSync');
    }
  };
  const updateCrosshairSync = (value: boolean, widgetId: string) => {
    if (widget?.id === widgetId) {
      updateWidgetAndSaveState(value, 'crosshairSync');
    }
  };

  const updateDateRangeSync = (value: boolean, widgetId: string) => {
    if (widget?.id === widgetId) {
      updateWidgetAndSaveState(value, 'dateRangeSync');
    }
  };

  const updateSymbolSync = (value: string, widgetId: string) => {
    if (widget?.id === widgetId) {
      updateWidgetAndSaveState(value, 'symbol');
    }
  };

  useEffect(() => {
    const parentElement = document.getElementById(`tv_chart_container_${widget.id}`);
    if (parentElement?.children?.length) {
      const chartIFrame = parentElement?.children[0];
      // eslint-disable-next-line
      // @ts-ignore
      const chartContext = chartIFrame?.contentWindow;
      chartContext.addEventListener(TV_EVENTS.KEY_DOWN, globalSearchKeyHandler);
    }
    return () => {
      parentElement?.removeEventListener('keydown', globalSearchKeyHandler as unknown as EventListener);
    };
  }, [chartRef?.current]);

  useEffect(() => {
    widgetRef.current = widget;
  }, [widget]);

  return (
    <div className={styles.chartWrap} ref={ref}>
      <div className={`${styles.chartDnDBlock} handle-grid-drag`}>
        {isEditable && <DndControl />}
      </div>
      <ChartWidgetHeader
        openItemsModalHandler={openItemsModalHandler}
        openModalHandler={openModalHandler}
        setColorGroupe={setColorGroupe}
        updateWidget={updateWidget}
        removeWidget={removeWidget}
        widget={widget}
        width={width}
        isEditable={isEditable}
      />
      <ChartContainer
        dashWidget={widget}
        keyDownHandler={() => {}}
        isAlertTable={false}
        handleToggleStats={() => {}}
        isStatsChartOpen={false}
        activeSymbolIndexValue={activeSymbolIndexValue}
        watchList={false}
        saveChartLayoudId={saveChartLayoudId}
        updateChartSymbolSync={updateChartSymbolSync}
        updateIntervalSync={updateIntervalSync}
        updateTimeSync={updateTimeSync}
        updateCrosshairSync={updateCrosshairSync}
        updateDateRangeSync={updateDateRangeSync}
        updateSymbolSync={updateSymbolSync}
        dashboardChartRef={chartRef}
      />
    </div>
  );
};

export default ChartWidget;
