import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_SIZE_STATE, FIXED_COLUMNS, VIEW_TYPES
} from '../../../constants/screener';
import { setColumnSetsId, setNameColumnSets } from '../../../store/newScreen/actions';
import { getActiveColumns } from '../../../utils/helperColumnsWorker';
import {
  setColumnsScreenerAction
} from '../../../store/screener/actions';
import { getTitleCreateSuccess } from '../../../utils/helperNewScreenModal';
import $api from '../../../http';
import { DASHBOARD_ITEMS } from '../../../constants/paths';
import { DASHBOARD_ITEM_EXISTS } from '../../../constants/responseStatuses';
import useSaveColumns from './useSaveColumns';
import useNewScreen from '../../../utils/hooks/useNewScreen';
import { saveUserState } from '../../../store/account/actions';
import { setCleanedItemsColumnsAction } from '../../../store/tableData/slice';

const useNewColumnSet = (modalHandler = () => {}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const columnsList = useSelector((state) => state.screenerState.columnsList, shallowEqual);
  const userLastState = useSelector((state) => state.accountState.userSettings, shallowEqual);

  const { saveColumnsToSpecificPlace } = useSaveColumns();
  const { getColumnSetsItems, successHandler, errorHandler } = useNewScreen();

  const successHandlerColumns = (message, title) => {
    successHandler(message, title);
    modalHandler();
  };

  const initColumnsSizeState = (id) => {
    const newState = { ...userLastState };
    newState.columnsWidth = {
      ...newState.columnsWidth,
      ...{ [id]: DEFAULT_SIZE_STATE }
    };

    dispatch(saveUserState(newState));
  };

  const setNewColumnSet = (columnSet) => {
    const activeColumns = getActiveColumns(columnsList, FIXED_COLUMNS, []);

    dispatch(setColumnSetsId(columnSet?.id));
    dispatch(setColumnsScreenerAction({ data: activeColumns }));
    dispatch(setNameColumnSets(columnSet?.title || t('recommended')));

    initColumnsSizeState(columnSet?.id);
    saveColumnsToSpecificPlace(columnSet?.id, columnSet);
  };

  const createColumnsSet = (...params) => {
    const [nameScreen,, folderId, folderName, type] = params;
    const body = {
      title: nameScreen,
      type,
      viewType: VIEW_TYPES.TABLE,
      data: {},
      isFavorite: false,
    };

    if (folderId && +folderId !== -1) {
      body.folder = { id: +folderId, name: folderName };
    }

    const labelSuccess = getTitleCreateSuccess(body.type, t);
    $api.post(
      DASHBOARD_ITEMS,
      body
    ).then((res) => {
      successHandlerColumns(nameScreen, labelSuccess);
      getColumnSetsItems();
      getColumnSetsItems(true);
      setNewColumnSet(res?.data);

      dispatch(setCleanedItemsColumnsAction());
    }).catch((error) => {
      if (error.response?.data?.message === DASHBOARD_ITEM_EXISTS) {
        errorHandler(nameScreen);
      }
    });
  };

  return { createColumnsSet };
};

export default useNewColumnSet;
