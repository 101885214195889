import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import RootStateTypes from '../../../store/RootStateTypes';
import { setWidgetsFilterVisible } from '../../../store/dashboards/slice';
import { IUseFiltersModalWorker } from '../types/DashboardComponentsInterfaces';

const useWidgetFiltersModalWorker: IUseFiltersModalWorker = () => {
  const dispatch = useDispatch();
  const widgetsFilterVisible = useSelector(
    (state: RootStateTypes) => state.dashboardsState.widgetsFilterVisible, shallowEqual
  );

  const closeFilterModal = (id: string): void => {
    const newWidgetsFilterVisible = { ...widgetsFilterVisible };
    newWidgetsFilterVisible[id] = false;
    dispatch(setWidgetsFilterVisible(newWidgetsFilterVisible));
  };

  const openFiltersModal = (id: string): void => {
    const newWidgetsFilterVisible = { ...widgetsFilterVisible };
    if (newWidgetsFilterVisible[id]) {
      closeFilterModal(id);
    } else {
      Object.keys(newWidgetsFilterVisible).forEach((key: string) => {
        newWidgetsFilterVisible[key] = false;
      });
      newWidgetsFilterVisible[id] = true;
      dispatch(setWidgetsFilterVisible(newWidgetsFilterVisible));
    }
  };

  return {
    openFiltersModal,
  };
};

export default useWidgetFiltersModalWorker;
