import React from 'react';

import { t } from 'i18next';
import styles from './sass/SelectGroupDropFilter.module.scss';
import ItemSelectDrop from './ItemSelectDrop';
import { createPlaceholder } from '../../utils/helperFilter';
import { TCommonItem } from '../FilterScreen/Types';

interface IListOptionsFilters {
  data: TCommonItem[];
  selectedGroup: string;
  handlerSelectedGroup: (title: string) => void;
  searchValue: string;
  searchValueHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlerSelectedFilter: (filterItem: TCommonItem) => void;
  selectedFilter: TCommonItem;
}

const ListOptionsFilters = ({
  data, selectedGroup, handlerSelectedGroup, searchValue,
  searchValueHandler, handlerSelectedFilter, selectedFilter,
}: IListOptionsFilters): React.ReactElement => (
  <div className={styles.dropDown}>
    {data?.length > 0 && data.map((item, index) => (
      item.fShow && (
        <ItemSelectDrop
          key={[item.name, index].join('_')}
          title={item.name}
          active={item.name === selectedGroup}
          handlerSelectedGroup={handlerSelectedGroup}
          placeholder={createPlaceholder(t('type'), item, t('to'))}
          searchValue={searchValue}
          searchValueHandler={searchValueHandler}
          items={item?.data?.filter((el) => el.fShow === true) as TCommonItem[]}
          handlerSelectedFilter={handlerSelectedFilter}
          selectedFilter={selectedFilter}
        />
      )
    ))}
  </div>
);

export default ListOptionsFilters;
